//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstoqueAjusteListagemPage from './EstoqueAjusteListagemPage';

export default class EstoqueAjusteIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/estoque-ajuste" component={EstoqueAjusteListagemPage} />
      </Switch>
    );
  }
}
