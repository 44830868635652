//@flow
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import Picker from 'react-month-picker';
import { Col, Row } from 'reactstrap';
import Arrow from '../../images/icones/arrow-left.svg';
import { CalendarDate } from './CalendarDate';

type Props = {
  year?: number,
  month?: number,
  marcacoes?: any,
  medicoConfiguracaoVO: any,
  onClick: any,
  onMonthChange: any,
};

type State = {
  year: number,
  month: number,
  medicoConfiguracaoVO: any,
};

export class Calendar extends PureComponent<Props, State> {
  static defaultProps = {
    hideTotalMarcacao: false,
  };
  constructor(props: Props) {
    super(props);
  }

  newMonthClick = (tipo: string) => {
    const { year, month } = this.props;
    let newDate = new Date(year, month, 1);
    if (tipo === 'anterior') {
      newDate.setMonth(newDate.getMonth() - 1);
    } else if (tipo === 'proximo') {
      newDate.setMonth(newDate.getMonth() + 1);
    }

    this.props.onMonthChange(newDate.getFullYear(), newDate.getMonth());
  };

  render() {
    const { year, month } = this.props;
    const weeks = createCalendar(year, month);
    let pickerLang = {
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    };

    let pickerLangShort = {
        monthsShort: [
          'JAN',
          'FEV',
          'MAR',
          'ABR',
          'MAI',
          'JUN',
          'JUL',
          'AGO',
          'SET',
          'OUT',
          'NOV',
          'DEZ',
        ],
      },
      mvalue = { year: this.props.year, month: this.props.month + 1 };

    let makeText = (m) => {
      return pickerLang.months[m.month - 1] + ' ' + m.year;
    };

    let dataAtual = moment();
    let yearCurrent = dataAtual.year();

    let arrayYears = [yearCurrent, yearCurrent + 1, yearCurrent + 2];
    return (
      <div className="calendar px-3 pt-0">
        <Row>
          <div
            className="col-2 arrowMonth"
            onClick={() => {
              this.newMonthClick('anterior');
            }}
          >
            <img src={Arrow} alt="voltar mês" />
          </div>
          <Col className="month d-flex align-items-center justifycontent-center">
            <Picker
              ref="pickAMonth"
              years={arrayYears}
              value={mvalue}
              lang={pickerLangShort.monthsShort}
              onChange={(year, month) => {
                this.props.onMonthChange(year, month - 1);
              }}
            >
              <div
                onClick={(e) => {
                  this.refs.pickAMonth.show();
                }}
              >
                {makeText(mvalue)}
              </div>
            </Picker>
          </Col>
          <div
            className="col-2 arrowMonth"
            onClick={() => {
              this.newMonthClick('proximo');
            }}
          >
            <img src={Arrow} alt="avançar mês" className="rotate" />
          </div>
        </Row>
        <Row className="week justify-content-center">
          <div className="col">dom</div>
          <div className="col">seg</div>
          <div className="col">ter</div>
          <div className="col">qua</div>
          <div className="col">qui</div>
          <div className="col">sex</div>
          <div className="col">sab</div>
        </Row>
        {weeks.map((w, i) => (
          <Row className="justify-content-center" key={i}>
            {w.map((d, j) => (
              <CalendarDate
                key={d ? d.getDate() : j * 100}
                chave={d ? d.getDate() : j * 100}
                date={d}
                agendas={this.props.agendas}
                //marcacoes={filterMarcacoes(this.props.marcacoes, d)}
                onClick={this.props.onClick}
                hideTotalMarcacao={this.props.hideTotalMarcacao}
              />
            ))}
          </Row>
        ))}
      </div>
    );
  }
}

function createArrayDate(year: number, month: number) {
  let dateArray = [];
  for (let i = 1; i <= 31; i++) {
    let date = new Date(year, month, i);
    if (date.getMonth() !== month) {
      break;
    }
    dateArray.push(date);
  }
  return dateArray;
}

function createCalendar(year: number, month: number) {
  const datesOfMonth = createArrayDate(year, month);
  let weeks = [];
  const firstDayWeek = datesOfMonth[0].getDay();
  let week = [];
  for (let i = 0; i < firstDayWeek; i++) {
    week.push(null);
  }
  for (let i = 0; i < datesOfMonth.length; i++) {
    if (datesOfMonth[i].getDay() === 0) {
      weeks.push(week);
      week = [];
    }
    week.push(datesOfMonth[i]);
  }
  const valuesToComplete = 7 - week.length;
  for (let i = 0; i < valuesToComplete; i++) {
    week.push(null);
  }
  weeks.push(week);
  return weeks;
}
