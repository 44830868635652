//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IconHistorico from '../../images/icon-hitorico.svg';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { historicoService } from '../../_services/historico.service';

type Props = {
  filtro: any,
};

class Historico extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      listHistorico: [],
    };
  }

  componentDidMount() {
    if (this.props.tipoHistorico === objectsConstants.TIPO_HISTORICO_MARCACAO) {
      this.findHistoricoMarcacao(this.props.idEntityHistorico);
    } else if (
      this.props.tipoHistorico === objectsConstants.TIPO_HISTORICO_PACIENTE
    ) {
      this.findHistoricoPaciente(this.props.idEntityHistorico);
    } else if (
      this.props.tipoHistorico === objectsConstants.TIPO_HISTORICO_MEDICO
    ) {
      this.findHistoricoMedico(this.props.idEntityHistorico);
    } else if (
      this.props.tipoHistorico ===
      objectsConstants.TIPO_HISTORICO_ESTABELECIMENTO_MEDICO
    ) {
      this.findHistoricoEstabelecimentoMedico(this.props.idEntityHistorico);
    }
  }

  findHistoricoMarcacao = (idMarcacao) => {
    historicoService.findHistoricoMarcacao(idMarcacao).then(
      (response) => {
        console.log(response);
        let listHistorico = response.data;
        this.setState({ listHistorico: listHistorico });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  findHistoricoPaciente = (idPaciente) => {
    historicoService.findHistoricoPaciente(idPaciente).then(
      (response) => {
        console.log(response);
        let listHistorico = response.data;
        this.setState({ listHistorico: listHistorico });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  findHistoricoMedico = (idMedico) => {
    historicoService.findHistoricoMedico(idMedico).then(
      (response) => {
        console.log(response);
        let listHistorico = response.data;
        this.setState({ listHistorico: listHistorico });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  findHistoricoEstabelecimentoMedico = (idEstabelecimentoMedico) => {
    historicoService
      .findHistoricoEstabelecimentoMedico(idEstabelecimentoMedico)
      .then(
        (response) => {
          console.log(response);
          let listHistorico = response.data;
          this.setState({ listHistorico: listHistorico });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  fechar = () => {
    this.props.fechaHistorico();
  };

  render() {
    const { listHistorico } = this.state;
    console.log(listHistorico);
    return (
      <div className="historico">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="close-menu">
            <div className="action" onClick={this.fechar}>
              Fechar
            </div>
          </div>
          <div className="titulo-historico d-flex align-items-center">
            <img src={IconHistorico} alt="icone Historico" className="mr-2" />
            <span className="titulo"> Histórico</span>
          </div>
          <div className="m-3"></div>
        </div>

        <div className="pt-3 pb-2">
          <div className="d-flex flex-row align-items-start"></div>
          {_.isEmpty(listHistorico) && (
            <div className="text-center w-100 mt-5 txt-sem-historico">
              Nenhum histórico de alterações.
            </div>
          )}
          <div className="timeline">
            {listHistorico.map(
              (historicoRow, index) =>
                !_.isEmpty(historicoRow) && (
                  <div
                    className="container-historico right"
                    key={index + 'row'}
                  >
                    <div className="content">
                      <p>
                        <b>Data:</b>{' '}
                        {dateHelper.format(
                          _.get(historicoRow, 'dataAlteracao')
                        )}
                      </p>
                      <p>
                        <b>Por:</b> {_.get(historicoRow, 'usuarioAlteracao')}
                      </p>
                      {
                        !_.isEmpty(_.get(historicoRow, 'listaItens')) &&
                          _.get(historicoRow, 'listaItens').map(
                            (item, index) =>
                              !_.isEmpty(item) && (
                                <div
                                  className="itemHistorico"
                                  key={'item' + index}
                                >
                                  <p>
                                    <b>
                                      {_.get(item, 'valueDe') != null
                                        ? 'Alterou '
                                        : 'Incluiu '}
                                      {_.get(item, 'fieldName')}
                                      {_.get(item, 'valueDe') != null
                                        ? ' de: '
                                        : ': '}
                                    </b>
                                    {_.get(item, 'valueDe') == null
                                      ? formatterHelper.formataItemHistorico(
                                          _.get(item, 'valuePara')
                                        )
                                      : formatterHelper.formataItemHistorico(
                                          _.get(item, 'valueDe')
                                        )}
                                  </p>
                                  <p>
                                    <b>
                                      {' '}
                                      {_.get(item, 'valueDe') != null
                                        ? 'para: '
                                        : ''}
                                    </b>{' '}
                                    {_.get(item, 'valueDe') != null
                                      ? formatterHelper.formataItemHistorico(
                                          _.get(item, 'valuePara')
                                        )
                                      : ''}
                                  </p>
                                </div>
                              )
                          ) //map
                      }
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({
  alert: { error, success },
  load: { loading },
  historicomenu: { toggleHistorico, abreHistorico, fechaHistorico },
}) => ({
  error: (message, code) => error({ message, code }),
  success: (message, code) => success({ message, code }),
  loading: (load: boolean) => loading({ load }),
  toggleHistorico: () => toggleHistorico(),
  abreHistorico: () => abreHistorico(),
  fechaHistorico: () => fechaHistorico(),
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  const { idEntityHistorico, tipoHistorico } = state.historicomenu;
  return {
    permissions,
    idEntityHistorico,
    tipoHistorico,
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(Historico));
