//@flow
import { Formik } from 'formik';
import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'reactstrap';
import * as Yup from 'yup';
import FormInput from '../../components/inputs/FormInput';
import CpfInput from '../../components/inputs/CpfInput';
import Toast from '../../components/Toasts/Toast';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import { userService } from '../../_services/user.service';

const EsqueciSenhaValidateModel = Yup.object().shape({
  cpf: Yup.string().required('Campo obrigatório'),
});

type Props = {
  cpf?: string,
};

type State = {
  cpf?: string,
};

class EsqueciSenhaForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let cpf = '';
    this.state = { cpf };
  }

  // handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
  //   const { name, value } = e.currentTarget;
  //   this.setState({ [name]: value });
  // };

  recuperarSenha = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    let _this = this;
    let values = _this.formRef.state.values;
    // if (this.props.agendamentoOnline) {
    this.props.recuperarSenha(values);
    // } else {
    // this.props.recuperarSenha(values.email);
    // }
  };

  render() {
    return (
      <React.Fragment>
        <Toast></Toast>
        <div className="move-up">
          <div className="text-center">
            <h2>
              Para recuperar a sua senha, insira o seu CPF cadastrado abaixo
            </h2>
          </div>
          <hr></hr>
          <Formik
            validationSchema={EsqueciSenhaValidateModel}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            initialValues={this.state}
            ref={(form) => {
              this.formRef = form;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              validationSchema,
              validateForm,
              setValues,
            }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                {/* {!this.props.agendamentoOnline && (
                  <React.Fragment>
                    <Label for="email">E-mail</Label>
                    <FormInput
                      type="email"
                      name="email"
                      id="email"
                      value={values.email}
                      placeholder="Insira o seu melhor E-mail"
                      erroMensagem={errors.email}
                      onChange={setFieldValue}
                    />
                  </React.Fragment>
                )} */}
                {/* {this.props.agendamentoOnline && ( */}
                <React.Fragment>
                  <CpfInput
                    label="CPF"
                    placeholder={'CPF'}
                    id={'cpf'}
                    name="cpf"
                    type={'number'}
                    value={_.get(values, 'cpf')}
                    required={true}
                    onChange={(nome, value) => {
                      setFieldValue('userPerfil', null);
                      setFieldValue(nome, value);
                    }}
                    erroMensagem={_.get(errors, 'cpf')}
                  />
                </React.Fragment>
                {/* )} */}

                {!this.props.agendamentoOnline &&
                  _.get(values, 'cpf') &&
                  values.cpf.length == 14 && (
                    <React.Fragment>
                      <Label for="senha">Selecione o perfil desejado</Label>
                      <FormSelectInput
                        name="userPerfil"
                        id={'userPerfil'}
                        label=""
                        placeholder="Selecione um perfil"
                        valueKey="role.id"
                        labelKey="nomeExibicao"
                        noSize={true}
                        required={false}
                        returnFullObject={true}
                        isMulti={false}
                        searchable={false}
                        allowSelectAll={false}
                        service={userService.findUserRolesByCpf}
                        onChange={setFieldValue}
                        value={values.userPerfil}
                        parent={values.cpf}
                      />
                    </React.Fragment>
                  )}

                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    className="bt-size-fixed mt-4 my-xl-4"
                    block={true}
                    color="primary"
                    type="submit"
                    onClick={this.recuperarSenha}
                  >
                    Enviar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load }),
});
const connectedLoginForm = connect(
  mapStateToProps,
  mapDispatch
)(EsqueciSenhaForm);
export { connectedLoginForm as EsqueciSenhaForm };
