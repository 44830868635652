//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const precificacaoService = {
  doGet,
  doSave,

};



function doGet(id) {
  return defaultService.doGet(`${urlsConstants.PRECIFICACAO}${id}`);
}

function doSave(listPrecificacao: any) {
  return defaultService.doPost(`${urlsConstants.PRECIFICACAO}createOrUpdateAll`, listPrecificacao);
}



