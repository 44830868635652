//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PagamentoPage from './PagamentoPage';

export default class PagamentoIndexPage extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/pagamento" component={PagamentoPage} />
      </Switch>
    );
  }
}
