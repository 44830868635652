//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const planoConvenioService = {

  doSave
};




function doSave(listPlanos,id) {

  return defaultService.doPost(`${urlsConstants.CONVENIO}${id}/atualiza/planosConvenio`,listPlanos);
}