//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { dateHelper } from '../../_helpers/date.helper';
import ArrowLeft from '../../images/icones/arrow-left.svg';
import { getAgendasDay } from './../../_helpers/agenda.helper';

export class CalendarDateView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  getMaxMarcaoDia(agendas: HorariosConsulta, date?: any) {
    if (!agendas || !agendas.length || !date) {
      return 0;
    }
    let agendaDay = getAgendasDay(agendas, date);
    return agendaDay.length;
  }

  arrowClick = (tipo: string, agendas: any, date: date) => {
    var newDate = _.cloneDeep(date);
    let newMaxMarcacoes;
    if (tipo === 'anterior') {
      newDate.setDate(newDate.getDate() - 1);
    } else if (tipo === 'proximo') {
      newDate.setDate(newDate.getDate() + 1);
    }
    newMaxMarcacoes = this.getMaxMarcaoDia(agendas, newDate);

    if (newDate.getMonth() !== date.getMonth()) {
      this.props.onMonthChange(newDate.getFullYear(), newDate.getMonth());
    }
    this.props.arrowClick(newDate, newMaxMarcacoes);
  };

  render() {
    const { data, agendas } = this.props;

    let monthNameShort = data
      ? data.toLocaleString('pt-br', { month: 'long' })
      : '';
    let fullYear = data ? data.getFullYear() : '';
    this.state = { monthNameShort };
    let diaSemana = dateHelper.getDiaSemana(data);

    return (
      <React.Fragment>
        <div
          className={`calendar-date view d-flex align-items-center ${
            this.props.arrowNavigation
              ? 'justify-content-between'
              : 'justify-content-around'
          }`}
        >
          <div
            className={`prev ml-1 ${
              this.props.arrowNavigation ? '' : 'd-none'
            } `}
            onClick={() => {
              this.arrowClick('anterior', agendas, data);
            }}
          >
            <img src={ArrowLeft} alt="voltar" className="arrows" />
          </div>
          <div className="d-flex flex-row flex-lg-column justify-content-center">
            <div className="font-date big">{data && data.getDate()}</div>

            <div className="d-flex justify-content-center align-items-start align-items-lg-center flex-column">
              <p className={`nome-mes`}>{monthNameShort}</p>
              <p className={`diaSemana`}>{diaSemana}</p>
              <p className={`ano`}>{fullYear}</p>
            </div>
          </div>
          <div
            className={`next mr-1 ${
              this.props.arrowNavigation ? '' : 'd-none'
            } `}
            onClick={() => {
              this.arrowClick('proximo', agendas, data);
            }}
          >
            <img src={ArrowLeft} alt="voltar" className="arrows rotate" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
