import { Can } from "@casl/react";
import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import packageJson from '../../../package.json';
import btnVoltar from "../../images/btn_voltar.svg";
import Exames from '../../images/ico-exames.svg';
import IconPagamento from '../../images/icon-payment.svg';
import Icon_relatorios from "../../images/icon-relatorios.svg";
import IconParametrizacao from "../../images/icon-parametrizacao.png";
import IconParamTriagem from "../../images/icon-param-triagem.png";
import IconAgendamentoRapido from "../../images/icon-agendamento-rapido.png";
import IconParamGeral from "../../images/icon-fast.png";
import Icon_relatorios_consulta from "../../images/icon-rel-consulta.png";
import Icon_relatorios_exame from "../../images/icon-rel-exame.png";
import IconDashFinancial from "../../images/icon-dashboard-financial.png";
import IconModeloDocumento from "../../images/icon-modelos-documentos.png";
import IconModeloAnamnese from "../../images/icon-modelo-anamnese.png";
import IconModeloReceituario from "../../images/icon-modelo-receituario.png";

import Agenda from "../../images/icones/calendar.svg";
import Paciente from '../../images/icones/clipboard.svg';
import Estabelecimento from '../../images/icones/map-pin.svg';
import Modelos from '../../images/icones/modelos.svg';
import Medicos from '../../images/icones/profissionais.svg';
import Usuarios from '../../images/icones/usuarios.svg';
import IconVoucher from '../../images/icon-voucher.png';
import DashBoardIcon from '../../images/icon-dashboard.png';
import IconTriagem from '../../images/icones/icon-triagem.svg';
import ConvenioIcon from '../../images/id-card-svgrepo-com.svg';
import Integracao from '../../images/integracao.svg';
import GestaoEstoque from '../../images/icon-gestao-estoque.png';
import EstoqueProdutos from '../../images/icon-estoque-produtos.png';
import EstoqueEntrada from '../../images/icon-estoque-entrada.png';
import EstoqueSaida from '../../images/icon-estoque-saida.png';
import EstoqueRelMovimentacao from '../../images/icon-estoque-rel-movimentacao.png';
import EstoqueRelPosicao from '../../images/icon-estoque-rel-posicao.png';
import EstoqueAjuste from '../../images/icon-estoque-ajuste.png';

import IntegracaoSasc from '../../images/icon-integracao-sasc.png';
import IntegracaoConnect from '../../images/icon-integracao-connect.png';
import Logo from '../../images/logosasccm.png';
import { objectsConstants } from "../../_constants/objects.constants";
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import { userService } from "../../_services/user.service";
import Avatar from "../Avatar/Avatar";
import FormSelectInput from "../inputs/FormSelectInput";
import IconPackage from '../../images/icon_package.svg';
import { Client } from 'webstomp-client';

class Menu extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      listaPerfis: [],
      estabelecimento: {},
      secondLevel: false,
      secondPageMenu: null
    }
    this.carregaPerfis();
    this.getEstabelecimento(this.props.user.role.extension);



  }


  carregaPerfis = () => {
    userService.findUserRolesByCpf(this.props.user.cpfCnpj).then((response) => {
      this.setState({ listaPerfis: response.data }, () => {
      });

    },
      (error) => {
        this.props.loading(false);
      }
    )
  }

  abrePerfil() {
    let user = this.props.user;
    this.goToPage(`/perfil/${user.id}`);
  }

  goToPage(page) {
    this.props.fechaMenu();
    this.props.history.push(page);
  }
  goToSecondLevel(page) {
    this.setState({ secondLevel: true, secondPageMenu: page }, () => {
    });
  }

  getInfoSecundaria = (usuario = this.props.user) => {
    if (usuario.crm) {
      return 'CRM - ' + usuario.crm;
    } else {
      return userService.getPerfilAtual(this.props.user)
    }
  };

  handleLogout = () => {
    this.props.clearCache();
    this.props.fechaMenu();
    this.props.logout();
    if (this.props.client) {
      this.props.client.disconnect();
      this.props.disconectSocket();
    }

  };

  setMenuPrincipal = () => {
    this.setState({ secondLevel: false }, () => {
    });
  }

  abrePerfil() {
    let user = this.props.user;
    this.goToPage(`/perfil/${user.id}`);
  }

  abreTarefas = () => {
    this.props.fechaMenu()
    this.props.history.push('/tarefas');
  };

  changePerfil = (userRole) => {
    userService.changePerfil(this.props.user.cpfCnpj, userRole.id).then((response) => {

      if (response) {
        this.props.updateAll();
      }

    },
      (error) => {
        this.props.loading(false);
      }
    )
  }

  updateUser = () => {
    userService.me().then(
      (user) => {
        this.props.updateAll();
        window.location.reload(false);
      },
      (error) => {

      }
    );
  }

  changeEstabelecimento = (estabelecimento) => {
    userService.changeEstabelecimento(estabelecimento.id).then((response) => {
      if (response) {
        this.getEstabelecimento(estabelecimento.id)
        this.updateUser();
      }

    },
      (error) => {
        this.props.loading(false);
      }
    )
  }

  getEstabelecimento = (extension) => {
    estabelecimentoService.doGet(extension).then((response) => {
      this.setState({ estabelecimento: response.data.data });
    })
  }



  render() {
    const {
      menuAberto,
      children, permissions, usuario, mainTitle } = this.props;
    return (
      <div id="menuContent" className={`d-flex flex-column fixed-top bg-riscos background-menu  ${menuAberto ? "move-in" : "move-out"}`}
      >
        <header className="navbar fixed-top topo iconMenu px-4">

          <div className="btnVoltar bd-highlight pl-3"
            onClick={() => {
              this.props.fechaMenu();
            }}
          >
            <img src={btnVoltar} alt="Voltar" className="backMenu" />
          </div>
          <div className={"bd-highlight"} >
            <img src={Logo} alt="Logo" className="iconLogo" />
          </div>
          <div className="App">
            <small>Versão: {packageJson.version}</small>
          </div>

        </header>

        <div className="bodyMenu d-flex flex-column h-100 mx-sm-auto" id="bodyMenu" >

          <div className="container-box">
            {/* <div className="row select-menu">
              <FormSelectInput
                name="userPerfil"
                id={'userPerfil'}
                label=""
                size={12}
                placeholder="Selecione um perfil"
                valueKey="userRoleId"
                labelKey="nomeExibicao"
                noSize={true}
                required={false}
                returnFullObject={true}
                isMulti={false}
                searchable={false}
                allowSelectAll={false}
                service={userService.findUserRolesByCpf}
                options={this.state.listaPerfis}
                onChange={(name, value) => {
                  this.changePerfil(value);
                }}
                value={this.props.user.role.role}
                parent={this.props.user.cpfCnpj}
              />
            </div> */}

            {userService.isSuporte() && (
              <div className="row select-menu">
                <FormSelectInput
                  name="estabelecimento"
                  id="estabelecimento"
                  size={12}
                  placeholder="Selecione um estabelecimento"
                  noSize={true}
                  returnFullObject={true}
                  valueKey={'id'}
                  labelKey={'nome'}
                  multi={false}
                  service={estabelecimentoService.findAllSuporte}
                  onChange={(name, value) => {
                    this.changeEstabelecimento(value);
                  }}
                  value={this.state.estabelecimento}
                  defaultValue={this.state.estabelecimento.nome}

                />
              </div>
            )}

            <div className="box-perfil">
              <div className="box row menu-user" >

                <div className="col-sm-12 col-3 p-0 justify-content-center">
                  <h2 className="roboto text-center font-weight-bold">
                    Bem vindo(a)
                  </h2>
                </div>

                <div className="col-sm-12 col-4 p-0 align-self-center" onClick={() => { this.abrePerfil() }}>
                  <Avatar className="avatarMenu" user={this.props.user} ></Avatar>
                </div>

                <div className="col-sm-12 px-0 col-5 d-flex flex-column justify-content-between ">
                  <p className="my-1" onClick={() => { this.abrePerfil() }}>
                    <b className="nomeProprioMenu">{this.props.user.nome}</b>
                  </p>
                  <div className="d-flex flex-column justify-content-center txt-secondary">
                    <p>{_.get(this.props.user, 'role.role.description')}</p>

                    {/*
                    <FormSelectInput
                      name="userPerfil"
                      id={'userPerfil'}
                      label=""
                      size={12}
                      placeholder="Selecione um perfil"
                      valueKey="userRoleId"
                      labelKey="nomeExibicao"
                      noSize={true}
                      required={false}
                      returnFullObject={true}
                      isMulti={false}
                      searchable={false}
                      allowSelectAll={false}
                      service={userService.findUserRolesByCpf}
                      options={this.state.listaPerfis}
                      onChange={(name, value) => {
                        this.changePerfil(value);
                      }}
                      value={this.props.user.role.role}
                      parent={this.props.user.cpfCnpj}
                    /> */}
                    {/* <FormSelectInput
                      name="role"
                      id={'role'}
                      label=""
                      size={12}
                      placeholder="Selecione um perfil para Login"
                      valueKey="id"
                      labelKey="description"
                      noSize={true}
                      required={false}
                      returnFullObject={true}

                      isMulti={false}
                      searchable={false}
                      allowSelectAll={false}
                      options={this.state.listaPerfis}
                      onChange={(name, value) => {
                        this.changePerfil(value);
                      }}
                      value={this.props.user.role.role}
                      parent={this.props.user.cpfCnpj}
                    /> */}
                    {/* )} */}
                  </div>
                </div>

              </div>


              {!this.state.secondLevel && (
                <div className="row d-flex align-items-center justify-content-center p-sm-3 p-1">
                  <button
                    onClick={this.handleLogout}
                    className="d-flex align-items-center justify-content-start button-logout"
                  >
                    <div className=" header-icon icon-sair"></div>
                    <span className="txt-header red"> Sair do sistema</span>
                  </button>
                </div>

              )}
              {this.state.secondLevel && (
                <div className="row d-flex align-items-center justify-content-center p-sm-3 p-1">
                  <button
                    onClick={this.setMenuPrincipal}
                    className="d-flex align-items-center justify-content-start button-logout"
                  >
                    <div className="header-icon back-icon"></div>
                    <span className="txt-header"> Menu Principal</span>
                  </button>
                </div>
              )}
            </div>
            {!this.state.secondLevel && (
              <>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_CODE4} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_EMPRESAS ? "selecionado" : ""}`} onClick={() => this.goToPage('/empresa')}>
                    <img src={Estabelecimento} className="icon-menu" alt="Empresa" />
                    <p>Empresa</p>
                  </div>
                </Can>

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_AGENDA} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_AGENDA || mainTitle == objectsConstants.TITULO_PAGINA_AGENDA_EXAMES ? "selecionado" : ""}`} onClick={() => this.goToPage('/agenda')}>
                    <img src={Agenda} className="icon-menu" alt="Agenda" />
                    <p>Agenda</p>
                  </div>
                </Can>

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_PACIENTE} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_PACIENTES ? "selecionado" : ""}`} onClick={() => this.goToPage('/paciente')}>
                    <img src={Paciente} className="icon-menu" alt="Pacientes" />
                    <p>Pacientes</p>
                  </div>
                </Can >

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_MEDICO} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_PROFISSIONAIS ? "selecionado" : ""}`} onClick={() => this.goToPage('/medico')}>
                    <img src={Medicos} className="icon-menu" alt="Profissionais de saúde" />
                    <p>Profissionais</p>
                  </div>
                </Can >

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_USUARIO} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_USUARIOS ? "selecionado" : ""}`} onClick={() => this.goToPage('/usuario')}>
                    <img src={Usuarios} className="icon-menu" alt="Usuários do sistema" />
                    <p>Usuários do sistema</p>
                  </div>
                </Can >

                <Can I={objectsConstants.VIEW} a={objectsConstants.CRUD_ESTABELECIMENTOS} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_ESTABELECIMENTO ? "selecionado" : ""}`} onClick={() => this.goToPage('/estabelecimento')}>
                    <img src={Estabelecimento} className="icon-menu" alt="Estabelecimento" />
                    <p>Estabelecimento</p>
                  </div>
                </Can>


                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_EXAME} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_EXAMES ? "selecionado" : ""}`} onClick={() => this.goToPage('/exame')}>
                    <img src={Exames} className="icon-menu" alt="Exames" />
                    <p>Exames</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_MODELO} ability={permissions}>
                  <div className={`box sub-menu ${mainTitle == objectsConstants.TITULO_PAGINA_MODELOS ? "selecionado" : ""}`} onClick={() => this.goToSecondLevel(objectsConstants.MENU_MODELO)}>
                    <img src={Modelos} className="icon-menu" alt="Modelos de documentos" />
                    <p>Modelos de documentos</p>
                  </div>
                </Can >


                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_RELATORIOS} ability={permissions}>
                  <div className={`box sub-menu ${mainTitle == objectsConstants.TITULO_PAGINA_RELATORIOS_CONSULTA ? "selecionado" : ""}`} onClick={() => this.goToSecondLevel(objectsConstants.MENU_RELATORIOS)} >
                    <img src={Icon_relatorios} className="icon-menu" alt="Relatórios" />
                    <p>Relatórios</p>
                  </div >
                </Can>

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_INTEGRACAO} ability={permissions}>
                  <div className={`box sub-menu ${mainTitle == objectsConstants.TITULO_PAGINA_INTEGRACAO_SASCCONNECT ? "selecionado" : ""}`} onClick={() => this.goToSecondLevel(objectsConstants.MENU_INTEGRACAO)}>
                    <img src={Integracao} className="icon-menu" alt="Integrações" />
                    <p>Integrações</p>
                  </div>
                </Can>


                <Can I={objectsConstants.VIEW} a={objectsConstants.CRUD_ESTABELECIMENTOS} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_PAGAMENTO ? "selecionado" : ""}`} onClick={() => this.goToPage('/pagamento')}>
                    <img src={IconPagamento} className="icon-menu" alt="Pagamento" />
                    <p>Dados de pagamento</p>
                  </div>
                </Can>

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_VOUCHER} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_VOUCHER_SOLUTI ? "selecionado" : ""}`} onClick={() => this.goToPage('/voucher-soluti')}>
                    <img src={IconVoucher} className="icon-menu" alt="Voucher Soluti" />
                    <p>Voucher Soluti</p>
                  </div>
                </Can>

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_DASHBOARD} ability={permissions}>
                  <div className={`box sub-menu ${mainTitle == objectsConstants.TITULO_DASHBOARD ? "selecionado" : ""}`} onClick={() => this.goToSecondLevel(objectsConstants.MENU_DASHBOARD)}>
                    <img src={DashBoardIcon} className="icon-menu" alt="Dashboard" />
                    <p>Dashboard</p>
                  </div>
                </Can>
                {/* <Can I={objectsConstants.VIEW} a={objectsConstants.CRUD_RELATORIOS} ability={permissions}>   
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_PACOTES ? "selecionado" : ""}`} onClick={() => this.goToPage('/pacotes')}>
                    <img src={IconPackage} className="icon-menu" alt="Pacotes" />
                    <p>Pacote</p>
                  </div>
                </Can> */}
                {/* <Can I={objectsConstants.VIEW} a={objectsConstants.CRUD_ESTABELECIMENTOS} ability={permissions}> 
                  <div className={`box ${mainTitle == objectsConstants.TITULO_CONVENIO ? "selecionado" : ""}`} onClick={() => this.goToPage('/convenio')}>
                    <img src={ConvenioIcon} className="icon-menu" alt="Convênio" /> 
                    <p>Convênio</p>
                  </div>
                </Can> */}

                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_INTEGRACAO} ability={permissions}>     {/* Mudar objectConstants.CRUD */}
                  <div className={`box ${mainTitle == objectsConstants.TITULO_AGENDAMENTO_RAPIDO ? "selecionado" : ""}`} onClick={() => this.goToPage('/agendamento-rapido')}>     {/* Mudar objectConstants.TITULO */}
                    <img src={IconAgendamentoRapido} className="icon-menu" alt="Agendamento Rápido" />  {/* Mudar icone */}
                    <p>Agendamento Rápido</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_GESTAO_ESTOQUE ? "selecionado" : ""}`} onClick={() => this.goToSecondLevel(objectsConstants.MENU_ESTOQUE)}>
                    <img src={GestaoEstoque} className="icon-menu" alt="Gestão de Estoque" />
                    <p>Gestão de estoque</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_PARAMETRIZACAO} ability={permissions}>     {/* Mudar objectConstants.CRUD */}
                  <div className={`box sub-menu `} onClick={() => this.goToSecondLevel(objectsConstants.MENU_PARAMETRIZACAO)}>     {/* Mudar objectConstants.TITULO */}
                    <img src={IconParametrizacao} className="icon-menu" alt="Parametrização" />  {/* Mudar icone */}
                    <p>Parametrização</p>
                  </div>
                </Can>
              </>
            )}
            {/* Sub-menu DASHBOARD*/}
            {this.state.secondLevel && this.state.secondPageMenu === objectsConstants.MENU_DASHBOARD && (
              <>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_DASHBOARD} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_DASHBOARD ? "selecionado" : ""}`} onClick={() => this.goToPage('/dashboard')}>
                    <img src={DashBoardIcon} className="icon-menu" alt="Dashboard" />
                    <p>Dashboard Geral</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.CRUD_RELATORIOS} ability={permissions}>     {/* Mudar objectConstants.CRUD */}
                  <div className={`box ${mainTitle == objectsConstants.TITULO_DASHBORAD_FINANCEIRO ? "selecionado" : ""}`} onClick={() => this.goToPage('/dashboard-financeiro')}>     {/* Mudar objectConstants.TITULO */}
                    <img src={IconDashFinancial} className="icon-menu " alt="Dashboard Financeiro" />  {/* Mudar icone */}
                    <p>Dashboard Financeiro</p>
                  </div>
                </Can>
              </>
            )}
            {/* menu GESTÃO DE ESTOQUE */}
            {
              this.state.secondLevel && this.state.secondPageMenu === objectsConstants.MENU_ESTOQUE && (
                <>
                  <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                    <div className={`box ${mainTitle == objectsConstants.TITULO_ESTOQUE_PRODUTOS ? "selecionado" : ""}`} onClick={() => this.goToPage('/estoque-produtos')}>
                      <img src={EstoqueProdutos} className="icon-menu" alt="Cadastro de produtos" />
                      <p>Cadastro de produtos</p>
                    </div>
                  </Can>
                  <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                    <div className={`box ${mainTitle == objectsConstants.TITULO_ESTOQUE_ENTRADA ? "selecionado" : ""}`} onClick={() => this.goToPage('/estoque-entrada')}>
                      <img src={EstoqueEntrada} className="icon-menu" alt="Entradas de estoque" />
                      <p>Entradas de estoque</p>
                    </div>
                  </Can>
                  <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                    <div className={`box ${mainTitle == objectsConstants.TITULO_ESTOQUE_SAIDA ? "selecionado" : ""}`} onClick={() => this.goToPage('/estoque-saida')}>
                      <img src={EstoqueSaida} className="icon-menu" alt="Saídas de estoque" />
                      <p>Saídas de estoque</p>
                    </div>
                  </Can>
                  <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                    <div className={`box ${mainTitle == objectsConstants.TITULO_ESTOQUE_REL_MOVIMENTACAO ? "selecionado" : ""}`} onClick={() => this.goToPage('/estoque-relatorio-movimentacao')}>
                      <img src={EstoqueRelMovimentacao} className="icon-menu" alt="Relatório de movimentação de estoque" />
                      <p>Relatório de movimentação de estoque</p>
                    </div>
                  </Can>
                  <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                    <div className={`box ${mainTitle == objectsConstants.TITULO_ESTOQUE_REL_POSICAO ? "selecionado" : ""}`} onClick={() => this.goToPage('/estoque-relatorio-posicao')}>
                      <img src={EstoqueRelPosicao} className="icon-menu" alt="Relatório de posição de estoque" />
                      <p>Relatório de posição de estoque</p>
                    </div>
                  </Can>
                  {/* <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_ESTOQUE} ability={permissions}>
                    <div className={`box ${mainTitle == objectsConstants.TITULO_ESTOQUE_AJUSTE ? "selecionado" : ""}`} onClick={() => this.goToPage('/estoque-ajuste')}>
                      <img src={EstoqueRelPosicao} className="icon-menu" alt="Ajuste de estoque" />
                      <p>Ajuste de estoque</p>
                    </div></Can> */}
                </>
              )}
            {this.state.secondLevel && this.state.secondPageMenu === objectsConstants.MENU_PARAMETRIZACAO && (
              <>
                {/* <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_PACIENTE} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PARAMETRIZACAO_GERAL ? "selecionado" : ""}`} onClick={() => this.goToPage('/parametrizacao/geral')}>
                    <img src={IconParamGeral} className="icon-menu" alt="Parametrizar geral" />
                    <p>Geral</p>
                  </div>
                </Can> */}
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_PARAMETRIZACAO_TRIAGEM} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PARAMETRIZACAO_TRIAGEM ? "selecionado" : ""}`} onClick={() => this.goToPage('/parametrizacao/triagem')}>
                    <img src={IconParamTriagem} className="icon-menu" alt="Parametrizar triagem" />
                    <p>Triagem</p>
                  </div>
                </Can>
              </>
            )}
            {/* FIM Sub-menu DASHBOARD */}

            {/* Sub-menu INTEGRACAO*/}
            {this.state.secondLevel && this.state.secondPageMenu === objectsConstants.MENU_INTEGRACAO && (
              <>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_INTEGRACAO_CONNECT} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_INTEGRACAO_SASCCONNECT ? "selecionado" : ""}`} onClick={() => this.goToPage('/integracao/sascconnect')}>
                    <img src={IntegracaoConnect} className="icon-menu" alt="Integração das Guias SascConnect" />
                    <p>Integração SASC Connect</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_INTEGRACAO_SASC} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_INTEGRACAO_SASC ? "selecionado" : ""}`} onClick={() => this.goToPage('/integracao/sasc')}>
                    <img src={IntegracaoSasc} className="icon-menu " alt="Integração SASC" />
                    <p>Integração SASC</p>
                  </div>
                </Can>
              </>
            )}
            {/* FIM Sub-menu INTEGRACAO */}

            {/* menu MODELO DOCUMENTO*/}
            {this.state.secondLevel && this.state.secondPageMenu === objectsConstants.MENU_MODELO && (
              <>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_MODELO} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_MODELOS ? "selecionado" : ""}`} onClick={() => this.goToPage('/modelo')}>
                    <img src={IconModeloDocumento} className="icon-menu" alt="Modelos de documentos" />
                    <p>Modelos de documentos</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_MODELO_ANAMNESE} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_MODELOS_ANAMNESE ? "selecionado" : ""}`} onClick={() => this.goToPage('/modelo-anamnese')}>
                    <img src={IconModeloAnamnese} className="icon-menu" alt="Modelos de anamnese" />
                    <p>Modelos de anamnese</p>
                  </div>
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_MODELO_RECEITUARIO} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_MODELOS_RECEITUARIO ? "selecionado" : ""}`} onClick={() => this.goToPage('/modelo-receituario')}>
                    <img src={IconModeloReceituario} className="icon-menu" alt="Modelos de receituário" />
                    <p>Modelos de receituário</p>
                  </div>
                </Can>
              </>
            )}
            {/* FIM MENU MODELO DOCUMENTO */}
            {/* menu RELATORIO*/}
            {this.state.secondLevel && this.state.secondPageMenu === objectsConstants.MENU_RELATORIOS && (
              <>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_RELATORIOS} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_RELATORIOS_CONSULTA ? "selecionado" : ""}`} onClick={() => this.goToPage('/relatorios')} >
                    <img src={Icon_relatorios_consulta} className="icon-menu" alt="Relatórios consultas" />
                    <p>Relatórios consultas</p>
                  </div >
                </Can>
                <Can I={objectsConstants.VIEW} a={objectsConstants.MENU_RELATORIO_EXAME} ability={permissions}>
                  <div className={`box ${mainTitle == objectsConstants.TITULO_PAGINA_RELATORIOS_EXAMES ? "selecionado" : ""}`} onClick={() => this.goToPage('/relatorios-exames')} >
                    <img src={Icon_relatorios_exame} className="icon-menu" alt="Relatórios exames" />
                    <p>Relatórios exames</p>
                  </div >
                </Can>

              </>
            )}
            {/* FIM MENU MODELO DOCUMENTO */}
          </div >
          <hr className="max-hr"></hr>
        </div >

        <footer>
          <p>© 2023 Sasc CM</p>
        </footer>
      </div >
    );
  }
}
const mapDispatch = ({
  agenda: { clearCache },
  alert: { error, warning },
  load: { loading },
  authentication: { logout, login, refreshUser },
  sidemenu: { toggleMenu, componenteMenu, abreMenu, fechaMenu },
  webSocket: { disconectSocket },
}) => ({
  clearCache: () => clearCache(),
  logout: () => logout(),
  login: (user, password, loginOnline, perfil) =>
    login({ user, password, loginOnline, perfil }),
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu(),
  fechaMenu: () => fechaMenu(),
  warning: (msg) => warning(msg),
  refreshUser: (user) => refreshUser({ user }),
  disconectSocket
});

function mapStateToProps(state) {
  const { permissions, user, selectPerfil, morePerfil } = state.authentication;
  const { menuAberto } = state.sidemenu;
  const { mainTitle } = state.pageTitle;
  const { client } = state.webSocket;
  return {
    permissions,
    menuAberto,
    user,
    selectPerfil,
    morePerfil,
    mainTitle,
    client
  };
}



export default connect(mapStateToProps, mapDispatch)(withRouter(Menu));
