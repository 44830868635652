//@flow
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import './AnamneseSintetico.scss';
import { dateHelper } from '../../_helpers/date.helper';
import LinhaReceituarioItemSintetico from './components/LinhaReceituarioItemSintetico';
import { replace } from 'lodash';

type Props = {
  entity: any,
  data: date,
};

class AnamneseSintetico extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showReceituario: false,
    };
  }

  componentDidMount() {
    const { atendimentoEmAtendimento, entity } = this.props;
    if (atendimentoEmAtendimento && atendimentoEmAtendimento.telemedicina) {
      this.abreSalaTeleAtendimento(atendimentoEmAtendimento);
    }
  }

  exibeReceituarioItem = (receituarioItem) => {
    if (receituarioItem.type === objectsConstants.TIPO_MEDICAMENTO) {
      return (
        <LinhaReceituarioItemSintetico
          nome={receituarioItem.medicamento.nome}
          descricao={receituarioItem.posologia.descricao}
        />
      );
    } else if (receituarioItem.type === objectsConstants.TIPO_FORMULA) {
      return (
        //{receituarioItem.formula} type: 'formula'
        <LinhaReceituarioItemSintetico
          nome={receituarioItem.formula}
          descricao={receituarioItem.posologia.descricao}
        />
      );
    } else if (receituarioItem.type === objectsConstants.TIPO_EXAMES_OUTROS) {
      return (
        //{receituarioItem.prescricao} type: 'examesOutros'
        <LinhaReceituarioItemSintetico nome={receituarioItem.prescricao} />
      );
    }
    return '';
  };

  render() {
    const { entity, data, ...otherProps } = this.props;
    let initValues = _.cloneDeep(entity);
    if (
      this.state.documentosGerados &&
      this.state.documentosGerados.length > 0
    ) {
      if (initValues.listAnexos) {
        initValues.listAnexos.push(this.state.documentosGerados);
      } else {
        initValues.listAnexos = this.state.documentosGerados;
      }
    }
    if (!initValues.anamnese) {
      initValues.anamnese = '';
    }

    let prontuarioImportado = false;
    if (
      (this.state.marcacao && this.state.marcacao.estabelecimento) ||
      (this.props.atendimentoEmAtendimento &&
        this.props.atendimentoEmAtendimento.estabelecimento)
    ) {
      prontuarioImportado = this.state.marcacao
        ? this.state.marcacao.estabelecimento.prontuarioImportado
        : this.props.atendimentoEmAtendimento.estabelecimento
            .prontuarioImportado;
    }
    const { showReceituario, showMeetConfrence } = this.state;

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle={_.get(entity.paciente, 'nome')} />

        <div
          id={this.props.id}
          className="prontuario-box-sintetico d-flex flex-column align-items-center"
        >
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <div className="font-date">
              {dateHelper.format(data, {
                mode: 'DATE',
              })}
            </div>
            <p className="m-0 p-0 font-professional">
              {_.get(entity, 'medico.nome')}
            </p>
          </div>

          <div className="d-flex flex-row w-100 flex-column flex-lg-row box-folhas justify-content-around mt-2 mb-3">
            <div className="d-flex flex-column container-sintetico descricao-anamnese">
              <p
                id="anamnese-sintetico"
                dangerouslySetInnerHTML={{
                  __html: _.get(entity, 'anamnese', ''),
                }}
              ></p>
            </div>

            <div className="d-flex flex-column container-sintetico descricao-receituario">
              <p className="p-0">Receituario: </p>
              {entity.receituario &&
                entity.receituario.listReceituarioItem.map(
                  (receituarioItem) => {
                    return this.exibeReceituarioItem(receituarioItem);
                  }
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  anamnese: { setTelemedicina },
  alert: { success, error, clear },
  load: { loading },
}) => ({
  setTelemedicina: (options) => setTelemedicina(options),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { showTelemedicina, nomeSala } = state.anamnese;
  return {
    user,
    showTelemedicina,
    nomeSala,
  };
}
export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(AnamneseSintetico));
