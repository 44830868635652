import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { triagemService } from '../../_services/triagem.service';
import FormTextArea from '../../components/inputs/FormTextArea';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import CabecalhoPaciente from '../prontuario/new/CabecalhoPaciente';
import './Triagem.css';
import TriagemClassificacaoDor from './TriagemClassificacaoDor';
import TriagemClassificacaoInputs from './TriagemClassificacaoInputs';

export default class TriagemPage extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = {
      listParamTriagem: [],
      paciente: {
        nome: 'Leonardo Bernardino',
        dataNascimento: '22/07/1988',
        email: 'leonardo.abernardino@gmail.com',
        telefones: [{ numero: '97178-3833' }],
      },
      entity: {
        tempCorporal: '',
        relato: '',
        freqCardiaca: '',
        freqResp: '',
        glicemia: '',
        sp02: '',
        peso: '',
        altura: '',
        imc: '',
      },
    };
  }

  componentDidMount() {}

  saveForm = () => {
    let _this = this;

    let values = _this.formRef.state.values;
    console.log(values);
    let promise = new Promise(function (resolve) {
      if (_.isEmpty(values, 'cpfCnpj') || _.get(values, 'cpfCnpj') === '') {
        values = _.omit(values, 'cpfCnpj');
      }
      if (_.isEmpty(values, 'email') || _.get(values, 'email') === '') {
        values = _.omit(values, 'email');
      }
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          triagemService.save(values).then(
            (response) => {
              _this.props.success({
                message: `Triagem foi salva com sucesso!`,
              });
              _this.props.loading(false);
              _this.props.callbackOnSave();
            },
            (errors) => {
              _this.props.error({
                message: 'Não foi possível salvar a triagem .',
              });
              _this.props.loading(false);
            }
          );
        } else {
          resolve(false);
          _this.props.error({
            message: 'Preencha os campos obrigatórios.',
          });
        }
      });
    });
    return promise;
  };
  render() {
    let initValues = this.props.triagem
      ? this.props.triagem
      : { marcacao: { id: _.get(this.props, 'entity.idMarcacao') } };
    let { viewMode } = this.props;
    console.log(initValues);
    return (
      <React.Fragment>
        <Formik
          validationSchema={''}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={initValues}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldError,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <div>
              {' '}
              {console.log(values)}{' '}
              <div>
                <CabecalhoPaciente paciente={this.props.paciente} />
              </div>
              <div className="conteiner-triagem">
                <div className="text-center">
                  <h1>Classificação de Risco</h1>
                </div>
                <div className="text-right">
                  {/*<Button className="bnt" > Chamar Paciente</Button>
          <Button className="bnt"> Evasão</Button>*/}
                </div>

                <TriagemClassificacaoInputs
                  viewMode={viewMode}
                  entity={values}
                  setFieldValue={setFieldValue}
                />
                <TriagemClassificacaoDor
                  viewMode={viewMode}
                  listParamTriagem={this.props.listParamTriagem}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <div>
                  {!viewMode && (
                    <InputViewEdit
                      component={FormTextArea}
                      className={'textArea relato-text-area'}
                      label="Relato"
                      name="relato"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                      }}
                      viewMode={viewMode}
                      viewClassName={'mt-2'}
                      placeholder="Insira aqui o relato do paciente"
                      required={true}
                      values={values.relato}
                    />
                  )}
                  {console.log(values)}
                  {viewMode && (
                    <div>
                      <label>Relato</label>
                      <p>{values.relato}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
