//@flow
import * as React from 'react';
import { NewButton } from '../buttons/NewButton';
import ExportExcelButton from '../buttons/ExportExcelButton';
import { defaultService } from '../../_services/defaultService';
import { Can } from '@casl/react';
import { objectsConstants } from '../../_constants/objects.constants';
import { connect } from 'react-redux';
let download = require('downloadjs');

type Props = {
  title: ?string,
  labelNewButton: ?string,
  showNewButton: boolean,
  actionClick: Function | string,
};

class HeaderList extends React.Component<Props, State> {
  // constructor(props: any) {
  //   super(props);
  //   this.state = { hideBtnSearch: false };
  // }

  // handleClick = () => {
  //   this.props.loading(true);
  //   console.log();
  //   defaultService
  //     .exportToExcel(
  //       this.props.controller,
  //       this.props.filter,
  //       this.props.fields,
  //       this.props.labels
  //     )
  //     .then(
  //       response => {
  //         download(
  //           response.data,
  //           (this.props.downloadExcelName
  //             ? this.props.downloadExcelName
  //             : this.props.controller) + '.csv',
  //           'text/html'
  //         );
  //         this.props.loading(false);
  //       },
  //       () => {
  //         this.props.loading(false);
  //         this.props.error('Não foi possível exportar arquivo.');
  //       }
  //     );
  // };
  getBtn = () => {
    const { permissions } = this.props;
    if (this.props.showNewButton && this.props.roleNewButton) {
      return (
        <Can
          I={objectsConstants.VIEW}
          a={this.props.roleNewButton}
          ability={permissions}
        >
          <NewButton
            handleClick={this.props.actionClick}
            label={this.props.labelNewButton}
          />
        </Can>
      );
    } else if (this.props.showNewButton) {
      return (
        <NewButton
          handleClick={this.props.actionClick}
          label={this.props.labelNewButton}
        />
      );
    }
  };

  collapse = () => {
    // this.setState({
    //   hideBtnSearch: !this.state.hideBtnSearch
    // });
    this.props.toogleSearch();
  };
  render() {
    const { TitleComponente, hideExport } = this.props;
    return (
      <div
        className={
          this.props.formFilter ? 'topo-listagem d-flex flex-column ' : ''
        }
      >
        {/* {!TitleComponente && <h2>{this.props.title}</h2>}
        {TitleComponente && <TitleComponente />} */}
        <div className="boxButton d-flex justify-content-center">
          {this.props.formFilter && (
            <>
              {!this.props.isOpen && (
                <div className="d-flex align-items-center">
                  {/* <div className="icone-lupa" onClick={this.collapse}></div> */}
                  <button
                    className="btn btn-secondary open search"
                    onClick={this.collapse}
                  >
                    <span className="icone-lupa"></span>
                    Pesquisar
                  </button>
                </div>
              )}
              {this.props.isOpen && (
                <button className="btn btn-blue open" onClick={this.collapse}>
                  <span className="icon-btn-fechar"></span>
                  Fechar pesquisa
                </button>
              )}
            </>
          )}
          {this.getBtn()}
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ alert: { error }, load: { loading } }) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions,
  };
}

export default connect(mapStateToProps, mapDispatch)(HeaderList);
