import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './FilaAtendimento.scss';
import {ModalFilaAtendimento} from '../../../components/Modal/ModalFilaAtendimento';
import FilaMarcacaoPage from "./FilaMarcacaoPage";

export default class FilaMarcacaoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showFilaAtendimento: false };
  }
  toggleFilaAtendimento = () => {
    this.setState(({ showFilaAtendimento }) => ({
      showFilaAtendimento: !showFilaAtendimento,
    }));
  };

  render() {
    const { fila,  statusChange } = this.props;
    const { showFilaAtendimento } = this.state;
    return (
      <React.Fragment>
        <button
          className="btn btn-success m-3"
          onClick={this.toggleFilaAtendimento}
          disabled={!fila.length}
        >
          Pacientes aguardando: {fila.length}
        </button>

        <ModalFilaAtendimento

          fila={this.props.fila}
          user={this.props.user}
          loading={this.props.loading}
          error={this.props.error}
          success={this.props.success}
          title="Pacientes em espera"
          toogle={this.toggleFilaAtendimento}
          isOpen={this.state.showFilaAtendimento}
          Componente={FilaMarcacaoPage}
          onChange={(cpf)=>{this.toggleFilaAtendimento();this.props.onChange(cpf)}}
        />
      </React.Fragment>
    );
  }
}
