//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstoqueProdutosEdit from './EstoqueProdutosEdit';
import EstoqueProdutosListagemPage from './EstoqueProdutosListagemPage';
import EstoqueProdutosView from './EstoqueProdutosView';

export default class EstoqueProdutosIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/estoque-produtos/novo" component={EstoqueProdutosEdit} />
        <Route path="/estoque-produtos/:id" component={EstoqueProdutosView} />
        <Route
          path="/estoque-produtos"
          component={EstoqueProdutosListagemPage}
        />
      </Switch>
    );
  }
}
