//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';
import { userService } from './user.service';

const taskType = {
  REMARCACAO_CONSULTA: { url: '/', field: '', withParam: true }
};

export const taskService = {
  getTaskType,
  getWsUrl,
  getWsTopic,
  findByFilter
};

function getTaskType(taskName: string) {
  return taskType[taskName];
}

function findByFilter() {
 
  return defaultService.doPost(urlsConstants.TASK_BY_FILTER, {});
}

function getWsTopic() {
  let url = urlsConstants.TASK_BY_USER + userService.getCurrentUser().id;
  if (userService.getExtension()) {
    url = urlsConstants.TASK_BY_EXTENSION + userService.getExtension();
  } 
  return url;
}

function getWsUrl() {
  return urlsConstants.WS_WEBSOCKET;
}
