//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import makeAnimated from 'react-select/animated';
import IconDelete from '../../images/icon_x_red.svg';
import { medicamentoService } from '../../_services/medicamento.service';
import { posologiaService } from '../../_services/posologia.service';
import FormInput from '../inputs/FormInput';
import FormSelectInput from '../inputs/FormSelectInput';
import InputViewEdit from '../inputs/InputViewEdit';
import SelectInput from '../inputs/SelectInput';

type Props = {
  medicamento: any,
  removeItem: any,
  viewMode: Boolean,
};

type State = {
  exibeEditName: boolean,
  showChangeName: boolean,
  editName: boolean,
};

let medicamentoValidator = Yup.object().shape({
  medicamento: Yup.string().required('Campo requerido').nullable(),
});

const Option = (props) => {
  const { data, innerProps } = props;

  return (
    <div className="d-block pb-1 medicamentos" {...innerProps}>
      <div className="d-flex flex-row align-items-startreact-select__option action">
        <div className="customOption">
          {`${data.nome}, `}
          <span className="font-weight-bold"> {data.apresentacao}</span>{' '}
        </div>
      </div>
    </div>
  );
};

const animatedComponents = makeAnimated();

class MedicamentoPrescrito extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      exibeEditName: false,
      showChangeName: false,
      editName: false,
      medicamento: null,
      posologia: null,
    };
  }

  newOptionCreator = (newOption: any) => {
    return { nome: newOption.label, hora: this.props.horario };
  };

  onNewOptionClick = (newOption: any) => {};

  insertMedicamento = (value) => {
    const { propName, onChange } = this.props;
    medicamentoService
      .saveMedicamentoCustom(value)
      .then((response) => {
        let medicamento = response.data;
 this.setState({medicamento})
      })
      .catch((e) => {
        console.log(e);
      });
  };

  insertPosologia = (value) => {
    const { propName, onChange } = this.props;
    posologiaService
      .savePosologiaCustom(value)
      .then((response) => {
        let posologia = response.data;
        this.setState({ posologia });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  openChangeName = (event: any) => {
    event.preventDefault();
    this.setState({ showChangeName: !this.state.showChangeName });
  };

  changeName = (event: any) => {
    const { entity, propName, index, viewMode, size } = this.props;
    event.preventDefault();
    this.setState({ showChangeName: !this.state.showChangeName });
  };

  onChange = (name, value) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, name, value);
    this.setState(newState);
  };

  validate = (values) => {
    return medicamentoValidator
      .validate(values, { abortEarly: false })
      .then(() => {})
      .catch((err) => {
        throw err;
      });
  };

  addMedicamento = () => {
    const { values } = this.props;

    this.validate({ medicamento: this.state.medicamento }).then(
      (retorno) => {
        let medicamento = this.state.medicamento.nome;
        let posologia = this.state.posologia.descricao;

        let receituario = _.cloneDeep(
          _.get(values, 'prontuarioReceituario.prescricao', '')
        );
        receituario = `${receituario} ${medicamento} - ${posologia}`;

        this.setState({ medicamento: null, posologia: null }, () => {
          this.props.setFieldValue(
            'prontuarioReceituario.prescricao',
            receituario
          );
        });
      },
      (error) => {
        let errors = [];
        _.map(error.inner, function (erro) {
          _.set(errors, erro.path, erro.message);
        });
        this.setState({ error: errors });
      }
    );
  };

  render() {
    const { entity, propName, index, viewMode, size, values } = this.props;
    const { showChangeName, exibeEditName, editName, errors } = this.state;
    return (
      <div className="form-row d-flex flex-row align-items-start">
        <div className="col-12 col-lg-12 flex-column align-items-center">
          {!showChangeName && !editName && (
            <div className="mb-1">
              <InputViewEdit
                component={SelectInput}
                //components={{ Option, animatedComponents }}
                label="Medicamento ou formula"
                id="medicamento"
                onFetchData={medicamentoService.findByNomeOrPrincipio}
                onChange={this.onChange}
                multi={false}
                returnFullObject={true}
                creatable={false}
                searchable={true}
                className="select-wrap"
                viewMode={viewMode}
                // viewClassName={'font-weight-bold pl-1'}
                cache={true}
                placeholder="Pesquise pelo nome ou principio ativo"
                valueKey="id"
                labelKey={'nome'}
                defaultValue={
                  viewMode && _.get(this.state, `medicamento.nome`)
                    ? _.get(this.state, `medicamento.nome`)
                    : _.get(this.state, `medicamento.nome`)
                }
                onNewOptionClick={this.insertMedicamento}
                creatable={true}
                required={true}
                value={_.get(this.state, `medicamento`)}
                name={`medicamento`}
                erroMensagem={_.get(this.state.error, 'medicamento')}
              />
            </div>
          )}

          <div className="mb-1">
            <InputViewEdit
              component={FormSelectInput}
              id={this.props.id}
              label={'Posologia'}
              name={`posologia`}
              disaabled={true}
              service={posologiaService.find}
              onChange={this.onChange}
              value={this.props.value}
              labelKey="descricao"
              isMulti={false}
              returnFullObject={true}
              creatable={true}
              // onNewOptionClick={this.onNewOptionClick}
              onNewOptionClick={this.insertPosologia}
              cache={true}
              // onNewOptionClick={this.insertPosologia}
              creatable={true}
              //   components={{ Option: OptionUsuario }}
              placeholder="Posologia"
              parent={{
                medico: this.props.user,
                medicamento: _.get(this.state, `medicamento`),
              }}
              value={_.get(this.state, `posologia`)}
              defaultValue={_.get(entity, `posologia.descricao`)}
              viewMode={viewMode}
              viewClassName={'font-weight-bold pl-1'}
            />
          </div>
          {!viewMode && (
            <button
              type={'button'}
              disabled={
                _.get(this.state, `posologia`) == null ||
                _.get(this.state, `medicamento`) == null
              }
              className={'btn btn-incluir d-flex align-items-center m-auto'}
              onClick={() => {
                this.addMedicamento();
              }}
            >
              <span className={'icon-incluir'}></span>Incluir
            </button>
          )}
        </div>

        {/* <hr></hr> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(MedicamentoPrescrito);
