import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconPainel from "../../images/icon-more-med.svg";
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import InputViewEdit from "../inputs/InputViewEdit";
import SelectInput from "../inputs/SelectInput";
import { medicoService } from "../../_services/medico.service";
import { OptionProfissional } from "../inputs/OptionProfissional";

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalProfissional extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      medico: null
    };
  }

  toogleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  fecharModal = () => {
    this.toogleModal(null, null);
  };

  handleSelectChange = (name: string, option: any) => {
    const { medico } = this.state;
    let newEntity = _.cloneDeep(medico);
    newEntity = option;
    this.setState({ medico: newEntity });
  };

  render() {
    const { estabelecimento } = this.props;
    const { medico, idMarcacao, status } = this.state;
    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toogleModal}
          backdrop="static"
          modalClassName=""
          className="modal-anexos"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="">
              <div className="row">
                <div className="col-12 text-center iconLocal">
                  <img src={IconPainel} alt="Icone painel de chamada" />
                </div>
                <div className="col-12 text-center mb-2">
                  <h2 className="font-weight-bold">
                    Adicionar novo profissional
                  </h2>
                  <p>
                    Pesquise por nome, CPF ou o número do conselho do
                    profissional para verificarmos em nossa base se ele já esta
                    cadastrado. Se não estiver cadastrado clique no botão
                    cadastrar.
                  </p>
                </div>
              </div>

              <React.Fragment>
                <div className="form-row">
                  <div className="col-12">
                    <InputViewEdit
                      component={SelectInput}
                      components={{ Option: OptionProfissional }}
                      name="medico"
                      label=""
                      placeholder="Pesquise o nome, CPF ou conselho"
                      onChange={this.handleSelectChange}
                      noSize={true}
                      viewMode={false}
                      id="medico"
                      returnFullObject={true}
                      className="select-wrap"
                      menuIsOpen={true}
                      onFetchData={medicoService.findExistenteMedico}
                      labelKey={"apelido"}
                      value={medico}
                      required={true}
                      creatable={true}
                      onNewOptionClick={nome => {
                        this.fecharModal();
                        this.props.callbackOnSave({});
                      }}
                      cache={true}
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-50 text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.fecharModal();
                  }}
                >
                  {" "}
                  Cancelar{" "}
                </button>
              </div>
              <div className="w-50 text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (medico) {
                      _this.fecharModal();
                      _this.props.callbackOnSave(medico);
                    } else {
                      _this.props.error({
                        message: "Selecione um profissional."
                      });
                    }
                  }}
                  disabled={!medico}
                >
                  {" "}
                  Cadastrar{" "}
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
