//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const IntegracaoSascLogService = {
  findByFiltro,
};

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.INTEGRACAO_LOG}list`,
    options,
    filter
  );
}
