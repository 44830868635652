import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { objectsConstants } from "../../_constants/objects.constants";
import { formatterHelper } from "../../_helpers/formatter.helper";
import { ConfirmAcationService } from "../../_services/ConfirmAcationService";
import anexo from "../../images/icon_anexo_BIG.svg";

import Dropzone from "react-dropzone";
import { urlsConstants } from "../../_constants/urls.constant";
import { defaultService } from "../../_services/defaultService";
import IconDelete from '../../images/icon_x_red.svg';

let download = require("downloadjs");

type Props = {
  target?: any,
  itens: []
};

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};
let fileInput;

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (event) {
    var dataUrl = event.target.result;

    var midiaVO = {
      nome: file.name,
      tamanho: file.size,
      tipo: file.type
    };
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (validImageTypes.includes(file.type)) {
      var image = new Image();
      image.src = dataUrl;
      image.onload = function () {
        var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
        midiaVO.data = resizedDataUrl;
        fn(midiaVO);
      };
    } else {
      midiaVO.data = dataUrl;
      fn(midiaVO);
    }
  };
}

function resizeImage(image, maxWidth, maxHeight, quality) {
  var canvas = document.createElement("canvas");

  var width = image.width;
  var height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL("image/jpeg", quality);
}

export class ModalAnexos extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        resize(item, maxWidth, maxHeight, function (midiaFile) {
          let { itens } = self.props;
          let newArrayFiles = _.cloneDeep(itens);
          newArrayFiles.push(midiaFile);
          self.props.onChange(self.props.name, newArrayFiles);
        });
      }
    }
  };



  openInputFile = () => {
    this.inputFile.click();
  };

  handleChange = (event: any, index) => {
    let { itens } = this.props;
    let descricao = event.target.value
    console.log(descricao);
    console.log(index);
    let newArrayFiles = _.cloneDeep(itens);
    _.set(newArrayFiles[index], 'descricao', descricao);
    console.log(newArrayFiles);
    this.props.onChange(this.props.name, newArrayFiles);

  };

  downLoadFile = (anexo: any) => {
    if (anexo.id) {
      let url = urlsConstants.URL_MIDIAS + "vo/" + anexo.id;
      defaultService.doGet(url).then(result => {
        let dataFile = result.data;
        if (dataFile) {
          let uri = `data:${dataFile.tipo};base64,${dataFile.data}`;
          if (dataFile.data.includes("base64,")) {
            uri = dataFile.data;
          }

          download(uri, dataFile.nome, dataFile.tipo);
        }
      });
    }
    return false;
  };

  downLoadDataFile = (dataFile: any) => {
    if (dataFile) {
      let uri = `data:${dataFile.tipo};base64,${dataFile.data}`;
      if (dataFile.data.includes("base64,")) {
        uri = dataFile.data;
      }

      download(uri, dataFile.nome, dataFile.tipo);
    }
    return false;
  };

  removeItem = (e, image) => {
    let { itens } = this.props;
    let newArrayFiles = _.cloneDeep(itens);
    _.remove(newArrayFiles, function (item) {
      return (image.id && image.id === item.id) || (image.data && image.data === item.data);
    });
    this.props.onChange(this.props.name, newArrayFiles);
  };


  onDrop(files) {

    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        console.log(item);
        resize(item, maxWidth, maxHeight, function (midiaFile) {
          console.log(midiaFile);
          let { itens } = self.props;
          let newArrayFiles = _.cloneDeep(itens);
          newArrayFiles.push(midiaFile);
          self.props.onChange(self.props.name, newArrayFiles);
        });
      }
    }
  }
  render() {
    return (
      <React.Fragment >
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-anexos modal-lg"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="row top-modal">
              <div className="col-12 text-center">
                <img className="" src={anexo} alt="anexo" />
              </div>
              <div className="col-12 text-center my-2">
                <h2 className="font-weight-bold">Anexos</h2>
              </div>
            </div>
            {!this.props.viewMode && (
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    Arraste os arquivos ou clique aqui para adicionar
                  </div>

                )}
              </Dropzone>
            )}
            {/* <div className="row"> */}
            {this.props.itens.length === 0 && (
              <div className="txt-modal disable w-100">
                {"Nenhum arquivo anexado"}
              </div>
            )}
            {this.props.itens.map((anexo, i) => {

              return (
                <div
                  className="row"
                  id={anexo.nome}
                  key={anexo.id ? anexo.id : i}
                >
                  <div className="col-12 d-flex flex-row align-items-center">
                    <div
                      className="txt-modal flex-fill"
                      onClick={() => {
                        this.downLoadFile(anexo);
                      }}
                    >
                      {anexo.nome}{" "}
                    </div>
                    <div className="txt-modal disable">
                      {formatterHelper.getFileSize(anexo.tamanho)}
                    </div>
                    {!this.props.viewMode && !anexo.id && (
                      <>
                        {/* <button className="btn btn-sm btn-blue" onClick={() => { this.addDescricao(anexo) }}>
                          Adicionar descrição
                        </button> */}
                        <div
                          className={`icon-delete d-flex align-items-center justify-content-center`}
                          onClick={e => {
                            ConfirmAcationService.confirmMsg(
                              () => {
                                this.removeItem(i, anexo);
                              },
                              "Excluir anexo",
                              <span>
                                {" "}
                                Deseja realmente excluir o anexo{" "}
                                <span className="font-bold">{anexo.nome}</span>?
                              </span>,
                              <span className="text-danger">{ }</span>,
                              "Não",
                              "Sim",
                              objectsConstants.TYPE_EXCLUIR
                            );
                          }}

                        >
                          <img src={IconDelete} alt="img" />
                        </div>

                      </>
                    )}
                  </div>
                  {!this.props.viewMode && !anexo.id && (
                    <>
                      <div className="col-12 d-flex flex-row align-items-center">
                        <textarea
                          className="form-control"
                          rows="1"
                          placeholder="Descrição do arquivo"
                          value={anexo.descricao}
                          onChange={(event) => {
                            console.log(event);
                            this.handleChange(event, i)
                          }
                          }
                        /></div>
                      <hr></hr>
                    </>
                  )}
                  {this.props.viewMode && anexo.id && (
                    <>
                      {anexo.descricao}
                      {/* <hr></hr> */}
                    </>
                  )}
                </div>


              );
            })}
            {/* </div> */}

          </ModalBody>
          <ModalFooter>
            <div className="w-100 text-center">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.props.toogle();
                }}
              >
                {" "}
                Fechar{" "}
              </button>
            </div>
          </ModalFooter>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment >
    );
  }
}
