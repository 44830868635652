//@flow
import { loadConstants } from "./../_constants/load.constant";

export const loadActions = {
  loading,
  clear
};

function loading(load: boolean) {
  return (dispatch: any) => {
    if (load) {
      dispatch(addLoading());
    } else {
      dispatch(removeLoading());
    }
  };

  function addLoading() {
    return { type: loadConstants.ADD_LOADING };
  }

  function removeLoading() {
    return { type: loadConstants.REMOVE_LOADING };
  }
}

function clear() {
  return { type: loadConstants.CLEAR_LOADING };
}
