//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ModelosAnamneseEdit from './ModelosAnamneseEdit';
import ModelosAnamneseListagemPage from './ModelosAnamneseListagemPage';
import ModelosAnamneseView from './ModelosAnamneseView';

export default class ModelosAnamneseIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/modelo-anamnese"
          component={ModelosAnamneseListagemPage}
          exact
        />
        <Route path="/modelo-anamnese/novo" component={ModelosAnamneseEdit} />
        <Route
          path="/modelo-anamnese/view/:id"
          component={ModelosAnamneseView}
        />
      </Switch>
    );
  }
}
