//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { modeloReceituarioService } from '../../_services/modeloReceituario.service';
import ModelosReceituarioForm from './ModelosReceituarioForm';

const defaultEntity: modelo = {
  nome: '',
  formulario: [],
};

class ModelosReceituarioEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      modeloReceituarioService.doGet(this.props.match.params.id).then(
        (response) => {
          let modelo = response.data.data;
          if (!modelo.formulario) {
            modelo.formulario = [{}];
          }
          this.setState({ entity: modelo }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  modelo de receituario`}
          backUrl={!entity.id ? null : '/modelo-receituario'}
          backConfirmObject={{
            title: `Cancelar ${!entity.id ? 'criação' : 'Edição'} `,
            message:
              'Ao clicar em sim, todos os dados inseridos serão perdidos. Você deseja realmente ' +
              `cancelar a ${
                !entity.id ? 'criação' : 'Edição'
              } do modelo de receituario?`,
            subMessage: null,
            type: objectsConstants.TYPE_CANCEL,
          }}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>
        <div className="flex-column">
          <FullCustomCard
            title="Dados do modelo receituario"
            permissionSave={objectsConstants.MENU_MODELO_RECEITUARIO}
            Componente={ModelosReceituarioForm}
            viewMode={false}
            entity={entity}
            redirectPath="/modelo-receituario/view/"
            onCancel={this.handleCancel}
            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(ModelosReceituarioEdit)
);
