//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { paramTriagemService } from '../../../_services/paramTriagem.service';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import HorarioAtendimentoExames from '../../../components/HorarioAtendimentoExames/HorarioAtendimentoExames';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { modeloService } from '../../../_services/modelo.service';
import TriagemForm from './TriagemForm';

const defaultEntity: triagem = {
  nome: '',
  itensSelecao: [
    {
      subItensSelecao: [],
    },
  ],
};

class TriagemEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      paramTriagemService.doGet(this.props.match.params.id).then(
        (response) => {
          let paramTriagem = response.data.data;

          this.setState({ entity: paramTriagem }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  parâmetro de triagem`}
          canBack={true}
          backUrl={!entity.id ? null : '/paramterizacao/triagem'}
          backConfirmObject={{
            title: `Cancelar ${!entity.id ? 'criação' : 'Edição'} `,
            message:
              'Ao clicar em sim, todos os dados inseridos serão perdidos. Você deseja realmente ' +
              `cancelar a ${
                !entity.id ? 'criação' : 'Edição'
              } do parâmetro de triagem?`,
            subMessage: null,
            type: objectsConstants.TYPE_CANCEL,
          }}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>
        <div className="flex-column">
          <FullCustomCard
            title="Dados do parâmetro de triagem"
            permissionSave={objectsConstants.MENU_PARAMETRIZACAO_TRIAGEM}
            Componente={TriagemForm}
            viewMode={false}
            entity={entity}
            onCancel={this.handleCancel}
            redirectPath="/parametrizacao/triagem/"
            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(TriagemEdit)
);
