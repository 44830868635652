import moment from 'moment';
import _ from 'lodash';
import { mesmoMesAno, updateMarcacao } from "../_helpers/agenda.helper";
import {userService} from "../_services/user.service";

export class CacheAgenda {
    estabelecimento;
    dataStart;
    dataEnd;
    tipoAgenda;
    agenda;

    constructor(filtro) {
        this.estabelecimento = filtro.estabelecimento;
        this.dataStart = filtro.dataStart;
        this.dataEnd = filtro.dataEnd;
        this.tipoAgenda = filtro.tipoAgenda;
    }

    addAgenda(marcacao): void {
        if (mesmoMesAno(this.dataStart, marcacao.dataConsulta)) {
            this.agenda = updateMarcacao(this.agenda, marcacao);
        }
    }
    isEquals(cache: CacheAgenda): boolean {
        return _.isEqual(cache.dataEnd, this.dataEnd) &&
            (userService.isMedico()|| (cache.estabelecimento == null && this.estabelecimento == null) || (cache.estabelecimento && this.estabelecimento && _.isEqual(cache.estabelecimento.id, this.estabelecimento.id))) &&
            _.isEqual(cache.dataStart, this.dataStart)
            && _.isEqual(cache.tipoAgenda, this.tipoAgenda);
    }

    exist(caches: Array) {
        if (caches && caches.length > 0) {
            for (let cache of caches) {
                if (cache && this.isEquals(cache)) {
                    return cache;
                }
            }
        }
        return null;
    }

    filtrar(filtro) {
        let agenda = this.agenda;
        if (filtro.convenio) {
            agenda = _.filter(agenda, function (a) {
                if (a.medico && a.medico.listConvenio && a.medico.listConvenio.length > 0) {
                    for (let convenio of a.medico.listConvenio) {
                        if (convenio && convenio.id == filtro.convenio.id) {
                            return true;
                        }
                    }
                }
                return false;
            })
        }
        if (filtro.especialidades && filtro.especialidades.length > 0) {
            agenda = _.filter(agenda, function (a) {
                for (let especialidade of a.medico.especialidades) {
                    for (let especialidadeFiltro of filtro.especialidades) {
                        if (especialidadeFiltro && especialidade && (especialidadeFiltro.id == especialidade.id)) {
                            return true
                        }
                    }

                }
                return false
            })
        }
        if (filtro.medicoList && filtro.medicoList.length > 0) {
            agenda = _.filter(agenda, function (a) {
                for (let medico of filtro.medicoList) {
                    if (a.medico && medico && a.medico.id == medico.id) {
                        return true
                    }
                }
                return false
            })
        }
        if (filtro.exame && filtro.exame.id) {
            agenda = _.filter(agenda, function (a) {
                return a.exame && filtro.exame && a.exame.id == filtro.exame.id;
            })
        }
        return agenda;

    }

}