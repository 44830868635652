//@flow
import { error } from 'jquery';
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormInput from '../../../components/inputs/FormInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import ModalImpressao from '../../../components/Modal/ModalImpressao';
import { procedimentoService } from '../../../_services/procedimento.service';
import TabelaDetalheProntuario from './TabelaDetalheProntuario';

let procedimentoValidator = Yup.object().shape({
  procedimento: Yup.string().required('Campo requerido').nullable(),
  quantidade: Yup.string().required('Campo obrigatório').nullable(),
});
class ProcedimentosForm extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = {
      procedimento: '',
      quantidade: '',
      justificativa: '',
    };
  }
  onChange = (name, value) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, name, value);
    this.setState(newState);
  };

  validate = (values) => {
    return procedimentoValidator
      .validate(values, { abortEarly: false })
      .then(() => {})
      .catch((err) => {
        throw err;
      });
  };

  addProcedimento = () => {
    const { values } = this.props;
    let listProcedimentosRealizados = _.cloneDeep(
      _.get(values, 'listProcedimentosRealizados', [])
    );

    this.validate({
      procedimento: this.state.procedimento,
      quantidade: this.state.quantidade,
    }).then(
      (retorno) => {
        listProcedimentosRealizados.push({
          procedimento: this.state.procedimento,
          quantidade: this.state.quantidade,
          justificativa: this.state.justificativa,
          imprimir: true,
        });
        this.setState(
          { procedimento: null, quantidade: null, justificativa: null },
          () => {
            this.props.setFieldValue(
              'listProcedimentosRealizados',
              listProcedimentosRealizados
            );
          }
        );
      },
      (error) => {
        let errors = [];
        // let erros = _.pick(, ['path', 'message']);
        _.map(error.inner, function (erro) {
          _.set(errors, erro.path, erro.message);
        });

        this.setState({ error: errors });
      }
    );
  };

  imprimirProcedimentos = () => {
    const { values } = this.props;
    this.props.loading(true);
    let documento = {};
    _.set(documento, 'marcacaoVO', values.marcacao);

    let listProcedimentoImprimir = [];
    _.map(values.listProcedimentosRealizados, function (exame) {
      if (exame.imprimir) {
        listProcedimentoImprimir.push(exame);
      }
    });
    _.set(documento, 'listProcedimentosPrint', listProcedimentoImprimir);
    procedimentoService.imprimir(documento).then(
      (response) => {
        var base64 = _.get(response, 'data.data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.props.loading(false);
      },
      (r) => {
        this.props.loading(false);
      }
    );
  };

  render() {
    const { viewMode, values, setFieldValue } = this.props;
    return (
      <div
        className={`row mt-2 procedimentos  ${
          this.props.show == 1 ? '' : 'd-none'
        }`}
      >
        <div className="col-12 col-sm-4">
          <InputViewEdit
            component={SelectInput}
            label={'Codigo do procedimento'}
            id="procedimento"
            name="procedimento"
            placeholder="Procedimento"
            value={_.get(this.state, 'procedimento')}
            onChange={this.onChange}
            noSize={true}
            // viewMode={viewMode}
            required={true}
            returnFullObject={true}
            valueKey={'id'}
            labelKey={'codigo'}
            multi={false}
            onFetchData={procedimentoService.findAll}
            sortKey={'descricao '}
            erroMensagem={_.get(this.state.error, 'procedimento')}
            // viewClassName={'font-weight-bold pl-1'}
          />
        </div>
        <div className="col-12 col-sm-8 ">
          <InputViewEdit
            component={SelectInput}
            label={'Descrição do procedimento'}
            id="procedimento"
            name="procedimento"
            placeholder="Procedimento"
            value={_.get(this.state, 'procedimento')}
            onChange={this.onChange}
            noSize={true}
            // viewClassName={'font-weight-bold pl-1'}
            // viewMode={viewMode}
            required={true}
            returnFullObject={true}
            valueKey={'id'}
            labelKey={'descricao'}
            multi={false}
            erroMensagem={_.get(this.state.error, 'procedimento')}
            onFetchData={procedimentoService.findAll}
            sortKey={'descricao '}
          />
        </div>
        <div className="col-12 col-sm-2">
          <InputViewEdit
            label="Quantidade"
            placeholder=""
            component={FormInput}
            viewMode={viewMode}
            required={false}
            type="number"
            value={_.get(this.state, 'quantidade')}
            onChange={this.onChange}
            name={`quantidade`}
            id={`quantidade`}
            erroMensagem={_.get(this.state.error, 'quantidade')}
            viewClassName={'font-weight-bold pl-1'}
          />
        </div>
        <div className="col-12 col-sm-10">
          <InputViewEdit
            label="Justificativa"
            placeholder=""
            viewMode={viewMode}
            component={FormInput}
            returnFullObject={true}
            required={false}
            value={_.get(this.state, 'justificativa')}
            onChange={this.onChange}
            name={`justificativa`}
            id={`quantidade`}
          />
        </div>

        <div className="col-12 mt-2">
          {!viewMode && (
            <button
              type={'button'}
              className={'btn btn-incluir d-flex align-items-center m-auto'}
              onClick={this.addProcedimento}
            >
              <span className={'icon-incluir'}></span>Incluir
            </button>
          )}
          <label>Incluídos:</label>
          <TabelaDetalheProntuario
            viewMode={viewMode}
            label={'procedimento.descricaoCodigo'}
            quantidade={'quantidade'}
            description={'justificativa'}
            name={'listProcedimentosRealizados'}
            values={values}
            edit={this.editMedicamento}
            setFieldValue={setFieldValue}
          />
        </div>
        {values.listProcedimentosRealizados &&
          values.listProcedimentosRealizados.length > 0 && (
            <div className={`col-12 d-flex justify-content-end`}>
              <button
                type="button"
                className="btn btn-link"
                onClick={this.imprimirProcedimentos}
              >
                <span className="icon-btn-imprimir"></span>
                Imprimir selecionados
              </button>
              {this.state.modalImpressao && (
                <ModalImpressao
                  title="Impressão receituário"
                  toogle={this.toggleModalImpressao}
                  isOpen={this.state.modalImpressao}
                  target={'impressao'}
                  getItem={this.getReceituarioItem}
                  entity={values.prontuarioReceituario}
                  marcacao={values.marcacao}
                  onCancel={this.toggleModalImpressao}
                  callbackOnSave={(valuesPrint) => {
                    //this.setValuesImprimir(valuesPrint)
                  }}
                ></ModalImpressao>
              )}
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ProcedimentosForm);
