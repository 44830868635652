import _, { replace } from 'lodash';
import { objectsConstants } from '../_constants/objects.constants';
import { dateHelper } from './date.helper';

export const formatterHelper = {
  cpfCnpjFormatter,
  getNameOrHifen,
  formatMoney,
  getNameAndLastName,
  getFileSize,
  getLabelEnum,
  getEnum,
  money,
  onlyNumber,
  formataItemHistorico,
  moneyToFloat,
  parseMoney
};

function parseMoney(value) {
  if (value && value != '') {
    return parseFloat(value.replace(".", "").replace(",", ".").replace('R$', '').replace(' ', ''));
  }
  return 0;
}

function number(v) {
  //Remove tudo o que não é dígito
  if (_.isNumber(v)) {
    return v;
  }
  v = v.replace(/\D/g, "");
  return v;
}

function onlyNumber(...props) {
  let hashResult = "";
  for (let i = 0; i < props.length; i++) {
    hashResult = hashResult + number(props[i]);
  }
  return hashResult;
}

function getLabelEnum(enumValue, options, valueKey, labelkey) {
  valueKey = valueKey ? valueKey : "value";
  labelkey = labelkey ? labelkey : "label";
  for (let indexOptions in options) {
    if (enumValue === options[indexOptions][valueKey]) {
      return options[indexOptions][labelkey];
    }
  }
}

function getEnum(enumValue, options) {
  for (let indexOptions in options) {
    if (enumValue === options[indexOptions].value) {
      return options[indexOptions];
    }
  }
}
function getNameOrHifen(name: String, defaultValue: String) {
  if (!name) {
    return defaultValue ? defaultValue : "- -";
  }
  try {
    // var firstName = name
    //   .split(" ")
    //   .slice(0, 1)
    //   .join(" ");
    // var lastName = name
    //   .split(" ")
    //   .slice(-1)
    //   .join(" ");
    // if (name.indexOf(" ") !== -1) {
    //   return firstName + " " + lastName;
    // }
    return name;
  } catch (error) {
    return name;
  }
}

function getNameAndLastName(name: String, defaultValue: String) {
  if (!name) {
    return defaultValue ? defaultValue : "- -";
  }

  if (name.split(" ").length === 0) {
    return name;
  }
  var firstName = name
    .split(" ")
    .slice(0, 1)
    .join(" ");
  var lastName = name
    .split(" ")
    .slice(-1)
    .join(" ");
  if (name.indexOf(" ") !== -1) {
    return firstName + " " + lastName;
  }
  return name;
}
function cpfCnpjFormatter(value) {
  const cnpjCpf = value ? value.replace(/\D/g, "") : "";

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
}
// function cpfCnpjFormatter(values) {
//   if (values) {
//     let onlyNumber = values.replace(/\D/g, "");
//     if (onlyNumber.length <= 11) {
//       onlyNumber = onlyNumber.replace(/(\d{3})(\d)/, "$1.$2");
//       onlyNumber = onlyNumber.replace(/(\d{3})(\d)/, "$1.$2");
//       onlyNumber = onlyNumber.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
//     } else {
//       //Coloca ponto entre o segundo e o terceiro dígitos
//       onlyNumber = onlyNumber.replace(/^(\d{2})(\d)/, "$1.$2");
//       //Coloca ponto entre o quinto e o sexto dígitos
//       onlyNumber = onlyNumber.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
//       //Coloca uma barra entre o oitavo e o nono dígitos
//       onlyNumber = onlyNumber.replace(/\.(\d{3})(\d)/, ".$1/$2");
//       //Coloca um hífen depois do bloco de quatro dígitos
//       onlyNumber = onlyNumber.replace(/(\d{4})(\d)/, "$1-$2");
//     }
//     return onlyNumber;
//   }
// }

function getSimNao(value) {
  if (value == 'true' || value == true) {
    return 'Sim'
  } else if (value == 'false' || value == false) {
    return 'Não'
  }
  return ''
}

function formataItemHistorico(value) {

  if (value != null) {
    let date = new Date(value);
    if (
      date instanceof Date &&
      date !== 'Invalid Date' &&
      !isNaN(date) &&
      value.includes('-')
    ) {
      return dateHelper.format(value, {
        mode: 'DATE',
      });
    } else if (value == 'true' || value == 'false') {
      return getSimNao(value)
    } else {
      return value;
    }
  }
  return null;
}


function money(valor) {
  return formatMoney(valor, 2, ",", ".");
}

function moneyToFloat(valor) {
  let rep = replace(valor, '.', '')
  let rep2 = parseFloat(replace(rep, ",", "."))
  return rep2;
}

function formatMoney(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    "R$ " +
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : "")
  );
}

function getFileSize(size) {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}
