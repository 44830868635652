//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../components/templates/ListagemPage';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { tableHelper } from '../../../_helpers/tableHelper';
import { IntegracaoSascLoteService } from '../../../_services/integracaoSascLote.service';
import IntegracaoSascListFilter from './IntegracaoSascListFilter';

const columns = [
  {
    path: 'codigoIntegracao',
    title: 'Código',
    isKey: true,
    dataSort: true,
    className: 'column-bold',
  },
  {
    path: 'dataIntegracao',
    title: 'Data da integração',
    isKey: false,
    dataSort: true,
    isDate: true,
  },

  {
    path: 'descricao',
    title: 'Descrição',
    isKey: false,

    dataSort: true,
  },
  {
    path: 'dataUltimaTentativa',
    title: 'Data da última tentativa',
    isKey: false,
    dataSort: true,
    isDate: true,
  },
  {
    path: 'mensagemRetorno',
    title: 'Retorno',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'statusIntegracao',
    title: 'Status',
    isKey: false,
    isStatusIntegracao: true,
    dataSort: true,
  },
  {
    path: 'detalhes',
    title: '',
    isKey: false,
    isIntegrationAction: true,
  },
];

class IntegracaoSascListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);

    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_INTEGRACAO_SASC}
          subTitle=""
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_INTEGRACAO}
        ></TopoListagem>
        <ListagemPage
          title={objectsConstants.TITULO_PAGINA_INTEGRACAO_SASC}
          showNewButton={false}
          service={IntegracaoSascLoteService}
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          FormFilter={IntegracaoSascListFilter}
          fields={this.state.fields}
          controller="api/integracaoLote"
          labels={this.state.labels}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(IntegracaoSascListagemPage);
