import { Formik } from "formik";
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import saveProtocolo from "../../images/icon-save.svg";
import { translate } from "../../_helpers/messages.helper";
import { defaultService } from "../../_services/defaultService";
import { protocoloAnamneseService } from "../../_services/protocoloAnamnese.service";
import FormInput from "../inputs/FormInput";


const protocolValidate = Yup.object().shape({
	nome: Yup.string()
		.min(5, "Nome muito curto!")
		.max(60, "Nome muito grande!")
		.required("Obrigatório")
});

const modifiers = {
	preventOverflow: {
		enabled: false
	},
	flip: {
		enabled: false
	}
};

export class ModalModelAnamnese extends PureComponent<Props, State> {
	constructor(props) {
		super(props);
		//this.loadFile = this.loadFile.bind(this);
	}

	cancelar = () => {
		this.props.toogle();
	};

	render() {
		const { entity, callbackOnSave } = this.props;
		let _this = this;
		return (
			<React.Fragment>
				<Modal
					isOpen={this.props.isOpen}
					toggle={this.props.toggle}
					backdrop="static"
					modalClassName=""
					className="modal-anexos"
					centered={true}
					modifiers={modifiers}
					target={this.props.target}
				>
					<Formik
						validationSchema={protocolValidate}
						validateOnBlur={false}
						validateOnChange={false}
						enableReinitialize={true}
						initialValues={{}}
						onSubmit={(values, { setSubmitting, setValues }) => {
							_this.props.loading(true);
							values.anamnese = entity.anamnese;
							values.medico = entity.medico;
							protocoloAnamneseService.doSave(values).then(
								response => {
									console.log("doSave");
									_this.props.success({
										message: `Modelo foi salvo com sucesso!`
									});
									let id = values.id ? values.id : defaultService.getIdFromUrl(response.headers.location);
									values.id = id;
									entity.protocoloAtendimentoAnamnese = values;
									_this.props.loading(false);
									if (callbackOnSave) {
										callbackOnSave(entity);
									}
									_this.props.toogle();
								},
								erros => {
									_this.props.loading(false);
									_this.props.error({
										message: "Não foi possível salvar modelo."
									});
									try {
										let response = erros.response.data;
										if (response && response.messages) {
											for (var i = 0; i < response.messages.length; i++) {
												let erroItem = response.messages[i];
												_this.formRef.setFieldError(
													erroItem.fieldName,
													translate(erroItem.message.code)
												);
											}
										}
									} catch (error) {
										console.log(error);
									}
									console.log(erros);
								}
							)
						}}
						ref={form => {
							this.formRef = form;
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							validationSchema,
							validateForm,
							setValues
						}) => (
							<form onSubmit={handleSubmit}>
								<ModalBody>
									<div className="">
										<div className="row">
											<div className="col-12 text-center iconProtocolo">
												<img src={saveProtocolo} alt="anexo" />
											</div>
											<div className="col-12 text-center mb-2">
												<h2 className="font-weight-bold">Salvar modelo de anamnese</h2>
											</div>
											{/* <div className="col-12 text-center my-2">
												 <p>Insira o nome do Protocolo</p>
											</div> */}
										</div>

										<React.Fragment>
											<div className="form-row">
												<div className="col-12">
													<FormInput
														label="Insira o nome do Modelo"
														placeholder="Nome do modelo"
														component={FormInput}
														defaultValue={""}
														required={false}
														value={values.nome}
														name={`nome`}
														id={`nomeModelo`}
														onChange={setFieldValue}
														erroMensagem={errors.nome}
													/>
												</div>
											</div>
										</React.Fragment>
									</div>
								</ModalBody>
								<ModalFooter>
									<React.Fragment>
										<div className="w-50 text-center">
											<button
												className="btn btn-secondary"
												type="button"
												onClick={() => {
													this.cancelar();
												}}
											>
												{" "}
												Cancelar{" "}
											</button>
										</div>
										<div className="w-50 text-center">
											<button
												className="btn btn-primary"
											>
												{" "}
												Salvar{" "}
											</button>
										</div>
									</React.Fragment>
								</ModalFooter>
							</form>
						)}
					</Formik>
				</Modal>
			</React.Fragment>
		);
	}
}
