//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { ModalProtocolo } from '../../../components/Modal/ModalProtocolo';
import { objectsConstants } from '../../../_constants/objects.constants';
import { urlsConstants } from '../../../_constants/urls.constant';
import { dateHelper } from '../../../_helpers/date.helper';
import { defaultService } from '../../../_services/defaultService';
import { prontuarioService } from '../../../_services/prontuario.service';
import AnamneseDadosForm from '../AnamneseDadosForm';
import AnamneseHistoricoDadosForm from '../AnamneseHistoricoDadosForm';
import HeaderProntuario from './HeaderProntuario';
import ReceituarioExamesProntuarioForm from './ReceituarioExamesProntuarioForm';
import { ModalGerarDocumentos } from '../../../components/Modal/ModalGerarDocumentos';
type Props = {
  dataHora: any,
  newDiv?: boolean,
  onCancelar: any,
  onEdit?: any,
  isAnamneseEditing: boolean,
  dispatch: any,
};
let AnamneseValidate = Yup.object().shape({
  anamnese: Yup.string()
    //     .min(10, 'Nome muito curto!')
    //     .max(60, 'Nome muito grande!')
    .required('Obrigatório'),
  //   cpfCnpj: Yup.string().required('Obrigatório'),
  //   dataNascimento: Yup.date().required('Obrigatório'),
  //   email: Yup.string().required('Obrigatório').email('Email inválido'),
});

class ProntuarioHistoricoForm extends React.Component<Props, {}> {
  formRef: any;
  constructor(props) {
    super(props);
    this.state = {
      viewMode: false,
      openProtocol: false,
      openModalGerarDocumentos: false,
    };
  }
  toggleProtocolo = () => {
    this.setState({ openProtocol: !this.state.openProtocol });
  };

  saveForm = (values, setFieldValue, finaliza) => {
    console.log(values);
    this.props.loading(true);
    prontuarioService.doSave(values).then(
      (response) => {
        this.props.loading(false);
        if (finaliza) {
          if (this.props.client.connected) {
            this.props.client.send(
              urlsConstants.SEND_PACIENTE_STATUS,
              JSON.stringify({
                id: values.id,
                status: objectsConstants.ATENDIDO,
              })
            );
            this.props.success({
              message: `Prontuario(s), foi finalizado com sucesso!`,
            });
          }
          this.props.changeView();
        } else {
          if (!values.id) {
            setFieldValue(
              'id',
              defaultService.getIdFromUrl(response.headers.location)
            );
          }

          this.props.success({
            message: `Prontuario(s), foi salvo com sucesso!`,
          });
        }
      },
      (error) => {
        this.props.loading(false);
      }
    );
  };

  finalizaAtendimento = () => {
    this.saveForm(this.formRef.state.values, null, true);
  };

  toggleModalGerarDocumentos = () => {
    this.setState(({ openModalGerarDocumentos }) => ({
      openModalGerarDocumentos: !openModalGerarDocumentos,
    }));
  };
  atualizaAnexosComModelo = (documentoVO, values, setValues) => {
    const newEntity = _.cloneDeep(values);
    console.log(newEntity);
    if (!newEntity.listAnexos) {
      newEntity.listAnexos = [];
    }

    if (newEntity.listAnexos) {
      newEntity.listAnexos.push(documentoVO);
    } else {
      newEntity.listAnexos = [documentoVO];
    }
    console.log(newEntity);
    setValues(newEntity);
  };

  render() {
    let { viewMode, history, entity } = this.props;

    return (
      <Formik
        validationSchema={AnamneseValidate}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.props.entity}
        ref={(form) => {
          this.formRef = form;
        }}
        onSubmit={(values, { setFieldValue }) => {
          console.log(values.marcacao.status);
          this.saveForm(
            values,
            setFieldValue,
            _.get(values, 'marcacao.status') == objectsConstants.ATENDIDO
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={'d-flex prontuario flex-wrap '}>
              <React.Fragment>
                <h1 className={'text-center mt-3 w-c-50 mr-50'}>Anamnese</h1>
                <p className={'text-center mb-3 w-c-50 mr-50'}>
                  {_.get(entity, 'medico.nome')} em{' '}
                  {dateHelper.format(
                    moment(_.get(entity, 'dataCadastro')).toDate(),
                    {
                      mode: 'DATE',
                    }
                  )}
                </p>
              </React.Fragment>
              <div className={'anamnese px-0 px-sm-4 d-flex flex-column'}>
                <AnamneseHistoricoDadosForm
                  history={history}
                  viewMode={viewMode}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                />
              </div>
              <div className={'receituario-exame px-0 px-sm-4'}>
                <ReceituarioExamesProntuarioForm
                  history={history}
                  agendamentosHoje={this.props.agendamentosHoje}
                  viewMode={viewMode}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  toggleProtocolo={this.toggleProtocolo}
                />
              </div>

              <ModalProtocolo
                ref={(protocol) => (this._protocolInput = protocol)}
                name="listprotocol"
                toogle={this.toggleProtocolo}
                isOpen={this.state.openProtocol}
                target={'protocol'}
                user={this.props.user}
                entity={values.prontuarioReceituario}
                estabelecimento={_.get(
                  this.props.entity.marcacao,
                  'estabelecimento.id'
                )}
                success={this.props.success}
                loading={this.props.loading}
                error={this.props.error}
              />
              <ModalGerarDocumentos
                ref={(gerarDocumentos) =>
                  (this._gerarDocumentosInput = gerarDocumentos)
                }
                name="listGerarDocumentos"
                toogle={this.toggleModalGerarDocumentos}
                isOpen={this.state.openModalGerarDocumentos}
                target={'gerarDocumentos'}
                user={this.props.user}
                success={this.props.success}
                loading={this.props.loading}
                error={this.props.error}
                marcacao={entity.marcacao}
                entity={entity}
                callbackOnSave={(documentoVO) => {
                  this.atualizaAnexosComModelo(documentoVO, values, setValues);
                }}
              />
              {history && (
                <React.Fragment>
                  <hr className={'my-4 hr-green'} />
                </React.Fragment>
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { agendas } = state.agenda;
  const { client } = state.webSocket;
  const { isAnamneseEditing } = state.anamnese;

  return {
    user,
    agendas,
    client,
    isAnamneseEditing,
  };
}

const mapDispatch = ({
  load: { loading },
  alert: { success, error, clear },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(ProntuarioHistoricoForm);
