//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const lotesService = {
  findByProduto,
};

function findByProduto(id) {
  return defaultService.doGet(`${urlsConstants.ENTRADA_ESTOQUE_LOTES}${id}`);
}
