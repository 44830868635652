//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const procedimentoService = {
  findAll,
  doSave,
  imprimir
};


function imprimir(description) {
  return defaultService.doPost(`${urlsConstants.PROCEDIMENTO}imprimir`, description);
}

function findAll(descricao) {

  return defaultService.doPost(`${urlsConstants.PROCEDIMENTO}findAll`,{descricao});
}


function doSave(listProcedimentos, id) {

  return defaultService.doPost(`${urlsConstants.CONVENIO}${id}/atualiza/procedimentosConvenio`, listProcedimentos);
}