import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { examesSolicitadosService } from '../../../_services/examesSolicitados.service';
import { prontuarioService } from '../../../_services/prontuario.service';
import { ModalSolutiOtp } from '../../../components/Modal/ModalSolutiPasswordOtp';
import FormInput from '../../../components/inputs/FormInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import TabelaDetalheProntuario from './TabelaDetalheProntuario';

let exameValidator = Yup.object().shape({
  exame: Yup.string().required('Campo requerido').nullable(),
});

class ExamesForm extends React.Component<{}> {
  constructor(props) {
    super(props);

    this.state = { exame: null, justificativa: null, listaExames: [] };
  }
  onChange = (name, value) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, name, value);
    this.setState(newState);
  };
  componentDidMount() {
    const { paciente, medico, id } = this.props.values;
    const agendamentosHoje = this.props.agendamentosHoje;
    if (agendamentosHoje && agendamentosHoje.length > 0) {
      this.findExamesultimoMes(
        paciente,
        agendamentosHoje[0].estabelecimento,
        id
      );
    }
  }

  findExamesultimoMes(paciente, estabelecimento, id) {
    prontuarioService
      .findListaExames(_.get(paciente, 'id'), _.get(estabelecimento, 'id'), id)
      .then(
        (response) => {
          this.setState({
            listaExames: response.data,
          });
        },
        () => {
          console.log('Não foi possivel lista de exames dos ultimos dias');
        }
      );
  }

  validate = (values) => {
    return exameValidator
      .validate(values, { abortEarly: false })
      .then(() => {})
      .catch((err) => {
        throw err;
      });
  };

  addExame = () => {
    const { values } = this.props;
    let listExamesSolicitados = _.cloneDeep(
      _.get(values, 'listExamesSolicitados', [])
    );
    console.log(values);
    this.validate({ exame: this.state.exame }).then(
      (retorno) => {
        listExamesSolicitados.push({
          exame: this.state.exame,
          justificativa: this.state.justificativa,
          imprimir: true,
        });
        this.setState({ exame: null, justificativa: null }, () => {
          this.props.setFieldValue(
            'listExamesSolicitados',
            listExamesSolicitados
          );
        });
      },
      (error) => {
        let errors = [];
        // let erros = _.pick(, ['path', 'message']);
        _.map(error.inner, function (erro) {
          _.set(errors, erro.path, erro.message);
        });

        this.setState({ error: errors });
      }
    );
  };

  // imprimir = (documentSignVO) => {
  //   const { marcacao, prontuarioReceituario } = this.props.values;
  //   let documentoPrint = {};
  //   let newItens = prontuarioReceituario.listReceituarioItem;
  //   _.set(documentoPrint, 'documentSignVO', documentSignVO);
  //   _.set(documentoPrint, 'marcacaoVO', marcacao);
  //   _.set(documentoPrint, 'marcacaoVO', marcacao);
  //   _.set(documentoPrint, 'listReceituarioItem', newItens);
  //   this.props.loading(true);
  //   prontuarioReceituarioService.imprimir(documentoPrint).then(
  //     (response) => {
  //       var base64 = _.get(response, 'data.data.data');
  //       let asciiString = atob(base64);
  //       let array = new Uint8Array(
  //         [...asciiString].map((char) => char.charCodeAt(0))
  //       );
  //       const file = new Blob([array], { type: 'application/pdf' });
  //       const fileURL = URL.createObjectURL(file);
  //       this.props.loading(false);
  //       window.open(fileURL);
  //     },
  //     (error) => {
  //       console.log(error);
  //       if (documentSignVO && documentSignVO.signdocumnet) {
  //         this.props.error({
  //           message: this.treatSignerSignExceptionGetMessage(
  //             error.response.data
  //           ),
  //         });
  //       } else {
  //         this.props.error({
  //           message:
  //             'Não foi possivel imprimir documento, contate o administrador do sistema.',
  //         });
  //       }
  //       this.props.loading(false);
  //     }
  //   );
  // };
  handleSetOtpCode = (otpCode) => {
    if (otpCode) {
      if (!/^[0-9]+$/.test(otpCode)) {
        this.props.error({
          message: 'O código OTP são somente números',
        });
      } else {
        this.toggleSolutiOtp();
        this.imprimirExames({
          otpCode: otpCode,
          signDocument: true,
          fileType: 'MEDICAL_REQUEST_EXAMINATION',
        });
      }
    } else {
      this.props.error({
        message: 'Insira o seu código otp',
      });
    }
  };
  treatSignerSignExceptionGetMessage = (data) => {
    if ('9001' === data.errorCode) {
      return 'Não foi possível assinar o documento por falta de informações. Complete seu cadastro completo para assinar o documento corretamente. (Endereço, Telefone, Conselho).';
    } else if ('9002' === data.errorCode) {
      return 'Seu código OTP não confere. Por favor valide corretamente o mesmo, junto com seu tempo de expiração e tente novamente.';
    } else {
      return 'Erro desconhecido ao assinar o seu documento. Por favor tente novamente em instantes, caso o erro persista entre em contato com o administrador do sistema.';
    }
  };

  assinarImprimir = () => {
    this.setState(({ openSolutiOtp }) => ({ openSolutiOtp: !openSolutiOtp }));
  };
  toggleSolutiOtp = () => {
    this.setState(({ openSolutiOtp }) => ({ openSolutiOtp: !openSolutiOtp }));
  };

  imprimirExames = (documentSignVO) => {
    const { values } = this.props;
    this.props.loading(true);
    let documento = {};
    _.set(documento, 'marcacaoVO', values.marcacao);
    _.set(documento, 'documentSignVO', documentSignVO);
    let listExameImprimir = [];
    _.map(values.listExamesSolicitados, function (exame) {
      if (exame.imprimir) {
        listExameImprimir.push(exame);
      }
    });
    _.set(documento, 'listExamesPrint', listExameImprimir);
    examesSolicitadosService.imprimir(documento).then(
      (response) => {
        var base64 = _.get(response, 'data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.props.loading(false);
      },
      (error) => {
        if (error.response.status == 404) {
          this.props.error({
            message:
              'Não existe nenhum modelo de solicitação de exame vinculado ao medico. Procure o Administrador do sistema.',
          });
        }
        this.props.loading(false);
      }
    );
  };

  render() {
    const { viewMode, values, setFieldValue } = this.props;
    const { listaExames } = this.state;
    return (
      <div className={`row mt-2 ${this.props.show == 2 ? '' : 'd-none'}`}>
        <div className={'col-12 exameAnteriores'}>
          <label>Exames em aberto ou solicitados nos últimos 30 dias </label>
          <p className="ml-2 small">
            {listaExames &&
              listaExames.map((exameSolicitado, index) => {
                return (
                  <div key={index}>
                    {index + 1} - {exameSolicitado.nome}
                    {' - '}
                    {moment(exameSolicitado.data, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY'
                    )}
                    {' - '}
                    {exameSolicitado.tipo}
                  </div>
                );
              })}
          </p>
        </div>
        <hr />
        <div className="col-12 col-sm-3">
          <InputViewEdit
            component={SelectInput}
            label={'Codigo do exame'}
            id="exame"
            name="exame"
            placeholder="Codigo do exame"
            value={_.get(this.state, 'exame')}
            onChange={this.onChange}
            noSize={true}
            viewMode={viewMode}
            required={true}
            returnFullObject={true}
            valueKey={'id'}
            labelKey={'codigo'}
            multi={false}
            onFetchData={examesSolicitadosService.findAll}
            sortKey={'descricao '}
            erroMensagem={_.get(this.state.error, 'exame')}
          />
        </div>
        <div className="col-12 col-sm-8 ">
          <InputViewEdit
            component={SelectInput}
            label={'Descrição do exame'}
            id="exameDes"
            name="exame"
            placeholder="Descrição do exame"
            value={_.get(this.state, 'exame')}
            onChange={this.onChange}
            noSize={true}
            viewMode={viewMode}
            required={true}
            returnFullObject={true}
            valueKey={'id'}
            labelKey={'descricao'}
            multi={false}
            onFetchData={examesSolicitadosService.findAll}
            sortKey={'descricao '}
            erroMensagem={_.get(this.state.error, 'exame')}
          />
        </div>
        <div className="col-12">
          <InputViewEdit
            label="Justificativa"
            placeholder=""
            viewMode={viewMode}
            component={FormInput}
            returnFullObject={true}
            required={false}
            value={_.get(this.state, 'justificativa')}
            onChange={this.onChange}
            name={`justificativa`}
            id={`quantidade`}
          />
        </div>

        <div className="col-12 mt-2">
          <ModalSolutiOtp
            id="solutiOtp"
            name="solutiOtp"
            toogle={this.toggleSolutiOtp}
            isOpen={this.state.openSolutiOtp}
            handleSetOtpCode={(otpCode) => {
              this.handleSetOtpCode(otpCode);
            }}
          />
          {!viewMode && (
            <button
              type={'button'}
              className={'btn btn-incluir d-flex align-items-center m-auto'}
              onClick={this.addExame}
            >
              <span className={'icon-incluir'}></span>Incluir
            </button>
          )}
          <label>Incluídos:</label>
          <TabelaDetalheProntuario
            viewMode={viewMode}
            label={'exame.descricaoCodigo'}
            description={'justificativa'}
            name={'listExamesSolicitados'}
            values={values}
            edit={this.editMedicamento}
            setFieldValue={setFieldValue}
          />
        </div>
        {values.listExamesSolicitados &&
          values.listExamesSolicitados.length > 0 && (
            <div className={`col-12 d-flex justify-content-end `}>
              <button
                type="button"
                className="btn btn-link"
                onClick={this.assinarImprimir}
              >
                <span className="icon-btn-imprimir"></span>
                Assinar e imprimir
              </button>
              <button
                type="button"
                className="btn btn-link"
                onClick={this.imprimirExames}
              >
                <span className="icon-btn-imprimir"></span>
                Imprimir selecionados
              </button>
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ExamesForm);
