import _ from "lodash";
import React, { PureComponent } from "react";
import { ModalBody, Modal, ModalFooter } from "reactstrap";
import { objectsConstants } from "../../_constants/objects.constants";
import { formatterHelper } from "../../_helpers/formatter.helper";
import anexo from "../../images/icon_anexo_BIG.svg";
import { ConfirmAcationService } from "../../_services/ConfirmAcationService";

import fechar from "../../images/icon_fechar_BLACK.svg";
import { defaultService } from "../../_services/defaultService";
import { urlsConstants } from "../../_constants/urls.constant";
import FormInput from "../inputs/FormInput";
import InputViewEdit from "../inputs/InputViewEdit";
import { cidService } from "../../_services/cid.service";
import CheckBoxInputOnly from "../inputs/CheckBoxInputOnly";

let download = require("downloadjs");

type Props = {
  target?: any,
  itens: [],
};

const modifiers = {
  preventOverflow: {
    enabled: false,
  },
  flip: {
    enabled: false,
  },
};
let fileInput;

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (event) {
    var dataUrl = event.target.result;

    var midiaVO = {
      nome: file.name,
      tamanho: file.size,
      tipo: file.type,
    };
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (validImageTypes.includes(file.type)) {
      var image = new Image();
      image.src = dataUrl;
      image.onload = function () {
        var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
        midiaVO.data = resizedDataUrl;
        fn(midiaVO);
      };
    } else {
      midiaVO.data = dataUrl;
      fn(midiaVO);
    }
  };
}

function resizeImage(image, maxWidth, maxHeight, quality) {
  var canvas = document.createElement("canvas");

  var width = image.width;
  var height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL("image/jpeg", quality);
}

export class ModalCids extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { descricao: '', cids: [], cidsSelecionados: [] };
  }

  onChange = (name, value) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, name, value);
    this.setState(newState);
  }
  remove = (index, cid) => {
    let cidsSelecionados = _.cloneDeep(this.state.cidsSelecionados);
    let cids = _.cloneDeep(this.state.cids);
    cidsSelecionados.splice(index, 1);
    let indexSelecionado = 0;
    cids.map((c, i) => {
      if (c.codigo == cid.codigo) {
        indexSelecionado = i;
      }
    })
    cid.checked = false;
    cids[index] = cid;
    this.setState({ cidsSelecionados, cids });
  }
  pesquisar = () => {
    cidService.findAll(this.state.descricao).then(r => {
      this.setState({ cids: r.data });
    })
  }
  atualizaLista = (cid, index) => {
    let cidsSelecionados = _.cloneDeep(this.state.cidsSelecionados);
    let cids = _.cloneDeep(this.state.cids);
    if (!cid.checked) {
      cidsSelecionados.push(cid);
    } else {
      let indexSelecionado = 0;
      cidsSelecionados.map((c, i) => {
        if (c.codigo == cid.codigo) {
          indexSelecionado = i;
        }
      })
      cidsSelecionados.splice(indexSelecionado, 1);
    }
    cid.checked = !cid.checked;
    cids[index] = cid;
    this.setState({ cidsSelecionados, cids });
  }
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-cids px-2"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="row top-modal ">
              <div className="col-12 text-center mb-2">
                <h2 className="font-weight-bold">Incluir C.I.D.</h2>
              </div>
              <div className="d-flex align-items-end w-100">
                <div className="mb-2 col-8">
                  <FormInput
                    label="Pesquisar doença"
                    placeholder="Informe o codigo C.I.D ou o nome da doença"
                    returnFullObject={true}
                    required={false}
                    value={_.get(this.state, "descricao")}
                    onChange={this.onChange}
                    name={`descricao`}
                    id={`descricao`}
                  />
                </div>
                <button
                  type="button"
                  className="btn  btn-primary d-flex align-items-center col-2"
                  onClick={this.pesquisar}
                >
                  <span className="icon-search"></span>
                  Pesquisar
                </button>
              </div>
            </div>

            <div className="box-list-cid justify-content-center align-items-center">
              <div className="row cabecalho-list-cid  p-4 ml-2 mr-2 align-items-center">
                <p className="col-2"></p>
                <p className="col-2"> C.I.D</p>
                <p className="col-8">Descrição da doença</p>
              </div>
              {this.state.cids.map((c, i) => {
                return (
                  <div className="row item-list-cid align-items-center p-4 ml-2 mr-2 ">
                    <div className="col-2">
                      <CheckBoxInputOnly
                        name={`prontuarioSintetico`}
                        id={"prontuarioSintetico"}
                        className={"mt-2 "}
                        label={``}
                        checked={c.checked}
                        onChange={(checked, name) => {
                          this.atualizaLista(c, i);
                        }}
                      ></CheckBoxInputOnly>
                    </div>
                    <div className="col-2">{c.codigo}</div>
                    <div className="col-8">{c.descricao}</div>
                  </div>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-100 text-center d-flex justify-content-end">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.props.toogle();
                }}
              ><span className="icon-close"></span>
                {" "}
                Fechar{" "}
              </button>

              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.props.inserirSelecionados(this.state.cidsSelecionados);
                }}
              >
                <span className="ico_mais"></span>
                {" "}
                Inserir Selecionados{" "}
              </button>
            </div>

          </ModalFooter>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
