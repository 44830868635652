//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstabelecimentoEdit from './EstabelecimentoEdit';
import EstabelecimentoListagemPage from './EstabelecimentoListagemPage';
import EstabelecimentoView from './EstabelecimentoView';

export default class EstabelecimentoIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/estabelecimento"
          component={EstabelecimentoListagemPage}
          exact
        />
        <Route path="/estabelecimento/novo" component={EstabelecimentoEdit} />
        <Route
          path="/estabelecimento/view/:id"
          component={EstabelecimentoView}
        />
      </Switch>
    );
  }
}
