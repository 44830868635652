//@flow
import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";
import Toast from "./components/Toasts/Toast";
import AgendaOnlineMain from "./pages/agendaOnline/AgendaOnlineMain";
import IndexAgendaOnlinePage from "./pages/agendaOnline/IndexAgendaOnlinePage";
import LoginPage from "./pages/login/LoginPage";
import MainPage from "./pages/main/MainPage";
import TelemedicinaPacientePage from "./pages/TelemedicinaPaciente/TelemedicinaPacientePage";
import { PrivateRoute } from "./_components/PrivateRoute";
import axios from 'axios/index';
import SignDocumentsDownloadPage from "./pages/signDocuments/SignDocumentsDownloadPage";
import LoginOnline from "./pages/agendaOnline/LoginOnline";
import { exact } from "prop-types";
import 'moment/locale/pt-br';
import moment from 'moment';
import AgendaOnlineCancelamento from "./pages/agendaOnline/AgendaOnlineCancelamento";
String.prototype.insert_at = function (index, string) {
  return this.substr(0, index) + string + this.substr(index);
}
// Date.prototype.toJSON = function () {
//   return moment(this).format('YYYY-MM-DD HH:mm:ss')
// }

type Props = {
  alert: Object,
  loggedIn: boolean,
  loginOnline: boolean,
  loading: number
};

class App extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    moment.locale('pt-br');
    localStorage.removeItem("dataAgenda");
  }

  initAxios = (props: any, _app) => {
    axios.interceptors.response.use(
      function (response) {

        return response;
      },
      err => {
        let res = err.response;
        if (
          res &&
          res.status === 402
        ) {

          this.props.setErroPagamento({ erroPagamento: true });
          props.error({
            message: 'Erro no faturamento!',
            code: "Error 402"
          });
        }
        return Promise.reject(err);
      }
    );
  };

  render() {
    const { loggedIn, loginOnline, tokenLoginOnline, erroPagamento } = this.props;
    const loading = this.props.loading > 0;
    this.initAxios(this.props, this);
    return (
      <div className="notranslate">
        <Loading loading={loading}></Loading>
        <Toast></Toast>
        <BrowserRouter>
          <Switch>
            <Route path="/agendaOnline/login" exact component={LoginOnline} />
            <Route path="/agendaOnline" exact component={IndexAgendaOnlinePage} />
            <Route path="/agendaOnline/:token?/:id?" component={IndexAgendaOnlinePage} />
            <Route path="/agendaOnlineCancelamento/:tokenCancelamento" component={AgendaOnlineCancelamento} />
            <Route path="/login/:id?" component={LoginPage} />
            <Route path="/telemedicina/:token" component={TelemedicinaPacientePage} />
            <Route path="/download/:token" component={SignDocumentsDownloadPage} />
            <Route path="/agendamento" component={AgendaOnlineMain} />
            <PrivateRoute path="/" component={MainPage} loggedIn={loggedIn} erroPagamento={erroPagamento} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}




function mapStateToProps(state) {
  const { loggedIn, loginOnline, tokenLoginOnline, erroPagamento } = state.authentication;
  const { load } = state.load;

  return {
    loggedIn,
    loginOnline,
    tokenLoginOnline,
    loading: load,
    erroPagamento

  };
}
const mapDispatch = ({
  fieldMessage: { addAllMessage, clearMessage },
  authentication: { success, setErroPagamento },
  alert: { error, clear, info }
}) => ({
  addAllMessage: messages => addAllMessage(messages),
  success: user => success(user),
  setErroPagamento: erroPagamento => setErroPagamento(erroPagamento),
  clearMessage: () => clearMessage(),
  error: message => error(message),
  info: message => info({ message }),
  clear: clear()
});

const connectedApp = connect(mapStateToProps,
  mapDispatch)(App);
export { connectedApp as App };

