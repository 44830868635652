//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import EstabelecimentoListFilter from './EstabelecimentoListFilter';

const columns = [
  {
    path: 'foto',
    title: '',
    isKey: false,
    isAvatar: true,
    dataSort: false,
    propsMidiaId: 'foto.id',
    className: 'avatar-list',
    width: '100px',
    notExport: true,
  },
  {
    path: 'nome',
    pathFilter: 'nome',
    title: 'Nome do estabelecimento',
    isKey: false,
    dataSort: true,
    className: 'column-bold',
    width: '33%',
  },
  {
    path: 'email',
    pathFilter: 'email',
    title: 'E-mail',
    isKey: false,
    dataSort: true,
    width: '33%',
  },
  {
    path: 'cpfCnpj',
    pathFilter: 'cpfCnpj',
    title: 'CPF ou CNPJ',
    cpfCnpj: true,
    isKey: true,
    dataSort: true,
    width: '33%',
  },
];

const endPoint = '/estabelecimento/novo';
const endPointView = '/estabelecimento/view/';
class EstabelecimentoListage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    // this.props.history.push({
    //   pathname: endPoint,
    //   state: {
    //     titlePage: 'Colaboradores',
    //   },
    // });
  };

  // getAvatar(entity: any) {
  //   if (entity.foto) {
  //     return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
  //   }
  // }
  // renderCustom(row: any, entity: any, column: any) {
  //   var avatarUrl = require('../../img/avatarDefault.svg');
  //   if (column.path == 'avatar') {
  //     if (entity.foto) {
  //       avatarUrl = `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
  //     }
  //     return (
  //       <img
  //         src={avatarUrl}
  //         alt="avatar"
  //         className={`rounded-circle m-auto `}
  //         width="50"
  //         height="50"
  //       />
  //     );
  //   } else if (column.path == 'status') {
  //     if (entity.status) {
  //       return <div className={`txtGreen`}>ATIVO</div>;
  //     } else {
  //       return <div className={`txtRed`}>INATIVO</div>;
  //     }
  //   } else if (column.path == 'role') {
  //     return (
  //       <React.Fragment> {_.get(constHelper, entity.role)}</React.Fragment>
  //     );
  //   } else {
  //     return tableHelper.renderCustom(row, entity, column);
  //   }
  // }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_ESTABELECIMENTO}
          subTitle=" "
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_ESTABELECIMENTOS_EDITAR}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo estabelecimento'}
        ></TopoListagem>
        <ListagemPage
          title={`Estabelecimentos`}
          showNewButton={false}
          // newButtonActionClick={this.handleNewButton}
          delete={() => {}}
          service={estabelecimentoService}
          // labelNewButton="Novo estabelecimento"
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={EstabelecimentoListFilter}
          fields={this.state.fields}
          controller="api/estabelecimento"
          labels={this.state.labels}
          // roleNewButton={objectsConstants.CRUD_ESTABELECIMENTOS_EDITAR}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(EstabelecimentoListage);
