//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstoqueRelatorioMovimentacaoListagemPage from './EstoqueRelatorioMovimentacaoListagemPage';

export default class EstoqueRelatorioMovimentacaoIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/estoque-relatorio-movimentacao"
          component={EstoqueRelatorioMovimentacaoListagemPage}
        />
      </Switch>
    );
  }
}
