//@flow
import { getPermission } from '../config/ability';
import type { UserType } from '../types/user.type';
import { authenticationService } from '../_services/authentication.service';
import { estabelecimentoService } from '../_services/estabelecimento.service';
import { userService } from '../_services/user.service';


type State = {
  loggedIn: boolean,
  loginOnline: Boolean,
  user: UserType,
  refresh: boolean,
  tokenLoginOnline: string,
  permissions: any,
  erroPagamento: boolean
};

type Payload = {
  user: UserType,
  company: any
};

export default {
  state: {
    loggedIn: false,
    loginOnline: false,
    selectPerfil: false,
    erroPagamento: false,
    morePerfil: false,
    user: {},
    refresh: false,
    company: {},
    tokenLoginOnline: '',
    permissions: {}
  },
  reducers: {
    success: (state: State, payload: Payload) => {
      let permissions = getPermission(payload.user);
      return Object.assign({}, state, {
        loggedIn: true,
        loginOnline: payload.loginOnline,
        user: payload.user,
        permissions: permissions
      });
    },
    logout: (state: State) => {
      authenticationService.doLogout();
      return Object.assign({}, state, {
        loggedIn: false,
        loginOnline: false,
        user: {},
        permissions: {},
        selectPerfil: false,
        morePerfil: false
      });
    },
    logoutOnline: (state: State) => {
      authenticationService.doLogout();
      return Object.assign({}, state, {
        loggedIn: false,
        loginOnline: true,
        user: {},
        permissions: {}
      });
    },
    setTokenLoginOnline: (state: State, payload: Payload) => {
      console.log(payload);
      return Object.assign({}, state, {
        tokenLoginOnline: payload.token,
      });
    },
    refreshUser: (state: State, payload: Payload) => {
      //TODO: change user quando precisar
      return Object.assign({}, state, {
        refresh: true,
        user: payload.user
      });
    },
    loadCompany: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        refresh: true,
        company: payload.company
      });
    },
    selectPerfil: (state: State, payload: Payload) => {
      return Object.assign({}, state, {

        selectPerfil: payload.selectPerfil
      });
    },
    setErroPagamento: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        erroPagamento: payload.erroPagamento
      });
    },

    morePerfil: (state: State, payload: Payload) => {
      return Object.assign({}, state, {

        morePerfil: payload.morePerfil
      });
    }
  },
  effects: (dispatch: any) => ({
    login: async (payload: { user: string, password: string, loginOnline: boolean }) => {
      console.log(payload.user, payload.password, payload.perfil);
      await authenticationService.doLogin(payload.user, payload.password, payload.perfil, payload.loginOnline).then(
        async () => {
          let user = await userService.me().then(response => {
            const user = response.data;
            if (userService.isMedico()) {
              try {
                estabelecimentoService.getLocalAtendimentoByMedico().then(result => {
                  if (result.data) {
                    console.log(result.data);
                    user.listLocalAtendimento = result.data;
                    dispatch.authentication.success({ user }, payload.loginOnline);
                  }
                })

              } catch (error) {
                console.log(error)
                dispatch.authentication.success({ user }, payload.loginOnline);
              }

            } if (userService.isSecretaria()) {
              try {
                estabelecimentoService.getLocalAtendimentoByRecepcionista().then(result => {
                  if (result.data) {
                    user.listLocalAtendimentoRecepcao = result.data;
                    dispatch.authentication.success({ user }, payload.loginOnline);
                  }
                })

              } catch (error) {
                console.log(error)
                dispatch.authentication.success({ user }, payload.loginOnline);
              }
            } else {

              dispatch.authentication.success({ user }, payload.loginOnline);
            }
            // dispatch.load.loading({ load: false });
          });

        },
        (e) => {
          if (e !== 401) {
            //payload.loginOnline ? dispatch.authentication.logoutOnline() : dispatch.authentication.logout();
            dispatch.alert.error({
              message: 'Confira o seu login e senha e tente novamente!',
              code: 'Ops,'
            });
            dispatch.load.loading({ load: false });
          } else {
            dispatch.authentication.selectPerfil({ selectPerfil: true });
            // dispatch.authentication.morePerfil({ morePerfil: true });
            dispatch.alert.warning({
              message: 'Selecione o perfil desejado para login',
              code: 'Ops,'
            });
          }
        }
      ).catch(e => {
        console.log(e)
      });
    },
    async doRefresh() {
      console.log('doRefresh');
      console.log(authenticationService.getlocalStorageFull());
      if (authenticationService.hasToken()) {
        try {
          await userService.me().then(response => {
            const user = response.data;
            if (userService.isMedico()) {
              try {
                estabelecimentoService.getLocalAtendimentoByMedico().then(result => {
                  if (result.data) {
                    user.listLocalAtendimento = result.data;
                    dispatch.authentication.success({ user });
                  }
                })

              } catch (error) {
                dispatch.authentication.success({ user });
              }

            } else if (userService.isSecretaria()) {
              try {
                estabelecimentoService.getLocalAtendimentoByRecepcionista().then(result => {
                  if (result.data) {
                    user.listLocalAtendimentoRecepcao = result.data;
                    dispatch.authentication.success({ user });
                  }
                })

              } catch (error) {
                console.log(error)
                dispatch.authentication.success({ user });
              }
            } else {
              if (userService.isPaciente()) {
                let estabelecimentoSalvo = authenticationService.getCurrentEstabelecimentoOnline();
                let token = authenticationService.getTokenOnline();
                dispatch.authentication.setTokenLoginOnline({ token })
                dispatch.agendaOnline.setEstabelecimento({ estabelecimento: estabelecimentoSalvo });
                dispatch.authentication.success({ user: user, loginOnline: true });
              } else {
                dispatch.authentication.success({ user });
              }

            }
          },
            error => {
              localStorage.clear();
              dispatch.authentication.logout();
            }
          );
        } catch (error) {
          console.log(error);
          dispatch.authentication.logout();
        }
      }
    }
  })
};
