//@flow
import _ from 'lodash';
import * as React from 'react';
import { objectsConstants } from '../../_constants/objects.constants';
import { urlsConstants } from '../../_constants/urls.constant';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { marcacaoService } from '../../_services/marcacao.service';
import { userService } from '../../_services/user.service';
import DadosUser from '../../components/DadosUser/DadosUser';
import ButtonAutorized from '../buttons/ButtonAutorized';

type Props = {
  horario: any,
  idMarcacao: number,
};

type State = {
  marcacao: any,
};

export class HorarioRemarcacaoRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { paciente: null };
  }

  componentDidMount() {
    this.findMarcacao(this.props.idMarcacao);
  }

  handleChangeStatus = () => {
    if (this.props.clientSocket && this.props.clientSocket.connected) {
      this.props.clientSocket.send(
        urlsConstants.TASK_STATUS_CHANGE,
        JSON.stringify({
          id: this.props.idMarcacao,
          status: { name: 'EM_ANDAMENTO' },
        })
      );
      this.props.remarcar(this.state.marcacao);
    }
  };

  cancelMarcacao = () => {
    this.props.clientSocket.send(
      urlsConstants.SEND_PACIENTE_STATUS,
      JSON.stringify({
        id: this.props.idMarcacao,
        status: objectsConstants.CANCELADO,
      })
    );
  };

  findMarcacao = (idMarcacao) => {
    marcacaoService.doGet(idMarcacao).then(
      (response) => {
        this.setState({
          marcacao: response.data.data,
        });
      },
      () => {
        console.log('Não foi possivel buscar marcação');
      }
    );
  };

  render() {
    const { horario } = this.props;

    // let horaMenorQueAgora = this.horaMenorQueAgora(horario) || indisponivel;
    return (
      <div className="row timetable flex-column flex-lg-row align-items-center">
        <div className="hour">{horario}</div>
        <div className="patient pl-0 pl-sm-5">
          <React.Fragment>
            {this.state.marcacao && (
              <DadosUser
                getInfoSecundaria={
                  this.state.marcacao.paciente.telefones
                    ? _.get(this.state.marcacao.paciente.telefones[0], 'numero')
                    : 'Nenhum telefone cadastrado'
                }
                idUser={this.state.marcacao.paciente.id}
                marcacao={this.state.marcacao}
              ></DadosUser>
            )}
          </React.Fragment>
        </div>

        <div className="d-flex justify-content-center">
          <React.Fragment>
            {this.createCancelarButton()}
            {this.createReMarcarButton()}
          </React.Fragment>
        </div>
      </div>
    );
  }

  createReMarcarButton = () => {
    return (
      <ButtonAutorized
        className="btn-blue"
        label={'Remarcar paciente'}
        role={objectsConstants.AGENDA_MARCAR}
        onClick={() => this.handleChangeStatus()}
        disabled={userService.isSuporte()}
      ></ButtonAutorized>
    );
  };

  createCancelarButton = (value?: any) => {
    return (
      <ButtonAutorized
        className="btn-danger delete"
        label={'Desmarcar'}
        label={'Cancelar marcação'}
        role={objectsConstants.AGENDA_MARCAR}
        disabled={userService.isSuporte()}
        onClick={(e) => {
          ConfirmAcationService.confirmMsg(
            () => {
              this.cancelMarcacao();
            },
            'Desmarcar atendimento',
            <span>Deseja realmente desmarcar esse atendimento?</span>,
            <span className="text-danger">{}</span>,
            'Não',
            'Sim',
            objectsConstants.TYPE_WARNING
          );
        }}
      ></ButtonAutorized>
    );
  };
}
