//@flow
import { oauthHeaderJson } from './../_helpers/oauth-header';
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';
import { objectsConstants } from '../_constants/objects.constants';
import Moment from 'moment';
import _ from 'lodash';

export const agendaService = {
  doList,
  findHorariosConsulta,
  findMarcacaoByPaciente,
  findMarcacaoExamesByPaciente,
  imprimir,
  findHorariosConsultaPublic,
  buscaHorariosDisponiveis,
  getPendingNotifyMessages,
};

function buscaHorariosDisponiveis(filtro) {
  return new Promise((resolve, reject) => {
    Promise.all([
      defaultService.doPost(`${urlsConstants.AGENDA}horariosLivres`, filtro),
      defaultService.doPost(`${urlsConstants.AGENDA}medicoList`, filtro),
    ]).then(
      (response) => {
        let agenda = response[0].data;
        let medicos = response[1].data;
        _.map(agenda, (a) => {
          _.map(medicos, (m) => {
            if (a.medico != null && m.id == a.medico.id) {
              if (a.medico.localAtendimento) {
                m.localAtendimento = a.medico.localAtendimento;
              }
              a.medico = _.cloneDeep(m);
            }
          });
        });
        response[0].data = agenda;

        resolve(response[0]);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function findHorariosConsultaPublic(filtro) {
  return defaultService.doPost(`${urlsConstants.AGENDA_PUBLIC}list`, filtro);
}

function findHorariosConsulta(filtro) {
  return new Promise((resolve, reject) => {
    Promise.all([
      defaultService.doPost(`${urlsConstants.AGENDA}list`, filtro),
      defaultService.doPost(`${urlsConstants.AGENDA}medicoList`, filtro),
    ]).then(
      (response) => {
        let agenda = response[0].data;
        let medicos = response[1].data;
        _.map(agenda, (a) => {
          _.map(medicos, (m) => {
            if (a.medico != null && m.id == a.medico.id) {
              if (a.medico.localAtendimento) {
                m.localAtendimento = a.medico.localAtendimento;
              }
              a.medico = _.cloneDeep(m);
            }
          });
        });
        response[0].data = agenda;

        resolve(response[0]);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function findMarcacaoByPaciente(filtro) {
  return defaultService.doPost(
    `${urlsConstants.AGENDA_PUBLIC}marcacao`,
    filtro
  );
}

function findMarcacaoExamesByPaciente(filtro) {
  return defaultService.doPost(
    `${urlsConstants.AGENDA_PUBLIC}marcacaoExame`,
    filtro
  );
}

function imprimir(filtroPrint) {
  if (filtroPrint.tipoAgenda == objectsConstants.TIPO_AGENDA_EXAMES) {
    return defaultService.doPost(
      `${urlsConstants.AGENDA}imprimirExames`,
      filtroPrint
    );
  }
  if (filtroPrint.tipoAgenda == objectsConstants.TIPO_AGENDA_EXAMES) {
    return defaultService.doPost(
      `${urlsConstants.AGENDA}imprimirExames`,
      filtroPrint
    );
  }
  return defaultService.doPost(`${urlsConstants.AGENDA}imprimir`, filtroPrint);
}

function getPendingNotifyMessages(channel, dateTimeLastSync) {
  let formatDateTime = Moment(dateTimeLastSync).format('YYYYMMDDHHmmss');
  return defaultService.doPost(
    `${urlsConstants.PENDING_NOTIFY_MESSAGES_LIST}${formatDateTime}`,
    channel
  );
}

function doList(fkMedico: number, year: number, month: number) {
  let dataStart = new Date(year, month, 1);
  let dataEnd = new Date(year, month + 1, 0);
  let filtro = {
    fkMedico,
    dataStart: dataStart.toISOString().split('T')[0],
    dataEnd: dataEnd.toISOString().split('T')[0],
  };

  const requestOptions = {
    method: 'POST',
    headers: oauthHeaderJson(),
    body: JSON.stringify(filtro),
  };

  let p: Promise<any> = fetch(urlsConstants.AGENDA_LIST, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        return data.entityReturn;
      }
      return data;
    });
  return p;
}
