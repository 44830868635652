//@flow
type State = {
    filtroAberto: boolean,
};

export default {
    state: { filtroAberto: false },
    reducers: {
        toggleFiltro: (state: State) => {
            console.log('ToogleFiltro');
            return Object.assign({}, state, {
                filtroAberto: !state.filtroAberto
            });
        },
        abreFiltro: (state: State,) => {
            return Object.assign({}, state, {
                filtroAberto: true,
            });
        },
        fechaFiltro: (state: State) => {
            return Object.assign({}, state, {
                filtroAberto: false
            });
        },

    }
};
