//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import FormTextArea from '../../components/inputs/FormTextArea';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { objectsConstants } from '../../_constants/objects.constants';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { marcacaoService } from '../../_services/marcacao.service';

class ObservacaoEditForm extends React.Component<> {
  static defaultProps = {
    agendamentoMode: false,
    hideAddres: false,
    backUrl: '/agenda',
  };

  goToViewEntity = (values) => {
    this.props.history.goBack();
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let atendimento = this.props.atendimento;
    let dataConsulta = moment(atendimento.data, 'DD/MM/YYYY');
    let marcacao = {
      id: atendimento.idMarcacao,
      fkMedico: _.get(atendimento, 'medico.id'),
      exame: _.get(atendimento, 'exame'),
      fkEstabelecimentoAtendimento: _.get(
        atendimento,
        'estabelecimentoAtendimento.id'
      ),
      fkPaciente: atendimento.paciente.id,
      fkCovenio: _.get(atendimento.convenio, 'id'),
      hora: atendimento.hora,
      dataConsulta: dataConsulta.format('YYYY-MM-DD'),
      status: atendimento.status,
      horaString: atendimento.horario,
      tipoAtendimento: atendimento.tipoAtendimento,
      tipoConsulta: atendimento.tipoConsulta,
      telemedicina: _.get(atendimento, 'telemedicina'),
      observacoes: values.observacoes,
    };

    _this.formRef.validateForm(values).then((error) => {
      if (_.isEmpty(error)) {
        _this.props.loading(true);
        marcacaoService.doSave(marcacao).then(
          (response) => {
            _this.props.success({
              message: `Observação, foi atualizada com sucesso!`,
            });
            //_this.props.loading(false);
            //fechar a janela de edição do observação
            this.props.closeEdicao();
            console.log('response ', response);

            _this.props.loading(false);

            this.goToViewEntity(values);
          },
          (error) => {
            _this.props.loading(false);
            _this.props.error({
              message: 'Não foi possível salvar Observação.',
            });
            console.log(error);
          }
        );
      }
    });
  };

  render() {
    let { atendimento } = this.props;

    console.log('PROPPSSSS', this.props);

    return (
      <React.Fragment>
        <Formik
          //validationSchema={LocaleHorarioFuncionamento}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={atendimento}
          onSubmit={(values) => {
            ConfirmAcationService.confirmMsg(
              () => {
                this.saveForm();
              },
              ' Tem certeza que deseja atualizar?',
              <span>
                Deseja realmente atualizar a observação desse atendimento?
              </span>,
              <span className="text-danger">{}</span>,
              'Não',
              'Sim',
              objectsConstants.TYPE_WARNING
            );

            //mandar nova observação para o banco
          }}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldError,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <InputViewEdit
                  component={FormTextArea}
                  className={'textArea'}
                  label="Observações"
                  id={'observacoes'}
                  name={'observacoes'}
                  onChange={handleChange}
                  rows={4}
                  maxLength={300}
                  viewClassName={'col-12'}
                  placeholder="Insira aqui as alterações para as observações"
                  value={values.observacoes}
                />
              </div>
              <section className={'d-flex justify-content-start'}>
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      this.props.closeEdicao();
                    }}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary">Salvar</button>
                </div>
              </section>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ObservacaoEditForm);
