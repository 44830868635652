//@flow
import _, { values } from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import { convenioService } from '../../_services/convenio.service';
import ConvenioForm from './ConvenioForm';
import PlanosForm from './PlanosForm';
import ProcedimentosForm from './ProcedimentosForm';

const defaultEntity = {
  listProcedimentos: [{}],
  listPlanos: [{}],
};

class ConvenioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {

    this.loadConvenio();
  }

  loadConvenio = (convenio) => {
    convenioService.doGet(this.props.match.params.id).then(
      (response) => {
        let newEntity = response.data.data;
        if (!newEntity.listProcedimentos) {
          newEntity.listProcedimentos = [{}]
        } 
        if (!newEntity.listPlanos) {
          newEntity.listPlanos = [{}]
        }
        this.setState({ entity: newEntity })
      }
    );
  };

  render() {
    const { entity } = this.state;
    const { ...otherProps } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={'View'}
          canBack={true}
          backUrl={'/convenio'}
        />

        <div className="flex-column">
          <FullCustomCard
            ref={(form) => {
              this.refFormComponent = form;
            }}
            permissionSave={objectsConstants.MENU_USUARIO}     // Mudar objectsContstants //
            title="Convênio"
            Componente={ConvenioForm}
            viewMode={true}
            entity={entity}
            onCancel={this.handleCancel}
            {...otherProps}
          ></FullCustomCard>
        </div>

        {entity.id && (
          <React.Fragment>
            <FullCustomCard
              ref={(form) => {
                this.refFormComponent = form;
              }}
              permissionSave={objectsConstants.MENU_USUARIO}     // Mudar objectsContstants //
              title="Procedimento"
              Componente={ProcedimentosForm}
              viewMode={true}
              entity={entity}
              onCancel={this.handleCancel}
              {...otherProps}
            ></FullCustomCard>

            <FullCustomCard
              ref={(form) => {
                this.refFormComponent = form;
              }}
              permissionSave={objectsConstants.MENU_USUARIO}     // Mudar objectsContstants //
              title="Planos"
              Componente={PlanosForm}
              viewMode={true}
              entity={entity}
              onCancel={this.handleCancel}
              {...otherProps}
            ></FullCustomCard>
          </React.Fragment>
        )}

      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  null,
  mapDispatch,
  null
)(withRouter(ConvenioView));