//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { translate } from '../../../_helpers/messages.helper';
import { defaultService } from '../../../_services/defaultService';
import { precificacaoService } from '../../../_services/precificacao.service';
import { produtoService } from '../../../_services/produto.service';
import ItemPrecificacao from './precificacao/ItemPrecificacao';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};
const OBRIGATORIO = 'Obrigatório';
const STRING_REQUIRED = Yup.string().required(OBRIGATORIO);
const EstoqueProdutosPrecificacaoValidate = Yup.object().shape({
  listPrecificacao: Yup.array().of(
    Yup.object().shape({
      listConvenio: STRING_REQUIRED,
      unidadePadrao: STRING_REQUIRED,
      precoVenda: STRING_REQUIRED,
    })
  ),
});

class EstoqueProdutosPrecificacaoForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  addPrecificacao = (
    propName,
    defaultValue,
    values,
    setValues,
    createAttribute?: boolean
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName]) {
      newValues[propName].unshift(
        defaultValue
          ? defaultValue
          : { idList: new Date().getTime(), estoque: newValues.id }
      );
      setValues(newValues);
    }
    if (!newValues[propName] && createAttribute) {
      _.set(newValues, `${propName}`, [
        { idList: new Date().getTime(), estoque: newValues.id },
      ]);
      setValues(newValues);
    }
  };

  removePrecificacao = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length >= 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };
  saveForm = () => {
    console.log('SVForm');
    let _this = this;
    let values = _this.formRef.state.values;
    console.log(values);
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          precificacaoService.doSave(values.listPrecificacao).then(
            (response) => {
              _this.props.success({
                message: `Precificação por convênio, foi ${
                  !values.id ? 'criada' : 'alterada'
                } com sucesso!`,
              });
              _this.props.loading(false);
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar Precificação por convênio.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          console.log(error);
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };
  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EstoqueProdutosPrecificacaoValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    onClick={() =>
                      this.addPrecificacao(
                        'listPrecificacao',
                        null,
                        values,
                        setValues,
                        true
                      )
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Adicionar novo preço
                  </button>
                </div>

                {values.listPrecificacao &&
                  values.listPrecificacao.map((precificacao, index) => {
                    return (
                      <div
                        className="row section-form item-precificacao"
                        key={index}
                      >
                        <ItemPrecificacao
                          entity={values}
                          index={index}
                          viewMode={viewMode}
                          size={values.listPrecificacao.length}
                          handleChange={handleChange}
                          errors={errors}
                          propName={`listPrecificacao[${index}]`}
                          onChange={setFieldValue}
                          removeItem={() => {
                            this.removePrecificacao(
                              `listPrecificacao`,
                              index,
                              values,
                              setValues
                            );
                          }}
                        ></ItemPrecificacao>
                      </div>
                    );
                  })}
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(EstoqueProdutosPrecificacaoForm);
