//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { ModalProfissional } from '../../components/Modal/ModalProfissional';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { medicoService } from '../../_services/medico.service';
import MedicoListFilter from './MedicoListFilter';
const columns = [
  {
    path: 'foto',
    title: '',
    isKey: false,
    isAvatar: true,
    dataSort: false,
    propsMidiaId: 'foto.id',
    className: 'avatar-list',
    width: '100px',
    notExport: true,
  },
  {
    path: 'apelido',
    pathFilter: 'nome',
    title: 'Nome do profissional de saúde',
    isKey: false,
    dataSort: true,
    className: 'nomeProprio column-bold',
    width: '33%',
  },
  {
    path: 'email',
    pathFilter: 'email',
    title: 'E-mail',
    isKey: false,
    dataSort: true,
    width: '33%',
  },
  {
    path: 'conselhoString',
    pathFilter: 'conselho',
    title: 'Conselho',
    isKey: true,
    dataSort: true,
    width: '33%',
  },
];

const endPoint = '/medico/novo';
const endPointView = '/medico/';
class MedicoListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this._refModalSearch.toogleModal();
  };

  salvarModalLocal = (medico) => {
    if (medico.id) {
      this.props.history.push(endPointView + medico.id);
    } else {
      this.props.history.push(endPoint);
    }
  };

  render() {
    const { ...otherProps } = this.props;
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFieldFilter={column.pathFilter}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_PROFISSIONAIS}
          subTitle=" "
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_MEDICO_EDITAR}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo profissional de saúde'}
        ></TopoListagem>
        <ListagemPage
          title="Profissionais"
          showNewButton={false}
          delete={() => {}}
          service={medicoService}
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={MedicoListFilter}
          controller="medico"
          fields={this.state.fields}
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
        <ModalProfissional
          ref={(r) => (this._refModalSearch = r)}
          callbackOnSave={this.salvarModalLocal}
          {...otherProps}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(MedicoListagemPage);
