//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import HorarioAtendimentoExames from '../../components/HorarioAtendimentoExames/HorarioAtendimentoExames';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { exameService } from '../../_services/exame.service';
import ExameForm from './ExameForm';

const defaultEntity: exame = {
  nome: '',
  recomendacoes: '',
};

class ExameEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      exameService.doGet(this.props.match.params.id).then(
        (response) => {
          let exame = response.data.data;
          this.setState({ entity: exame }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  exame`}
          canBack={true}
          backConfirmObject={{
            title: `Cancelar ${!entity.id ? 'criação' : 'Edição'} `,
            message:
              'Ao clicar em sim, todos os dados inseridos serão perdidos. Você deseja realmente ' +
              `cancelar a ${!entity.id ? 'criação' : 'Edição'} do exame?`,
            subMessage: null,
            type: objectsConstants.TYPE_CANCEL,
          }}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>
        <div className="flex-column">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_EXAME}
            title="Exame"
            Componente={ExameForm}
            viewMode={false}
            entity={entity}
            redirectPath="/exame/view/"
            onCancel={this.handleCancel}
            {...otherPops}
          ></FullCustomCard>
          {entity.id && (
            <FullCustomCard
              title="Local e horários de atendimento"
              permissionSave={objectsConstants.MENU_EXAME}
              Componente={HorarioAtendimentoExames}
              viewMode={true}
              entity={entity}
              {...otherPops}
            ></FullCustomCard>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(ExameEdit)
);
