//@flow

////////////////////////////////// IMPORTANTE //////////////////////////////////////
/////////////// Este arquivo é utilizado somente no crud de PACIENTE ///////////////
///// Para marcações de consultas/Exames alterar o novo arquivo *MarcacaoForm* /////
////////////////////////////////////////////////////////////////////////////////////

import { Formik } from 'formik';
import _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import Avatar from '../../components/Avatar/Avatar';
import ToogleButton from '../../components/buttons/ToogleButton';
import ConveniosPlanosPaciente from '../../components/ConveniosPlanosPaciente/ConveniosPlanosPaciente';
import { FormGroup } from '../../components/FormGroup';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import DatePickerInput from '../../components/inputs/DatePickerInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import FormTextArea from '../../components/inputs/FormTextArea';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import ZipCodeInput from '../../components/inputs/ZipCodeInput';
import { ModalAnexos } from '../../components/Modal/ModalAnexos';
import MultiTelefones from '../../components/MultiTelefones/MultiTelefones';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { translate } from '../../_helpers/messages.helper';
import { convenioService } from '../../_services/convenio.service';
import { defaultService } from '../../_services/defaultService';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { marcacaoService } from '../../_services/marcacao.service';
import { medicoService } from '../../_services/medico.service';
import { pacienteService } from '../../_services/paciente.service';
import ApiIBGE from '../../_services/uf.service';
import { etapaAtendimentoService } from '../../_services/etapaAtendimento.service';
import FormTextCKEditor from '../../components/inputs/FormTextCKEditor';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
  exibeRetorno: boolean,
  atendePlano: boolean,
};

// const defaultPaciente = {
//   nome: '',
//   email: '',
//   // cpfCnpj: '',
//   telefones: [{}],
//   observacoes: '',
//   tipoAtendimento: objectsConstants.TIPO_ATENDIMENTO_PARTICULAR,
//   pacienteConvenios: [
//     // {
//     //   convenio: {},
//     //   plano: '',
//     //   carteirinha: '',
//     //   validadeCarteirinha: '',
//     // },
//   ],
// };

const REQUIRED_STRING_VALIDATE = Yup.string()
  .nullable()
  .required('Obrigatório');
const PacienteValidate = Yup.object().shape({
  nome: Yup.string()
    .min(7, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Obrigatório')
    .matches(/^[A-ZÀ-Úa-zà-ú\s]+$/, 'Somente letras são aceitas'),
  // dataNascimento: Yup.date()
  //   .required('Data de nascimento obrigatório')
  //   .max(new Date(), 'Data de nascimento deve ser menor que hoje'),
  dataNascimento: Yup.string().test(
    'valid-data-nascimento',
    'Data de nascimento obrigatório',
    function (value) {
      const { requiredAddres } = this.parent;
      if (requiredAddres) return value != null;
      return true;
    }
  ),
  etapaAtendimento: Yup.string()
    .nullable()
    .test('valid-etapa-atendimento', 'obrigatório', function (value) {
      const { cpfCnpj, etapaAtendimentoObrigatorio, etapaAtendimento } =
        this.parent;
      console.log(etapaAtendimentoObrigatorio);
      console.log(etapaAtendimento);
      if (etapaAtendimentoObrigatorio && !etapaAtendimento) {
        return this.createError({
          path: 'etapaAtendimento',
          message: 'Obrigatório',
        });
      }
      console.log(123);
      return true;
    }),
  email: Yup.string().test(
    'valid-email',
    'E-mail ou CPF é obrigatório',
    function (value) {
      const { cpfCnpj, telemedicina, requiredAddres } = this.parent;
      if (telemedicina && !value) {
        return this.createError({
          path: 'email',
          message: 'E-mail obrigatório, para receber link da consulta',
        });
      }
      if (!cpfCnpj && requiredAddres) return value != null;
      return true;
    }
  ),
  cpfCnpj: Yup.string().test(
    'valid-cpf',
    'CPF ou E-mail é obrigatório',
    function (value) {
      const { email, requiredAddres } = this.parent;
      if (!email && requiredAddres) return value != null;
      return true;
    }
  ),

  telefones: Yup.array()
    .min(1, 'É necessario informar pelo menos um telefone')
    .of(
      Yup.object().shape({
        tipo: Yup.string().required('Obrigatório'),
        numero: Yup.string().required('Obrigatório'),
      })
    ),
  pacienteConvenios: Yup.array()
    .of(
      Yup.object().shape({
        convenio: Yup.string().nullable(),
        numeroCarteirinha: Yup.string().nullable(),
        dataValidadeCarteirinha: Yup.date().nullable(),
      })
    )
    .when('tipoAtendimento', {
      is: (tipoAtendimento) =>
        tipoAtendimento === objectsConstants.TIPO_ATENDIMENTO_CONVENIO,
      then: Yup.array().of(
        Yup.object().shape({
          convenio: Yup.string().required('Obrigatório'),
        })
      ),
    })
    .when(['hideCarteirinha', 'tipoAtendimento'], {
      is: (hideCarteirinha, tipoAtendimento) =>
        !hideCarteirinha &&
        tipoAtendimento === objectsConstants.TIPO_ATENDIMENTO_CONVENIO,
      then: Yup.array().of(
        Yup.object().shape({
          convenio: REQUIRED_STRING_VALIDATE,
          numeroCarteirinha: REQUIRED_STRING_VALIDATE,
          dataValidadeCarteirinha: Yup.date()
            .required('Data de validade obrigatória')
            .min(
              new Date(),
              'A data inserida está vencida, favor conferir a data da carteirinha do paciente'
            ),
        })
      ),
    }),
  medico: Yup.object()
    .nullable()
    .when('requiredMedico', (requiredMedico, schema) => {
      if (requiredMedico) {
        return Yup.string().required('Obrigatório');
      } else {
        return Yup.mixed().notRequired();
      }
    }),

  endereco: Yup.object()
    .nullable()
    .when('requiredAddres', (requiredAddres, schema) => {
      if (requiredAddres) {
        return Yup.object().shape({
          cep: REQUIRED_STRING_VALIDATE,
          logradouro: REQUIRED_STRING_VALIDATE,
          numero: REQUIRED_STRING_VALIDATE,
          bairro: REQUIRED_STRING_VALIDATE,
          cidade: REQUIRED_STRING_VALIDATE,
          estado: REQUIRED_STRING_VALIDATE,
        });
      } else {
        return Yup.mixed().notRequired();
      }
    }),
  nomeMae: Yup.object()
    .nullable()
    .when('requiredAddres', (requiredAddres, schema) => {
      if (requiredAddres) {
        return Yup.string().required('Obrigatório');
      } else {
        return Yup.string().notRequired();
      }
    }),
  // especialidade: REQUIRED_STRING_VALIDATE,
  especialidade: Yup.object()
    .nullable()
    .when('requiredEspecialidade', (requiredEspecialidade, schema) => {
      if (requiredEspecialidade) {
        return Yup.string().required('Obrigatório');
      } else {
        return Yup.string().notRequired();
      }
    }),
});

class PacienteForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      exibeRetorno: false,
      atendePlano: false,
      medicoExames: [],
      valorConsultaParticular: 0,
      listEtapaAtendimento: [],
      etapaAtendimento: null,
      openAnexo: false,
    };
    if (this.props.entity.id) {
      this.verificaAtendeRetorno();
    }
  }
  static defaultProps = {
    agendamentoMode: false,
    hideAddres: false,
  };

  componentDidMount() {
    const { estabelecimento, confirmaPresenca, entity } = this.props;
    let idEstabelecimento = _.get(estabelecimento, 'id');
    if (entity && entity.agendamento && entity.agendamento.status) {
      this.getValorConsultaParticular();
      this.props.loading(true);
      etapaAtendimentoService
        .findProximasEtapas(
          idEstabelecimento,
          entity.agendamento.status,
          entity.agendamento.especialidade
        )
        .then((response) => {
          console.log(response);
          this.props.loading(false);
          if (response.data && response.data.length > 1) {
            this.setState({ listEtapaAtendimento: response.data });
          } else if (response.data && response.data.length === 1) {
            this.setState({ etapaAtendimento: response.data[0] });
          }
        });
    }
    let convenio = this.props.entity.id
      ? _.get(this.props.entity, 'pacienteConvenios[0].convenio', null)
      : null;

    let tipoAtendimento =
      _.get(this.props.entity, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CONVENIO;
    if (
      this.props.agendamentoMode &&
      convenio != null &&
      this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS
    ) {
      console.log('Verifica se atende o plano');
      this.verificaAtendePlanoConvenio(convenio).then((response) => {
        this.setState({ atendePlano: response });
      });
    }
  }
  getValorConsultaParticular = () => {
    const { estabelecimentoAtendimento } = this.props;
    let idEstabelecimentoAtendimento = _.get(estabelecimentoAtendimento, 'id');
    estabelecimentoService
      .getValorConsultaParticular(idEstabelecimentoAtendimento)
      .then((response) => {
        let valConsultaParticular = formatterHelper.money(response.data);
        this.setState({ valorConsultaParticular: valConsultaParticular });
      });
  };

  // verificaObrigatoriedadeMedico = () => {
  //   const { confirmaPresenca } = this.props;
  // };
  carregaMedicosExame = () => {
    const { estabelecimento, entity, dataConsulta, agendamentoMode } =
      this.props;
    if (agendamentoMode) {
      let medicoFiltroMarcacao = {};
      _.set(medicoFiltroMarcacao, 'estabelecimento', estabelecimento);
      _.set(
        medicoFiltroMarcacao,
        'data',
        dateHelper.format(dataConsulta, {
          mode: 'DATE',
        })
      );
      _.set(medicoFiltroMarcacao, 'horario', _.get(entity, 'horario'));
      //medico de exame está com findAll - validar como será o retorno dos profissionais que irão atender o exame
      medicoService.findByEspecialidade().then(
        (response) => {
          if (response) {
            this.setState({ medicoExames: response.data });
          }
        },
        (error) => {
          // this.props.error(`Erro ao obter lista de profissionais`);
        }
      );
      // medicoService.findMedicoMarcacao(medicoFiltroMarcacao).then(
      //   (response) => {
      //     if (response) {
      //       this.setState({ medicoExames: response.data });
      //     }
      //   },
      //   (error) => {
      //     // this.props.error(`Erro ao obter lista de profissionais`);
      //   }
      // );
    }
  };

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/paciente/' + values.id,
      state: { entity: values },
    });
  };

  saveForm = () => {
    const { callbackOnSave } = this.props;
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      if (_.isEmpty(values, 'cpfCnpj') || _.get(values, 'cpfCnpj') === '') {
        values = _.omit(values, 'cpfCnpj');
      }
      if (_.isEmpty(values, 'email') || _.get(values, 'email') === '') {
        values = _.omit(values, 'email');
      }
      console.log(values);
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          _.set(values, 'agendamento', null);
          pacienteService.doSave(values).then(
            (response) => {
              if (!_this.props.agendamentoMode) {
                _this.props.success({
                  message: `Paciente, foi ${
                    !values.id ? 'criado' : 'alterado'
                  } com sucesso!`,
                });
              }
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              console.log(values);
              if (callbackOnSave) {
                callbackOnSave(values);
              } else {
                _this.goToViewEntity(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar paciente.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
          _this.props.error({
            message: 'Preencha os campos obrigatórios.',
          });
        }
      });
    });
    return promise;
  };

  addTelefone = (setFieldValue, telefones) => {
    let newArray = _.concat(telefones, { numero: '', tipo: 'CELULAR' });
    setFieldValue('telefones', newArray);
  };

  removeItem = (setFieldValue, telefones, index) => {
    telefones.splice(index, 1);
    setFieldValue('telefones', telefones);
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 0) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };

  getCovenioContent = (
    values,
    setFieldValue,
    errors,
    setValues,
    setFieldError
  ) => {
    let {
      viewMode,
      entity,
      agendamentoMode,
      estabelecimento,
      estabelecimentoAtendimento,
      hideCarteirinha,
    } = this.props;
    if (
      (agendamentoMode &&
        _.get(values, 'tipoAtendimento') ===
          objectsConstants.TIPO_ATENDIMENTO_PARTICULAR) ||
      _.get(values, 'tipoAtendimento') ===
        objectsConstants.TIPO_ATENDIMENTO_CORTESIA
    ) {
      return null;
    }

    _.set(values, 'hideCarteirinha', hideCarteirinha);
    return (
      <React.Fragment>
        {/* <h2 className="w-100">Convênio</h2> */}
        {/* <div className="form-row section-form"></div> */}
        <div>
          {values.pacienteConvenios &&
            values.pacienteConvenios.map((convenio, index) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 && <hr className="alpha"></hr>}
                  <ConveniosPlanosPaciente
                    key={convenio.id ? convenio.id : convenio.idList}
                    entity={values}
                    index={index}
                    viewMode={viewMode}
                    size={values.pacienteConvenios.length}
                    propName={`pacienteConvenios[${index}]`}
                    tipoAgenda={this.props.tipoAgenda}
                    atendePlano={this.state.atendePlano}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                      if (
                        name === `pacienteConvenios[${index}].convenio` &&
                        agendamentoMode &&
                        this.props.tipoAgenda ===
                          objectsConstants.TIPO_AGENDA_CONSULTAS
                      ) {
                        this.verificaAtendePlanoConvenio(value).then(
                          (response) => {
                            if (response) {
                              setFieldError(
                                `pacienteConvenios[${index}].convenio`,
                                undefined
                              );
                            } else {
                              setFieldError(
                                `pacienteConvenios[${index}].convenio`,
                                'Convênio não atendido'
                              );
                            }
                            this.setState({ atendePlano: response });
                          }
                        );
                      }
                    }}
                    errors={errors}
                    removeItem={() => {
                      this.removeItemInArray(
                        `pacienteConvenios`,
                        index,
                        values,
                        setValues
                      );
                    }}
                    agendamentoMode={agendamentoMode}
                    estabelecimento={estabelecimento}
                    estabelecimentoAtendimento={estabelecimentoAtendimento}
                    medico={
                      this.props.medico
                        ? this.props.medico
                        : _.get(values, `medico`)
                    }
                    hideCarteirinha={hideCarteirinha}
                  ></ConveniosPlanosPaciente>
                </React.Fragment>
              );
            })}
        </div>
        {!agendamentoMode && (
          <div
            className={`form-row w-100 justify-content-xl-end justify-content-center ${
              viewMode ? 'd-none' : ''
            }`}
          >
            <Button
              type="button"
              className="btn btn-secondary open"
              onClick={() =>
                this.addItemInArray(
                  'pacienteConvenios',
                  null,
                  values,
                  setValues
                )
              }
            >
              Adicionar outro convênio
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  };
  verificaAtendePlanoConvenio = (convenio) => {
    const {
      entity,
      estabelecimentoAtendimento,
      dataConsulta,
      agendamentoMode,
      estabelecimento,
    } = this.props;
    console.log(this.props);
    if (
      agendamentoMode &&
      this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS &&
      convenio != null
    ) {
      let medico = _.get(entity, `medico`);
      let promise = new Promise(function (resolve) {
        convenioService
          .atendePorEstabelecimentoOuEstabelecimentoMedico(
            estabelecimentoAtendimento.id,
            convenio.id,
            dataConsulta,
            estabelecimento.id
          )
          .then(
            (response) => {
              resolve(response.data);
            },
            (erros) => {
              console.log(erros);
              resolve(false);
            }
          );
      });
      return promise;
    }
    return;
  };
  verificaAtendeRetornoComEspecialidade = (especialidade, setFieldValue) => {
    const {
      estabelecimentoAtendimento,
      entity,
      dataConsulta,
      agendamentoMode,
    } = this.props;
    let horario = _.get(entity, 'horario') + ':00';
    if (
      agendamentoMode &&
      this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS
    ) {
      marcacaoService
        .exibeRetornoConsulta(
          estabelecimentoAtendimento.id,
          entity.id,
          dataConsulta,
          horario,
          especialidade
        )
        .then(
          (response) => {
            let exibe = response.data;
            if (_.get(exibe, 'retornoConsulta')) {
              setFieldValue(
                'tipoConsulta',
                objectsConstants.TIPO_CONSULTA_RETORNO
              );
            } else {
              setFieldValue(
                'tipoConsulta',
                objectsConstants.TIPO_CONSULTA_COMUM
              );
            }
          },
          (erros) => {
            console.log(erros);
          }
        );
    }
  };

  verificaAtendeRetorno = (especialidade) => {
    const {
      estabelecimentoAtendimento,
      entity,
      dataConsulta,
      agendamentoMode,
    } = this.props;
    let horario;
    horario = _.get(entity, 'horario') + ':00';
    let especialidadeSelecionada;
    let idEspecialidadeFiltro = _.get(
      _.get(this.props.filter, 'especialidade'),
      'id'
    );
    especialidade
      ? (especialidadeSelecionada = especialidade)
      : (especialidadeSelecionada = idEspecialidadeFiltro
          ? idEspecialidadeFiltro
          : null);
    if (
      agendamentoMode &&
      this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS
    ) {
      marcacaoService
        .exibeConsulta(
          estabelecimentoAtendimento.id,
          entity.id,
          dataConsulta,
          horario
        )
        .then(
          (response) => {
            this.setState({ exibeConsulta: response.data }, () => {
              marcacaoService
                .exibeRetornoConsulta(
                  estabelecimentoAtendimento.id,
                  entity.id,
                  dataConsulta,
                  horario,
                  especialidadeSelecionada
                )
                .then(
                  (response) => {
                    this.setState({ exibeRetorno: response.data });
                  },
                  (erros) => {
                    console.log(erros);
                  }
                );
            });
          },
          (erros) => {
            console.log(erros);
          }
        );
    }
  };

  verificaEspecialidade = (values) => {
    if (
      !_.get(values, 'especialidade') &&
      _.get(this.props.entity, 'especialidade')
    ) {
      _.set(values, 'especialidade', _.get(this.props.entity, 'especialidade'));
    }
    if (
      !_.get(values, 'especialidade') &&
      _.get(this.props.filter, 'especialidade')
    ) {
      _.set(values, 'especialidade', _.get(this.props.filter, 'especialidade'));
    }

    if (
      !_.get(values, 'especialidade') &&
      _.get(this.props.medico, 'especialidades') &&
      _.get(this.props.medico, 'especialidades').length === 1
    ) {
      _.set(
        values,
        'especialidade',
        _.get(this.props.medico, 'especialidades[0]')
      );
    }
    if (
      !_.get(values, 'especialidade') &&
      _.get(this.props.medico, 'especialidades') &&
      _.get(this.props.medico, 'especialidades').length > 1
    ) {
      _.set(values, 'especialidade', '');
    }
    if (this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_EXAMES) {
      values = _.omit(values, 'especialidade');
    }
  };

  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  ////////////////////////////////// IMPORTANTE //////////////////////////////////////
  /////////////// Este arquivo é utilizado somente no crud de PACIENTE ///////////////
  ///// Para marcações de consultas/Exames alterar o novo arquivo *MarcacaoForm* /////
  ////////////////////////////////////////////////////////////////////////////////////

  render() {
    let _this = this;
    let {
      viewMode,
      entity,
      agendamentoMode,
      estabelecimento,
      hideAddres,
      hideCarteirinha,
      estabelecimentoAtendimento,
      dataConsulta,
      confirmaPresenca,
    } = this.props;

    let { exibeRetorno, exibeConsulta } = this.state;

    _.set(entity, 'atendePlano', this.state.atendePlano);
    _.set(entity, 'hideCarteirinha', hideCarteirinha);
    _.set(entity, 'requiredAddres', !hideAddres);

    let medico = this.props.medico;
    if (
      this.state.listEtapaAtendimento &&
      this.state.listEtapaAtendimento.length > 1
    ) {
      _.set(entity, 'etapaAtendimentoObrigatorio', true);
    }
    if (this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS) {
      _.set(entity, 'requiredEspecialidade', true);
      if (medico) {
        if (
          _.get(medico, 'especialidades') &&
          _.get(medico, 'especialidades').length === 1
        ) {
          _.set(entity, 'requiredEspecialidade', false);
        } else {
          _.set(entity, 'requiredEspecialidade', true);
        }
      } else {
        _.set(entity, 'requiredEspecialidade', false);
      }
      if (_.get(this.props.entity, 'medico')) {
        _.set(entity, 'requiredEspecialidade', true);
      }
    }
    _.set(entity, 'telemedicina', false);
    if (this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_EXAMES) {
      _.set(entity, 'tipoConsulta', objectsConstants.TIPO_CONSULTA_COMUM);
      _.set(entity, 'requiredEspecialidade', false);
      entity = _.omit(entity, 'especialidade');
    }
    if (!agendamentoMode) {
      _.set(entity, 'requiredEspecialidade', false);
    }

    if (!confirmaPresenca) {
      if (
        _.get(exibeConsulta, 'consulta') &&
        !_.get(exibeConsulta, 'disable')
      ) {
        _.set(entity, 'tipoConsulta', objectsConstants.TIPO_CONSULTA_COMUM);
      }
      if (
        _.get(entity, 'primeiraConsulta') &&
        !_.get(entity, 'primeiraConsultaDisabled')
      ) {
        _.set(entity, 'tipoConsulta', objectsConstants.TIPO_CONSULTA_PRIMEIRA);
      }
      if (
        _.get(exibeRetorno, 'retornoConsulta') &&
        !_.get(exibeRetorno, 'disable')
      ) {
        _.set(entity, 'tipoConsulta', objectsConstants.TIPO_CONSULTA_RETORNO);
      }
    }
    if (this.props.consultaAvulsa) {
      _.set(exibeConsulta, 'consulta', true);
      _.set(exibeConsulta, 'disable', false);
      _.set(entity, 'tipoConsulta', objectsConstants.TIPO_CONSULTA_COMUM);
      _.set(exibeRetorno, 'retornoConsulta', true);
      _.set(exibeRetorno, 'disable', false);
      _.set(entity, 'primeiraConsulta', true);
      _.set(entity, 'primeiraConsulta', true);
    }
    _.set(entity, 'etapaAtendimento', this.state.etapaAtendimento);
    return (
      <React.Fragment>
        <Formik
          validationSchema={PacienteValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldError,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* CONDICIONAL - SÓ APARECER NO MODAL */}
              {this.verificaEspecialidade(values)}
              {agendamentoMode &&
                this.props.tipoAgenda ===
                  objectsConstants.TIPO_AGENDA_CONSULTAS &&
                !this.props.pacienteOnline && (
                  <React.Fragment>
                    <div className="form-row">
                      <h2 className="w-100">Dados da Consulta</h2>

                      <div
                        className="toogleButtons w-100 justify-content-center justify-content-lg-start"
                        data-toggle="buttons"
                      >
                        <ToogleButton
                          onChange={() => {
                            setFieldValue(
                              'tipoConsulta',
                              objectsConstants.TIPO_CONSULTA_PRIMEIRA
                            );
                          }}
                          disabled={_.get(values, 'primeiraConsultaDisabled')}
                          selected={
                            _.get(values, 'tipoConsulta') ===
                            objectsConstants.TIPO_CONSULTA_PRIMEIRA
                          }
                          label="Primeira Consulta"
                        />

                        <ToogleButton
                          onChange={() => {
                            setFieldValue(
                              'tipoConsulta',
                              objectsConstants.TIPO_CONSULTA_COMUM
                            );
                          }}
                          disabled={_.get(exibeConsulta, 'disable')}
                          selected={
                            _.get(values, 'tipoConsulta') ===
                            objectsConstants.TIPO_CONSULTA_COMUM
                          }
                          label="Consulta"
                        />

                        <ToogleButton
                          onChange={() => {
                            setFieldValue(
                              'tipoConsulta',
                              objectsConstants.TIPO_CONSULTA_RETORNO
                            );
                          }}
                          disabled={_.get(exibeRetorno, 'disable')}
                          selected={
                            _.get(values, 'tipoConsulta') ===
                              objectsConstants.TIPO_CONSULTA_RETORNO ||
                            (_.get(entity, 'tipoConsulta') ===
                              objectsConstants.TIPO_CONSULTA_RETORNO &&
                              _.get(values, 'tipoConsulta') ===
                                objectsConstants.TIPO_CONSULTA_RETORNO)
                          }
                          label="Retorno"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}

              {/* CONDICIONAL - SÓ APARECER NO MODAL */}
              {agendamentoMode && (
                <React.Fragment>
                  <div className="form-row w-100 justify-content-end">
                    <p>
                      <small>
                        <b>Campos obrigatórios *</b>
                      </small>
                    </p>
                  </div>
                  <div className="form-row w-100 justify-content-center justify-content-lg-start">
                    <div className="toogleButtons" data-toggle="buttons">
                      <ToogleButton
                        onChange={() => {
                          setFieldValue(
                            'tipoAtendimento',
                            objectsConstants.TIPO_ATENDIMENTO_CONVENIO
                          );
                        }}
                        selected={
                          _.get(values, 'tipoAtendimento') ===
                          objectsConstants.TIPO_ATENDIMENTO_CONVENIO
                        }
                        label="Convênio"
                      />
                      <ToogleButton
                        onChange={() => {
                          setFieldValue(
                            'tipoAtendimento',
                            objectsConstants.TIPO_ATENDIMENTO_PARTICULAR
                          );
                          // this.setState({ atendePlano: true });
                        }}
                        selected={
                          _.get(values, 'tipoAtendimento') ===
                          objectsConstants.TIPO_ATENDIMENTO_PARTICULAR
                        }
                        label="Particular"
                      />
                      {!this.props.pacienteOnline && (
                        <ToogleButton
                          onChange={() => {
                            setFieldValue(
                              'tipoAtendimento',
                              objectsConstants.TIPO_ATENDIMENTO_CORTESIA
                            );
                            // this.setState({ atendePlano: true });
                          }}
                          selected={
                            _.get(values, 'tipoAtendimento') ===
                            objectsConstants.TIPO_ATENDIMENTO_CORTESIA
                          }
                          label="Cortesia"
                        />
                      )}
                    </div>
                  </div>
                  {_.get(values, 'tipoAtendimento') ===
                    objectsConstants.TIPO_ATENDIMENTO_PARTICULAR && (
                    <div className="valor-particular">
                      Valor da consulta particular:{' '}
                      <b>{this.state.valorConsultaParticular}</b>
                    </div>
                  )}
                </React.Fragment>
              )}

              {this.getCovenioContent(
                values,
                setFieldValue,
                errors,
                setValues,
                setFieldError
              )}

              {/* CONDICIONAL - SÓ APARECER NO MODAL */}
              <div className="form-inside">
                {agendamentoMode && (
                  <React.Fragment>
                    <div className="row section-form">
                      <FormGroup className="col-6 col-lg-3">
                        <label>
                          {this.props.tipoAgenda ===
                          objectsConstants.TIPO_AGENDA_CONSULTAS
                            ? 'Data da consulta:'
                            : 'Data do exame:'}{' '}
                        </label>
                        <p className="form-view">
                          {dateHelper.format(this.props.dataConsulta, {
                            mode: 'DATE',
                          })}
                        </p>
                      </FormGroup>
                      <FormGroup className="col-6 col-lg-9">
                        <label>
                          {' '}
                          {this.props.tipoAgenda ===
                          objectsConstants.TIPO_AGENDA_CONSULTAS
                            ? 'Hora da consulta:'
                            : 'Hora do exame:'}{' '}
                        </label>
                        <p className="form-view">{_.get(entity, 'horario')}</p>
                      </FormGroup>

                      <FormGroup className="col-12 col-lg-12">
                        <InputViewEdit
                          component={SelectInput}
                          label="Atendimento Prioritario"
                          name="atendimentoPrioritarioList"
                          id={'atendimentoPrioritarioList'}
                          placeholder="Selecione a prioridade do paciente"
                          valueKey="name"
                          labelKey="description"
                          noSize={true}
                          // disabled={!hideAddres}
                          required={false}
                          returnFullObject={true}
                          searchable={true}
                          isMulti={true}
                          options={objectsConstants.ATENDIMENTO_PRIORITARIO}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.atendimentoPrioritarioList}
                          // defaultValue={this.verificaEspecialidade(values)}
                          //erroMensagem={errors.especialidade}
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <InputViewEdit
                          component={DatePickerInput}
                          label="Data de Autorizacão"
                          name="dataAutorizacao"
                          id={'dataAutorizacao'}
                          placeholder="Data de Autorização"
                          noSize={true}
                          // disabled={!hideAddres}
                          required={false}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.dataAutorizacao}
                          // defaultValue={this.verificaEspecialidade(values)}
                          //erroMensagem={errors.especialidade}
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <InputViewEdit
                          component={DatePickerInput}
                          label="Data da validade da senha"
                          name="dataValidadeSenha"
                          id={'dataValidadeSenha'}
                          placeholder="Data da valiade da senha"
                          noSize={true}
                          // disabled={!hideAddres}
                          required={false}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.dataValidadeSenha}
                          // defaultValue={this.verificaEspecialidade(values)}
                          //erroMensagem={errors.especialidade}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 col-lg-6">
                        <InputViewEdit
                          component={FormInput}
                          label="Senha de Autorizacao"
                          name="senha"
                          id={'senha'}
                          placeholder="Senha"
                          noSize={true}
                          // disabled={!hideAddres}
                          required={false}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.senha}
                          // defaultValue={this.verificaEspecialidade(values)}
                          //erroMensagem={errors.especialidade}
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <InputViewEdit
                          component={FormInput}
                          label="Número da guia atribuido pelo operador"
                          name="numeroGuiaOperador"
                          id={'numeroGuiaOperador'}
                          placeholder="Número Guia"
                          noSize={true}
                          // disabled={!hideAddres}
                          required={false}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.numeroGuiaOperador}
                          // defaultValue={this.verificaEspecialidade(values)}
                          //erroMensagem={errors.especialidade}
                        />
                      </FormGroup>
                      {this.props.tipoAgenda ===
                        objectsConstants.TIPO_AGENDA_CONSULTAS && (
                        <>
                          <FormGroup className="col-12 col-lg-6">
                            <InputViewEdit
                              component={SelectInput}
                              label="Selecione uma especialidade"
                              name="especialidade"
                              id={'especialidade'}
                              placeholder="Selecione uma especialidade"
                              valueKey="id"
                              labelKey="nome"
                              noSize={true}
                              required={true}
                              returnFullObject={true}
                              searchable={true}
                              isMulti={false}
                              options={
                                this.props.medico
                                  ? _.get(this.props.medico, 'especialidades')
                                  : _.get(
                                      this.props.entity.medico,
                                      'especialidades'
                                    )
                              }
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                                this.verificaAtendeRetornoComEspecialidade(
                                  _.get(value, 'id'),
                                  setFieldValue
                                );
                              }}
                              value={values.especialidade}
                              erroMensagem={errors.especialidade}
                            />
                          </FormGroup>
                          {this.props.pacienteOnline &&
                            _.get(values, 'tipoConsulta') ===
                              objectsConstants.TIPO_CONSULTA_RETORNO && (
                              <>
                                <FormGroup className="col-12 pb-1">
                                  <button
                                    type="button"
                                    className="btn btn-info d-flex align-items-center m-auto"
                                    onClick={this.toggleAnexos}
                                  >
                                    <span className="icon-anexar"></span> Anexar
                                    exame ou arquivo
                                  </button>
                                </FormGroup>
                                {values.listMidias &&
                                  values.listMidias.length > 0 && (
                                    <FormGroup className="col-12 pt-0">
                                      <div
                                        id="anexos"
                                        className="pointer d-flex justify-content-center"
                                        onClick={this.toggleAnexos}
                                      >
                                        <p
                                          className={`destaque font-bold ${
                                            _.size(values.listMidias) === 0 &&
                                            'd-none'
                                          }`}
                                        >
                                          {_.size(values.listMidias) === 1
                                            ? `${_.size(
                                                values.listMidias
                                              )} arquivo anexado`
                                            : `${_.size(
                                                values.listMidias
                                              )} arquivos anexados`}
                                        </p>
                                      </div>
                                    </FormGroup>
                                  )}
                              </>
                            )}
                        </>
                      )}
                      {!this.props.pacienteOnline &&
                        this.state.listEtapaAtendimento &&
                        this.state.listEtapaAtendimento.length > 0 && (
                          <FormGroup className="col-12 col-lg-6">
                            <InputViewEdit
                              component={SelectInput}
                              label="Selecione para qual local deseja encaminhar."
                              name="etapaAtendimento"
                              id={'etapaAtendimento'}
                              placeholder="Selecione o local"
                              valueKey="id"
                              labelKey="local"
                              noSize={true}
                              // disabled={!hideAddres}
                              required={true}
                              returnFullObject={true}
                              searchable={true}
                              isMulti={false}
                              options={this.state.listEtapaAtendimento}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              value={values.etapaAtendimento}
                              // defaultValue={this.verificaEspecialidade(values)}
                              erroMensagem={errors.etapaAtendimento}
                            />
                          </FormGroup>
                        )}
                      {/* // TELEMEDICINA */}
                      {hideAddres &&
                        this.props.tipoAgenda ===
                          objectsConstants.TIPO_AGENDA_CONSULTAS &&
                        !this.props.pacienteOnline && (
                          <React.Fragment>
                            <FormGroup className="col-12 col-lg-6">
                              <InputViewEdit
                                component={SelectInput}
                                disabled={
                                  !_.get(entity, 'atendeTelemedicina', false)
                                }
                                id={'telemedicina'}
                                options={[
                                  { label: 'Sim', value: true },
                                  { label: 'Não', value: false },
                                ]}
                                returnFullObject={true}
                                label="Atendimento por telemedicina? "
                                required={true}
                                labelKey={'label'}
                                valueKey={'value'}
                                viewMode={viewMode}
                                name={'telemedicina'}
                                defaultValue={
                                  values.telemedicina &&
                                  values.telemedicina.value === false
                                    ? 'Não'
                                    : formatterHelper.getLabelEnum(
                                        values.telemedicina &&
                                          values.telemedicina.value
                                          ? values.telemedicina.value
                                          : values.telemedicina,
                                        objectsConstants.SIM_NAO
                                      )
                                }
                                value={
                                  _.isBoolean(values.telemedicina)
                                    ? formatterHelper.getEnum(
                                        values.telemedicina,
                                        objectsConstants.SIM_NAO
                                      )
                                    : values.telemedicina
                                }
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                              />
                            </FormGroup>
                          </React.Fragment>
                        )}

                      {this.props.tipoAgenda ===
                        objectsConstants.TIPO_AGENDA_EXAMES && (
                        <FormGroup className="col-12 col-lg-12 pt-0">
                          <InputViewEdit
                            component={FormSelectInput}
                            onChange={(name, value) => {
                              setFieldValue(name, !value ? [] : value);
                            }}
                            id={`medico`}
                            label="Profissional que irá realizar o exame    "
                            placeholder="Selecione um profissionais para realizar o exame"
                            name={`medico`}
                            value={_.get(values, `medico`)}
                            erroMensagem={_.get(errors, `medico`)}
                            returnFullObject={true}
                            service={medicoService.findByHorarioExame}
                            parent={{
                              dataConsulta: dateHelper.format(
                                this.props.dataConsulta,
                                {
                                  mode: 'DATE-API',
                                }
                              ),
                              horario: _.get(entity, 'horario'),
                              exame: _.get(entity, 'exame'),
                            }}
                            creatable={false}
                            isMulti={false}
                            valueKey="id"
                            labelKey="apelido"
                          />
                        </FormGroup>
                      )}
                      {!this.props.pacienteOnline && (
                        <FormGroup className="col-12 col-lg-12 pt-0">
                          <InputViewEdit
                            component={FormTextArea}
                            className={'textArea'}
                            label="Observações"
                            id={'observacoes'}
                            name={'observacoes'}
                            onChange={handleChange}
                            rows={4}
                            maxLength={300}
                            viewClassName={'col-12'}
                            placeholder="Insira aqui as observações"
                            value={values.observacoes}
                          />
                        </FormGroup>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>

              {agendamentoMode && <h2 className="w-100">Dados do paciente</h2>}
              <div className="form-inside">
                <div className="row section-form">
                  <div
                    className={`col-12 ${
                      agendamentoMode ? 'd-none' : 'col-lg-1'
                    }`}
                  >
                    <Avatar
                      user={values}
                      namePropsMidiaId={'foto.id'}
                      editable={!viewMode}
                      onChange={(name, value) => setFieldValue('foto', value)}
                    ></Avatar>
                  </div>
                  <div
                    className={`col-12 ${
                      agendamentoMode ? 'col-lg-12' : 'col-lg-11'
                    } form-row pl-3`}
                  >
                    {viewMode && (
                      //se estiver visualizando o paciente exibe o id
                      <FormGroup className="col-1">
                        <InputViewEdit
                          component={FormInput}
                          label={'Código'}
                          type="text"
                          id="idPaciente"
                          name="idPaciente"
                          value={values.id}
                          noSize={true}
                          erroMensagem={errors.id}
                          viewMode={viewMode}
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      className={`col-12 ${
                        agendamentoMode ? 'col-lg-8' : 'col-lg-5'
                      }`}
                    >
                      <InputViewEdit
                        component={FormInput}
                        label={'Nome'}
                        type="text"
                        id="nome"
                        name="nome"
                        placeholder="Digite o nome do paciente"
                        value={values.nome}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.nome}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'nomeProprio font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup
                      className={`col-12 ${
                        agendamentoMode ? 'col-lg-4' : 'col-lg-5'
                      }`}
                    >
                      <InputViewEdit
                        component={DatePickerInput}
                        label="Data de Nascimento"
                        id="dataNascimento"
                        name="dataNascimento"
                        value={values.dataNascimento}
                        placeholder="- - / - - / - - - -"
                        onChange={(name, value) => setFieldValue(name, value)}
                        viewMode={viewMode}
                        defaultValue={
                          _.get(values, `dataNascimento`)
                            ? dateHelper.format(
                                _.get(values, `dataNascimento`),
                                {
                                  mode: 'DATE',
                                }
                              )
                            : '--'
                        }
                        maxDate={moment()}
                        required={false}
                        erroMensagem={errors.dataNascimento}
                      />
                    </FormGroup>
                    <FormGroup
                      className={`col-12 ${
                        agendamentoMode ? 'col-lg-8' : 'col-lg-4'
                      }`}
                    >
                      <InputViewEdit
                        component={FormInput}
                        label={'E-mail*'}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="E-mail do paciente ou responsável"
                        value={values.email}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        viewMode={viewMode}
                        erroMensagem={errors.email}
                      />
                    </FormGroup>

                    <FormGroup
                      className={`col-12 ${
                        agendamentoMode ? 'col-lg-4' : 'col-lg-5'
                      }`}
                    >
                      <InputViewEdit
                        component={CpfCnpjInput}
                        label={'CPF*'}
                        type="text"
                        id="cpfCnpj"
                        name="cpfCnpj"
                        placeholder="Digite o CPF"
                        value={values.cpfCnpj}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.cpfCnpj}
                        viewMode={viewMode}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 col-lg-6">
                      <InputViewEdit
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        id={`sexo`}
                        label={'Sexo'}
                        options={objectsConstants.TIPOS_SEXO}
                        name={`sexo`}
                        placeholder="Digite o sexo"
                        viewMode={viewMode}
                        returnFullObject={false}
                        searchable={true}
                        labelKey={'description'}
                        valueKey={'name'}
                        value={values.sexo}
                        noSize={true}
                      />
                    </FormGroup>
                    <FormGroup
                      className={`col-12 ${
                        agendamentoMode ? 'col-lg-12' : 'col-lg-6'
                      }`}
                    >
                      <InputViewEdit
                        component={FormInput}
                        label={'Nome da mãe'}
                        type="text"
                        id="nomeMae"
                        name="nomeMae"
                        placeholder="Digite o nome da mãe do paciente"
                        value={values.nomeMae}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        viewMode={viewMode}
                        required={!hideAddres}
                        erroMensagem={errors.nomeMae}
                      />
                    </FormGroup>
                    {!this.props.pacienteOnline && (
                      <>
                        <FormGroup
                          className={`col-12 ${
                            agendamentoMode ? 'col-lg-12' : 'col-lg-6'
                          }`}
                        >
                          <InputViewEdit
                            component={FormInput}
                            label={'Nome do pai'}
                            type="text"
                            id="nomePai"
                            name="nomePai"
                            placeholder="Digite o nome do pai do paciente"
                            value={values.nomePai}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            viewMode={viewMode}
                            erroMensagem={errors.nomePai}
                          />
                        </FormGroup>

                        <FormGroup
                          className={`col-12 ${
                            agendamentoMode ? 'col-lg-12' : 'col-lg-6'
                          }`}
                        >
                          <InputViewEdit
                            component={FormSelectInput}
                            name="medicosBloqueado"
                            id={'medico'}
                            // icone={Medicos}
                            classIcone={'off'}
                            label="Paciente bloqueado para o(s) profissional(is) de saúde"
                            placeholder="Selecione o profissional de saúde que o paciente será bloqueado"
                            valueKey="id"
                            labelKey="apelido"
                            noSize={true}
                            required={false}
                            defaultValue={
                              _.get(values, `medicosBloqueado`)
                                ? _.get(values, `medicosBloqueado`, [])
                                    .map((x) => x.apelido)
                                    .join(', ')
                                : ''
                            }
                            returnFullObject={true}
                            isMulti={true}
                            searchable={true}
                            service={medicoService.findMedicoByEspecialidade}
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                            }}
                            value={values.medicosBloqueado}
                            viewMode={viewMode}
                            erroMensagem={errors.nomePai}
                          />
                        </FormGroup>
                      </>
                    )}
                    {!this.props.pacienteOnline && (
                      <FormGroup className={`col-12`}>
                        <InputViewEdit
                          component={FormInput}
                          label={'Observações do paciente'}
                          type="text"
                          id="observacoesPaciente"
                          name="observacoesPaciente"
                          placeholder="Digite as observações gerais do paciente..."
                          value={values.observacoesPaciente}
                          onChange={(name, value) => setFieldValue(name, value)}
                          noSize={true}
                          viewMode={viewMode}
                          erroMensagem={errors.observacoesPaciente}
                        />
                      </FormGroup>
                    )}
                  </div>
                </div>
              </div>

              <h2 className="w-100">Telefone para contato</h2>
              <div className="form-inside">
                {values.telefones &&
                  values.telefones.map((telefone, index) => {
                    return (
                      <div className="form-row section-form" key={index}>
                        <MultiTelefones
                          entity={values}
                          index={index}
                          viewMode={viewMode}
                          agendamentoMode={agendamentoMode}
                          handleChange={handleChange}
                          errors={errors}
                          propName={`telefones[${index}]`}
                          onChange={setFieldValue}
                          size={values.telefones.length}
                          removeItem={() => {
                            this.removeItemInArray(
                              `telefones`,
                              index,
                              values,
                              setValues
                            );
                          }}
                        ></MultiTelefones>
                      </div>
                    );
                  })}
              </div>
              <div
                className={`form-row justify-content-md-end justify-content-center ${
                  viewMode ? 'd-none' : ''
                }`}
              >
                <button
                  onClick={() =>
                    this.addTelefone(setFieldValue, values.telefones)
                  }
                  type="button"
                  className="btn btn-secondary open mt-4"
                >
                  Adicionar outro telefone
                </button>
              </div>
              <React.Fragment>
                {/* ENDEREÇO */}
                <h2 className="w-100">Endereço</h2>
                <div className="form-inside">
                  <div className="row section-form">
                    <FormGroup className="col-12 col-lg-3">
                      <InputViewEdit
                        component={ZipCodeInput}
                        label="CEP"
                        id="enderecoId"
                        name="endereco.id"
                        value={_.get(values, 'endereco.cep')}
                        erroMensagem={errors.endereco && errors.endereco.cep}
                        required={true}
                        placeholder="CEP"
                        required={!hideAddres}
                        onChange={(name, value) => {
                          if (values.endereco) {
                            value.id = values.endereco.id;
                          }

                          value.estado = value.uf;
                          setFieldValue('endereco', value);
                        }}
                        returnFullCity={false}
                        viewMode={viewMode}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-7">
                      <InputViewEdit
                        component={FormInput}
                        label={'Logradouro'}
                        placeholder="Digite o logradouro"
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.logradouro}
                        viewMode={viewMode}
                        required={!hideAddres}
                        id="enderecoLogradouro"
                        value={_.get(values, 'endereco.logradouro')}
                        name="endereco.logradouro"
                        erroMensagem={_.get(errors, 'endereco.logradouro')}
                      />
                    </FormGroup>
                    <FormGroup className="col-3 col-lg-2">
                      <InputViewEdit
                        component={FormInput}
                        type="text"
                        label="Nº"
                        placeholder="Nº"
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="endereconumero"
                        value={_.get(values, 'endereco.numero')}
                        name="endereco.numero"
                        erroMensagem={_.get(errors, 'endereco.numero')}
                        required={!hideAddres}
                      />
                    </FormGroup>
                    <FormGroup className="col-9 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label="Complemento"
                        type="text"
                        placeholder="Complemento"
                        erroMensagem={errors.complemento}
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="enderecocomplemento"
                        value={_.get(values, 'endereco.complemento')}
                        name="endereco.complemento"
                        erroMensagem={_.get(errors, 'endereco.complemento')}
                      />
                    </FormGroup>

                    <FormGroup className="col-12 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label="Bairro"
                        type="text"
                        placeholder="Bairro"
                        erroMensagem={errors.bairro}
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="enderecobairro"
                        value={_.get(values, 'endereco.bairro')}
                        name="endereco.bairro"
                        erroMensagem={_.get(errors, 'endereco.bairro')}
                        required={!hideAddres}
                      />
                    </FormGroup>
                    <FormGroup className="col-8 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label="Cidade"
                        type="text"
                        placeholder="Cidade"
                        erroMensagem={errors.cidade}
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="enderecocidade"
                        value={_.get(values, 'endereco.cidade')}
                        name="endereco.cidade"
                        erroMensagem={_.get(errors, 'endereco.cidade')}
                        required={!hideAddres}
                      />
                    </FormGroup>
                    <FormGroup className="col-4 col-lg-4">
                      <InputViewEdit
                        component={FormSelectInput}
                        label={'UF'}
                        id="uf"
                        name="endereco.estado"
                        placeholder="UF"
                        value={_.get(values, 'endereco.estado')}
                        onChange={setFieldValue}
                        noSize={true}
                        erroMensagem={_.get(errors, 'endereco.estado')}
                        viewMode={viewMode}
                        required={!hideAddres}
                        returnFullObject={false}
                        valueKey={'sigla'}
                        labelKey={'nome'}
                        multi={false}
                        service={ApiIBGE.findAllEstados}
                        sortKey={'sigla'}
                      />
                    </FormGroup>
                  </div>
                </div>
                <h2 className="w-100">Informações adicionais</h2>
                <div className="form-inside">
                  <div className="row section-form">
                    <FormGroup className="col-12 col-lg-10">
                      {!viewMode && (
                        <FormTextCKEditor
                          label="Informações adicionais"
                          id={'informacao'}
                          name={'informacao'}
                          className={'textCKeditor'}
                          type={'textarea'}
                          required={true}
                          onChange={setFieldValue}
                          erroMensagem={errors.informacao}
                          viewMode={viewMode}
                          viewClassName={'view-anamnese'}
                          value={values.informacao}
                          defaultValue={values.informacao}
                          placeholder="Insira aqui as informações adicionais do paciente"
                          removePlugins={[
                            'Heading',
                            'BlockQuote',
                            'ImageUpload',
                            'MediaEmbed',
                          ]}
                          ref={(ckRef) => {
                            this.refCkEditor = ckRef;
                          }}
                        />
                      )}
                      {viewMode && (
                        <p className="form-view">
                          {ReactHtmlParser(values.informacao)}
                        </p>
                      )}
                    </FormGroup>
                  </div>
                </div>
              </React.Fragment>
              <ModalAnexos
                ref={(anexos) => (this._anexosInput = anexos)}
                name="listMidias"
                toogle={this.toggleAnexos}
                isOpen={this.state.openAnexo}
                target={'anexos'}
                itens={values.listMidias ? values.listMidias : []}
                onChange={setFieldValue}
                viewMode={viewMode}
                user={this.props.user}
              />
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

////////////////////////////////// IMPORTANTE //////////////////////////////////////
/////////////// Este arquivo é utilizado somente no crud de PACIENTE ///////////////
///// Para marcações de consultas/Exames alterar o novo arquivo *MarcacaoForm* /////
////////////////////////////////////////////////////////////////////////////////////

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { filter } = state.agenda;
  return {
    user,
    filter,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(PacienteForm);
