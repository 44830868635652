//@flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  handleClick: any,
};

export default class ToogleButton extends React.Component<Props> {
  render() {
    const { selected, label, disabled } = this.props;

    return (
      <label
        className={`btn-toogle ${
          selected === true ? 'btn-toogle-on' : 'btn-toogle-off'
        } ${disabled === true ? 'disabled' : ''}`}
        onClick={this.handleClick}
      >
        {label}
      </label>
    );
  }

  handleClick = (event: any) => {
    event.preventDefault();
    let { selected, disabled } = this.props;
    if (!disabled) {
      this.props.onChange(!selected, this.props.value);
    }
    return false; //Click event is triggered twice and this prevents re-toggling of classes
  };
}
