//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const entradaEstoqueService = {
  doGet,
  doSave,
  doDelete,
  findByFiltro,
};


function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(`${urlsConstants.ENTRADA_ESTOQUE}list`, options, filter);
}


function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.ENTRADA_ESTOQUE}${id}`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ENTRADA_ESTOQUE}${id}`);
}

function doSave(estoque: any) {
  return defaultService.doSave(urlsConstants.ENTRADA_ESTOQUE, estoque);
}

