//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from './../../components/inputs/FormInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class EstabelecimentoListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="Nome do estabelecimento"
            placeholder={'Nome'}
            id={'namefilter'}
            name="nome"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4 offset-sm-1'}
            value={entity.nome}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="E-mail"
            placeholder={'E-mail'}
            id={'email'}
            name="email"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4'}
            value={entity.email}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <CpfCnpjInput
            label="CPF ou CNPJ"
            placeholder={'CPF ou CNPJ'}
            id={'cpfCnpj'}
            name="cpfCnpj"
            onChange={this.props.onChangeDate}
            type={'number'}
            size={12}
            style={'col-6 col-sm-2'}
            value={entity.cpfCnpj}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
