import React, { PureComponent } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./ModalFilaAtendimento.scss";
type Props = {
    target?: any,
    itens: [],
    titleBtnSave: String,
    titleBtnCancel: String
};

const modifiers = {
    preventOverflow: {
        enabled: false
    },
    flip: {
        enabled: false
    }
};
let fileInput;

export class ModalFilaAtendimento extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: props.viewMode
        };
    }
    static defaultProps = {
        titleBtnSave: "Salvar",
        titleBtnCancel: "Cancelar",
    }


    cancelForm = () => {
        this.props.toogle();
    };

    render() {
        const { title, Componente, entity, ...otherProps } = this.props;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    backdrop="static"
                    modalClassName=""
                    className="modal-filaAtendimento modal-lg"
                    centered={true}
                    modifiers={modifiers}
                    target={this.props.target}
                >
                    <div className="d-flex align-items-center modal-header">
                        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between modal-header-info w-100">
                            <h1>{title}</h1>
                            <div className="box-buton d-flex align-items-center justify-content-end mt-3 mt-lg-0">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={this.cancelForm}
                                > Fechar </button>
                            </div>
                        </div>
                    </div>
                    <ModalBody>
                        <React.Fragment>
                            {Componente && (
                                <Componente
                                    ref={form => {
                                        this.refComponent = form;
                                    }}
                                    entity={entity}
                                    {...otherProps}
                                />
                            )}
                        </React.Fragment>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
