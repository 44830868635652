


//@flow
import * as React from 'react';
import { Modal, ModalBody } from "reactstrap";
import ReactLoading from "react-loading";

export default class Loading extends React.Component<{ children?: React.Node }, {}> {
  render() {
      const{loading}=this.props;
    return (
        <Modal
        isOpen={loading}
        backdrop="static"
        modalClassName="modalCenter"
        className="modalLoading"
        centered={true}>
        <ModalBody>
          {" "}
          <ReactLoading
            className="loading"
            type={"bars"}
            color="#fff"
            height={"70px"}
            width={"70px"}
          />
        </ModalBody>
      </Modal>
    );
  }
}
