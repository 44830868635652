//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from './../../components/inputs/FormInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class EmpresaListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="Nome da empresa"
            placeholder={'Nome'}
            id={'namefilter'}
            name="nome"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4 offset-sm-1'}
            value={entity.nome}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="Domínio"
            placeholder={'dominio.com.br'}
            id={'dominio'}
            name="dominio"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4'}
            value={entity.dominio}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
