//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../../components/FormGroup';
import CreatableMulti from '../../../components/inputs/CreatableMulti';
import FormInput from '../../../components/inputs/FormInput';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import {
  objectsConstants,
  objectsEnuns,
} from '../../../_constants/objects.constants';
import { translate } from '../../../_helpers/messages.helper';
import { defaultService } from '../../../_services/defaultService';
import { estabelecimentoService } from '../../../_services/estabelecimento.service';
import { perfilservice } from '../../../_services/perfil.service';
import { estoqueService } from '../../../_services/estoque.service';
import { fabricanteService } from '../../../_services/fabricante.service';
import { grupoService } from '../../../_services/grupo.service';
import TagsBusca from './TagBusca';
import { formatterHelper } from '../../../_helpers/formatter.helper';
import { unidadePadraoService } from '../../../_services/unidadePadrao.service';
import MoneyInput from '../../../components/inputs/MoneyInput';
import UnidadeFracao from './unidadeFracao/UnidadeFracao';
import EstoqueFaturamento from './EstoqueFaturamento';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};
const OBRIGATORIO = 'Obrigatório';
const STRING_REQUIRED = Yup.string().required(OBRIGATORIO);
const EstoqueProdutosValidate = Yup.object().shape({
  produto: Yup.object().shape({
    codigo: STRING_REQUIRED,
    fabricante: STRING_REQUIRED,
    ean: STRING_REQUIRED,
    nome: STRING_REQUIRED,
    grupo: STRING_REQUIRED,
    situacao: STRING_REQUIRED,
  }),
  estoqueUnidade: Yup.object().shape({
    unidadePadrao: STRING_REQUIRED,
    custo: STRING_REQUIRED,
    precoVendaParticular: STRING_REQUIRED,
    precoVendaConvenio: STRING_REQUIRED,
  }),
  estoqueMinimo: STRING_REQUIRED,
  estoqueMaximo: STRING_REQUIRED,
  estoqueInicial: STRING_REQUIRED,
  listEstoqueUnidadeFracao: Yup.array().of(
    Yup.object().shape({
      fracoes: STRING_REQUIRED,
      unidadePadrao: STRING_REQUIRED,
      precoVendaParticular: STRING_REQUIRED,
      precoVendaConvenio: STRING_REQUIRED,
    })
  ),
});

class EstoqueProdutosForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  addUnidadeFracao = (
    propName,
    defaultValue,
    values,
    setValues,
    createAttribute?: boolean
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName]) {
      newValues[propName].unshift(
        defaultValue
          ? defaultValue
          : {
              idList: new Date().getTime(),
              tipoEstoqueUnidade: objectsConstants.TIPO_UNIDADE_FRACAO,
            }
      );
      setValues(newValues);
    }
    if (!newValues[propName] && createAttribute) {
      _.set(newValues, `${propName}`, [
        {
          idList: new Date().getTime(),
          tipoEstoqueUnidade: objectsConstants.TIPO_UNIDADE_FRACAO,
        },
      ]);
      setValues(newValues);
    }
  };

  removeUnidadeFracao = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length >= 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };
  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      let newValues = _.cloneDeep(values);
      _.set(
        newValues,
        'estoqueUnidade.tipoEstoqueUnidade',
        objectsConstants.TIPO_UNIDADE_PADRAO
      );
      console.log(newValues);
      _this.formRef.validateForm(newValues).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          estoqueService.doSave(newValues).then(
            (response) => {
              _this.props.success({
                message: `Dados do produto, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar Dados do produto.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          console.log(error);
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };
  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EstoqueProdutosValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'Código'}
                      type="text"
                      id="codigo"
                      name="produto.codigo"
                      placeholder="Informe o código do produto"
                      value={_.get(values, 'produto.codigo')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'produto.codigo')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      label={'Fabricante'}
                      // viewLabelClassName="d-none"
                      viewClassName="font-weight-bold"
                      placeholder="Informe o fabricante do produto"
                      component={FormSelectInput}
                      service={fabricanteService.findByEstabelecimento}
                      labelKey={'nome'}
                      valueKey={'id'}
                      returnFullObject={true}
                      defaultValue={_.get(
                        values,
                        'produto.fabricante.nome',
                        '--'
                      )}
                      required={true}
                      value={_.get(values, 'produto.fabricante')}
                      viewMode={viewMode}
                      id="fabricante"
                      name="produto.fabricante"
                      onChange={(name, value) => {
                        if (!value.id) {
                          fabricanteService.doSave(value).then((response) => {
                            let id = defaultService.getIdFromUrl(
                              response.headers.location
                            );
                            _.set(value, 'id', id);
                            setFieldValue(name, value);
                          });
                        } else {
                          setFieldValue(name, value);
                        }
                      }}
                      erroMensagem={_.get(errors, 'produto.fabricante')}
                      sortNoAsc={true}
                      sortKey={'nome'}
                      creatable={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-5">
                    <InputViewEdit
                      component={FormInput}
                      label={'Código de barras (EAN13)'}
                      type="text"
                      id="ean"
                      name="produto.ean"
                      placeholder="Informe o código de barras do produto"
                      value={_.get(values, 'produto.ean')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'produto.ean')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-5">
                    <InputViewEdit
                      component={FormInput}
                      label={'Nome'}
                      type="text"
                      id="nome"
                      name="produto.nome"
                      placeholder="Informe o nome do produto"
                      value={_.get(values, 'produto.nome')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'produto.nome')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      label={'Grupo'}
                      // viewLabelClassName="d-none"
                      viewClassName="font-weight-bold"
                      placeholder="Informe o grupo do produto"
                      component={FormSelectInput}
                      service={grupoService.findByEstabelecimento}
                      labelKey={'nome'}
                      valueKey={'id'}
                      returnFullObject={true}
                      defaultValue={_.get(values, 'produto.grupo.nome', '--')}
                      required={true}
                      value={_.get(values, 'produto.grupo')}
                      viewMode={viewMode}
                      id="grupo"
                      name="produto.grupo"
                      onChange={(name, value) => {
                        if (!value.id) {
                          grupoService.doSave(value).then((response) => {
                            let id = defaultService.getIdFromUrl(
                              response.headers.location
                            );
                            _.set(value, 'id', id);

                            setFieldValue(name, value);
                          });
                        } else {
                          setFieldValue(name, value);
                        }
                      }}
                      erroMensagem={_.get(errors, 'produto.grupo')}
                      sortNoAsc={true}
                      sortKey={'nome'}
                      creatable={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={SelectInput}
                      options={objectsEnuns.SITUACAO_PRODUTO}
                      type="text"
                      name="produto.situacao"
                      label="Situação"
                      placeholder="Selecione a situação do produto"
                      onChange={setFieldValue}
                      noSize={true}
                      viewMode={viewMode}
                      id="situacao"
                      returnFullObject={false}
                      labelKey={'label'}
                      valueKey={'value'}
                      multi={false}
                      value={_.get(values, 'produto.situacao')}
                      erroMensagem={_.get(errors, 'produto.situacao')}
                      required={true}
                      defaultValue={_.get(values, 'situacao')}
                    />
                  </FormGroup>
                  <div className="d-flex align-items-center justify-content-center col-12 col-xl-12">
                    <TagsBusca />
                  </div>
                  <FormGroup className="col-12 col-xl-12">
                    <InputViewEdit
                      component={CreatableMulti}
                      onChange={setFieldValue}
                      id={`listTags`}
                      label={'TAGS de Busca'}
                      name={`produto.listTags`}
                      placeholder="Insira Tags de busca"
                      noResultsText={''}
                      viewMode={viewMode}
                      value={_.get(values, 'produto.listTags')}
                      creatable={true}
                      required={false}
                      option={[]}
                      multi
                      apendRoot
                      className={'tagsProduto'}
                      labelKey={'label'}
                      valueKey={'value'}
                      defaultValue={
                        viewMode &&
                        _.get(values, 'produto.listTags') && (
                          <React.Fragment>
                            {_.get(values, 'produto.listTags')
                              .map((e) => e.label)
                              .join(', ')}
                          </React.Fragment>
                        )
                      }
                    />
                  </FormGroup>
                </div>
              </div>

              {/* //////////// */}
              <div className="form-inside">
                <div className="d-flex align-items-center justify-content-center">
                  <EstoqueFaturamento />
                </div>
                <div className="row section-form">
                  <FormGroup className="col-12 col-md-2 col-xl-2">
                    <InputViewEdit
                      viewClassName="font-weight-bold"
                      name="unidadePadrao"
                      label="Unidade padrão"
                      placeholder="Selecione a unidade padrão"
                      component={FormSelectInput}
                      service={unidadePadraoService.findByEstabelecimento}
                      labelKey={'nome'}
                      valueKey={'id'}
                      returnFullObject={true}
                      defaultValue={_.get(
                        values,
                        'estoqueUnidade.unidadePadrao.nome',
                        '--'
                      )}
                      required={true}
                      value={_.get(values, 'estoqueUnidade.unidadePadrao')}
                      viewMode={viewMode}
                      id="unidadePadrao"
                      name="estoqueUnidade.unidadePadrao"
                      onChange={(name, value) => {
                        if (!value.id) {
                          unidadePadraoService
                            .doSave(value)
                            .then((response) => {
                              let id = defaultService.getIdFromUrl(
                                response.headers.location
                              );
                              _.set(value, 'id', id);
                              setFieldValue(name, value);
                            });
                        } else {
                          setFieldValue(name, value);
                        }
                      }}
                      erroMensagem={_.get(errors, 'unidadePadrao')}
                      sortNoAsc={true}
                      sortKey={'nome'}
                      creatable={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12  col-md">
                    <InputViewEdit
                      component={MoneyInput}
                      label={'Custo'}
                      type="number"
                      id="custo"
                      name="estoqueUnidade.custo"
                      placeholder="Informe o custo"
                      value={_.get(values, 'estoqueUnidade.custo')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      defaultValue={formatterHelper.money(
                        _.get(values, 'estoqueUnidade.custo')
                      )}
                      noSize={true}
                      erroMensagem={_.get(errors, 'estoqueUnidade.custo')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12  col-md">
                    <InputViewEdit
                      component={MoneyInput}
                      label={'Preço venda particular'}
                      type="number"
                      id="precoVendaParticular"
                      name="estoqueUnidade.precoVendaParticular"
                      placeholder="Informe o preço de venda particular"
                      value={_.get(
                        values,
                        'estoqueUnidade.precoVendaParticular'
                      )}
                      onChange={(name, value) => setFieldValue(name, value)}
                      defaultValue={formatterHelper.money(
                        _.get(values, 'estoqueUnidade.precoVendaParticular')
                      )}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'estoqueUnidade.precoVendaParticular'
                      )}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12  col-md">
                    <InputViewEdit
                      component={MoneyInput}
                      label={'Preço venda convênio'}
                      type="number"
                      id="precoVendaConvenio"
                      name="estoqueUnidade.precoVendaConvenio"
                      placeholder="Informe o preço de venda convênio"
                      value={_.get(values, 'estoqueUnidade.precoVendaConvenio')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      defaultValue={formatterHelper.money(
                        _.get(values, 'estoqueUnidade.precoVendaConvenio')
                      )}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'estoqueUnidade.precoVendaConvenio'
                      )}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12  col-md">
                    <InputViewEdit
                      component={FormInput}
                      label={'Estoque mínimo'}
                      type="number"
                      id="estoqueMinimo"
                      name="estoqueMinimo"
                      placeholder="Informe o estoque mínimo"
                      value={_.get(values, 'estoqueMinimo')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'estoqueMinimo')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12  col-md">
                    <InputViewEdit
                      component={FormInput}
                      label={'Estoque máximo'}
                      type="number"
                      id="estoqueMaximo"
                      name="estoqueMaximo"
                      placeholder="Informe o estoque máximo"
                      value={_.get(values, 'estoqueMaximo')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'estoqueMaximo')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12  col-md">
                    <InputViewEdit
                      component={FormInput}
                      label={'Estoque inicial'}
                      type="number"
                      id="estoqueInicial"
                      name="estoqueInicial"
                      placeholder="Informe o estoque máximo"
                      value={_.get(values, 'estoqueInicial')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'estoqueInicial')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                </div>

                <h1 className="w-100">Unidades fração</h1>

                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    onClick={() =>
                      this.addUnidadeFracao(
                        'listEstoqueUnidadeFracao',
                        null,
                        values,
                        setValues,
                        true
                      )
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Adicionar unidade fração
                  </button>
                </div>

                {_.get(values, 'listEstoqueUnidadeFracao') &&
                  !_.isEmpty(_.get(values, 'listEstoqueUnidadeFracao')) &&
                  _.get(values, 'listEstoqueUnidadeFracao').length > 0 &&
                  _.get(values, 'listEstoqueUnidadeFracao').map(
                    (unidadeFracao, index) => {
                      return (
                        <div className="row section-form" key={index}>
                          <UnidadeFracao
                            entity={values}
                            index={index}
                            viewMode={viewMode}
                            size={
                              _.get(values, 'listEstoqueUnidadeFracao').length
                            }
                            handleChange={handleChange}
                            errors={errors}
                            custoPadrao={_.get(values, 'estoqueUnidade.custo')}
                            propName={`listEstoqueUnidadeFracao[${index}]`}
                            onChange={setFieldValue}
                            removeItem={() => {
                              this.removeUnidadeFracao(
                                `listEstoqueUnidadeFracao`,
                                index,
                                values,
                                setValues
                              );
                            }}
                          ></UnidadeFracao>
                        </div>
                      );
                    }
                  )}
              </div>
              {/* /////////////// */}
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(EstoqueProdutosForm);
