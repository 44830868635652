//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';

import PacoteForm from './PacoteForm';
import { pacoteService } from '../../_services/pacote.service';

const defaultArrayItem = [{ idList: new Date().getTime() }];

class PacoteView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadUser();
  }

    
  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      pacoteService.doGet(this.props.match.params.id).then(
        (response) => {
          let pacote = response.data.data;
                   
          this.setState({ entity: _.cloneDeep(pacote) }, () => {
            this.props.loading(false);
   
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };

  render() {
    let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  Pacote`}
          canBack={true}
          backUrl={!entity.id ? null : '/pacotes'}
        />
        
        <div className="flex-column mt-4">
          <FullCustomCard
            title="Pacote"
            permissionSave={objectsConstants.CRUD_USUARIO_EDITAR}
            Componente={PacoteForm}
            viewMode={true}
            entity={entity}
            onCancel={this.handleCancel}
            {...otherPops}
          ></FullCustomCard>

         
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  null,
  mapDispatch,
  null
)(withRouter(PacoteView));
