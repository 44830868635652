//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../components/templates/ListagemPage';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { tableHelper } from '../../../_helpers/tableHelper';
import { estoqueMovimentacaoService } from '../../../_services/estoqueMovimentacao.service';
import EstoqueRelatorioMovimentacaoListFilter from './EstoqueRelatorioMovimentacaoListFilter';

const columns = [
  {
    path: 'tipoLancamento',
    pathFilter: 'tipoLancamento',
    title: 'Tipo de lancamento',
    isKey: false,
    dataSort: true,
    width: '10%',
  },

  {
    path: 'ultimaMovimentacao',
    pathFilter: 'ultimaMovimentacao',
    title: 'Última movimentacao',
    isKey: true,
    isDateTime: true,
    dataSort: true,
    width: '10%',
  },
  {
    path: 'produto.nome',
    pathFilter: 'produto.nome',
    title: 'Produto',
    isKey: false,
    dataSort: true,
    width: '25%',
  },
  {
    path: 'quantidade',
    pathFilter: 'quantidade',
    title: 'Quantidade',
    isKey: false,
    dataSort: true,
    width: '8%',
  },
  {
    path: 'custo',
    pathFilter: 'custo',
    title: 'Valor',
    isMoney: true,
    isKey: false,
    dataSort: true,
    width: '5%',
  },
  {
    path: 'estoqueAtualUnidade',
    pathFilter: 'estoqueAtualUnidade',
    title: 'Estoque',
    isKey: false,
    dataSort: true,
    width: '7%',
  },

  {
    path: 'foto',
    title: '',
    isKey: false,
    isAvatar: true,
    dataSort: false,
    propsMidiaId: 'responsavel.foto.id',
    className: 'avatar-list',
    width: '100px',
    notExport: true,
    width: '3%',
  },
  {
    path: 'responsavel.nome',
    pathFilter: 'responsavel.nome',
    title: 'Responsável',
    isKey: false,
    dataSort: true,
    width: '15%',
  },
];

class EstoqueRelatorioMovimentacaoListagemPage extends React.PureComponent<
  {},
  State
> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  // handleRowClick = (row: e) => {
  //   console.log(row.id);
  //   this.props.history.push(endPointView + row.id);
  // };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_ESTOQUE_REL_MOVIMENTACAO}
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_ESTOQUE_EDITAR}
          // buttonActionClassName={'btn-light'}
          // buttonAction={() => {
          //   this.handleNewButton();
          // }}
          // securityActionIcon={'icon-btn-add'}
          // securityActionName={'Novo produto'}
        ></TopoListagem>
        <ListagemPage
          title={'Estoque: Relatório de movimentação de estoque'}
          showNewButton={false}
          delete={() => {}}
          service={estoqueMovimentacaoService}
          defaultOrder={'dataHora'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          // onRowClick={this.handleRowClick}
          FormFilter={EstoqueRelatorioMovimentacaoListFilter}
          controller="api/usuario"
          fields={this.state.fields}
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(
  EstoqueRelatorioMovimentacaoListagemPage
);
