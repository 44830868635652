//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import MedicoEdit from './MedicoEdit';
import MedicoListagemPage from './MedicoListagemPage';
import MedicoView from './MedicoView';

export default class MedicoIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/medico/novo" component={MedicoEdit} />
        <Route path="/medico/:id" component={MedicoView} />
        <Route path="/medico" component={MedicoListagemPage} />
      </Switch>
    );
  }
}
