import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconPainel from "../../images/icon-tv-WHITE.svg";
import { objectsConstants } from '../../_constants/objects.constants';
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import { medicoService } from '../../_services/medico.service';
import FormSelectInput from "../inputs/FormSelectInput";
import InputViewEdit from "../inputs/InputViewEdit";
import { PacienteSelect } from '../inputs/PacienteSelect';


const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

const ValidaIndisponibilidade = Yup.object().shape({

});

export class ModalConsultaAvulsa extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      temEstabelecimento: false,
      estabelecimentoMedico: null
    };
  }



  handlePacienteOnChange = (name?: any, value: any) => {
    let values = {};
    _.set(values, 'paciente', value);
    _.set(values, 'estabelecimentoMedico', this.state.estabelecimentoMedico);
    this.props.handleSelectPacienteConsultaAvulsa(values);
    this.setState({ estabelecimentoMedico: null, temEstabelecimento: false }, () => {
      this.props.toogle();
    })
  };

  onNewOptionClick = (nome) => {
    let values = {};
    _.set(values, 'nome', nome);
    _.set(values, 'estabelecimentoMedico', this.state.estabelecimentoMedico);
    this.props.handleNewOptionClickConsultaAvulsa(values);
    this.setState({ estabelecimentoMedico: null, temEstabelecimento: false }, () => {
      this.props.toogle();
    })
  };

  handleNewOption = (newOption: any) => {
    return { nome: newOption.label, hora: this.props.horario };
  };

  setEstabelecimento = (estabelecimentoMedico) => {
    this.setState({ estabelecimentoMedico: estabelecimentoMedico, temEstabelecimento: true }, () => {
    })
  }

  cancelForm = () => {
    this.props.toogle();
  };

  render() {

    const { toggle, isOpen, viewMode, entity, ...otherProps } = this.props;

    let _this = this;
    return (

      // <Formik
      //   validateOnBlur={false}
      //   validateOnChange={false}
      //   validationSchema={ValidaIndisponibilidade}
      //   enableReinitialize={true}
      //   initialValues={{}}
      //   ref={(form) => {
      //     this.formRef = form;
      //   }}
      // >
      //   {({
      //     values,
      //     handleSubmit,
      //     setFieldValue,
      //     errors,
      //   }) => (
      //     <form onSubmit={handleSubmit}>
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-anexos modal-lg modal-consulta-avulsa"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="">
              <div className="row">
                <div className="col-12 text-center iconLocal">
                  <img src={IconPainel} alt="Icone painel de chamada" />
                </div>
                <div className="col-12 text-center mb-2">
                  {/* <h2 className="font-weight-bold"> {`Selecionar Local de atendimento - ${estabelecimento.nome}`}</h2> */}
                  <h2 className="font-weight-bold">Atendimento fora da Agenda</h2>
                  <p>Selecione abaixo um paciente para fazer o atendimento fora da sua agenda.
                  </p>
                </div>
              </div>

              <React.Fragment>
                <div className="form-row">
                  <div className="col-12">
                    <InputViewEdit
                      component={FormSelectInput}
                      onChange={(name, value) => {
                        this.setEstabelecimento(value)
                      }}
                      id={`estabelecimentoMedico`}
                      label="Local de atendimento"
                      placeholder="Selecione o local do atendimento"
                      name={`estabelecimentoMedico`}
                      required={true}
                      value={this.state.estabelecimentoMedico}
                      returnFullObject={true}
                      service={medicoService.findByEspecialidade}
                      creatable={false}
                      isMulti={false}
                      valueKey={'estabelecimento.id'}
                      labelKey={'estabelecimento.nome'}
                    />
                  </div>
                  {this.state.temEstabelecimento && (
                    <div className="col-12">
                      <label>Paciente*</label>
                      <PacienteSelect
                        onChange={this.handlePacienteOnChange}
                        value={this.state.paciente}
                        onNewOptionClick={this.onNewOptionClick}
                        newOptionCreator={this.handleNewOptionClickConsultaAvulsa}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-100 text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.cancelForm();
                  }}
                >
                  {" "}
                  Cancelar{" "}
                </button>
              </div>
              {/* <div className="w-50 text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (consultorio) {
                      _this.fecharModal();
                      _this.props.callbackOnSave(
                        estabelecimento.id,
                        consultorio,
                        idMarcacao,
                        status
                      );
                    } else {
                      _this.props.error({
                        message: 'Selecione um local de atendimento.',
                      });
                    }
                  }}
                >
                  {" "}
                  Salvar{" "}
                </button>
              </div> */}
            </React.Fragment>
          </ModalFooter>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment>

      //     </form>
      //   )}
      // </Formik>
    );
  }
}
