//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from './../../components/inputs/FormInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class ExameListFilter extends React.PureComponent<Props> {
    render() {
        const { entity } = this.props;
        return (
            <React.Fragment>
                <FormGroup className="col-12 col-xl-3">
                    <FormInput
                        label="Nome do exame"
                        placeholder={'Nome do exame'}
                        id={'namefilter'}
                        name="nome"
                        onChange={this.props.onChangeDate}
                        type={'text'}
                        size={12}
                        style={'col-12 col-sm-4 offset-sm-1'}
                        value={entity.nome}
                    />
                </FormGroup>
                <FormGroup className="col-12 col-xl-2">
                    <FormInput
                        label="Código do procedimento"
                        placeholder={'Código do procedimento'}
                        id={'codigofilter'}
                        name="codigoProcedimento"
                        onChange={this.props.onChangeDate}
                        type={'text'}
                        size={12}
                        style={'col-6 col-sm-2'}
                        value={entity.codigoProcedimento}
                    />
                </FormGroup>
                <FormGroup className="col-12 col-xl-3">
                    <FormInput
                        label="Nome do procedimento"
                        placeholder={'Nome do procedimento'}
                        id={'nomeProcedimentofilter'}
                        name="descricaoProcedimento"
                        onChange={this.props.onChangeDate}
                        type={'text'}
                        size={12}
                        style={'col-12 col-sm-4 offset-sm-1'}
                        value={entity.descricaoProcedimento}
                    />
                </FormGroup>
            </React.Fragment>
        );
    }
}
