//@flow
import { Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'reactstrap';
import * as Yup from 'yup';
import _ from 'lodash';
import FormInput from '../../components/inputs/FormInput';
import { pacienteService } from '../../_services/paciente.service';
import { defaultService } from '../../_services/defaultService';
import { translate } from '../../_helpers/messages.helper';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import { authenticationService } from '../../_services/authentication.service';
import { objectsConstants } from '../../_constants/objects.constants';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const PacienteValidateModel = Yup.object().shape({
  nome: Yup.string()
    .min(7, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Obrigatório')
    .matches(/^[A-ZÀ-Úa-zà-ú\s]+$/, 'Somente letras são aceitas'),
  cpfCnpj: Yup.string().required('Obrigatório'),
  senha: ITEM_REQUIRED,
});

class AgendaOnlineCadastro extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // const { estabelecimento } = this.props;
    let estabelecimento =
      authenticationService.getCurrentEstabelecimentoOnline();
    console.log(estabelecimento);
    return (
      <React.Fragment>
        <div className="move-up">
          <div className="text-center">
            <h2>Para fazer seu cadastro, insira as informações abaixo:</h2>
          </div>
          <hr></hr>
          <Formik
            validationSchema={PacienteValidateModel}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            initialValues={{ estabelecimento: estabelecimento.id }}
            ref={(form) => {
              this.formRef = form;
            }}
            onSubmit={(values, { setFieldError }) => {
              let _this = this;
              _.set(values, 'estabelecimento', estabelecimento.id);

              console.log(values);
              _this.props.loading(true);
              pacienteService.doRegister(values).then(
                (response) => {
                  console.log(response);
                  _this.props.loading(false);
                  console.log(values);
                  this.props.success({
                    message: `Cadastro, realizado com sucesso!`,
                  });
                  this.props.loading(false);

                  this.props.login(
                    values.cpfCnpj,
                    values.senha,
                    true,
                    values.role ? values.role.id : 0
                  );
                },
                (erros) => {
                  _this.props.loading(false);
                  _this.props.error({
                    message:
                      'Não foi possível realizar o cadastro, verifique os dados informados.',
                  });
                  try {
                    let response = erros.response.data;
                    if (response && response.messages) {
                      for (var i = 0; i < response.messages.length; i++) {
                        let erroItem = response.messages[i];
                        setFieldError(
                          erroItem.fieldName,
                          translate(erroItem.message.code)
                        );
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                  console.log(erros);
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              validationSchema,
              validateForm,
              setValues,
            }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <InputViewEdit
                  component={FormInput}
                  label="Nome completo"
                  type="text"
                  name="nome"
                  id="nome"
                  value={values.nome}
                  placeholder="Insira o seu nome completo"
                  erroMensagem={errors.nome}
                  onChange={setFieldValue}
                  required={true}
                />
                <InputViewEdit
                  component={CpfCnpjInput}
                  label={'CPF'}
                  type="text"
                  id="cpfCnpj"
                  name="cpfCnpj"
                  placeholder="Digite o CPF"
                  value={values.cpfCnpj}
                  onChange={setFieldValue}
                  noSize={true}
                  erroMensagem={errors.cpfCnpj}
                  required={true}
                />
                <InputViewEdit
                  component={FormInput}
                  type="email"
                  name="email"
                  id="email"
                  label="E-mail"
                  value={values.email}
                  placeholder="Insira o seu e-mail"
                  erroMensagem={errors.email}
                  onChange={setFieldValue}
                />
                <InputViewEdit
                  component={FormInput}
                  name={'senha'}
                  id={'senha'}
                  label="Senha"
                  value={values.senha}
                  type={'password'}
                  onChange={setFieldValue}
                  placeholder="Insira sua senha"
                  maxLength={30}
                  viewPassword={true}
                  erroMensagem={errors.senha}
                  required={true}
                />{' '}
                <div className="d-flex mt-3 justify-content-center align-items-center"></div>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    className="bt-size-fixed my-4"
                    block={true}
                    color="primary"
                    type="submit"
                  >
                    Cadastrar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn, loginOnline } = state.authentication;
  const { alert } = state;
  return {
    loggedIn,
    loginOnline,
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading },
}) => ({
  error: (message) => error(message),
  clear: () => clear(),
  success: (msg) => success(msg),
  login: (user, password, loginOnline, perfil) =>
    login({ user, password, loginOnline, perfil }),
  loading: (load: boolean) => loading({ load }),
});
const connectedAgendaOnlineCadastro = connect(
  mapStateToProps,
  mapDispatch
)(AgendaOnlineCadastro);
export { connectedAgendaOnlineCadastro as AgendaOnlineCadastro };
