import _ from 'lodash';

export function shouldComponentUpdate(instance,nextProps,nextState){
    let keysNewProps = Object.keys(nextProps);
  let propsEquals = isEquals(keysNewProps,nextProps,instance.props);
    let keysNewState = Object.keys(nextState);
    let stateEquals = isEquals(keysNewState,nextState,instance.state);
    return !propsEquals || !stateEquals;
}



function is(x, y) {
    // SameValue algorithm
    if (x === y) {
        // Steps 1-5, 7-10
        // Steps 6.b-6.e: +0 != -0
        // Added the nonzero y check to make Flow happy, but it is redundant
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
        // Step 6.a: NaN == NaN
        return x !== x && y !== y;
    }
}

function isEquals (keys,objA,objB){
    for (let i = 0; i < keys.length; i++) {
        if (typeof objA[keys[i]] != 'function' && (!hasOwnProperty.call(objB, keys[i]) || !_.isEqual(objA[keys[i]], objB[keys[i]]))) {
            return false;
        }
    }
    return true;
}