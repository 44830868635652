import React, { PureComponent } from 'react';
import toastIconSucess from '../../images/ico_sucess.svg';

export default class IconSucessToast extends PureComponent {
  render() {
    return (
      <div className="contentAvatarToastr">
        {/* <img src={toastIconSucess} alt="avatar" className="icon_error" /> */}
      </div>
    );
  }
}
