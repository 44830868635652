import { Formik } from 'formik';
import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import _ from 'lodash';
import CheckBoxInputOnly from '../../../components/inputs/CheckBoxInputOnly';
import FormInput from '../../../components/inputs/FormInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import { ModalTermoAceite } from '../../../components/Modal/ModalTermoAceite';

const Step2ValidateModel = Yup.object().shape({
  senha: Yup.string().required('Campo obrigatório'),
  confirmaSenha: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('senha'), null], 'Senhas diferentes'),
  acceptTermosUso: Yup.bool().oneOf([true], 'Campo requerido'),
});

class Step2 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalTermo: false,
    };
  }

  toggleModalTermo = () => {
    console.log('toggleModalTermo');
    this.setState(({ modalTermo }) => ({
      modalTermo: !modalTermo,
    }));
  };

  render() {
    return (
      <Formik
        validationSchema={Step2ValidateModel}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.props.entity}
        ref={(form) => {
          this.form = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="row form-inside">
              <div className="col-6 section-form">
                <ModalTermoAceite
                  toogle={this.toggleModalTermo}
                  isOpen={this.state.modalTermo}
                />
                <p>Passo 2 de 6 - Defina e cadastre sua senha de acesso</p>
                <div className="row">
                  <FormGroup className="col-12 col-md-12">
                    <InputViewEdit
                      component={FormInput}
                      label="Senha"
                      type="password"
                      name="senha"
                      id="senha"
                      placeholder="Insira sua senha"
                      required={true}
                      value={values.senha}
                      onChange={this.props.handleChange}
                      erroMensagem={errors.senha}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-12">
                    <InputViewEdit
                      component={FormInput}
                      label="Confirmar senha"
                      type="password"
                      name="confirmaSenha"
                      id="confirmaSenha"
                      placeholder="Insira sua senha"
                      required={true}
                      value={values.confirmaSenha}
                      onChange={this.props.handleChange}
                      erroMensagem={errors.confirmaSenha}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-6 d-flex mt-auto justify-content-end flex-row">
                <CheckBoxInputOnly
                  checked={values.acceptTermosUso}
                  name={`acceptTermosUso`}
                  required={true}
                  erroMensagem={_.get(errors, 'acceptTermosUso')}
                  label="Ao continuar, concordo que li e aceito os "
                  onChange={(checked, name) => {
                    this.props.handleChange(name, checked);
                  }}
                />{' '}
                <label className="top ml-0 mt-auto termoCheck">
                  <span onClick={this.toggleModalTermo}>termo de uso</span>
                </label>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Step2;
