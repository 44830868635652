//@flow
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonAutorized from '../../../components/buttons/ButtonAutorized';
import { CalendarDateView } from '../../../components/calendar/CalendarDateView';
import DadosUser from '../../../components/DadosUser/DadosUser';
import { objectsConstants } from '../../../_constants/objects.constants';
import { legendHelper } from '../../../_helpers/legend.helper';
import { ConfirmAcationService } from '../../../_services/ConfirmAcationService';
import { marcacaoService } from '../../../_services/marcacao.service';
import { prontuarioService } from '../../../_services/prontuario.service';
import { userService } from '../../../_services/user.service';
import ObservacaoEditForm from '../ObservacaoEditForm';
import StatusAtendimento from './StatusAtendimento';

// const HORAS_DELAY_ATENDIMENTO = 2;

class Atendimento extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    if (this.props.atendimento.idMarcacao) {
      this.findMarcacao(this.props.atendimento.idMarcacao);
    }
    if (this.props.atendimento.fkMarcacao) {
      this.findMarcacao(this.props.atendimento.fkMarcacao);
    }
  }

  verAnamnese = (atendimento) => {
    prontuarioService.findByMarcacao(atendimento.idMarcacao).then(
      (response) => {
        let prontuario = response.data.data;
        console.log(prontuario);
        if (prontuario) {
          this.props.history.push({
            pathname: '/prontuario/anamnese/',
            state: { anamnese: prontuario },
          });
        } else {
          this.props.error({
            message: 'Esse atendimento não possui anamnese',
          });
        }
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  findMarcacao = (idMarcacao) => {
    marcacaoService.doGet(idMarcacao).then(
      (response) => {
        this.setState({
          marcacao: response.data.data,
        });
      },
      () => {
        console.log('Não foi possivel buscar marcação');
      }
    );
  };

  getBtn = (data, atendimento) => {
    if (
      moment(data).isSameOrAfter(new Date()) &&
      atendimento.status === objectsConstants.MARCADO
    ) {
      return (
        <React.Fragment>
          <ButtonAutorized
            type="button"
            className="btn-danger delete"
            label={'Desmarcar'}
            role={objectsConstants.AGENDA_MARCAR}
            onClick={(e) => {
              ConfirmAcationService.confirmMsg(
                () => {
                  this.props.desmarcarConsulta(atendimento.idMarcacao);
                },
                'Desmarcar atendimento',
                <span>
                  Deseja realmente desmarcar esse atendimento
                  <span className="font-bold">{atendimento.id}</span>?
                </span>,
                <span className="text-danger">{}</span>,
                'Não',
                'Sim',
                objectsConstants.TYPE_CANCEL
              );
            }}
          ></ButtonAutorized>

          <ButtonAutorized
            type="button"
            className="btn-blue "
            label={'Editar obsevação '}
            role={objectsConstants.AGENDA_COMPARECER}
            onClick={(e) => {
              this.abreEditarObservacao();
            }}
          />
        </React.Fragment>
      );
    } else if (atendimento.status === objectsConstants.CANCELADO) {
      return (
        <React.Fragment>
          <ButtonAutorized
            type="button"
            className="btn-blue "
            label={'Editar obsevação '}
            role={objectsConstants.AGENDA_COMPARECER}
            onClick={(e) => {
              this.abreEditarObservacao();
            }}
          />
        </React.Fragment>
      );
    } else if (
      moment(data).isAfter(new Date()) &&
      atendimento.status === objectsConstants.MARCACOES_FORA_HORARIO_MEDICO
    ) {
      return (
        <React.Fragment>
          <ButtonAutorized
            type="button"
            className="btn-secondary delete mb-0"
            label={'Cancelar'}
            role={objectsConstants.AGENDA_MARCAR}
            onClick={(e) => {
              ConfirmAcationService.confirmMsg(
                () => {
                  this.props.desmarcarConsulta(atendimento.idMarcacao);
                },
                'Cancelar atendimento',
                <span>
                  Deseja realmente cancelar esse atendimento
                  <span className="font-bold">{atendimento.id}</span>?
                </span>,
                <span className="text-danger">{}</span>,
                'Não',
                'Sim',
                objectsConstants.TYPE_CANCEL
              );
            }}
          ></ButtonAutorized>
          <ButtonAutorized
            className="btn-secondary open"
            label={'Remarcar'}
            role={objectsConstants.AGENDA_MARCAR}
            onClick={() =>
              this.props.remarcar(this.state.marcacao, atendimento.medico)
            }
          ></ButtonAutorized>
        </React.Fragment>
      );
    } else if (
      !atendimento.exame &&
      (userService.isMedico() || userService.isSuporte()) &&
      atendimento.status !== objectsConstants.MARCADO
    ) {
      return (
        <ButtonAutorized
          label={'Ver anamnese'}
          className="btn-link"
          icon={'icon-visualizar'}
          role={objectsConstants.CRUD_ANAMNESE_VISUALIZAR}
          onClick={() => {
            this.verAnamnese(atendimento);
          }}
        ></ButtonAutorized>
      );
    }
  };

  abreEditarObservacao = () => {
    this.setState({ show: !this.state.show });
  };

  btnCancelEdicao = () => {
    this.setState({ show: false });
  };

  validaData = (atendimento) => {
    if (atendimento.dataHora) {
      return moment(atendimento.dataHora).toDate();
    } else if (atendimento.dataHoraConsulta) {
      return moment(atendimento.dataHoraConsulta).toDate();
    } else if (atendimento.data && atendimento.horario) {
      var dia = atendimento.data.split('/')[0];
      var mes = atendimento.data.split('/')[1];
      var ano = atendimento.data.split('/')[2];
      let dataFormatada =
        ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
      return moment(dataFormatada + ' ' + atendimento.horario).toDate();
    }
  };

  getTipoAtendimento = () => {
    const { atendimento } = this.props;
    let tipoAtend = _.get(
      atendimento,
      'tipoAtendimento',
      _.get(atendimento, 'marcacao.tipoAtendimento')
    );
    if (tipoAtend === objectsConstants.TIPO_ATENDIMENTO_CONVENIO) {
      return (
        _.get(
          atendimento,
          'convenio.nome',
          _.get(atendimento, 'marcacao.convenio.nome', 'Convênio')
        ) +
        '-' +
        _.get(atendimento, 'planoConvenio.nome', '')
      );
    } else if (tipoAtend === objectsConstants.TIPO_ATENDIMENTO_PARTICULAR) {
      return 'Particular';
    } else if (tipoAtend === objectsConstants.TIPO_ATENDIMENTO_CORTESIA) {
      return 'Cortesia';
    }
  };

  getHorario = () => {
    const { atendimento } = this.props;
    return atendimento.horario
      ? atendimento.horario
      : atendimento.marcacao
      ? atendimento.marcacao.hora.split(':')[0] +
        ':' +
        atendimento.marcacao.hora.split(':')[1]
      : '';
  };

  // horaMenorQueAgoraDelay = (horario, data) => {
  //   let horas = horario.split(':');
  //   let horarioDelay =
  //     parseInt(horas[0]) + HORAS_DELAY_ATENDIMENTO + ':' + horas[1];
  //   return this.horaMenorQueAgora(horarioDelay, data);
  // };

  horaMenorQueAgora = (horario, data) => {
    if (horario) {
      let dataAtual = moment();
      let dataRow = moment(data);
      let horas = horario.split(':');
      dataRow.set({ hour: horas[0], minute: horas[1] });

      if (dataRow.isBefore(dataAtual)) {
        return true;
      }
    }
    return false;
  };
  getNomeEspecialidade = (atendimento) => {
    return _.get(atendimento, 'especialidade.nome')
      ? ' (' + _.get(this.props.atendimento, 'especialidade.nome') + ')'
      : _.get(atendimento, 'marcacao.especialidade.nome')
      ? ' (' +
        _.get(this.props.atendimento, 'marcacao.especialidade.nome') +
        ')'
      : '';
  };

  render() {
    const { atendimento, online, ...otherProps } = this.props;
    let data = this.validaData(atendimento);
    return (
      <div className={`atendimento-row ${this.props.classNameRow}`}>
        <section>
          <div className="atendimento-row d-flex flex-column flex-lg-row align-items-center">
            <div className="atendimento-data">
              <CalendarDateView data={data}></CalendarDateView>
            </div>

            <div className="legends d-flex justify-content-end">
              {legendHelper.createLegendSituation(atendimento)}
              {legendHelper.createLegendType(atendimento)}
            </div>
            <div className="atendimento-dados d-flex flex-fill flex-column">
              <span>
                <b>{this.getHorario()}</b> {` / ${this.getTipoAtendimento()}`}
                {this.getNomeEspecialidade(atendimento)}
              </span>
              {atendimento.medico && (
                <DadosUser idUser={atendimento.medico.id}></DadosUser>
              )}
              {atendimento.exame && (
                <div className="dados-user d-flex align-items-center justify-content-start">
                  {atendimento.exame.nome}
                </div>
              )}
              {atendimento.observacoes && !this.props.online && (
                <div className="obs">{atendimento.observacoes}</div>
              )}
              <StatusAtendimento atendimento={atendimento}> </StatusAtendimento>
            </div>

            <div className="atendimento-acao mr-2">
              <div className="d-flex justify-content-center">
                {this.getBtn(data, atendimento)}
                {(userService.isAdministrador() || userService.isSuporte()) && (
                  <ButtonAutorized
                    className="btn-secondary open"
                    label={'Histórico'}
                    role={objectsConstants.AGENDA_MARCAR}
                    onClick={() => {
                      console.log('Abre histórico');
                      this.props.abreHistoricoConsulta(atendimento);
                    }}
                  ></ButtonAutorized>
                )}
              </div>
            </div>
          </div>

          {this.state.show && (
            <section>
              <ObservacaoEditForm
                atendimento={atendimento}
                closeEdicao={this.btnCancelEdicao}
                {...otherProps}
              />
            </section>
          )}
        </section>
      </div>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(Atendimento));
