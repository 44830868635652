//@flow
//@flow
import _ from "lodash";
import "moment/locale/pt-br";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import DadosUser from "../../../components/DadosUser/DadosUser";
import { FilaAtendimentoRow } from "./FilaAtendimentoRow";
import "./FilaAtendimento.scss"
import { connect } from "react-redux";
import ClockIcon from "../../../images/icones/clock-icon.svg";
import SemAgendaIcon from "../../../images/icones/icon-sem-agenda.svg";
import ComAgendaIcon from "../../../images/icones/icon-com-agenda.svg";
import PersonLocation from "../../../images/icones/icon-person-location.svg";
import Avatar from "../../../components/Avatar/Avatar";
import {FilaAtendimentoRowPacienteSemAgenda} from "./FilaAtendimentoRowPacienteSemAgenda";
import moment from "moment";
import {formatterHelper} from "../../../_helpers/formatter.helper";

class FilaAtendimento extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filaAExibir: 1 };
  }

  changeFila = (filaAExibir) => {
    this.setState({ filaAExibir });
  };
  render() {
    const { marcacoes, fila, ...otherProps } = this.props;
    let marcacao;
    let primeiroFila;
    if(marcacoes&&marcacoes.length>0){
      marcacao = marcacoes[0];
    }
    if(fila&&fila.length>0){
      primeiroFila = fila[0];
    }

    return (
      <div>
        <div className="d-flex justify-content-center">
          <div className="col-xl-2 col-sm-6 col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex">
                    <div className="align-self-center">
                      <i className="icon-pencil primary font-large-2 float-left"></i>
                    </div>
                    <div className="media-body text-right">
                      Consulta express
                      <span>0 horas e 40 min aguardando</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`btn-custom nav-link ${
                this.state.filaAExibir === 1 ? "active" : ""
              } `}
              onClick={() => {
                this.changeFila(1);
              }}
            >
              Pacientes agendados
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`btn-custom nav-link ${
                this.state.filaAExibir === 2 ? "active" : ""
              } `}
              onClick={() => {
                this.changeFila(2);
              }}
            >
              Pacientes sem agendamento
            </button>
          </li>
        </ul>
        <div className={'fila-body-container'}>
        {this.state.filaAExibir === 1 &&
          marcacoes.map((marcacao, index) => {
            return <FilaAtendimentoRow marcacao={marcacao} {...otherProps} />;
          })}

        {this.state.filaAExibir === 2 &&
          fila.map((marcacao, index) => {
            return <FilaAtendimentoRowPacienteSemAgenda marcacao={marcacao} {...otherProps} />;
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { fila } = state.agenda;
  return {
    fila,
  };
}

export default connect(mapStateToProps, null)(FilaAtendimento);
