//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from '../../../../components/inputs/FormInput';
import FormSelectInput from '../../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../../components/inputs/InputViewEdit';
import MoneyInput from '../../../../components/inputs/MoneyInput';
import SelectInput from '../../../../components/inputs/SelectInput';
import IconDelete from '../../../../images/icon_x_red.svg';
import { formatterHelper } from '../../../../_helpers/formatter.helper';
import { defaultService } from '../../../../_services/defaultService';
import { unidadePadraoService } from '../../../../_services/unidadePadrao.service';

type Props = {
  entity: any,
};

export default class UnidadeFracao extends React.Component<Props, State> {
  calculaCusto = (custoPadrao) => {
    const { entity, propName, index, viewMode, size } = this.props;

    let custo = custoPadrao / _.get(entity, `${propName}.fracoes`, 1);

    console.log(custoPadrao);

    if (isNaN(custo) || custoPadrao == null || custoPadrao == 0) {
      this.props.onChange(`${propName}.custo`, 'R$ 0,00');
    } else {
      this.props.onChange(`${propName}.custo`, custo);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.entity.estoqueUnidade.custo !=
      this.props.entity.estoqueUnidade.custo
    ) {
      console.log('ok');
      this.calculaCusto(nextProps.entity.estoqueUnidade.custo);
    }
  }

  render() {
    const { entity, propName, index, viewMode, size } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            component={FormInput}
            label={'Quantidade fração'}
            type="number"
            id={`fracoes${index}`}
            name={`${propName}.fracoes`}
            placeholder="Informe quantidade fraçãos"
            value={_.get(entity, `${propName}.fracoes`)}
            onChange={this.props.onChange}
            onBlur={() => {
              this.calculaCusto(_.get(entity, `estoqueUnidade.custo`, 0));
            }}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.fracoes`)}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            viewClassName="font-weight-bold"
            label="Unidade fração"
            id={`unidadePadrao${index}`}
            name={`${propName}.unidadePadrao`}
            placeholder="Selecione a unidade fraçãos"
            component={FormSelectInput}
            service={unidadePadraoService.findByEstabelecimento}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            erroMensagem={_.get(this.props.errors, `${propName}.unidadePadrao`)}
            required={true}
            defaultValue={_.get(entity, `${propName}.unidadePadrao.nome`, '--')}
            value={_.get(entity, `${propName}.unidadePadrao`)}
            viewMode={viewMode}
            onChange={(name, value) => {
              if (!value.id) {
                unidadePadraoService.doSave(value).then((response) => {
                  let id = defaultService.getIdFromUrl(
                    response.headers.location
                  );
                  _.set(value, 'id', id);
                  this.props.onChange(name, value);
                });
              } else {
                this.props.onChange(name, value);
              }
            }}
            sortNoAsc={true}
            sortKey={'nome'}
            creatable={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={MoneyInput}
            label={'Custo'}
            type="number"
            id={`custo${index}`}
            name={`${propName}.custo`}
            // placeholder="Informe o preço venda particular"
            value={_.get(entity, `${propName}.custo`)}
            defaultValue={formatterHelper.money(
              _.get(entity, `${propName}.custo`)
            )}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.custo`)}
            viewMode={true}
            required={false}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            component={MoneyInput}
            label={'Preço venda particular'}
            type="number"
            id={`precoVendaParticular${index}`}
            name={`${propName}.precoVendaParticular`}
            placeholder="Informe o preço venda particular"
            value={_.get(entity, `${propName}.precoVendaParticular`)}
            defaultValue={formatterHelper.money(
              _.get(entity, `${propName}.precoVendaParticular`)
            )}
            onChange={this.props.onChange}
            noSize={true}
            erroMensagem={_.get(
              this.props.errors,
              `${propName}.precoVendaParticular`
            )}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            component={MoneyInput}
            label={'Preço venda convênio'}
            type="number"
            id={`precoVendaConvenio${index}`}
            name={`${propName}.precoVendaConvenio`}
            placeholder="Informe o preço venda convênio"
            value={_.get(entity, `${propName}.precoVendaConvenio`)}
            defaultValue={formatterHelper.money(
              _.get(entity, `${propName}.precoVendaConvenio`)
            )}
            onChange={this.props.onChange}
            noSize={true}
            erroMensagem={_.get(
              this.props.errors,
              `${propName}.precoVendaConvenio`
            )}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <div
          className={`form-group col-2 col-lg-1 align-items-center justify-content-start ${
            viewMode ? 'd-none' : ' d-flex '
          }`}
        >
          <div
            className={`icon-delete d-flex align-items-end justify-content-start$ {
              size === 1 || viewMode ? 'disabled' : ''
            }`}
            onClick={() => {
              this.props.removeItem(index, entity);
            }}
            disabled={size === 1}
          >
            <img src={IconDelete} alt="img" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
