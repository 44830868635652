import { Formik } from "formik";
import React, { PureComponent } from "react";
import moment from 'moment';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import _ from 'lodash';
import saveProtocolo from "../../images/icon-save.svg";
import { translate } from "../../_helpers/messages.helper";
import { defaultService } from "../../_services/defaultService";
import { modeloService } from "../../_services/modelo.service";
import { protocoloAnamneseService } from "../../_services/protocoloAnamnese.service";
import FormInput from "../inputs/FormInput";
import FormSelectInput from "../inputs/FormSelectInput";
import InputViewEdit from "../inputs/InputViewEdit";
import { marcacaoService } from "../../_services/marcacao.service";
import { FormCheck } from "../inputs/FormCheck";
import CheckBoxInputOnly from "../inputs/CheckBoxInputOnly";
import DatePickerInput from "../inputs/DatePickerInput";
import { dateHelper } from "../../_helpers/date.helper";
import { medicoService } from "../../_services/medico.service";
import { agendaService } from "../../_services/agenda.service";
import { userService } from "../../_services/user.service";

const GerarDocumentoValidate = Yup.object().shape({
	medicoList: Yup.object()
		.nullable()
		.when('requiredMedicoList', (requiredMedicoList, schema) => {
			if (requiredMedicoList) {
				return Yup.string().required('Campo obrigatório');
			} else {
				return Yup.string().notRequired();
			}
		}),
});

const modifiers = {
	preventOverflow: {
		enabled: false
	},
	flip: {
		enabled: false
	}
};


export class ModalImpressaoAgenda extends PureComponent<Props, State> {
	constructor(props) {
		super(props);


	}

	cancelar = () => {
		this.props.toogle();
	};

	// getApelido = (listaMedico) => {
	// 	const prefixNameM = "Dr. ";
	// 	const prefixNameF = "Dra. ";
	// 	_.forEach(listaMedico, function (medico) {
	// 		if (_.get(medico, 'sexo') != null && _.get(medico, 'sexo') == "F") {
	// 			_.set(medico, 'nome', prefixNameF + '' + _.get(medico, 'nome'));
	// 		} else {
	// 			_.set(medico, 'nome', prefixNameM + '' + _.get(medico, 'nome'));
	// 		}
	// 	});
	// }


	render() {
		const { callbackOnSave, viewHorario, date } = this.props;
		let _this = this;

		if (!userService.isMedico() && !viewHorario) {
			_.set(this.props.filtro, 'requiredMedicoList', true)
		} else {
			_.set(this.props.filtro, 'requiredMedicoList', false)

		}
		return (
			<React.Fragment>
				<Modal
					isOpen={this.props.isOpen}
					toggle={this.props.toggle}
					backdrop="static"
					modalClassName=""
					className="modal-anexos"
					centered={true}
					modifiers={modifiers}
					target={this.props.target}
				>
					<Formik
						validationSchema={GerarDocumentoValidate}
						validateOnBlur={false}
						validateOnChange={false}
						enableReinitialize={true}
						initialValues={this.props.filtro}
						onSubmit={(values) => {
							this.props.load(true);
							let filtroPrint
							if (this.props.viewHorario && this.props.date) {
								filtroPrint = _.cloneDeep(this.props.filtro);
								_.set(filtroPrint, 'data', this.props.date);
								_.set(filtroPrint, 'horariosVazios', values.horariosVazios);
								_.set(filtroPrint, 'observacoes', values.observacoes);
							} else {
								filtroPrint = _.cloneDeep(values);
								filtroPrint.dataStart = moment(values.dataStart).format('YYYY-MM-DDTHH:mm:ss');
								filtroPrint.dataEnd = moment(values.dataEnd).format('YYYY-MM-DDTHH:mm:ss');

							}
							console.log(filtroPrint);
							agendaService.imprimir(filtroPrint).then(
								(response) => {
									var base64 = _.get(response, 'data.data.data');
									let asciiString = atob(base64);
									let array = new Uint8Array(
										[...asciiString].map((char) => char.charCodeAt(0))
									);
									const file = new Blob([array], { type: 'application/pdf' });
									const fileURL = URL.createObjectURL(file);
									window.open(fileURL);
									this.props.load(false);
									// this.props.callbackOnSave(values);
									this.props.toogle();
								},
								(error) => {
									this.props.load(false);
								}
							);
						}}
						ref={form => {
							this.formRef = form;
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							validationSchema,
							validateForm,
							setValues
						}) => (

							< form onSubmit={handleSubmit}>
								{console.log(errors)}
								<ModalBody>
									<div className="">
										<div className="row">
											<div className="col-12 text-center iconProtocolo">
												<img src={saveProtocolo} alt="anexo" />
											</div>
											<div className="col-12 text-center mb-2">
												{this.props.viewHorario && this.props.date && (
													<h2 className="font-weight-bold">Impressão da agenda  {dateHelper.format(this.props.date, {
														mode: 'DATE',
													})}</h2>
												)}
												{!this.props.viewHorario && (
													<h2 className="font-weight-bold">Impressão da agenda</h2>
												)}

											</div>

										</div>

										<React.Fragment>
											<div className="form-row">
												{!viewHorario && (
													<React.Fragment>
														<div className="col-12">
															<React.Fragment>
																<div className="form-row">
																	<div className="col-6 py-0">
																		<InputViewEdit
																			component={DatePickerInput}
																			label="Dia início"
																			name={`dataStart`}
																			erroMensagem={_.get(errors, `dataStart`)}
																			id={`dataStart`}
																			value={values.dataStart}
																			onChange={(name, value) => setFieldValue(name, value)}
																			placeholder={'--/--/----'}
																			required={false}
																			defaultValue={
																				_.get(values, `dataStart`)
																					? dateHelper.format(_.get(values, `dataStart`), {
																						mode: 'DATE',
																					})
																					: '--'
																			}
																		/>
																	</div>
																	<div className="col-6 py-0">
																		<InputViewEdit
																			component={DatePickerInput}
																			label="Dia fim"
																			name={`dataEnd`}
																			id={`dataEnd`}
																			value={values.dataEnd}
																			onChange={(name, value) => setFieldValue(name, value)}
																			erroMensagem={_.get(errors, `dataEnd`)}
																			placeholder={'--/--/----'}
																			required={false}
																			defaultValue={
																				_.get(values, `dataEnd`)
																					? dateHelper.format(_.get(values, `dataEnd`), {
																						mode: 'DATE',
																					})
																					: '--'
																			}
																		/>
																	</div>
																</div>
															</React.Fragment>
														</div>
														{!userService.isMedico() && (
															<div className="col-12">
																<FormSelectInput
																	name="medicoList"
																	id={'medico'}
																	// icone={Medicos}
																	classIcone={'off'}
																	label="Profissional de saúde"
																	placeholder="Selecione um profissional de saúde"
																	valueKey="id"
																	labelKey="nome"
																	noSize={true}
																	required={!userService.isMedico() && !this.props.viewHorario}
																	returnFullObject={true}
																	isMulti={true}
																	searchable={true}
																	allowSelectAll={true}
																	erroMensagem={errors.medicoList}
																	service={medicoService.findMedicoByEspecialidade}
																	onChange={(name, value) => {
																		setFieldValue(name, value);
																	}}
																	value={values.medicoList}
																	parent={_.get(values, 'especialidades[0].id')}
																/>
															</div>
														)}
													</React.Fragment>
												)}
												<div className="col-12">
													<div className="w-100 d-flex flex-row align-items-start" >
														<CheckBoxInputOnly
															checked={values.horariosVazios}
															name={`horariosVazios`}
															id={`horariosVazios`}
															onChange={(checked, name) => {
																setFieldValue(name, checked);
															}}
														/>
														<div className="d-flex flex-row w-100">
															<div className="d-flex flex-column flex-fill my-2">
																<small>Imprimir horários vazios</small>
															</div>
														</div>
													</div>
												</div>
												{/* <div className="col-12">
													<div className="w-100 d-flex flex-row align-items-start" >
														<CheckBoxInputOnly
															checked={values.observacoes}
															name={`observacoes`}
															id={`observacoes`}
															onChange={(checked, name) => {
																console.log(checked);
																console.log(name);
																setFieldValue(name, checked);
															}}
														/>
														<div className="d-flex flex-row w-100">
															<div className="d-flex flex-column flex-fill my-2">
																<small>Imprimir observações</small>
															</div>
														</div>
													</div>
												</div> */}
											</div>
										</React.Fragment>
									</div>
								</ModalBody>
								<ModalFooter>
									<React.Fragment>
										<div className="w-50 text-center">
											<button
												className="btn btn-secondary"
												type="button"
												onClick={() => {
													this.cancelar();
												}}
											>
												Cancelar
											</button>
										</div>
										<div className="w-50 text-center">
											<button
												className="btn btn-primary"
												type="submit"

											>

												Imprimir agenda
											</button>
										</div>
									</React.Fragment>
								</ModalFooter>
							</form>
						)}
					</Formik>
				</Modal>
			</React.Fragment >
		);
	}
}
