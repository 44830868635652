//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect }  from 'react-redux';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import ConveniosPlanos from '../../components/ConveniosPlanos/ConveniosPlanos';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const EstabelecimentoValidateCovenios = Yup.object().shape({
  listConvenio: Yup.array()
    .min(1, 'É necessario informar pelo menos um telefone')
    .of(
      Yup.object().shape({
        sigla: Yup.string().required('Obrigatório'),
        // id: Yup.string().required('Obrigatório'),
      })
    ),
});

class ConsultoriosForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(defaultValue ? defaultValue : {});
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
    } else {
      newValues[propName] = [{}];
    }
    setValues(newValues);
  };

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/estabelecimento/view/' + values.id,
      state: { entity: values },
    });
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          console.log(values);
          estabelecimentoService.saveConvenio(values, values.listConvenio).then(
            (r) => {
              _this.props.success({
                message: `Convênios, salvo com sucesso!`,
              });
              _this.props.loading(false);
              resolve(true);
            },
            (error) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar Convênio.',
              });
              console.log(error);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  render() {
    const { viewMode, entity } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EstabelecimentoValidateCovenios}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                {viewMode && (
                  <div className="row section-form">
                    <p className="instruction">
                      O cadastro de convênio é utilizado em todo agendamento.
                      <br></br>
                      Somente os convênios cadastrados aqui serão exibidos para
                      os usuários ao agendar uma consulta.
                    </p>
                  </div>
                )}
                {values.listConvenio &&
                  values.listConvenio.map((convenio, index) => {
                    return (
                      <React.Fragment
                        key={convenio && convenio.id ? convenio.id : index}
                      >
                        <ConveniosPlanos
                          size={values.listConvenio.length}
                          entity={values}
                          index={index}
                          viewMode={viewMode}
                          propName={`listConvenio[${index}]`}
                          onChange={setFieldValue}
                          removeItem={() => {
                            this.removeItemInArray(
                              `listConvenio`,
                              index,
                              values,
                              setValues
                            );
                          }}
                          errors={errors}
                        ></ConveniosPlanos>
                      </React.Fragment>
                    );
                  })}

                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ' d-flex '
                  }`}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      this.addItemInArray(
                        'listConvenio',
                        null,
                        values,
                        setValues
                      )
                    }
                  >
                    Adicionar outro convênio
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ConsultoriosForm);
