//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import {objectsConstants} from '../../_constants/objects.constants';
import GerarVoucherForm from "./GerarVoucherForm";
import {State} from "react-overlays/usePopper";
import FullCustomCard from "../../components/FullCustomCard/FullCustomCard";

class VoucherNew extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);
    this.state = state;
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const entity = {id: 0};
    const { callbackOnSave } = this.props;
      const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Solicitar Novo Voucher"
          canBack={true}
          backUrl="/voucher-soluti"
          backConfirmObject={{
            title: `Cancelar Geração `,
            message:
                'Ao clicar em sim, todos os dados inseridos serão perdidos. Você deseja realmente ' +
                `cancelar a geração do voucher?`,
            subMessage: null,
            type: objectsConstants.TYPE_CANCEL,
          }}
        />
        <div className="flex-column">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_VOUCHER}
            title="Gerar voucher"
            Componente={GerarVoucherForm}
            viewMode={false}
            entity={entity}
            callbackOnSave={callbackOnSave}
            onCancel={this.handleCancel}
            titleBtnSave="Gerar Voucher"
            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(VoucherNew)
);
