//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';

export const authenticationService = {
  doLogin,
  doLogout,
  hasToken,
  getTokenOnline,
  setTokenOnline,
  getlocalStorageFull,
  setEstabelecimentoOnline,
  getCurrentEstabelecimentoOnline
};

function doLogin(username: string, password: string, perfil, loginOnline: boolean) {
  const clientId: string = process.env.REACT_APP_CLIENT_ID || '';
  const clientPassword: string = process.env.REACT_APP_CLIENT_PASSWORD || '';

  const details = {
    client_id: clientId,
    grant_type: 'password',
    username,
    password,
    perfil,
    loginOnline
  };

  const formBody = Object.keys(details).map(
    key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])
  );

  const basicAuthorization = btoa(clientId + ':' + clientPassword);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Connection': 'keep-alive',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Basic ' + basicAuthorization
    },
    body: formBody.join('&')
  };

  return fetch(urlsConstants.OAUTH_TOKEN, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  }).then(token => {
    if (token) {
      console.log(token);
      localStorage.setItem('token', JSON.stringify(token));
    }
    return token;
  })
}

function setTokenOnline(tokenOnline) {
  return localStorage.setItem('tokenOnline', tokenOnline);
}

function setEstabelecimentoOnline(estabelecimento) {
  return localStorage.setItem('estabelecimentoOnline', JSON.stringify(estabelecimento));
}
function getCurrentEstabelecimentoOnline() {
  let estabelecimentoOnline = localStorage.getItem('estabelecimentoOnline');
  if (!estabelecimentoOnline) {
    return null;
  }
  let estabelecimento = JSON.parse(estabelecimentoOnline);
  return estabelecimento;
}


function getlocalStorageFull() {
  return localStorage;
}

function hasToken() {
  return localStorage.getItem('token') !== null;
}


function getTokenOnline() {
  return localStorage.getItem('tokenOnline');

}

function doLogout() {
  var tokenOnline = localStorage.getItem('tokenOnline');
  var estabelecimentoOnline = localStorage.getItem('estabelecimentoOnline');
  localStorage.clear();
  localStorage.setItem('tokenOnline', tokenOnline);
  localStorage.setItem('estabelecimentoOnline', estabelecimentoOnline);
}
