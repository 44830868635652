//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const pagamentoService = {
  doUpdate,
  updateLicenses,
  licencasEmUso,
};

function doUpdate(estabelecimentoPagamentoCrudVO) {
  return defaultService.doPost(
    `${urlsConstants.PAGAMENTO}`,
    estabelecimentoPagamentoCrudVO
  );
}

function updateLicenses(estabelecimentoPagamentoCrudVO) {
  return defaultService.doPost(
    `${urlsConstants.PAGAMENTO}updateLicenses`,
    estabelecimentoPagamentoCrudVO
  );
}

function licencasEmUso() {
  return defaultService.doGet(`${urlsConstants.PAGAMENTO}licencasEmUso`);
}
