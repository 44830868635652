//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const exameService = {
  doGet,
  doSave,
  findByFiltro,
  exportToExcel,
  findAll,
  saveLocalHorarioAtendimento,
  deleteLocalHorarioAtendimento,
  findByEstabelecimento,
};
function deleteLocalHorarioAtendimento(idMedicoEstabelecimento: number) {
  return defaultService.doDelete(
    `${urlsConstants.EXAME}deleteLocalHorarioAtendimento/${idMedicoEstabelecimento}`
  );
}
function saveLocalHorarioAtendimento(exame, estabelecimentoExameVO) {
  return defaultService.doPost(
    `${urlsConstants.EXAME}saveLocalHorarioAtendimento/${exame.id}`,
    estabelecimentoExameVO
  );
}

function findByEstabelecimento(idEstabelecimento) {
  return defaultService.doGet(
    `${urlsConstants.EXAME}findByEstabelecimento/${idEstabelecimento}`
  );
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.EXAME}findAll`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.EXAME}list`,
    options,
    filter
  );

  /*let moc = {
    data: {
      data: [
        {
          id: 5720,
          nome: 'Endoscopia',
        },
      ],
      pages: 1,
      dataTotalSize: 8,
    },
  };
  let p: Promise<any> = Promise.resolve(moc);
  return p;*/
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.EXAME}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.EXAME, entity);
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel(
    'estabelecimento',
    filter,
    fields,
    labels
  );
}
