//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const estoquePosicaoService = {
  findAll,
  doGet,
  findByFiltro,
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.ESTOQUE_POSICAO}findAll`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ESTOQUE_POSICAO}${id}`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ESTOQUE_POSICAO}list`,
    options,
    filter
  );
}
