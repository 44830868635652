import _ from 'lodash';
import * as React from 'react';
import { objectsConstants } from '../../_constants/objects.constants';
import { PacienteSelect } from '../../components/inputs/PacienteSelect';
import './AgendamentoRapido.css';

type Props = {
  horario: any,
  value?: any,
  onStatusChanged: any,
  date: any,
  onAnamneseClick: any,
  onNewOptionClick: any,
  onMarcarClick: any,
};

type State = {
  paciente: any,
  pacienteEncaixe: any,
  valuesState: any,
};

export default class HorarioAgendamentoRapido extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = { show: false, paciente: null };
  }
  toogleInputPaciente = () => {
    let _this = this;
    console.log(_this.props.medico);
    this.setState({ show: !this.state.show });
    document.onkeydown = function (evt) {
      if (evt.keyCode == 27) {
        // Escape key pressed
        _this.toogleInputPaciente();
      }
    };
  };
  handlePacienteOnChange = (name?: any, value: any) => {
    if (value && value.bloqueado) {
      this.props.warning({
        message:
          'Atenção, paciente bloqueado para atendimento com esse profissional.',
      });
      return;
    }
    _.set(value, 'tipo', objectsConstants.TIPO_MARCACAO);

    this.setState({ paciente: value }, () => {
      if (this.props.handlePacienteOnChange) {
        this.props.handlePacienteOnChange(value);
      }
    });
  };
  onNewOptionClick = (nome) => {
    let tipo = objectsConstants.TIPO_MARCACAO;
    this.props.onNewOptionClick(
      nome,
      tipo,
      this.props.medico,
      this.props.agenda
    );
  };
  handleNewOption = (newOption: any) => {
    return { nome: newOption.label, hora: this.props.horario };
  };
  onChangePaciente = (name, paciente) => {
    this.toogleInputPaciente();
    this.props.tooglePacientes(paciente, this.props.medico, this.props.agenda);
  };
  render() {
    return (
      <React.Fragment>
        {!this.state.show && (
          <button
            className="btn-custom "
            type="buttom"
            onClick={this.toogleInputPaciente}
          >
            {this.props.horario}
          </button>
        )}
        {this.state.show && (
          <div className={'d-block'}>
            <span className="label-input-marcacao">
              Precione ESC para cancelar!
            </span>
            <PacienteSelect
              onChange={this.onChangePaciente}
              medico={this.props.medico}
              value={this.state.paciente}
              onNewOptionClick={this.onNewOptionClick}
              newOptionCreator={this.handleNewOption}
              horario={this.props.horario}
              idExame={this.props.idExame}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
