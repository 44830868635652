import * as React from 'react';
import './Triagem.css';
import _ from 'lodash';
import { userService } from '../../_services/user.service';
import { Button } from 'react-bootstrap';
import { filaService } from '../../_services/fila.service';
import Switch from 'react-switch';
import moment from 'moment';
import FormInput from '../../components/inputs/FormInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
export default class FilaPage extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = {
      fila: [],
      filas: [],
      pacienteAtual: null,
    };
  }
  componentDidMount() {
    this.loadFila();
  }
  loadFila = () => {
    this.props.loading(true);
    filaService.findFilas().then(
      (r) => {
        this.props.loading(false);
        let filas = r.data;
        this.setState({ filas });
      },
      () => {
        this.props.loading(false);
      }
    );
  };

  chamarNovamente = (pacienteAtual) => {
    this.props.loading(true);
    let newUser = _.cloneDeep(this.props.user);
    console.log(newUser);
    console.log(this.props.user);
    let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
    let local;
    console.log(lista);
    if (lista) {
      for (let i = 0; i < lista.length; i++) {
        const item = lista[i];
        if (item.estabelecimento.id === userService.getExtension()) {
          local = item;
        }
      }
    }
    console.log(local);
    if (local) {
      filaService.chamarNovamentePaciente(pacienteAtual, local).then(
        (p) => {
          this.props.loading(false);
          this.props.success({ message: 'Chamada realizada com sucesso!' });
        },
        (e) => {
          this.props.loading(false);
          this.props.error({ message: 'Paciente em atendimento!' });
        }
      );
    } else {
      this.props.error({
        message:
          'É necessario selecionar um local de atendimento para chamar o paciente.',
      });
      this.props.loading(false);
    }
  };

  chamar = (pacienteAtual) => {
    this.props.loading(true);
    let newUser = _.cloneDeep(this.props.user);
    console.log(newUser);
    console.log(this.props.user);
    let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
    let local;
    console.log(lista);
    if (lista) {
      for (let i = 0; i < lista.length; i++) {
        const item = lista[i];
        if (item.estabelecimento.id === userService.getExtension()) {
          local = item;
        }
      }
    }
    console.log(local);
    if (local) {
      filaService.chamarPaciente(pacienteAtual, local).then(
        (p) => {
          this.props.loading(false);
          this.setState({ pacienteAtual });
          this.props.success({ message: 'Chamada realizada com sucesso!' });
        },
        (e) => {
          this.props.loading(false);
          this.props.error({ message: 'Paciente em atendimento!' });
        }
      );
    } else {
      this.props.error({
        message:
          'É necessario selecionar um local de atendimento para chamar o paciente.',
      });
      this.props.loading(false);
    }
  };
  cancelar = (pacienteAtual) => {
    this.props.loading(true);
    filaService.cancelarPaciente(this.state.pacienteAtual).then(
      (p) => {
        this.props.loading(false);
        this.setState({ pacienteAtual: null });
        this.props.success({ message: 'Chamda cancelada com sucesso!' });
      },
      (e) => {
        this.props.loading(false);
        this.props.error({ message: 'Paciente em atendimento!' });
      }
    );
  };

  inativar = (pacienteAtual) => {
    this.props.loading(true);
    if (!pacienteAtual) {
      pacienteAtual = this.state.pacienteAtual;
    }
    filaService.inativarPaciente(pacienteAtual).then(
      (p) => {
        this.props.loading(false);
        this.setState({ pacienteAtual: null });
        this.props.success({ message: 'Senha inativada com sucesso!' });
      },
      (e) => {
        this.props.loading(false);
        this.props.error({ message: 'Paciente em atendimento!' });
      }
    );
  };

  iniciarAtendimento = () => {
    let paciente = _.cloneDeep(this.state.pacienteAtual);
    let cpf = _.cloneDeep(this.state.cpf);
    this.setState({ pacienteAtual: null, cpf }, () => {
      if (this.props.onChange) {
        this.props.onChange(paciente, cpf);
      }
    });
  };

  render() {
    let { filas } = this.state;
    let { fila } = this.props;
    _.map(filas, (f) => {
      f.fila = _.filter(fila, (paciente) => {
        return _.get(paciente, 'filaAtendimento.id', 0) == f.id;
      });
    });

    return (
      <React.Fragment>
        <div>
          {' '}
          <div></div>
          <div className="conteiner-triagem">
            {this.state.pacienteAtual && (
              <div className="text-center d-flex flex-column align-items-center">
                <h1>Paciente Atual</h1>
                <p>{this.state.pacienteAtual.senha}</p>
                <p>{this.state.pacienteAtual.horaChegada}</p>
                <div className={'col-6'}>
                  <InputViewEdit
                    label="CPF do Paciente"
                    viewMode={false}
                    component={CpfCnpjInput}
                    type="text"
                    id={`cpf`}
                    name={`cpf`}
                    placeholder="Informe o CPF do Paciente"
                    value={_.get(this.state, `cpf`)}
                    onChange={(name, cpf) => {
                      this.setState({ cpf });
                    }}
                  />
                </div>
                <div className={'col-12'}>
                  <button
                    className={'btn btn-danger float-left mx-3'}
                    onClick={this.inativar}
                  >
                    Inativar Senha
                  </button>
                  <button
                    className={'btn btn-primary mx-3'}
                    onClick={() => {
                      this.chamarNovamente(this.state.pacienteAtual);
                    }}
                  >
                    Chamar novamente
                  </button>
                  <button
                    className={'btn btn-secondary mx-3'}
                    onClick={this.cancelar}
                  >
                    Cancelar
                  </button>
                  <button
                    className={'btn btn-info'}
                    onClick={this.iniciarAtendimento}
                    disabled={!this.state.cpf || this.state.cpf.length != 14}
                  >
                    Iniciar Atendimento
                  </button>
                </div>
              </div>
            )}
            {console.log(filas)}
            {console.log(fila)}
            <div className="row align-items-stretch filas">
              {_.orderBy(filas, (f) => {
                return _.get(f, 'fila[0].horarioChegada');
              }).map((fila, i) => {
                return (
                  <div className={'col-6 col-lg-3 d-flex'} key={i}>
                    <div className={'my-2 mr-2 w-100 card card-fila'}>
                      <div className="clearfix mb-2 d-flex">
                        <h2 className={'d-inline mx-3'}>{fila.nome}</h2>
                      </div>

                      {fila.fila && fila.fila.length > 0 && (
                        <React.Fragment>
                          <div>
                            <p>
                              <b>Senha:</b> {fila.fila[0].senha}
                            </p>
                            <p>
                              <b>Tempo de espera: </b>
                              {moment
                                .duration(
                                  moment().diff(
                                    moment(fila.fila[0].horarioChegada, [
                                      'h:m a',
                                      'H:m',
                                    ])
                                  )
                                )
                                .hours()}{' '}
                              horas e{' '}
                              {moment
                                .duration(
                                  moment().diff(
                                    moment(fila.fila[0].horarioChegada, [
                                      'h:m a',
                                      'H:m',
                                    ])
                                  )
                                )
                                .minutes()}{' '}
                              minutos
                            </p>
                          </div>
                          <div className="clearfix  text-left">
                            <button
                              className={'btn btn-danger float-left '}
                              onClick={() => {
                                this.inativar(fila.fila[0]);
                              }}
                            >
                              Inativar Senha
                            </button>

                            <button
                              className={'btn btn-primary float-left '}
                              onClick={() => {
                                this.chamar(fila.fila[0]);
                              }}
                            >
                              Chamar
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                      {!fila.fila ||
                        (fila.fila.length == 0 && (
                          <React.Fragment>
                            <div className={'mb-3'}>
                              Nenhum Paciente aguardando
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Senha</th>
                  <th scope="col">Hora da chegada</th>
                  <th scope="col">Fila</th>
                  <th scope="col">Tempo de Espera</th>
                  <th scope="col">Atendimento prioritario</th>
                </tr>
              </thead>
              <tbody>
                {fila &&
                  fila.map((f, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{f.senha}</th>
                        <td>{f.horarioChegada}</td>
                        <td>{f.filaAtendimento && f.filaAtendimento.nome}</td>
                        <td>
                          {moment
                            .duration(
                              moment().diff(
                                moment(f.horarioChegada, ['h:m a', 'H:m'])
                              )
                            )
                            .hours()}{' '}
                          horas e{' '}
                          {moment
                            .duration(
                              moment().diff(
                                moment(f.horarioChegada, ['h:m a', 'H:m'])
                              )
                            )
                            .minutes()}{' '}
                          minutos
                        </td>
                        <td>
                          {f.filaAtendimento && f.filaAtendimento.prioritario}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
