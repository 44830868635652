//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../components/templates/ListagemPage';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { tableHelper } from '../../../_helpers/tableHelper';
import { estoquePosicaoService } from '../../../_services/estoquePosicao.service';
import EstoqueRelatorioPosicaoListFilter from './EstoqueRelatorioPosicaoListFilter';

const columns = [
  {
    path: 'ultimaMovimentacao',
    pathFilter: 'ultimaMovimentacao',
    title: 'Última movimentação',
    isKey: false,
    isDateTime: true,
    dataSort: true,
    className: 'nomeProprio column-bold',
  },

  {
    path: 'produto.nome',
    pathFilter: 'produto.nome',
    title: 'Produto',
    isKey: true,
    dataSort: true,
    className: 'nomeProprio column-bold',
  },
  {
    path: 'produto.grupo.nome',
    pathFilter: 'produto.grupo.nome',
    title: 'Grupo',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'estoqueAtualUnidade',
    pathFilter: 'estoqueAtualUnidade',
    title: 'Estoque atual',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'estoqueMinimoUnidade',
    pathFilter: 'estoqueMinimoUnidade',
    title: 'Estoque mínimo',
    isKey: false,
    dataSort: false,
  },
];

class EstoqueRelatorioPosicaoListagemPage extends React.PureComponent<
  {},
  State
> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  // handleRowClick = (row: e) => {
  //   console.log(row.id);
  //   this.props.history.push(endPointView + row.id);
  // };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_ESTOQUE_REL_POSICAO}
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_ESTOQUE_EDITAR}
          // buttonActionClassName={'btn-light'}
          // buttonAction={() => {
          //   this.handleNewButton();
          // }}
          // securityActionIcon={'icon-btn-add'}
          // securityActionName={'Novo produto'}
        ></TopoListagem>
        <ListagemPage
          title={'Estoque: Relatório de posição de estoque'}
          showNewButton={false}
          delete={() => {}}
          service={estoquePosicaoService}
          defaultOrder={'dataHora'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          // onRowClick={this.handleRowClick}
          FormFilter={EstoqueRelatorioPosicaoListFilter}
          controller="api/usuario"
          fields={this.state.fields}
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(EstoqueRelatorioPosicaoListagemPage);
