
//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ModelosReceituarioEdit from './ModelosReceituarioEdit';
import ModelosReceituarioListagemPage from './ModelosReceituarioListagemPage';
import ModelosReceituarioView from './ModelosReceituarioView';

export default class ModelosReceituarioIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/modelo-receituario"
          component={ModelosReceituarioListagemPage}
          exact
        />
        <Route path="/modelo-receituario/novo" component={ModelosReceituarioEdit} />
        <Route
          path="/modelo-receituario/view/:id"
          component={ModelosReceituarioView}
        />
      </Switch>
    );
  }
}
