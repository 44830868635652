import * as React from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { Md5 } from 'ts-md5';

/**
 * Props
 *
 */
type Props = {
  dataAnalyticsConvenios: DataAnalyticsConvenios[],
};

/**
 * State
 *
 */
type State = {
  dataAnalyticsConveniosFinal: any[],
  keysConvenios: string[],
  hashProps: string,
  hasData: boolean,
};

/**
 * Data chart DataAnalyticsConvenios
 *
 */
interface DataAnalyticsConvenios {
  //   period: string;
  convenios: Convenios[];
}

/**
 * Convenios
 *
 */
interface Convenios {
  name: string;
  value: number;
}

/**
 * Chart Dashboard Component
 *
 */
class ChartPacientesAnalyticsDashboardComponent extends React.Component<
  Props,
  State
> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Responsible change componente.
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    let newHashProps: string = this.generateHasProps(
      this.props.dataAnalyticsConvenios
    );
    if (newHashProps !== prevState.hashProps) {
      this.setState(this.getInitialState());
    }
  }

  /**
   * Responsible get initial state
   *
   * @return {}
   */
  getInitialState(): any {
    const [dataAnalyticsConveniosFinal, keys] =
      this.getDataAnalyticsConveniosFinal(this.props.dataAnalyticsConvenios);
    return {
      dataAnalyticsConveniosFinal: dataAnalyticsConveniosFinal
        .slice()
        .reverse(),
      keysConvenios: keys,
      hashProps: this.generateHasProps(this.props.dataAnalyticsConvenios),
      hasData: this.hasData(),
    };
  }

  /**
   * Responsible generate hash.
   *
   * @param dataAnalyticsConvenios
   * @return {string}
   */
  generateHasProps = (
    dataAnalyticsConvenios: DataAnalyticsConvenios[]
  ): string => {
    return Md5.hashStr(JSON.stringify(dataAnalyticsConvenios));
  };

  /**
   *
   * Responsible validate if has data.
   *
   * @return {boolean}
   */
  hasData = (): boolean => {
    return this.props.dataAnalyticsConvenios;
  };

  /**
   * Get data convenios final
   *
   * @param dataAnalyticsConvenios - dataAnalyticsConvenios
   * @return [any[], string[]]
   */
  getDataAnalyticsConveniosFinal(
    dataAnalyticsConvenios: DataAnalyticsConvenios[]
  ): [any[], string[]] {
    if (dataAnalyticsConvenios) {
      console.log(dataAnalyticsConvenios);
      let keysConvenios = [];
      let dataAnalyticsConveniosFinal: any[] = [];
      _.forEach(
        dataAnalyticsConvenios,
        function (dataAnalyticsConvenio: DataAnalyticsConvenios) {
          console.log(dataAnalyticsConvenio);
          let dataAnalyticsConveniosFinalDetails = {};
          _.forEach(dataAnalyticsConvenio, function (convenio: Convenios) {
            dataAnalyticsConveniosFinalDetails[convenio.name] = convenio.value;
            keysConvenios.push(convenio.name);
          });
          dataAnalyticsConveniosFinal.push(dataAnalyticsConveniosFinalDetails);
        }
      );
      return [dataAnalyticsConveniosFinal, keysConvenios];
    } else {
      return [null, new Set()];
    }
  }

  /**
   * Responsible get legend label
   *
  //  * @returns {string}
  //  */
  // getLegendLabel(): string {
  //   if (
  //     this.props.dataAnalyticsConvenios &&
  //     this.props.dataAnalyticsConvenios[0] &&
  //     this.props.dataAnalyticsConvenios[0].period
  //   ) {
  //     if (this.props.dataAnalyticsConvenios[0].period.length === 10) {
  //       return 'Dias';
  //     } else if (this.props.dataAnalyticsConvenios[0].period.length === 7) {
  //       return 'Meses';
  //     } else {
  //       return 'Anos';
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  /**
   * Responsible format date.
   *
   * @param datum
   * @return {string}
   */
  formatDateIndex = (datum: RawDatum): string => {
    if (datum && datum.period) {
      let splitDate: Array<string> = datum.period.split('-');
      if (splitDate.length > 1) {
        let finalDate: string = '';
        splitDate
          .slice()
          .reverse()
          .forEach((value, index, array) => {
            finalDate += value + (index + 1 < array.length ? '/' : '');
          });
        return finalDate;
      }
    }
    return '';
  };

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Card className="bg-light h-100">
        <Card.Body className="chart-card-body">
          <Card.Title className="chart-card-title d-flex justify-content-center">
            Convenios
          </Card.Title>
          {!this.state.hasData && (
            <React.Fragment>
              <div className="chart-text-blank justify-content-center">
                Não existem dados a serem exibidos para essa pesquisa.
              </div>
            </React.Fragment>
          )}
          {this.state.hasData && (
            <React.Fragment>
              <div className="chart-card-full">
                <ResponsiveBar
                  keys={_.uniq(this.state.keysConvenios)}
                  margin={{ top: 60, right: 550, bottom: 70, left: 80 }}
                  indexBy={this.formatDateIndex}
                  padding="0.2"
                  labelTextColor="inherit:darker(1.4)"
                  labelSkipHeight="16"
                  labelSkipWidth="16"
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      toggleSerie: true,
                      justify: false,
                      translateX: 120,
                      translateY:
                        _.uniq(this.state.keysConvenios).length > 15 ? 50 : 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -63,
                    // legend: this.getLegendLabel(),
                    legendPosition: 'end',
                    legendOffset: 50,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Quantidade',
                    legendPosition: 'end',
                    legendOffset: -60,
                  }}
                  data={this.state.dataAnalyticsConveniosFinal}
                  height={500}
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={{ scheme: 'red_blue' }}
                  borderWidth="1px"
                  borderColor="#c7c7c7"
                />
              </div>
            </React.Fragment>
          )}
        </Card.Body>
      </Card>
    );
  }
}

/**
 * Export default connect
 *
 */
export default connect(
  null,
  null,
  null
)(ChartPacientesAnalyticsDashboardComponent);
