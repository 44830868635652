//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const marcacaoService = {
  findAll,
  doGet,
  doSave,
  exibeRetornoConsulta,
  primeiraConsulta,
  limiteEncaixe,
  findByToken,
  cancelarMarcacao,
  sendEmailAgendamento,
  exibeConsulta,
  consultaHoje,
  findHorarioConsultaByIdMarcacao,
  findByFiltro,
  imprimirGuiaConsulta,
  imprimirGuiaSADT,
  findAnexosByMarcacao,
  findHistoricoMarcacaoByPaciente,
  findHistoricoMarcacaoExameByPaciente,
  findDataHoraDisponivelRecorrente,
};

function sendEmailAgendamento(idMarcacao) {
  return defaultService.doPut(
    `${urlsConstants.MARCACAO}enviarAcessoTelemedicina/${idMarcacao}`
  );
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.MARCACAO}findAll`);
}

function doSave(marcacao: medico) {
  return defaultService.doSave(urlsConstants.MARCACAO, marcacao);
}

function findByToken(token) {
  return defaultService.doGet(
    `${urlsConstants.MARCACAO_PUBLIC}findByToken/${token}`
  );
}

function cancelarMarcacao(tokenMarcacao) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO_PUBLIC}cancelarMarcacao/${tokenMarcacao}`
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.MARCACAO}${id}`);
}

function exibeRetornoConsulta(
  idEstabelecimentoMedico,
  idPaciente,
  dataConsulta,
  horaConsulta,
  especialidade
) {
  if (!especialidade) {
    especialidade = 0;
  }
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}exibeRetornoConsulta/${idEstabelecimentoMedico}/${idPaciente}/${horaConsulta}/${especialidade}`,
    dataConsulta
  );
}

function exibeConsulta(
  idEstabelecimentoMedico,
  idPaciente,
  dataConsulta,
  horaConsulta
) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}consulta/${idEstabelecimentoMedico}/${idPaciente}/${horaConsulta}`,
    dataConsulta
  );
}

function primeiraConsulta(
  idEstabelecimentoMedico,
  idPaciente,
  dataConsulta,
  horaConsulta
) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}primeiraConsulta/${idEstabelecimentoMedico}/${idPaciente}/${horaConsulta}`,
    dataConsulta
  );
}

function consultaHoje(idEstabelecimentoMedico, idPaciente, dataConsulta) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}consultaHoje/${idEstabelecimentoMedico}/${idPaciente}`,
    dataConsulta
  );
}

function limiteEncaixe(idEstabelecimentoMedico, dataConsulta) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}limiteEncaixe/${idEstabelecimentoMedico}`,
    dataConsulta
  );
}

function findHorarioConsultaByIdMarcacao(idMarcacao) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}horarioConsulta/${idMarcacao}`
  );
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.MARCACAO}list`,
    options,
    filter
  );
}
function imprimirGuiaConsulta(idMarcacao) {
  return defaultService.doGet(
    `${urlsConstants.AGENDA}imprimirConsulta/${idMarcacao}`
  );
}

function imprimirGuiaSADT(idMarcacao) {
  return defaultService.doGet(
    `${urlsConstants.AGENDA}imprimirSADT/${idMarcacao}`
  );
}

function findAnexosByMarcacao(id) {
  return defaultService.doGet(
    `${urlsConstants.MARCACAO}findAnexosByMarcacao/${id}`
  );
}

function findHistoricoMarcacaoByPaciente(filtro) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}findHistoricoByPaciente`,
    filtro
  );
}

function findHistoricoMarcacaoExameByPaciente(filtro) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}findHistoricoMarcacaoExame`,
    filtro
  );
}

function findDataHoraDisponivelRecorrente(
  listaDias,
  hora,
  idEstabelecimentoAtendimento
) {
  return defaultService.doPost(
    `${urlsConstants.MARCACAO}findDataHoraDisponivelRecorrente/${idEstabelecimentoAtendimento}/${hora}`,
    listaDias
  );
}
