import _ from 'lodash';
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconPainel from "../../images/icon-tv-WHITE.svg";
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import { userService } from '../../_services/user.service';
import FormSelectInput from "../inputs/FormSelectInput";
import InputViewEdit from "../inputs/InputViewEdit";


const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalLocalRecepcao extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      recepcao: null
    };
  }
  toogleModal = (estabelecimento, value, status) => {
    this.setState({ showModal: !this.state.showModal, value, status, estabelecimento });
  };

  fecharModal = () => {
    this.toogleModal(null, null);
  };

  handleSelectChange = (name: string, option: any) => {
    const { recepcao } = this.state;
    let newEntity = _.cloneDeep(recepcao);
    newEntity = option;
    this.setState({ recepcao: newEntity });
  };


  getValue = () => {
    const { estabelecimento } = this.state;
    if (this.state.recepcao) {
      return this.state.recepcao;
    } else {
      this.props.getLocalRecepcao(estabelecimento)
    }
  }


  render() {

    const { recepcao, value, status, estabelecimento } = this.state;
    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toogleModal}
          backdrop="static"
          modalClassName=""
          className="modal-anexos"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="">
              <div className="row">
                <div className="col-12 text-center iconLocal">
                  <img src={IconPainel} alt="Icone painel de chamada" />
                </div>
                <div className="col-12 text-center mb-2">
                  {/* <h2 className="font-weight-bold"> {`Selecionar Local de atendimento - ${estabelecimento.nome}`}</h2> */}
                  <h2 className="font-weight-bold">Em qual local de trabalho você está?</h2>
                  <p>Selecione abaixo o local de trabalho que você está atuando agora.
                    Este local será exibido para o paciente no painel de chamada.
                  </p>
                </div>
              </div>

              <React.Fragment>
                <div className="form-row">
                  <div className="col-12">
                    <InputViewEdit
                      component={FormSelectInput}
                      type="text"
                      name="recepcao"
                      label=""
                      placeholder="Selecione o recepção ou sala"
                      onChange={this.handleSelectChange}
                      noSize={true}
                      viewMode={false}
                      id="recepcao"
                      returnFullObject={true}
                      service={estabelecimentoService.findLocalAtendimentoRecepcao}
                      labelKey={"nome"}
                      valueKey={"id"}
                      value={this.getValue()}
                      required={true}
                      parent={userService.getExtension()}
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-50 text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.fecharModal();
                  }}
                >
                  {" "}
                  Cancelar{" "}
                </button>
              </div>
              <div className="w-50 text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (recepcao) {
                      _this.fecharModal();
                      _this.props.callbackOnSave(
                        estabelecimento,
                        recepcao,
                        value,
                        status
                      );
                    } else {
                      _this.props.error({
                        message: 'Selecione um local de atendimento.',
                      });
                    }
                  }}
                >
                  {" "}
                  Salvar{" "}
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
