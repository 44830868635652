//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import { objectsConstants } from '../../../_constants/objects.constants';
import { dateHelper } from '../../../_helpers/date.helper';
import FormInput from './../../../components/inputs/FormInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class IntegracaoSascListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-md-3">
          <InputViewEdit
            component={DatePickerInput}
            label="Data da integração"
            name={`dataIntegracao`}
            id={`dataIntegracao`}
            value={entity.dataIntegracao}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataIntegracao`)
                ? dateHelper.format(_.get(entity, `dataIntegracao`))
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-3">
          <FormInput
            label="Descrição"
            placeholder={'Descrição'}
            id={'descricao'}
            name="descricao"
            onChange={this.props.onChangeDate}
            type={'text'}
            value={entity.descricao}
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-3">
          <InputViewEdit
            component={DatePickerInput}
            label="Data da última tentativa"
            name={`dataUltimaTentativa`}
            id={`dataUltimaTentativa`}
            value={entity.dataUltimaTentativa}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataUltimaTentativa`)
                ? dateHelper.format(_.get(entity, `dataUltimaTentativa`))
                : '--'
            }
          />
        </FormGroup>

        <FormGroup className={'col-12 col-md-3'}>
          <InputViewEdit
            component={SelectInput}
            options={objectsConstants.STATUS_INTEGRACAO}
            label="Status"
            id="statusIntegracao"
            name="statusIntegracao"
            viewMode={this.props.viewMode}
            valueKey={'name'}
            labelKey={'description'}
            returnFullObject={false}
            value={_.get(entity, 'statusIntegracao')}
            placeholder="Status da integração"
            onChange={this.props.onChangeDate}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
