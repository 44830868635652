import Axios from 'axios';

const ApiIBGE = {
  findAllEstados() {
    return Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`, {
      timeout: 5000
    });
  }
};

export default ApiIBGE;
