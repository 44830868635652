//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { CalendarDate } from '../../components/calendar/CalendarDate';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import DadosUser from '../../components/DadosUser/DadosUser';
import moment from 'moment';
import { CalendarDateView } from '../../components/calendar/CalendarDateView';
import { dateHelper } from '../../_helpers/date.helper';
import { midiaService } from '../../_services/midia.service';
import ReactHtmlParser from 'react-html-parser';

class AgendaOnlineSucess extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  getTipoAtendimento = () => {
    const { paciente } = this.props;
    if (
      paciente.tipoAtendimento === objectsConstants.TIPO_ATENDIMENTO_CONVENIO
    ) {
      let convenio = _.get(paciente, 'pacienteConvenios[0].convenio');
      return convenio.nome;
    } else if (
      paciente.tipoAtendimento === objectsConstants.TIPO_ATENDIMENTO_PARTICULAR
    ) {
      return 'Particular';
    } else if (
      paciente.tipoAtendimento === objectsConstants.TIPO_ATENDIMENTO_CORTESIA
    ) {
      return 'Cortesia';
    }
  };

  render() {
    const { state } = this.props.location;
    const { estabelecimento } = this.props;
    const { agendamento } = state;
    const marcacaoDetails = _.get(agendamento, 'listMarcacaoHora[0]');
    let dataConsulta = dateHelper.getDateFormat(
      _.get(agendamento, 'dataConsulta')
    );


    return (
      <div className="d-flex flex-column justify-content-center">
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_AGENDA_ONLINE_SUCESSO}
          subTitle=""
          canBack={true}
          backUrl={'/agendamento/agendamentos'}
        />
        <div className="agenda-online-filter">
          <div className="text-center">
            {_.get(this.props.estabelecimento, 'foto.id') && (
              <img
                className="logoOnline"
                src={midiaService.url(
                  _.get(this.props.estabelecimento, 'foto.id')
                )}
              ></img>
            )}
            {!_.get(this.props.estabelecimento, 'foto.id') && (
              <div className="logo-agenda"></div>
            )}
          </div>
          <div className="tag-success">
            Seu agendamento foi <b>concluído com sucesso!</b>
          </div>
          <hr></hr>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <CalendarDateView data={dataConsulta}></CalendarDateView>
            <div className="pl-3">
              <span>
                <b>{marcacaoDetails.hora}</b>{' '}
                {` / ${this.getTipoAtendimento()}`}{' '}
                {` (${_.get(this.props.paciente, 'especialidade.nome')})`}
              </span>
              <DadosUser idUser={agendamento.fkMedico}

              ></DadosUser>
            </div>
          </div>
          <hr></hr>
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            <span>
              <b>{_.get(estabelecimento, 'nome')}</b>
            </span>
            <p>{_.get(estabelecimento, 'enderecoCompleto')}</p>
            <p>
              Telefone: <b>{_.get(estabelecimento, 'telefonesString')}</b>
            </p>
          </div>
        </div>

        {agendamento.recomendacoes && (
          <div className="d-flex w-100 justify-content-center flex-column agenda-online-info">
            <div className="recomendacoes">
              <h2 className="mb-0">Recomendações:</h2>
              <React.Fragment>
                {ReactHtmlParser(agendamento.recomendacoes)}
              </React.Fragment>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { estabelecimento, filtro, paciente } = state.agendaOnline;
  const { agendas } = state.agenda;
  const { user } = state.authentication;

  return {
    estabelecimento,
    filtro,
    agendas,
    user,
    paciente,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading },
  agendaOnline: { setEstabelecimento, setFiltroAgenda },
  agenda: { changeFilter, receiveAgenda },
}) => ({
  error: (message, code) => error({ message, code }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  setEstabelecimento: (estabelecimento: any) =>
    setEstabelecimento({ estabelecimento }),
  setFiltroAgenda: (filtro: any) => setFiltroAgenda({ filtro }),
  receiveAgenda: (agendas) => receiveAgenda({ agendas }),
  changeFilter: (filter) => changeFilter({ filter: filter }),
});
const connectedAgendaOnlineSucess = connect(
  mapStateToProps,
  mapDispatch
)(AgendaOnlineSucess);
export { connectedAgendaOnlineSucess as AgendaOnlineSucess };
