//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstoqueRelatorioPosicaoListagemPage from './EstoqueRelatorioPosicaoListagemPage';

export default class EstoqueRelatorioPosicaoIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/estoque-relatorio-posicao"
          component={EstoqueRelatorioPosicaoListagemPage}
        />
      </Switch>
    );
  }
}
