//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { translate } from '../../_helpers/messages.helper';
import { convenioService } from '../../_services/convenio.service';
import { defaultService } from '../../_services/defaultService';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { perfilservice } from '../../_services/perfil.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};
type State = {
  id?: number,
};
const OBRIGATORIO = 'Campo obrigatório';
const STRING_REQUIRED = Yup.string().required(OBRIGATORIO);
const ConvenioValidate = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Nome muito curto!')
    .max(100, 'Nome muito grande!')
    .required(OBRIGATORIO)
,
  registroAns: STRING_REQUIRED,
  cnpj: STRING_REQUIRED,
});

class ConvenioForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: 'view/' + values.id,
      state: { entity: values },
    });
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          convenioService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Convênio, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar convênio.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };


  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };
  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={ConvenioValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      component={FormInput}
                      label={'Convênio'}
                      placeholder="Digite o nome do Convênio"
                      type="text"
                      id="nome"
                      name="nome"
                      value={values.nome}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.nome}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'Número ANS'}
                      type="text"
                      id="numeroANS"
                      name="registroAns"
                      placeholder="Digite o número ANS"
                      value={values.registroAns}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode}
                      required={true}
                      erroMensagem={errors.registroAns}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={CpfCnpjInput}
                      label={'CNPJ'}
                      type="text"
                      id="cnpj"
                      name="cnpj"
                      placeholder="Digite o CNPJ"
                      value={values.cnpj}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.cnpj}
                      viewMode={viewMode}
                      required={true}
                      viewClassName={'font-weight-bold'}
                    />
                  </FormGroup>
                  
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ConvenioForm);
