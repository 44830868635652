//@flow
//@flow
import { Formik } from 'formik';
import _, { replace } from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import FormInput from '../../components/inputs/FormInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { defaultService } from '../../_services/defaultService';
import IconDelete from '../../images/icon_x_red.svg';
import SelectInput from '../../components/inputs/SelectInput';
import { objectsConstants } from '../../_constants/objects.constants';
import { pacoteService } from '../../_services/pacote.service';
import { procedimentoService } from '../../_services/procedimento.service';
import { convenioService } from '../../_services/convenio.service';
import { userService } from '../../_services/user.service';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import MoneyInput from '../../components/inputs/MoneyInput';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { toNumber } from 'lodash';
import { translate } from '../../_helpers/messages.helper';
import OnlyNumberInput from '../../components/inputs/OnlyNumberInput';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const PacoteValidate = Yup.object().shape({
  codigo: Yup.string().required('Obrigatório'),
  descricao: Yup.string().required('Obrigatório'),

  procedimentos: Yup.array()
    .min(1, 'É necessario informar pelo menos um procedimento')
    .of(
      Yup.object().shape({
        codigo: Yup.string().required('Código é obrigatório'),
        descricao: Yup.string().required('Obrigatório'),
        valor: Yup.string().required('Obrigatório'),
      })
    ),

  materiais: Yup.array().of(
    Yup.object().shape({
      item: Yup.string().required('Código é obrigatório'),
      unidade: Yup.string().required('Obrigatório'),
      quantidade: Yup.string().required('Obrigatório'),
      valorUnitario: Yup.string().required('Obrigatório'),
    })
  ),
});

class PacoteForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  calculaValorTotalPacote = (pacote) => {
    let valorTotal = 0.0;
    if (pacote.procedimentos) {
      pacote.procedimentos.map((procedimento, index) => {
        valorTotal =
          valorTotal +
          (procedimento.valor ? toNumber(procedimento.valor) : 0.0);
      });
    }
    if (pacote.materiais) {
      pacote.materiais.map((material, index) => {
        valorTotal =
          valorTotal +
          (material.quantidade && material.valorUnitario
            ? material.quantidade * toNumber(material.valorUnitario)
            : 0.0);
      });
    }

    return formatterHelper.money(valorTotal);
  };
  addProcedimentos = (setFieldValue, procedimentos) => {
    let newArray = _.cloneDeep(procedimentos);
    if (!newArray) {
      newArray = [];
    }
    newArray.push({});
    setFieldValue('procedimentos', newArray);
  };

  addMateriais = (setFieldValue, materiais) => {
    let newArray = _.cloneDeep(materiais);
    if (!newArray) {
      newArray = [];
    }
    newArray.push({});
    setFieldValue('materiais', newArray);
  };

  removeItem = (setFieldValue, lista, index, nome) => {
    lista.splice(index, 1);
    setFieldValue(nome, lista);
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };
  y;

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/pacotes/view/' + values.id,
      state: { entity: values },
    });
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    console.log(values);
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          pacoteService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Pacote foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar pacotes.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        }
      });
    });
    return promise;
  };

  render() {
    let _this = this;
    const { viewMode } = this.props;
    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          validationSchema={PacoteValidate}
          initialValues={this.props.entity}
          validateOnBlur={false}
          validateOnChange={false}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <div className="col-12  form-row pl-lg-3">
                    <FormGroup className="col-12 col-lg-2">
                      <InputViewEdit
                        component={FormInput}
                        label={'Codigo do pacote'}
                        type="text"
                        id="codigo"
                        name="codigo"
                        placeholder=""
                        value={values.codigo}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.codigo}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-4">
                      <InputViewEdit
                        component={FormSelectInput}
                        label="Convênio"
                        placeholder="Selecione um convênio"
                        name="convenios"
                        id={'convenio'}
                        // icone={Convenios}
                        classIcone={'off'}
                        noSize={true}
                        required={false}
                        service={convenioService.findAll}
                        searchable={true}
                        multi={true}
                        isMulti={true}
                        returnFullObject={true}
                        valueKey={'id'}
                        labelKey={'sigla'}
                        defaultSelected={false}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        defaultValue={_.get(values, 'convenios', []).map(
                          (c, i) => {
                            if (i > 0) {
                              return ',' + c.nome;
                            } else {
                              return c.nome;
                            }
                          }
                        )}
                        value={values.convenios}
                        clearable={true}
                        viewMode={viewMode}
                        parent={
                          _.get(values, 'estabelecimento.id')
                            ? _.get(values, 'estabelecimento.id')
                            : userService.getExtension()
                        }
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label={'Descrição'}
                        type="text"
                        id="descricao"
                        name="descricao"
                        placeholder="Digite a descrição do pacote"
                        value={values.descricao}
                        onChange={(descricao, value) =>
                          setFieldValue(descricao, value)
                        }
                        noSize={true}
                        erroMensagem={errors.descricao}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup
                      id="valorPacote"
                      className=" aling-items-center- justify-content-center col-12 col-lg-2"
                    >
                      <p className="label-valor-total">
                        <b>Valor total pacote:</b>
                      </p>
                      <p className="valor-total">
                        {this.calculaValorTotalPacote(values)}
                      </p>
                    </FormGroup>
                  </div>
                </div>
              </div>

              <h2 className="w-100">Procedimentos</h2>
              <div className="form-inside">
                {' '}
                {console.log(errors)}
                {/* <div className="row section-form"> */}
                {values.procedimentos &&
                  values.procedimentos.map((procedimento, index) => {
                    return (
                      <div
                        className="row section-form align-items-center justify-content-center"
                        key={index}
                      >
                        <FormGroup className="col-12 col-lg-2">
                          <InputViewEdit
                            component={SelectInput}
                            entity={values}
                            index={index}
                            label={'Codigo do procedimento:'}
                            type="text"
                            id="codProcedimento"
                            name={`procedimentos[${index}]`}
                            value={procedimento}
                            returnFullObject={true}
                            placeholder="Digite o codigo do procedimento"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            defaultValue={_.get(procedimento, 'codigo', ' ')}
                            noSize={true}
                            onFetchData={procedimentoService.findAll}
                            valueKey={'codigo'}
                            labelKey={'codigo'}
                            erroMensagem={
                              errors &&
                              errors.procedimentos &&
                              errors.procedimentos[index] &&
                              errors.procedimentos[index].codigo
                            }
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            errors={errors}
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-lg-7">
                          <InputViewEdit
                            component={FormInput}
                            entity={values}
                            index={index}
                            label={'Descrição do procedimento:'}
                            type="text"
                            id="descricaoProcedimento"
                            name={`procedimentos[${index}].descricao`}
                            value={procedimento.descricao}
                            placeholder="Digite a descrição do procedimento"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            erroMensagem={
                              errors &&
                              errors.procedimentos &&
                              errors.procedimentos[index] &&
                              errors.procedimentos[index].descricao
                            }
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            errors={errors}
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-lg-2">
                          <InputViewEdit
                            component={MoneyInput}
                            entity={values}
                            index={index}
                            label={'Valor do procedimento:'}
                            type="number"
                            id="valorProcedimento"
                            name={`procedimentos[${index}].valor`}
                            value={procedimento.valor}
                            placeholder="R$"
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                            }}
                            noSize={true}
                            erroMensagem={
                              errors &&
                              errors.procedimentos &&
                              errors.procedimentos[index] &&
                              errors.procedimentos[index].valor
                            }
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            defaultValue={formatterHelper.money(
                              _.get(procedimento, 'valor')
                            )}
                            errors={errors}
                          />
                        </FormGroup>
                        {!viewMode && (
                          <div
                            className={`icon-delete d-flex col-lg-1 col-12 `}
                            onClick={() => {
                              this.removeItem(
                                setFieldValue,
                                values.procedimentos,
                                index,
                                'procedimentos'
                              );
                            }}
                          >
                            <img src={IconDelete} alt="img" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    onClick={() =>
                      this.addProcedimentos(setFieldValue, values.procedimentos)
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Adicionar procedimento
                  </button>
                </div>
              </div>

              <h2 className="w-100">Materiais</h2>
              <div className="form-inside">
                {/* <div className="row section-form"> */}
                {values.materiais &&
                  values.materiais.map((material, index) => {
                    return (
                      <div
                        className="row align-items-center justify-content-center section-form"
                        key={index}
                      >
                        <FormGroup className="col-12 col-lg-2">
                          <InputViewEdit
                            component={FormInput}
                            entity={values}
                            index={index}
                            label={'Item:'}
                            type="text"
                            id={`materiais${index}item`}
                            name={`materiais[${index}].item`}
                            value={material.item}
                            placeholder="Digite aqui o nome to item"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            erroMensagem={
                              errors &&
                              errors.materiais &&
                              errors.materiais[index] &&
                              errors.materiais[index].item
                            }
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            errors={errors}
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-lg-2">
                          <InputViewEdit
                            component={FormSelectInput}
                            id={`materiais${index}unidade`}
                            label="Selecione uma unidade de medida"
                            placeholder="Selecione uma unidade de medida"
                            name={`materiais[${index}].unidade`}
                            value={material.unidade}
                            options={objectsConstants.UNIDADES}
                            labelKey={'description'}
                            valueKey={'name'}
                            viewMode={viewMode}
                            erroMensagem={
                              errors &&
                              errors.materiais &&
                              errors.materiais[index] &&
                              errors.materiais[index].unidade
                            }
                            defaultValue={_.get(
                              material,
                              'unidade.description'
                            )}
                            required={true}
                            returnFullObject={true}
                            viewClassName={'font-weight-bold'}
                            errors={errors}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                          />
                        </FormGroup>

                        <FormGroup className="col-12 col-lg-3">
                          <InputViewEdit
                            component={OnlyNumberInput}
                            onlyPositive={true}
                            entity={values}
                            index={index}
                            label={'Quantidade:'}
                            type="text"
                            id={`materiais${index}quantidade`}
                            name={`materiais[${index}].quantidade`}
                            value={material.quantidade}
                            placeholder="Informe a quantidade utilizada"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            erroMensagem={
                              errors &&
                              errors.materiais &&
                              errors.materiais[index] &&
                              errors.materiais[index].quantidade
                            }
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            errors={errors}
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-lg-2">
                          <InputViewEdit
                            component={MoneyInput}
                            entity={values}
                            index={index}
                            label={'Valor do material:'}
                            type="number"
                            id={`materiais${index}valor`}
                            name={`materiais[${index}].valorUnitario`}
                            value={material.valorUnitario}
                            placeholder="R$"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            erroMensagem={
                              errors &&
                              errors.materiais &&
                              errors.materiais[index] &&
                              errors.materiais[index].valorUnitario
                            }
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            defaultValue={formatterHelper.money(
                              _.get(material, 'valorUnitario')
                            )}
                            errors={errors}
                          />
                        </FormGroup>
                        <FormGroup className=" aling-items-center- justify-content-center col-12 col-lg-2">
                          <p className="label-valor-total">
                            <b>Valor total:</b>
                          </p>
                          <p className="valor-total">
                            {material.quantidade && material.valorUnitario
                              ? formatterHelper.money(
                                  material.quantidade *
                                    toNumber(material.valorUnitario)
                                )
                              : formatterHelper.money(0.0)}
                          </p>
                        </FormGroup>
                        {!viewMode && (
                          <div
                            className={`icon-delete  d-flex  col-lg-1 col-12 `}
                            onClick={() => {
                              this.removeItem(
                                setFieldValue,
                                values.materiais,
                                index,
                                'materiais'
                              );
                            }}
                          >
                            <img src={IconDelete} alt="img" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                {/* </div> */}
                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    onClick={() =>
                      this.addMateriais(setFieldValue, values.materiais)
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Adicionar outro material
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(PacoteForm);
