//@flow
import { anamneseConstants } from "../_constants/anamnese.constant";
import { headerConstants } from "../_constants/header.constant";

export default {
  state: {
    type: null, handle: false, typeButton: null, handleExtraButton: null, showExtraButton: null, typeExtraButton: null
  },
  reducers: {
    changeToBack: (state, payload) => {
      return Object.assign({}, state, {
        type: headerConstants.CHANGE_ACTION, handle: payload.handle, typeButton: "BACK"

      });

    },
    changeToMenu: (state, payload) => {
      return Object.assign({}, state, {
        type: headerConstants.CHANGE_ACTION,
        handle: null,
        typeButton: "MENU"
      });

    },
    changeExtraButton: (state, payload) => {
      return Object.assign({}, state, {
        type: headerConstants.CHANGE_EXTRA_ACTION,
        handleExtraButton: payload.handleExtraButton,
        showExtraButton: payload.showExtraButton,
        typeExtraButton: payload.typeExtraButton
      });

    },

  }
};
