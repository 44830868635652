//@flow
import moment from 'moment';
import 'moment/locale/pt-br';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';
import DiaTarefas from './DiaTarefas';
import IconAgendaOff from '../../images/icon-agendaMedica-off.svg';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { taskService } from '../../_services/taskService';
import { marcacaoService } from '../../_services/marcacao.service';
import { dateHelper } from '../../_helpers/date.helper';
import DiaTarefasNaoFinalizadas from './DiaTarefasNaoFinalizadas';

type Props = {
  // id: number,
};

type State = {
  listTarefas: any,
};

class TarefasPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listTarefas: [],
    };
  }

  render() {
    const { taskListSize, taskList } = this.props;
    let taskListByDate = _.groupBy(taskList, function (tarefa) {
      return _.get(tarefa, 'dataLimite');
    });

    console.log('taskList');
    console.log(taskList);

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${taskListSize} tarefas`}
          subTitle=""
          canBack={true}
        />
        <div className="tarefas-page">
          {taskListSize === 0 && (
            <React.Fragment>
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                  <div className="row doctor cardInfo">
                    <div className="d-flex justify-content-center">
                      <img
                        src={IconAgendaOff}
                        alt="Imagem Link agendamento online"
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <p className="title">Nenhum tarefa pendente</p>
                      <p className="instruction"></p>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {Object.entries(taskListByDate).map(([dataTask, listTask]) => {
            if (dateHelper.isBeforeToday(moment(dataTask).toDate())) {
              return (
                <DiaTarefasNaoFinalizadas
                  id={`tarefa-${dataTask}`}
                  key={dataTask}
                  listaDoDia={listTask}
                  data={moment(dataTask).toDate()}
                  onSave={this.handleSave}
                />
              );
            } else {
              return (
                <DiaTarefas
                  id={`tarefa-${dataTask}`}
                  key={dataTask}
                  listaDoDia={listTask}
                  data={moment(dataTask).toDate()}
                  onSave={this.handleSave}
                />
              );
            }
          })}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  task: { addTask },
}) => ({
  addTask: (listTask) => addTask(listTask),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { agendas } = state.agenda;
  const { client } = state.webSocket;
  const { taskListSize, taskList } = state.task;

  return {
    user,
    agendas,
    client,
    taskList,
    taskListSize,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(TarefasPage));
