//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect }  from 'react-redux';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import { empresaService } from '../../_services/empresa.service';
import { userService } from '../../_services/user.service';
import EmpresaForm from './EmpresaForm';
import ResponsavelForm from './ResponsavelForm';

const defaultArrayItem = [{ idList: new Date().getTime() }];

class EmpresaView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadEmpresa();
  }

  loadEmpresa = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      empresaService.doGet(this.props.match.params.id).then(
        (response) => {
          let empresa = response.data.data;
          this.loadResponsavel(empresa);
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };

  loadResponsavel = (empresa) => {
    userService.findByEmpresaSudo(empresa.id).then(
      (response) => {
        let responsavel = response.data;
        if (!_.isEmpty(responsavel)) {
          _.set(empresa, 'responsavel', responsavel);
        } else {
          _.set(empresa, 'responsavel', {});
        }
        this.setState({ entity: _.cloneDeep(empresa) }, () => {
          this.props.loading(false);
        });
      },
      (error) => {
        console.log(error);
        this.setState({ entity: _.cloneDeep(empresa) }, () => {
          this.props.loading(false);
        });
      }
    );
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={entity.nome}
          canBack={true}
          backUrl={'/empresa'}
        />
        <div className="flex-column mt-4">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_CODE4}
            title="Empresa"
            Componente={EmpresaForm}
            viewMode={true}
            entity={entity}
            onSave={this.loadUser}
            cancelForm={this.loadUser}
            {...otherPops}
          ></FullCustomCard>
          {entity.id && (
            <FullCustomCard
              permissionSave={objectsConstants.MENU_CODE4}
              title="Responsável pela empresa"
              Componente={ResponsavelForm}
              viewMode={_.get(entity, 'responsavel') ? true : false}
              entity={entity}
              {...otherPops}
            ></FullCustomCard>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(EmpresaView));
