//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import Atendimento from './Atendimento';
import { medicoService } from '../../../_services/medico.service';
import { urlsConstants } from '../../../_constants/urls.constant';
import { objectsConstants } from '../../../_constants/objects.constants';
import { Client } from 'webstomp-client';
import _ from 'lodash';
import { dateHelper } from '../../../_helpers/date.helper';

type Props = {
  atendimentos: any,
  agenda: any,
  client: Client,
};

type State = {
  agenda: any,
};

class AtendimentosList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agenda: this.props.agenda,
    };
  }
  desmarcarConsulta = (idMarcacao) => {
    if (this.props.client.connected) {
      let status = objectsConstants.CANCELADO;
      this.props.client.send(
        urlsConstants.SEND_PACIENTE_STATUS,
        JSON.stringify({ id: idMarcacao, status })
      );
      let cloneAgenda = _.cloneDeep(this.state.agenda);
      _.remove(cloneAgenda, function (item) {
        return idMarcacao === item.idMarcacao;
      });
      this.setState({ agenda: cloneAgenda }, () => {});
    }
  };

  remarcar = (marcacao, medico) => {
    if (this.props.client && this.props.client.connected) {
      this.props.client.send(
        urlsConstants.TASK_STATUS_CHANGE,
        JSON.stringify({
          id: marcacao.id,
          status: { name: 'EM_ANDAMENTO' },
        })
      );

      let filtro = {};
      _.set(filtro, 'estabelecimentoMedico.medico', medico);
      this.props.changeFilter(filtro);
      this.props.history.push({
        pathname: '/reagendamento',
        state: { marcacao: marcacao },
      });
    }
  };

  // abreHistoricoConsulta;

  render() {
    const { entity, atendimentos, ...otherPops } = this.props;
    const { agenda } = this.state;
    return (
      <React.Fragment>
        {agenda &&
          agenda.length > 0 &&
          agenda.map((agenda, i) => {
            return (
              <Atendimento
                key={agenda.idMarcacao}
                paciente={entity}
                atendimento={agenda}
                remarcar={this.remarcar}
                classNameRow={i % 2 ? 'bkg-cinza' : 'bkg-branco'}
                desmarcarConsulta={this.desmarcarConsulta}
                abreHistoricoConsulta={(marcacao) => {
                  this.props.receiveIdEntityHistorico(
                    _.get(marcacao, 'idMarcacao'),
                    objectsConstants.TIPO_HISTORICO_MARCACAO
                  );
                  this.props.abreHistorico();
                }}
                {...otherPops}
              ></Atendimento>
            );
          })}
        {agenda &&
          agenda.length == 0 &&
          atendimentos &&
          atendimentos.length == 0 && (
            <p className="text-center p-3">Nenhum atendimento encontrado!</p>
          )}

        {atendimentos &&
          atendimentos.length > 0 &&
          atendimentos.map((atendimento, i) => {
            return (
              <Atendimento
                key={atendimento.idMarcacao}
                classNameRow={i % 2 ? 'bkg-cinza' : 'bkg-branco'}
                paciente={entity}
                atendimento={atendimento}
                remarcar={this.remarcar}
                abreHistoricoConsulta={(atendimento) => {
                  this.props.receiveIdEntityHistorico(
                    _.get(atendimento, 'idMarcacao'),
                    objectsConstants.TIPO_HISTORICO_MARCACAO
                  );
                  this.props.abreHistorico();
                }}
                {...otherPops}
              ></Atendimento>
            );
          })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { client } = state.webSocket;
  return {
    user,
    client,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  agenda: { changeFilter },
  historicomenu: {
    toggleHistorico,
    abreHistorico,
    fechaHistorico,
    receiveIdEntityHistorico,
  },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  changeFilter: (filter) => changeFilter({ filter: filter }),
  receiveIdEntityHistorico: (idEntityHistorico, tipoHistorico) =>
    receiveIdEntityHistorico({ idEntityHistorico, tipoHistorico }),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(AtendimentosList);
