//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { Button, Form, Input, Label } from 'reactstrap';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { LoginForm } from '../login/LoginForm';
import { AgendaOnlineCadastro } from './AgendaOnlineCadastro';
import { userService } from '../../_services/user.service';
import { EsqueciSenhaForm } from '../login/EsqueciSenhaForm';
import { SenhaEnviadaForm } from '../login/SenhaEnviadaForm';
import { NovaSenhaForm } from '../login/NovaSenhaForm';
import { authenticationService } from '../../_services/authentication.service';
import logoEstabelecimento from '../../images/icon-agenda-online.svg';
import { midiaService } from '../../_services/midia.service';
import { translate } from '../../_helpers/messages.helper';
import Toast from '../../components/Toasts/Toast';
import { marcacaoService } from '../../_services/marcacao.service';

type Props = {
  email?: string,
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  location: any,
  // alert: any,
};

type State = {
  email?: string,
  senha?: string,
  esqueciSenha: boolean,
  senhaEnviada: boolean,
  idRecover: any,
};

class IndexAgendaOnlinePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCadastro: false,
      esqueciSenha: false,
      senhaEnviada: false,
      idRecover: this.props.match.params.id,
    };
  }

  componentDidMount() {
    let token = this.props.match.params.token
      ? this.props.match.params.token
      : authenticationService.getTokenOnline();

    this.props.loading(true);
    estabelecimentoService.findByToken(token).then(
      (response) => {
        let estabelecimento = response.data.data;
        this.props.setEstabelecimento(estabelecimento);
        authenticationService.setEstabelecimentoOnline(estabelecimento);
        authenticationService.setTokenOnline(token);
        this.props.loading(false);
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  }
  showCadastro = () => {
    this.setState({ showCadastro: !this.state.showCadastro });
  };

  handleSubmit = (email, senha, loginOnline, perfil) => {
    this.props.clear();
    if (email && senha) {
      this.props.login(email, senha, loginOnline, perfil);
      authenticationService.setTokenOnline(this.props.match.params.token);
    }
  };

  esqueciSenha = () => {
    this.setState({ esqueciSenha: true });
  };

  recuperarSenha = (values) => {
    this.props.loading(true);
    const { estabelecimento } = this.props;
    _.set(values, 'idEstabelecimento', estabelecimento.id);
    userService.esqueciSenha(values).then(
      (response) => {
        console.log(response);
        if (response.status == 200) {
          this.props.loading(false);
          this.setState({ senhaEnviada: true });
        } else {
          this.props.loading(false);
          this.props.error('O CPF digitado não foi encontrado');
        }
      },
      (erros) => {
        try {
          console.log(erros);
          let response = erros.response.data;
          console.log(response);
          if (response && response.messages) {
            console.log(response.messages[0]);
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              this.props.error(translate(erroItem.message.code));
            }
          }
          this.props.loading(false);
        } catch (error) {
          this.props.loading(false);
          console.log(error);
        }
      }
    );
  };
  cancelarLogin = () => {
    console.log('cancelar login');
    this.setState({
      esqueciSenha: false,
      senhaEnviada: false,
      showCadastro: false,
    });
  };

  salvarNovaSenha = (senha) => {
    this.props.loading(true);
    let values = {
      confirmPassword: senha,
      key: this.state.idRecover,
      password: senha,
    };

    console.log(values);
    userService.updateRecoverPassword(values).then(
      (response) => {
        console.log(response);
        if (response.status == 200) {
          this.props.loading(false);
          this.setState({ idRecover: null });
          this.props.success({
            message: `Senha alterada com sucesso!`,
          });
        } else {
          console.log(response);
          this.props.loading(false);
          this.props.error('Não foi possivel alterar sua senha');
        }
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error('Não foi possivel alterar sua senha');
      }
    );
  };
  render() {
    const { showCadastro, esqueciSenha, senhaEnviada, idRecover } = this.state;
    let token = this.props.match.params.token
      ? this.props.match.params.token
      : authenticationService.getTokenOnline();
    const { loggedIn, estabelecimento } = this.props;
    //TODO
    if (estabelecimento && estabelecimento.id && !idRecover) {
      return <Redirect to={{ pathname: '/agendamento' }}></Redirect>;
    }

    return (
      <div className="container-fluid d-flex h100 back-elements">
        <div className="card login ">
          {(showCadastro || esqueciSenha) && (
            <div className="float-left fade-in ">
              <button
                className=" header-icon back-icon"
                type="button"
                onClick={this.cancelarLogin}
              >
                <p className="txt-header"> Voltar</p>
              </button>
            </div>
          )}
          <div className="text-center">
            {_.get(estabelecimento, 'foto.id') && (
              <img
                className="logoOnline"
                src={midiaService.url(_.get(estabelecimento, 'foto.id'))}
              ></img>
            )}
            {!_.get(estabelecimento, 'foto.id') && (
              <div className="logo-agenda"></div>
            )}
          </div>
          {!esqueciSenha && !senhaEnviada && !showCadastro && !idRecover && (
            <div className="move-down">
              <p className="text-center m-0">Agendamento online</p>
              <h2 className="text-center mb-3">
                {/* {`Agendamento online`}<br></br> */}
                <b>{_.get(estabelecimento, 'nome')}</b>
              </h2>

              <p className="text-center">
                Para fazer um agendamento,<br></br>insira os dados abaixo e
                acesse sua conta
              </p>

              <hr></hr>
              <LoginForm
                exibeAlertErro={(msg) => {
                  this.props.error(msg);
                }}
                onSubmit={this.handleSubmit}
                esqueciSenha={this.esqueciSenha}
              ></LoginForm>
              <hr></hr>
              <div className="text-center">
                <p>
                  Se ainda não possuir cadastro,<br></br>clique abaixo e
                  cadastre sua conta
                </p>
                <button
                  className="btn btn-primary light"
                  onClick={this.showCadastro}
                >
                  Fazer cadastro
                </button>
              </div>
            </div>
          )}
          {showCadastro && !esqueciSenha && !senhaEnviada && (
            <AgendaOnlineCadastro
              tokenEstabelecimento={token}
            ></AgendaOnlineCadastro>
          )}

          {!showCadastro && esqueciSenha && !senhaEnviada && (
            <EsqueciSenhaForm
              recuperarSenha={this.recuperarSenha}
              agendamentoOnline={true}
            ></EsqueciSenhaForm>
          )}

          {senhaEnviada && <SenhaEnviadaForm></SenhaEnviadaForm>}

          {idRecover && (
            <NovaSenhaForm
              salvarNovaSenha={this.salvarNovaSenha}
            ></NovaSenhaForm>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn, loginOnline } = state.authentication;
  const { alert } = state;
  const { estabelecimento } = state.agendaOnline;

  return {
    loggedIn,
    alert,
    loginOnline,
    estabelecimento,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login, setTokenLoginOnline },
  load: { loading },
  agendaOnline: { setEstabelecimento },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  setTokenLoginOnline: (tokenLoginOnline) =>
    setTokenLoginOnline(tokenLoginOnline),
  login: (user, password, loginOnline, perfil) =>
    login({ user, password, loginOnline, perfil }),
  loading: (load: boolean) => loading({ load }),
  setEstabelecimento: (estabelecimento: any) =>
    setEstabelecimento({ estabelecimento }),
});

export default connect(mapStateToProps, mapDispatch)(IndexAgendaOnlinePage);
