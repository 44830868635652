import * as React from 'react';
import {connect} from 'react-redux';
import {Card} from "react-bootstrap";
import {ResponsivePie} from "@nivo/pie";
import {animated} from "@react-spring/web";
import {Md5} from "ts-md5";

/**
 * Props
 *
 */
type Props = {
    quantidadeTotalPacientes: number;
    quantidadeTotalPacientesNovos: number;
    quantidadeTotalPacientesRecorrentes: number;
    quantidadeTotalPacientesHomens: number;
    quantidadeTotalPacientesMulheres: number;
};

/**
 * State
 *
 */
type State = {
    dataChartPacientesFirst: DataChartPacientes[];
    dataChartPacientesSecond: DataChartPacientes[];
    dataChartPacientesThird: DataChartPacientes[];
    hashProps: string;
    hasData: boolean;
}

/**
 * Data chart pacientes
 *
 */
interface DataChartPacientes {
    id: string;
    label: string;
    value: number;
    color: string
}

/**
 * Chart Dashboard Component
 *
 */
class ChartPacientesDashboardComponent extends React.Component<Props, State> {

    /**
     * Default constructor
     *
     * @param props
     */
    constructor(props: any) {
        super(props);
        this.state = this.getInitialState();
    }

    /**
     * Resposible initial state
     *
     * @return {}
     */
    getInitialState() {
        return {
            dataChartPacientesFirst: this.getDataChartPacientesFirst(),
            dataChartPacientesSecond: this.getDataChartPacientesSecond(),
            dataChartPacientesThird: this.getDataChartPacientesThird(),
            hashProps: this.generateHasProps(
                this.props.quantidadeTotalPacientes,
                this.props.quantidadeTotalPacientesNovos,
                this.props.quantidadeTotalPacientesRecorrentes,
                this.props.quantidadeTotalPacientesHomens,
                this.props.quantidadeTotalPacientesMulheres
            ),
            hasData: this.hasData()
        };
    }

    /**
     * Responsible change componente.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        let newHashProps: string = this.generateHasProps(
            this.props.quantidadeTotalPacientes,
            this.props.quantidadeTotalPacientesNovos,
            this.props.quantidadeTotalPacientesRecorrentes,
            this.props.quantidadeTotalPacientesHomens,
            this.props.quantidadeTotalPacientesMulheres
        );
        if (newHashProps !== prevState.hashProps) {
            this.setState(this.getInitialState());
        }
    }

    /**
     * Responsible generate hash.
     *
     * @param quantidadeTotalPacientes
     * @param quantidadeTotalPacientesNovos
     * @param quantidadeTotalPacientesRecorrentes
     * @param quantidadeTotalPacientesHomens
     * @param quantidadeTotalPacientesMulheres
     * @return {string}
     */
    generateHasProps = (quantidadeTotalPacientes: number, quantidadeTotalPacientesNovos: number,
                        quantidadeTotalPacientesRecorrentes: number, quantidadeTotalPacientesHomens: number,
                        quantidadeTotalPacientesMulheres: number): string => {
        return Md5.hashStr(
            JSON.stringify(quantidadeTotalPacientes) +
            JSON.stringify(quantidadeTotalPacientesNovos) +
            JSON.stringify(quantidadeTotalPacientesRecorrentes) +
            JSON.stringify(quantidadeTotalPacientesHomens) +
            JSON.stringify(quantidadeTotalPacientesMulheres)
        )
    }

    /**
     *
     * Responsible validate if has data.
     *
     * @return {boolean}
     */
    hasData = (): boolean => {
        return this.props.quantidadeTotalPacientes > 0
            || this.props.quantidadeTotalPacientesNovos > 0
            || this.props.quantidadeTotalPacientesRecorrentes > 0
            || this.props.quantidadeTotalPacientesHomens > 0
            || this.props.quantidadeTotalPacientesMulheres > 0
    }

    /**
     * setDataChartPacientesThird
     *
     */
    getDataChartPacientesThird() {
        return [
            {
                id: "Mulheres",
                label: "Mulheres",
                value: Math.round((this.props.quantidadeTotalPacientesMulheres / this.props.quantidadeTotalPacientes) * 100),
                color: "#BA0072"
            },
            {
                value: Math.round((this.props.quantidadeTotalPacientesHomens / this.props.quantidadeTotalPacientes) * 100),
                color: "#DCDCDC"
            }
        ];
    }

    /**
     * setDataChartPacientesSecond
     *
     */
    getDataChartPacientesSecond() {
        return [
            {
                id: "Homens",
                label: "Homens",
                value: Math.round((this.props.quantidadeTotalPacientesHomens / this.props.quantidadeTotalPacientes) * 100),
                color: "#005DA9"
            },
            {
                value: Math.round((this.props.quantidadeTotalPacientesMulheres / this.props.quantidadeTotalPacientes) * 100),
                color: "#DCDCDC"
            }
        ];
    }

    /**
     * Responsible set DataChartPacientesFirst
     *
     */
    getDataChartPacientesFirst() {
        return [
            {
                id: "Recorrentes",
                label: "Recorrentes",
                value: Math.round((this.props.quantidadeTotalPacientesRecorrentes / this.props.quantidadeTotalPacientes) * 100),
                color: "#013A66FF"
            },
            {
                id: "Novos",
                label: "Novos",
                value: Math.round((this.props.quantidadeTotalPacientesNovos / this.props.quantidadeTotalPacientes) * 100),
                color: "#8ea2c0"
            }
        ];
    }

    getArcLabelsComponent() {
        return ({datum, label, style}) => (
            <animated.g transform={style.transform} style={{pointerEvents: 'none'}}>
                <circle fill={style.textColor} cy={6} r={15}/>
                <circle fill="#ffffff" stroke={datum.color} strokeWidth={2} r={16}/>
                <text
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={style.textColor}
                    style={{
                        fontSize: 10,
                        fontWeight: 800,
                    }}
                >
                    {label}%
                </text>
            </animated.g>
        );
    }

    getLegends(justify, translateX, translateY, itemsSpacing, itemWidth, itemHeight, symbolSize) {
        return [
            {
                anchor: 'bottom',
                direction: 'row',
                toggleSerie: true,
                justify: justify,
                translateX: translateX,
                translateY: translateY,
                itemsSpacing: itemsSpacing,
                itemWidth: itemWidth,
                itemHeight: itemHeight,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: symbolSize,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ];
    }

    /**
     * Get layers
     *
     * @param id
     * @returns {(string|(function({dataWithArc: *, centerX: *, centerY: *})))[]}
     */
    getLayers(id: string) {
        return ['arcs', 'arcLabels', 'arcLinkLabels', 'legends', ({dataWithArc, centerX, centerY}) => {
            let total = 0
            dataWithArc.forEach(datum => {
                if (datum.id === id) {
                    total += datum.value
                }
            })
            return (
                <text
                    x={centerX}
                    y={centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '15px',
                        fontWeight: 150,
                    }}
                >
                    {total}%
                </text>
            )
        }];
    }

    /**
     * Render component
     *
     * @returns {JSX.Element}
     */
    render() {
        return (
            <React.Fragment>
                <Card className="chart-card bg-light">
                    <Card.Body className="chart-card-body">
                        <Card.Title
                            className="chart-card-title d-flex justify-content-center">Pacientes</Card.Title>
                        {!this.state.hasData && (
                            <React.Fragment>
                                <div className="chart-text-blank">Não existem dados a serem exibidos para essa pesquisa.</div>
                            </React.Fragment>
                        )}
                        {this.state.hasData && (
                            <React.Fragment>
                                <div className="chart-card-pacientes-first">
                                    <ResponsivePie
                                        data={this.state.dataChartPacientesFirst}
                                        colors={{datum: 'data.color'}}
                                        enableArcLinkLabels={false}
                                        margin={{right: 15, bottom: 80, left: 15}}
                                        animate={true}
                                        activeOuterRadiusOffset={8}
                                        sortByValue={true}
                                        arcLabelsSkipAngle={20}
                                        arcLabelsRadiusOffset={0.55}
                                        arcLabelsTextColor={{from: 'color', modifiers: [['darker', 0.6]]}}
                                        arcLinkLabelsOffset={2}
                                        arcLinkLabelsColor={{from: 'color'}}
                                        arcLinkLabelsThickness={3}
                                        arcLabelsComponent={this.getArcLabelsComponent()}
                                        legends={this.getLegends(false, 27, 15, 0, 100, 15, 16)}
                                    />
                                </div>
                                <div className="chart-card-pacientes-second d-flex justify-content-around">
                                    <ResponsivePie
                                        data={this.state.dataChartPacientesSecond}
                                        colors={{datum: 'data.color'}}
                                        innerRadius={0.8}
                                        activeInnerRadiusOffset={8}
                                        layers={this.getLayers("Homens")}
                                        isInteractive={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        margin={{right: 15, bottom: 40, left: 15}}
                                        animate={false}
                                        activeOuterRadiusOffset={8}
                                        legends={this.getLegends(true, 18, 10, 0, 100, 15, 0)}
                                    />
                                    <ResponsivePie
                                        data={this.state.dataChartPacientesThird}
                                        colors={{datum: 'data.color'}}
                                        innerRadius={0.8}
                                        activeInnerRadiusOffset={8}
                                        layers={this.getLayers("Mulheres")}
                                        isInteractive={false}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        margin={{right: 15, bottom: 40, left: 15}}
                                        animate={false}
                                        activeOuterRadiusOffset={8}
                                        legends={this.getLegends(true, 20, 10, 0, 100, 15, 0)}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}

/**
 * Export default connect
 *
 */
export default connect(null, null, null)(ChartPacientesDashboardComponent);