import _ from 'lodash';
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconReserved from "../../images/icon-reserved.png";
import { dateHelper } from '../../_helpers/date.helper';
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import { userService } from '../../_services/user.service';
import FormSelectInput from "../inputs/FormSelectInput";
import FormTextArea from '../inputs/FormTextArea';
import InputViewEdit from "../inputs/InputViewEdit";

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalReservarHorario extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      observacoes: null
    };
  }

  fecharModal = () => {
    this.props.toogleModal();
  };

  handleChange = (name: string, value: any) => {
    const { observacoes } = this.state;
    // let newEntity = _.cloneDeep(observacoes);
    // newEntity = value;
    this.setState({ observacoes: value });
  };


  getValue = () => {
    if (this.state.observacoes) {
      return this.state.observacoes;
    }
  }


  clearObservacoes = () => {
    this.setState({ observacoes: null });
  }


  render() {
    const { estabelecimentoAtendimento, horario, date, medico } = this.props;
    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.fecharModal}
          backdrop="static"
          modalClassName=""
          className="modal-anexos"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="">
              <div className="row">
                <div className="col-12 text-center iconLocal">
                  <img src={IconReserved} alt="Icone reservar horario" />
                </div>
                <div className="col-12 text-center mb-2">
                  <h2 className="font-weight-bold">Deseja realmente reservar esse horário?</h2>
                  <p> Ao reservar não será possível agendar atendimento para{' '}
                    <b>{_.get(medico, 'apelido')}</b> no dia{' '}
                    <b>
                      {dateHelper.format(date, {
                        mode: 'DATE',
                      })}
                    </b>{' '}
                    às <b> {horario} </b>, até que a reserva seja retirada.
                  </p>
                </div>
              </div>

              <React.Fragment>
                <div className="form-row">
                  <div className="col-12">
                    <InputViewEdit
                      component={FormTextArea}
                      className={'textArea'}
                      label=""
                      id={'observacoes'}
                      name={'observacoes'}
                      onChange={(e) => { const { name, value } = e.target; this.handleChange(name, value) }}
                      rows={4}
                      maxLength={300}
                      viewClassName={'col-12'}
                      placeholder="Insira aqui as observações"
                      value={this.getValue()}
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-50 text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.fecharModal();
                  }}
                >
                  Cancelar
                </button>
              </div>
              <div className="w-50 text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    _this.fecharModal();
                    console.log(this.state.observacoes);
                    _this.props.callbackOnSave(this.state.observacoes);
                  }}
                >
                  Reservar
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
