//@flow
import _ from 'lodash';
import * as React from 'react';
import nextId from 'react-id-generator';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import {
  default as IconDelete,
  default as IconDelete2,
} from '../../images/icon_x_red.svg';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { exameService } from '../../_services/exame.service';
import CheckBoxInputOnly from '../inputs/CheckBoxInputOnly';
import DatePickerInput from '../inputs/DatePickerInput';
import DateTimePicker from '../inputs/DateTimePicker';
import FormSelectInput from '../inputs/FormSelectInput';
import InputViewEdit from '../inputs/InputViewEdit';
import SelectInput from '../inputs/SelectInput';
import { DiaDaSemanaItem } from './DiaDaSemanaItem';
import moment from 'moment';
import 'moment/locale/pt-br';
import FormInput from '../inputs/FormInput';

type Props = {
  entity: any,
  periodicidade: Boolean,
};

type State = {
  daysOfWeek: [],
  exibeListaExames: Boolean,
};

class HorarioFuncionamento extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      exibeListaExames: false,
      daysOfWeek: [
        { name: 'SEG', description: 'Seg', codigo: 1 },
        { name: 'TER', description: 'Ter', codigo: 2 },
        { name: 'QUA', description: 'Qua', codigo: 3 },
        { name: 'QUI', description: 'Qui', codigo: 4 },
        { name: 'SEX', description: 'Sex', codigo: 5 },
        { name: 'SAB', description: 'Sáb', codigo: 6 },
        { name: 'DOM', description: 'Dom', codigo: 0 },
      ],
      atenderSomente: [
        { name: 'PARTICULAR', description: 'Particular', codigo: 1 },
        { name: 'CONVENIO', description: 'Convênio', codigo: 2 },
        { name: 'CONSULTAS', description: 'Consultas', codigo: 3 },
        { name: 'RETORNO', description: 'Retorno', codigo: 4 },
        {
          name: 'PRIMEIRA_CONSULTA',
          description: 'Primeira consulta',
          codigo: 5,
        },
        { name: 'EXAMES', description: 'Exames', codigo: 6 },
      ],
    };
    this.exibeListaExames();
  }

  getNomeDayOfWeek = (value) => {
    let dayOfWeek;
    let { daysOfWeek } = this.state;
    for (let i = 0; i < daysOfWeek.length; i++) {
      if (daysOfWeek[i].name === value.name) {
        dayOfWeek = daysOfWeek[i];
      }
    }
    return dayOfWeek.description;
  };

  joinDays = (dias) => {
    let _this = this;
    var out = [];
    dias = _.orderBy(dias, ['codigo'], ['asc']);
    for (var i = 0; i < dias.length; i++) {
      out.push(_this.getNomeDayOfWeek(dias[i]));
    }
    return out.join(', ');
  };

  dayIsChecke = (entity, dayOfWeek) => {
    let checked = false;
    let dias = _.get(entity, 'dias', []);
    for (let i = 0; i < dias.length; i++) {
      if (dias[i].name === dayOfWeek.name) {
        checked = true;
      }
    }
    return checked;
  };

  atenderIsChecked = (entity, atenderSomente) => {
    let checked = false;
    let listHorarioAtendimentoMedicoAtender = _.get(
      entity,
      'listHorarioAtendimentoMedicoAtender',
      []
    );
    if (listHorarioAtendimentoMedicoAtender) {
      for (let i = 0; i < listHorarioAtendimentoMedicoAtender.length; i++) {
        if (
          listHorarioAtendimentoMedicoAtender[i].name === atenderSomente.name
        ) {
          checked = true;
        }
      }
    }
    return checked;
  };

  exibeListaExames = () => {
    const { entity } = this.props;
    let listHorarioAtendimentoMedicoAtender = _.get(
      entity,
      'listHorarioAtendimentoMedicoAtender',
      []
    );
    if (listHorarioAtendimentoMedicoAtender) {
      for (let i = 0; i < listHorarioAtendimentoMedicoAtender.length; i++) {
        if (listHorarioAtendimentoMedicoAtender[i].name === 'EXAMES') {
          // this.setState({ exibeListaExames: true });
          return true;
        }
      }
    }
  };

  //   addItemInArray = (nameItem) => {
  //     const { entity, propName } = this.props;
  //     let itemlist = [{ idList: new Date().getTime() }];
  //     // _.set(entity, `${propName}.${nameItem}`, itemlist);
  //     this.props.onChange(`${propName}.${nameItem}`, itemlist);
  //   };

  addItemInArray = (nameItem) => {
    const { entity, propName } = this.props;
    let listItem = _.get(entity, `${nameItem}`, []);
    listItem.push({ idList: new Date().getTime() });
    // _.set(entity, `${propName}.${nameItem}`, listItem);
    this.props.onChange(`${propName}.${nameItem}`, listItem);
  };

  removeHorarioFuncionamento = (index, entity) => {
    // ConfirmAcationService.confirmMsg(
    //   () => {
    this.props.removeItem(index, entity);
    //   },
    //   'Excluir horário de funcionamento',
    //   <span>Tem certeza que deseja excluir o horário de funcionamento?</span>,
    //   <span className="text-danger">{}</span>,
    //   'Não',
    //   'Sim',
    //   objectsConstants.TYPE_EXCLUIR
    // );
  };

  removeItemInArray = (nameItem, indexItem) => {
    const { entity, propName } = this.props;
    let newValues = _.cloneDeep(entity);
    let listItem: Array = _.get(newValues, `${nameItem}`, []);

    listItem.splice(indexItem, 1);
    this.props.onChange(`${propName}.${nameItem}`, listItem);
  };

  handleClickDayChange = (isChecked: boolean, dayOfWeekCallback: any) => {
    const { entity, propName } = this.props;
    let nameProp = `${propName}.dias`;
    let dias = _.get(entity, 'dias', []);
    if (isChecked) {
      dias.push(dayOfWeekCallback);
    } else {
      dias = _.remove(dias, function (n) {
        return n.name !== dayOfWeekCallback.name;
      });
    }
    this.props.onChange(nameProp, dias);
  };

  handleClickAtenderSomente = (isChecked: boolean, atenderSomente: any) => {
    const { entity, propName } = this.props;
    let nameProp = `${propName}.listHorarioAtendimentoMedicoAtender`;
    let listHorarioAtendimentoMedicoAtender = _.get(
      entity,
      'listHorarioAtendimentoMedicoAtender',
      []
    );
    if (isChecked) {
      listHorarioAtendimentoMedicoAtender.push(atenderSomente);
    } else {
      listHorarioAtendimentoMedicoAtender = _.remove(
        listHorarioAtendimentoMedicoAtender,
        function (n) {
          return n.name !== atenderSomente.name;
        }
      );
    }
    this.props.onChange(nameProp, listHorarioAtendimentoMedicoAtender);
  };

  getPeriodicidadeContent = () => {
    const { entity, propName, index, viewMode, size, errors } = this.props;
    return (
      <div className="row section-form">
        <FormGroup className="col-12 col-sm-4 pr-2">
          <InputViewEdit
            component={SelectInput}
            options={objectsConstants.REPETIR_HORARIO}
            name={`${propName}.periodicidade`}
            label="Repetir esta agenda"
            placeholder="Selecione a periodicidade"
            onChange={this.props.onChange}
            noSize={true}
            valueKey={'name'}
            labelKey={'description'}
            returnFullObject={true}
            value={_.get(entity, `periodicidade`)}
            viewMode={viewMode}
            id="periodicidade"
            erroMensagem={_.get(errors, `${propName}.periodicidade`)}
            required={true}
            defaultValue={_.get(entity, `periodicidade.description`)}
          />
        </FormGroup>
        <FormGroup className="col-6 col-sm-3">
          <InputViewEdit
            component={DatePickerInput}
            label="Data início"
            name={`${propName}.dataInicial`}
            id={`horariosAtendimento${index}dataInicial`}
            viewMode={viewMode}
            // format={'DD/MM/YYYY'}
            // defaultValue={
            //   _.get(entity, `dataInicial`) ? _.get(entity, `dataInicial`) : '--'
            // }
            value={_.get(entity, `dataInicial`)}
            onChange={this.props.onChange}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            placeholder={'--/--/----'}
            erroMensagem={_.get(errors, `${propName}.dataInicial`)}
            required={true}
            defaultValue={moment(_.get(entity, 'dataInicial')).format(
              'DD/MM/YYYY'
            )}
          />
        </FormGroup>
        <FormGroup className="col-6 col-sm-3">
          <InputViewEdit
            label="Data fim"
            viewMode={viewMode}
            format={'DD/MM/YYYY'}
            component={DatePickerInput}
            defaultValue={
              _.get(entity, `dataFinal`) ? _.get(entity, `dataFinal`) : '--'
            }
            name={`${propName}.dataFinal`}
            id={`horariosAtendimento${index}dataFinal`}
            value={_.get(entity, `dataFinal`)}
            onChange={this.props.onChange}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            placeholder={'--/--/----'}
            erroMensagem={_.get(errors, `${propName}.dataFinal`)}
            required={true}
            /* defaultValue={dateHelper.format(_.get(entity, `dataFinal`), {
              mode: 'DATE',
            })}*/
            defaultValue={moment(_.get(entity, `dataFinal`)).format(
              'DD/MM/YYYY'
            )}
          />
        </FormGroup>
        <FormGroup className="col-6 col-lg-3">
          <InputViewEdit
            component={FormInput}
            onChange={this.props.onChange}
            id={`local`}
            // erroMensagem={errors.sexo}
            label={'Nome do local de atendimento'}
            name={`localAtendimento`}
            placeholder="--"
            viewMode={viewMode}
            name={`${propName}.localAtendimento`}
            value={_.get(entity, `localAtendimento`)}
            apendRoot={true}
          />
        </FormGroup>
        <FormGroup className="col-6 col-lg-3">
          <InputViewEdit
            component={FormInput}
            onChange={this.props.onChange}
            id={`numeroLocal`}
            // erroMensagem={errors.sexo}
            label={'Número do local de atendimento'}
            name={`${propName}.numeroLocal`}
            value={_.get(entity, `numeroLocal`)}
            placeholder="--"
            viewMode={viewMode}
            apendRoot={true}
          />
        </FormGroup>
      </div>
    );
  };

  getNewInfo = () => {
    const { entity, propName, index, viewMode, size, errors, estabelecimento } =
      this.props;
    return (
      <React.Fragment>
        {viewMode && (
          <div className="col-12 col-sm-3">
            <label>Atender Somente</label>
            <p>
              {this.state.atenderSomente.map((atenderSomente, index) => {
                if (this.atenderIsChecked(entity, atenderSomente)) {
                  return (
                    <React.Fragment key={index}>
                      <span>{atenderSomente.description}, </span>
                    </React.Fragment>
                  );
                }
              })}
            </p>
            <label>Exames atendidos</label>
            <p>
              {entity &&
                entity.listExame &&
                entity.listExame.map((exame, index) => {
                  return (
                    <React.Fragment key={index}>
                      <span>{exame.nome}</span>
                    </React.Fragment>
                  );
                })}
            </p>
          </div>
        )}
        {!viewMode && (
          <div className="col-12 col-sm-2">
            <p>Atender Somente*</p>
            {this.state.atenderSomente.map((atenderSomente, index) => (
              <React.Fragment key={index}>
                <CheckBoxInputOnly
                  onChange={(checked, name) => {
                    this.handleClickAtenderSomente(checked, atenderSomente);
                  }}
                  checked={this.atenderIsChecked(entity, atenderSomente)}
                  name={atenderSomente.name}
                  label={atenderSomente.description}
                />
              </React.Fragment>
            ))}
            {this.exibeListaExames() && (
              <InputViewEdit
                component={FormSelectInput}
                onChange={(name, value) => {
                  this.props.onChange(name, !value ? [] : value);
                }}
                id={`listExame`}
                label="Selecione um ou mais Exames"
                placeholder="Selecione um ou mais exames"
                name={`${propName}.listExame`}
                value={_.get(entity, `listExame`)}
                erroMensagem={_.get(errors, `${propName}.listExame`)}
                returnFullObject={true}
                service={exameService.findAll}
                creatable={false}
                isMulti={true}
                valueKey="id"
                labelKey="nome"
                parent={estabelecimento.id}
              />
            )}
          </div>
        )}
        <div className="col-12 col-sm-2">
          <InputViewEdit
            component={SelectInput}
            id={'atendeTelemedicina'}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            returnFullObject={true}
            label="Atende em telemedicina? "
            required={true}
            labelKey={'label'}
            valueKey={'value'}
            viewMode={viewMode}
            name={`${propName}.atendeTelemedicina`}
            defaultValue={
              entity.atendeTelemedicina &&
              entity.atendeTelemedicina.value === false
                ? 'Não'
                : formatterHelper.getLabelEnum(
                    entity.atendeTelemedicina && entity.atendeTelemedicina.value
                      ? entity.atendeTelemedicina.value
                      : entity.atendeTelemedicina,
                    objectsConstants.SIM_NAO
                  )
            }
            value={
              _.isBoolean(entity.atendeTelemedicina)
                ? formatterHelper.getEnum(
                    entity.atendeTelemedicina,
                    objectsConstants.SIM_NAO
                  )
                : entity.atendeTelemedicina
            }
            erroMensagem={_.get(errors, `${propName}.atendeTelemedicina`)}
            onChange={(name, value) => {
              this.props.onChange(name, value.value);
            }}
          />

          <InputViewEdit
            component={SelectInput}
            id={'permiteAgendamentoOnline'}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            returnFullObject={true}
            label="Permitir agendamento on-line"
            required={true}
            labelKey={'label'}
            valueKey={'value'}
            viewMode={viewMode}
            name={`${propName}.permiteAgendamentoOnline`}
            defaultValue={
              entity.permiteAgendamentoOnline &&
              entity.permiteAgendamentoOnline.value === false
                ? 'Não'
                : formatterHelper.getLabelEnum(
                    entity.permiteAgendamentoOnline &&
                      entity.permiteAgendamentoOnline.value
                      ? entity.permiteAgendamentoOnline.value
                      : entity.permiteAgendamentoOnline,
                    objectsConstants.SIM_NAO
                  )
            }
            value={
              _.isBoolean(entity.permiteAgendamentoOnline)
                ? formatterHelper.getEnum(
                    entity.permiteAgendamentoOnline,
                    objectsConstants.SIM_NAO
                  )
                : entity.permiteAgendamentoOnline
            }
            erroMensagem={_.get(errors, `${propName}.permiteAgendamentoOnline`)}
            onChange={(name, value) => {
              this.props.onChange(name, value.value);
            }}
          />
          <InputViewEdit
            component={SelectInput}
            id={'permiteEncaixe'}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            returnFullObject={true}
            label="Permitir encaixe"
            required={true}
            labelKey={'label'}
            valueKey={'value'}
            viewMode={viewMode}
            name={`${propName}.permiteEncaixe`}
            defaultValue={
              entity.permiteEncaixe && entity.permiteEncaixe.value === false
                ? 'Não'
                : formatterHelper.getLabelEnum(
                    entity.permiteEncaixe && entity.permiteEncaixe.value
                      ? entity.permiteEncaixe.value
                      : entity.permiteEncaixe,
                    objectsConstants.SIM_NAO
                  )
            }
            value={
              _.isBoolean(entity.permiteEncaixe)
                ? formatterHelper.getEnum(
                    entity.permiteEncaixe,
                    objectsConstants.SIM_NAO
                  )
                : entity.permiteEncaixe
            }
            erroMensagem={_.get(errors, `${propName}.permiteEncaixe`)}
            onChange={(name, value) => {
              this.props.onChange(name, value.value);
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { entity, propName, index, viewMode, size, errors, estabelecimento } =
      this.props;
    return (
      <React.Fragment key={this.props.key}>
        {!viewMode && (
          <div className="horarioFuncionamento">
            <React.Fragment>
              <div className="form-inside">
                <div className="row pt-3">
                  <div className="col-12 col-sm-7">
                    <div className="row section-form">
                      <div
                        className="col-12 col-sm-5 weekDays d-flex justify-content-start align-items-center"
                        data-toggle="buttons"
                      >
                        {this.state.daysOfWeek.map((dayOfWeek, index) => (
                          <React.Fragment key={index}>
                            <DiaDaSemanaItem
                              key={dayOfWeek.id}
                              entity={dayOfWeek}
                              onChange={this.handleClickDayChange}
                              selected={this.dayIsChecke(entity, dayOfWeek)}
                            />
                          </React.Fragment>
                        ))}
                      </div>

                      <FormGroup className="col-6 col-sm-2 col-sm">
                        <InputViewEdit
                          label="Hora início"
                          viewMode={viewMode}
                          format={'HH:mm'}
                          component={DateTimePicker}
                          defaultValue={
                            _.get(entity, `inicio`)
                              ? _.get(entity, `inicio`)
                              : '--'
                          }
                          name={`${propName}.inicio`}
                          id={`horariosAtendimento${index}inicio`}
                          value={_.get(entity, `inicio`)}
                          erroMensagem={_.get(errors, `${propName}.inicio`)}
                          required={true}
                          onChange={this.props.onChange}
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          placeholder={'--:--'}
                          timeIntervals={30}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 col-sm-2 col-sm">
                        <InputViewEdit
                          label="Hora fim"
                          viewMode={viewMode}
                          format={'HH:mm'}
                          component={DateTimePicker}
                          defaultValue={
                            _.get(entity, `fim`) ? _.get(entity, `fim`) : '--'
                          }
                          name={`${propName}.fim`}
                          id={`horariosAtendimento${index}fim`}
                          value={_.get(entity, `fim`)}
                          erroMensagem={_.get(errors, `${propName}.fim`)}
                          required={true}
                          onChange={this.props.onChange}
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          placeholder={'--:--'}
                          timeIntervals={30}
                        />
                      </FormGroup>

                      <FormGroup className="col-12 col-sm-3 col-sm">
                        <InputViewEdit
                          component={SelectInput}
                          type="text"
                          name="intervaloMarcacao"
                          name={`${propName}.intervaloMarcacao`}
                          label="Tempo de atendimento"
                          placeholder="30m, 1h..."
                          options={objectsConstants.TEMPO_ATENDIMENTO}
                          onChange={this.props.onChange}
                          labelKey={'description'}
                          valueKey={'value'}
                          noSize={true}
                          erroMensagem={_.get(
                            errors,
                            `${propName}.intervaloMarcacao`
                          )}
                          viewMode={viewMode}
                          id="intervaloMarcacao"
                          value={_.get(entity, `intervaloMarcacao`)}
                          required={true}
                          returnFullObject={false}
                          searchable={true}
                          apendRoot={true}
                          defaultValue={
                            _.get(entity, `intervaloMarcacao`)
                              ? _.get(entity, `intervaloMarcacao`)
                              : '--'
                          }
                        />
                      </FormGroup>
                    </div>
                    {entity.listaIntervalo &&
                      entity.listaIntervalo.map((intervalo, i) => {
                        return (
                          <React.Fragment key={nextId()}>
                            <div
                              className="row section-form"
                              key={
                                i +
                                new Date().getTime() +
                                new Date().getUTCMilliseconds()
                              }
                            >
                              <FormGroup className="col-5 col-sm-4">
                                <InputViewEdit
                                  label="Intervalo início"
                                  viewMode={viewMode}
                                  format={'HH:mm'}
                                  component={DateTimePicker}
                                  defaultValue={
                                    _.get(
                                      entity,
                                      `listaIntervalo[${i}].horaInicio`
                                    )
                                      ? _.get(
                                          entity,
                                          `listaIntervalo[${i}].horaInicio`
                                        )
                                      : '--'
                                  }
                                  name={`${propName}.listaIntervalo[${i}].horaInicio`}
                                  id={`horariosAtendimento${index}listaIntervalo${i}horaInicio`}
                                  value={_.get(
                                    entity,
                                    `listaIntervalo[${i}].horaInicio`
                                  )}
                                  erroMensagem={_.get(
                                    errors,
                                    `${propName}.listaIntervalo[${i}].horaInicio`
                                  )}
                                  required={true}
                                  onChange={this.props.onChange}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  placeholder={'--:--'}
                                  timeIntervals={30}
                                />
                              </FormGroup>
                              <FormGroup className="col-5 col-sm-4">
                                <InputViewEdit
                                  label="Intervalo fim"
                                  viewMode={viewMode}
                                  format={'HH:mm'}
                                  component={DateTimePicker}
                                  defaultValue={
                                    _.get(
                                      entity,
                                      `listaIntervalo[${i}].horaFim`
                                    )
                                      ? _.get(
                                          entity,
                                          `listaIntervalo[${i}].horaFim`
                                        )
                                      : '--'
                                  }
                                  name={`${propName}.listaIntervalo[${i}].horaFim`}
                                  id={`horariosAtendimento${index}listaIntervalo${i}horaFim`}
                                  value={_.get(
                                    entity,
                                    `listaIntervalo[${i}].horaFim`
                                  )}
                                  erroMensagem={_.get(
                                    errors,
                                    `${propName}.listaIntervalo[${i}].horaFim`
                                  )}
                                  required={true}
                                  onChange={this.props.onChange}
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  placeholder={'--:--'}
                                  timeIntervals={30}
                                />
                              </FormGroup>

                              <div
                                className={`col-12 col-md-1 align-items-center justify-content-md-start justify-content-start ${
                                  viewMode ? 'd-none' : ' d-flex '
                                }`}
                              >
                                <div className="text-right mt-3">
                                  <div
                                    className={`icon-delete-2 d-flex align-items-center justify-content-center`}
                                    onClick={() => {
                                      this.removeItemInArray(
                                        'listaIntervalo',
                                        i
                                      );
                                    }}
                                  >
                                    <img
                                      src={IconDelete2}
                                      alt="Deletar horário"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    <div
                      className={
                        'w-100 d-flex justify-content-center justify-content-md-start mb-3 pl-10px'
                      }
                    >
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          this.addItemInArray('listaIntervalo');
                        }}
                        type="button"
                      >
                        Adicionar intervalo (indisponibilidade)
                      </button>
                    </div>
                    {this.props.periodicidade && this.getPeriodicidadeContent()}
                  </div>
                  {this.props.tipoHorarioFuncionamento ===
                    objectsConstants.TIPO_HORARIO_FUNCIONAMENTO_ESTABELECIMENTO_MEDICO &&
                    this.getNewInfo()}

                  <div
                    className={`col-12 col-sm align-items-center justify-content-center ${
                      viewMode ? 'd-none' : ' d-flex '
                    }`}
                  >
                    <div className="text-right mt-2">
                      <div
                        className={`icon-delete local-horario d-flex align-items-center justify-content-center ${
                          size === 1 || viewMode ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          this.removeHorarioFuncionamento(index, entity);
                        }}
                        disabled={size === 1}
                      >
                        <img src={IconDelete} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {index + 1 != size && <hr className="topo"></hr>}
            </React.Fragment>
          </div>
        )}
        {viewMode && (
          <React.Fragment>
            <div className="form-inside list-horarios">
              {entity.dias && (
                <div className={`row section-form`}>
                  <div className={`col-12 col-sm-4`}>
                    <label>Horário e dias:</label>
                    <p className="form-view mb-2">
                      <b>
                        {_.get(entity, `inicio`, '--')} {' ás  '}
                        {_.get(entity, `fim`, '--')}
                      </b>
                      {' - '}
                      {entity.dias && this.joinDays(entity.dias)}
                    </p>

                    <React.Fragment>
                      <label>Tempo de atendimento:</label>
                      <p className="form-view mb-2">
                        {_.get(entity, `intervaloMarcacao`)} minutos
                      </p>
                    </React.Fragment>

                    {entity.listaIntervalo &&
                      entity.listaIntervalo.length > 0 && (
                        <label>Intervalos:</label>
                      )}
                    {entity.listaIntervalo &&
                      entity.listaIntervalo.map((intervalo, index) => (
                        <React.Fragment key={index}>
                          <p className="form-view mb-2">
                            {intervalo.horaInicio} às {intervalo.horaFim}
                          </p>
                        </React.Fragment>
                      ))}
                  </div>
                  {this.props.tipoHorarioFuncionamento ===
                    objectsConstants.TIPO_HORARIO_FUNCIONAMENTO_ESTABELECIMENTO_MEDICO &&
                    this.getNewInfo()}
                </div>
              )}
              {!entity.dias && <p>Nenhum horário cadastrado</p>}
              {this.props.periodicidade && this.getPeriodicidadeContent()}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const connectedMedicoFormHorario = connect()(HorarioFuncionamento);
export { connectedMedicoFormHorario as HorarioFuncionamento };
