//@flow
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Client } from 'webstomp-client';
import { objectsConstants } from '../../_constants/objects.constants';
import { AgendaOnlineFilter } from './AgendaOnlineFilter';
import { userService } from '../../_services/user.service';
import { urlsConstants } from '../../_constants/urls.constant';
import SockJS from 'sockjs-client';
import webstomp from 'webstomp-client';
import { AgendaOnlineHeader } from './AgendaOnlineHeader';
import Toast from '../../components/Toasts/Toast';
import AuthorizedRoute from '../../_components/AuthorizedRoute';
import '../main/MainPage.css';
import { Redirect, withRouter } from 'react-router-dom';
import { AgendaOnlineView } from './AgendaOnlineView';
import { AgendaOnlineSucess } from './AgendaOnlineSucess';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import _ from 'lodash';

type Props = {
  medico: medico,
  dados: any,
  dispatch: any,
  client: Client,
  showDivMedico: boolean,
};

type State = {
  viewHorario: boolean,
  date?: any,
  maxMarcacaoDia: number,
  medicos: any,
  year: number,
  month: number,
  canal?: any,
};

class AgendaOnlineMain extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let dataAtual = moment();
    let year = dataAtual.year();
    let month = dataAtual.month();

    this.state = {
      viewHorario: false,
      maxMarcacaoDia: 0,
      year,
      month,
      filtro: props.filter ? props.filter : {},
      date: new Date(),
      agendaDoDia: [],
    };
  }
  /*
  componentDidMount() {
    this.props.loading(true);
  }*/

  componentDidMount() {
    if (this.props.loggedIn) {
      this.connectAndReconnect();
    }
  }

  connectAndReconnect = (socketInfo) => {
    const token = JSON.parse(localStorage.getItem('token'));
    let sock = new SockJS(
      urlsConstants.WS_WEBSOCKET + '?access_token=' + token.access_token
    );
    let client = webstomp.over(sock);
    client.connect(
      {},
      (frame) => {
        this.successCallback(client);
      },
      (error) => {
        this.errorCallback(error);
        setTimeout(() => {
          this.connectAndReconnect(socketInfo);
        }, 5000);
      }
    );
  };

  successCallback = (client) => {
    this.props.clear();
    this.setState({ client, webSocketConnected: true });
    this.props.conectSocket({ client, webSocketConnected: true });
  };

  errorCallback = (error) => {
    console.log(error);
    this.setState({ webSocketConnected: false });
    this.props.disconectSocket();
  };

  AgendaOnlineFilter = () => {
    const { ...othreProps } = this.props;
    return <AgendaOnlineFilter client={this.state.client} {...othreProps} />;
  };

  AgendaOnlineView = () => {
    const { ...othreProps } = this.props;
    return <AgendaOnlineView client={this.state.client} {...othreProps} />;
  };

  AgendaOnlineSucess = () => {
    const { ...othreProps } = this.props;
    return <AgendaOnlineSucess client={this.state.client} {...othreProps} />;
  };

  render() {
    console.log(this.props.estabelecimento);
    if (!this.props.estabelecimento || _.isEmpty(this.props.estabelecimento)) {
      return <Redirect to={{ pathname: '/agendaOnline/' }} />;
    }
    return (
      <div id="wrapper" className="container-fluid">
        <AgendaOnlineHeader></AgendaOnlineHeader>
        <Toast></Toast>
        <Switch>
          <Switch>
            <Route
              path="/agendamento/sucesso"
              component={this.AgendaOnlineSucess}
              I={objectsConstants.VIEW}
              a={objectsConstants.AGENDAMENTO_ONLINE}
              exact
            />
            <Route
              path="/agendamento/view"
              component={this.AgendaOnlineView}
              I={objectsConstants.VIEW}
              a={objectsConstants.AGENDAMENTO_ONLINE}
              exact
            />
            <Route
              path="/agendamento"
              component={this.AgendaOnlineFilter}
              I={objectsConstants.VIEW}
              a={objectsConstants.AGENDAMENTO_ONLINE}
            />
          </Switch>
        </Switch>
      </div>
    );
  }
}

const mapDispatch = ({
  authentication: { logout },
  webSocket: { conectSocket, disconectSocket },
  agenda: { clearMedico },
  alert: { success, error, clear },
  sidemenu: { toggleMenu, abreMenu, fechaMenu },
}) => ({
  logout: () => logout(),
  clearMedico: () => clearMedico(),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
  clear: () => clear(),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu(),
  fechaMenu: () => fechaMenu(),
  conectSocket: (clientScoket) => conectSocket(clientScoket),
  disconectSocket: () => disconectSocket(),
});

function mapStateToProps(state) {
  const { estabelecimento } = state.agendaOnline;
  const { loggedIn } = state.authentication;
  return {
    estabelecimento,
    loggedIn,
  };
}
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(AgendaOnlineMain));

// const connectedAgendaOnlineMain = withRouter(connect(mapStateToProps, mapDispatch)(AgendaOnlineMain));
// export { connectedAgendaOnlineMain as AgendaOnlineMain };
