//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import FormSelectInput from '../../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../../components/inputs/InputViewEdit';
import SelectInput from '../../../../components/inputs/SelectInput';
import { objectsConstants } from '../../../../_constants/objects.constants';
import { dateHelper } from '../../../../_helpers/date.helper';
import { medicoService } from '../../../../_services/medico.service';
import { pacienteService } from '../../../../_services/paciente.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class IntegracaoSascLogListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <React.Fragment>
          <FormGroup className="col-12 col-md-2">
            {' '}
            <InputViewEdit
              component={DatePickerInput}
              label="Dia início"
              name={`dataStart`}
              id={`dataStart`}
              value={entity.dataStart}
              onChange={this.props.onChangeDate}
              placeholder={'--/--/----'}
              defaultValue={
                _.get(entity, `dataStart`)
                  ? dateHelper.format(_.get(entity, `dataStart`), {
                      mode: 'DATE',
                    })
                  : '--'
              }
            />
          </FormGroup>
          <FormGroup className="col-12 col-md-2">
            <InputViewEdit
              component={DatePickerInput}
              label="Dia fim"
              name={`dataEnd`}
              id={`dataEnd`}
              value={entity.dataEnd}
              onChange={this.props.onChangeDate}
              placeholder={'--/--/----'}
              defaultValue={
                _.get(entity, `dataEnd`)
                  ? dateHelper.format(_.get(entity, `dataEnd`), {
                      mode: 'DATE',
                    })
                  : '--'
              }
            />
          </FormGroup>
          <FormGroup className="col-12 col-md-5">
            <SelectInput
              id="paciente"
              name="paciente"
              label="Paciente"
              onFetchData={pacienteService.findByNomeOuCPF}
              onChange={this.props.onChangeDate}
              value={entity.paciente}
              labelKey="nome"
              multi={false}
              returnFullObject={true}
              creatable={true}
              required={false}
              // components={{ Option: OptionUsuario }}
              placeholder="Digite e selecione um paciente"
            />
          </FormGroup>

          <FormGroup className="col-12 col-md-3">
            <InputViewEdit
              component={SelectInput}
              name="tipoAtendimento"
              id={'tipoAtendimento'}
              label="Tipo do atendimento"
              placeholder="Selecione um tipo do atendimento"
              valueKey="name"
              labelKey="description"
              noSize={true}
              required={false}
              returnFullObject={false}
              isMulti={false}
              searchable={false}
              options={objectsConstants.TIPO_ATENDIMENTO}
              onChange={this.props.onChangeDate}
              value={entity.tipoAtendimento}
            />
          </FormGroup>

          <FormGroup className="col-12 col-md-2">
            <InputViewEdit
              component={SelectInput}
              name="tipoConsulta"
              id={'tipoConsulta'}
              label="Tipo de consulta"
              placeholder="Selecione um tipo de consulta"
              valueKey="name"
              labelKey="description"
              noSize={true}
              required={false}
              returnFullObject={false}
              isMulti={false}
              searchable={false}
              options={objectsConstants.TIPO_CONSULTA}
              onChange={this.props.onChangeDate}
              value={entity.tipoConsulta}
            />
          </FormGroup>
          <FormGroup className="col-12 col-md-5">
            <FormSelectInput
              name="fkMedico"
              id={'medico'}
              label="Profissional de saúde"
              placeholder="Selecione um profissional de saúde"
              valueKey="id"
              labelKey="apelido"
              noSize={true}
              required={false}
              returnFullObject={false}
              isMulti={false}
              searchable={true}
              service={medicoService.findMedicoByEspecialidade}
              onChange={this.props.onChangeDate}
              value={entity.fkMedico}
            />
          </FormGroup>
          <FormGroup className="col-12 col-md-3">
            <InputViewEdit
              component={SelectInput}
              name="tipo"
              id={'tipo'}
              label="Tipo"
              placeholder="Selecione um tipo"
              valueKey="name"
              labelKey="description"
              noSize={true}
              required={false}
              returnFullObject={false}
              isMulti={false}
              searchable={false}
              options={objectsConstants.TIPO}
              onChange={this.props.onChangeDate}
              value={entity.tipo}
            />
          </FormGroup>
          <FormGroup className="col-12 col-md-2">
            <InputViewEdit
              component={SelectInput}
              name="status"
              id={'status'}
              label="Status da consulta"
              placeholder="Selecione um status da consulta"
              valueKey="name"
              labelKey="description"
              noSize={true}
              required={false}
              returnFullObject={true}
              isMulti={false}
              searchable={false}
              options={objectsConstants.STATUS_CONSULTA}
              onChange={this.props.onChangeDate}
              value={entity.status}
            />
          </FormGroup>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
