import { Formik } from "formik";
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import FormInput from "../inputs/FormInput";
import saveProtocolo from "../../images/icon-save.svg";
import { translate } from "../../_helpers/messages.helper";
import { defaultService } from "../../_services/defaultService";
import { protocoloAtendimentoService } from "../../_services/protocoloAtendimento.service";
import _ from "lodash";
import { empresaService } from "../../_services/empresa.service";


const protocolValidate = Yup.object().shape({
  nome: Yup.string()
    .min(5, "Nome muito curto!")
    .max(60, "Nome muito grande!")
    .required("Obrigatório")
});

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalProtocolo extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    //this.loadFile = this.loadFile.bind(this);
  }
  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          values.prescricao = _.get(_this.props.entity, 'prescricao', '');
          values.listMedicos = [_this.props.user];
          values.estabelecimento = _this.props.estabelecimento;
          protocoloAtendimentoService.doSave(values).then(
            response => {
              console.log("doSave");
              _this.props.success({
                message: `Protocolo, foi salvo com sucesso!`
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);

              _this.props.loading(false);

              _this.props.toogle();
            },
            erros => {
              _this.props.loading(false);
              _this.props.error({
                message: "Não foi possível salvar protocolo."
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          )
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };
  cancelar = () => {
    this.props.toogle();
  };

  render() {
    const { entity, callbackOnSave } = this.props;
    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-anexos"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <Formik
            validationSchema={protocolValidate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            initialValues={{}}

            ref={form => {
              this.formRef = form;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              validationSchema,
              validateForm,
              setValues
            }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className="">
                    <div className="row">
                      <div className="col-12 text-center iconProtocolo">
                        <img src={saveProtocolo} alt="anexo" />
                      </div>
                      <div className="col-12 text-center mb-2">
                        <h2 className="font-weight-bold">Salvar Protocolo</h2>
                      </div>
                      {/* <div className="col-12 text-center my-2">
                         <p>Insira o nome do Protocolo</p>
                      </div> */}
                    </div>

                    <React.Fragment>
                      <div className="form-row">
                        <div className="col-12">
                          <FormInput
                            label="Insira o nome do Protocolo"
                            placeholder="Nome do protocolo"
                            component={FormInput}
                            defaultValue={""}
                            required={false}
                            value={values.nome}
                            name={`nome`}
                            id={`nomeProtocolo`}
                            onChange={setFieldValue}
                            erroMensagem={errors.nome}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <React.Fragment>
                    <div className="w-50 text-center">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                          this.cancelar();
                        }}
                      >
                        {" "}
                        Cancelar{" "}
                      </button>
                    </div>
                    <div className="w-50 text-center">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.saveForm}
                      >
                        {" "}
                        Salvar{" "}
                      </button>
                    </div>
                  </React.Fragment>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
