//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import IconDelete from '../../images/icon_x_red.svg';
import { posologiaService } from '../../_services/posologia.service';

import FormInput from '../inputs/FormInput';
import FormSelectInput from '../inputs/FormSelectInput';
import InputViewEdit from '../inputs/InputViewEdit';
import DadosUser from '../DadosUser/DadosUser';
import reactSwipe from 'react-easy-swipe/lib/react-swipe';
import { dateHelper } from '../../_helpers/date.helper';
import { Link } from 'react-router-dom';
import { indisponibilidadeService } from '../../_services/indisponibilidade.service';
import { userService } from '../../_services/user.service';

type Props = {
  indisponibilidade: any,
};

class IndisponibilidadeRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      marcacoesAfetadas: 0,
    };
  }

  componentDidMount = () => {
    indisponibilidadeService
      .findMarcacoesAfetadas(
        _.get(this.props.indisponibilidade, 'id'),
        _.get(this.props.indisponibilidade, 'tipoAgenda')
      )
      .then(
        (response) => {
          let tarefasGeradas = response.data;
          if (response) {
            this.setState({ marcacoesAfetadas: tarefasGeradas });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  verificaHorario = () => {
    return (
      <span>
        {this.props.indisponibilidade.horaInicio} até{' '}
        {this.props.indisponibilidade.horaFim}
      </span>
    );
  };

  verificaPeriodo = () => {
    return (
      <span>
        {dateHelper.format(this.props.indisponibilidade.dataInicio, {
          mode: 'DATE',
        })}{' '}
        até{' '}
        {dateHelper.format(this.props.indisponibilidade.dataFim, {
          mode: 'DATE',
        })}
      </span>
    );
  };
  exibeListaMedicos = () => {
    let med = _.get(
      this.props.indisponibilidade,
      'listEstabelecimentoMedico',
      []
    );
    let medicos = _.uniqBy(med, function (e) {
      return e.medico.id;
    })
      .map((e) => e.medico.apelido)
      .join(', ');
    return (
      <p>
        <b>{medicos}</b>
      </p>
    );
  };
  exibeListaExames = () => {
    let listExame = _.get(this.props.indisponibilidade, 'listExame', []);
    let exames = listExame.map((e) => e.nome).join(', ');
    return (
      <p>
        <b>{exames}</b>
      </p>
    );
  };

  render() {
    const { indisponibilidade, index } = this.props;
    const { marcacoesAfetadas } = this.state;
    return (
      <React.Fragment>
        <div className="indisponibilidade-row d-flex flex-row">
          <div className="d-flex flex-column flex-fill">
            {/* <p className="titulo-red">Agenda Fechada</p> */}
            <p>
              <b>{_.get(indisponibilidade, 'estabelecimento.nome')}</b>
            </p>
            {this.exibeListaMedicos()}
            {this.exibeListaExames()}
            <p>{this.verificaPeriodo()}</p>
            <p>{this.verificaHorario()}</p>
            <p>{_.get(indisponibilidade, 'justificativa')}</p>
            {marcacoesAfetadas > 0 && (
              <div className="anchor-link">
                <Link to="/tarefas">
                  {marcacoesAfetadas}
                  {marcacoesAfetadas > 1
                    ? ' pacientes para remarcar'
                    : ' paciente para remarcar'}
                </Link>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {!userService.isSuporte() && (
              <div
                className={`icon-delete d-flex align-items-center justify-content-center `}
                onClick={() => {
                  this.props.removeItem(indisponibilidade.id);
                }}
              >
                <img src={IconDelete} alt="Deletar" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(IndisponibilidadeRow);
