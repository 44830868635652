//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import DateTimePicker from '../../../components/inputs/DateTimePicker';
import FormInput from '../../../components/inputs/FormInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { objectsEnuns } from '../../../_constants/objects.constants';
import { dateHelper } from '../../../_helpers/date.helper';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class EstoqueAjusteListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-2">
          <DatePickerInput
            label="Data da posição do estoque"
            name={`data`}
            id={`data`}
            value={entity.data}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `data`)
                ? dateHelper.format(_.get(entity, `data`), {
                    mode: 'DATE',
                  })
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <DateTimePicker
            label="Hora da posição do estoque"
            name={`hora`}
            id={`hora`}
            value={entity.hora}
            onChange={this.props.onChangeDate}
            format={'HH:mm'}
            defaultValue={_.get(entity, `hora`) ? _.get(entity, `hora`) : '--'}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            placeholder={'--:--'}
            timeIntervals={30}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <SelectInput
            options={objectsEnuns.TIPO_LANCAMENTO_ESTOQUE}
            type="text"
            name="produto"
            label="Produto"
            placeholder="Selecione o produto"
            onChange={this.props.onChangeDate}
            noSize={true}
            id="produto"
            returnFullObject={true}
            labelKey={'label'}
            valueKey={'value'}
            multi={false}
            value={entity.produto}
            required={false}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <SelectInput
            options={objectsEnuns.TIPO_LANCAMENTO_ESTOQUE}
            type="text"
            name="grupo"
            label="Grupo"
            placeholder="Selecione o grupo"
            onChange={this.props.onChangeDate}
            noSize={true}
            id="grupo"
            returnFullObject={true}
            labelKey={'label'}
            valueKey={'value'}
            multi={false}
            value={entity.grupo}
            required={false}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <FormInput
            type="text"
            name="tag"
            label="Tag do produto"
            placeholder="Insira uma tag de busca"
            onChange={this.props.onChangeDate}
            noSize={true}
            id="tag"
            multi={false}
            value={entity.tag}
            required={false}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <SelectInput
            options={objectsEnuns.TIPO_ESTOQUE_QTD}
            type="text"
            name="estoque"
            label="Estoque"
            placeholder="Selecione"
            onChange={this.props.onChangeDate}
            noSize={true}
            id="estoque"
            returnFullObject={true}
            labelKey={'label'}
            valueKey={'value'}
            multi={false}
            value={entity.estoque}
            required={false}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <FormInput
            type="text"
            name="qtdEstoque"
            label="Qtd."
            placeholder=""
            onChange={this.props.onChangeDate}
            noSize={true}
            id="qtdEstoque"
            multi={false}
            value={entity.qtdEstoque}
            required={false}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
