//@flow
import {Formik} from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import {FormGroup} from '../../components/FormGroup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import {userService} from "../../_services/user.service";
import {voucherService} from "../../_services/voucher.service";

const VoucherValidate = Yup.object().shape({
    nameOrCpfcNPJ: Yup.string()
        .required('O nome ou Cpf / Cnpj do usuário é obrigatório.'),
});

class GerarVoucherForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    goToViewEntity = () => {
        this.props.history.push("/voucher-soluti");
    };

    saveForm = () => {
        let _this = this;
        let values = _this.formRef.state.values;
        return new Promise(function (resolve) {
            _this.formRef.validateForm(values).then((error) => {
                if (_.isEmpty(error)) {
                    _this.props.loading(true);
                    voucherService.generate(values.nameOrCpfcNPJ.id).then(
                        (response) => {
                            _this.props.loading(false);
                            if(response.statusFinal === "SUCCESS") {
                                _this.props.success({message: response.formatMsg});
                                _this.goToViewEntity(values);
                                resolve(true);
                            }else {
                                _this.props.error({message: response.formatMsg});
                                resolve(false);
                            }
                        })
                } else {
                    _this.props.error({
                        message: 'O nome ou Cpf / Cnpj do usuário é obrigatório.',
                    });
                    resolve(false);
                }
            });
        });
    };

    render() {
        let _this = this;
        const {viewMode, entity, propName, index} = this.props;
        return (
            <React.Fragment>
                <Formik
                    validationSchema={VoucherValidate}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={entity}
                    ref={(form) => {
                        this.formRef = form;
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          validationSchema,
                          validateForm,
                          setValues,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-inside">
                                <div className="row section-form">
                                    <div className="col-12 col-lg-12 form-row pl-3">
                                        <FormGroup className="col-12 col-lg-5">
                                            <InputViewEdit
                                                component={SelectInput}
                                                label={'Nome ou Cpf / CNPJ do usuário'}
                                                id="nameOrCpfcNPJ"
                                                name="nameOrCpfcNPJ"
                                                placeholder="Nome ou Cpf / CNPJ do usuário"
                                                value={_.get(values, 'nameOrCpfcNPJ')}
                                                defaultValue={
                                                    _.get(values, 'nameOrCpfcNPJ')
                                                        ? _.get(values, 'nameOrCpfcNPJ').cpfCnpjJoinName
                                                        : '--'
                                                }
                                                onChange={setFieldValue}
                                                noSize={true}
                                                viewMode={viewMode}
                                                required={true}
                                                returnFullObject={true}
                                                valueKey={'id'}
                                                labelKey={'cpfCnpjJoinName'}
                                                multi={false}
                                                onFetchData={userService.findByCpfCnpjOrName}
                                                sortKey={'cpfCnpjJoinName'}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.authentication;
    return {
        user,
    };
}

const mapDispatch = ({
                         alert: {success, error, clear},
                         load: {loading},
                         authentication: {refreshUser},
                     }) => ({
    refreshUser: (user) => refreshUser({user}),
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({load}),
    error: (msg) => error(msg),
    clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
    forwardRef: true,
})(GerarVoucherForm);
