//@flow
import {AbilityBuilder} from '@casl/ability';
import { objectsConstants } from '../_constants/objects.constants';


const DEFAULT = AbilityBuilder.define(can => {});

export function getPermission(user) {
  const ability = AbilityBuilder.define(can => {
    let listRolePermission = user.role.role.listRolePermission;
    for (let i = 0; i < listRolePermission.length; i++) {
        let permission = listRolePermission[i];
        can(permission.action, permission.casoDeUso.nome);
    }
    can(objectsConstants.VIEW, objectsConstants.PUBLIC);
  });
  return ability;
}
