import * as React from 'react';

export class ParamGeralPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return <div>Param Geral </div>;
  }
}
