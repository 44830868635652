import { dateHelper } from '../_helpers/date.helper';
import CpfCnpjInput from '../components/inputs/CpfCnpjInput';
import DatePickerInput from '../components/inputs/DatePickerInput';
import FormInput from '../components/inputs/FormInput';
import TimePicker from '../components/inputs/TimePicker';

export const objectsConstants = {
  //ABILITY
  ATENDIMENTO_PRIORITARIO: [
    { name: 'IDOSO', description: 'Idoso' },
    { name: 'CADEIRANTE', description: 'Cadeirante' },
    { name: 'GRAVIDAS', description: 'Grávidas' },
    { name: 'RISCO_QUEDA', description: 'Risco de queda' },
    { name: 'TEA', description: 'TEA' },
  ],
  INDISPONIBILIDADE: 'INDISPONIBILIDADE',
  DELETE: 'DELETE',
  INSERT: 'INSERT',
  PUBLIC: 'PUBLIC',
  MENU_EXAME: 'MENU_EXAME',
  MENU_MEDICO: 'MENU_MEDICO',
  MENU_VOUCHER: 'MENU_VOUCHER',
  MENU_DASHBOARD: 'MENU_DASHBOARD',
  MENU_INTEGRACAO: 'MENU_INTEGRACAO',
  MENU_INTEGRACAO_CONNECT: 'MENU_INTEGRACAO_CONNECT',
  MENU_INTEGRACAO_SASC: 'MENU_INTEGRACAO_SASC',
  MENU_AGENDA: 'MENU_AGENDA',
  MENU_CODE4: 'MENU_CODE4',
  MENU_PACIENTE: 'MENU_PACIENTE',
  MENU_USUARIO: 'MENU_USUARIO',
  MENU_TAREFAS: 'MENU_TAREFAS',
  MENU_PACOTES: 'MENU_PACOTES',
  ALTERAR_LOCAL_ATENDIMENTO_MEDICO: 'ALTERAR_LOCAL_ATENDIMENTO_MEDICO',
  MENU_AGENDAMENTO_RAPIDO: 'MENU_AGENDAMENTO_RAPIDO',
  MENU_PARAMETRIZACAO: 'MENU_PARAMETRIZACAO',
  MENU_PARAMETRIZACAO_TRIAGEM: 'MENU_PARAMETRIZACAO_TRIAGEM',
  CRUD_ANAMNESE_VISUALIZAR: 'CRUD_ANAMNESE_VISUALIZAR',
  CRUD_ANAMNESE_CRIAR: 'CRUD_ANAMNESE_CRIAR',
  CRUD_MEDICO_EDITAR: 'CRUD_MEDICO_EDITAR',
  CRUD_PACOTE_EDITAR: 'CRUD_PACOTE_EDITAR',
  CRUD_USUARIO_EDITAR: 'CRUD_USUARIO_EDITAR',
  CRUD_ESTOQUE_EDITAR: 'CRUD_USUARIO_EDITAR',
  CRUD_PACIENTE_EDITAR: 'CRUD_PACIENTE_EDITAR',
  CRUD_PARAMETRIZACAO_TRIAGEM: 'CRUD_PARAMETRIZACAO_TRIAGEM',
  MENU_ADMINNISTRADOR: 'MENU_ADMINNISTRADOR',
  CRUD_ESTABELECIMENTOS: 'CRUD_ESTABELECIMENTOS',
  CRUD_ESTABELECIMENTOS_EDITAR: 'CRUD_ESTABELECIMENTOS_EDITAR',
  CRUD_RELATORIOS: 'CRUD_RELATORIOS',
  CRUD_RELATORIOS_EXAMES: 'CRUD_RELATORIOS_EXAMES',
  MENU_RELATORIOS: 'MENU_RELATORIOS',
  MENU_RELATORIO_EXAME: 'MENU_RELATORIO_EXAME',
  CRUD_DASHBORAD_FINANCEIRO: 'CRUD_DASHBORAD_FINANCEIRO',
  CRUD_AGENDAMENTO_RAPIDO: 'CRUD_AGENDAMENTO_RAPIDO',
  CRUD_EMPRESA_CRIAR: 'CRUD_EMPRESA_CRIAR',
  CRUD_EMPRESA_EDITAR: 'CRUD_EMPRESA_EDITAR',
  CRUD_INTEGRACAO: 'CRUD_INTEGRACAO',
  CRUD_PACOTE: 'CRUD_PACOTE',
  CRUD_MODELO_RECEITUARIO: 'CRUD_MODELO_RECEITUARIO',
  AGENDA_ATENDER: 'AGENDA_ATENDER',
  AGENDA_ATENDIDO: 'AGENDA_ATENDIDO',
  AGENDA_COMPARECER: 'AGENDA_COMPARECER',
  AGENDA_VALIDAR_ELEGIBILIDADE: 'AGENDA_VALIDAR_ELEGIBILIDADE',
  AGENDA_MARCAR: 'AGENDA_MARCAR',
  AGENDA_DESMARCAR: 'AGENDA_DESMARCAR',
  VERIFICAR_ATENDIMENTO: 'VERIFICAR_ATENDIMENTO',
  REMARCACAO_CONSULTA: 'REMARCACAO_CONSULTA',
  AGENDA_TRIAGEM: 'AGENDA_TRIAGEM',
  FILTRO_PERIODO: {
    manha: { inicio: '06:00', fim: '11:59' },
    tarde: { inicio: '12:00', fim: '17:59' },
    noite: { inicio: '18:00', fim: '23:59' },
  },
  STATUS_DESCRICAO: {
    MARCADO: 'Marcado',
    ATENDIDO: 'Atendido',
    CANCELADO: 'Cancelado',
    AGUARDANDO_ATENDIMENTO: 'Aguardando atendimento',
    EM_ATENDIMENTO: 'Em atendimento',
    EM_CHAMADA: 'Em chamada',
    MARCACOES_FORA_HORARIO_MEDICO: 'Fora do horário',
    NAO_COMPARECEU: 'Não compareceu',
    AGUARDANDO_RECEPCAO: 'Aguardando recepção',
    REAGENDADA: 'Reagendada',
    RECEPCAO_INTERNA: 'Aguardando recepção interna',
    AGUARDANDO_TRIAGEM: 'Aguardando triagem ',
  },
  STATUS_MARCACAO: [
    'MARCADO',
    'AGUARDANDO_ATENDIMENTO',
    'ATENDIDO',
    'EM_CHAMADA',
    'RECEPCAO_INTERNA',
    'AGUARDANDO_TRIAGEM',
  ],
  TIPOS_DOCUMENTOS_ASSINADOS: [
    { name: 'MEDICAL_STATEMENT', description: 'Atestado/Laudo médico' },
    { name: 'MEDICAL_REPORT', description: 'Encaminhamento médico' },
    {
      name: 'MEDICAL_PRESCRIPTION',
      description: 'Receita médica',
    },
    { name: 'MEDICAL_ATTENTION', description: 'Orientação/cuidados médicos' },
    { name: 'MEDICAL_RELEASE', description: 'Alta médica' },
    // { name: 'VACCINATION', description: 'Vacinação' },
  ],
  ROLE_STATUS_AGENDA: [
    { status: 'MARCADO', permission: 'AGENDA_COMPARECER', prioridade: 1 },
    {
      status: 'AGUARDANDO_ATENDIMENTO',
      permission: 'AGENDA_ATENDER',
      prioridade: 4,
    },
    { status: 'EM_CHAMADA', permission: 'AGENDA_ATENDIDO', prioridade: 3 },
    {
      status: 'RECEPCAO_INTERNA',
      permission: 'AGENDA_VALIDAR_ELEGIBILIDADE',
      prioridade: 0,
    },
    {
      status: 'AGUARDANDO_TRIAGEM',
      permission: 'AGENDA_TRIAGEM',
      prioridade: 2,
    },
  ],
  REOPEN: 'REOPEN',
  REPROVE: 'REPROVE',
  APROVE: 'APROVE',
  TYPE_EXCLUIR: 'TYPE_EXCLUIR',
  TYPE_WARNING: 'TYPE_WARNING',
  TYPE_CANCEL: 'TYPE_CANCEL',
  EM_ATENDIMENTO: 'EM_ATENDIMENTO',
  AGUARDANDO_ATENDIMENTO: 'AGUARDANDO_ATENDIMENTO',
  ATENDIDO: 'ATENDIDO',
  MARCADO: 'MARCADO',
  AGUARDANDO_RECEPCAO: 'AGUARDANDO_RECEPCAO',
  CANCELADO: 'CANCELADO',
  EM_CHAMADA: 'EM_CHAMADA',
  MARCACAO_RECORRENTE: 'MARCACAO_RECORRENTE',
  MARCACOES_FORA_HORARIO_MEDICO: 'MARCACOES_FORA_HORARIO_MEDICO',
  NAO_COMPARECEU: 'NAO_COMPARECEU',
  AGENDAMENTO_ONLINE: 'AGENDAMENTO_ONLINE',
  PERFIL: 'perfil',
  EXISTE_MARCACAO_HORARIO_EXCLUIDO: 'EXISTE_MARCACAO_HORARIO_EXCLUIDO',
  TIPO_HISTORICO_MARCACAO: 'TIPO_HISTORICO_MARCACAO',
  TIPO_HISTORICO_PACIENTE: 'TIPO_HISTORICO_PACIENTE',
  TIPO_HISTORICO_MEDICO: 'TIPO_HISTORICO_MEDICO',
  TIPO_HISTORICO_ESTABELECIMENTO_MEDICO:
    'TIPO_HISTORICO_ESTABELECIMENTO_MEDICO',
  CARTAO_CREDITO: 'CARTAO_CREDITO',
  BOLETO: 'BOLETO',
  RECEPCAO_INTERNA: 'RECEPCAO_INTERNA',
  AGUARDANDO_TRIAGEM: 'AGUARDANDO_TRIAGEM',
  //FIM ABILITY
  FILA_BLOQUEADA: 'BLOQUEADA',
  FILA_INATIVA: 'INATIVA',
  USO_INDEVIDO: 6,
  TIPO_FATURAMENTOS: [
    { name: 'ESTABELECIMENTO', description: 'Estabelecimento' },
    { name: 'COOPERADO', description: 'Cooperado' },
  ],
  TIPO_INTEGRACAO: [
    { name: 'NENHUMA', description: 'Nenhuma' },
    { name: 'SASC_CONNECT', description: 'SASC Connect' },
    { name: 'SASC', description: 'SASC' },
  ],
  NA_MARCACAO: 'NA_MARCACAO',
  NA_CONFIRMACAO: 'NA_CONFIRMACAO',
  NAO_OBRIGATORIO: 'NAO_OBRIGATORIO',

  TIPO_OBRIGATORIEDADE: [
    {
      name: 'NA_MARCACAO',
      description: 'Obrigatório na marcação do agendamento',
    },
    {
      name: 'NA_CONFIRMACAO',
      description: 'Obrigatório na confirmação do agendamento',
    },
    { name: 'NAO_OBRIGATORIO', description: 'Não obrigatório' },
  ],
  TIPO_HORARIO_FUNCIONAMENTO_ESTABELECIMENTO: 'ESTABELECIMENTO',
  TIPO_HORARIO_FUNCIONAMENTO_ESTABELECIMENTO_MEDICO: 'ESTABELECIMENTO_MEDICO',
  PARTES: [
    { nome: 'PARAFUSO', qtd: 8 },
    { nome: 'PINO', qtd: 8 },
    { nome: 'TERMINAL', qtd: 2 },
  ],
  TIPOS_SEXO: [
    { name: 'M', description: 'M' },
    { name: 'F', description: 'F' },
  ],
  UNIDADES: [
    { name: 'MILILITROS', description: 'Mililitros' },
    { name: 'GRAMAS', description: 'Gramas' },
    { name: 'UNITARIO', description: 'Unidade' },
  ],
  SIM_NAO: [
    { label: 'Não', value: false },
    { label: 'Sim', value: true },
  ],
  TEMPO_ATENDIMENTO: [
    { name: '15', description: '0:15', value: 15 },
    { name: '20', description: '0:20', value: 20 },
    { name: '30', description: '0:30', value: 30 },
    { name: '40', description: '0:40', value: 40 },
    { name: '45', description: '0:45', value: 45 },
    { name: '50', description: '0:50', value: 50 },
    { nome: '60', description: '1:00', value: 60 },
    { nome: '90', description: '1:30', value: 90 },
    { nome: '120', description: '2:00', value: 120 },
  ],

  REPETIR_HORARIO: [
    { name: 'SEMANAL', description: 'Toda semana', codigo: 7 },
    { name: 'QUINZENAL', description: 'A cada duas semanas', codigo: 14 },
    { name: 'MENSAL', description: 'Uma vez por mês', codigo: 30 },
  ],

  TIPO_DOCUMENTO: [
    { name: 'TEXT', description: 'Texto', component: FormInput },
    { name: 'CPF', description: 'CPF', component: CpfCnpjInput },
    { name: 'NUMBER', description: 'Número', component: FormInput },
    {
      name: 'DATE',
      description: 'Data',
      component: DatePickerInput,
      format: dateHelper.formatDate,
    },
    {
      name: 'TIME',
      description: 'Hora',
      component: TimePicker,
      format: dateHelper.getHourFormat,
    },
  ],

  TIPO_AGENDA: [
    { name: 'CONSULTAS', description: 'Consultas' },
    { name: 'EXAMES', description: 'Exames' },
  ],

  FORMA_PAGAMENTO: [
    { name: 'CARTAO_CREDITO', description: 'Cartão de crédito' },
    // { name: 'BOLETO', description: 'Boleto bancário' },
  ],

  MESES: [
    { description: 'Janeiro', name: '01' },
    { description: 'Fevereiro', name: '02' },
    { description: 'Março', name: '03' },
    { description: 'Abril', name: '04' },
    { description: 'Maio', name: '05' },
    { description: 'Junho', name: '06' },
    { description: 'Julho', name: '07' },
    { description: 'Agosto', name: '08' },
    { description: 'Setembro', name: '09' },
    { description: 'Outubro', name: '10' },
    { description: 'Novembro', name: '11' },
    { description: 'Dezembro', name: '12' },
  ],

  cnpjValidate: {
    name: 'cnpj',
    message: 'Campo obrigatório',
    type: 'cnpj',
    required: true,
  },

  HORARIO_INDISPONIBILIDADE: [
    { name: 'TODOS', description: 'Todos os horários' },
    { name: 'SELECIONAR', description: 'Selecionar horários' },
  ],

  TELEFONES: [
    { value: 'CELULAR_WHATSAPP', name: 'Whatsapp' },
    { value: 'CELULAR', name: 'Celular' },
    { value: 'COMERCIAL', name: 'Comercial' },
    { value: 'RESIDENCIAL', name: 'Residêncial' },
  ],
  TIPO_AGENDA_CONSULTAS: 'CONSULTAS',
  TIPO_AGENDA_EXAMES: 'EXAMES',
  TIPO_ATENDIMENTO_PARTICULAR: 'PARTICULAR',
  TIPO_ATENDIMENTO_CONVENIO: 'CONVENIO',
  TIPO_ATENDIMENTO_CORTESIA: 'CORTESIA',
  TIPO_CONSULTA_PRIMEIRA: 'PRIMEIRA',
  TIPO_CONSULTA_RETORNO: 'RETORNO',
  TIPO_CONSULTA_COMUM: 'COMUM',
  TIPO_MEDICAMENTO: 'medicamento',
  TIPO_MARCACAO: 'MARCACAO',
  TIPO_ENCAIXE: 'ENCAIXE',
  TIPO_FORMULA: 'formula',
  TIPO_LOGIN_ONLINE: 'ONLINE',
  TIPO_LOGIN_INTERNO: 'INTERNO',
  TIPO_EXAMES_OUTROS: 'examesOutros',

  TITULO_PAGINA_AGENDA: 'Agenda',
  TITULO_PAGINA_AGENDA_EXAMES: 'Agenda de exames',
  TITULO_AGENDA_ONLINE: 'Agendamento Online',
  TITULO_AGENDA_ONLINE_SUCESSO: 'Agendamento realizado',
  TITULO_PAGINA_PACIENTES: 'Pacientes',
  TITULO_PAGINA_PROFISSIONAIS: 'Profissionais de saúde',
  TITULO_PAGINA_USUARIOS: 'Usuários do sistema',
  TITULO_PAGINA_ESTABELECIMENTO: 'Estabelecimento',
  TITULO_PAGINA_EXAMES: 'Exames',
  TITULO_PAGINA_MODELOS: 'Modelos de documentos',
  TITULO_PAGINA_MODELOS_ANAMNESE: 'Modelos de anamnese',
  TITULO_PAGINA_MODELOS_RECEITUARIO: 'Modelos de receituário',
  TITULO_PAGINA_RELATORIOS_CONSULTA: 'Relatórios - Consultas',
  TITULO_PAGINA_RELATORIOS_EXAMES: 'Relatórios - Exames',
  TITULO_PAGINA_INTEGRACAO_SASCCONNECT: 'Integração Sascconnect',
  TITULO_PAGINA_PAGAMENTO: 'Pagamento',
  TITULO_VOUCHER_SOLUTI: 'Voucher Soluti',
  TITULO_DASHBOARD: 'Dashboard',
  TITULO_PAGINA_EMPRESAS: 'Empresas',
  TITULO_PAGINA_DASHBOARD: 'DashBoard Gerencial',
  TITULO_CONVENIO: 'Convênio',
  TITULO_DASHBORAD_FINANCEIRO: 'DashBoard Financeiro',
  TITULO_GESTAO_ESTOQUE: 'Gestão de Estoque',
  TITULO_ESTOQUE_PRODUTOS: 'Estoque: Produtos',
  TITULO_ESTOQUE_ENTRADA: 'Estoque: Entradas',
  TITULO_ESTOQUE_SAIDA: 'Estoque: Saídas',
  TITULO_ESTOQUE_REL_MOVIMENTACAO:
    'Estoque: Relatório de movimentação de estoque',
  TITULO_ESTOQUE_REL_POSICAO: 'Estoque: Relatório de posição de estoque',
  TITULO_ESTOQUE_AJUSTE: 'Estoque: Ajuste de estoque',

  // CRUD_ESTOQUE: 'CRUD_USUARIOS',
  MENU_ESTOQUE: 'MENU_USUARIO',
  TITULO_AGENDAMENTO_RAPIDO: 'Agendamento rápido',
  TITULO_PACOTES: 'Titulo pacotes',
  TITULO_DASHBORAD_FINANCEIRO: 'DashBoard Financeiro',
  TITULO_PARAMETRIZACAO_TRIAGEM: 'Parametrização triagem',
  TITULO_PARAMETRIZACAO_GERAL: 'Parametrização geral',

  TITULO_PAGINA_PACOTES: 'Pacotes',
  codeGoogle: 'AIzaSyCgj5WxP4p7O9K-9WZIry2zqdw4C2LbxIA',
  razaoSocialValidate: {
    name: 'razaoSocial',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  nomeFantasiaValidate: {
    name: 'nomeFantasia',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },

  inscricaoEstadualValidate: {
    name: 'inscricaoEstadual',
    message: 'Campo obrigatório',
    type: 'number',
    required: true,
  },

  inscricaoMunicipalValidate: {
    name: 'inscricaoMunicipal',
    message: 'Campo obrigatório',
    type: 'number',
    required: true,
  },
  emailComercialValidate: {
    name: 'emailComercial',
    message: 'Campo obrigatório',
    type: 'email',
    required: true,
  },
  telefoneComercialValidate: {
    name: 'telefoneComercial',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  // Address
  cepValidate: {
    name: 'cep',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  ruaValidate: {
    name: 'rua',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  numeroValidate: {
    name: 'numero',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  complementoValidate: {
    name: 'complemento',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  bairroValidate: {
    name: 'bairro',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  paisValidate: {
    name: 'pais',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  estadoValidate: {
    name: 'estado',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  cidadeValidate: {
    name: 'cidade',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  horaFuncionamentoValidate: {
    name: 'horaFuncionamento',
    message: 'Campo obrigatório',
    type: 'text',
    required: true,
  },
  messageCampoObrigatorio: 'Campos obrigatórios não foram preenchidos',
  messageSucesso: 'Registro salvo com sucesso.',
  messageError: 'Ops, desculpe houve um erro interno ao processar requisição.',
  defaultFilter: {
    status: 'true',
  },
  PAIS_DEFAULT: { id: 1, nome: 'Brasil' },
  ROLE_REPRESENTANTE_LEGAL: 'ROLE_REPRESENTANTE_LEGAL',
  VIEW: 'VIEW',
  REPORT: 'REPORT',
  ROLE_REPARAR_OS: 'ROLE_REPARAR_OS',
  CONSULTA_OS: 'CONSULTA_OS',
  PJ: 'PJ',
  PF: 'PF',
  MONTH_ARRAY: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  MENU_MODELO: 'MENU_MODELO',
  MENU_MODELO_ANAMNESE: 'MENU_MODELO_ANAMNESE',
  MENU_MODELO_RECEITUARIO: 'MENU_MODELO_RECEITUARIO',
  TIPO_INPUT_FORMULARIO: [
    { name: 'TEXTO', description: 'Texto' },
    { name: 'CPF', description: 'Cpf' },
    { name: 'DATA', description: 'Data' },
    { name: 'NUMERO', description: 'Númerico' },
    { name: 'NUMERO', description: 'Númerico' },
    { name: 'NUMERO', description: 'Númerico' },
  ],

  TIPO_ENTRADA_MANUAL: '1',
  TIPO_ENTRADA_NOTA_FISCAL: '0',
  TIPO_ENTRADA_IMPORTACAO_XML: 'IMPORTACAO_XML',
  TIPO_NOTA_FISCAL_MANUAL: 'MANUAL',
  TIPO_NOTA_FISCAL_ELETRONICA: 'ELETRONICA',

  TIPO_VENDA: 'VENDA',
  TIPO_AJUSTE: 'AJUSTE',
  TIPO_CONSUMO_INTERNO: 'CONSUMO_INTERNO',
  TIPO_TRANSFERENCIA: 'TRANSFERENCIA',
  TIPO_OUTRAS_SAIDAS: 'OUTRAS_SAIDAS',

  TIPO_COMPRA: 'COMPRA',
  TIPO_ENTRADA_AJUSTE: 'AJUSTE',
  TIPO_DEVOLUCAO_VENDA: 'DEVOLUCAO_VENDA',
  TIPO_ENTRADA_TRANSFERENCIA: 'TRANSFERENCIA',
  TIPO_ENTRADA_OUTROS: 'OUTROS',
  TIPO_SALDO_INICIAL: 'SALDO_INICIAL',
  TIPOS_SALDO_INICIAL: 'SALDO_INICIAL',
  TIPO_UNIDADE_PADRAO: 'PADRAO',
  TIPO_UNIDADE_FRACAO: 'FRACAO',
};

export const objectsEnuns = {
  TIPO_ENTRADA_NOTA_FISCAL: [
    { label: 'Manual', value: objectsConstants.TIPO_ENTRADA_MANUAL },
    { label: 'Nota Fiscal', value: objectsConstants.TIPO_ENTRADA_NOTA_FISCAL },
    {
      label: 'Importação do XML',
      value: objectsConstants.TIPO_ENTRADA_IMPORTACAO_XML,
    },
  ],

  TIPO_ENTRADA: [
    { label: 'Ajuste', value: objectsConstants.TIPO_ENTRADA_AJUSTE },
    { label: 'Compra', value: objectsConstants.TIPO_COMPRA },
    { label: 'Devolução venda', value: objectsConstants.TIPO_DEVOLUCAO_VENDA },
    {
      label: 'Transferência',
      value: objectsConstants.TIPO_ENTRADA_TRANSFERENCIA,
    },
    { label: 'Saldo inicial', value: objectsConstants.TIPO_SALDO_INICIAL },
    { label: 'Outras entradas', value: objectsConstants.TIPO_ENTRADA_OUTROS },
  ],

  TIPO_SAIDA_NOTA_FISCAL: [
    { label: 'Ajuste', value: objectsConstants.TIPO_AJUSTE },
    { label: 'Consumo interno', value: objectsConstants.TIPO_CONSUMO_INTERNO },
    {
      label: 'Transferência',
      value: objectsConstants.TIPO_TRANSFERENCIA,
    },
    { label: 'Venda', value: objectsConstants.TIPO_VENDA },
    { label: 'Outras saídas', value: objectsConstants.TIPO_OUTRAS_SAIDAS },
  ],

  TIPO_NOTA_FISCAL: [
    {
      label: 'Nota fiscal comum',
      value: objectsConstants.TIPO_NOTA_FISCAL_MANUAL,
    },
    {
      label: 'Nota fiscal eletrônica',
      value: objectsConstants.TIPO_NOTA_FISCAL_ELETRONICA,
    },
  ],
  SITUACAO_NOTA_FISCAL: [
    { label: 'Ativa', value: 'ATIVA' },
    { label: 'Cancelada', value: 'CANCELADA' },
  ],
  SITUACAO_PRODUTO: [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Inativo', value: 'INATIVO' },
    { label: 'Suspenso', value: 'SUSPENSO' },
  ],

  SITUACAO_ENTRADA_ESTOQUE: [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Inativo', value: 'INATIVO' },
    { label: 'Suspenso', value: 'SUSPENSO' },
  ],

  SITUACAO_SAIDA_ESTOQUE: [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Inativo', value: 'INATIVO' },
    { label: 'Suspenso', value: 'SUSPENSO' },
  ],
  TIPO_LANCAMENTO_ESTOQUE: [
    { label: 'Entradas', value: 'ENTRADAS' },
    { label: 'Saídas', value: 'SAIDAS' },
    { label: 'Entradas e Saídas', value: 'ENTRADAS_SAIDAS' },
  ],
  TIPO_ESTOQUE_QTD: [
    { label: 'Maior que', value: '>' },
    { label: 'Menor que', value: '<' },
    { label: 'Igual a', value: '=' },
  ],
  STATUS_INTEGRACAO: [
    { name: 'SUCESS', description: 'Sucesso' },
    { name: 'ERROR_TIMEOUT', description: 'Erro - Tempo limite ultrapassado' },
    { name: 'ERROR_AUTHENTICATION', description: 'Erro - Autenticação' },
    { name: 'ERROR_VALIDATION', description: 'Erro - Dados inválidos' },
    { name: 'ERROR_NETWORK', description: 'Erro - Conexão' },
    { name: 'ERROR_REFUSED', description: 'Erro - Recusada pelo receptor' },
    { name: 'ERROR_UNKNOWN', description: 'Erro - Desconhecido' },
    { name: 'PENDING', description: 'Aguarde - Transação pendente' },
    { name: 'IN_PROCESS', description: 'Aguarde - Em processamento' },
  ],

  TIPO_CONSULTA: [
    { name: 'PRIMEIRA', description: 'Primeira consulta', codigo: 0 },
    { name: 'COMUM', description: 'Comum', codigo: 1 },
    { name: 'RETORNO', description: 'Retorno', codigo: 2 },
  ],

  TIPO: [
    { name: 'MARCACAO', description: 'Marcação', codigo: 0 },
    { name: 'ENCAIXE', description: 'Encaixe', codigo: 1 },
  ],

  TIPO_ATENDIMENTO: [
    { name: 'CONVENIO', description: 'Convenio', codigo: 0 },
    { name: 'PARTICULAR', description: 'Particular', codigo: 1 },
    { name: 'CORTESIA', description: 'Cortesia', codigo: 1 },
  ],

  STATUS_CONSULTA: [
    { name: 'MARCADO', description: 'Marcado', codigo: 0 },
    { name: 'ATENDIDO', description: 'Atendido', codigo: 1 },
    { name: 'CANCELADO', description: 'Cancelado', codigo: 2 },
    {
      name: 'AGUARDANDO_ATENDIMENTO',
      description: 'Aguardando atendimento',
      codigo: 3,
    },
    { name: 'EM_ATENDIMENTO', description: 'Em atendimento', codigo: 4 },
    { name: 'EM_CHAMADA', description: 'Em chamada', codigo: 5 },
    { name: 'NAO_COMPARECEU', description: 'Não compareceu', codigo: 7 },
    {
      name: 'MARCACAO_RECORRENTE',
      description: 'Marcação recorrente',
      codigo: 13,
    },
  ],
};
