//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconPrecificacao from '../../../images/icon-precificacao.png';

export default class Precificacao extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="card-central d-flex flex-column flex-xl-row">
          <div className="col-12 col-xl-3 d-flex align-items-center justify-content-center">
            <img src={IconPrecificacao} alt="Imagem de agenda" />
          </div>
          <div className="col-12 col-xl-9 p-0">
            <h1>Precificação</h1>
            <p>
              Customize o preço das unidades para vendas para convênios
              especificos.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
