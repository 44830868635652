import React from 'react';
import moment from 'moment';
import './CountDownTime.scss'
import {dateHelper} from "../../_helpers/date.helper";

export default class CountdownTime extends React.Component {
	state = {
		days: undefined,
		hours: undefined,
		minutes: undefined,
		seconds: undefined
	};

	componentDidMount() {
		this.interval = setInterval(() => {
			const { dateEnvet } = this.props;

			let dateDiff = moment.duration(
				moment(dateEnvet).diff(         // instead of this.selectedEndDateTime
					moment(dateHelper.now())))

			const days = parseInt(dateDiff.days());
			const hours = parseInt(dateDiff.hours());
			const minutes = parseInt(dateDiff.minutes());
			const seconds = parseInt(dateDiff.seconds());

			this.setState({ days, hours, minutes, seconds });
		}, 1000);
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	render() {
		const { days, hours, minutes, seconds } = this.state;

		return (
			<>
				<div className="countdown-wrapper">
					{days>0 && <div className="countdown-item">
						{days}
						<span>Dias</span>
					</div>}
					<div className="countdown-item">
						{hours}
						<span>horas</span>
					</div>
					<div className="countdown-item">
						{minutes}
						<span>minutos</span>
					</div>
					<div className="countdown-item">
						{seconds}
						<span>segundos</span>
					</div>
				</div>
			</>
		);
	}
}


