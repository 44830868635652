//@flow
import _ from 'lodash';
type State = {
    historicoAberto: boolean,
    idEntityHistorico: null,
};

export default {
    state: { historicoAberta: false },
    reducers: {
        toggleHistorico: (state: State) => {
            console.log('ToogleHistorico');
            return Object.assign({}, state, {
                historicoAberta: !state.historicoAberto
            });
        },
        abreHistorico: (state: State,) => {
            console.log('abreHistorico');
            return Object.assign({}, state, {
                historicoAberto: true,
            });
        },
        fechaHistorico: (state: State) => {
            return Object.assign({}, state, {
                historicoAberto: false
            });
        },

        receiveIdEntityHistorico: (state, payload) => {

            console.log(payload);
            return Object.assign({}, state, {
                idEntityHistorico: _.get(payload, 'idEntityHistorico', ''),
                tipoHistorico: _.get(payload, 'tipoHistorico', ''),
            });
        },

    }
};
