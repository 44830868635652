//@flow
import { func } from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { objectsConstants } from '../_constants/objects.constants';
import { ToolTipHelp } from '../components/Utils/ToolTipHelp';

const HORAS_DELAY_ATENDIMENTO = 2;

export const cardHelper = {
  getBandeiraCartao,
};

function getBandeiraCartao(numeroCartao) {
  var valid = require('card-validator');
  if (numeroCartao) {
    let numCartao = numeroCartao.replace(/\D/g, '');
    var numberValidation = valid.number(numCartao);
    let bandeira = _.get(numberValidation, 'card.type');
    if (bandeira) {
      return bandeira;
    } else {
      return null;
    }
  }
}
