import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconPainel from "../../images/icon-tv-WHITE.svg";
import { objectsConstants } from '../../_constants/objects.constants';
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import { medicoService } from '../../_services/medico.service';
import FormSelectInput from "../inputs/FormSelectInput";
import InputViewEdit from "../inputs/InputViewEdit";
import { PacienteSelect } from '../inputs/PacienteSelect';

export class ModalWhatsapp extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }
  cancelForm = () => {
    this.props.toogle();
  };

  gerarNovoQRCode = () => {
    this.props.gerarQrCode();
  }

  concluirModal = () => {
    this.props.toogle();
    this.props.verificaStatusWhatsapp();
  }

  render() {
    const { toggle, isOpen, qrCode, ...otherProps } = this.props;
    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-anexos"
          centered={true}
          target={this.props.target}
        >
          <ModalBody>
            <div className="">
              <div className="row">
                <div className="col-12 text-center mb-2">
                  <h2 className="font-weight-bold">Sessão do Whatsapp expirada</h2>
                  <p>Acesse o aplicativo do seu celular e leia o QR Code abaixo para renovar a sessão.</p>
                </div>
              </div>
              <React.Fragment>
                <div className="text-center py-5">
                  <img
                    src={qrCode}
                    alt={"QR Code"}
                    className={`img-fluid`}
                    height={'200 px'}
                    id={"QRCode"}
                  />
                </div>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter className={'justify-content-around'}>
            <React.Fragment>
              <div className="text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.cancelForm();
                  }}
                >
                  {" "}
                  Cancelar{" "}
                </button>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-blue"
                  type={'button'}
                  onClick={() => {
                    this.gerarNovoQRCode();
                  }}
                >Gerar novo QR Code
                </button>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.concluirModal()
                  }}
                >Concluir
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
