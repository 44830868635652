//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const triagemService = {
  save,
  findByMarcacao
};

function save(triagem) {
  return defaultService.doSave(`${urlsConstants.TRIAGEM}`,triagem);
}

function findByMarcacao(idMarcacao){
  return defaultService.doGet(`${urlsConstants.TRIAGEM}findByMarcacao/${idMarcacao}`)
}