//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconFaturamento from '../../../images/icon-faturamento.png';

export default class EstoqueFaturamento extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="card-central d-flex flex-column flex-xl-row">
          <div className="col-12 col-xl-3 d-flex align-items-center justify-content-center">
            <img src={IconFaturamento} alt="Imagem de agenda" />
          </div>
          <div className="col-12 col-xl-9 p-0">
            <h1>Estoque e Faturamento</h1>
            <p>
              Insira as unidades de armazenamento e utilização deste produto,
              seja venda ou uso interno.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
