//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const reservarHorarioService = {
  doSave,
  findByDataHoraEstabe,
  doDelete,
};

function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.RESERVAR_HORARIO}${id}`);
}

function doSave(indisponibilidade: any) {
  return defaultService.doSave(
    urlsConstants.RESERVAR_HORARIO,
    indisponibilidade
  );
}

function findByDataHoraEstabe(horarioReservado) {
  return defaultService.doPost(
    `${urlsConstants.RESERVAR_HORARIO}findByDataHoraEstabe`,
    horarioReservado
  );
}
