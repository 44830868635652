//@flow
import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dateHelper } from '../../_helpers/date.helper';
import { marcacaoService } from '../../_services/marcacao.service';
import moment from 'moment';
import 'moment/locale/pt-br';
import VideoConference from '../../components/Jitsi/VideoConference';
import CountdownTime from './CountDownTime';

type Props = {};

type State = {};

let callFrame;
let interValItem;

class TelemedicinaPacientePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      marcacao: null,
      cronometro: null,
    };
    this.videoConference = React.createRef();
  }

  componentDidMount() {
    const { token } = this.props.match.params;

    marcacaoService.findByToken(token).then((result) => {
      let marcacao = result.data.data;
      this.setState({ marcacao }, () => {
        this.setCountDount(marcacao);
      });
    });
  }

  setCountDount = (marcacao) => {
    let _this = this;
    let interval = 1000;
    let timer = setInterval(function () {
      let tempoRestante = moment
        .duration(
          moment(marcacao.dataHoraConsulta).diff(
            // instead of this.selectedEndDateTime
            moment(dateHelper.now())
          )
        )
        .asSeconds();
      console.log('setInterval', tempoRestante);
      _this.setState({ tempoRestante });
      if (tempoRestante <= 0) clearInterval(timer);
    }, interval);
  };

  render() {
    const { marcacao, tempoRestante } = this.state;
    console.log('cronometro', tempoRestante);
    return (
      <React.Fragment>
        {marcacao && tempoRestante > 0 && (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <p className={'alert alert-info '}>
              Atendimento iniciará em:{' '}
              <CountdownTime dateEnvet={marcacao.dataHoraConsulta} />{' '}
            </p>
          </div>
        )}

        <div className="container-fluid d-flex h-100 back-elements">
          <div className="container d-flex justify-content-center align-items-center ">
            {marcacao && tempoRestante > 0 && (
              <p className={'alert-absolute alert-danger'}>
                Está sala ainda não está disponível{' '}
              </p>
            )}
            <div className={'card telemedicina'}>
              {marcacao && tempoRestante <= 0 && (
                <VideoConference
                  subject={'Tele Consulta'}
                  roomName={marcacao.nomeSala}
                  userName={_.get(marcacao, 'paciente.nome')}
                  userEmail={_.get(marcacao, 'paciente.email')}
                  ref={this.videoConference}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;

  return {
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
});

export default connect(mapStateToProps, mapDispatch)(TelemedicinaPacientePage);
