//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from './../../components/inputs/FormInput';
// import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
// import { colaboradoresHelper } from '../../helpers/colaboradores.helper';
// import RoleSelectInput from '../../components/Inputs/RoleSelectInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class MedicoListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="Nome do profissional de saúde"
            placeholder={'Nome'}
            id={'namefilter'}
            name="nome"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4 offset-sm-1'}
            value={entity.nome}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="E-mail"
            placeholder={'E-mail'}
            id={'email'}
            name="email"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4'}
            value={entity.email}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <FormInput
            label="Número do conselho"
            placeholder={'Número do conselho'}
            id={'numeroConselho'}
            name="numeroConselho"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-6 col-sm-2'}
            value={entity.numeroConselho}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
