import _ from "lodash";
import * as React from "react";
import { FormGroup } from "react-bootstrap";
import FormInput from "../../components/inputs/FormInput";
import InputViewEdit from "../../components/inputs/InputViewEdit";

export default class TriagemClassificacaoInputs extends React.Component<{}> {
  constructor(props) {
    super(props);
  }
    calculateIMC() {
      let{entity}=this.props;
        let weight = _.get(entity,'peso');
        let height = _.get(entity,'altura');
        if(weight&&height) {
            return Number((weight / (height * height))).toFixed(6);
        }else{
            return '--';
        }
    }
  render() {
    const { entity,viewMode} = this.props;
    return (
      <div className="row section-form">
        <FormGroup className="col-12 col-lg-3">
          <InputViewEdit
            component={FormInput}
            type="number"
            name="temperaturaCorporal"
            value={_.get(entity, "temperaturaCorporal")}
            label="Temperatura Corporal"
            placeholder="Temperatura"
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>

        <FormGroup className="col-12 col-lg-3">
          <InputViewEdit
            component={FormInput}
            type="number"
            name="frequenciaCardiaca"
            value={_.get(entity, "frequenciaCardiaca")}
            label="Freq. Cardíaca"
            placeholder="Frequência Cardíaca"
            onChange={this.props.setFieldValue}
            noSize={true}

            viewMode={viewMode}

          />
        </FormGroup>
        <FormGroup className="col-12 col-lg-3">
          <InputViewEdit
            component={FormInput}
            type="number"
            name="frequenciaRespiratoria"
            value={_.get(entity, "frequenciaRespiratoria")}
            label="Freq. Respiratória"
            placeholder="Frequência Respiratória"
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>
        <FormGroup className="col-12 col-lg-3">
          <InputViewEdit
            component={FormInput}
            type="number"
            name="circunferenciaAbdominal"
            value={_.get(entity, "circunferenciaAbdominal")}
            label="Circunferência Abdominal"
            placeholder="Digite a circunferência abdominal"
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>

        <FormGroup className="col-12 col-lg-3">
          <InputViewEdit
            component={FormInput}
            type="number"
            label="Oximetria de Pulso"
            name="sp02"
            value={_.get(entity, "sp02")}
            placeholder="Saturação"
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>
          <FormGroup className="col-12 col-lg-3">
              <InputViewEdit
                  component={FormInput}
                  type="number"
                  label="Pressão arterial"
                  name="pressaoArterial"
                  value={_.get(entity, "pressaoArterial")}
                  placeholder="Digite a pressão"
                  onChange={this.props.setFieldValue}
                  noSize={true}
                  viewMode={viewMode}
              />
          </FormGroup>
        <FormGroup className="col-12 col-lg-2">
          <InputViewEdit
            component={FormInput}
            type="number"
            label="Peso"
            name="peso"
            value={_.get(entity, "peso")}
            placeholder="Digite o peso"
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>
        <FormGroup className="col-12 col-lg-2">
          <InputViewEdit
            component={FormInput}
            type="number"
            name="altura"
            value={_.get(entity, "altura")}
            label="Altura"
            placeholder="Digite a altura"
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>
        <FormGroup className="col-12 col-lg-2">
          <InputViewEdit
            component={FormInput}
            type="number"
            name="imc"
            label="IMC"
            placeholder="IMC"
            value={_.get(entity, "imc",this.    calculateIMC()
                )}
            onChange={this.props.setFieldValue}
            noSize={true}
            viewMode={viewMode}
          />
        </FormGroup>
      </div>
    );
  }
}
