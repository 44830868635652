//@flow
import 'moment/locale/pt-br';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from '../../../components/header/Header';
import HeaderProntuario from './HeaderProntuario';
import AnamneseDadosForm from '../AnamneseDadosForm';
import ReceituarioForm from './ReceituarioForm';
import ReceituarioExamesProntuarioForm from './ReceituarioExamesProntuarioForm';
import CheckBoxInputOnly from '../../../components/inputs/CheckBoxInputOnly';
type Props = {
  dataHora: any,
  newDiv?: boolean,
  onCancelar: any,
  onEdit?: any,
  isAnamneseEditing: boolean,
  dispatch: any,
};

class TabelaDetalheProntuario extends React.Component<Props, {}> {
  delete = (index) => {
    const { values, setFieldValue, name } = this.props;
    let newArray = _.cloneDeep(_.get(values, name, []));
    newArray.splice(index, 1);
    setFieldValue(name, newArray);
  };

  onChangePrint = (index, checked) => {
    const { values, setFieldValue, name } = this.props;
    let newArray = _.cloneDeep(_.get(values, name, []));
    _.set(newArray[index], 'imprimir', checked);
    setFieldValue(name, newArray);
  };

  render() {
    let { values, name, label, printed, description, viewMode, quantidade } =
      this.props;
    return (
      <div className={'tabela-prontuario my-2 '}>
        {_.get(values, name, []).map((m, index) => {
          return (
            <div className={'row p-1 w-100 m-0'} key={index * 3}>
              <div className={'col-10 d-flex align-items-center'}>
                {_.get(m, label)}{' '}
                {_.get(m, quantidade) ? ' : Qtd: ' + _.get(m, quantidade) : ''}
                {_.get(m, description)
                  ? ' Just.: ' + _.get(m, description)
                  : ''}
              </div>
              <div
                className={
                  'col-2 d-flex justify-content-end align-items-center'
                }
              >
                <div className="form-check form-check-inline">
                  <React.Fragment key={index}>
                    <CheckBoxInputOnly
                      checked={m.imprimir}
                      name={label}
                      label={''}
                      onChange={(checked, name) => {
                        this.onChangePrint(index, checked);
                      }}
                    ></CheckBoxInputOnly>
                  </React.Fragment>
                  {!viewMode && (
                    <span
                      className={'icon-delete mx-2'}
                      onClick={() => {
                        this.delete(index);
                      }}
                    ></span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {_.get(values, name, []).length == 0 && (
          <div className={'row p-1 w-100 m-0'}>
            <div className={'col-10'}>Nenhum item inserido </div>
            <div className={'col-2 d-flex justify-content-end'}>
              {/*   <span className={'icon-edit mx-2'} onClick={()=>{this.props.edit(index)}}></span>*/}
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { isAnamneseEditing } = state.anamnese;

  return {
    isAnamneseEditing,
  };
}

export default connect(
  mapStateToProps,
  null,
  null
)(withRouter(TabelaDetalheProntuario));
