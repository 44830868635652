//@flow
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import AgendamentoRapidoPage from './AgendamentoRapidoPage';




export default class AgendamentoRapidoIndexPage extends React.Component<{}> {
  render() {
        return (
      <Switch>
        <Route path="/agendamento-rapido" component={AgendamentoRapidoPage} exact />
      </Switch>
    );
  }
}
