import { Formik } from "formik";
import _ from 'lodash';
import React, { PureComponent } from "react";
import { FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import { objectsConstants } from "../../_constants/objects.constants";
import { inputHelper } from "../../_helpers/input.helper";
import { modeloService } from "../../_services/modelo.service";
import saveProtocolo from "../../images/icon-save.svg";
import DatePickerInput from "../inputs/DatePickerInput";
import FormSelectInput from "../inputs/FormSelectInput";
import FormTextCKEditor from "../inputs/FormTextCKEditor";
import InputViewEdit from "../inputs/InputViewEdit";
import TimePicker from "../inputs/TimePicker";
import { ModalSolutiOtp } from "./ModalSolutiPasswordOtp";

const GerarDocumentoValidate = Yup.object().shape({

});

type Props = {
	entity: any
};

// type State = {
// 	marcacao: any,
// };

const modifiers = {
	preventOverflow: {
		enabled: false
	},
	flip: {
		enabled: false
	}
};



export class ModalGerarDocumentos extends PureComponent<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			showCorpoModelo: false,
			openSolutiOtp: false,
			documentSignVO: {},
			generateAndSendEmail: false,
			listModeloDocumento: [{}]
		}

	}

	cancelar = () => {
		this.props.toogle();
		this.setState({ showCorpoModelo: false })
	};

	selecionouModelo = (modelo, setFieldValue) => {
		const { marcacao } = this.props;
		let documentoPrint = {};
		_.set(documentoPrint, 'marcacaoVO', marcacao);
		_.set(documentoPrint, 'documentoVO', modelo);

		modeloService.textoModeloSubstituido(documentoPrint).then(
			(response) => {
				let textoCorpoEditado = response.data;
				this.setState({ showCorpoModelo: true, textoCorpoEditado: textoCorpoEditado, textoCorpoOriginal: textoCorpoEditado });
				setFieldValue('textoCorpoEditado', textoCorpoEditado)
			},
			(error) => {
				console.log(error);
			}
		);

	}

	handleSetOtpCode = (otpCode) => {
		if (otpCode) {
			if (!/^[0-9]+$/.test(otpCode)) {
				this.props.error({
					message: 'O código OTP são somente números',
				});
			} else {
				let tipo = _.get(this.formRef.state.values, 'tipoDocumento');
				this.state.documentSignVO = { otpCode: otpCode, signDocument: true, fileType: tipo.name };
				this.toggleSolutiOtp();
				this.formRef.handleSubmit();
			}
		} else {
			this.props.error({
				message: 'Insira o seu código otp',
			});
		}
	}

	generateDocumentAndSign = (sendEmail: boolean) => {
		let values = this.formRef.state.values;
		let setFieldError = this.formRef.setFieldError;
		this.setState({ generateAndSendEmail: sendEmail });
		this.props.loading(true);
		if (!_.get(values, 'documentoVO')) {
			this.props.loading(false);
			setFieldError('documentoVO', `Selecione um modelo de documento`);
		} else if (!_.get(values, 'tipoDocumento')) {
			this.props.loading(false);
			setFieldError('tipoDocumento', `Selecione um tipo de documento`);
		} else {
			if (this.props.entity.medico.signerAuthenticationIsValid) {
				let tipo = _.get(values, 'tipoDocumento');
				this.state.documentSignVO = { signDocument: true, fileType: tipo.name };
				this.formRef.handleSubmit();
			} else {
				this.props.loading(false);
				this.toggleSolutiOtp();
			}
		}
	}
	// signAndSendEmail = () => {
	// 	this.setState({ generateAndSendEmail: true });
	// 	this.props.loading(true);
	// 	if (!_.get(this.formRef.state.values, 'documentoVO')) {
	// 		this.props.loading(false);
	// 		this.props.error({
	// 			message: 'Selecione um tipo de documento',
	// 		});
	// 	} else if (!_.get(this.formRef.state.values, 'tipoDocumento')) {
	// 		this.props.loading(false);
	// 		this.props.error({
	// 			message: 'Selecione um tipo de documento',
	// 		});
	// 	} else {
	// 		if (this.props.entity.medico.signerAuthenticationIsValid) {
	// 			let tipo = _.get(this.formRef.state.values, 'tipoDocumento');
	// 			this.state.documentSignVO = { signDocument: true, fileType: tipo.name };
	// 			this.formRef.handleSubmit();
	// 		} else {
	// 			this.props.loading(false);
	// 			this.toggleSolutiOtp();
	// 		}
	// 	}
	// }

	generateDocument = () => {
		this.props.loading(true);
		if (!_.get(this.formRef.state.values, 'documentoVO')) {
			this.props.loading(false);
			this.props.error({
				message: 'Selecione um tipo de documento',
			});
		} else {
			this.formRef.handleSubmit();
		}
	}



	toggleSolutiOtp = () => {
		this.setState(({ openSolutiOtp }) => ({ openSolutiOtp: !openSolutiOtp }));
	};

	treatSignerSignException = (error) => {
		if (this.state.documentSignVO.signDocument && error && error.response && error.response.data) {
			this.props.error({
				message: this.treatSignerSignExceptionGetMessage(error.response.data),
			});
		}
	}

	treatSignerSignExceptionGetMessage = (data) => {
		console.log(data);
		if ("9001" === data.errorCode) {
			return "Não foi possível assinar o documento por falta de informações. Complete seu cadastro completo para assinar o documento corretamente. (Endereço, Telefone, Conselho).";
		} else if ("9002" === data.errorCode) {
			return "Seu código OTP não confere. Por favor valide corretamente o mesmo, junto com seu tempo de expiração e tente novamente.";
		} else {
			return "Erro desconhecido ao assinar o seu documento. Por favor tente novamente em instantes, caso o erro persista entre em contato com o administrador do sistema.";
		}
	}

	changeInputFormulario = (values) => {

		let textoCorpoOriginal = _.cloneDeep(this.state.textoCorpoOriginal);
		for (let input of values.documentoVO.formulario) {
			let value = _.get(values, input.nome);
			if (value && value != "") {
				let find = `_${input.nome.replace(/\s/g, "_").toUpperCase()}_`
				let re = new RegExp(find, 'g');
				let format = inputHelper.getFormat(input);
				if (format) {
					textoCorpoOriginal = textoCorpoOriginal.replace((re), format(_.get(values, input.nome)));
				} else {

					textoCorpoOriginal = textoCorpoOriginal.replace((re), _.get(values, input.nome));
				}
			}
		}
		this.setState({ textoCorpoEditado: textoCorpoOriginal })
	}


	formEstaValido = (inputForms, values) => {
		let setFieldError = this.formRef.setFieldError;
		let valido = true;
		for (let i = 0; i < inputForms.length; i++) {
			const iForm = inputForms[i];
			if (iForm.obrigatorio == true && !_.get(values, iForm.nome)) {
				setFieldError(iForm.nome, 'Campo Obrigatório')
				valido = false
			}
		}
		return valido;
	}

	render() {
		const { entity, callbackOnSave, marcacao } = this.props;
		const { showCorpoModelo, textoCorpoEditado, listModeloDocumento } = this.state;
		let _this = this;
		return (
			<React.Fragment>
				<Modal
					isOpen={this.props.isOpen}
					toggle={this.props.toggle}
					backdrop="static"
					modalClassName=""
					className="modal-anexos modal-xl"
					centered={true}
					modifiers={modifiers}
					target={this.props.target}
				>
					<Formik
						validationSchema={GerarDocumentoValidate}
						validateOnBlur={false}
						validateOnChange={false}
						enableReinitialize={true}
						initialValues={{}}
						onSubmit={(values) => {
							this.props.loading(true);
							let inputForms = _.get(values, 'documentoVO.formulario');
							if (this.formEstaValido(inputForms, values)) {
								_.set(values, 'documentSignVO', this.state.documentSignVO);
								_.set(values, 'marcacaoVO', marcacao);
								_.set(_.get(values, 'documentoVO'), 'textoCorpo', _.get(values, 'textoCorpoEditado'));
								let newValues = _.pick(values, ['documentoVO', 'marcacaoVO', 'documentSignVO']);

								if (!this.state.generateAndSendEmail) {
									modeloService.imprimir(newValues).then(
										(response) => {
											var base64 = _.get(response, 'data.data');
											if (callbackOnSave) {
												callbackOnSave({ tamanho: (base64.length * (3 / 4)), data: base64, midiaConteudoVO: { foto64: base64 }, nome: _.get(values, 'documentoVO.nome'), tipo: 'application/pdf' })
											}
											let asciiString = atob(base64);
											let array = new Uint8Array(
												[...asciiString].map((char) => char.charCodeAt(0))
											);
											const file = new Blob([array], { type: 'application/pdf' });
											const fileURL = URL.createObjectURL(file);
											this.props.toogle();
											this.setState({ showCorpoModelo: false })
											window.open(fileURL);
											this.props.loading(false);
											this.props.entity.medico.signerAuthenticationIsValid = this.state.documentSignVO ? this.state.documentSignVO.signDocument : false;
										},
										(error) => {
											console.log(error);
											this.props.loading(false);
											this.treatSignerSignException(error);
										}
									);
								} else {
									modeloService.generateDocumentAndSendEmail(newValues).then(
										(response) => {
											this.props.loading(false);
											this.props.success({
												message: "Documento enviado com sucesso por e-mail. Por favor aguarde em até no máximo 1 (uma) hora para receber o e-mail."
											});
											this.props.entity.medico.signerAuthenticationIsValid = this.state.documentSignVO ? this.state.documentSignVO.signDocument : false;
											this.cancelar();
										},
										(error) => {
											console.log(error);
											this.props.loading(false);
											this.treatSignerSignException(error);
										}
									);
								}
							} else {
								this.props.loading(false);
								this.props.error({ message: 'Formulário contém erro(s)!' })
							}
						}}
						ref={form => {
							this.formRef = form;
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							setFieldError,
							validationSchema,
							validateForm,
							setValues
						}) => (


							<form form onSubmit={handleSubmit}>
								<ModalBody>
									<div className="">
										<div className="row">
											<div className="col-12 text-center iconProtocolo">
												<img src={saveProtocolo} alt="anexo" />
											</div>
											<div className="col-12 text-center mb-2">
												<h2 className="font-weight-bold">Gerar documento com modelo</h2>
											</div>

										</div>

										<React.Fragment>
											<div className="form-row">
												<div className="col-12">
													<InputViewEdit
														component={FormSelectInput}
														label={'Selecione o modelo do documento'}
														id="documentoVO"
														name="documentoVO"
														placeholder="Selecione um modelo"
														value={values.documentoVO}
														onChange={(name, value) => {
															this.selecionouModelo(value, setFieldValue);
															setFieldValue(name, value)
															setFieldError(name, null)
														}}
														noSize={true}
														required={true}
														erroMensagem={_.get(errors, 'documentoVO')}
														service={modeloService.findSemReceituario}
														defaultValue={_.get(values, 'documentoVO')}
														labelKey={'nomeListagem'}
														valueKey={'id'}
														returnFullObject={true}
														parent={_.get(entity, 'marcacao.estabelecimento.id')}
													/>
												</div>
												{values.documentoVO && values.documentoVO.formulario && (
													values.documentoVO.formulario.map((input, index) => {
														let component = inputHelper.getComponent(input);
														return (
															<FormGroup className="col-12 col-lg-4">
																<InputViewEdit
																	component={component}
																	label={input.nome}
																	type={input.tipo}
																	name={input.nome}
																	id={`listModeloDocumento${index}nomeInput`}
																	placeholder={input.nome}
																	value={_.get(values, input.nome)}
																	onChange={(name, value) => {
																		setFieldValue(name, value);
																		values.documentoVO.formulario[index].value = value;
																		if (component == DatePickerInput || component == TimePicker) {
																			let newValues = _.cloneDeep(values);
																			newValues[name] = value;
																			this.changeInputFormulario(newValues)
																		}
																		setFieldError(name, null)
																	}}
																	erroMensagem={_.get(errors, input.nome)}
																	required={input.obrigatorio}
																	onBlur={() => { this.changeInputFormulario(values) }}
																/>
															</FormGroup>
														)
													}))}
												{showCorpoModelo && (
													<>
														<FormGroup className="col-12 col-lg-12 mt-5">
															<FormTextCKEditor
																label="Corpo da mensagem"
																id={'textoCorpoEditado'}
																name={'textoCorpoEditado'}
																className={'textCKeditor'}
																type={'textarea'}
																required={true}
																onChange={setFieldValue}
																viewClassName={'view-anamnese'}
																value={textoCorpoEditado}
																defaultValue={textoCorpoEditado}
																placeholder="Insira aqui as informações do modelo"
																removePlugins={['Heading', 'BlockQuote', 'ImageUpload', 'MediaEmbed']}
															/>
														</FormGroup>
														<FormGroup className="col-12 col-lg-12">
															<InputViewEdit
																component={FormSelectInput}
																label={'Tipo de Documento'}
																id="tipoDocumento"
																name="tipoDocumento"
																placeholder="Tipo de documento"
																value={_.get(values, 'tipoDocumento')}
																onChange={(name, value) => {
																	setFieldValue(name, value)
																	setFieldError(name, null)
																}}
																noSize={true}
																erroMensagem={_.get(errors, 'tipoDocumento')}
																required={true}
																returnFullObject={true}
																valueKey={'name'}
																labelKey={'description'}
																defaultValue={_.get(
																	values,
																	'tipoDocumento.description',
																	' '
																)}
																multi={false}
																options={objectsConstants.TIPOS_DOCUMENTOS_ASSINADOS}
															/>
														</FormGroup>
													</>
												)}
												{this.props.entity.medico.hasPermissionSigner && (
													<ModalSolutiOtp id="solutiOtp" name="solutiOtp" toogle={this.toggleSolutiOtp} isOpen={this.state.openSolutiOtp} handleSetOtpCode={(otpCode) => { this.handleSetOtpCode(otpCode); }} />
												)}
											</div>
										</React.Fragment>
									</div>
								</ModalBody>
								<ModalFooter>
									<React.Fragment>
										<div className="w-25 text-center">
											<button
												className="btn btn-secondary"
												type="button"
												onClick={() => {
													this.cancelar();
												}}
											>
												Cancelar
											</button>
										</div>
										<div className="w-75 text-center">
											<button
												className="btn btn-primary"
												type="button"
												onClick={() => {

													this.setState({ generateAndSendEmail: false });
													this.state.documentSignVO = { signDocument: false };
													// this.generateDocument();
													this.formRef.handleSubmit();
												}}

											>

												Gerar Documento
											</button>
											{this.props.entity.medico.hasPermissionSigner && (
												<button
													className="btn btn-primary"
													type="button"
													onClick={() => {
														this.generateDocumentAndSign(false);
													}}

												>

													Gerar Documento Assinado
												</button>
											)}
											{this.props.entity.medico.hasPermissionSigner && this.props.entity.paciente.email && (
												<button
													className="btn btn-primary"
													type="button"
													onClick={() => {
														this.generateDocumentAndSign(true);
													}}
												>
													Assinar documento e enviar por e-mail.
												</button>
											)}
										</div>
									</React.Fragment>
								</ModalFooter>
							</form>
						)}
					</Formik>
				</Modal>
			</React.Fragment >
		);
	}
}





