//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'reactstrap';
import { AgendaOnlineHeader } from './AgendaOnlineHeader';
import { marcacaoService } from '../../_services/marcacao.service';
import { urlsConstants } from '../../_constants/urls.constant';
import { objectsConstants } from '../../_constants/objects.constants';
import SockJS from 'sockjs-client';
import webstomp from 'webstomp-client';
import _ from 'lodash';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { dateHelper } from '../../_helpers/date.helper';

class AgendaOnlineCancelamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marcacao: {},
      cancelled: false,
      notFoundOrCancelled: false,
      tokenCancelamento: null,
    };
  }

  componentDidMount() {
    this.verificaCancelamento();
  }

  desmarcarConsulta = () => {
    const { tokenCancelamento } = this.state;
    if (tokenCancelamento) {
      marcacaoService.cancelarMarcacao(tokenCancelamento).then(
        (response) => {
          console.log(response);
          this.setState({ cancelled: true });
        },
        (error) => {
          console.log(error);
          this.props.error({
            message:
              'Não foi possível cancelar o agendamento, tente novamente mais tarde',
          });
        }
      );
    } else {
      this.props.error({
        message:
          'Não foi possível cancelar o agendamento, tente novamente mais tarde',
      });
    }
  };

  verificaCancelamento = () => {
    let tokenCancelamento = this.props.match.params.tokenCancelamento
      ? this.props.match.params.tokenCancelamento
      : null;

    if (tokenCancelamento) {
      marcacaoService.findByToken(tokenCancelamento).then(
        (response) => {
          console.log(response.data.data);
          let marcacaoResponse = response.data.data;
          if (marcacaoResponse.status != 'CANCELADO') {
            this.setState({
              marcacao: marcacaoResponse,
              tokenCancelamento,
            });
          } else {
            this.setState({
              notFoundOrCancelled: true,
            });
          }
        },
        (error) => {
          this.setState({
            notFoundOrCancelled: true,
          });
        }
      );
    }
  };

  render() {
    const { marcacao, cancelled, notFoundOrCancelled } = this.state;

    return (
      <div className="container-fluid d-flex flex-column h100 back-elements">
        <div className="background-lines">
          <div className="bg-riscos"></div>
        </div>
        {notFoundOrCancelled ? (
          <>
            <header className="d-flex justify-content-center align-items-center mb-4 mt-3">
              <div className="logo-sasc-cm"></div>
            </header>
            <div className="d-flex justify-content-center align-items-center mb-4 mt-3">
              <p style={{ fontSize: '18px' }}>
                <b>Agendamento não encontrado ou já está cancelado.</b>
              </p>
            </div>
          </>
        ) : cancelled && !notFoundOrCancelled ? (
          <>
            <header className="d-flex justify-content-center align-items-center mb-4 mt-3">
              <div className="logo-sasc-cm"></div>
            </header>
            <div className="d-flex justify-content-center align-items-center mb-4 mt-3">
              <p style={{ fontSize: '22px' }}>
                <b>Agendamento desmarcado</b>
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontSize: '18px' }}>
                Uma confirmação do cancelamento foi enviada para o email
                cadastrado!
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-1">
              <p style={{ fontSize: '14px' }}>
                Caso não receba o email de confirmação da desmarcação, entre em
                contato com o estabelecimento.
              </p>
            </div>
          </>
        ) : (
          <>
            <header className="d-flex justify-content-center align-items-center mb-4 mt-3">
              <div className="logo-sasc-cm"></div>
            </header>
            <div className="d-flex justify-content-center align-items-center mb-1 mt-3">
              <p style={{ fontSize: '22px' }}>
                <b>Paciente:</b> {_.get(marcacao.paciente, 'nome')}
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p style={{ fontSize: '18px' }}>
                <b> Dia:</b>{' '}
                {dateHelper.format(_.get(marcacao, `dataConsulta`), {
                  mode: 'DATE',
                })}{' '}
                às {marcacao.hora}{' '}
              </p>
              <p style={{ fontSize: '18px' }}>
                <b>Profissional:</b> {_.get(marcacao.medico, 'nome')}
              </p>
              <p style={{ fontSize: '18px' }}>
                <b> Especialidade:</b> {_.get(marcacao.especialidade, 'nome')}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <button
                className="bt-size-fixed mt-4 my-xl-4 btn btn-danger"
                onClick={(_e) => {
                  ConfirmAcationService.confirmMsg(
                    () => {
                      this.desmarcarConsulta(marcacao.id);
                    },
                    'Desmarcar atendimento',
                    <span>
                      Deseja realmente desmarcar esse atendimento para{' '}
                      <b>{_.get(marcacao.paciente, 'nome')}</b> no dia{' '}
                      <b>
                        {dateHelper.format(marcacao.dataConsulta, {
                          mode: 'DATE',
                        })}
                      </b>{' '}
                      às <b>{marcacao.hora}</b>?
                    </span>,
                    <span className="text-danger">{}</span>,
                    'Não',
                    'Sim',
                    objectsConstants.TYPE_CANCEL
                  );
                }}
                type="button"
              >
                Desmarcar
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatch = ({
  authentication: { logout },
  webSocket: { conectSocket, disconectSocket },
  alert: { success, error, clear },
}) => ({
  logout: () => logout(),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
  clear: () => clear(),
  conectSocket: (clientScoket) => conectSocket(clientScoket),
  disconectSocket: () => disconectSocket(),
});

function mapStateToProps(state) {
  const { estabelecimento } = state.agendaOnline;
  const { loggedIn } = state.authentication;
  const { client } = state.webSocket;
  return {
    estabelecimento,
    loggedIn,
    client,
  };
}

export default connect(mapStateToProps, mapDispatch)(AgendaOnlineCancelamento);
