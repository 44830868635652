//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import LocaleHorarioAtendimento from '../../components/LocaleHorarioAtendimento/LocaleHorarioAtendimento';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { medicoService } from '../../_services/medico.service';
import MedicoForm from './MedicoForm';

const defaultArrayItem = [{ idList: new Date().getTime() }];
const defaultEntity: medico = {
  nome: '',
  cpfCnpj: '',
  listHorarioAtendimento: defaultArrayItem,
  listConfiguracaoSala: defaultArrayItem,
  listConvenio: defaultArrayItem,
  telefones: [{ numero: '', tipo: 'CELULAR' }],
};

class MedicoEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  // goToViewEntity = (values) => {
  //   this.props.history.push({
  //     pathname: '/medico/view/' + values.id,
  //     state: { entity: values },
  //   });
  // };

  handleCancel = () => {
    console.log('cancel medico');
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      medicoService.doGet(this.props.match.params.id).then(
        (response) => {
          let medico = response.data.data;

          this.setState({ entity: medico }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  render() {
    let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        <TopoTitleComponente
          mainTitle={`${
            entity.id ? 'Editar ' : 'Novo '
          }  profissional de saúde`}
          canBack={true}
          backConfirmObject={{
            title: `Cancelar ${!entity.id ? 'criação' : 'Edição'} `,
            message:
              'Ao clicar em sim, todos os dados inseridos serão perdidos. Você deseja realmente ' +
              `cancelar a ${
                !entity.id ? 'criação' : 'Edição'
              } do profissional?`,
            subMessage: null,
            type: objectsConstants.TYPE_CANCEL,
          }}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>
        <div className="flex-column">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_MEDICO}
            title="Profissional de saúde"
            Componente={MedicoForm}
            viewMode={false}
            entity={entity}
            redirectPath="/medico/"
            onCancel={this.handleCancel}
            {...otherPops}
          ></FullCustomCard>
        </div>
        {entity.id && (
          <div className="flex-column">
            <FullCustomCard
              permissionSave={objectsConstants.MENU_MEDICO}
              title="Local e horários de atendimento"
              Componente={LocaleHorarioAtendimento}
              viewMode={true}
              entity={entity}
              {...otherPops}
            ></FullCustomCard>
          </div>
        )}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(MedicoEdit)
);
