//@flow
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import iconWarning from '../images/iconAlert_warning.svg';
import iconCancel from '../images/iconAlert_cancel.svg';
import iconExcluir from '../images/iconAlert_delete.svg';
import { objectsConstants } from '../_constants/objects.constants';

export const ConfirmAcationService = {
  confirmMsg
};

function getIcon(type) {
  if (type === objectsConstants.TYPE_EXCLUIR) {
    return iconExcluir;
  }
  if (type === objectsConstants.TYPE_WARNING) {
    return iconWarning;
  }
  if (type === objectsConstants.TYPE_CANCEL) {
    return iconCancel;
  }

}

function confirmMsg(onConfirm, title, mensagem, subMensagem, textCancelBtn, textConfirmBtn, type, onCancel) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="confirm-ui css-alert-code4">
          <img src={getIcon(type)} alt="icon" />
          <h2 >{title}</h2>
          <p className={"text-center"}>
            <b>{subMensagem}</b>
          </p>
          <div className=' body-confirm'>{mensagem}</div>

          <div className="alert-buttons">
            <button
              className="btn btn-secondary delete"
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
                onClose();
              }}
            >{textCancelBtn}
            </button>
            {textConfirmBtn && <button
              className="btn btn-primary"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >{textConfirmBtn}
            </button>
            }
          </div>

        </div>
      );
    },
    closeOnEscape: false,
    closeOnClickOutside: false,

  });
}