//@flow
import moment from 'moment';
import { func } from 'prop-types';
import { objectsConstants } from '../_constants/objects.constants';

export const inputHelper = {
    getComponent,
    getFormat,
};

function getComponent (input) {
    for(
        let inputType of objectsConstants.TIPO_DOCUMENTO
    ) {
        if(inputType.name === input.tipo) {
            return inputType.component;
        }
    }
}
function getFormat (input) {
    for(
        let inputType of objectsConstants.TIPO_DOCUMENTO
        ) {
        if(inputType.name === input.tipo) {
            return inputType.format;
        }
    }
}