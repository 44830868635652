//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { translate } from '../../_helpers/messages.helper';
import { convenioService } from '../../_services/convenio.service';
import { defaultService } from '../../_services/defaultService';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { perfilservice } from '../../_services/perfil.service';
import IconDelete from '../../images/icon_x_red.svg';
import MoneyInput from '../../components/inputs/MoneyInput';
import {planoConvenioService} from "../../_services/planoConvenio.service";

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const OBRIGATORIO = 'Campo obrigatório';
const PlanosValidate = Yup.object().shape({
    listPlanoConvenio:Yup.array().of(
        Yup.object().shape({
            nome: Yup.string()
            .min(2, 'Nome muito curto!')
            .max(100, 'Nome muito grande!')
            .required(OBRIGATORIO)
            //.matches(/^[A-ZÀ-Úa-zà-ú\s]+$/, 'Somente letras são aceitas')
          ,
        })
    )
});

class PlanosForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : {} 
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
    } else {
      newValues[propName] = [{}];
    }
    setValues(newValues);
  };

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          planoConvenioService.doSave(values.listPlanoConvenio,values.id).then(
            (response) => {
              _this.props.success({
                message: `Plano(s), foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });

              _this.props.loading(false);
              //_this.goToViewEntity(values);

              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar plano(s).',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };
  
  render() {
    let _this = this;
    const { viewMode, entity, propName } = this.props;

    return (
      <React.Fragment>
        <Formik
          validationSchema={PlanosValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
            <div className="form-inside">
              {values.listPlanoConvenio && values.listPlanoConvenio.map((procedimento,index)=>{
                return (
                
                <div className="row section-form">
                <FormGroup className="col-12 col-lg-4">
                    <InputViewEdit
                        component={FormInput}
                        label="Nome do plano"
                        placeholder="Nome do plano"
                        service={convenioService.findAll}
                        returnFullObject={true}
                        required={true}
                        value={_.get(values, `listPlanoConvenio[${index}].nome`)}
                        name={`listPlanoConvenio[${index}].nome`}
                        viewMode={viewMode}
                        id={`listPlanoConvenio${index}nome`}
                        onChange={(name, value) => setFieldValue(name, value)}
                        erroMensagem={_.get(errors, `listPlanoConvenio[${index}].nome`)}
                        removeItem={() => {
                            this.removeItemInArray(
                              `listPlanoConvenio`,
                              index,
                              values,
                              setValues
                            );
                        }}
                    />
                </FormGroup>

                    <FormGroup className="col-12 col-sm-1 d-flex align-items-center justify-content-center">
                        <div className="row">
                            <div className={`col-12 ${viewMode ? 'd-none' : ' d-flex '}`}>
                            <div
                                className={`icon-delete d-flex align-items-center justify-content-center `}
                                onClick={() => {
                                this.removeItemInArray(
                                    `listPlanoConvenio`,
                                    index, 
                                    values,
                                    setValues
                                    );
                                }}
                            >
                                <img src={IconDelete} alt="img" />
                            </div>
                            </div>
                        </div>
                        </FormGroup>
                </div>
                )
            })}

                     <div
                        className={`form-row justify-content-md-start justify-content-center pl-10px ${
                            viewMode ? 'd-none' : ' d-flex '
                        }`}
                        >
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                            this.addItemInArray(
                                'listPlanoConvenio',
                                null,
                                values,
                                setValues
                            )
                            }
                        >
                            Adicionar outro plano   
                        </button>
                    </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(PlanosForm);
