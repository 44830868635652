//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const historicoService = {
  findHistoricoMarcacao,
  findHistoricoPaciente,
  findHistoricoMedico,
  findHistoricoEstabelecimentoMedico,
};

function findHistoricoMarcacao(idMarcacao) {
  return defaultService.doGet(
    `${urlsConstants.HISTORICO}historicoMarcacao/${idMarcacao}`
  );
}

function findHistoricoPaciente(idPaciente) {
  return defaultService.doGet(
    `${urlsConstants.HISTORICO}historicoPaciente/${idPaciente}`
  );
}

function findHistoricoMedico(idMedico) {
  return defaultService.doGet(
    `${urlsConstants.HISTORICO}historicoMedico/${idMedico}`
  );
}

function findHistoricoEstabelecimentoMedico(idEstabelecimentoMedico) {
  return defaultService.doGet(
    `${urlsConstants.HISTORICO}historicoEstabelecimentoMedico/${idEstabelecimentoMedico}`
  );
}
