import * as React from 'react';
import './LinhaReceituarioItemSintetico.scss'

type Props = {
  nome: strig,
  descricao: string
};
export default class LinhaReceituarioItemSintetico extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { nome, descricao } = this.props;
    return (
      <>
        <div className="receituario-paragrafos">
          <p><strong>{nome}</strong></p>
          {descricao && (
            <p className="receituario-descricao">{descricao}</p>
          )}
        </div>
      </>
    );
  }
}
