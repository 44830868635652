//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import Sair from '../../images/icon-logoff.svg';

type Props = {
  showVoltar: boolean,
  dispatch: any,
  typeButton: string,
  handle: any,
  handleExtraButton: any,
  showExtraButton: boolean,
  typeExtraButton: string,
  isAnamneseEditing: boolean,
};

class AgendaOnlineHeader extends React.Component<Props> {
  handleBack = () => {
    const { backUrl, onClickBack } = this.props;
    if (backUrl) {
      this.props.history.push(backUrl);
    } else {
      this.props.history.goBack();
    }
    if (onClickBack) {
      onClickBack();
    }
  };
  render() {
    const { backConfirmObject } = this.props;
    return (
      <header id="app-header" className="navbar navbar-light sticky-top">
        <div className="header-back-menu">
          {this.props.canBack && (
            <button
              className=" header-icon back-icon"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={(e) => {
                if (backConfirmObject) {
                  ConfirmAcationService.confirmMsg(
                    this.handleBack,
                    backConfirmObject.title,
                    backConfirmObject.message,
                    backConfirmObject.subMessage,
                    'Não',
                    'Sim',
                    backConfirmObject.type
                  );
                } else {
                  this.handleBack();
                }
              }}
            >
              <p className="txt-header"> Voltar</p>
            </button>
          )}
        </div>
        <div className="header-title">{this.props.mainTitle}</div>
        <button
          onClick={() => {
            if (this.props.loggedIn) {
              this.props.logoutOnline();
              this.props.success({
                message: `Você foi desconectado com sucesso!`,
              });
            } else {
              this.props.history.push({
                pathname: '/agendaOnline/login',
              });
            }
          }}
          className={`d-flex align-items-center justify-content-start  button-logout `}
        >
          <div
            className={`header-icon ${
              this.props.loggedIn ? ' icon-sair' : ' '
            }`}
          ></div>
          <span
            className={`txt-header ${this.props.loggedIn ? ' red' : 'green'}`}
          >
            {' '}
            {this.props.loggedIn ? 'Sair do sistema' : 'Acessar o sistema'}
          </span>
        </button>
      </header>
    );
  }

  createNewAnamneseButton = () => {
    if (!this.props.isAnamneseEditing) {
      return (
        <button
          className="menu-button font-medium-38"
          type="button"
          onClick={this.props.handleExtraButton}
        >
          <span className="header-icon mais-icon" />
        </button>
      );
    }
  };
}

function mapStateToProps(state) {
  const { typeButton, handle, handleExtraButton } = state.header;
  const { loggedIn } = state.authentication;
  const {
    mainTitle,
    subTitle,
    canBack,
    backUrl,
    backConfirmObject,
    onClickBack,
  } = state.pageTitle;

  const { isAnamneseEditing } = state.anamnese;
  return {
    loggedIn,
    typeButton,
    handle,
    handleExtraButton,
    isAnamneseEditing,
    mainTitle,
    subTitle,
    canBack,
    backUrl,
    backConfirmObject,
    onClickBack,
  };
}

const mapDispatch = ({
  authentication: { logoutOnline },
  alert: { success, error, warning },
}) => ({
  logoutOnline: () => logoutOnline(),
  success: (msg) => success(msg),
});

const connectedAgendaOnlineHeader = withRouter(
  connect(mapStateToProps, mapDispatch)(AgendaOnlineHeader)
);
export { connectedAgendaOnlineHeader as AgendaOnlineHeader };
