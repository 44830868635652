//@flow
import * as React from 'react';

type Props = {};

export default class ExportExcelButton extends React.Component<Props> {
  render() {
    return (
      <button
        type="button"
        className="btn btn-secondary open"
        onClick={this.props.onClick}
      >
        <span className="icon-btn-ecxel"></span>
        Exportar para excel
      </button>
    );
  }
}
