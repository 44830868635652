//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect }  from 'react-redux';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { translate } from '../../_helpers/messages.helper';
import { defaultService } from '../../_services/defaultService';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { perfilservice } from '../../_services/perfil.service';
import { userService } from '../../_services/user.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};
const OBRIGATORIO = 'Obrigatório';

const ROLE_SUPER = {
  id: 1,
  name: 'ROLE_SUDO',
  active: true,
  description: 'Super Administrador',
  dtype: 0,
};
const STRING_REQUIRED = Yup.string().required(OBRIGATORIO);
const UsuarioValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required(OBRIGATORIO),
  cpfCnpj: STRING_REQUIRED,
  email: STRING_REQUIRED.email('Email inválido'),
  senha: Yup.string().test(
    'valid-password',
    'Senha é obrigatório',
    function () {
      const { parent, createError } = this;
      if (parent.id == undefined && parent.senha === undefined) {
        return createError();
      } else {
        return true;
      }
    }
  ),
});

class ResponsavelForm extends React.Component<Props, State> {
  // static defaultProps={
  //   redirectPath:"/usuario/view/"
  // }
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };
  saveForm = () => {
    const { entity } = this.props;
    let _this = this;
    let values = _this.formRef.state.values;
    let responsavel = _.get(values, 'responsavel');
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(responsavel).then((error) => {
        if (!_.get(responsavel, 'empresa')) {
          _.set(responsavel, 'empresa', entity);
        }
        _.set(responsavel, 'role.role', ROLE_SUPER);
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          userService.doSave(responsavel).then(
            (response) => {
              _this.props.success({
                message: `Usuário, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar usuário.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };
  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={UsuarioValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      component={FormInput}
                      label={'Nome do responsável'}
                      type="text"
                      id="respNome"
                      name="responsavel.nome"
                      placeholder="Digite o nome do responsável"
                      value={_.get(values, 'responsavel.nome')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'nome')}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-2">
                    <InputViewEdit
                      component={CpfCnpjInput}
                      label={'CPF'}
                      type="text"
                      id="respCpfCnpj"
                      name="responsavel.cpfCnpj"
                      placeholder="Digite o CPF"
                      value={_.get(values, 'responsavel.cpfCnpj')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={_.get(errors, 'cpfCnpj')}
                      viewMode={viewMode}
                      required={true}
                      viewClassName={'font-weight-bold'}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'E-mail principal'}
                      type="email"
                      id="respEmail"
                      name="responsavel.email"
                      placeholder="Digite o e-mail principal"
                      value={_.get(values, 'responsavel.email')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode}
                      required={true}
                      erroMensagem={_.get(errors, 'email')}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'Senha'}
                      type="password"
                      id="respSenha"
                      defaultValue={'●●●●●●'}
                      name="responsavel.senha"
                      placeholder="Digite a senha"
                      value={_.get(values, 'responsavel.senha')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode}
                      erroMensagem={_.get(errors, 'senha')}
                      required={!_.get(values, 'id')}
                      maxLength={30}
                      viewPassword={true}
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ResponsavelForm);
