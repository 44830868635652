//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from '../../components/inputs/FormInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class PacoteListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="Codigo do pacote"
            placeholder={'Codigo'}
            id={'codigo'}
            name="codigo"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4 offset-sm-1'}
            value={entity.codigo}
          />
        </FormGroup>
       
        
       
        
      </React.Fragment>
    );
  }
}
