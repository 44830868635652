//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import {objectsConstants} from "../../_constants/objects.constants";
import { convenioService } from '../../_services/convenio.service';
import ConvenioForm from './ConvenioForm';
import PlanosForm from './PlanosForm';
import ProcedimentosForm from './ProcedimentosForm';

const defaultEntity = {
  nome: '',
  numeroANS: '',
  cnpj: '',
  listProcedimentos:[{}],
  listPlanos:[{}],
};

class ConvenioEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/convenio/view/' + values.id,
      state: { entity: values },
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      convenioService.doGet(this.props.match.params.id).then(
        (response) => {
          let convenio = response.data.data;

          this.setState({ entity: convenio }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          this.props.loading(false);
        }
      );
    }
  }

  handleChangeProfile = (name, value) => {
    const entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherProps } = this.props;
    return (
      <React.Fragment>
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  convênio`}
          canBack={true}
          backUrl={!entity.id ? null : '/convenio'}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>

        <div className="flex-column">
          <FullCustomCard
            ref={(form) => {
              this.refFormComponent = form;
            }}
            permissionSave={objectsConstants.MENU_USUARIO}     // Mudar objectsContstants //
            title="Convênio"
            Componente={ConvenioForm}
            viewMode={false}
            entity={entity}
            onCancel={this.handleCancel}
            {...otherProps}
          ></FullCustomCard>
        </div>
        
        
        {entity.id && (
          <React.Fragment>
            <FullCustomCard
                ref={(form) => {
                  this.refFormComponent = form;
                }}
                permissionSave={objectsConstants.MENU_USUARIO}     // Mudar objectsContstants //
                title="Procedimento"
                Componente={ProcedimentosForm}
                viewMode={false}
                entity={entity}

                onCancel={this.handleCancel}
                {...otherProps}
              ></FullCustomCard>

              <FullCustomCard
                ref={(form) => {
                  this.refFormComponent = form;
                }}
                permissionSave={objectsConstants.MENU_USUARIO}     // Mudar objectsContstants //
                title="Planos"
                Componente={PlanosForm}
                viewMode={false}
                entity={entity}

                onCancel={this.handleCancel}
                {...otherProps}
              ></FullCustomCard>
            </React.Fragment>
          )}

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(ConvenioEdit)
);