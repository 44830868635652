//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import * as Yup from 'yup';
import { GuicheAtendimento } from '../../components/GuicheAtendimento/GuicheAtendimento';
import { estabelecimentoService } from '../../_services/estabelecimento.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const EstabelecimentoValidateRecepcao = Yup.object().shape({
  listConfiguracaoRecepcao: Yup.array()
    .min(1, 'É necessario informar pelo menos uma recepção')
    .of(
      Yup.object().shape({

        local: Yup.string().required('Obrigatório'),
        nome: Yup.string().required('Obrigatório'),
        inicioNumero: Yup.string().required('Obrigatório'),
        fimNumero: Yup.string().required('Obrigatório'),
      })
    ),
});

class RecepcaoForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/estabelecimento/view/' + values.id,
      state: { entity: values },
    });
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  saveForm = () => {
    let _this = this;

    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          estabelecimentoService
            .saveRecepcao(values, values.listConfiguracaoRecepcao)
            .then(
              (r) => {
                _this.props.success({
                  message: `Rcepção salva com sucesso!`,
                });
                _this.props.loading(false);
                //_this.goToViewEntity(values);
                resolve(true);
              },
              (error) => {
                _this.props.loading(false);
                _this.props.error({
                  message: 'Não foi possível salvar Recepção.',
                });
                console.log(error);
              }
            );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  getCountRecepcao = (listConfiguracaoRecepcao) => {
    let count = 0;
    for (let i = 0; i < listConfiguracaoRecepcao.length; i++) {
      if (listConfiguracaoRecepcao[i].id) {
        count =
          count +
          (listConfiguracaoRecepcao[i].fimNumero -
            listConfiguracaoRecepcao[i].inicioNumero) +
          1;
      }
    }
    if (count > 0) {
      return `${count} recepções cadastradas`;
    }
    return `Nenhuma recepção cadastrada`;
  };

  render() {
    let _this = this;
    const { viewMode, entity } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EstabelecimentoValidateRecepcao}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                {viewMode && (
                  <div className="row section-form">
                    <p className="instruction">
                      O cadastro de recepção é utilizado para os(as)
                      recepcionistas identificarem o seu local de atendimento.
                      (Ex: "Guichê 02, Balcão 05, etc.")
                    </p>
                  </div>
                )}
                {!viewMode &&
                  values.listConfiguracaoRecepcao &&
                  values.listConfiguracaoRecepcao.map((guiche, index) => {
                    return (
                      <div className="row section-form" key={index}>
                        <GuicheAtendimento
                          key={guiche.id ? guiche.id : guiche.idList}
                          entity={values}
                          index={index}
                          size={values.listConfiguracaoRecepcao.length}
                          viewMode={viewMode}
                          propName={`listConfiguracaoRecepcao[${index}]`}
                          onChange={setFieldValue}
                          removeItem={() => {
                            this.removeItemInArray(
                              `listConfiguracaoRecepcao`,
                              index,
                              values,
                              setValues
                            );
                          }}
                          errors={errors}
                        ></GuicheAtendimento>
                      </div>
                    );
                  })}
                {viewMode && (
                  <React.Fragment>
                    <React.Fragment>
                      <Label className="pl-5px">
                        {this.getCountRecepcao(values.listConfiguracaoRecepcao)}
                      </Label>{' '}
                    </React.Fragment>

                    {values.listConfiguracaoRecepcao &&
                      values.listConfiguracaoRecepcao.map((guiche, index) => {
                        if (guiche.id) {
                          return (
                            <div
                              className="item-view"
                              key={index + '-' + guiche.id}
                            >
                              <p className={`form-view`} key={guiche.id}>
                                {_.get(guiche, 'local', '')} {' - '}{' '}

                                {_.get(guiche, 'nome', '--')} {' - '}{' '}
                                <b>
                                  {_.get(guiche, 'inicioNumero', '--')}
                                  {' à '}
                                  {_.get(guiche, 'fimNumero', '--')}
                                </b>
                              </p>
                            </div>
                          );
                        }
                      })}
                  </React.Fragment>
                )}
                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ' d-flex '
                  }`}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      this.addItemInArray(
                        'listConfiguracaoRecepcao',
                        null,
                        values,
                        setValues
                      )
                    }
                  >
                    Adicionar recepção
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(RecepcaoForm);
