//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import { userService } from '../../_services/user.service';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import DatePickerInput from '../../components/inputs/DatePickerInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import FormInput from './../../components/inputs/FormInput';

const voucherStatus = [
  {
    name: 'Ativo',
    value: 'ENABLE',
  },
  {
    name: 'Bloqueado',
    value: 'BLOCKED',
  },
  {
    name: 'Utilizado',
    value: 'USED',
  },
  {
    name: 'Expirado',
    value: 'OVERDUE',
  },
  {
    name: 'Cancelado',
    value: 'CANCELED',
  },
];

type Props = { onChangeText: any, entity: any, onChangeValue: any };

type State = { voucherStatus: [] };

export default class VoucherListFilter extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      voucherStatus: voucherStatus,
    };
  }

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-6 col-xl-3">
          <FormInput
            label="Número"
            placeholder={'Número'}
            id={'voucherNumber'}
            name="voucherNumber"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-6 col-sm-4 offset-sm-1'}
            value={entity.voucherNumber}
          />
          <SelectInput
            id="status"
            name="status"
            label="Status"
            options={this.state.voucherStatus}
            onChange={this.props.onChangeDate}
            value={entity.status}
            labelKey="name"
            valueKey="value"
            multi={false}
            returnFullObject={false}
            creatable={false}
            required={false}
            style={'col-6 col-sm-4 offset-sm-1'}
            placeholder="Status"
          />
        </FormGroup>
        <FormGroup className="col-6 col-xl-3">
          {!userService.isMedico() && (
            <FormInput
              label="Proprietário"
              placeholder={'Proprietário'}
              id={'owner'}
              name="owner"
              onChange={this.props.onChangeDate}
              type={'text'}
              size={12}
              style={'col-6 col-sm-4 offset-sm-1'}
              value={entity.owner}
            />
          )}
          <InputViewEdit
            component={DatePickerInput}
            label="Data de Criação"
            name={`creationDate`}
            id={`creationDate`}
            value={entity.creationDate}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
          />
        </FormGroup>
        <FormGroup className="col-6 col-xl-3">
          {!userService.isMedico() && (
            <CpfCnpjInput
              label="CPF ou CNPJ"
              placeholder={'CPF ou CNPJ'}
              id={'identifier'}
              name="identifier"
              onChange={this.props.onChangeDate}
              type={'number'}
              size={12}
              style={'col-6 col-sm-4 offset-sm-1'}
              value={entity.identifier}
            />
          )}
          <InputViewEdit
            component={DatePickerInput}
            label="Data de Expiração"
            name={`overdueDate`}
            id={`overdueDate`}
            value={entity.overdueDate}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
