//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const estocagemService = {
  doGet,
  doSave,

};



function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ESTOCAGEM}${id}`);
}

function doSave(estoque: any) {
  return defaultService.doSave(urlsConstants.ESTOCAGEM, estoque);
}

