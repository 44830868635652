import { alertConstants } from "../_constants/alert.constants";

//@flow

export function alert(state: any = {}, action: any) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return Object.assign({}, state, {
        type: 'success',
        message: action.message,
        serverError: false,
        code: action.code
      });
    case alertConstants.ERROR:
      return Object.assign({}, state, {
        type: 'danger',
        message: action.message,
        serverError: false,
        code: action.code
      });
    case alertConstants.CLEAR:
      return {};
    case alertConstants.SERVER_ERROR:
      return Object.assign({}, state, {
        type: 'danger',
        message: action.message,
        serverError: true,
        code: action.code
      });
    case alertConstants.SUCCESS_INFO:
      return Object.assign({}, state, {
        type: 'info',
        message: action.message,
        serverError: true,
        code: action.code
      });
    default:
      return state;
  }
}
