//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { userService } from '../../_services/user.service';
import UsuarioListFilter from './UsuarioListFilter';

const columns = [
  {
    path: 'foto',
    title: '',
    isKey: false,
    isAvatar: true,
    dataSort: false,
    propsMidiaId: 'foto.id',
    className: 'avatar-list',
    width: '100px',
    notExport: true,
  },
  {
    path: 'nome',
    pathFilter: 'nome',
    title: 'Nome do usuário',
    isKey: false,
    dataSort: true,
    className: 'nomeProprio column-bold',
    width: '50%',
  },

  {
    path: 'cpfCnpj',
    pathFilter: 'cpfCnpj',
    title: 'CPF',
    isKey: true,
    dataSort: true,
    width: '20%',
  },
  {
    path: 'email',
    pathFilter: 'email',
    title: 'E-mail',
    isKey: false,
    dataSort: true,
    width: '50%',
  },
];

const endPoint = '/usuario/novo';
const endPointView = '/usuario/view/';
class UsuarioListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    console.log(row.id);
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    // this.props.history.push({
    //   pathname: endPoint,
    //   state: {
    //     titlePage: 'Usuário',
    //   },
    // });
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_USUARIOS}
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_USUARIO_EDITAR}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo usuário'}
        ></TopoListagem>
        <ListagemPage
          title={'Usuários'}
          showNewButton={false}
          delete={() => {}}
          service={userService}
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={UsuarioListFilter}
          controller="api/usuario"
          fields={this.state.fields}
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(UsuarioListagemPage);
