import React from 'react';
import { Formik } from 'formik';
import { FormGroup, Form } from 'reactstrap';
import * as Yup from 'yup';
import CpfCnpjInput from '../../../components/inputs/CpfCnpjInput';
import { useValidationsBR } from 'validations-br';

const Step1ValidateModel = Yup.object().shape({
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('is-cpf', 'CPF não é válido', function (value) {
      const { path, createError } = this;
      if (value) {
        if (value.length > 14) {
          if (!useValidationsBR('cnpj', value)) {
            return createError({ path, message: 'CNPJ não é válido' });
          }
        } else {
          if (!useValidationsBR('cpf', value)) {
            return createError({ path, message: 'CPF não é válido' });
          }
        }
      } else {
        return createError({ path, message: 'Campo obrigatório' });
      }
      return true;
    }),
});

class Step1 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Formik
        validationSchema={Step1ValidateModel}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.props.entity}
        ref={(form) => {
          this.form = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="row form-inside">
              <div className="col-6"></div>
              <div className="col-6 section-form">
                <p>Digite seu CPF ou CNPJ da Empresa para criar uma conta </p>
                <div className="row">
                  <FormGroup className="col-12 col-md-12">
                    <CpfCnpjInput
                      label="CPF ou CNPJ"
                      placeholder={'Cpf ou Cnpj'}
                      id={'cpfCnpj'}
                      name="cpfCnpj"
                      type={'number'}
                      value={values.cpfCnpj}
                      required={true}
                      onChange={this.props.handleChange}
                      erroMensagem={errors.cpfCnpj}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Step1;
