//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { oauthHeaderJson } from '../_helpers/oauth-header';

/**
 * DashboardService
 *
 * @type {{getAllMedicalAppointmentData: (function(Partial<GetAllMedicalAppointmentDataRequest>): Promise<Partial<GetAllMedicalAppointmentDataResponse>>)}}
 */
export const dashboardService = {
  getAllMedicalAppointmentData,
};

/**
 * Responsible get all medical appointment data
 *
 * @param getAllMedicalAppointmentDataRequest
 * @returns {Promise<Partial<GetAllMedicalAppointmentDataResponse>>}
 */
function getAllMedicalAppointmentData(
  getAllMedicalAppointmentDataRequest: Partial<GetAllMedicalAppointmentDataRequest>
): Promise<Partial<GetAllMedicalAppointmentDataResponse>> {
  return fetch(`${urlsConstants.DASHBOARD}/getAllMedicalAppointmentData`, {
    method: 'POST',
    headers: oauthHeaderJson(),
    body: JSON.stringify(getAllMedicalAppointmentDataRequest),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return Promise.reject(response.status);
    }
  });
}

/**
 * Request GetAllMedicalAppointmentDataRequest
 *
 */
export interface GetAllMedicalAppointmentDataRequest {
  groupDate: string;
  dataInicio: Date;
  dataFim: Date;
  tiposAtendimento: string[];
  tiposConsulta: string[];
  tiposMarcacao: string[];
  idsEstabelecimento: number[];
  idsMedico: number[];
  idsPaciente: number[];
  idsEmpresa: number[];
  idsConselho: number[];
  idsEspecialidade: number[];
  idsCooperativa: number[];
  idsConvenio: number[];
  idsPlanoConvenio: number[];
  idsExame: number[];
  idsProcedimento: number[];
}

/**
 * GetAllMedicalAppointmentDataResponse
 *
 */
export interface GetAllMedicalAppointmentDataResponse {
  medicalAppointmentDataResponse: MedicalAppointmentDataResponse;
}

/**
 * MedicalAppointmentDataResponse
 *
 */
export interface MedicalAppointmentDataResponse {
  medicalInsuranceDataResponse: MedicalInsuranceDataResponse[];
  medicalAppointmentsAndExamsAndProceduresDetails: MedicalAppointmentsAndExamsAndProceduresDetail[];
}

/**
 * MedicalInsurancesDataResponse
 *
 */
export interface MedicalInsurancesDataResponse {
  medicalInsuranceDataResponses: MedicalInsuranceDataResponse[];
  medicalInsuranceWithPeriodDataResponses: MedicalInsuranceWithPeriodDataResponse[];
}

/**
 * MedicalAppointmentsAndExamsAndProceduresDetail
 *
 */
export interface MedicalAppointmentsAndExamsAndProceduresDetail {
  period: string;
  totalAmount: number;
  totalAmountAppointment: number;
  totalAmountExamsAndProcedures: number;
  totalAmountReturn: number;
  totalAmountFit: number;
  totalAmountFirsts: number;
  totalAmountSimple: number;
  totalAmountWithInsurance: number;
  totalAmountPrivate: number;
  totalAmountFree: number;
  totalAmountScheduled: number;
  totalAmountAttended: number;
  totalAmountCancelled: number;
  totalAmountWaitingAppointment: number;
  totalAmountAppointmentInProgress: number;
  totalAmountInCall: number;
  totalAmountOutOfTime: number;
  totalAmountNotAttended: number;
  totalAmountMalePatient: number;
  totalAmountFemalePatient: number;
  totalAmountMaleDoctor: number;
  totalAmountFemaleDoctor: number;
  totalAmountPatientRecurrents: number;
  totalAmountPatientNew: number;
  averageAppointmentTimeParticular: number;
  maxAppointmentTimeParticular: number;
  minAppointmentTimeParticular: number;
  averageAppointmentTimeConvenio: number;
  maxAppointmentTimeConvenio: number;
  minAppointmentTimeConvenio: number;
}

/**
 * MedicalInsuranceDataResponse
 *
 */
export interface MedicalInsuranceDataResponse {
  name: string;
  amount: number;
}

/**
 * MedicalInsuranceWithPeriodDataResponse
 *
 */
export interface MedicalInsuranceWithPeriodDataResponse {
  period: string;
  medicalInsuranceDataResponses: MedicalInsuranceDataResponse[];
}
