//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProntuarioPage from './ProntuarioPage';

export default class ProntuarioIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/prontuario/anamnese" component={ProntuarioPage} />
        <Route path="/prontuario/:id" component={ProntuarioPage} exact />
        <Route path="/prontuario/:id/:idMarcacao" component={ProntuarioPage} />
      </Switch>
    );
  }
}
