import * as React from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import { Md5 } from 'ts-md5';

/**
 * Props
 *
 */
type Props = {
  dataAnalyticsAtendimentos: DataAnalyticsAtendimentos[],
};

/**
 * State
 *
 */
type State = {
  hashProps: string,
  hasData: boolean,
};

/**
 * Data chart pacientes
 *
 */
interface DataAnalyticsAtendimentos {
  //   period: string;
  particular: number;
  convenio: number;
  cortesia: number;
}

/**
 * Chart Dashboard Component
 *
 */
class ChartAtendimentosAnalyticsDashboardComponent extends React.Component<
  Props,
  State
> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Responsible change componente.
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    let newHashProps: string = this.generateHasProps(
      this.props.dataAnalyticsAtendimentos
    );
    if (newHashProps !== prevState.hashProps) {
      this.setState(this.getInitialState());
    }
  }

  /**
   * Responsible get initial state
   *
   * @return {}
   */
  getInitialState(): any {
    return {
      hashProps: this.generateHasProps(this.props.dataAnalyticsAtendimentos),
      hasData: this.hasData(),
    };
  }

  /**
   * Responsible generate hash.
   *
   * @param dataAnalyticsAtendimentos
   * @return {string}
   */
  generateHasProps = (
    dataAnalyticsAtendimentos: DataAnalyticsAtendimentos[]
  ): string => {
    return Md5.hashStr(JSON.stringify(dataAnalyticsAtendimentos));
  };

  /**
   *
   * Responsible validate if has data.
   *
   * @return {boolean}
   */
  hasData = (): boolean => {
    return (
      this.props.dataAnalyticsAtendimentos &&
      this.props.dataAnalyticsAtendimentos.length > 0
    );
  };

  // /**
  //  * Responsible get legend label
  //  *
  //  * @returns {string}
  //  */
  getLegendLabel(): string {
    if (
      this.props.dataAnalyticsAtendimentos &&
      this.props.dataAnalyticsAtendimentos[0] &&
      this.props.dataAnalyticsAtendimentos[0].period
    ) {
      if (this.props.dataAnalyticsAtendimentos[0].period.length === 10) {
        return 'Dias';
      } else if (this.props.dataAnalyticsAtendimentos[0].period.length === 7) {
        return 'Meses';
      } else {
        return 'Anos';
      }
    } else {
      return '';
    }
  }

  /**
   * Responsible get color anality data
   *
   * @param id - id
   * @param data - data
   * @returns {string}
   */
  getColorAnalyticData(id, data) {
    if (id && data) {
      switch (id) {
        case 'particular':
          return '#606C38';
        case 'convenio':
          return '#02457A';
        case 'cortesia':
          return '#BC4B0D';
      }
    }
  }

  /**
   * Responsible format date.
   *
   * @param datum
   * @return {string}
   */
  formatDateIndex = (datum: RawDatum): string => {
    if (datum && datum.period) {
      let splitDate: Array<string> = datum.period.split('-');
      if (splitDate.length > 1) {
        let finalDate: string = '';
        splitDate
          .slice()
          .reverse()
          .forEach((value, index, array) => {
            finalDate += value + (index + 1 < array.length ? '/' : '');
          });
        return finalDate;
      }
    }
    return '';
  };

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <React.Fragment>
        <Card className="bg-light h-100">
          <Card.Body className="chart-card-body">
            <Card.Title className="chart-card-title d-flex justify-content-center">
              Tipo Atendimento
            </Card.Title>
            {!this.state.hasData && (
              <React.Fragment>
                <div className="chart-text-blank justify-content-center">
                  Não existem dados a serem exibidos para essa pesquisa.
                </div>
              </React.Fragment>
            )}
            {this.state.hasData && (
              <React.Fragment>
                <div className="chart-card-full">
                  <ResponsiveBar
                    keys={['particular', 'convenio', 'cortesia']}
                    margin={{ top: 60, right: 110, bottom: 70, left: 80 }}
                    indexBy={this.formatDateIndex}
                    padding="0.2"
                    labelTextColor="inherit:darker(1.4)"
                    labelSkipHeight="16"
                    labelSkipWidth="16"
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        toggleSerie: true,
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -63,
                      legend: this.getLegendLabel(),
                      legendPosition: 'end',
                      legendOffset: 50,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Quantidade',
                      legendPosition: 'end',
                      legendOffset: -60,
                    }}
                    data={this.props.dataAnalyticsAtendimentos
                      .slice()
                      .reverse()}
                    height="500"
                    colors={({ id, data }) =>
                      this.getColorAnalyticData(id, data)
                    }
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                  />
                </div>
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

/**
 * Export default connect
 *
 */
export default connect(
  null,
  null,
  null
)(ChartAtendimentosAnalyticsDashboardComponent);
