//@flow
import { headerConstants } from "./../_constants/header.constant";

export const headerAction = {
  changeToBack,
  changeToMenu,
  changeExtraButton
};

function changeToBack(handle: any) {
  return { type: headerConstants.CHANGE_ACTION, handle, typeButton: "BACK" };
}

function changeToMenu() {
  return {
    type: headerConstants.CHANGE_ACTION,
    handle: null,
    typeButton: "MENU"
  };
}

function changeExtraButton(
  showExtraButton: boolean,
  handleExtraButton?: any,
  typeExtraButton?: string
) {
  return {
    type: headerConstants.CHANGE_EXTRA_ACTION,
    handleExtraButton,
    showExtraButton,
    typeExtraButton
  };
}
