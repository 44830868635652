//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from '../../../components/inputs/FormInput';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import { objectsEnuns } from '../../../_constants/objects.constants';
import { fabricanteService } from '../../../_services/fabricante.service';
import { fornecedorService } from '../../../_services/fornecedor.service';
import { grupoService } from '../../../_services/grupo.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class EstoqueProdutosListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-2">
          <FormInput
            label={'Código'}
            type="text"
            id="codigo"
            name="produto.codigo"
            placeholder="Informe o código do produto"
            value={_.get(entity, 'produto.codigo')}
            onChange={this.props.onChangeDate}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-4">
          <FormInput
            label={'Nome do produto'}
            type="text"
            id="nome"
            name="produto.nome"
            placeholder="Informe o nome do produto"
            value={_.get(entity, 'produto.nome')}
            onChange={this.props.onChangeDate}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            label={'Grupo'}
            placeholder="Informe o grupo do produto"
            component={FormSelectInput}
            service={grupoService.findByEstabelecimento}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, 'grupo.nome', '--')}
            value={_.get(entity, 'produto.grupo')}
            id="grupo"
            name="produto.grupo"
            onChange={this.props.onChangeDate}
            sortNoAsc={true}
            sortKey={'nome'}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            label={'Fabricante'}
            placeholder="Informe o fabricante do produto"
            component={FormSelectInput}
            service={fabricanteService.findByEstabelecimento}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, 'fabricante.nome', '--')}
            value={_.get(entity, 'produto.fabricante')}
            id="fabricante"
            name="produto.fabricante"
            onChange={this.props.onChangeDate}
            sortNoAsc={true}
            sortKey={'nome'}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-2">
          <SelectInput
            options={objectsEnuns.SITUACAO_PRODUTO}
            type="text"
            name="produto.situacao"
            label="Situação"
            placeholder="Selecione a situação do produto"
            onChange={this.props.onChangeDate}
            id="produto.situacao"
            returnFullObject={false}
            labelKey={'label'}
            valueKey={'value'}
            value={_.get(entity, 'produto.situacao')}
            defaultValue={_.get(entity, 'produto.situacao')}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
