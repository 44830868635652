//@flow
import { Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'reactstrap';
import * as Yup from 'yup';
import { objectsConstants } from '../../_constants/objects.constants';
import { userService } from '../../_services/user.service';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const LoginValidateModel = Yup.object().shape({
  cpfCnpj: Yup.string().required('Obrigatório'),
  senha: ITEM_REQUIRED,
});
type Props = {
  email?: string,
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  location: any,
  alert: any,
};

type State = {
  // email?: string,
  senha?: string,
};

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let email = '';
    let senha = '';

    this.state = { email, senha };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  logar(values) {
    let _this = this;

    if (_this.props.onSubmit) {
      _this.props.onSubmit(
        values.cpfCnpj,
        values.senha,
        true,
        values.userPerfil ? values.userPerfil.userRoleId : 0
      );
    } else {
      _this.props.login(
        values.cpfCnpj,
        values.senha,
        false,
        values.userPerfil ? values.userPerfil.userRoleId : 0
      );
    }
  }

  render() {
    const { onSubmit } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={LoginValidateModel}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={this.state}
          ref={(form) => {
            this.formRef = form;
          }}
          onSubmit={(values) => {
            console.log(values);
            this.logar(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit} autocomplete="off">
              <InputViewEdit
                component={CpfCnpjInput}
                label={'CPF'}
                type="text"
                id="cpfCnpj"
                name="cpfCnpj"
                placeholder="Digite o CPF"
                value={values.cpfCnpj}
                onChange={setFieldValue}
                noSize={true}
                erroMensagem={errors.cpfCnpj}
                required={true}
              />

              <Label for="senha">Senha</Label>
              <FormInput
                name={'senha'}
                id={'senha'}
                value={values.senha}
                autoComplete={false}
                type={'password'}
                onChange={setFieldValue}
                placeholder="Insira sua senha"
                maxLength={30}
                viewPassword={true}
                erroMensagem={errors.senha}
                autocomplete="off"
              />
              {this.props.selectPerfil && (
                <React.Fragment>
                  <Label for="senha">Selecione o perfil desejado</Label>
                  <FormSelectInput
                    name="userPerfil"
                    id={'userPerfil'}
                    label=""
                    placeholder="Selecione um perfil"
                    valueKey="userRoleId"
                    labelKey="nomeExibicao"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={false}
                    searchable={false}
                    allowSelectAll={false}
                    service={userService.findUserRolesByCpf}
                    onChange={setFieldValue}
                    value={values.userPerfil}
                    parent={values.cpfCnpj}
                  />
                </React.Fragment>
              )}
              <div className="d-flex flex-column-reverse flex-xl-row justify-content-between align-items-center">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    this.props.esqueciSenha();
                  }}
                >
                  Esqueci a senha *
                </button>

                <Button
                  className="bt-size-fixed mt-4 my-xl-4"
                  block={true}
                  color="primary"
                  type="submit"
                >
                  Entrar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn, loginOnline, selectPerfil } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    loginOnline,
    alert,
    selectPerfil,
  };
}
const mapDispatch = ({
  alert: { clear, success },
  authentication: { login },
  load: { loading },
}) => ({
  clear: () => clear(),
  success: (msg) => success(msg),
  login: (user, password, loginOnline, perfil) =>
    login({ user, password, loginOnline, perfil }),
  loading: (load: boolean) => loading({ load }),
});
const connectedLoginForm = connect(mapStateToProps, mapDispatch)(LoginForm);
export { connectedLoginForm as LoginForm };
