//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import IconDelete from '../../images/icon_x_red.svg';
import { convenioService } from '../../_services/convenio.service';
import CreatableMulti from '../inputs/CreatableMulti';
import FormSelectInput from '../inputs/FormSelectInput';
import InputViewEdit from '../inputs/InputViewEdit';
import FormInput from '../inputs/FormInput';
import { FormGroup } from '../FormGroup';
import SelectInput from '../inputs/SelectInput';
import { objectsConstants } from '../../_constants/objects.constants';
import { formatterHelper } from '../../_helpers/formatter.helper';

type Props = {
  entity: any,
};

type State = {
  daysOfWeek: [],
};

export default class ConveniosLimite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listPlanoConvenios: [],
    };
  }
  componentDidUpdate(prevProps) {
    const { entity, propName } = this.props;
    let idConvenio = _.get(entity, `${propName}.convenio.id`, null);
    if (
      _.get(prevProps.entity, `${propName}.convenio.id`) !== idConvenio ||
      _.get(prevProps, `viewMode`) != this.props.viewMode
    ) {
      if (idConvenio) {
        this.carregaListaPlanoConvenio(idConvenio);
      }
    }
  }

  carregaListaPlanoConvenio = (idConvenio) => {
    convenioService.findPlanoConvenioByConvenio(idConvenio).then(
      (response) => {
        let listPlanoConvenios = response.data;
        this.setState({ listPlanoConvenios: listPlanoConvenios }, () => {});
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const { entity, propName, index, viewMode, size } = this.props;
    return (
      <div
        className={`row section-form ${
          viewMode ? 'item-view d-flex align-items-center' : ' '
        }`}
      >
        <FormGroup
          className={`col-12 ${
            this.props.exibeCheckIntegracao ? 'col-xl-4' : ' col-xl-6'
          }`}
        >
          <InputViewEdit
            label="Convênio"
            className="flex-fill"
            viewLabelClassName="d-none"
            viewClassName="font-weight-bold"
            placeholder="Selecione um convênio"
            component={FormSelectInput}
            service={convenioService.findAll}
            labelKey={'sigla'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, `${propName}.convenio.sigla`, '--')}
            className={''}
            required={true}
            value={_.get(entity, `${propName}.convenio`)}
            name={`${propName}convenio`}
            viewMode={viewMode}
            id={`conveniolimite${index}Item`}
            onChange={(name, value) => {
              this.props.onChange(`${propName}.listPlanoConvenio`, []);
              this.props.onChange(name, value);
            }}
            erroMensagem={_.get(this.props.errors, `${propName}.convenio`)}
            sortNoAsc={false}
            sortKey={'sigla'}
            creatable={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <InputViewEdit
            label="Plano"
            placeholder="Selecione um plano"
            component={CreatableMulti}
            onChange={(name, value) => {
              if (value && !_.get(value[value.length - 1], 'status')) {
                value[value.length - 1].id = new Date().getTime();
              }
              this.props.onChange(name, value ? value : []);
            }}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, `${propName}.listPlanoConvenio`, [])
              .map((x) => x.nome)
              .join(', ')}
            className={''}
            value={_.get(entity, `${propName}.listPlanoConvenio`, [])}
            name={`${propName}.listPlanoConvenio`}
            viewMode={viewMode}
            id={`listPlanoConvenio${index}Item`}
            creatable={true}
            multi
            options={this.state.listPlanoConvenios}
          />
        </FormGroup>
        <FormGroup className={`col-12 col-sm-2`}>
          <InputViewEdit
            component={FormInput}
            id={`limiteAtendimentoMes`}
            label={'Max. de consulta por mês'}
            type="text"
            name={`${propName}limiteAtendimentoMes`}
            placeholder="Número de consultas"
            value={_.get(entity, `${propName}.limiteAtendimentoMes`)}
            onChange={this.props.onChange}
            noSize={true}
            viewMode={viewMode}
            defaultValue={_.get(entity, `${propName}.limiteAtendimentoMes`)}
          />
        </FormGroup>
        {this.props.exibeCheckIntegracao && (
          <FormGroup className="col-12 col-xl-2">
            <InputViewEdit
              helpText="Integrar o convênio para geração de Guias no SASC Connect"
              component={SelectInput}
              id={`integrarConvenio${index}`}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
              returnFullObject={true}
              label="Integrar convenio ? "
              required={true}
              labelKey={'label'}
              valueKey={'value'}
              viewMode={viewMode}
              name={`${propName}.integrarConvenio`}
              erroMensagem={_.get(
                this.props.errors,
                `${propName}.integrarConvenio`
              )}
              defaultValue={
                _.get(entity, `${propName}.integrarConvenio`) &&
                _.get(entity, `${propName}.integrarConvenio`).value === false
                  ? 'Não'
                  : formatterHelper.getLabelEnum(
                      _.get(entity, `${propName}.integrarConvenio`) &&
                        _.get(entity, `${propName}.integrarConvenio`).value
                        ? _.get(entity, `${propName}.integrarConvenio`).value
                        : _.get(entity, `${propName}.integrarConvenio`),
                      objectsConstants.SIM_NAO
                    )
              }
              value={
                _.isBoolean(_.get(entity, `${propName}.integrarConvenio`))
                  ? formatterHelper.getEnum(
                      _.get(entity, `${propName}.integrarConvenio`),
                      objectsConstants.SIM_NAO
                    )
                  : _.get(entity, `${propName}.integrarConvenio`)
              }
              onChange={(name, value) => {
                this.props.onChange(name, value.value);
              }}
            />
          </FormGroup>
        )}
        {!viewMode && (
          <div
            className={`col-12 col-md align-items-center justify-content-md-center justify-content-center ${
              viewMode ? 'd-none' : ' d-flex '
            }`}
          >
            <div className="text-right mt-2">
              <div
                className={`icon-delete d-flex align-items-center justify-content-center ${
                  size === 1 || viewMode ? 'disabled' : ''
                }`}
                onClick={() => {
                  this.props.removeItem(index, entity);
                }}
                disabled={size === 1}
              >
                <img src={IconDelete} alt="img" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
