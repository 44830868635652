import * as React from 'react';
import './AgendamentoRapido.css';

export default class AgendamentoRapidoPeriodo extends React.Component<{}> {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="check-periodo">
        <div className="form-check form-check-inline check-periodo ml-4">
          <input
            type="checkbox"
            value="option1"
            checked={this.props.manha}
            onChange={() => {
              this.props.changePeriodo('manha');
            }}
          />
          <label>Manhã</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            type="checkbox"
            value="option2"
            checked={this.props.tarde}
            onChange={() => {
              this.props.changePeriodo('tarde');
            }}
          />
          <label>Tarde</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            type="checkbox"
            value="option3"
            checked={this.props.noite}
            onChange={() => {
              this.props.changePeriodo('noite');
            }}
          />
          <label>Noite</label>
        </div>
      </div>
    );
  }
}
