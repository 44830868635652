//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconAgendamento from '../../images/icon-agenda-online-WHITE.svg';
import IconCopy from '../../images/icon-copy.svg';
import IconPainel from '../../images/icon-tv-WHITE.svg';
import { etapaAtendimentoService } from '../../_services/etapaAtendimento.service';
import { urlsConstants } from '../../_constants/urls.constant';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

export default class EstabelecimentoLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  copiaLink(value) {
    // console.log('Copied value: ', value);
    var textArea = document.createElement('textarea');
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="card-agendamento d-flex flex-column flex-xl-row justify-content-around">
          <div className="area-link">
            <a
              onClick={() => {
                this.copiaLink(entity.urlAgendamento);
              }}
            >
              <div className="icon d-flex justify-content-center align-items-center">
                <img
                  src={IconAgendamento}
                  alt="Imagem link agendamento online"
                />
              </div>

              <div className="title">Agendamento online</div>
              <p className="instruction">
                Disponibilize este link para os seus pacientes poderem agendar
                as suas consultas.
              </p>
            </a>
            <div className="d-flex justify-content-center">
              <a
                onClick={() => {
                  this.copiaLink(entity.urlAgendamento);
                }}
                className="btn btn-link m-0 pr-0 flex-fill"
              >
                Copiar o Link
              </a>
              <a
                className="d-flex button-copy"
                title="Copiar link"
                onClick={() => {
                  this.copiaLink(entity.urlAgendamento);
                }}
              >
                <img src={IconCopy} alt="Copiar link de agendamento online" />
              </a>
            </div>
          </div>

          <div className="area-link">
            <a
              onClick={() => {
                this.copiaLink(entity.urlPainelSenha);
              }}
            >
              <div className="icon d-flex justify-content-center align-items-center">
                <img src={IconPainel} alt="Imagem link painel de chamadas" />
              </div>

              <div className="title">Painel de chamadas</div>
              <p className="instruction">
                Acesse este link pela Smart TV da sala de espera do
                estabelecimento.
              </p>
            </a>
            <div className="d-flex justify-content-center">
              <a
                onClick={() => {
                  this.copiaLink(entity.urlPainelSenha);
                }}
                className="btn btn-link m-0 pr-0 flex-fill"
              >
                Copiar o Link
              </a>

              <a
                className="d-flex button-copy"
                title="Copiar link"
                onClick={() => {
                  // this.copiaLink(urlsConstants.REACT_APP_URL_PAINEL_SASCCM);
                  this.copiaLink("https://painel.sasccm.coop.br");
                }}
              >
                <img src={IconCopy} alt="Copiar link do painel de chamadas" />
              </a>
            </div>
          </div>
        </div>

        {/* <div className="card-agendamento d-flex flex-column flex-xl-row">
          <div className="col-12 col-xl-2 d-flex justify-content-center">
            <img src={IconLink} alt="Imagem Link agendamento online" />
          </div>
          <div className="col-12 col-xl-6 p-0">
            <h1>Link - Agendamento online</h1>
            <p className="instruction">
              Disponibilize este link para os seus pacientes poderem agendar as
              suas consultas.
            </p>
          </div>          
          <div className="col-12 col-xl p-0 d-flex align-items-center justify-content-around justify-content-xl-between">
            <a href={entity.urlAgendamento} target="_blank">
              <button type="button" className="btn btn-secondary save m-0">Acessar</button>
            </a>
            <button
              type="button"
              className="btn btn-secondary m-0"
              onClick={() => {
                this.copiaLink(
                  entity.urlAgendamento
                );
              }}
            >
              Copiar
            </button>
          </div> 
        </div> */}
      </React.Fragment>
    );
  }
}
