//@flow

import _ from 'lodash';
import { agendaConstants } from "../_constants/agenda.constant";
import { headerConstants } from "../_constants/header.constant";
import moment from 'moment';
import { objectsConstants } from '../_constants/objects.constants';
import { updateMarcacaoAgenda, updateMarcacao } from "../_helpers/agenda.helper";
import { CacheAgenda } from "../_components/CacheAgenda";

export default {
  state: {
    type: null,
    medico: null,
    filtroRemarcacao: {},
    showDivMedico: false,
    dados: null, filter: {},
    cache: [],
    marcacao: null,
    cacheAgendaAtual: null,
    agendas: [],
    agendaDoDia: [],
    maxMarcacaoDia: 0,
    dataAtualAgenda: new Date(),
    dataAgendaDia: null,
    viewHorario: false,
    canal: null,
    canalFila: null,
    fila: [],
    year: moment().year(),
    month: moment().month(),
    tipoAgenda: objectsConstants.TIPO_AGENDA[0].name,
    filtro: null
  },
  reducers: {
    receiveAgenda: (state, payload) => {

      let { cache } = state;
      let filtro = _.cloneDeep(state.filtro);
      let cacheAgendaAtual = null
      if (payload.filtro) {
        filtro = payload.filtro;
        let newCache = new CacheAgenda(payload.filtro);
        cacheAgendaAtual = newCache.exist(cache);
        if (!cacheAgendaAtual) {
          newCache.agenda = _.get(payload, 'agendas', []);
          cacheAgendaAtual = newCache;
          cache.push(newCache);
          return Object.assign({}, state, {
            agendas: newCache.filtrar(payload.filtro),
            cache,
            cacheAgendaAtual,
            filtro

          });
        }
      }
      let agendas = _.get(payload, 'agendas', []);


      return Object.assign({}, state, {
        agendas,
        cache,
        cacheAgendaAtual,
        filtro

      }

      );
    },
    clearCache: (state, payload) => {
      return Object.assign({}, state, {
        agendas: [],
        cache: [],
        cacheAgendaAtual: null,
        agendaDoDia: [],
        filtro: null,
        fila: []

      }

      );
    },
    receiveFila: (state, payload) => {
      return Object.assign({}, state, {
        fila: _.get(payload, 'fila', [])
      }

      );
    },
    addMarcacaoCache: (state, payload) => {

      let { cache, agendas, agendaDoDia } = state;

      for (let cacheUpdate of cache) {

        cacheUpdate.addAgenda(payload.marcacao);
      }
      if (agendaDoDia) {
        agendaDoDia = updateMarcacao(agendaDoDia, payload.marcacao, state.tipoAgenda);
      }
      agendas = updateMarcacao(agendas, payload.marcacao, state.tipoAgenda);
      return Object.assign({}, state, {
        agendas,
        cache,
        marcacao: payload.marcacao,
        agendaDoDia

      }

      );
    },
    updateCacheAgendaAtual: (state, payload) => {

      let { cacheAgendaAtual } = payload;

      return Object.assign({}, state, {

        cacheAgendaAtual

      }

      );
    },
    updateCache: (state, payload) => {

      let { cache } = payload;

      return Object.assign({}, state, {

        cache

      }

      );
    },
    addCache: (state, payload) => {
      let { cache } = state;

      if (payload.filtro) {
        let newCache = new CacheAgenda(payload.filtro);
        if (!newCache.exist(cache)) {
          newCache.agenda = _.get(payload, 'agendas', []);
          cache.push(newCache);
        }
      }
      return Object.assign({}, state, {

        cache

      }

      );
    },
    updateMarcacao: (state, payload) => {
      const marcacao = _.get(payload, 'marcacao', [])
      let agendas = updateMarcacaoAgenda(state.agendas, marcacao, state.tipoAgenda);
      return Object.assign({}, state, {
        agendas
      });
    },
    receiveDadosAgenda: (state, payload) => {
      return Object.assign({}, state, {
        maxMarcacaoDia: _.get(payload, 'maxMarcacaoDia', 0),
        dataAtualAgenda: _.get(payload, 'dataAtualAgenda', new Date()),
        viewHorario: _.get(payload, 'viewHorario', false),
      });
    },

    receiveMonthAndYear: (state, payload) => {
      return Object.assign({}, state, {
        month: _.get(payload, 'month', ''),
        year: _.get(payload, 'year', ''),
      });
    },

    receiveTipoAgenda: (state, payload) => {
      return Object.assign({}, state, {
        tipoAgenda: _.get(payload, 'tipoAgenda', ''),
      });
    },

    receiveViewHorario: (state, payload) => {
      return Object.assign({}, state, {
        viewHorario: _.get(payload, 'viewHorario', [])
      });
    },
    setAgendaDoDia: (state, payload) => {

      return Object.assign({}, state, {
        agendaDoDia: _.get(payload, 'agendaDoDia', []),
        dataAgendaDia: _.get(payload, 'dataAgendaDia')
      });
    },

    setFiltroRemarcacao: (state, payload) => {
      return Object.assign({}, state, {
        filtroRemarcacao: _.get(payload, 'filtroRemarcacao', {}),
      });
    },

    setCanalSocket: (state, payload) => {
      return Object.assign({}, state, {
        canal: _.get(payload, 'canal', null)
      });

    },
    changeFilter: (state, payload) => {
      return Object.assign({}, state, {
        filter: payload.filter
      });
    },
    clearFilter: (state) => {
      return Object.assign({}, state, {
        filter: {}
      });

    },
    changeMedico: (state, payload) => {
      return Object.assign({}, state, {
        type: headerConstants.CHANGE_ACTION,
        handle: null,
        typeButton: "MENU",
        year: payload.year,
        month: payload.month,
        medico: payload.medico
      });

    },
    clearMedico: (state) => {
      return Object.assign({}, state, {
        medico: null
      });

    },
    showDivMedico: (state, payload) => {
      return Object.assign({}, state, {
        type: agendaConstants.SHOW_DIV_MEDICO, showDivMedico: payload.show
      });

    },
  },
  effects: (dispatch: any) => ({
    changeMedico: async (payload: { medicoEstabelecimento: medicoEstabelecimento, year: number, month: number }) => {
      dispatch.load.loading(true);
      // agendaService.doList(payload.medicoEstabelecimento ? payload.medicoEstabelecimento.medico.id : 0, payload.year, payload.month).then(
      //   json => {
      //     dispatch.agenda.receiveData(json, payload.medicoEstabelecimento);
      //     dispatch.load.loading(false);
      //   },
      //   error => {
      //     console.log(error);
      //     dispatch.load.loading(false);
      //   }
      // );
    }
  })
};
