//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { entradaEstoqueService } from '../../../_services/entradaEstoque.service';
import EstoqueEntradaForm from './EstoqueEntradaForm';
import { dateHelper } from '../../../_helpers/date.helper';

// const defaultArrayItem = [{ idList: new Date().getTime() }];

class EstoqueEntradaView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadEntradaEstoque();
  }

  loadEntradaEstoque = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      entradaEstoqueService.doGet(this.props.match.params.id).then(
        (response) => {
          let entradaEstoque = response.data.data;
          console.log(entradaEstoque);
          let horaLancamento = _.get(entradaEstoque, `dataHoraLancamento`)
            ? dateHelper.getHourFormat(
                _.get(entradaEstoque, `dataHoraLancamento`),
                {
                  mode: 'TIME',
                }
              )
            : '--';
          _.set(entradaEstoque, 'horaLancamento', horaLancamento);
          this.setState({ entity: entradaEstoque }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleCancel = () => {
    console.log('cancel entrada');
    this.props.history.goBack();
  };

  handleDeleteItem = (index, entity) => {
    this.props.loading(true);
    entradaEstoqueService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Entrada foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push('/estoque-entrada');
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir Entrada.',
        });
      }
    );
  };

  handleChangeProfile = (name, value) => {
    // let validateModel = this.ObjectValidateProfile(value);
    const entity = _.cloneDeep(this.state.entity);
    console.log(entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        console.log(erros);
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        {entity.id && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`${entity.id ? 'Editar ' : 'Nova '}  entrada`}
              canBack={true}
              backUrl={!entity.id ? null : '/estoque-entrada'}
            />
            <TopoListagem
              showIndisponibilidade={false}
              showfilter={false}
            ></TopoListagem>
            <div className="flex-column">
              <FullCustomCard
                permissionSave={objectsConstants.MENU_ESTOQUE}
                ref={(form) => {
                  this.refFormComponent = form;
                }}
                title="Usuário"
                Componente={EstoqueEntradaForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                btnExcluir={false}
                // removeItem={this.handleDeleteItem}
                redirectPath="/estoque-entrada/view/"
                {...otherPops}
              ></FullCustomCard>
            </div>
            {<div className="flex-column"></div>}
          </React.Fragment>
        )}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(EstoqueEntradaView));
