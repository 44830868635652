//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class Footer extends PureComponent<Props, State> {
  render() {
    return (
      <footer>
        <div className="background-lines">
          <div className="bg-riscos bottom"></div>
        </div>
        <p>© 2021 Sasc CM - Fencom</p>
      </footer>
    );
  }
}

export default Footer;
