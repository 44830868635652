//@flow
import { agendaConstants } from "./../_constants/agenda.constant";
import type { medico } from "../_types/medico.type";
import { agendaService } from "./../_services/agenda.service";
import { loadActions } from "./load.action";

export const agendaAction = {
  receiveData,
  changeMedico,
  clearMedico,
  showDivMedico
};

function receiveData(data: any, medico: medico) {
  return { type: agendaConstants.RECEIVE_DATA, data, medico };
}

function changeMedico(medico: medico, year: number, month: number) {

  return (dispatch: any) => {
    dispatch(loadActions.loading(true));
    agendaService.doList(medico ? medico.id : 0, year, month).then(
      json => {
        dispatch(agendaAction.receiveData(json, medico));
        dispatch(loadActions.loading(false));
      },
      error => {
        console.log(error);
        dispatch(loadActions.loading(false));
      }
    );
  };
}

function clearMedico() {
  return { type: agendaConstants.CLEAR_MEDICO };
}

function showDivMedico(show: boolean) {
  return { type: agendaConstants.SHOW_DIV_MEDICO, showDivMedico: show };
}
