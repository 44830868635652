//@flow
import { anamneseConstants } from "../_constants/anamnese.constant";

export default {
  state: { type: null, isAnamneseEditing: false },
  reducers: {
    changeEditing: (state, payload) => {
      return Object.assign({}, state, {
        type: anamneseConstants.CHANGE_EDITING,
        isAnamneseEditing: payload.isAnamneseEditing
      });

    },
    setTelemedicina: (state, payload) => {
      return Object.assign({}, state, {
        showTelemedicina: payload.showTelemedicina,
        nomeSala: payload.nomeSala,

      });

    }
  }
};
