//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PacienteEdit from './PacienteEdit';
import PacienteListagemPage from './PacienteListagemPage';
import PacienteView from './PacienteView';

export default class PacienteIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
          <Route path="/paciente" exact component={PacienteListagemPage} />
          <Route path="/paciente/novo" component={PacienteEdit} />
          <Route path="/paciente/:id/:backUrl?" component={PacienteView} />
      </Switch>
    );
  }
}