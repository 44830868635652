//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const estabelecimentoService = {
  doGet,
  doSave,
  findByFiltro,
  exportToExcel,
  saveConsultorio,
  findConsultorios,
  findAll,
  getLocalAtendimentoByMedico,
  getLocalAtendimentoByRecepcionista,
  findLocalAtendimento,
  findLocalAtendimentoRecepcao,
  checkInLocal,
  checkInRecepcionistaLocal,
  findByToken,
  saveConvenio,
  getPagamento,
  findAllSuporte,
  getValorConsultaParticular,
  limiteDeLicencasAtingido,
  findRecepcao,
  saveRecepcao,
  saveCamposObrigatorios,
  findCamposObrigatorios,
  findCamposObrigatoriosProfissionais,
  whatsappConfig
};

function findByToken(token) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO_PUBLICO}${token}`
  );
}
function whatsappConfig(){
  return defaultService.doGet(
      `${urlsConstants.ESTABELECIMENTO}whatsappConfig`
  );
}
function findAll() {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}findAll`);
}
function findRecepcao(id) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO}findRecepcao/${id}`
  );
}
function findConsultorios(id) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO}findConsultorios/${id}`
  );
}
function checkInLocal(local, medico) {
  local.medico = medico;
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO_CHECKIN}`,
    local
  );
}

function checkInRecepcionistaLocal(local) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO_CHECKIN_RECEPCIONISTA}`,
    local
  );
}

function saveConvenio(estabelecimento, listConvenio) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO}saveConvenio/${estabelecimento.id}`,
    listConvenio
  );
}

function saveConsultorio(estabelecimento, listConfiguracaoSala) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO}saveConsultorio/${estabelecimento.id}`,
    listConfiguracaoSala
  );
}

function saveRecepcao(estabelecimento, listConfiguracaoRecepcao) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO}saveRecepcao/${estabelecimento.id}`,
    listConfiguracaoRecepcao
  );
}

function saveCamposObrigatorios(estabelecimento, camposObrigatorios) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO}saveCamposObrigatorios/${estabelecimento.id}`,
    camposObrigatorios
  );
}

function findCamposObrigatorios(idEstabelecimento) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO}findCamposObrigatorios/${idEstabelecimento}`
  );
}

function findCamposObrigatoriosProfissionais(idProfissional) {
  return defaultService.doPost(
    `${urlsConstants.ESTABELECIMENTO}findCamposObrigatoriosProfissionais/${idProfissional}`
  );
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ESTABELECIMENTO}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}${id}`);
}

function getValorConsultaParticular(idEstabelecimentoAtendimento) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO}valorConsultaParticular/${idEstabelecimentoAtendimento}`
  );
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.ESTABELECIMENTO, entity);
}

function findLocalAtendimento(idEstabelecimento) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO_FIND_LOCAL}${idEstabelecimento}`
  );
}

function findLocalAtendimentoRecepcao(idEstabelecimento) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO_FIND_LOCAL_RECEPCAO}${idEstabelecimento}`
  );
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel(
    'estabelecimento',
    filter,
    fields,
    labels
  );
}

function getLocalAtendimentoByMedico() {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO}getLocalAtendimentoByMedico`
  );
}

function getLocalAtendimentoByRecepcionista() {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO}getLocalAtendimentoByRecepcionista`
  );
}

function getPagamento() {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO_PAGAMENTO}`);
}

function findAllSuporte() {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}findAllSuporte`);
}

function limiteDeLicencasAtingido(idEstabelecimento, idMedico) {
  return defaultService.doGet(
    `${urlsConstants.ESTABELECIMENTO}limiteDeLicencasAtingido/${idEstabelecimento}/${idMedico}`
  );
}
