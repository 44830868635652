//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const fabricanteService = {
  findByEstabelecimento,
  doSave
};

function findByEstabelecimento() {
  return defaultService.doGet(`${urlsConstants.FABRICANTE}findByEstabelecimento`);
}


function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.FABRICANTE, entity);
}

