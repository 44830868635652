import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import './AgendamentoRapido.css';
import HorarioAgendamentoRapido from './HorariosAgendamentoRapido';

export default class AgendamentoRapidoPeriodo extends React.Component<{}> {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="row painel-horarios">
        {this.props.agenda.map((agendaDia, index) => {
          if (!agendaDia) {
            return <React.Fragment key={index}></React.Fragment>;
          }
          let data = moment(agendaDia.dia, 'DD/MM/YYYY').locale('pt-br');

          let horariosDisponiveis = _.orderBy(
            agendaDia.horariosDisponiveis,
            (h) => {
              return moment(h.horario, 'hh:mm');
            },
            ['asc']
          );

          return (
            <React.Fragment key={index + data}>
              <div className="col-12 col-md-2 d-inline-flex justify-content-center text-center ">
                <div className="box-data align-self-center">
                  <h1>{data.date()}</h1>
                  <h2>{data.format('MMMM')}</h2>
                  <p>{data.format('dddd')}</p>
                  <p>{data.year()}</p>
                </div>
              </div>
              <div className="col-12 col-md-10 d-flex flex-wrap justify-content-center d-sm-block btn-painel-horarios">
                {horariosDisponiveis.map((horario, index) => {
                  return (
                    <HorarioAgendamentoRapido
                      key={index + horario.horario}
                      agenda={horario}
                      horario={horario.horario}
                      medico={horario.medico}
                      tooglePacientes={this.props.tooglePacientes}
                      onNewOptionClick={this.props.onNewOptionClick}
                    />
                  );
                })}
              </div>
              <hr></hr>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
