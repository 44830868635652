//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ModelosEdit from './ModelosEdit';
import ModelosListagemPage from './ModelosListagemPage';
import ModelosView from './ModelosView';

export default class ModelosIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/modelo" component={ModelosListagemPage} exact />
        <Route path="/modelo/novo" component={ModelosEdit} />
        <Route path="/modelo/view/:id" component={ModelosView} />
      </Switch>
    );
  }
}
