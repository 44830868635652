import * as React from 'react';
import './AgendamentoRapido.css';
import AgendamentoRapidoHorarios from './AgendamentoRapidoHorarios';
import AgendamentoRapidoMedicos from './AgendamentoRapidoMedicos';
import AgendamentoRapidoPeriodo from './AgendamentoRapidoPeriodo';

export default class AgendamentoRapidoPainel extends React.Component<{}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.listaMedico && this.props.listaMedico.length > 0 && (
          <React.Fragment>
            <AgendamentoRapidoMedicos
              medicos={this.props.listaMedico}
              changeMedico={this.props.changeMedico}
              indexMedico={this.props.indexMedico}
            />

            <div className="horarios-agendamento">
              <AgendamentoRapidoPeriodo
                manha={this.props.manha}
                tarde={this.props.tarde}
                noite={this.props.noite}
                changePeriodo={this.props.changePeriodo}
              />
            </div>
            <div>
              <AgendamentoRapidoHorarios
                agenda={this.props.agenda}
                tooglePacientes={this.props.tooglePacientes}
                onNewOptionClick={this.props.onNewOptionClick}
              />
            </div>
          </React.Fragment>
        )}
      </>
    );
  }
}
