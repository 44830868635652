//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const prontuarioIntegracaoService = {
  findPaciente,
  findProntuario,save
};

function findPaciente(nome,parent) {
  return defaultService.doGet(
    `${urlsConstants.PRONTUARIO_INTEGRACAO}findPacienteByNome/${nome}/${parent}`
  );
}
function findProntuario(estabelecimento,paciente) {
  return defaultService.doGet(
      `${urlsConstants.PRONTUARIO_INTEGRACAO}findProntuario/${estabelecimento}/${paciente}`
  );
}

function save(idPacienteProtocolo,paciente,estabelecimento){
  return defaultService.doGet(
      `${urlsConstants.PRONTUARIO_INTEGRACAO}save/${estabelecimento}/${paciente}/${idPacienteProtocolo}`
  );
}