import React, { PureComponent } from 'react';
import toastIconInfo from './img/icon_info.svg';

export default class IconInfoToast extends PureComponent {
  render() {
    return (
      <div className="contentAvatarToastr">
        <img src={toastIconInfo} alt="avatar" className="icon_info" />
      </div>
    );
  }
}
