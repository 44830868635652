import { Line } from "@nivo/line";
import * as React from "react";
import "./DashboardFinanceiro.css";
import { Bar } from "@nivo/bar";

export default class DashFinanceiroGraficoDiario extends React.Component<{}> {
  render() {
    const keys = ["Recebimentos", "Pagamentos"];
    const commonProperties = {
      width: 900,
      height: 400,
      margin: { top: 20, right: 20, bottom: 60, left: 80 },
      data: [],
      animate: true,
      enableSlices: "Valor",
    };
    const commonProps = {
      width: 900,
      height: 500,
      margin: { top: 60, right: 110, bottom: 60, left: 80 },

      indexBy: "data",
      keys,
      padding: 0.2,
      labelTextColor: "inherit:darker(1.4)",
      labelSkipWidth: 16,
      labelSkipHeight: 16,
    };
    const colors = { Pagamentos: "#dd3a46", Recebimentos: "#7bcc6d" };
    let data = [
      {
        data: "Jan",

        Recebimentos: 57,

        Pagamentos: 112,
      },
      {
        data: "Fev",

        Recebimentos: 38,

        Pagamentos: 73,
      },
      {
        data: "Mar",

        Recebimentos: 118,

        Pagamentos: 39,
      },
    ];

    return (
      <div className=" box-grafico">
        <div className=" row justify-content-center text-center ">
          <h2 className="col-12 mt-5">FLUXO DE CAIXA MENSAL</h2>

          <div className=" col-12">
            <Bar
              colors={(bar) => {
                return colors[bar.id];
              }}
              groupMode="grouped"
              data={data}
              {...commonProps}
              layers={[
                "grid",
                "axes",
                "bars",
                "markers",
                "legends" /*LineLayer*/,
              ]}
            />
          </div>
        </div>
         <div className="label-listagem d-flex flex-wrap justify-content-end align-items-center text-center">
          <p className="col-3">Data</p>
          <p className="col-3">Recebimentos</p>
          <p className="col-3">Pagamentos</p>
          <p className="col-3">Saldo Final</p>
        </div>

        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Janeiro</p>
          <p className="col-3">R$30.000,00</p>
          <p className="col-3">R$20.000,00</p>
          <p className="col-3">R$10.000,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap justify-content-end align-items-center text-center">
          <p className="col-3">Fevereiro</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Março</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Abril</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Maio</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Junho</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Julho</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Agosto</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>
        <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
          <p className="col-3">Setembro</p>
          <p className="col-3">R$220,000</p>
          <p className="col-3">R$20,00</p>
          <p className="col-3">R$200,00</p>
        </div>

      </div>
    );
  }
}
