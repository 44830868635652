//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import nextId from 'react-id-generator';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { alertActions } from '../../_actions/alert.action';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { translate } from '../../_helpers/messages.helper';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { convenioService } from '../../_services/convenio.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { medicoService } from '../../_services/medico.service';
import ConveniosLimite from '../ConveniosLimite/ConveniosLimite';
import { FormGroup } from '../FormGroup';
import { HorarioFuncionamento } from '../HorarioFuncionamento/HorarioFuncionamento';
import FormInput from '../inputs/FormInput';
import FormSelectInput from '../inputs/FormSelectInput';
import FormTextArea from '../inputs/FormTextArea';
import FormTextCKEditor from '../inputs/FormTextCKEditor';
import InputViewEdit from '../inputs/InputViewEdit';
import MoneyInput from '../inputs/MoneyInput';

type Props = {
  entity: any,
};
const hoje = new Date(Date.now());
const today = new Date();
const maxDate = new Date();
maxDate.setMonth(today.getMonth() + 6);
const LocaleHorarioFuncionamento = Yup.object().shape({
  estabelecimento: Yup.string().required('Local de atendimento é obrigatório'),
  listEstabelecimentoMedicoConvenio: Yup.array().of(
    Yup.object().shape({
      convenio: Yup.string().required('Campo obrigatório'),
      integrarConvenio: Yup.string().required('Campo obrigatório'),
    })
  ),

  horariosAtendimento: Yup.array()
    .min(1, 'É necessario informar pelo menos um horário de atendimento')
    .of(
      Yup.object().shape({
        periodicidade: Yup.string().test(
          'valid-periodicidade',
          'Periodicidade é obrigatório',
          function (value) {
            const { createError } = this;
            const { dataFinal } = this.parent;
            if (dataFinal >= hoje && !value) {
              return false;
            } else {
              return true;
            }
          }
        ),

        inicio: Yup.string().required('Obrigatório'),
        fim: Yup.string().required('Obrigatório'),
        dataInicial: Yup.date().required('Obrigatório'),
        intervaloMarcacao: Yup.string().required('Campo obrigatório'),
        atendeTelemedicina: Yup.string().required('Campo obrigatório'),
        permiteAgendamentoOnline: Yup.string().required('Campo obrigatório'),
        permiteEncaixe: Yup.string().required('Campo obrigatório'),
        dias: Yup.array().min(
          1,
          'É necessario informar pelo menos um dia de atendimento'
        ),
        // listaIntervalo: Yup.array().of(
        //   Yup.object().shape({
        //     horaInicio: Yup.string().required('necessároip'),
        //   })
        // ),
        dataFinal: Yup.date()
          .required('Obrigatório')
          .min(
            Yup.ref('dataInicial'),
            'Data Final deve ser maior que data inicial.'
          )
          .max(
            maxDate,
            'A data final não pode ser superior a 6 meses a partir de hoje.'
          ),
      })
    ),
});

class LocaleHorarioAtendimento extends React.Component<Props, State> {
  static defaultProps = {
    periodicidade: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      horariosFuncionamento: [],
      estabelecimentoSemHorario: false,
      daysOfWeek: [
        { name: 'SEG', description: 'Seg', codigo: 1 },
        { name: 'TER', description: 'Ter', codigo: 2 },
        { name: 'QUA', description: 'Qua', codigo: 3 },
        { name: 'QUI', description: 'Qui', codigo: 4 },
        { name: 'SEX', description: 'Sex', codigo: 5 },
        { name: 'SAB', description: 'Sáb', codigo: 6 },
        { name: 'DOM', description: 'Dom', codigo: 0 },
      ],
      atenderSomente: [
        { name: 'PARTICULAR', description: 'Particular', codigo: 1 },
        { name: 'CONVENIO', description: 'Convênio', codigo: 2 },
        { name: 'CONSULTAS', description: 'Consultas', codigo: 3 },
        { name: 'RETORNO', description: 'Retorno', codigo: 4 },
        {
          name: 'PRIMEIRA_CONSULTA',
          description: 'Primeira consulta',
          codigo: 5,
        },
        { name: 'EXAMES', description: 'Exames', codigo: 6 },
      ],
    };
    // this.verificaLimiteConvenios(
    //   this.props.entity.estabelecimento
    //     ? this.props.entity.estabelecimento.id
    //     : null
    // );
  }

  // verificaLimiteConvenios = (idEstabelecimento) => {
  //   if (idEstabelecimento) {
  //     convenioService.findAll(idEstabelecimento).then((response) => {
  //       this.setState({ limiteConvenios: response.data.length });
  //     });
  //   }
  // };
  joinDays = (dias) => {
    let _this = this;
    var out = [];
    dias = _.orderBy(dias, ['codigo'], ['asc']);
    for (var i = 0; i < dias.length; i++) {
      out.push(_this.getNomeDayOfWeek(dias[i]));
    }
    return out.join(', ');
  };

  getNomeDayOfWeek = (value) => {
    let dayOfWeek;
    let { daysOfWeek } = this.state;
    for (let i = 0; i < daysOfWeek.length; i++) {
      if (daysOfWeek[i].name === value.name) {
        dayOfWeek = daysOfWeek[i];
      }
    }
    return dayOfWeek.description;
  };

  validaHorariosEstabelecimento = (values, setFieldError) => {
    let _this = this;
    let promise = new Promise(function (resolve) {
      let horarios = values.horariosAtendimento;
      let response = true;
      const { fullEntity } = _this.props;
      let estabelecimentos = _this.state.horariosFuncionamento
        ? _this.state.horariosFuncionamento
        : values.estabelecimento;
      for (let i = 0; i < horarios.length; i++) {
        let dias = horarios[i].dias;
        let listaIntervalo = _.get(horarios[i], 'listaIntervalo');

        if (listaIntervalo) {
          for (let k = 0; k < listaIntervalo.length; k++) {
            if (
              dateHelper.verificaHoraUmMaiorQueDois(
                listaIntervalo[k].horaInicio,
                horarios[i].inicio
              )
            ) {
              response = true;
            } else {
              setFieldError(
                `horariosAtendimento[${i}].listaIntervalo[${k}].horaInicio`,
                'Horário de intervalo inicial anterior ao horário de atendimento'
              );
              response = false;
              break;
            }

            if (
              dateHelper.verificaHoraUmMaiorQueDois(
                horarios[i].fim,
                listaIntervalo[k].horaFim
              )
            ) {
              response = true;
            } else {
              setFieldError(
                `horariosAtendimento[${i}].listaIntervalo[${k}].horaFim`,
                'Horário de intervalo final posterior ao horario de atendimento'
              );
              response = false;
              break;
            }

            if (
              dateHelper.verificaHoraUmMaiorQueDois(
                listaIntervalo[k].horaFim,
                listaIntervalo[k].horaInicio
              )
            ) {
              response = true;
            } else {
              setFieldError(
                `horariosAtendimento[${i}].listaIntervalo[${k}].horaFim`,
                'Horário de intervalo final posterior ao horario inicial'
              );
              response = false;
              break;
            }
          }
        }

        if (dias && dias.length > 0) {
          for (let j = 0; j < dias.length; j++) {
            const codigo = dias[j].codigo;
            for (let h = 0; h < estabelecimentos.length; h++) {
              let diasEstabelecimento = estabelecimentos[h].dias;
              for (let index = 0; index < diasEstabelecimento.length; index++) {
                if (diasEstabelecimento[index].codigo === codigo) {
                  if (
                    dateHelper.verificaHoraUmMaiorQueDois(
                      horarios[i].inicio,
                      estabelecimentos[h].inicio
                    )
                  ) {
                    response = true;
                  } else {
                    setFieldError(
                      `horariosAtendimento[${i}].inicio`,
                      'Horário inicial anterior ao funcionamento do estabelecimento'
                    );
                    response = false;
                    break;
                  }
                  if (
                    dateHelper.verificaHoraUmMaiorQueDois(
                      estabelecimentos[h].fim,
                      horarios[i].fim
                    )
                  ) {
                    response = true;
                  } else {
                    setFieldError(
                      `horariosAtendimento[${i}].fim`,
                      'Horário final posterior ao funcionamento do estabelecimento'
                    );
                    response = false;
                    break;
                  }
                }
              }
            }
          }
        } else {
          alertActions.error('Selecione ao menos um dia para atendimento');
          response = false;
          break;
        }
      }
      resolve(response);
    });

    return promise;
  };

  addItemInArray = (
    propName,
    defaultValue,
    values,
    setValues,
    createAttribute?: boolean
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName]) {
      newValues[propName].unshift(
        defaultValue ? defaultValue : { idList: new Date().getTime() }
      );
      setValues(newValues);
    }
    if (!newValues[propName] && createAttribute) {
      _.set(newValues, `${propName}`, [{ idList: new Date().getTime() }]);
      setValues(newValues);
    }
  };

  removeHorarioAtendimento = () => {};

  removeItemInArray = (
    propName,
    indexItem,
    values,
    setValues,
    removerTodos: boolean = false
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1 || removerTodos) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };
  selecionouEstabelecimento = (
    entity,
    name,
    idMedico,
    setFieldError,
    setFieldValue
  ) => {
    let _this = this;
    if (this.temEstabelecimentoVinculado(entity.id)) {
      setFieldError(name, `Este estabelecimento já esta cadastrado`);
      setFieldValue(name, null);
      alertActions.error(
        'Este estabelecimento já esta cadastrado, selecione outro ou edite seus horário'
      );
    } else {
      let horariosFuncionamento = entity.listHorarioAtendimento;
      if (_.isEmpty(horariosFuncionamento)) {
        ConfirmAcationService.confirmMsg(
          () => {
            this.props.direcionaEstabelecimento(entity.id);
          },
          'Estabelecimento sem horário de atendimento',
          <span>
            Deseja ir para o estabelecimento para adicionar horário de
            funcionamento?
          </span>,
          <span className="text-danger">{}</span>,
          'Não',
          'Sim',
          objectsConstants.TYPE_CANCEL
        );
        this.setState({ estabelecimentoSemHorario: true });
      } else {
        estabelecimentoService
          .limiteDeLicencasAtingido(entity.id, idMedico)
          .then((response) => {
            if (!response.data) {
              this.setState({
                horariosFuncionamento,
                estabelecimentoSemHorario: false,
              });
            } else {
              this.setState({ estabelecimentoSemHorario: true });
              _this.props.error({
                message: translate('NUMBER_OF_LICENSES_EXCEEDED'),
              });
            }
          });
      }
    }
    // this.verificaLimiteConvenios(entity.id);
  };

  temEstabelecimentoVinculado = (idEstabelecimento) => {
    const { fullEntity } = this.props;
    let existe = false;
    let estabelecimentosMedico = _.get(
      fullEntity,
      'estabelecimentoMedicos',
      []
    );
    for (let i = 0; i < estabelecimentosMedico.length; i++) {
      if (
        _.get(estabelecimentosMedico[i], 'estabelecimento.id') ===
        idEstabelecimento
      ) {
        existe = true;
      }
    }
    return existe;
  };

  saveForm = () => {
    let _this = this;
    const { entity } = this.props;
    let values = _this.formRef.state.values;
    let setFieldError = _this.formRef.setFieldError;
    // _.set(values, 'medico.estabelecimentoMedicos', null);
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this
            .validaHorariosEstabelecimento(values, setFieldError)
            .then((response) => {
              if (response) {
                _this.props.loading(true);
                medicoService
                  .saveLocalHorarioAtendimento(entity.medico, values)
                  .then(
                    (response) => {
                      _this.props.success({
                        message: `Horário, foi ${
                          !values.id ? 'criado' : 'alterado'
                        } com sucesso!`,
                      });
                      _this.props.loading(false);
                      resolve(true);
                    },
                    (erros) => {
                      _this.props.loading(false);
                      // _this.props.error({
                      //   message:
                      //     'Não foi possível salvar horário de funcionamento.',
                      // });
                      try {
                        let response = erros.response.data;
                        if (response && response.messages) {
                          for (var i = 0; i < response.messages.length; i++) {
                            let erroItem = response.messages[i];
                            _this.formRef.setFieldError(
                              erroItem.fieldName,
                              translate(erroItem.message.code)
                            );
                            _this.props.error({
                              message: translate(erroItem.message.code),
                            });
                          }
                        }
                      } catch (error) {
                        console.log(error);
                      }
                      console.log(error);
                    }
                  );
              } else {
                resolve(false);
              }
            });
        } else {
          _this.props.error({
            message: 'O formulário contém erro(s)!',
          });
          console.log(error);
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  countConvenios = (convenios?: any) => {
    if (!convenios) {
      return 'Convênios';
    }
    if (convenios.length === 1) {
      return convenios.length + ' convênio';
    }
    if (convenios.length > 1) {
      return convenios.length + ' convênios';
    }
  };

  // verificaMaxConvenios = (values) => {
  //   let disabledButton;
  //   if (_.get(values, 'listEstabelecimentoMedicoConvenio')) {
  //     disabledButton =
  //       values.listEstabelecimentoMedicoConvenio.length >=
  //       this.state.limiteConvenios
  //         ? true
  //         : false;
  //   } else {
  //     disabledButton = false;
  //   }
  //   return disabledButton;
  // };

  render() {
    const { entity, viewMode, periodicidade, fullEntity } = this.props;
    const { horariosFuncionamento, estabelecimentoSemHorario } = this.state;

    return (
      <React.Fragment>
        <Formik
          validationSchema={LocaleHorarioFuncionamento}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldError,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-7 col-lg-5">
                    <InputViewEdit
                      component={FormSelectInput}
                      type="text"
                      name="estabelecimento"
                      label="Local de atendimento"
                      placeholder="Selecione o local do atendimento"
                      onChange={(name, date) => {
                        estabelecimentoService
                          .doGet(date.id)
                          .then((response) => {
                            this.selecionouEstabelecimento(
                              response.data.data,
                              name,
                              _.get(entity, 'medico.id', null),
                              setFieldError,
                              setFieldValue
                            );
                            convenioService
                              .findAll({ estabelecimento: date.id })
                              .then((r) => {
                                let listEstabelecimentoMedicoConvenio = [];
                                for (let convenio of r.data) {
                                  let convenioEstabelecimentoMedico = {
                                    listPlanoConvenio:
                                      convenio.listPlanoConvenio,
                                    convenio,
                                    integrarConvenio: true,
                                  };
                                  listEstabelecimentoMedicoConvenio.push(
                                    convenioEstabelecimentoMedico
                                  );
                                }
                                setFieldValue(
                                  'listEstabelecimentoMedicoConvenio',
                                  listEstabelecimentoMedicoConvenio
                                );
                              });
                          });
                        setFieldValue(name, date);
                      }}
                      noSize={true}
                      viewMode={viewMode}
                      id="estabelecimento"
                      returnFullObject={true}
                      valueKey={'id'}
                      labelKey={'nome'}
                      multi={false}
                      setFieldError={setFieldError}
                      service={estabelecimentoService.findAll}
                      value={values.estabelecimento}
                      erroMensagem={errors.estabelecimento}
                      required={true}
                      defaultValue={_.get(values, 'estabelecimento.nome')}
                    />
                  </FormGroup>

                  <div className="col-12 col-lg-4">
                    {/* {horariosFuncionamento && !viewMode && (
                      <label>Horário de funcionamento do estabelecimento</label>
                    )} */}
                    {horariosFuncionamento &&
                      !viewMode &&
                      horariosFuncionamento.map((horario, index) => {
                        return (
                          <React.Fragment key={nextId()}>
                            <p>
                              {horario.dias && this.joinDays(horario.dias)}
                              {' -  '}
                              {'  '}
                              <b>
                                {_.get(horario, `inicio`, '--')} {' ás  '}
                                {_.get(horario, `fim`, '--')}
                              </b>
                            </p>
                          </React.Fragment>
                        );
                      })}
                    {horariosFuncionamento.length < 1 &&
                      estabelecimentoSemHorario && (
                        <React.Fragment>
                          <p>
                            Adicionar horario de atendimento do estabelecimento
                          </p>
                        </React.Fragment>
                      )}
                  </div>
                </div>
              </div>
              <div className="agenda">
                <h2 className="w-100">Dias e horários de atendimento</h2>
                <div className="form-inside">
                  <div
                    className={` w-100 justify-content-lg-start justify-content-center pl-10px ${
                      viewMode ? 'd-none' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.addItemInArray(
                          'horariosAtendimento',
                          {
                            listHorarioAtendimentoMedicoAtender:
                              this.state.atenderSomente,
                          },
                          values,
                          setValues
                        )
                      }
                    >
                      Adicionar outro horário
                    </button>
                  </div>
                </div>

                {values.horariosAtendimento &&
                  !estabelecimentoSemHorario &&
                  values.horariosAtendimento.map((horario, index) => {
                    if (
                      !dateHelper.isBeforeToday(_.get(horario, 'dataFinal', ''))
                    ) {
                      return (
                        <React.Fragment
                          key={horario.id ? horario.id : uuidv4()}
                        >
                          {values.estabelecimento && (
                            <HorarioFuncionamento
                              tipoHorarioFuncionamento={
                                objectsConstants.TIPO_HORARIO_FUNCIONAMENTO_ESTABELECIMENTO_MEDICO
                              }
                              key={index * 13}
                              entity={horario}
                              index={index}
                              viewMode={viewMode}
                              size={values.horariosAtendimento.length}
                              propName={`horariosAtendimento[${index}]`}
                              onChange={setFieldValue}
                              errors={errors}
                              periodicidade={periodicidade}
                              estabelecimento={values.estabelecimento}
                              removeItem={() => {
                                this.removeItemInArray(
                                  `horariosAtendimento`,
                                  index,
                                  values,
                                  setValues
                                );
                              }}
                            ></HorarioFuncionamento>
                          )}
                        </React.Fragment>
                      );
                    } else {
                      return '';
                    }
                  })}
              </div>
              <div className="convenios">
                <h2 className="w-100">
                  {this.countConvenios(
                    _.get(values, 'listEstabelecimentoMedicoConvenio')
                  )}{' '}
                </h2>

                {!values.estabelecimento && (
                  <p className="pt-4 text-center">
                    É necessário selecionar um local de atendimento para
                    adicionar convênios.
                  </p>
                )}
                <div className="form-inside">
                  <div
                    className={`w-100 justify-content-lg-start justify-content-center pl-10px  ${
                      viewMode ? 'd-none' : ' d-flex '
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.addItemInArray(
                          'listEstabelecimentoMedicoConvenio',
                          null,
                          values,
                          setValues,
                          true
                        )
                      }
                    >
                      {values.listEstabelecimentoMedicoConvenio &&
                      values.listEstabelecimentoMedicoConvenio.length >= 1
                        ? 'Adicionar outro convênio'
                        : 'Adicionar convênio'}
                    </button>
                  </div>
                  {values.listEstabelecimentoMedicoConvenio &&
                    values.listEstabelecimentoMedicoConvenio.map(
                      (convenio, index) => {
                        return (
                          <React.Fragment
                            key={convenio && convenio.id ? convenio.id : index}
                          >
                            <ConveniosLimite
                              exibeCheckIntegracao={true}
                              size={
                                values.listEstabelecimentoMedicoConvenio.length
                              }
                              entity={values}
                              index={index}
                              viewMode={viewMode}
                              propName={`listEstabelecimentoMedicoConvenio[${index}]`}
                              onChange={setFieldValue}
                              removeItem={() => {
                                this.removeItemInArray(
                                  `listEstabelecimentoMedicoConvenio`,
                                  index,
                                  values,
                                  setValues,
                                  true
                                );
                              }}
                              errors={errors}
                            ></ConveniosLimite>
                          </React.Fragment>
                        );
                      }
                    )}
                </div>
              </div>

              <div className="info">
                <h2 className="w-100">
                  Informações e recomendações de atendimento
                </h2>
                <div className="form-inside">
                  <div className="row section-form">
                    <FormGroup className="col-6 col-lg">
                      <InputViewEdit
                        component={FormInput}
                        type="number"
                        label="Qtd. primeiras consultas por dia"
                        placeholder="Limite de primeiras consultas por dia"
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="qtdPrimeiraConsultaDia"
                        value={_.get(values, 'qtdPrimeiraConsultaDia')}
                        name="qtdPrimeiraConsultaDia"
                        erroMensagem={_.get(errors, 'qtdPrimeiraConsultaDia')}
                        required={false}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 col-lg">
                      <InputViewEdit
                        component={FormInput}
                        type="number"
                        label="Qtd. retornos por dia"
                        placeholder="Limite de retornos por dia"
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="qtdRetornoDia"
                        value={_.get(values, 'qtdRetornoDia')}
                        name="qtdRetornoDia"
                        erroMensagem={_.get(errors, 'qtdRetornoDia')}
                        required={false}
                      />
                    </FormGroup>

                    <FormGroup className="col-12  col-lg">
                      <InputViewEdit
                        component={MoneyInput}
                        type="text"
                        name="valorConsultaParticular"
                        label="Valor da consulta particular"
                        placeholder="Informe o valor da consulta"
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="valorConsultaParticular"
                        value={values.valorConsultaParticular}
                        erroMensagem={errors.valorConsultaParticular}
                      />
                    </FormGroup>
                  </div>
                  <div className="row section-form">
                    <FormGroup className={'col-12'}>
                      {!viewMode && (
                        <>
                          <label>Recomendações para o Paciente</label>
                          <FormTextCKEditor
                            id={'recomendacoes'}
                            name={'recomendacoes'}
                            label={'Recomendações'}
                            className={'textCKeditor'}
                            type={'textarea'}
                            disabled={false}
                            required={true}
                            onChange={setFieldValue}
                            erroMensagem={errors.recomendacoes}
                            viewMode={viewMode}
                            value={values.recomendacoes}
                            defaultValue={values.recomendacoes}
                            placeholder={
                              'Insira aqui as recomendações para serem enviadas para o paciente ao agendar'
                            }
                          />
                        </>
                      )}
                      {viewMode && (
                        <>
                          <label>
                            Recomendações para serem enviadas para o paciente
                          </label>
                          <p className="form-view">
                            {ReactHtmlParser(values.recomendacoes)}
                          </p>
                        </>
                      )}
                    </FormGroup>
                  </div>

                  <div className="row section-form">
                    <FormGroup className={'col-12'}>
                      {!viewMode && (
                        <>
                          <InputViewEdit
                            component={FormTextArea}
                            className={'textArea'}
                            label="Orientações a serem feitas para as secretárias"
                            id={`recomendacoesSecretaria`}
                            onChange={handleChange}
                            viewMode={viewMode}
                            placeholder="Insira aqui a prescrição"
                            value={_.get(values, `recomendacoesSecretaria`)}
                            name={`recomendacoesSecretaria`}
                            placeholder={'Orientação para agendamento.'}
                          />
                          {/* <FormTextArea
                            id={'recomendacoesSecretaria'}
                            name={'recomendacoesSecretaria'}
                            label={'Recomendações'}
                            className={'textCKeditor'}
                            type={'textarea'}
                            disabled={false}
                            required={true}
                            onChange={setFieldValue}
                            erroMensagem={''}
                            viewMode={viewMode}
                            value={values.recomendacoesSecretaria}
                            defaultValue={values.recomendacoesSecretaria}
                            placeholder={'Orientação para agendamento.'}
                          /> */}
                        </>
                      )}
                      {viewMode && (
                        <>
                          <label>
                            Orientações a serem feitas para as secretárias
                          </label>

                          <p className="form-view">
                            {ReactHtmlParser(values.recomendacoesSecretaria)}
                          </p>
                        </>
                      )}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(LocaleHorarioAtendimento);
