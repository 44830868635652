import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DadosUser from '../../../components/DadosUser/DadosUser';
import { FilaAtendimentoRow } from './FilaAtendimentoRow';
import './FilaAtendimento.scss';
import { connect } from 'react-redux';
import ClockIcon from '../../../images/icones/clock-icon.svg';
import SemAgendaIcon from '../../../images/icones/icon-sem-agenda.svg';
import ComAgendaIcon from '../../../images/icones/icon-com-agenda.svg';
import PersonLocation from '../../../images/icones/icon-person-location.svg';
import Avatar from '../../../components/Avatar/Avatar';
import { FilaAtendimentoRowPacienteSemAgenda } from './FilaAtendimentoRowPacienteSemAgenda';
import moment from 'moment';
import { formatterHelper } from '../../../_helpers/formatter.helper';
import { ModalFilaAtendimento } from '../../../components/Modal/ModalFilaAtendimento';
import FilaAtendimento from './FilaAtendimento';
import { userService } from '../../../_services/user.service';
import FilaPage from '../../fila/FilaPage';
import { Ability } from '@casl/ability';
import { objectsConstants } from '../../../_constants/objects.constants';
export default class FilaAtendimentoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showFilaAtendimento: false };
  }
  toggleFilaAtendimento = () => {
    this.setState(({ showFilaAtendimento }) => ({
      showFilaAtendimento: !showFilaAtendimento,
    }));
  };

  render() {
    const { filterMarcacoesSecretaria, fila, statusChange } = this.props;
    const { showFilaAtendimento } = this.state;
    return (
      <React.Fragment>
        <button
          className="btn btn-success m-3"
          onClick={this.toggleFilaAtendimento}
          disabled={!filterMarcacoesSecretaria.length && !fila.length}
        >
          Pacientes aguardando: {filterMarcacoesSecretaria.length + fila.length}
        </button>

        <ModalFilaAtendimento
          onStatusChanged={statusChange}
          fila={this.props.fila}
          user={this.props.user}
          loading={this.props.loading}
          error={this.props.error}
          success={this.props.success}
          title="Pacientes em espera"
          toogle={this.toggleFilaAtendimento}
          isOpen={this.state.showFilaAtendimento}
          Componente={FilaPage}
          marcacoes={filterMarcacoesSecretaria}
          estabelecimento={{
            id: userService.getExtension(),
          }}
          onChange={(paciente, cpf) => {
            this.setState(
              {
                showFilaAtendimento: !this.state.showFilaAtendimento,
              },
              () => {
                this.props.onChange(paciente, cpf);
              }
            );
          }}
        />
      </React.Fragment>
    );
  }
}
