import * as React from 'react';
import {connect} from 'react-redux';
import {Card} from "react-bootstrap";
import {ResponsiveBullet} from "@nivo/bullet";
import {BasicTooltip} from '@nivo/tooltip'
import {Md5} from "ts-md5";

/**
 * Props
 *
 */
type Props = {
    tempoAtendimentoParticular: DataTempoAtendimento;
    tempoAtendimentoConvenio: DataTempoAtendimento;
};

/**
 * State
 *
 */
type State = {
    dataChartTempoAtendimento: Array<DataChartTempoAtendimeto>;
    hashProps: string;
    hasData: boolean;
}

/**
 * Data interface
 *
 */
interface DataTempoAtendimento {
    minimo: number;
    medio: number;
    maximo: number;
}

/**
 * Date interface for chart time
 *
 */
interface DataChartTempoAtendimeto {
    id: string;
    ranges: Array<number>;
    measures: Array<number>;
    markers: Array<number>;
}

/**
 * Fixed ranges for time
 *
 * @type {number[]}
 */
const ranges = [0, 60, 120];

/**
 * Custom tooltip for chart tempo de atendimento
 *
 * @param v0 - v0
 * @param v1 - v1
 * @param color - color
 * @returns {JSX.Element}
 * @constructor
 */
const CustomTooltip = ({v0, v1, color}: { color: string; v0: number; v1?: number }) => {
    return (
        <BasicTooltip
            id={
                v1 ? (
                    <span>
                            <strong>{v0}</strong> a <strong>{v1}</strong>
                        </span>
                ) : (
                    <strong>{v0}</strong>
                )
            }
            enableChip={true}
            color={color}
        />
    )
}

/**
 * Chart Dashboard Component
 *
 */
class ChartTempoAtendimentoDashboardComponent extends React.Component<Props, State> {

    /**
     * Default constructor
     *
     * @param props
     */
    constructor(props: any) {
        super(props);
        this.state = this.getInitialState();
    }

    /**
     * Initial state
     *
     * @return {}
     */
    getInitialState() {
        return {
            dataChartTempoAtendimento: [{
                id: "Particular",
                ranges: ranges,
                measures: [0],
                markers: [
                    this.props.tempoAtendimentoParticular.minimo,
                    this.props.tempoAtendimentoParticular.medio,
                    this.props.tempoAtendimentoParticular.maximo
                ]
            },
                {
                    id: "Convênio",
                    ranges: ranges,
                    measures: [0],
                    markers: [
                        this.props.tempoAtendimentoConvenio.minimo,
                        this.props.tempoAtendimentoConvenio.medio,
                        this.props.tempoAtendimentoConvenio.maximo
                    ]
                }],
            hashProps: this.generateHasProps(
                this.props.tempoAtendimentoParticular,
                this.props.tempoAtendimentoConvenio
            ),
            hasData: this.hasData()
        };
    }

    /**
     * Responsible change componente.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        let newHashProps: string = this.generateHasProps(
            this.props.tempoAtendimentoParticular,
            this.props.tempoAtendimentoConvenio
        );
        if (newHashProps !== prevState.hashProps) {
            this.setState(this.getInitialState());
        }
    }

    /**
     * Responsible generate hash.
     *
     * @param tempoAtendimentoParticular
     * @param tempoAtendimentoConvenio
     * @return {string}
     */
    generateHasProps = (tempoAtendimentoParticular: DataTempoAtendimento, tempoAtendimentoConvenio: DataTempoAtendimento): string => {
        return Md5.hashStr(
            JSON.stringify(tempoAtendimentoParticular) +
            JSON.stringify(tempoAtendimentoConvenio)
        )
    }

    /**
     *
     * Responsible validate if has data.
     *
     * @return {boolean}
     */
    hasData = (): boolean => {
        return (this.props.tempoAtendimentoParticular && this.props.tempoAtendimentoConvenio)
            && (
                !isNaN(this.props.tempoAtendimentoParticular.minimo)
                && !isNaN(this.props.tempoAtendimentoParticular.medio)
                && !isNaN(this.props.tempoAtendimentoParticular.maximo)
                && !isNaN(this.props.tempoAtendimentoConvenio.minimo)
                && !isNaN(this.props.tempoAtendimentoConvenio.medio)
                && !isNaN(this.props.tempoAtendimentoConvenio.maximo)
            )
            && (
                this.props.tempoAtendimentoParticular.minimo > 0
                || this.props.tempoAtendimentoParticular.medio > 0
                || this.props.tempoAtendimentoParticular.maximo > 0
                || this.props.tempoAtendimentoConvenio.minimo > 0
                || this.props.tempoAtendimentoConvenio.medio > 0
                || this.props.tempoAtendimentoConvenio.maximo > 0
            )
    }

    /**
     * Render component
     *
     * @returns {JSX.Element}
     */
    render() {
        return (
            <React.Fragment>
                <Card className="chart-card bg-light pr-0 pl-0">
                    <Card.Body className="chart-card-body pr-0 pl-0">
                        <Card.Title
                            className="chart-card-title d-flex justify-content-center">Tempo Atendimento</Card.Title>
                        {!this.state.hasData && (
                            <React.Fragment>
                                <div className="chart-text-blank">Não existem dados a serem exibidos para essa
                                    pesquisa.
                                </div>
                            </React.Fragment>
                        )}
                        {this.state.hasData && (
                            <React.Fragment>
                                <div className="chart-card-h85p">
                                    <ResponsiveBullet
                                        data={this.state.dataChartTempoAtendimento}
                                        layout="vertical"
                                        spacing={80}
                                        margin={{right: 70, left: 50, top: 50, bottom: 15}}
                                        titleAlign="start"
                                        titleOffsetX={0}
                                        titleOffsetY={-15}
                                        titleRotation={-40}
                                        measureSize={0.15}
                                        measureBorderWidth={0}
                                        rangeColors={["#8ea2c0", "#013A66FF"]}
                                        markerColors={['#7c8657', '#b95722', '#831a26']}
                                        markerSize={0.8}
                                        tooltip={CustomTooltip}
                                        animate={true}
                                    />
                                </div>
                                <div className="chart-tempo-atendimento-lengenda-main d-flex justify-content-center">
                                    <hr className="chart-tempo-atendimento-legenda chart-tempo-atendimento-minimo"/>
                                    <span>Minimo</span>
                                    <hr className="chart-tempo-atendimento-legenda chart-tempo-atendimento-medio"/>
                                    <span>Médio</span>
                                    <hr className="chart-tempo-atendimento-legenda chart-tempo-atendimento-maximo"/>
                                    <span>Máximo</span>
                                </div>
                            </React.Fragment>
                        )}
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}

/**
 * Export default connect
 *
 */
export default connect(null, null, null)(ChartTempoAtendimentoDashboardComponent);