import React from 'react';
import './MultiStepProgressBar.css';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

const MultiStepProgressBar = (props) => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 20;
  } else if (props.currentStep === 3) {
    stepPercentage = 40;
  } else if (props.currentStep === 4) {
    stepPercentage = 60;
  } else if (props.currentStep === 5) {
    stepPercentage = 80;
  } else if (props.currentStep === 6) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <React.Fragment>
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            ></div>
            <div className="labelProgBar">
              <p>PASSO 1</p>
              {/* <span>Defina seu e-mail</span> */}
            </div>
          </React.Fragment>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <React.Fragment>
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            ></div>
            <div className="labelProgBar">
              <p>PASSO 2</p>
              {/* <span>Defina seu e-mail</span> */}
            </div>
          </React.Fragment>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <React.Fragment>
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            ></div>
            <div className="labelProgBar">
              <p>PASSO 3</p>
              {/* <span>Defina seu e-mail</span> */}
            </div>
          </React.Fragment>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <React.Fragment>
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            ></div>
            <div className="labelProgBar">
              <p>PASSO 4</p>
              {/* <span>Defina seu e-mail</span> */}
            </div>
          </React.Fragment>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <React.Fragment>
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            ></div>
            <div className="labelProgBar">
              <p>PASSO 5</p>
              {/* <span>Defina seu e-mail</span> */}
            </div>
          </React.Fragment>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <React.Fragment>
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            ></div>
            <div className="labelProgBar">
              <p>PASSO 6</p>
              {/* <span>Defina seu e-mail</span> */}
            </div>
          </React.Fragment>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
