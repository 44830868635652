import * as React from "react";
import IconPagamento from "../../images/icon-payment.svg";
import "./Triagem.css";
import _ from 'lodash';
export default class TriagemClassificacaoDor extends React.Component<{}> {
    constructor(props) {
        super(props);
    }
    handleChange=(values,subItem,item)=>{
        let newSubItem = _.cloneDeep(subItem);
        _.set(newSubItem,'itemSelecao',item);
        let subItensSelecionados = _.cloneDeep(_.get(values,'subItensSelecionados',[]));
        if(_.filter(subItensSelecionados,(s)=>{return s.id===newSubItem.id}).length>0){
            subItensSelecionados.splice (subItensSelecionados.indexOf(newSubItem),1);
        }else {
            subItensSelecionados.push(newSubItem);
            let itensRemover = _.filter(subItensSelecionados,(s)=>{
                return s.id!=newSubItem.id && item.id==s.itemSelecao.id;
            });
            if(itensRemover&& itensRemover.length>0){
                itensRemover.map(ir=>{
                    subItensSelecionados.splice (subItensSelecionados.indexOf(ir),1);
                })

            }
        }
        this.props.setFieldValue('subItensSelecionados', subItensSelecionados);
    }
    render() {
        const {values} = this.props;
        let _this = this;
        console.log(values)
        return (

            <React.Fragment>

                {this.props.listParamTriagem.map(param => {
                    return <React.Fragment>
                        <label>{param.nome}</label>

                        <div className="row conteiner-classificacao m-1 ">
                            {param.itensSelecao.map(item => {
                                return (
                                    <React.Fragment>

                                        <div className=" mb-3  col-12 col-lg text-center ">
                                            <div className="row px-3">
                                            {item.subItensSelecao.map(subItem=>{
                                                return (
                                                    <button
                                                        className={`btn-classificacao col-lg ${_.get(values,'subItensSelecionados',[]).length>0&& _.filter(_.get(values,'subItensSelecionados',[]),(s)=>{return s.id===subItem.id}).length==0? 'opacity' : ''}`}
                                                        type={'button'}
                                                        style={{backgroundColor:item.color}}
                                                        onClick={() => {
                                                            if(!this.props.viewMode) {
                                                                _this.handleChange(values, subItem, item);
                                                            }
                                                        }}> {subItem.nome}
                                                    </button>
                                                )
                                            })}
                                            </div>
                                            <div className=" card-classificacao-sem-dor "  style={{backgroundColor:item.color}}>
                                                {item.nome}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}





                        </div>

                    </React.Fragment>
                })}


            </React.Fragment>
        );
    }
}
