//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { exameService } from '../../_services/exame.service';
import ExameListFilter from './ExameListFilter';

const columns = [
    {
        path: 'nome',
        pathFilter: 'nome',
        title: 'Nome do exame',
        isKey: true,
        dataSort: true,
        className: 'column-bold',
        width: '33%',
    },
    {
        path: 'procedimento.codigo',
        pathFilter: 'codigoProcedimento',
        title: 'Código do procedimento',
        isKey: false,
        dataSort: true,
        className: 'column-bold',
        width: '33%',
    },
    {
        path: 'procedimento.descricao',
        pathFilter: 'descricaoProcedimento',
        title: 'Descrição do procedimento',
        isKey: false,
        dataSort: true,
        className: 'column-bold',
        width: '33%',
    }
];

const endPoint = '/exame/novo';
const endPointView = '/exame/view/';
class ExameListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
        _.filter(columns, function (coluna) {
          return !coluna.notExport;
        }),
        function (coluna) {
          return coluna.path;
        }
    );

    let labels = _.map(
        _.filter(columns, function (coluna) {
          return !coluna.notExport;
        }),
        function (coluna) {
          return coluna.title;
        }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
        <TableHeaderColumn
            key={index}
            dataField={column.path}
            dataFormat={tableHelper.renderCustom}
            formatExtraData={column}
            isKey={column.isKey}
            dataSort={column.dataSort}
            hidden={column.hidden}
            width={column.width}
            className={column.classname}
        >
          {column.title}
        </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
          ? this.props.extension
          : this.props.user.extension,
    };

    return (
        <React.Fragment>
          <TopoTitleComponente
              mainTitle={objectsConstants.TITULO_PAGINA_EXAMES}
              subTitle=" "
              canBack={false}
          />

          <TopoListagem
              securityActionDo={objectsConstants.VIEW}
              securityActionOn={objectsConstants.CRUD_MEDICO_EDITAR}
              buttonActionClassName={'btn-light'}
              buttonAction={() => {
                this.handleNewButton();
              }}
              securityActionIcon={'icon-btn-add'}
              securityActionName={'Novo exame'}
          ></TopoListagem>
          <ListagemPage
              title={`Exames`}
              showNewButton={false}
              delete={() => {}}
              service={exameService}
              defaultOrder={'nome'}
              defaultDirection={'asc'}
              defaultFilter={filter}
              onRowClick={this.handleRowClick}
              FormFilter={ExameListFilter}
              fields={this.state.fields}
              controller="api/exame"
              labels={this.state.labels}
              columns={columns}
          >
            {tableColumn}
          </ListagemPage>
        </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(ExameListagemPage);