//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconAgenda from '../../images/icon-agendaMedica.svg';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
  addlocal: any,
};

type State = {
  id?: number,
};

export default class LocalAtendimento extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="card-localAtendimento d-flex flex-column flex-xl-row">
          <div className="col-12 col-xl-2 d-flex justify-content-center">
            <img src={IconAgenda} alt="Imagem de agenda" />
          </div>
          <div className="col-12 col-xl-5 p-0">
            <h1>Agenda do profissional</h1>
            <p>
              Insira aqui os locais, dias e horários de atendimentos. Este
              cadastro será utilizado para disponibilizar horários na sua
              agenda.
            </p>
          </div>
          <div className="col-12 col-xl p-0 d-flex align-items-center justify-content-around justify-content-xl-center">
            <button
              type="button"
              className="btn btn-secondary m-0"
              onClick={this.props.addItem}
            >
              Adicionar local e horário
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
