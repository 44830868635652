import { PureComponent } from "react";
import { connect } from "react-redux";
import { taskService } from "../../_services/taskService";
import _ from 'lodash';
type Props = {
  id: number,
  urlConect: String
};
type State = {
  entity: any
};

class TaskSocketConect extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      client: null
    }
  }


  componentDidMount() {
    taskService.findByFilter().then(response => {
      this.props.addTask(response.data.data);
      if (this.props.client) {
        this.props.client.subscribe(taskService.getWsTopic(), this.handleTasks)
      }
    });

  }

  handleTasks = (messageOutput) => {
    let tarefas = JSON.parse(messageOutput.body);

    let taskListModel = _.cloneDeep(this.props.taskList);
    if (taskListModel) {
      if (tarefas.concluida) {
        let notificacoes = _.filter(taskListModel, (task) => {
          return task.id != tarefas.id;
        });
        this.props.addTask({
          notificacoes, size: notificacoes.length
        })
      } else {
        taskListModel.push(tarefas)
        this.props.addTask({ notificacoes: taskListModel, size: taskListModel.length })
      }
    } else {
      console.log('tasklist model undefined');
    }

  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { client } = state.webSocket;
  const { taskList } = state.task;
  return {
    client,
    taskList
  };
}

const mapDispatch = ({ task: { addTask, conectSocket } }) => ({
  addTask: listTask => addTask(listTask),
  conectSocket: socket => conectSocket(socket)
});

const connectedTaskSocketConect = connect(
  mapStateToProps,
  mapDispatch
)(TaskSocketConect);
export { connectedTaskSocketConect as TaskSocketConect };

