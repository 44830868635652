//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import {objectsConstants} from '../../_constants/objects.constants';
import GerarVoucherForm from "./GerarVoucherForm";
import {voucherService} from "../../_services/voucher.service";
import {ConfirmAcationService} from "../../_services/ConfirmAcationService";

class VoucherView extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            entity: {status: true},
        };
    }

    componentDidMount() {
        this.loadVoucher();
    }

    goToViewEntity = () => {
        this.props.history.push("/voucher-soluti");
    };

    loadVoucher = () => {
        if (this.props.match.params.id) {
            this.props.loading(true);
            voucherService.findById(this.props.match.params.id).then(
                (response) => {
                    if (response) {
                        response.nameOrCpfcNPJ = {
                            id: response.userVO.id,
                            cpfCnpjJoinName: response.userVO.cpfCnpjJoinName
                        }
                        this.setState({entity: _.cloneDeep(response)}, () => {
                            this.props.loading(false);
                        });
                    }
                }
            );
        }
    };

    printVoucher() {
        this.props.loading(true);
        voucherService.printVoucher(this.state.entity.voucherNumber).then((response) => {
            window.open(URL.createObjectURL(new Blob([new Uint8Array([...atob(_.get(response, 'data'))].map((char) => char.charCodeAt(0)))], { type: 'application/pdf' })));
            this.props.loading(false);
        });
    }

    cancelVoucher() {
        this.props.loading(true);
        voucherService.cancelVoucher(this.state.entity.voucherNumber).then(
            (response) => {
            this.props.loading(false);
            if(response.statusFinal === "SUCCESS") {
                this.props.success({message: response.formatMsg});
                this.goToViewEntity();
            }else {
                this.props.error({message: response.formatMsg});
            }
        });
    }

    cancelVoucherConfirm() {
        ConfirmAcationService.confirmMsg(
            () => {this.cancelVoucher()},
            `Cancelamento Voucher`,
            `Você tem certeza que deseja cancelar o voucher?`,
            null,
            'Não',
            'Sim',
            objectsConstants.TYPE_EXCLUIR
        );
    }

    getEditDeleteBtn = () => {
        if(this.state.entity.status === "ENABLE") {
            return (
                <React.Fragment>
                    <button
                        type="button"
                        className={`btn btn-link`}
                        onClick={() => {this.cancelVoucherConfirm()}}>
                        <span className="icon-btn-deletar"></span>
                        Cancelar Voucher
                    </button>

                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {this.printVoucher()}}>
                        <span className="icon-btn-voucher"></span>
                        Imprimir voucher
                    </button>
                </React.Fragment>
            );
        }else {
            return (<React.Fragment />);
        }
    };

    render() {
        const {entity} = this.state;
        const {...otherPops} = this.props;
        return (
            <React.Fragment>
                <TopoTitleComponente
                    mainTitle={entity.suggestionName + " - " + entity.voucherNumber}
                    canBack={true}
                    backUrl={'/voucher-soluti'}
                />

                <div className="flex-column mt-4">
                    <FullCustomCard
                        permissionSave={objectsConstants.MENU_VOUCHER}
                        title="Soluti Voucher"
                        Componente={GerarVoucherForm}
                        viewMode={true}
                        entity={entity}
                        getEditDeleteBtn={this.getEditDeleteBtn}
                        {...otherPops}
                    ></FullCustomCard>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
                         alert: {success, error, clear},
                         load: {loading},
                     }) => ({
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({load}),
    error: (msg) => error(msg),
    clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(VoucherView));
