//@flow
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Client } from 'webstomp-client';
import BarraFiltro from '../../components/BarraFiltro/BarraFiltro';
import { Calendar } from '../../components/calendar/Calendar';
import { CalendarDateView } from '../../components/calendar/CalendarDateView';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import { translate } from '../../_helpers/messages.helper';
import { agendaService } from '../../_services/agenda.service';
import { marcacaoService } from '../../_services/marcacao.service';
import type { medico } from '../../_types/medico.type';
import { urlsConstants } from './../../_constants/urls.constant';
import {
  filterMarcacoes,
  updateMarcacao,
} from './../../_helpers/agenda.helper';
import { userService } from './../../_services/user.service';
import AgendaHorariosPage from './../agenda/AgendaHorariosPage';

type Props = {
  medico: medico,
  dados: any,
  dispatch: any,
  client: Client,
  showDivMedico: boolean,
};

type State = {
  viewHorario: boolean,
  medicos: any,
  canal?: any,
};

class ReagendamentoPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let dataAtual = moment();
    this.state = {
      filtro: props.filter ? props.filter : {},
      marcacao: this.props.location.state.marcacao
        ? this.props.location.state.marcacao
        : {},
    };
  }

  componentDidMount() {
    this.handleFilterAgenda(this.state.filtro);
  }

  handleDateClick = (dataAtualAgenda, maxMarcacaoDia, viewHorario = true) => {
    this.props.receiveDadosAgenda(maxMarcacaoDia, dataAtualAgenda, viewHorario);
  };

  conectChanels = () => {
    if (this.props.client && this.props.client.connected) {
      const canalAtivo = this.state.canal;
      if (canalAtivo) {
        canalAtivo.unsubscribe();
      }
      let { estabelecimento } = this.state.filtro;
      let id;
      if (userService.isMedico()) {
        id = userService.getCurrentUser().id;
      } else if (estabelecimento) {
        id = estabelecimento.id;
      } else {
        id = userService.getExtension();
      }
      if (id) {
        let canal = this.props.client.subscribe(
          urlsConstants.SUBSCRIBE_PACIENTE_STATUS_CHANGE + id,
          this.handleDataUpdate
        );
        this.setState({ canal });
      }
    }
  };

  // handleDataUpdate = (messageOutput: any) => {
  //   let marcacao = JSON.parse(messageOutput.body);
  //   let agendas = updateMarcacao(this.props.agendas, marcacao);
  //   this.props.receiveAgenda(agendas);
  // };

  handleStatusChanged = (
    idMarcacao: number,
    status: string,
    tipoAtendimento: string
  ) => {
    if (this.props.client.connected) {
      this.props.client.send(
        urlsConstants.SEND_PACIENTE_STATUS,
        JSON.stringify({ id: idMarcacao, status, tipoAtendimento })
      );
    }
  };

  handleMarcacaoCreated = (entidade: any) => {
    this.props.loading(true);
    if (this.props.client.connected) {
      // this.props.client.send(
      //   urlsConstants.SEND_MARCACAO_NEW,
      //   JSON.stringify(entidade)
      // );
      if (this.state.marcacao) {
        this.props.client.send(
          urlsConstants.SEND_PACIENTE_STATUS,
          JSON.stringify({
            id: this.state.marcacao.id,
            status: objectsConstants.CANCELADO,
          })
        );
        console.log(entidade);
        _.set(entidade, 'tipo', objectsConstants.TIPO_MARCACAO);
        console.log(entidade);
        marcacaoService.doSave(entidade).then(
          (response) => {
            setTimeout(() => {
              this.props.receiveViewHorario(false);
              this.props.success({
                message: `Paciente reagendado com sucesso!`,
              });
              // this.handleFilterAgenda({});
              this.props.history.push('/agenda');
              this.props.loading(false);
            }, 4000);
          },
          (erros) => {
            console.log(erros);
            this.props.error({ message: `Erro ao reagendar horário` });
            try {
              let response = erros.response.data;
              if (response && response.messages) {
                for (var i = 0; i < response.messages.length; i++) {
                  let erroItem = response.messages[i];
                  this.props.error({
                    message: translate(erroItem.message.code),
                  });
                }
              }
              this.props.loading(false);
            } catch (error) {
              this.props.loading(false);
              console.log(error);
            }
          }
        );
      } else {
        this.props.loading(false);
        console.log('Não está conectado no socket');
      }
    }
  };

  setAgendaDoDia = (agendaDoDia) => {
    this.props.receiveAgendaDoDia(agendaDoDia);
    // this.setState({ agendaDoDia });
  };

  changeFilterTarefas = () => {
    let newFiltro = _.cloneDeep(this.state.filtro);
    if (
      newFiltro.estabelecimentoMedico &&
      newFiltro.estabelecimentoMedico.medico
    ) {
      this.props.setFiltroRemarcacao(this.state.filtro);
      _.set(newFiltro, 'especialidades', newFiltro.medico.especialidades);
      var result = _.omit(newFiltro, ['estabelecimentoMedico', 'medico']);
      this.handleFilterAgenda(result);
    } else {
      newFiltro = this.props.filtroRemarcacao;
      this.handleFilterAgenda(newFiltro);
    }
  };

  handleFilterAgenda = (filtro) => {
    this.props.loading(true);
    const { year, month, tipoAgenda } = this.props;
    this.props.fechaFiltro();
    let now = new Date();
    let dataStart = new Date(year, month, 1, 0, 0, 0);
    let dataEnd = new Date(year, month + 1, 0, 23, 59);
    filtro.tipoAgenda = tipoAgenda;
    filtro.dataStart = moment(dataStart).format('YYYY-MM-DDTHH:mm:ss');
    filtro.dataEnd = moment(dataEnd).format('YYYY-MM-DDTHH:mm:ss');
    filtro.medico = _.get(filtro, 'estabelecimentoMedico.medico');
    filtro.medicoList = _.get(filtro, 'medicoList');
    this.conectChanels();
    console.log('handleFilterAgenda');
    this.setState({ filtro }, () => {
      this.props.changeFilter(filtro);
      console.log('changeFilter');
      console.log(filtro);
      agendaService.findHorariosConsulta(filtro).then(
        (json) => {
          console.log('Recebeu a Agenda');
          this.props.loading(false);
          this.props.receiveAgenda(json.data);
        },
        (error) => {
          if (tipoAgenda === objectsConstants.TIPO_AGENDA[0].name) {
            this.props.error({
              message: 'Não foi possível atualizar a agenda de CONSULTAS.',
            });
          } else if (tipoAgenda === objectsConstants.TIPO_AGENDA[1].name) {
            this.props.error({
              message: 'Não foi possível atualizar a agenda de EXAMES.',
            });
            this.props.receiveAgenda({});
          }

          this.props.loading(false);
        }
      );
    });
  };

  render() {
    const { ...otherProps } = this.props;
    const { remarcacao, marcacao } = this.state;
    _.set(marcacao.paciente, 'tipoConsulta', marcacao.tipoConsulta);
    return (
      <div className="px-0 px-xl-4">
        {!this.props.viewHorario && (
          <TopoTitleComponente
            mainTitle={'Selecione um dia disponível'}
            subTitle=""
            canBack={true}
          />
        )}

        {/* {!userService.isMedico() && (
          <React.Fragment>
            <div
              className={`icon-filtro d-flex align-items-center justify-content-center fixed`}
              onClick={() => {
                this.props.abreFiltro();
              }}
            >
              <img src={IconFiltro} alt="Filtra" />
            </div>
          </React.Fragment>
        )} */}

        <div className="container-fluid">
          {(this.state.filtro.especialidades || this.state.filtro.medicoList) &&
            this.state.filtro.tipoAgenda && (
              <BarraFiltro
                remarcacao={true}
                limpaFiltro={this.handleFilterAgenda}
                changeFilterTarefas={this.changeFilterTarefas}
              ></BarraFiltro>
            )}
          {this.props.viewHorario ? (
            <React.Fragment>
              <div className="d-flex justify-content-center lista-horarios">
                <CalendarDateView
                  data={this.props.dataAtualAgenda}
                  agendas={this.props.agendas}
                  onMonthChange={this.handleMonthChange}
                  arrowNavigation={true}
                  arrowClick={this.handleDateClick}
                  className="justify-content-between"
                />
              </div>
              <div className="row">
                <AgendaHorariosPage
                  marcacao={marcacao}
                  date={this.props.dataAtualAgenda}
                  maxMarcacaoDia={this.props.maxMarcacaoDia}
                  agendas={this.props.agendas}
                  onStatusChanged={this.handleStatusChanged}
                  onGoBack={this.handleOnGoBack}
                  goBackCache={this.OnGoBackCache}
                  onMarcacaoCreated={this.handleMarcacaoCreated}
                  filtro={this.state.filtro}
                  year={this.props.year}
                  month={this.props.month}
                  tipoAgenda={marcacao.tipoAgenda}
                  reagendamento={true}
                  {...otherProps}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Calendar
                agendas={this.props.agendas}
                onClick={this.handleDateClick}
                onMonthChange={this.handleMonthChange}
                year={this.props.year}
                month={this.props.month}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  handleOnGoBack = () => {
    let viewHorario = false;
    this.props.receiveViewHorario(viewHorario);
  };

  handleMonthChange = (year: any, month: any) => {
    this.props.receiveMonthAndYear(month, year).then((response) => {
      this.handleFilterAgenda(this.state.filtro);
    });
  };

  findMarcacoes = () => {
    return filterMarcacoes(this.props.dados, this.props.dataAtualAgenda);
  };
}

const mapDispatch = ({
  agenda: {
    receiveData,
    changeFilter,
    receiveAgenda,
    receiveDadosAgenda,
    receiveViewHorario,
    setFiltroRemarcacao,
    setCanalSocket,
    receiveMonthAndYear,
    receiveTipoAgenda,
  },
  load: { loading },
  filtromenu: { toggleFiltro, abreFiltro, fechaFiltro },
  indisponibilidademenu: {
    toggleIndisponibilidade,
    abreIndisponibilidade,
    fechaIndisponibilidade,
  },
  alert: { success, error },
}) => ({
  setCanalSocket: (canal) => setCanalSocket({ canal }),
  receiveAgenda: (agendas, source) => receiveAgenda({ agendas, source }),
  setFiltroRemarcacao: (filtroRemarcacao) =>
    setFiltroRemarcacao({ filtroRemarcacao }),
  receiveDadosAgenda: (maxMarcacaoDia, dataAtualAgenda, viewHorario) =>
    receiveDadosAgenda({
      maxMarcacaoDia,
      dataAtualAgenda,
      viewHorario,
    }),
  receiveMonthAndYear: (month, year) => receiveMonthAndYear({ month, year }),
  receiveTipoAgenda: (tipoAgenda) => receiveTipoAgenda({ tipoAgenda }),
  receiveViewHorario: (viewHorario) => receiveViewHorario({ viewHorario }),
  changeFilter: (filter) => changeFilter({ filter: filter }),
  receiveData: (data, medico) => receiveData({ data, medico }),
  loading: (load: boolean) => loading({ load }),
  toggleFiltro: () => toggleFiltro(),
  abreFiltro: () => abreFiltro({}),
  fechaFiltro: () => fechaFiltro(),
  toggleIndisponibilidade: () => toggleIndisponibilidade(),
  abreIndisponibilidade: () => abreIndisponibilidade({}),
  fechaIndisponibilidade: () => fechaIndisponibilidade(),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
});

function mapStateToProps(state) {
  const {
    dados,
    medico,
    showDivMedico,
    filter,
    agendas,
    maxMarcacaoDia,
    dataAtualAgenda,
    viewHorario,
    filtroRemarcacao,
    canal,
    month,
    year,
    tipoAgenda,
  } = state.agenda;
  const { filtroAberto } = state.filtromenu;
  const { indisponibilidadeAberta } = state.indisponibilidademenu;
  const { webSocketConnected } = state.webSocket;
  return {
    dados,
    medico,
    showDivMedico,
    filtroAberto,
    indisponibilidadeAberta,
    filter,
    agendas,
    maxMarcacaoDia,
    dataAtualAgenda,
    viewHorario,
    filtroRemarcacao,
    webSocketConnected,
    canal,
    month,
    year,
    tipoAgenda,
  };
}

const connectedAgendaPage = connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ReagendamentoPage));
export { connectedAgendaPage as ReagendamentoPage };
