//@flow
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import nextId from 'react-id-generator';
import { useValidationsBR } from 'validations-br';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { legendHelper } from '../../_helpers/legend.helper';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { marcacaoService } from '../../_services/marcacao.service';
import { reservarHorarioService } from '../../_services/reservarHorario.service';
import { userService } from '../../_services/user.service';
import DadosUser from '../../components/DadosUser/DadosUser';
import IconDelete from '../../images/icon_x_red.svg';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
import ButtonAutorized from '../buttons/ButtonAutorized';
import { PacienteSelect } from './../inputs/PacienteSelect';

import { Can } from '@casl/react';
import { v4 as uuidv4 } from 'uuid';
import { shouldComponentUpdate } from '../../_helpers/render.helper';
import { filaService } from '../../_services/fila.service';
import { paramTriagemService } from '../../_services/paramTriagem.service';
import TriagemPage from '../../pages/triagem/TriagemPage';
import { ModalCrud } from '../Modal/ModalCrud';
import { ModalReservarHorario } from '../Modal/ModalReservarHorario';
import ButtonAutorizedChangeStatusMarcacao from '../buttons/ButtonAutorizedChangeStatusMarcacao';

const LABEL_MSG_REMARCAR = 'Remarcar';
const LABEL_MSG_DESMARCAR = 'Desmarcar';

type Props = {
  horario: any,
  value?: any,
  onStatusChanged: any,
  date: any,
  onAnamneseClick: any,
  onNewOptionClick: any,
  onMarcarClick: any,
};

type State = {
  paciente: any,
  pacienteEncaixe: any,
  valuesState: any,
  showReservarHorario: boolean,
};

class HorarioRow extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      paciente: null,
      pacienteEncaixe: null,
      showTriagem: false,
      listParamTriagem: [],
      showReservarHorario: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState);
  }
  // componentDidUpdate(prevProps, prevState, snapshot) {}

  handlePacienteOnChange = (name?: any, value: any) => {
    if (value && value.bloqueado) {
      this.props.warning({
        message:
          'Atenção, paciente bloqueado para atendimento com esse profissional.',
      });
      return;
    }
    _.set(value, 'tipo', objectsConstants.TIPO_MARCACAO);

    this.setState({ paciente: value }, () => {
      if (this.props.handlePacienteOnChange) {
        this.props.handlePacienteOnChange(value);
      }
    });
  };
  toggleModalImpressao = () => {
    this.setState(({ modalImpressao }) => ({
      modalImpressao: !modalImpressao,
    }));
  };

  handlePacienteEncaixeOnChange = (name?: any, value: any) => {
    _.set(value, 'tipo', objectsConstants.TIPO_ENCAIXE);
    this.setState({ pacienteEncaixe: value }, () => {
      if (this.props.handlePacienteOnChange) {
        this.props.handlePacienteOnChange(value);
      }
    });
  };

  onNewOptionClick = (nome) => {
    let tipo = objectsConstants.TIPO_MARCACAO;
    this.props.onNewOptionClick(nome, tipo);
  };

  onNewOptionClickEncaixe = (nome) => {
    let tipo = objectsConstants.TIPO_ENCAIXE;
    this.props.onNewOptionClick(nome, tipo);
  };

  abreEncaixe = (values) => {
    let newValuesState = _.cloneDeep(this.props.value);
    marcacaoService
      .limiteEncaixe(
        _.get(this.props, 'estabelecimentoAtendimento.id'),
        this.props.date
      )
      .then(
        (response) => {
          let limiteDiarioEncaixeAtingido =
            response.data.limiteDiarioEncaixeAtingido;
          if (!limiteDiarioEncaixeAtingido) {
            if (!newValuesState) {
              newValuesState = this.props.itemHorario;
              // newValuesState.listaEncaixe = [];
              newValuesState.tipo = objectsConstants.TIPO_MARCACAO;
              // newValuesState.data = this.props.date
              // newValuesState.horario= this.props.horario
              // newValuesState.medico= this.props.medico
              // newValuesState.exame= this.props.exame
              // newValuesState.estabelecimento=  _.get(this.props, 'estabelecimentoAtendimento.id'),
            }
            newValuesState.listaEncaixe.unshift({});
            this.props.updateMarcacao(newValuesState);
          } else {
            console.log('Já atingiu limite diário de encaixes');
            this.props.warning({
              message:
                'Limite de encaixes diário para esse profissional já foi atingido',
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  removeEncaixeVazio = () => {
    let newValuesState = _.cloneDeep(this.props.value);
    if (!newValuesState) {
      newValuesState = this.props.itemHorario;
      newValuesState.tipo = objectsConstants.TIPO_MARCACAO;
    }
    let listaEncaixe = newValuesState.listaEncaixe;
    listaEncaixe.shift();
    this.props.updateMarcacao(newValuesState);
  };
  handleNewOption = (newOption: any) => {
    return { nome: newOption.label, hora: this.props.horario };
  };

  clearPacienteState = () => {
    this.setState({ paciente: null, pacienteEncaixe: null });
  };

  createEncaixeButton = (value, horaMenorQueAgora) => {
    const { itemHorario } = this.props;

    let listaEncaixe = _.get(
      value,
      'listaEncaixe',
      _.get(itemHorario, 'listaEncaixe')
    );

    let permiteEncaixe =
      _.get(itemHorario, 'permiteEncaixe') || this.props.exame;
    let key = nextId();
    /*if (!this.props.exame) {*/
    let exibeBotao =
      listaEncaixe && listaEncaixe.length > 0
        ? _.get(listaEncaixe[0], 'paciente') !== undefined
        : true;
    if (!horaMenorQueAgora && exibeBotao) {
      return (
        <React.Fragment>
          <ButtonAutorized
            id={key}
            role={objectsConstants.AGENDA_COMPARECER}
            className={`btn btn-sm btn-link ${
              permiteEncaixe ? '' : 'desabilitado'
            }`}
            onClick={permiteEncaixe ? this.abreEncaixe : null}
            label="Encaixe"
            icon={'icon-btn-editar'}
          ></ButtonAutorized>
          {!permiteEncaixe && (
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.AGENDA_COMPARECER}
              ability={this.props.permissions}
            >
              <ToolTipHelp message="Horário não permite encaixe" target={key} />
            </Can>
          )}
        </React.Fragment>
      );
    }
    /*  }*/
  };

  getTipoAtendimento = (atendimento) => {
    if (
      _.get(atendimento, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CONVENIO
    ) {
      let convenio = _.get(atendimento, 'convenio.nome');
      let planoConvenio = _.get(atendimento, 'planoConvenio.nome', null);
      return (
        <label className="label-custom label-convenio">
          {convenio} {planoConvenio ? ' - ' + planoConvenio : ''}
        </label>
      );
    } else if (
      _.get(atendimento, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_PARTICULAR
    ) {
      return (
        <label className="label-custom label-particular">Particular</label>
      );
    } else if (
      _.get(atendimento, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CORTESIA
    ) {
      return <label className="label-custom label-cortesia">Cortesia</label>;
    }
  };

  getEspecialidadeAtendimento = (atendimento) => {
    if (_.get(atendimento, 'especialidade')) {
      return (
        <label className="label-custom label-especialidade">
          {_.get(atendimento, 'especialidade.nome')}
        </label>
      );
    }
    return null;
  };
  toggleTriagem = (marcacao) => {
    this.props.loading(true);
    if (marcacao) {
      let idEstabelecimento = _.get(marcacao, 'estabelecimento.id');
      Promise.all([
        paramTriagemService.findByEstabelecimento(idEstabelecimento),
        userService.doGet(marcacao.paciente.id),
      ]).then((r) => {
        let listParamTriagem = r[0].data;
        let paciente = r[1].data.data;
        this.setState({
          marcacao,
          showTriagem: !this.state.showTriagem,
          paciente,
          listParamTriagem,
        });
        this.props.loading(false);
      });
    } else {
      this.setState({ marcacao, showTriagem: !this.state.showTriagem });
    }
  };

  toogleModalReservarHorario = () => {
    this.setState({ showReservarHorario: !this.state.showReservarHorario });
  };

  cancelShowTriagem = () => {
    this.setState(
      { marcacao: null, showTriagem: !this.state.showTriagem },
      () => {
        this.props.loading(false);
      }
    );
  };

  handleSaveTriagem = () => {
    const { marcacao } = this.state;
    const idMarcacao = _.cloneDeep(marcacao.idMarcacao);

    this.setState(
      { marcacao: null, showTriagem: !this.state.showTriagem },
      () => {
        this.props.changeStatusMarcacao(
          idMarcacao,
          objectsConstants.AGUARDANDO_ATENDIMENTO
        );
      }
    );
  };
  render() {
    const {
      horario,
      marcacao,
      indisponivel,
      value,
      itemHorario,
      exameObj,
      reservado,
      horarioReservadoObj,
      ...otherProps
    } = this.props;
    let horaMenorQueAgora = this.horaMenorQueAgora(horario) || indisponivel;
    let paciente = _.get(marcacao, 'paciente');
    let listaEncaixe = _.get(
      value,
      'listaEncaixe',
      _.get(itemHorario, 'listaEncaixe')
    );
    if (_.isEmpty(listaEncaixe)) {
      listaEncaixe = _.get(this.props.itemHorario, 'listaEncaixe');
    }

    return (
      <div
        className="row timetable flex-column flex-lg-row align-items-center"
        id={`${
          itemHorario && itemHorario.paciente
            ? itemHorario.paciente.cpfCnpj
            : uuidv4()
        }`}
      >
        <ModalCrud
          title="Triagem"
          pacienteOnline={this.props.pacienteOnline}
          toogle={this.toggleTriagem}
          isOpen={this.state.showTriagem}
          paciente={this.state.paciente}
          Componente={TriagemPage}
          agendamentoMode={true}
          className={'modal-filaAtendimento modal-lg'}
          viewMode={false}
          entity={_.get(this.state, 'marcacao')}
          callbackOnSave={this.handleSaveTriagem}
          onCancel={this.cancelShowTriagem}
          permissionSave={objectsConstants.AGENDA_TRIAGEM}
          titleBtnSave={'Salvar'}
          listParamTriagem={this.state.listParamTriagem}
          {...otherProps}
        ></ModalCrud>

        <ModalReservarHorario
          ref={(modalReservar) => {
            this.refModalReservar = modalReservar;
          }}
          isOpen={this.state.showReservarHorario}
          estabelecimentoAtendimento={this.props.estabelecimentoAtendimento}
          horario={horario}
          date={this.props.date}
          medico={this.props.medico}
          toogleModal={this.toogleModalReservarHorario}
          callbackOnSave={this.reservarHorario}
        />
        <div className="hora-detalhe-row d-flex flex-row flex-wrap justify-content-center align-items-center mt-3 mt-sm-0">
          <div className="hour">{horario}</div>
          {!this.props.isAgendamentoOnline && (
            <React.Fragment>
              <div className="legends d-flex justify-content-end">
                {legendHelper.createLegendSituation(this.props.value)}
                {legendHelper.createLegendType(this.props.value)}
              </div>

              {this.createEncaixeButton(this.props.value, horaMenorQueAgora)}
            </React.Fragment>
          )}
        </div>
        {!this.props.isAgendamentoOnline && (
          <div className="patient pl-0 pl-sm-5">
            <div className={`select ${this.props.value && 'name'}`}>
              {this.props.value && this.props.value.paciente && (
                <div
                  className="action"
                  onClick={() => {
                    this.props.onPacienteClick(this.props.value.paciente.id);
                  }}
                >
                  <DadosUser
                    telemedicina={this.props.value.telemedicina}
                    exame={_.get(this.props.value, 'exame')}
                    exibeNomeExame={!this.props.exame}
                    user={this.props.value.paciente}
                    marcacao={this.props.value}
                    recorrencia={this.props.value.recorrencia != null}
                  ></DadosUser>
                  <div
                    className={
                      'd-flex flex-row flex-wrap justify-content-center justify-content-md-start'
                    }
                  >
                    <small className={'mr-1 mt-2'}>
                      {this.getTipoAtendimento(this.props.value)}
                    </small>
                    <small className={'mr-0 mr-sm-5 mt-2'}>
                      {this.getEspecialidadeAtendimento(this.props.value)}
                    </small>

                    {this.props.value.observacoes && (
                      <small className={'mr-0 mr-sm-5 mt-2 box-obs'}>
                        <b>Obs:</b> {this.props.value.observacoes}
                      </small>
                    )}
                  </div>
                </div>
              )}
              {(!this.props.value || !this.props.value.paciente) &&
                !paciente &&
                !reservado && (
                  <React.Fragment>
                    <Can
                      I={objectsConstants.VIEW}
                      a={objectsConstants.AGENDA_MARCAR}
                      ability={this.props.permissions}
                    >
                      <PacienteSelect
                        onChange={this.handlePacienteOnChange}
                        medico={this.props.medico}
                        value={this.state.paciente}
                        onNewOptionClick={this.onNewOptionClick}
                        newOptionCreator={this.handleNewOption}
                        disabled={horaMenorQueAgora}
                        idExame={this.props.idExame}
                      />
                    </Can>
                  </React.Fragment>
                )}
              {(!this.props.value || !this.props.value.paciente) &&
                !paciente &&
                reservado && (
                  <React.Fragment>
                    <div className="txt-reserva">
                      <b>HORÁRIO RESERVADO</b>
                      {_.get(itemHorario, 'horarioReservadoObj')
                        ? _.get(itemHorario, 'horarioReservadoObj.observacoes')
                          ? ' (' +
                            _.get(
                              itemHorario,
                              'horarioReservadoObj.observacoes'
                            ) +
                            ')'
                          : ''
                        : _.get(itemHorario, 'observacaoReservado')
                        ? ' (' +
                          _.get(itemHorario, 'observacaoReservado', '') +
                          ')'
                        : ''}
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
        )}
        <div className="d-flex flex-wrap justify-content-start">
          {!dateHelper.isBeforeNow(moment(this.props.date).toDate()) && (
            <React.Fragment>
              {this.createAtenderButton(this.props.value)}
              {this.createAtendidoButton(this.props.value)}
              {this.createAtenderTelemedicinaButton(
                this.props.value,
                horaMenorQueAgora
              )}

              {this.createValidarElegibilidadeButton(this.props.value)}

              {this.createAnamneseButton(this.props.value)}
              {this.createMarcarButton(this.props.value, horaMenorQueAgora)}
              {this.createTriagemButton(this.props.value)}
              {this.createCompareceuButton(this.props.value, true)}

              {this.createRemarcarButton(this.props.value, true)}
              {this.createDesmarcarButton(this.props.value, false)}
            </React.Fragment>
          )}
          {this.createNaoCompareceuButton(this.props.value, horaMenorQueAgora)}
          {this.createEditarMarcacaoButton(this.props.value)}
          {this.createImprimirGuiaButton(this.props.value)}
          {this.createHistoricoButton(this.props.value)}
          {this.createReservarLiberarHorarioButton(horaMenorQueAgora)}
        </div>
        {listaEncaixe &&
          !this.props.isAgendamentoOnline &&
          listaEncaixe.map((encaixe, index) => {
            return (
              <div
                className="encaixe timetable flex-column flex-lg-row align-items-center"
                key={nextId()}
              >
                {encaixe.paciente && (
                  <div className="hora-detalhe-row d-flex flex-row flex-wrap align-items-center mt-3 mt-sm-0">
                    <div className="legends d-flex justify-content-end">
                      <ToolTipHelp
                        message="Encaixe"
                        target={`encaixe${encaixe.idMarcacao}`}
                      />
                      <div
                        className="legenda-consulta encaixe"
                        title="Encaixe"
                        id={`encaixe${encaixe.idMarcacao}`}
                      >
                        E
                      </div>
                      {legendHelper.createLegendSituation(encaixe)}
                      {legendHelper.createLegendType(encaixe)}
                    </div>
                  </div>
                )}
                {encaixe.paciente && (
                  <div className="patient pl-0 pl-sm-5">
                    <div className={`select ${encaixe && 'name'}`}>
                      <div
                        className="action"
                        onClick={() => {
                          this.props.onPacienteClick(encaixe.paciente.id);
                        }}
                      >
                        <DadosUser
                          telemedicina={encaixe.telemedicina}
                          user={encaixe.paciente}
                          marcacao={encaixe}
                        ></DadosUser>
                        <div
                          className={
                            'd-flex flex-row flex-wrap justify-content-center justify-content-md-start'
                          }
                        >
                          <small className={'mr-1 mt-2'}>
                            {this.getTipoAtendimento(encaixe)}
                          </small>
                          <small className={'mr-0 mr-sm-5 mt-2'}>
                            {this.getEspecialidadeAtendimento(this.props.value)}
                          </small>

                          {encaixe.observacoes && (
                            <small className={'mr-0 mr-sm-5 mt-2'}>
                              <b>Obs:</b> {encaixe.observacoes}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!encaixe.paciente && (
                  <React.Fragment>
                    <div
                      className={`icon-delete d-flex align-items-center justify-content-center `}
                      onClick={() => {
                        this.removeEncaixeVazio(encaixe);
                      }}
                    >
                      <img src={IconDelete} alt="img" />
                    </div>
                    <div className="patient pl-0 ">
                      <div className={`select ${encaixe && 'name'}`}>
                        <PacienteSelect
                          onChange={this.handlePacienteEncaixeOnChange}
                          value={this.state.pacienteEncaixe}
                          medico={this.props.medico}
                          onNewOptionClick={this.onNewOptionClickEncaixe}
                          newOptionCreator={this.handleNewOption}
                          disabled={false}
                          idExame={this.props.idExame}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {encaixe.paciente && (
                  <div className="d-flex flex-wrap justify-content-start">
                    {!dateHelper.isBeforeNow(
                      moment(this.props.date).toDate()
                    ) && (
                      <React.Fragment>
                        {this.createAtenderButton(encaixe)}
                        {this.createAtendidoButton(encaixe)}
                        {this.createAtenderTelemedicinaButton(
                          encaixe,
                          horaMenorQueAgora
                        )}
                        {this.createValidarElegibilidadeButton(encaixe, true)}
                        {this.createAnamneseButton(encaixe)}
                        {this.createMarcarButton(encaixe, horaMenorQueAgora)}
                        {this.createCompareceuButton(encaixe, true)}
                        {this.createTriagemButton(encaixe)}
                        {this.createRemarcarButton(encaixe)}
                        {this.createDesmarcarButton(encaixe, true)}
                      </React.Fragment>
                    )}
                    {this.createNaoCompareceuButton(encaixe, horaMenorQueAgora)}
                    {this.createEditarMarcacaoButton(encaixe)}
                    {this.createImprimirGuiaButton(encaixe)}
                    {this.createHistoricoButton(encaixe)}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }

  handleRescheduleStatus = (idMarcacao) => {
    const { exameObj } = this.props;
    marcacaoService.doGet(idMarcacao).then(
      (response) => {
        let marcacao = response.data.data;
        _.set(marcacao, 'exame', exameObj);
        this.props.remarcar(marcacao);
      },
      () => {
        console.log('Não foi possivel buscar marcação');
      }
    );
  };

  handleStatusChanged = (value, status: string, confirmar: Boolean) => {
    if (value) {
      this.props.onStatusChanged(value, status, confirmar);
    }
  };

  alertaEmailInvalido = (value, labelMsg) => {
    ConfirmAcationService.confirmMsg(
      () => {
        if (labelMsg == LABEL_MSG_REMARCAR) {
          this.handleRescheduleStatus(value.idMarcacao);
        } else {
          this.handleStatusChanged(value, objectsConstants.CANCELADO);
        }
      },
      'E-mail inexistente ou inválido',
      <span>
        O(A) paciente <b>{_.get(value, 'paciente.nome')}</b> não irá receber o
        <b> E-mail </b> de confirmação dessa ação.
      </span>,
      <span>Deseja realmente {labelMsg} esse atendimento?</span>,
      'Não',
      'Sim',
      objectsConstants.TYPE_WARNING
    );
  };

  alertaConfirmaDesmarcar = (value, labelMsg: string) => {
    ConfirmAcationService.confirmMsg(
      () => {
        if (labelMsg == LABEL_MSG_REMARCAR) {
          this.handleRescheduleStatus(value.idMarcacao);
        } else {
          this.handleStatusChanged(value, objectsConstants.CANCELADO);
        }
      },
      <span> {labelMsg} atendimento</span>,
      <span>Deseja realmente {labelMsg} esse atendimento?</span>,
      <span className="text-danger">{}</span>,
      'Não',
      'Sim',
      objectsConstants.TYPE_WARNING
    );
  };

  handleSaveMarcacao = () => {
    this.props.onMarcarClick(
      this.state.paciente.id,
      this.props.horario,
      this.props.medico,
      this.props.estabelecimentoAtendimento
    );
  };

  getLabelStatus = (value?: any) => {
    if (value) {
      if (value.status === objectsConstants.ATENDIDO) {
        return 'Paciente atendido';
      }
      if (value.status === objectsConstants.EM_CHAMADA) {
        return 'Paciente foi chamado';
      }
      if (value.status === objectsConstants.EM_ATENDIMENTO) {
        return 'Paciente em atendimento';
      }
      if (value.status === objectsConstants.AGUARDANDO_ATENDIMENTO) {
        return 'Aguardando ser chamado';
      }

      if (this.horaMenorQueAgora(value)) {
        return 'Atrasado';
      }
    }
    return null;
  };
  // horaMenorQueAgoraDelay = (horario, indisponivel) => {
  //   let horas = horario.split(':');
  //   let horarioDelay =
  //     parseInt(horas[0]) + HORAS_DELAY_ATENDIMENTO + ':' + horas[1];
  //   return this.horaMenorQueAgora(horarioDelay) || indisponivel;
  // };

  horaMenorQueAgora = (horario) => {
    if (horario) {
      let dataAtual = moment().startOf('day');
      let dataRow = moment(this.props.date);
      let horas = horario.split(':');
      dataRow.set({ hour: horas[0], minute: horas[1] });

      if (dataRow.isBefore(dataAtual)) {
        return true;
      }
    }
    return false;
  };

  createAnamneseButton = (value?: any) => {
    if (
      value &&
      (value.status === objectsConstants.AGUARDANDO_ATENDIMENTO ||
        value.status === objectsConstants.EM_ATENDIMENTO ||
        value.status === objectsConstants.EM_CHAMADA ||
        value.status === objectsConstants.ATENDIDO)
    ) {
      if (!userService.isMedico()) {
        return (
          <div className="actions">
            <label className="lbl-status">{this.getLabelStatus(value)}</label>
          </div>
        );
      } else {
        if (!this.props.isAgendamentoOnline) {
          return (
            <ButtonAutorized
              className="btn-link"
              label={'Ver prontuário'}
              icon={'icon-visualizar'}
              role={objectsConstants.CRUD_ANAMNESE_VISUALIZAR}
              onClick={() => {
                this.props.onAnamneseClick(value.paciente.id);
              }}
            ></ButtonAutorized>
          );
        }
      }
    }
    return null;
  };

  createMarcarButton = (value?: any, horaMenorQueAgora: any) => {
    const { marcacao, pacienteOnline, reservado } = this.props;
    let paciente = this.props.pacienteOnline
      ? this.props.pacienteOnline
      : _.get(marcacao, 'paciente');
    if (!value && paciente && !horaMenorQueAgora) {
      let newPaciente = _.cloneDeep(paciente);
      _.set(newPaciente, 'tipo', objectsConstants.TIPO_MARCACAO);
      if (this.props.isAgendamentoOnline) {
        return (
          <button
            className={`btn btn-secondary open`}
            onClick={() => {
              if (this.props.pacienteOnline && this.props.pacienteOnline.id) {
                this.props.handlePacienteOnChange(this.props.pacienteOnline);
              } else {
                this.props.handleLoginOnline({
                  itemhorario: this.props.itemHorario,
                });
              }
            }}
            id={this.props.id}
            disabled={userService.isSuporte()}
          >
            {this.props.icon && <span className={`${this.props.icon}`}></span>}
            Agendar
          </button>
        );
      } else {
        if (!this.props.isAgendamentoOnline && !reservado) {
          return (
            <ButtonAutorized
              className="btn-secondary open"
              label={'Reagendar'}
              role={objectsConstants.AGENDA_MARCAR}
              onClick={() => this.reagendarPaciente(newPaciente)}
            ></ButtonAutorized>
          );
        }
      }
    }
    return null;
  };

  reagendarPaciente = (paciente) => {
    const { exameObj, medico } = this.props;
    let newMarcacao = _.cloneDeep(this.props.marcacao);
    let dataConsulta = moment(this.props.date);
    let estabelecimentoAtendimento = _.cloneDeep(
      this.props.estabelecimentoAtendimento
    );
    _.set(newMarcacao, 'dataConsulta', dataConsulta.format('YYYY-MM-DD'));
    _.set(newMarcacao, 'horaString', this.props.horario);
    _.set(
      newMarcacao,
      'fkEstabelecimentoAtendimento',
      _.get(estabelecimentoAtendimento, 'id')
    );
    if (exameObj) {
      _.set(newMarcacao, 'exame', exameObj);
    } else {
      _.set(newMarcacao, 'fkMedico', _.get(medico, 'id'));
    }
    _.set(newMarcacao, 'fkEstabelecimento', newMarcacao.estabelecimento.id);
    _.set(newMarcacao, 'fkPaciente', newMarcacao.paciente.id);
    _.set(newMarcacao, 'status', objectsConstants.MARCADO);

    this.props.reagendarMarcacao(newMarcacao);
  };

  createRemarcarButton = (value?: any) => {
    if (
      value &&
      (value.status === objectsConstants.MARCADO ||
        value.status === objectsConstants.MARCACOES_FORA_HORARIO_MEDICO)
    ) {
      if (!this.props.isAgendamentoOnline) {
        return (
          <ButtonAutorized
            className="btn-blue "
            label={'Remarcar'}
            role={objectsConstants.AGENDA_MARCAR}
            onClick={(e) => {
              let emailPaciente = _.get(value, 'paciente.email');
              let emailValido = useValidationsBR('email', emailPaciente);
              emailValido
                ? this.alertaConfirmaDesmarcar(value, LABEL_MSG_REMARCAR)
                : this.alertaEmailInvalido(value, LABEL_MSG_REMARCAR);
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };

  createTriagemButton = (value?: any) => {
    if (value && value.status === objectsConstants.AGUARDANDO_TRIAGEM) {
      if (
        !this.props.isAgendamentoOnline &&
        (userService.isAdministrador() || userService.isEnfermeira())
      ) {
        return (
          <ButtonAutorized
            className="btn-blue "
            label={'Iniciar Triagem'}
            role={objectsConstants.AGENDA_TRIAGEM}
            onClick={(e) => {
              filaService.chamarTerminal(
                { nome: 'Sala de triagem' },
                value.idMarcacao
              );
              this.toggleTriagem(value);
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };
  createHistoricoButton = (value?: any) => {
    if (
      value &&
      userService.isAdministrador() &&
      !this.props.isAgendamentoOnline
    ) {
      return (
        <ButtonAutorized
          className="btn-secondary open"
          label={'Histórico'}
          role={objectsConstants.AGENDA_MARCAR}
          onClick={() => {
            this.props.abreHistoricoConsulta(value);
          }}
        ></ButtonAutorized>
      );
    }
  };
  createImprimirGuiaButton = (value?: any) => {
    if (
      ((value && userService.isAdministrador()) ||
        userService.isSecretaria()) &&
      !this.props.isAgendamentoOnline
    ) {
      return (
        <ButtonAutorized
          className="btn-gray  "
          type="button"
          label={'Imprimir Guia'}
          role={objectsConstants.AGENDA_MARCAR}
          onClick={() => {
            this.imprimirGuias(value.idMarcacao);
          }}
        ></ButtonAutorized>
      );
    }
  };

  imprimirGuias = (idMarcacao) => {
    const { idExame } = this.props;
    if (idExame) {
      marcacaoService.imprimirGuiaSADT(idMarcacao).then((response) => {
        var base64 = _.get(response, 'data.data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    } else {
      marcacaoService.imprimirGuiaConsulta(idMarcacao).then((response) => {
        var base64 = _.get(response, 'data.data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    }
  };

  reservarHorario = (observacoes) => {
    const { estabelecimentoAtendimento, horario, date } = this.props;
    let dataAtual = dateHelper.format(date, {
      mode: 'DATE',
    });
    let entity = {
      estabelecimentoAtendimento: estabelecimentoAtendimento,
      hora: horario,
      data: date,
      observacoes: observacoes,
    };
    reservarHorarioService.doSave(entity).then((response) => {});
  };

  liberarHorario = () => {
    const { horarioReservadoObj, date, horario, estabelecimentoAtendimento } =
      this.props;
    let idHorarioReservado = _.get(horarioReservadoObj, 'id');

    if (idHorarioReservado) {
      reservarHorarioService
        .doDelete(_.get(horarioReservadoObj, 'id'))
        .then((response) => {});
      this.refModalReservar.clearObservacoes();
    } else {
      let entity = {
        estabelecimentoAtendimento: estabelecimentoAtendimento,
        hora: horario,
        data: date,
      };
      reservarHorarioService.findByDataHoraEstabe(entity).then((response) => {
        let idHorarioReservado = _.get(response.data, 'id');
        reservarHorarioService
          .doDelete(idHorarioReservado)
          .then((response) => {});
      });
    }
  };

  createReservarLiberarHorarioButton = (horaMenorQueAgora) => {
    const {
      estabelecimentoAtendimento,
      horario,
      date,
      value,
      reservado,
      reagendamento,
    } = this.props;
    let dataRow = moment(date);
    if (
      !dateHelper.isBeforeNow(dataRow.toDate()) &&
      !value &&
      !horaMenorQueAgora
    ) {
      if (!reservado && !this.props.isAgendamentoOnline && !reagendamento) {
        return (
          <ButtonAutorized
            className="txt-red"
            label={'Reservar horário'}
            disabled={userService.isSuporte()}
            role={objectsConstants.AGENDA_MARCAR}
            onClick={() => {
              this.toogleModalReservarHorario();
            }}
          ></ButtonAutorized>
        );
      } else if (
        reservado &&
        !this.props.isAgendamentoOnline &&
        !reagendamento
      ) {
        return (
          <ButtonAutorized
            className="txt-green"
            label={'Liberar horário'}
            disabled={userService.isSuporte()}
            role={objectsConstants.AGENDA_MARCAR}
            onClick={() => {
              ConfirmAcationService.confirmMsg(
                () => {
                  this.liberarHorario();
                },
                'Liberar horário',
                <span></span>,
                <span>Deseja realmente liberar esse horário?</span>,
                'Não',
                'Sim',
                objectsConstants.TYPE_WARNING
              );
            }}
          ></ButtonAutorized>
        );
      } else if (
        reservado &&
        !this.props.isAgendamentoOnline &&
        reagendamento
      ) {
        return <p>Horario Reservado</p>;
      }
    }
  };

  createDesmarcarButton = (value?: any, ehEncaixe: boolean) => {
    if (
      value &&
      (value.status === objectsConstants.MARCADO ||
        value.status === objectsConstants.AGUARDANDO_ATENDIMENTO ||
        value.status === objectsConstants.RECEPCAO_INTERNA ||
        value.status === objectsConstants.AGUARDANDO_TRIAGEM ||
        value.status === objectsConstants.EM_ATENDIMENTO ||
        value.status === objectsConstants.EM_CHAMADA ||
        value.status === objectsConstants.MARCACOES_FORA_HORARIO_MEDICO ||
        value.status === objectsConstants.NAO_COMPARECEU)
    ) {
      if (ehEncaixe) {
        _.set(value, 'tipo', objectsConstants.TIPO_ENCAIXE);
      } else {
        _.set(value, 'tipo', objectsConstants.TIPO_MARCACAO);
      }
      if (!this.props.isAgendamentoOnline) {
        return (
          <ButtonAutorized
            className="btn-danger delete"
            label={'Desmarcar'}
            role={objectsConstants.AGENDA_MARCAR}
            disabled={userService.isSuporte()}
            onClick={(e) => {
              let emailPaciente = _.get(value, 'paciente.email');
              let emailValido = useValidationsBR('email', emailPaciente);
              emailValido
                ? this.alertaConfirmaDesmarcar(value, LABEL_MSG_DESMARCAR)
                : this.alertaEmailInvalido(value, LABEL_MSG_DESMARCAR);
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };

  createValidarElegibilidadeButton = (value?: any) => {
    if (
      value &&
      value.status === objectsConstants.RECEPCAO_INTERNA &&
      !value.telemedicina
    ) {
      return (
        <ButtonAutorizedChangeStatusMarcacao
          statusMarcacao={value ? value.status : null}
          role={objectsConstants.AGENDA_VALIDAR_ELEGIBILIDADE}
          disabled={userService.isSuporte()}
          onClick={() => {
            let newUser = _.cloneDeep(this.props.user);
            let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
            let local;
            if (lista) {
              for (let i = 0; i < lista.length; i++) {
                const item = lista[i];
                if (item.estabelecimento.id === userService.getExtension()) {
                  local = item;
                }
              }
            }

            if (local) {
              filaService.chamarTerminal(
                { nome: local.local + ' ' + local.nome },
                value.idMarcacao
              );
            }
            this.handleStatusChanged(
              value,
              objectsConstants.AGUARDANDO_ATENDIMENTO,
              true
            );
          }}
          localAtendimento={value.localEtapaAtendimento}
          className="btn-primary open"
          label={'Validar elegibilidade'}
          statusAuthorizated={objectsConstants.RECEPCAO_INTERNA}
          user={this.props.user}
          isAgendamentoOnline={this.props.isAgendamentoOnline}
        />
      );
    }
  };
  createChamarNovamenteButton = (value?: any, local) => {
    if (
      value &&
      value.status === objectsConstants.RECEPCAO_INTERNA &&
      !value.telemedicina
    ) {
      return (
        <ButtonAutorizedChangeStatusMarcacao
          statusMarcacao={value ? value.status : null}
          role={objectsConstants.AGENDA_VALIDAR_ELEGIBILIDADE}
          disabled={userService.isSuporte()}
          onClick={() => {
            let newUser = _.cloneDeep(this.props.user);
            let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
            let local;
            if (lista) {
              for (let i = 0; i < lista.length; i++) {
                const item = lista[i];
                if (item.estabelecimento.id === userService.getExtension()) {
                  local = item;
                }
              }
            }

            if (local) {
              filaService.chamarTerminal(local, value.idMarcacao);
            }
            this.handleStatusChanged(
              value,
              objectsConstants.AGUARDANDO_ATENDIMENTO,
              true
            );
          }}
          localAtendimento={value.localEtapaAtendimento}
          className="btn-primary open"
          label={'Validar elegibilidade'}
          statusAuthorizated={objectsConstants.RECEPCAO_INTERNA}
          user={this.props.user}
          isAgendamentoOnline={this.props.isAgendamentoOnline}
        />
      );
    }
  };
  createCompareceuButton = (value?: any, confirmar: Boolean) => {
    let dataAtual = moment();
    let dataRow = moment(this.props.date);
    if (
      dataRow.isBefore(dataAtual) &&
      value &&
      (value.status === objectsConstants.MARCADO ||
        value.status === objectsConstants.AGUARDANDO_RECEPCAO ||
        value.status === objectsConstants.NAO_COMPARECEU) &&
      !value.telemedicina &&
      !this.props.isAgendamentoOnline
    ) {
      return (
        <ButtonAutorizedChangeStatusMarcacao
          statusMarcacao={value ? value.status : null}
          role={objectsConstants.AGENDA_COMPARECER}
          disabled={userService.isSuporte()}
          onClick={() => {
            this.handleStatusChanged(
              value,
              objectsConstants.AGUARDANDO_ATENDIMENTO,
              confirmar
            );
          }}
          className="btn-primary open"
          label={'Compareceu'}
          statusAuthorizated={objectsConstants.MARCADO}
          user={this.props.user}
          isAgendamentoOnline={this.props.isAgendamentoOnline}
        />
      );
    }
    return null;
  };

  createNaoCompareceuButton = (value?: any, horaMenorQueAgora: any) => {
    let dataAtual = moment();
    let dataRow = moment(this.props.date);
    if (
      value &&
      value.status === objectsConstants.MARCADO &&
      dataRow.isBefore(dataAtual)
    ) {
      if (!this.props.isAgendamentoOnline) {
        return (
          <ButtonAutorized
            className="btn btn-orange open"
            label={'Não compareceu'}
            role={objectsConstants.AGENDA_COMPARECER}
            disabled={userService.isSuporte()}
            onClick={(e) => {
              ConfirmAcationService.confirmMsg(
                () => {
                  this.handleStatusChanged(
                    value,
                    objectsConstants.NAO_COMPARECEU
                  );
                },
                'Paciente não compareceu',
                <span>
                  Deseja realmente informar que o(a) paciente
                  <p className="mb-0">
                    <b>{_.get(value, 'paciente.nome')} </b>
                  </p>
                  não compareceu?
                </span>,
                <span className="text-danger">{}</span>,
                'Não',
                'Sim',
                objectsConstants.TYPE_WARNING
              );
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };

  createEditarMarcacaoButton = (value?: any) => {
    if (
      value &&
      (value.status === objectsConstants.ATENDIDO ||
        value.status === objectsConstants.MARCADO ||
        value.status === objectsConstants.AGUARDANDO_ATENDIMENTO ||
        // value.status === objectsConstants.EM_ATENDIMENTO ||
        value.status === objectsConstants.EM_CHAMADA)
    ) {
      if (!this.props.isAgendamentoOnline) {
        return (
          <ButtonAutorized
            className="btn btn-edit"
            label={'Editar consulta'}
            role={objectsConstants.AGENDA_COMPARECER}
            onClick={() => {
              this.handleStatusChanged(value, value.status, true);
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };

  createAtenderButton = (value?: any) => {
    if (value && !value.telemedicina) {
      return (
        <ButtonAutorizedChangeStatusMarcacao
          statusMarcacao={value ? value.status : null}
          role={objectsConstants.AGENDA_ATENDER}
          disabled={userService.isSuporte()}
          onClick={() => {
            this.handleStatusChanged(value, objectsConstants.EM_CHAMADA);
          }}
          className="btn-primary"
          label={'Chamar paciente'}
          statusAuthorizated={objectsConstants.AGUARDANDO_ATENDIMENTO}
          user={this.props.user}
          isAgendamentoOnline={this.props.isAgendamentoOnline}
        />
      );
    }

    return null;
  };

  createAtenderTelemedicinaButton = (value?: any, horaMenorQueAgora: any) => {
    if (
      value &&
      (value.status === objectsConstants.MARCADO ||
        value.status === objectsConstants.NAO_COMPARECEU) &&
      value.telemedicina
    ) {
      if (!this.props.isAgendamentoOnline) {
        return (
          <ButtonAutorized
            className="btn-secondary open"
            label={'Iniciar'}
            role={objectsConstants.AGENDA_ATENDIDO}
            disabled={userService.isSuporte()}
            onClick={() => {
              this.props.onAnamneseClick(value.paciente.id);
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };

  createAtendidoButton = (value?: any) => {
    let idEstabelecimento = _.get(value, 'estabelecimento.id');
    if (
      value &&
      value.status === objectsConstants.EM_CHAMADA &&
      !value.telemedicina
    ) {
      if (!this.props.isAgendamentoOnline) {
        return (
          <React.Fragment>
            <ButtonAutorized
              className={`btn-info ${idEstabelecimento != 53 ? 'd-none' : ''}`}
              label={'Chamar novamente'}
              role={objectsConstants.AGENDA_ATENDIDO}
              disabled={userService.isSuporte()}
              onClick={() => {
                let localAtendimento = {};
                if (
                  this.props.itemHorario &&
                  this.props.itemHorario.medico &&
                  this.props.itemHorario.medico.localAtendimento
                ) {
                  localAtendimento = {
                    nome: this.props.itemHorario.medico.localAtendimento,
                    id: this.props.itemHorario.medico.idLocalAtendimento,
                  };
                }
                filaService.chamarTerminal(localAtendimento, value.idMarcacao);
              }}
            ></ButtonAutorized>
            <ButtonAutorized
              className="btn-primary"
              label={'Iniciar atendimento'}
              role={objectsConstants.AGENDA_ATENDIDO}
              disabled={userService.isSuporte()}
              onClick={() => {
                this.handleStatusChanged(
                  value,
                  objectsConstants.EM_ATENDIMENTO
                );
                this.props.onAnamneseClick(value.paciente.id);
              }}
            ></ButtonAutorized>
          </React.Fragment>
        );
      }
    }
    return null;
  };
}

// const mapDispatch = ({ alert: { warning } }) => ({
//   warning: (msg) => warning(msg),
// });

export default HorarioRow;
