//@flow
import { func } from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { objectsConstants } from '../_constants/objects.constants';
import { ToolTipHelp } from '../components/Utils/ToolTipHelp';

const HORAS_DELAY_ATENDIMENTO = 2;

export const legendHelper = {
  createLegendType,
  createLegendSituation,
  createLegendRecomendacao,
  isTipoEntrada,
  isTipoSaida,
};

function createLegendRecomendacao(value?: any, key) {
  if (value) {
    return (
      <React.Fragment>
        <ToolTipHelp message={value} target={`tipo${key}`} />
        <div
          className="legenda-consulta recomendacao"
          title="recomendacao"
          id={`tipo${key}`}
        />
      </React.Fragment>
    );
  }

  return null;
}

function isTipoSaida(value) {
  switch (value) {
    case 'VENDA':
      value = 'Venda';
      break;
    case 'AJUSTE':
      value = 'Ajuste';
      break;
    case 'CONSUMO_INTERNO':
      value = 'Consumo interno';
      break;
    case 'TRANSFERENCIA':
      value = 'Transferência';
      break;
    case 'OUTRAS_SAIDAS':
      value = 'Outras saídas';
      break;
  }
  return value;
}

function isTipoEntrada(value) {
  switch (value) {
    case 'COMPRA':
      value = 'Compra';
      break;
    case 'AJUSTE':
      value = 'Ajuste';
      break;
    case 'DEVOLUCAO_VENDA':
      value = 'Devolução venda';
      break;
    case 'TRANSFERENCIA':
      value = 'Transferência';
      break;
    case 'SALDO_INICIAL':
      value = 'Saldo inicial';
      break;
    case 'OUTROS':
      value = 'Outras entradas';
      break;
  }
  return value;
}

function createLegendType(value?: any) {
  let key = _.get(value, 'idMarcacao');
  if (value && value.tipoConsulta === objectsConstants.TIPO_CONSULTA_COMUM) {
    return (
      <React.Fragment>
        <ToolTipHelp message="Consulta" target={`tipo${key}`} />
        <div
          className="legenda-consulta comum"
          title="Consulta"
          id={`tipo${key}`}
        >
          C
        </div>
      </React.Fragment>
    );
  }
  if (value && value.tipoConsulta === objectsConstants.TIPO_CONSULTA_PRIMEIRA) {
    return (
      <React.Fragment>
        <ToolTipHelp message="Primeira consulta" target={`tipo${key}`} />
        <div
          className="legenda-consulta primeira"
          title="Primeira consulta"
          id={`tipo${key}`}
        >
          P
        </div>
      </React.Fragment>
    );
  }
  if (value && value.tipoConsulta === objectsConstants.TIPO_CONSULTA_RETORNO) {
    return (
      <React.Fragment>
        <ToolTipHelp message="Retorno" target={`tipo${key}`} />
        <div
          className="legenda-consulta retorno"
          title="Retorno"
          id={`tipo${key}`}
        >
          R
        </div>
      </React.Fragment>
    );
  }
  return null;
}

function createLegendSituation(value?: any) {
  let key = _.get(value, 'idMarcacao');
  if (_.get(value, 'status') === objectsConstants.ATENDIDO) {
    return (
      <React.Fragment>
        <ToolTipHelp message="Consulta realizada" target={`situacao${key}`} />
        <div
          className="legenda-consulta compareceu"
          title="Consulta realizada"
          id={`situacao${key}`}
        >
          R
        </div>
      </React.Fragment>
    );
  }
  if (_.get(value, 'status') === objectsConstants.NAO_COMPARECEU) {
    return (
      <React.Fragment>
        <ToolTipHelp message="Não compareceu" target={`situacao${key}`} />
        <div
          className="legenda-consulta nao-compareceu"
          title="Não compareceu"
          id={`situacao${key}`}
        >
          N
        </div>
      </React.Fragment>
    );
  }
  return null;
}
