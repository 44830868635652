//@flow
import { defaultService } from "./defaultService";
import { urlsConstants } from "../_constants/urls.constant";


export const whatsappService = {
    checkStatus,
    startSession,
    disconnect
};


function checkStatus() {
    return defaultService.doGet(`${urlsConstants.WHATSAPP_CHECK_STATUS}`);
}

function startSession() {
    return defaultService.doGet(`${urlsConstants.WHATSAPP_START_SESSION}`);
}

function disconnect() {
    return defaultService.doGet(`${urlsConstants.WHATSAPP_STOP_SESSION}`);
}