//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const modeloAnamneseService = {
  doGet,
  doSave,
  findByFiltro,
  exportToExcel,
  findAll,
  imprimir,
  doDelete,
};

function findAll() {
  return defaultService.doGet(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}findAll`
  );
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}${id}`
  );
}

function doDelete(id) {
  return defaultService.doDelete(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}${id}`
  );
}

function doSave(entity: any) {
  return defaultService.doSave(
    urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE,
    entity
  );
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel(
    'estabelecimento',
    filter,
    fields,
    labels
  );
}

function imprimir(values) {
  return defaultService.doPost(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}imprimir`,
    values
  );
}
