//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalCrud } from '../../../components/Modal/ModalCrud';
import TriagemPage from '../../triagem/TriagemPage';
import ButtonAutorized from '../../../components/buttons/ButtonAutorized';
import _ from 'lodash';
import { objectsConstants } from '../../../_constants/objects.constants';
import { paramTriagemService } from '../../../_services/paramTriagem.service';
import { userService } from '../../../_services/user.service';
import { prontuarioFinalizaAtendimentoService } from '../../../_services/prontuarioFinalizaAtendimento.service';

type Props = {
  dataHora: any,
  newDiv?: boolean,
  onCancelar: any,
  onEdit?: any,
  isAnamneseEditing: boolean,
  dispatch: any,
};

class HeaderProntuario extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
    this.state = { showTriagem: false };
  }

  toggleTriagem = () => {
    let { marcacao } = this.props;
    this.props.loading(true);
    if (marcacao) {
      let idEstabelecimento = _.get(marcacao, 'estabelecimento.id');
      Promise.all([
        paramTriagemService.findByEstabelecimento(idEstabelecimento),
        userService.doGet(marcacao.paciente.id),
      ])
      .then((r) => {
        let listParamTriagem = r[0].data;
        let paciente = r[1].data.data;
        this.setState({
          marcacao,
          showTriagem: !this.state.showTriagem,
          paciente,
          listParamTriagem,
        });
        this.props.loading(false);
      });
    } else {
      this.setState({ marcacao, showTriagem: !this.state.showTriagem });
    }
  };

  render() {
    let { ...otherProps } = this.props;
    return (
      <div
        className={
          'header-prontuario d-flex flex-column flex-sm-row align-items-center'
        }
      >
        <h1 className={'text-center'}>Anamnese</h1>
        <div
          className={
            'd-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between'
          }
        >
          <div>
            {this.props.triagem && (
              <React.Fragment>
                <button
                  type="button"
                  onClick={this.toggleTriagem}
                  className="btn btn-primary "
                >
                  <span className="icon-triagem"></span>Exibir triagem
                </button>
                <ModalCrud
                  title="Triagem"
                  pacienteOnline={this.props.pacienteOnline}
                  toogle={() => {
                    this.toggleTriagem();
                  }}
                  isOpen={this.state.showTriagem}
                  paciente={this.props.paciente}
                  Componente={TriagemPage}
                  agendamentoMode={true}
                  className={'modal-filaAtendimento modal-lg'}
                  viewMode={true}
                  triagem={this.props.triagem}
                  entity={this.props.marcacao}
                  titleBtnSave={'Salvar'}
                  listParamTriagem={this.state.listParamTriagem}
                />
              </React.Fragment>
            )}
            <button
              type="button"
              onClick={this.props.toggleModalGerarDocumentos}
              className="btn btn-info "
            >
              <span className="icon-print" />
              Outros modelos para impressão
            </button>
          </div>
          <div className={'d-flex justify-content-end'}>
            {!this.props.viewMode && (
              <React.Fragment>
                <button
                  type="button"
                  onClick={this.props.changeView}
                  className="btn  btn-secondary"
                >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Salvar
                </button>
                {_.get(this.props.values, 'marcacao.status') !=
                  objectsConstants.ATENDIDO && (
                  <button
                    type="button"
                    onClick={this.props.finalizaAtendimento}
                    className="btn  btn-danger"
                    id={'finalizar'}
                  >
                    Finaliza Atendimento
                  </button>
                )}
              </React.Fragment>
            )}
            {this.props.viewMode && (
              <React.Fragment>
                <button
                  type="button"
                  onClick={this.props.changeView}
                  className="btn btn-primary"
                >
                  Editar
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isAnamneseEditing } = state.anamnese;

  return {
    isAnamneseEditing,
  };
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load }),
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(HeaderProntuario));
