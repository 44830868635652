//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RelatoriosConsultasListagemPage from './RelatoriosConsultasListagemPage';

export default class RelatoriosIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/relatorios"
          component={RelatoriosConsultasListagemPage}
          exact
        />
      </Switch>
    );
  }
}
