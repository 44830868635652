import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import FormInput from '../../../components/inputs/FormInput';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import ZipCodeInput from '../../../components/inputs/ZipCodeInput';
import ApiIBGE from '../../../_services/uf.service';
import CpfInput from '../../../components/inputs/CpfInput';
import { useValidationsBR } from 'validations-br';

const Step3ValidateModel = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
  emailEstabelecimento: Yup.string()
    .required('Campo obrigatório')
    .email('Email inválido'),
  endereco: Yup.object().shape({
    cep: Yup.string().required('Campo obrigatório'),
    logradouro: Yup.string().required('Campo obrigatório'),
    estado: Yup.string().required('Campo obrigatório'),
    numero: Yup.string().required('Campo obrigatório'),
    bairro: Yup.string().required('Campo obrigatório'),
    cidade: Yup.string().required('Campo obrigatório'),
  }),
  nomeResponsavel: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('is-cpf', 'CPF não é válido', function (value) {
      const { path, createError } = this;
      if (!useValidationsBR('cpf', value)) {
        return createError({ path, message: 'CPF não é válido' });
      }
      return true;
    }),
});

class Step3 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Formik
        validationSchema={Step3ValidateModel}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.props.entity}
        ref={(form) => {
          this.form = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="row form-inside">
              <div className="col-12 section-form">
                <p>Passo 3 de 6 - Dados do Beneficiário</p>
                <div className="row">
                  <FormGroup className="col-12 col-md-6">
                    <InputViewEdit
                      component={FormInput}
                      label="Nome da empresa"
                      type="text"
                      name="nome"
                      id="nome"
                      placeholder="Nome da empresa"
                      required={true}
                      value={_.get(values, 'nome')}
                      onChange={this.props.handleChange}
                      erroMensagem={_.get(errors, 'nome')}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6">
                    <InputViewEdit
                      component={FormInput}
                      label="E-mail do estabelecimento"
                      placeholder={'E-mail do estabelecimento'}
                      id={'emailEstabelecimento'}
                      name="emailEstabelecimento"
                      type={'text'}
                      value={_.get(values, 'emailEstabelecimento')}
                      required={true}
                      onChange={this.props.handleChange}
                      erroMensagem={_.get(errors, 'emailEstabelecimento')}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-lg-2">
                    <InputViewEdit
                      component={ZipCodeInput}
                      label="CEP"
                      id="enderecoId"
                      name="endereco.id"
                      value={_.get(values, 'endereco.cep')}
                      erroMensagem={errors.endereco && errors.endereco.cep}
                      required={true}
                      placeholder="CEP"
                      required={true}
                      onChange={(name, value) => {
                        if (values.endereco) {
                          value.id = values.endereco.id;
                        }
                        value.estado = value.uf;
                        setFieldValue('endereco', value);
                      }}
                      returnFullCity={false}
                    />
                  </FormGroup>
                  <FormGroup className="col-10 col-md-8">
                    <InputViewEdit
                      component={FormInput}
                      label="Logradouro"
                      type="text"
                      name="endereco.logradouro"
                      id="logradouro"
                      required={true}
                      placeholder="Av, rua, praça"
                      value={_.get(values, 'endereco.logradouro')}
                      onChange={this.props.handleChange}
                      erroMensagem={_.get(errors, 'endereco.logradouro')}
                    />
                  </FormGroup>
                  <FormGroup className="col-2 col-md-2">
                    <InputViewEdit
                      component={FormInput}
                      type="text"
                      label="Nº"
                      placeholder="Nº"
                      onChange={this.props.handleChange}
                      noSize={true}
                      id="endereconumero"
                      value={_.get(values, 'endereco.numero')}
                      name="endereco.numero"
                      erroMensagem={_.get(errors, 'endereco.numero')}
                      required={true}
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-md-3">
                    <InputViewEdit
                      component={FormInput}
                      label="Complemento"
                      type="text"
                      placeholder="Complemento"
                      erroMensagem={errors.complemento}
                      onChange={this.props.handleChange}
                      noSize={true}
                      id="enderecocomplemento"
                      value={_.get(values, 'endereco.complemento')}
                      name="endereco.complemento"
                      erroMensagem={_.get(errors, 'endereco.complemento')}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-lg-3">
                    <InputViewEdit
                      component={FormInput}
                      label="Bairro"
                      type="text"
                      placeholder="Bairro"
                      erroMensagem={errors.bairro}
                      onChange={this.props.handleChange}
                      noSize={true}
                      id="enderecobairro"
                      value={_.get(values, 'endereco.bairro')}
                      name="endereco.bairro"
                      erroMensagem={_.get(errors, 'endereco.bairro')}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-8 col-lg-3">
                    <InputViewEdit
                      component={FormInput}
                      label="Cidade"
                      type="text"
                      placeholder="Cidade"
                      erroMensagem={errors.cidade}
                      onChange={this.props.handleChange}
                      noSize={true}
                      id="enderecocidade"
                      value={_.get(values, 'endereco.cidade')}
                      name="endereco.cidade"
                      erroMensagem={_.get(errors, 'endereco.cidade')}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-4 col-lg-3">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'UF'}
                      id="estado"
                      name="endereco.estado"
                      placeholder="UF"
                      value={_.get(values, 'endereco.estado')}
                      onChange={this.props.handleChange}
                      noSize={true}
                      erroMensagem={_.get(errors, 'endereco.estado')}
                      required={true}
                      returnFullObject={false}
                      valueKey={'sigla'}
                      labelKey={'sigla'}
                      multi={false}
                      service={ApiIBGE.findAllEstados}
                      sortKey={'sigla'}
                    />
                  </FormGroup>
                  <FormGroup className="col-4">
                    <InputViewEdit
                      component={FormInput}
                      label="Nome do responsável"
                      type="text"
                      name="nomeResponsavel"
                      id="nomeResponsavel"
                      placeholder="Nome do responsável pela empresa"
                      required={true}
                      value={_.get(values, 'nomeResponsavel')}
                      onChange={this.props.handleChange}
                      erroMensagem={_.get(errors, 'nomeResponsavel')}
                    />
                  </FormGroup>
                  <FormGroup className="col-4">
                    <CpfInput
                      label="CPF do responsável"
                      placeholder={'CPF'}
                      id={'cpf'}
                      name="cpf"
                      type={'number'}
                      value={_.get(values, 'cpf')}
                      required={true}
                      onChange={this.props.handleChange}
                      erroMensagem={_.get(errors, 'cpf')}
                    />
                  </FormGroup>
                  <FormGroup className="col-4">
                    <InputViewEdit
                      component={FormInput}
                      label="E-mail do responsável"
                      placeholder={'E-mail do responsável'}
                      id={'email'}
                      name="email"
                      type={'text'}
                      value={_.get(values, 'email')}
                      required={true}
                      onChange={this.props.handleChange}
                      erroMensagem={_.get(errors, 'email')}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Step3;
