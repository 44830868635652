//@flow
import axios from 'axios';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { defaultService } from '../../_services/defaultService';
import ExportExcelButton from '../buttons/ExportExcelButton';
import DataTable from '../table/DataTable';
import { objectsConstants } from './../../_constants/objects.constants';
import HeaderList from './HeaderList';
import sortIcon from './../../images/icones/arrow-down.svg';
import DataTableRelatorio from '../table/DataTableRelatorio';
let download = require('downloadjs');

type Props = {
  title?: string,
  children: any,
  FormFilter?: any,
  newButtonActionClick: Function | string,
  showNewButton: boolean,
  defaultOrder: string,
  classSignal: string,
  defaultDirection: string,
  service: any,
  deleteSuccess: string,
  labelNewButton: string,
  onRowClick: any,
  noFilter?: boolean,
  filtro: any,
};

type State = { filtro: any, filterForm: any, submitFilter: boolean };

class ListagemRelatorioPage extends React.Component<Props, State> {
  static defaultProps = {
    defaultOrder: 'id',
    showNewButton: true,
    deleteSuccess: 'Registro excluído com sucesso.',
    forceUpdate: false,
    loadOnInit: true,
  };

  _datatable: any;
  _source: any;

  constructor(props: any) {
    super(props);

    this._source = axios.CancelToken.source();
    let filtro = this.props.filtro;
    this.state = {
      filtro,
      filterForm: {},
      submitFilter: false,
      // openSearch: false,
    };
  }

  handleClick = () => {
    this.props.loading(true);
    defaultService
      .exportToExcel(
        this.props.controller,
        this.props.filtro,
        this.props.fields,
        this.props.labels
      )
      .then(
        (response) => {
          download(
            response.data,
            (this.props.downloadExcelName
              ? this.props.downloadExcelName
              : this.props.controller) + '.csv',
            'text/html'
          );
          this.props.loading(false);
        },
        () => {
          this.props.loading(false);
          this.props.error({ message: 'Não foi possível exportar arquivo.' });
        }
      );
  };

  componentWillUnmount() {
    this._source.cancel('Operation canceled due component being unmounted.');
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.filtro && !_.isEqual(nextProps.filtro, this.props.filtro)) {
      this.setState({ filtro: Object.assign({}, nextProps.filtro) }, () => {
        this._datatable.resetTable(nextProps.filtro);
      });
    }
  }

  render() {
    const { title, hideExport } = this.props;
    return (
      <div className={'listagem-custom d-flex flex-column'}>
        <div className="conteudo-listagem">
          <div className="action-line">
            <div className="d-flex justify-content-around justify-content-xl-between align-items-center w-100 action-topo-tabela">
              <span className="labelInfoGrid">
                <b>
                  {this.props.subTitle} {this.props.title}
                </b>
              </span>
              <div className="d-flex flex-row justify-content-center justify-sm-content-end">
                <ExportExcelButton onClick={this.handleClick} />
              </div>
            </div>
          </div>
          <DataTableRelatorio
            ref={(datatable) => (this._datatable = datatable)}
            defaultOrder={this.props.defaultOrder}
            defaultDirection={this.props.defaultDirection}
            service={this.props.service}
            classSignal={this.props.classSignal}
            filter={this.state.filtro}
            onRowClick={this.props.onRowClick}
            submitFilter={this.state.submitFilter}
            controller={this.props.controller}
            loadOnInit={this.props.loadOnInit}
            sortIcon={sortIcon}
            columns={this.props.columns}
            trStyle={this.props.trStyle}
            selectRow={this.props.selectRow}
            loading={this.props.loading}
            changeTitle={this.props.changeTitle}
          >
            {this.props.children}
          </DataTableRelatorio>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { filter, page } = state.defaultFilter;
  const { subTitle } = state.listTitle;

  return {
    filter,
    page,
    subTitle,
  };
}
const mapDispatch = ({
  alert: { error, success },
  load: { loading },
  listTitle: { changeTitle },
}) => ({
  error: (message) => error({ message }),
  success: (message) => success({ message }),
  loading: (load: boolean) => loading({ load }),
  changeTitle: (subTitle: string) => changeTitle({ subTitle }),
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(ListagemRelatorioPage);
