//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import IconFiltro from '../../images/icones/filter.svg';
import Avatar from '../Avatar/Avatar';
import _ from 'lodash';
import IconMedico from '../../images/icones/profissionais.svg';
import IconEspecialidades from '../../images/icon-especialidade.svg';
import IconVizualizar from '../../images/icon_visualizar.svg';
import IconOcultar from '../../images/icon_ocultar.svg';
import { withRouter } from 'react-router-dom';
import { PureComponent } from 'react';

class BarraFiltro extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { remarcacao, filter } = this.props;
    console.log(filter);
    return (
      <div className="barra-filtro d-flex flex-row flex-wrap-reverse flex-lg-nowrap align-items-center">
        <div className="order-1 order-md-1 pr-2 py-2">
          <div
            className={`icon-filtro d-flex align-items-center justify-content-center`}
            onClick={() => {
              this.props.abreFiltro();
            }}
          >
            <img src={IconFiltro} alt="Filtra" />
          </div>
        </div>
        <div className="dados-filtro d-flex flex-fill flex-wrap order-2 order-md-2 align-items-center pl-0 pl-lg-3">
          {filter.medicoList && filter.medicoList.length === 1 && (
            <Avatar user={filter.medicoList[0]} editable={false}></Avatar>
          )}

          {filter.medicoList &&
            filter.medicoList.length === 1 &&
            !filter.especialidades && (
              <div className="d-flex flex-column pl-2">
                <React.Fragment>
                  <p>{filter.medicoList[0].apelido}</p>
                  <span className="info-secundaria">
                    {filter.medicoList[0].especialidades instanceof Array
                      ? filter.medicoList[0].especialidades[0].nome
                      : filter.medicoList[0].especialidades.nome}
                  </span>
                </React.Fragment>
              </div>
            )}
          {filter.medicoList &&
            filter.medicoList.length > 1 &&
            !filter.especialidades &&
            filter.medicoList.map((medico, index) => {
              return (
                <React.Fragment key={index}>
                  <Avatar user={medico} editable={false}></Avatar>
                  <div className="d-flex flex-row px-2 my-2">
                    <div>
                      <p>{medico.apelido}</p>
                      <span className="info-secundaria">
                        {medico.especialidades instanceof Array
                          ? _.get(medico, 'especialidades[0].nome')
                          : _.get(medico, 'especialidades.nome')}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          {filter.especialidades && !filter.medicoList && (
            <div className="d-flex flex-column pl-2">
              <p>
                {filter.especialidades instanceof Array
                  ? _.get(filter, 'especialidades[0].nome')
                  : _.get(filter, 'especialidades.nome')}
              </p>
            </div>
          )}
          {filter.convenio && (
            <div className="d-flex flex-column pl-2">
              <p>
                {filter.convenio instanceof Array
                  ? filter.convenio[0].nome
                  : filter.convenio.nome}
              </p>
            </div>
          )}
          {filter.especialidades && filter.medicoList && (
            <div className="d-flex flex-column pl-2">
              <span className="info-secundaria">
                {filter.especialidades instanceof Array
                  ? _.get(filter, 'especialidades[0].nome')
                  : _.get(filter, 'especialidades.nome')}
              </span>
            </div>
          )}
        </div>
        {filter.especialidades && !remarcacao && (
          <div className="tipo-filtro d-flex order-4 order-md-3 align-items-center justify-content-center justify-content-lg-end pl-lg-2">
            <img src={IconEspecialidades} width="30" className="mr-2" />
            <span>Especialidades</span>
          </div>
        )}
        {filter.medicoList && !remarcacao && (
          <div className="tipo-filtro d-flex  order-4 order-md-3 align-items-center justify-content-center justify-content-lg-end pl-lg-2 mt-2 mt-md-0">
            <img src={IconMedico} width="15" className="mr-2" />
            <span>Profissional de saúde</span>
          </div>
        )}
        {filter.convenio && !remarcacao && (
          <div className="tipo-filtro d-flex  order-4 order-md-3 align-items-center justify-content-center justify-content-lg-end pl-lg-2 mt-2 mt-md-0">
            <img src={IconMedico} width="15" className="mr-2" />
            <span>Convênio</span>
          </div>
        )}

        {remarcacao && (
          <div
            onClick={() => {
              this.props.changeFilterTarefas();
            }}
            className="action tipo-filtro d-flex order-4 order-md-3 align-items-center justify-content-lg-end"
          >
            <img src={IconVizualizar} width="18" className="mr-1" />
            <span>
              {filter.medicoList
                ? 'Ver agenda de todos os médicos com as mesmas especialidades'
                : 'Ver agenda do médico'}{' '}
            </span>
          </div>
        )}
        {!remarcacao && (
          <div
            className="icon-limpar-filtro d-flex order-3 order-md-4 align-items-center mx-lg-3 mx-1"
            onClick={() => {
              this.props.limpaFiltro({});
            }}
          ></div>
        )}
      </div>
    );
  }
}

const mapDispatch = ({
  alert: { error },
  load: { loading },
  agenda: { clearFilter },
  filtromenu: { abreFiltro },
}) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  clearFilter: () => clearFilter(),
  abreFiltro: () => abreFiltro({}),
  // changeFilter: (filter) => changeFilter({ filter: filter }),
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  const { filter } = state.agenda;
  return {
    permissions,
    filter,
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(BarraFiltro));
