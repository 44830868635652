//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RelatoriosExamesListagemPage from './RelatoriosExamesListagemPage';

export default class RelatoriosExamesIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/relatorios-exames"
          component={RelatoriosExamesListagemPage}
          exact
        />
      </Switch>
    );
  }
}
