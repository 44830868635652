/* eslint-disable */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
  component: Component,
  loggedIn,
  tokenLoginOnline,
  loginOnline,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn ? (
        <Component {...props} />
      ) : loginOnline ? (
        <Redirect
          to={{
            pathname: '/agendaOnline/' + tokenLoginOnline,
            state: { from: props.location },
          }}
        />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);
