import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import ChartPacientesDashboardComponent from './ChartPacientesDashboardComponent';
import ChartProcedimentosDashboardComponent from './ChartProcedimentosDashboardComponent';
import ChartTipoAtendimentoDashboardComponent from './ChartTipoAtendimentoDashboardComponent';
import ChartTempoAtendimentoDashboardComponent from './ChartTempoAtendimentoDashboardComponent';
import ChartConvenioDashboardComponent from './ChartConveniosDashboardComponent';
import ChartPacientesAnalityDashboardComponent from './ChartPacientesAnalityDashboardComponent';
import ChartProcedimentosAnalyticsDashboardComponent from './ChartProcedimentosAnalityDashboardComponent';
import ChartAtendimentosAnalyticsDashboardComponent from './ChartAtendimentosAnalityDashboardComponent';
import ChartTemposAtendimentoAnalyticsDashboardComponent from './ChartTempoAtendimentosAnalityDashboardComponent';
import ChartConveniosAnalityDashboardComponent from './ChartConveniosAnalityDashboardComponent';
import { Md5 } from 'ts-md5';
import Ar from 'moment/locale/ar';
import ChartTempoAtendimentosAnalityDashboardComponent from './ChartTempoAtendimentosAnalityDashboardComponent';

/**
 * Props
 *
 */
type Props = {
  dataAnalyticsPacientes: DataAnalyticsPacientes[],
  dataAnalyticsProcedimentos: DataAnalyticsProcedimentos[],
  dataAnalyticsAtendimento: DataAnalyticsAtendimentos[],
  dataAnalyticsTemposAtendimento: DataAnalyticsTemposAtendimento,
  dataAnalyticsConvenios: DataAnalyticsConvenios[],
  showSyntheticData: boolean,
  showAnalyticData: boolean,
};

/**
 * State
 *
 */
type State = {
  dataAnalyticsPacientes: DataAnalyticsPacientes[],
  dataChartPacientesDashboard: DataChartPacientesDashboard,
  dataAnalyticsProcedimentos: DataAnalyticsProcedimentos[],
  dataChartProcedimentosDashboard: DataChartProcedimentosDashboard,
  dataAnalyticsAtendimento: DataAnalyticsAtendimentos[],
  dataChartTipoAtendimentoDashboard: DataChartTipoAtendimentoDashboard,
  dataAnalyticsTemposAtendimento: DataAnalyticsTemposAtendimento,
  dataTempoAtendimento: DataTempoAtendimento,
  dataAnalyticsConvenios: DataAnalyticsConvenios[],
  dataConvenio: DataConvenio[],
  showSyntheticData: boolean,
  showAnalyticData: boolean,
  hashProps: string,
};

/**
 * Data charts pacientes dashboard
 *
 */
interface DataChartPacientesDashboard {
  quantidadeTotalPacientes: number;
  quantidadeTotalPacientesNovos: number;
  quantidadeTotalPacientesRecorrentes: number;
  quantidadeTotalPacientesHomens: number;
  quantidadeTotalPacientesMulheres: number;
}

/**
 * Data charts procedimentos dashboard
 *
 */
interface DataChartProcedimentosDashboard {
  quantidadeTotalProcedimentos: number;
  quantidadeTotalConsultas: number;
  quantidadeTotalRetornos: number;
  quantidadeTotalExames: number;
}

/**
 * Data charts procedimentos dashboard
 *
 */
interface DataChartTipoAtendimentoDashboard {
  quantidadeTotalProcedimentos: number;
  quantidadeTotalProcedimentosParticulares: number;
  quantidadeTotalProcedimentosConveniadas: number;
  quantidadeTotalProcedimentosCortesia: number;
}

/**
 * Data interface
 *
 */
interface DataTempoAtendimento {
  tempoAtendimentoParticular: DataDetailsTempoAtendimento;
  tempoAtendimentoConvenio: DataDetailsTempoAtendimento;
}

/**
 * Data interface
 *
 */
interface DataDetailsTempoAtendimento {
  minimo: number;
  medio: number;
  maximo: number;
}

/**
 * Interface Data Convenio
 *
 */
interface DataConvenio {
  name: string;
  value: number;
}

/**
 * Data chart pacientes
 *
 */
interface DataAnalyticsPacientes {
  // period: string;
  recorrentes: number;
  novos: number;
  homens: number;
  mulheres: number;
}

/**
 * Data chart procedimentos
 *
 */
interface DataAnalyticsProcedimentos {
  // period: string;
  consultas: number;
  retornos: number;
  exames: number;
}

/**
 * Data chart atendimento
 *
 */
interface DataAnalyticsAtendimentos {
  // period: string;
  particular: number;
  convenio: number;
  cortesia: number;
}

/**
 * Data chart pacientes
 *
 */
interface DataAnalyticsTemposAtendimento {
  particularData: DataAnalyticsTemposAtendimentoDetails[];
  convenioData: DataAnalyticsTemposAtendimentoDetails[];
}

/**
 * Data chart pacientes
 *
 */
interface DataAnalyticsTemposAtendimentoDetails {
  // period: string;
  minimo: number;
  medio: number;
  maximo: number;
}

/**
 * Data chart DataAnalyticsConvenios
 *
 */
interface DataAnalyticsConvenios {
  // period: string;
  convenios: Convenios[];
}

/**
 * Convenios
 *
 */
interface Convenios {
  name: string;
  value: number;
}

/**
 * Chart Dashboard Component
 *
 */
class ChartDashboardComponent extends React.Component<Props, State> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Responsible change componente.
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    let newHashProps: string = this.generateHasProps(
      this.props.dataAnalyticsPacientes,
      this.props.dataAnalyticsProcedimentos,
      this.props.dataAnalyticsAtendimento,
      this.props.dataAnalyticsTemposAtendimento,
      this.props.dataAnalyticsConvenios,
      this.props.showAnalyticData,
      this.props.showSyntheticData
    );
    if (newHashProps !== prevState.hashProps) {
      this.setState(this.getInitialState());
    }
  }

  /**
   * Responsible get initial state
   *
   * @return {{showSyntheticData: (boolean|boolean), showAnalyticData: (boolean|boolean), syntheticData: SyntheticData, analyticData: Analytic[]}}
   */
  getInitialState(): any {
    console.log(this.props.dataAnalyticsConvenios);
    console.log(
      this.getSyntheticConvenioData(this.props.dataAnalyticsConvenios)
    );
    return {
      dataAnalyticsPacientes: this.props.dataAnalyticsPacientes,
      dataChartPacientesDashboard: this.getSyntheticPacientesData(
        this.props.dataAnalyticsPacientes
      ),
      dataAnalyticsProcedimentos: this.props.dataAnalyticsProcedimentos,
      dataChartProcedimentosDashboard: this.getSyntheticProcedimentosData(
        this.props.dataAnalyticsProcedimentos
      ),
      dataAnalyticsAtendimento: this.props.dataAnalyticsAtendimento,
      dataChartTipoAtendimentoDashboard: this.getSyntheticAtendimentoData(
        this.props.dataAnalyticsAtendimento
      ),
      dataAnalyticsTemposAtendimento: this.props.dataAnalyticsTemposAtendimento,
      dataTempoAtendimento: this.getSyntheticTempoAtendimentoData(
        this.props.dataAnalyticsTemposAtendimento
      ),
      dataAnalyticsConvenios: this.props.dataAnalyticsConvenios,
      dataConvenio: this.getSyntheticConvenioData(
        this.props.dataAnalyticsConvenios
      ),
      showAnalyticData:
        this.props.showAnalyticData !== undefined
          ? this.props.showAnalyticData
          : false,
      showSyntheticData:
        this.props.showSyntheticData !== undefined
          ? this.props.showSyntheticData
          : true,
      hashProps: this.generateHasProps(
        this.props.dataAnalyticsPacientes,
        this.props.dataAnalyticsProcedimentos,
        this.props.dataAnalyticsAtendimento,
        this.props.dataAnalyticsTemposAtendimento,
        this.props.dataAnalyticsConvenios,
        this.props.showAnalyticData,
        this.props.showSyntheticData
      ),
    };
  }

  /**
   * Responsible generate hash.
   *
   * @param dataAnalyticsPacientes
   * @param dataAnalyticsProcedimentos
   * @param dataAnalyticsAtendimento
   * @param dataAnalyticsTemposAtendimento
   * @param dataAnalyticsConvenios
   * @param showAnalyticData
   * @param showSyntheticData
   * @return {string}
   */
  generateHasProps = (
    dataAnalyticsPacientes: DataAnalyticsPacientes[],
    dataAnalyticsProcedimentos: DataAnalyticsProcedimentos[],
    dataAnalyticsAtendimento: DataAnalyticsAtendimentos[],
    dataAnalyticsTemposAtendimento: DataAnalyticsTemposAtendimento,
    dataAnalyticsConvenios: DataAnalyticsConvenios[],
    showAnalyticData: boolean,
    showSyntheticData: boolean
  ): string => {
    return Md5.hashStr(
      JSON.stringify(dataAnalyticsPacientes) +
        JSON.stringify(dataAnalyticsProcedimentos) +
        JSON.stringify(dataAnalyticsAtendimento) +
        JSON.stringify(dataAnalyticsTemposAtendimento) +
        JSON.stringify(dataAnalyticsConvenios) +
        JSON.stringify(showAnalyticData) +
        JSON.stringify(showSyntheticData)
    );
  };

  /**
   * Responsible get Synthentic Pacientes Data
   *
   * @param dataAnalyticsPacientes - DataAnalyticsPacientes[]
   * @returns DataChartPacientesDashboard
   */
  getSyntheticPacientesData(
    dataAnalyticsPacientes: DataAnalyticsPacientes[]
  ): DataChartPacientesDashboard {
    if (dataAnalyticsPacientes) {
      let quantidadeTotalPacientes = 0;
      let quantidadeTotalPacientesNovos = 0;
      let quantidadeTotalPacientesRecorrentes = 0;
      let quantidadeTotalPacientesHomens = 0;
      let quantidadeTotalPacientesMulheres = 0;
      _.forEach(
        dataAnalyticsPacientes,
        function (dataAnalyticsPaciente: DataAnalyticsPacientes) {
          quantidadeTotalPacientes +=
            dataAnalyticsPaciente.novos + dataAnalyticsPaciente.recorrentes;
          quantidadeTotalPacientesNovos += dataAnalyticsPaciente.novos;
          quantidadeTotalPacientesRecorrentes +=
            dataAnalyticsPaciente.recorrentes;
          quantidadeTotalPacientesHomens += dataAnalyticsPaciente.homens;
          quantidadeTotalPacientesMulheres += dataAnalyticsPaciente.mulheres;
        }
      );
      return {
        quantidadeTotalPacientes: quantidadeTotalPacientes,
        quantidadeTotalPacientesNovos: quantidadeTotalPacientesNovos,
        quantidadeTotalPacientesRecorrentes:
          quantidadeTotalPacientesRecorrentes,
        quantidadeTotalPacientesHomens: quantidadeTotalPacientesHomens,
        quantidadeTotalPacientesMulheres: quantidadeTotalPacientesMulheres,
      };
    } else {
      return {
        quantidadeTotalPacientes: 0,
        quantidadeTotalPacientesNovos: 0,
        quantidadeTotalPacientesRecorrentes: 0,
        quantidadeTotalPacientesHomens: 0,
        quantidadeTotalPacientesMulheres: 0,
      };
    }
  }

  /**
   * Responsible get Synthentic Procedimentos Data
   *
   * @param dataAnalyticsProcedimentos - DataAnalyticsProcedimentos[]
   * @returns DataChartPacientesDashboard
   */
  getSyntheticProcedimentosData(
    dataAnalyticsProcedimentos: DataAnalyticsProcedimentos[]
  ): DataChartProcedimentosDashboard {
    if (dataAnalyticsProcedimentos) {
      let quantidadeTotalProcedimentos = 0;
      let quantidadeTotalConsultas = 0;
      let quantidadeTotalRetornos = 0;
      let quantidadeTotalExames = 0;
      _.forEach(
        dataAnalyticsProcedimentos,
        function (dataAnalyticsProcedimento: DataAnalyticsProcedimentos) {
          quantidadeTotalProcedimentos +=
            dataAnalyticsProcedimento.consultas +
            dataAnalyticsProcedimento.retornos +
            dataAnalyticsProcedimento.exames;
          quantidadeTotalConsultas += dataAnalyticsProcedimento.consultas;
          quantidadeTotalRetornos += dataAnalyticsProcedimento.retornos;
          quantidadeTotalExames += dataAnalyticsProcedimento.exames;
        }
      );
      return {
        quantidadeTotalProcedimentos: quantidadeTotalProcedimentos,
        quantidadeTotalConsultas: quantidadeTotalConsultas,
        quantidadeTotalRetornos: quantidadeTotalRetornos,
        quantidadeTotalExames: quantidadeTotalExames,
      };
    } else {
      return {
        quantidadeTotalProcedimentos: 0,
        quantidadeTotalConsultas: 0,
        quantidadeTotalRetornos: 0,
        quantidadeTotalExames: 0,
      };
    }
  }

  /**
   * Responsible get Synthentic Atendimento Data
   *
   * @param dataAnalyticsAtendimentos - DataAnalyticsAtendimentos[]
   * @returns DataChartPacientesDashboard
   */
  getSyntheticAtendimentoData(
    dataAnalyticsAtendimentos: DataAnalyticsAtendimentos[]
  ): DataChartTipoAtendimentoDashboard {
    if (dataAnalyticsAtendimentos) {
      let quantidadeTotalProcedimentos = 0;
      let quantidadeTotalProcedimentosParticulares = 0;
      let quantidadeTotalProcedimentosConveniadas = 0;
      let quantidadeTotalProcedimentosCortesia = 0;
      _.forEach(
        dataAnalyticsAtendimentos,
        function (dataAnalyticsAtendimento: DataAnalyticsAtendimentos) {
          quantidadeTotalProcedimentos +=
            dataAnalyticsAtendimento.particular +
            dataAnalyticsAtendimento.convenio +
            dataAnalyticsAtendimento.cortesia;
          quantidadeTotalProcedimentosParticulares +=
            dataAnalyticsAtendimento.particular;
          quantidadeTotalProcedimentosConveniadas +=
            dataAnalyticsAtendimento.convenio;
          quantidadeTotalProcedimentosCortesia +=
            dataAnalyticsAtendimento.cortesia;
        }
      );
      return {
        quantidadeTotalProcedimentos: quantidadeTotalProcedimentos,
        quantidadeTotalProcedimentosParticulares:
          quantidadeTotalProcedimentosParticulares,
        quantidadeTotalProcedimentosConveniadas:
          quantidadeTotalProcedimentosConveniadas,
        quantidadeTotalProcedimentosCortesia:
          quantidadeTotalProcedimentosCortesia,
      };
    } else {
      return {
        quantidadeTotalProcedimentos: 0,
        quantidadeTotalProcedimentosParticulares: 0,
        quantidadeTotalProcedimentosConveniadas: 0,
        quantidadeTotalProcedimentosCortesia: 0,
      };
    }
  }

  /**
   * Responsible get Synthentic Tempo Atendimento Data
   *
   * @param dataAnalyticsTemposAtendimento - DataAnalyticsTemposAtendimento
   * @returns DataChartPacientesDashboard
   */
  getSyntheticTempoAtendimentoData(
    dataAnalyticsTemposAtendimento: DataAnalyticsTemposAtendimento
  ): DataTempoAtendimento {
    if (dataAnalyticsTemposAtendimento) {
      let tempoParticularMinimo = 0;
      let tempoParticularMedio = 0;
      let tempoParticularMaximo = 0;
      let tempoConvenioMinimo = 0;
      let tempoConvenioMedio = 0;
      let tempoConvenioMaximo = 0;
      if (dataAnalyticsTemposAtendimento.particularData) {
        tempoParticularMinimo = Math.round(
          Math.min(
            ...dataAnalyticsTemposAtendimento.particularData
              .map((o) => o.minimo)
              .filter((n) => n > 0)
          )
        );
        let newArrayMedio: Array<number> =
          dataAnalyticsTemposAtendimento.particularData
            .map((o) => o.medio)
            .filter((n) => n > 0);
        tempoParticularMedio = Math.round(
          newArrayMedio.reduce(
            (
              previousValue: number,
              currentValue: number,
              _,
              newArray: number[]
            ) => previousValue + currentValue,
            0
          ) / newArrayMedio.length
        );
        tempoParticularMaximo = Math.round(
          Math.max(
            ...dataAnalyticsTemposAtendimento.particularData
              .map((o) => o.maximo)
              .filter((n) => n > 0)
          )
        );
      }
      if (dataAnalyticsTemposAtendimento.convenioData) {
        tempoConvenioMinimo = Math.round(
          Math.min(
            ...dataAnalyticsTemposAtendimento.convenioData
              .map((o) => o.minimo)
              .filter((n) => n > 0)
          )
        );
        let newArrayMedio: Array<number> =
          dataAnalyticsTemposAtendimento.convenioData
            .map((o) => o.medio)
            .filter((n) => n > 0);
        tempoConvenioMedio = Math.round(
          newArrayMedio.reduce(
            (
              previousValue: number,
              currentValue: number,
              _,
              newArray: number[]
            ) => previousValue + currentValue,
            0
          ) / newArrayMedio.length
        );
        tempoConvenioMaximo = Math.round(
          Math.max(
            ...dataAnalyticsTemposAtendimento.convenioData
              .map((o) => o.maximo)
              .filter((n) => n > 0)
          )
        );
      }
      return {
        tempoAtendimentoParticular: {
          minimo: tempoParticularMinimo,
          medio: tempoParticularMedio,
          maximo: tempoParticularMaximo,
        },
        tempoAtendimentoConvenio: {
          minimo: tempoConvenioMinimo,
          medio: tempoConvenioMedio,
          maximo: tempoConvenioMaximo,
        },
      };
    } else {
      return {
        tempoAtendimentoParticular: {
          minimo: 0,
          medio: 0,
          maximo: 0,
        },
        tempoAtendimentoConvenio: {
          minimo: 0,
          medio: 0,
          maximo: 0,
        },
      };
    }
  }

  /**
   * Responsible get synthentic convenio data
   *
   * @param dataAnalyticsConvenios - dataAnalyticsConvenios
   * @return DataConvenio[]
   */
  getSyntheticConvenioData(
    dataAnalyticsConvenios: DataAnalyticsConvenios[]
  ): DataConvenio[] {
    if (dataAnalyticsConvenios) {
      let mapConvenios: Map<string, number> = new Map();
      let dataConvenio: DataConvenio[] = [];
      _.forEach(
        dataAnalyticsConvenios,
        function (dataAnalyticsConvenio: DataAnalyticsConvenios) {
          if (dataAnalyticsConvenio) {
            _.forEach(dataAnalyticsConvenio, function (convenio: Convenios) {
              if (mapConvenios.has(convenio.name)) {
                mapConvenios.set(
                  convenio.name,
                  mapConvenios.get(convenio.name) + convenio.value
                );
              } else {
                mapConvenios.set(convenio.name, convenio.value);
              }
            });
          }
        }
      );
      mapConvenios.forEach((value: number, key: string) => {
        dataConvenio.push({
          name: key,
          value: value,
        });
      });
      return dataConvenio;
    } else {
      return [];
    }
  }

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <React.Fragment>
        {this.state.showSyntheticData && (
          <Container fluid className="mt-4 mb-5 d-flex justify-content-center">
            <Row className="justify-content-around chart-cards">
              <Col className="col-md-auto">
                <ChartPacientesDashboardComponent
                  quantidadeTotalPacientes={
                    this.state.dataChartPacientesDashboard
                      .quantidadeTotalPacientes
                  }
                  quantidadeTotalPacientesNovos={
                    this.state.dataChartPacientesDashboard
                      .quantidadeTotalPacientesNovos
                  }
                  quantidadeTotalPacientesRecorrentes={
                    this.state.dataChartPacientesDashboard
                      .quantidadeTotalPacientesRecorrentes
                  }
                  quantidadeTotalPacientesHomens={
                    this.state.dataChartPacientesDashboard
                      .quantidadeTotalPacientesHomens
                  }
                  quantidadeTotalPacientesMulheres={
                    this.state.dataChartPacientesDashboard
                      .quantidadeTotalPacientesMulheres
                  }
                />
              </Col>
              <Col className="col-md-auto">
                <ChartProcedimentosDashboardComponent
                  quantidadeTotalProcedimentos={
                    this.state.dataChartProcedimentosDashboard
                      .quantidadeTotalProcedimentos
                  }
                  quantidadeTotalConsultas={
                    this.state.dataChartProcedimentosDashboard
                      .quantidadeTotalConsultas
                  }
                  quantidadeTotalRetornos={
                    this.state.dataChartProcedimentosDashboard
                      .quantidadeTotalRetornos
                  }
                  quantidadeTotalExames={
                    this.state.dataChartProcedimentosDashboard
                      .quantidadeTotalExames
                  }
                />
              </Col>
              <Col className="col-md-auto">
                <ChartTipoAtendimentoDashboardComponent
                  quantidadeTotalProcedimentos={
                    this.state.dataChartTipoAtendimentoDashboard
                      .quantidadeTotalProcedimentos
                  }
                  quantidadeTotalProcedimentosParticulares={
                    this.state.dataChartTipoAtendimentoDashboard
                      .quantidadeTotalProcedimentosParticulares
                  }
                  quantidadeTotalProcedimentosConveniadas={
                    this.state.dataChartTipoAtendimentoDashboard
                      .quantidadeTotalProcedimentosConveniadas
                  }
                  quantidadeTotalProcedimentosCortesia={
                    this.state.dataChartTipoAtendimentoDashboard
                      .quantidadeTotalProcedimentosCortesia
                  }
                />
              </Col>
              <Col className="col-md-auto">
                <ChartTempoAtendimentoDashboardComponent
                  tempoAtendimentoParticular={
                    this.state.dataTempoAtendimento.tempoAtendimentoParticular
                  }
                  tempoAtendimentoConvenio={
                    this.state.dataTempoAtendimento.tempoAtendimentoConvenio
                  }
                />
              </Col>
              <div className="w-100"></div>
              <Col className="col-md-auto column-chart-convenios-with-padding">
                <ChartConvenioDashboardComponent
                  dataConvenio={this.state.dataConvenio}
                />
              </Col>
            </Row>
          </Container>
        )}
        {this.state.showAnalyticData && (
          <Container fluid className="mt-4 mb-5 d-flex justify-content-center">
            <Row className="justify-content-around chart-cards">
              <Col className="pb-4">
                <ChartPacientesAnalityDashboardComponent
                  dataAnalyticsPacientes={this.state.dataAnalyticsPacientes}
                />
              </Col>
              <div className="w-100"></div>
              <Col className="pb-4">
                <ChartProcedimentosAnalyticsDashboardComponent
                  dataAnalyticsProcedimentos={
                    this.state.dataAnalyticsProcedimentos
                  }
                />
              </Col>
              <div className="w-100"></div>
              <Col className="pb-4">
                <ChartAtendimentosAnalyticsDashboardComponent
                  dataAnalyticsAtendimentos={
                    this.state.dataAnalyticsAtendimento
                  }
                />
              </Col>
              <div className="w-100"></div>
              <Col className="pb-4">
                <ChartTempoAtendimentosAnalityDashboardComponent
                  dataAnalyticsTempoAtendimentos={
                    this.state.dataAnalyticsTemposAtendimento.convenioData
                  }
                  titleDash="Tempo de atendimento Convênio"
                  scheme="greens"
                />
              </Col>
              <div className="w-100"></div>
              <Col className="pb-4">
                <ChartTempoAtendimentosAnalityDashboardComponent
                  dataAnalyticsTempoAtendimentos={
                    this.state.dataAnalyticsTemposAtendimento.particularData
                  }
                  titleDash="Tempo de atendimento Particular"
                  scheme="blue_purple"
                />
              </Col>
              <div className="w-100"></div>
              <Col className="pb-4 column-chart-convenios">
                <ChartConveniosAnalityDashboardComponent
                  dataAnalyticsConvenios={this.state.dataAnalyticsConvenios}
                />
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

/**
 * Export default connect
 *
 */
export default connect(null, null, null)(ChartDashboardComponent);
