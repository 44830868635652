import React, { useRef } from 'react';
import imgLogo from '../../images/logo.svg'
import {urlsConstants} from '../../_constants/urls.constant'

function VideoConference (props) {
  const jitsiContainerId = "jitsi-container-id";
  const [jitsi, setJitsi] = React.useState({});
  const {roomName,userName,userEmail}=props

  console.log('process',urlsConstants.REACT_APP_URL_JITSI_SERVER)

  const loadJitsiScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src =urlsConstants.REACT_APP_URL_JITSI_SERVER;
      script.async = true;
      script.onload = resolve
      document.body.appendChild(script);
    });
  };

  const executeCommand=(comand)=>{
    return jitsi.executeCommand('comand');
  }

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const _jitsi = new window.JitsiMeetExternalAPI(urlsConstants.JITSI_SERVER_BASE_URL, {
      parentNode: document.getElementById(jitsiContainerId),
      roomName,
      interfaceConfigOverwrite: { TOOLBAR_BUTTONS: [ 'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
          'fodeviceselection', 'hangup', 'chat',
          'etherpad',  'shareaudio', 'raisehand',
          'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
          'tileview',  'download', 'help'],DEFAULT_LOGO_URL:imgLogo },
      userInfo: {
        email: userEmail,
        displayName: userName
      }

    });
    _jitsi.on('participantLeft', (participante) => {
      let participantes = _jitsi.getParticipantsInfo();
      if(props.participantLeft){
        props.participantLeft(participantes)
      }

    });

    _jitsi.executeCommand('subject', props.subject);



    setJitsi(_jitsi);
  };

  React.useEffect(() => {
    initialiseJitsi();
    return jitsi && jitsi.dispose?jitsi.dispose():()=>null;
  }, []);

  return <div id={jitsiContainerId} style={{ height: "80vh", width: "100%" }} />
};


export default VideoConference;
