//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import FormInput from '../../../components/inputs/FormInput';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import { objectsEnuns } from '../../../_constants/objects.constants';
import { dateHelper } from '../../../_helpers/date.helper';
import { userService } from '../../../_services/user.service';
import { pacienteService } from '../../../_services/paciente.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class EstoqueSaidaListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        {/* <FormGroup className="col-12 col-xl-3">
          <InputViewEdit
            label={'Tipo de saida'}
            placeholder="Selecione o tipo de saida"
            component={FormSelectInput}
            options={objectsEnuns.TIPO_ENTRADA_NOTA_FISCAL}
            labelKey={'label'}
            valueKey={'value'}
            returnFullObject={false}
            defaultValue={_.get(entity, 'type')}
            value={_.get(entity, 'type')}
            id="type"
            name="type"
            onChange={this.props.onChangeDate}
          />
        </FormGroup> */}
        <FormGroup className="col-12 col-md-2">
          <InputViewEdit
            component={DatePickerInput}
            label="Data Inicial"
            name="dataHoraLancamentoInicio"
            id="dataHoraLancamentoInicio"
            value={entity.dataHoraLancamentoInicio}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataHoraLancamentoInicio`)
                ? dateHelper.format(_.get(entity, `dataHoraLancamentoInicio`), {
                    mode: 'DATE',
                  })
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-2">
          <InputViewEdit
            component={DatePickerInput}
            label="Data Final"
            name="dataHoraLancamentoFim"
            id="dataHoraLancamentoFim"
            value={entity.dataHoraLancamentoFim}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataHoraLancamentoFim`)
                ? dateHelper.format(_.get(entity, `dataHoraLancamentoFim`), {
                    mode: 'DATE',
                  })
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-4">
          <SelectInput
            options={objectsEnuns.TIPO_SAIDA_NOTA_FISCAL}
            type="text"
            name="tipoSaida"
            label="Tipo de saída"
            placeholder="Selecione o tipo de saída"
            onChange={this.props.onChangeDate}
            id="tipoSaida"
            returnFullObject={false}
            labelKey={'label'}
            valueKey={'value'}
            value={_.get(entity, 'tipoSaida')}
            defaultValue={_.get(entity, 'tipoSaida')}
          />
        </FormGroup>

        <FormGroup className="col-12 col-xl-4">
          <SelectInput
            options={objectsEnuns.SITUACAO_PRODUTO}
            type="text"
            name="situacao"
            label="Situação"
            placeholder="Selecione a situação do produto"
            onChange={this.props.onChangeDate}
            id="situacao"
            returnFullObject={false}
            labelKey={'label'}
            valueKey={'value'}
            value={_.get(entity, 'situacao')}
            defaultValue={_.get(entity, 'situacao')}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
