//@flow
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TarefasList from './TarefasList';
import TarefasMarcacaoNaoFinalizadaList from './TarefasMarcacaoNaoFinalizadaList';

type Props = {
  entity: any,
  data: date,
};

class DiaTarefas extends React.Component<Props, State> {
  render() {
    const { listaDoDia, data } = this.props;
    let taskListByUser = _.groupBy(listaDoDia, function (tarefa) {
      return _.get(tarefa, 'user.id', _.get(tarefa, 'exame.id'));
    });

    let monthNameShort = data
      ? data.toLocaleString('pt-br', { month: 'long' })
      : '';
    let fullYear = data ? data.getFullYear() : '';

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end ">
            <div className="calendar-date d-flex flex-row flex-lg-column align-items-center justify-content-center">
              <div className="font-date">{data && data.getDate()}</div>
              <p className="nome-mes">{monthNameShort}</p>
              <p className="ano">{fullYear}</p>
            </div>
          </div>
          <div className="col-12 col-lg-10 listaTarefas">
            {Object.entries(taskListByUser).map(([idUser, listTask]) => {
              return (
                <TarefasMarcacaoNaoFinalizadaList
                  key={idUser}
                  listTaskPacientes={listTask}
                  medico={listTask[0].user}
                  exame={listTask[0].exame}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}
export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(DiaTarefas));
