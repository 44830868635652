//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { translate } from '../../_helpers/messages.helper';
import { defaultService } from '../../_services/defaultService';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { perfilservice } from '../../_services/perfil.service';
import { userService } from '../../_services/user.service';
import { objectsConstants } from '../../_constants/objects.constants';
import { formatterHelper } from '../../_helpers/formatter.helper';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};
const OBRIGATORIO = 'Obrigatório';
const STRING_REQUIRED = Yup.string().required(OBRIGATORIO);
const UsuarioValidate = Yup.object().shape({
  nome: Yup.string()
    .min(5, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required(OBRIGATORIO)
    .matches(/^[A-ZÀ-Úa-zà-ú\s]+$/, 'Somente letras são aceitas'),
  cpfCnpj: STRING_REQUIRED,
  status: STRING_REQUIRED,
  email: STRING_REQUIRED.email('Email inválido'),
  senha: Yup.string().test(
    'valid-password',
    'Senha é obrigatório',
    function () {
      const { parent, createError } = this;
      if (parent.id == undefined && parent.senha === undefined) {
        return createError();
      } else {
        return true;
      }
    }
  ),
  estabelecimento: Yup.string().test(
    'valid-estabelecimento',
    'Estábelecimento é obrigatório',
    function () {
      const { parent, createError } = this;
      if (
        parent.id == undefined &&
        parent.estabelecimento === undefined &&
        !userService.getExtension() &&
        !_.get(parent, 'role.role.name') === 'ROLE_SUDO'
      ) {
        return createError({ path: 'estabelecimento', message: OBRIGATORIO });
      } else {
        return true;
      }
    }
  ),
});

class UsuarioForm extends React.Component<Props, State> {
  // static defaultProps={
  //   redirectPath:"/usuario/view/"
  // }
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };
  saveForm = () => {
    console.log('SVForm');
    let _this = this;
    let values = _.cloneDeep(this.formRef.state.values);

    if (userService.isCurrent(values)) {
      _.set(values, 'status', true);
    }
    let stt = _.get(values, 'status');

    if (_.isObject(stt)) {
      let sttValue = _.get(stt, 'value');
      _.set(values, 'status', sttValue);
    }

    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          values.status = values.status.value
            ? values.status.value
            : values.status;
          _this.props.loading(true);
          userService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Usuário, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar usuário.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    console.log(entity);
    return (
      <React.Fragment>
        <Formik
          validationSchema={UsuarioValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      component={FormInput}
                      label={'Nome'}
                      type="text"
                      id="nome"
                      name="nome"
                      placeholder="Digite o nome do Usuário"
                      value={values.nome}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.nome}
                      viewMode={viewMode}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-2">
                    <InputViewEdit
                      component={CpfCnpjInput}
                      label={'CPF'}
                      type="text"
                      id="cpfCnpj"
                      name="cpfCnpj"
                      placeholder="Digite o CPF"
                      value={values.cpfCnpj}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.cpfCnpj}
                      viewMode={viewMode}
                      required={true}
                      viewClassName={'font-weight-bold'}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'E-mail principal'}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Digite o e-mail principal"
                      value={values.email}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode}
                      required={true}
                      erroMensagem={errors.email}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-xl-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'Senha'}
                      type="password"
                      id="senha"
                      defaultValue={'●●●●●●'}
                      name="senha"
                      placeholder="Digite a senha"
                      value={values.senha}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode}
                      erroMensagem={errors.senha}
                      required={!values.id}
                      maxLength={30}
                      viewPassword={true}
                    />
                  </FormGroup>

                  {!userService.getExtension() && (
                    <FormGroup className="col-12 col-xl-4">
                      <InputViewEdit
                        component={FormSelectInput}
                        type="text"
                        name="estabelecimento"
                        label="Estabelecimento"
                        placeholder="Selecione o local do atendimento"
                        onChange={setFieldValue}
                        noSize={true}
                        viewMode={viewMode}
                        id="estabelecimento"
                        returnFullObject={true}
                        valueKey={'id'}
                        labelKey={'nome'}
                        multi={false}
                        service={estabelecimentoService.findAll}
                        value={values.estabelecimento}
                        erroMensagem={errors.estabelecimento}
                        required={true}
                        defaultValue={_.get(values, 'estabelecimento.nome')}
                      />
                    </FormGroup>
                  )}
                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Perfil'}
                      id="role"
                      name="role.role"
                      placeholder="Perfil"
                      value={_.get(values, 'role.role')}
                      onChange={(name, value) => {
                        console.log(name);
                        console.log(value);
                        setFieldValue(name, value);
                      }}
                      noSize={true}
                      erroMensagem={_.get(errors, 'role')}
                      viewMode={viewMode || userService.isCurrent(values)}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      service={perfilservice.findAll}
                      defaultValue={_.get(values, 'role.role.description')}
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-xl-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Ativo'}
                      id="status"
                      name="status"
                      placeholder="Ativo"
                      value={
                        _.isBoolean(_.get(values, 'status'))
                          ? formatterHelper.getEnum(
                              _.get(values, 'status'),
                              objectsConstants.SIM_NAO
                            )
                          : _.get(values, 'status')
                      }
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      noSize={true}
                      erroMensagem={_.get(errors, 'status')}
                      viewMode={viewMode || userService.isCurrent(values)}
                      required={true}
                      returnFullObject={true}
                      valueKey={'value'}
                      labelKey={'label'}
                      multi={false}
                      options={objectsConstants.SIM_NAO}
                      defaultValue={
                        values.status && values.status.value === false
                          ? 'Não'
                          : formatterHelper.getLabelEnum(
                              values.status && values.status.value
                                ? values.status.value
                                : values.status,
                              objectsConstants.SIM_NAO
                            )
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(UsuarioForm);
