//@flow
type State = {
    menuAberto: boolean,
};

export default {
    state: { menuAberto: false },
    reducers: {
        toggleMenu: (state: State) => {
            return Object.assign({}, state, {
                menuAberto: !state.menuAberto
            });
        },
        abreMenu: (state: State,) => {
            return Object.assign({}, state, {
                menuAberto: true,
            });
        },
        fechaMenu: (state: State) => {
            return Object.assign({}, state, {
                menuAberto: false
            });
        },

    }
};
