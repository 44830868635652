//@flow
export function oauthHeader() {
  const storageToken = localStorage.getItem("token");
  if (storageToken) {
    const token = JSON.parse(storageToken);
    return { Authorization: "Bearer " + token.access_token };
  } else {
    return {};
  }
}

export function oauthHeaderJson() {
  const storageToken = localStorage.getItem("token");

  if (storageToken) {
    const token = JSON.parse(storageToken);
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access_token
    };
  } else {
    return {};
  }
}
