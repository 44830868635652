//@flow
import * as React from "react";
import { connect } from "react-redux";
//import CurrencyInput from 'react-currency-input';
//import CurrencyInput from 'react-currency-input-field'
import { Label } from "reactstrap";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import CurrencyInput from "./CurrencyInput";
import { formatterHelper } from "../../_helpers/formatter.helper";
import _ from 'lodash';
type Props = {
  name: string,
  label: string,
  onChange: any,
  size?: number,
  required: boolean,
  messageValidate?: Array<any>,
  value?: any,
  messages?: any
};

type State = {
  value?: any
};

class MoneyInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    required: false,
    value: null,
    placeholder: "0,00"
  };

  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }

  onlyMoney = valueWithPrefix => {
    if (valueWithPrefix) {
      if (!_.isNumber(valueWithPrefix))
        return formatterHelper.parseMoney(valueWithPrefix.replace("R$", ""));
    }
    return valueWithPrefix;
  };

  render() {
    let colSize = "col";
    if (this.props.size) {
      colSize += "-" + this.props.size;
    }

    const { messageValidate, name } = this.props;

    let error;

    if (messageValidate && messageValidate.length > 0) {
      error = messageValidate.find(item => {
        return item.fieldName === name;
      });
    }
    if (this.props.erroMensagem) {
      error = this.props.erroMensagem;
    }
    let value =
      this.props.value === 0 || this.props.value === ""
        ? null
        : this.props.value;
    if (_.isNumber(value)) {
      value = formatterHelper.money(value);
    }
    return (
      <div className={`${this.props.className} ${error ? 'is-invalid' : ''}${this.props.required ? ' input-required' : ''}`}>

        {this.props.label && (
          <React.Fragment>
            <Label
              for={this.props.name}
              className="label-nowrap"
              title={this.props.label}
            >
              {this.props.label}
              {this.props.required && "*"}
            </Label>{" "}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={"help_" + this.props.id} />
                <ToolTipHelp
                  target={"help_" + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <CurrencyInput
          id={this.props.id}
          name={this.props.name}
          value={value}
          onChange={e => {
            const { name, type, value, checked } = e.currentTarget;
            this.props.onChange(name, this.onlyMoney(value), type, checked);
          }}
          required={this.props.required}
          placeholder={
            this.props.placeholder === "" ||
              this.props.placeholder === undefined
              ? this.props.label
              : this.props.placeholder
          }
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={`form-control ${error ? 'is-invalid' : ''}`}
        >
          {this.props.children}
        </CurrencyInput>
        {error && (
          <ToolTipFeedBack
            target={this.props.id ? this.props.id : this.props.name}
            messageValidate={error}
          />
        )}

      </div>
    );
  }
}

function number(v) {
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, "");
  return v;
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(MoneyInput);
