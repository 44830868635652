import _ from 'lodash';
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconPainel from "../../images/icon-tv-WHITE.svg";
import { estabelecimentoService } from "../../_services/estabelecimento.service";
import FormSelectInput from "../inputs/FormSelectInput";
import InputViewEdit from "../inputs/InputViewEdit";


const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalTermoAceite extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,

    };
  }

  cancelar = () => {
    this.props.toogle();
  };

  render() {

    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-termo modal-xl"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="col-12 text-left mb-2">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum massa nec ullamcorper congue. Quisque luctus, mauris convallis molestie consequat, est lacus euismod nisi, in finibus metus metus in odio. Nam non urna eros. Fusce id neque eu nisl dictum tempor id sit amet eros. Ut ut facilisis augue. Nunc quis sapien et neque faucibus cursus. Suspendisse potenti. Phasellus a lectus vel arcu pulvinar dignissim. Sed molestie vehicula aliquam. Curabitur consequat imperdiet fringilla. Maecenas ac neque id ligula lacinia facilisis. Ut fringilla, odio eu tincidunt pretium, enim risus fringilla quam, sit amet pulvinar est sapien in nisi.
              </p><p>
                Vestibulum nec nisl vel ipsum finibus interdum. Cras nec mollis massa. Duis aliquam justo non ligula consequat scelerisque. Sed ut ligula eget erat iaculis vulputate. Duis placerat ante id mi sodales faucibus. Integer ut convallis enim. Quisque in facilisis mauris, eget viverra tellus. Curabitur sit amet tristique magna, eget finibus justo. Donec egestas at erat eu imperdiet. Nunc lacus erat, sodales vitae lacinia non, pellentesque quis augue.
              </p><p>
                Pellentesque mattis euismod velit sed mattis. Aliquam pharetra justo nec lectus venenatis ullamcorper. Sed non tempus augue. Integer at dui quis tellus cursus ultrices eget ac leo. Nulla venenatis neque sed risus consectetur consectetur. Nam commodo congue nunc, quis tristique turpis finibus eu. Praesent convallis arcu est, in pretium ligula vehicula sit amet. Ut tempus erat in urna congue convallis. Nullam dui risus, laoreet at odio ac, posuere placerat purus. In mattis turpis venenatis, consectetur augue sed, rutrum nibh. Mauris dictum sapien eu purus ullamcorper, sit amet efficitur nulla suscipit. Morbi faucibus elit eget iaculis iaculis. Donec lorem urna, dignissim quis ligula id, congue pretium tortor. Donec porta dui in orci ullamcorper consectetur. Nulla ante felis, placerat at ultrices a, accumsan ut purus. Donec quam dolor, blandit aliquam dictum a, auctor faucibus ante.
              </p><p>
                Proin porttitor felis et magna sodales, ut vulputate elit malesuada. Duis ullamcorper dignissim tellus, sed gravida nunc pretium eu. Donec pharetra at orci id bibendum. In quis elit mattis, pulvinar ipsum dictum, condimentum lorem. Donec ac gravida quam, non dignissim sem. Nullam congue dapibus faucibus. Mauris ullamcorper interdum ligula, ut euismod lectus consequat eget. Suspendisse luctus, urna at laoreet tristique, leo quam finibus tellus, non auctor magna nisl vel ligula. Fusce vitae commodo eros. Phasellus placerat metus laoreet, aliquet dui vel, tincidunt mauris.
              </p><p>
                In hac habitasse platea dictumst. Nunc ultrices elementum ultricies. Curabitur dictum dolor eros, ut mollis sapien dapibus at. Nulla vel pulvinar quam, a consequat velit. Vivamus mattis ligula nisl, sit amet posuere ligula mollis sit amet. Donec nec blandit ipsum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam sit amet nunc pretium, condimentum elit non, tempus nibh. Vestibulum vel ullamcorper leo, et imperdiet nulla. Ut ac interdum tortor. Sed volutpat erat sed tortor efficitur vehicula. Proin sollicitudin feugiat vehicula. Nam luctus placerat quam in efficitur. Phasellus fringilla mauris et purus malesuada pellentesque. Fusce iaculis ante eget erat consequat, posuere eleifend arcu porttitor. Curabitur sed scelerisque dui, sed consequat erat.
              </p><p>
                Integer tellus erat, ultricies sed condimentum non, semper tempor est. Aenean in porttitor elit, et malesuada dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris euismod sed orci vulputate rutrum. Nam luctus purus et dignissim ultrices. Curabitur condimentum porta commodo. Sed nec quam pellentesque, consectetur elit nec, cursus eros. Nulla eget leo ac quam facilisis tincidunt. Vivamus hendrerit ante in rhoncus vulputate. Nulla facilisi. Maecenas imperdiet justo eu velit iaculis efficitur. Aliquam iaculis nulla non erat semper, at ultrices nulla convallis. Quisque non dolor at lectus porta sodales. Aliquam sodales mauris augue. Cras euismod, ligula sed sodales sodales, elit nisi ullamcorper ex, in convallis diam mi vitae lacus. Nulla tincidunt aliquet nisl, id semper felis volutpat a.
              </p><p>
                Maecenas at sem nec nulla porttitor consequat. Donec pharetra fermentum erat, eget egestas tortor condimentum quis. Cras a convallis elit. Duis id pretium leo, id iaculis neque. Praesent rhoncus eu arcu sit amet eleifend. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum quis quam lobortis, suscipit orci at, ultrices metus. Donec tincidunt tincidunt consectetur. Duis porta, orci sed pellentesque euismod, nisl neque dapibus nisl, et sollicitudin dolor libero vestibulum urna.
              </p><p>
                Praesent quis nisl sed odio elementum pretium eget at purus. Sed lacinia, purus a tincidunt cursus, justo massa aliquam eros, quis posuere nunc quam quis urna. Donec pulvinar commodo nunc in facilisis. Praesent est nunc, fringilla in eros in, ullamcorper tincidunt dui. Praesent id erat eget quam lacinia accumsan. Etiam volutpat gravida ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p><p>
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi ac varius nisi, ut porta arcu. Donec sagittis felis risus, a malesuada urna consequat id. Nulla a mi turpis. In ac diam non neque sagittis convallis. Aliquam semper turpis ac eleifend vehicula. Sed erat nulla, pulvinar a interdum ac, consectetur ut neque. Aliquam commodo risus sem, at pulvinar dolor finibus non. In rhoncus volutpat risus, sit amet sagittis erat gravida in. Morbi sapien leo, euismod nec rutrum nec, vehicula id erat. Morbi eu enim in nunc vulputate tincidunt non nec dui. Sed est lectus, cursus quis malesuada vel, placerat in nisi.
              </p><p>
                Aenean convallis nulla orci, a ultrices est ornare non. Ut elementum erat diam, a accumsan nisi mattis eu. Etiam ornare, ligula a viverra laoreet, diam nunc cursus augue, sed lobortis ante ligula at turpis. Mauris faucibus feugiat egestas. Sed a ante ut neque fringilla varius. Suspendisse leo mauris, eleifend quis erat vitae, vehicula interdum tortor. In dapibus et libero in dictum. In ipsum metus, ullamcorper non arcu ac, interdum gravida velit. Pellentesque orci odio, imperdiet vel vulputate sed, maximus tincidunt nulla.
              </p><p>
                Vivamus iaculis pretium placerat. Etiam ac felis vel orci dictum tincidunt blandit et libero. Suspendisse rhoncus, dui nec hendrerit molestie, turpis neque aliquam elit, in pulvinar nibh metus vitae leo. In fermentum nulla quam, eu tincidunt felis pellentesque eget. Aenean eleifend molestie libero, at fringilla mi bibendum a. Nam gravida ipsum lorem, tristique interdum metus ullamcorper at. Etiam tempor porta blandit. Maecenas et massa feugiat, scelerisque erat nec, sagittis ex. Ut et diam felis.
              </p><p>
                Integer euismod mi sit amet dapibus dapibus. Curabitur nec dui venenatis, interdum tellus non, scelerisque elit. Pellentesque ante magna, interdum sit amet arcu hendrerit, molestie vestibulum orci. Proin sed massa non libero mollis vestibulum. Sed viverra pellentesque diam, non varius lorem mollis non. Praesent elementum, risus non ornare cursus, urna tortor viverra risus, et condimentum arcu felis eu quam. Pellentesque in mauris viverra, sodales mauris at, iaculis quam. Morbi faucibus sit amet nisl ac luctus. Duis posuere diam id varius dictum.
              </p><p>
                Pellentesque orci risus, elementum a molestie non, luctus non nunc. Vestibulum pharetra suscipit metus, eget faucibus magna pellentesque dignissim. Cras auctor vitae sapien in vehicula. Vivamus ac auctor justo. Fusce sed rutrum tortor. In pellentesque tincidunt facilisis. Quisque non mauris ac mi elementum pretium. Ut pulvinar enim nec vulputate pulvinar. Proin elementum lacus diam, vitae rhoncus purus vehicula non. Aliquam nec quam justo. Quisque sollicitudin ex consequat porta venenatis. Proin interdum nisl vel laoreet varius. Morbi tempor non velit et tincidunt.
              </p><p>
                Nulla quis venenatis nunc. Cras convallis est ut sem consequat suscipit. Pellentesque dignissim consequat velit, in sollicitudin lectus sollicitudin id. Aenean nec ex dapibus, dignissim ligula ac, maximus lectus. Donec congue auctor massa fermentum tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vestibulum rutrum euismod porta. Integer faucibus, nulla et lacinia pharetra, orci purus lacinia dolor, in volutpat est lacus sit amet purus. Donec sodales dolor eget varius egestas.
              </p><p>
                Quisque metus nulla, bibendum tincidunt purus vel, aliquet dignissim mi. Etiam nunc enim, tristique eget nulla pharetra, scelerisque facilisis ligula. Ut pharetra placerat nunc vel lobortis. Praesent mattis, eros sed vehicula imperdiet, leo neque aliquam elit, vitae gravida ex turpis nec felis. Suspendisse sagittis, neque vitae bibendum consequat, urna nisi euismod justo, nec tincidunt diam nulla eget ex. Proin vulputate malesuada purus, id ultricies nisi venenatis ut. Phasellus pretium, sem et porttitor egestas, sapien lorem bibendum urna, eu bibendum augue nisl at erat. Duis vitae ex scelerisque enim pharetra malesuada. Nunc nec blandit justo. Vivamus sit amet turpis eu tortor pretium iaculis. Sed quis semper lectus. Curabitur eget augue iaculis, auctor lorem nec, volutpat nibh.
              </p>


            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-100 text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.cancelar();
                  }}
                >
                  Fechar
                </button>
              </div>

            </React.Fragment>
          </ModalFooter>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
