//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const etapaAtendimentoService = {

  findProximasEtapas
};
function findProximasEtapas(idEstabelecimento, status, especialidade

) {
  let idEspecialidade = 0;
  if(especialidade && especialidade.id){
    idEspecialidade=especialidade.id
  }
  return defaultService.doGet(`${urlsConstants.ETAPA_ATENDIMENTO}/proximaEtapa/${idEstabelecimento}/${status}/${idEspecialidade}`)
}


