import React, { PureComponent } from 'react';
import toastIconPendencia from './img/icon_pendente_WHITE.svg';

export default class IconPendenciaToast extends PureComponent {
  render() {
    return (
      <div className="contentAvatarToastr">
        <img src={toastIconPendencia} alt="avatar" className="icon_pendencia" />
      </div>
    );
  }
}
