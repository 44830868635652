//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { alertActions } from '../../_actions/alert.action';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { translate } from '../../_helpers/messages.helper';
import { exameService } from '../../_services/exame.service';
import ConveniosLimite from '../ConveniosLimite/ConveniosLimite';
import { FormGroup } from '../FormGroup';
import { HorarioFuncionamento } from '../HorarioFuncionamento/HorarioFuncionamento';
import FormTextCKEditor from '../inputs/FormTextCKEditor';
import InputViewEdit from '../inputs/InputViewEdit';
import SelectInput from '../inputs/SelectInput';

type Props = {
  entity: any,
};
const HorarioFuncionamentoExames = Yup.object().shape({
  intervaloMarcacao: Yup.string().required('Campo obrigatório'),
  horariosAtendimento: Yup.array()
    .min(1, 'É necessario informar pelo menos um horário de atendimento')
    .of(
      Yup.object().shape({
        periodicidade: Yup.string().required('Periodicidade é obrigatório'),
        inicio: Yup.string().required('Obrigatório'),
        fim: Yup.string().required('Obrigatório'),
        dataInicial: Yup.date().required('Obrigatório'),
        dias: Yup.array().min(
          1,
          'É necessario informar pelo menos um dia de atendimento'
        ),
        // listaIntervalo: Yup.array().of(
        //   Yup.object().shape({
        //     horaInicio: Yup.string().required('necessároip'),
        //   })
        // ),
        dataFinal: Yup.date()
          .required('Obrigatório')
          .min(
            Yup.ref('dataInicial'),
            'Data Final deve ser maior que data inicial.'
          ),
      })
    ),
});

class HorarioAtendimentoExames extends React.Component<Props, State> {
  static defaultProps = {
    periodicidade: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      horariosFuncionamento: [],
      daysOfWeek: [
        { name: 'SEG', description: 'Seg', codigo: 1 },
        { name: 'TER', description: 'Ter', codigo: 2 },
        { name: 'QUA', description: 'Qua', codigo: 3 },
        { name: 'QUI', description: 'Qui', codigo: 4 },
        { name: 'SEX', description: 'Sex', codigo: 5 },
        { name: 'SAB', description: 'Sáb', codigo: 6 },
        { name: 'DOM', description: 'Dom', codigo: 0 },
      ],
    };
  }

  joinDays = (dias) => {
    let _this = this;
    var out = [];
    dias = _.orderBy(dias, ['codigo'], ['asc']);
    for (var i = 0; i < dias.length; i++) {
      out.push(_this.getNomeDayOfWeek(dias[i]));
    }
    return out.join(', ');
  };

  getNomeDayOfWeek = (value) => {
    let dayOfWeek;
    let { daysOfWeek } = this.state;
    for (let i = 0; i < daysOfWeek.length; i++) {
      if (daysOfWeek[i].name === value.name) {
        dayOfWeek = daysOfWeek[i];
      }
    }
    return dayOfWeek.description;
  };

  validaHorariosEstabelecimento = (values, setFieldError) => {
    let _this = this;
    let promise = new Promise(function (resolve) {
      let horarios = values.horariosAtendimento;
      let response = true;
      let estabelecimentos = _this.state.horariosFuncionamento
        ? _this.state.horariosFuncionamento
        : values.estabelecimento;
      for (let i = 0; i < horarios.length; i++) {
        let dias = horarios[i].dias;
        let listaIntervalo = _.get(horarios[i], 'listaIntervalo');

        if (listaIntervalo) {
          for (let k = 0; k < listaIntervalo.length; k++) {
            if (
              dateHelper.verificaHoraUmMaiorQueDois(
                listaIntervalo[k].horaInicio,
                horarios[i].inicio
              )
            ) {
              response = true;
            } else {
              setFieldError(
                `horariosAtendimento[${i}].listaIntervalo[${k}].horaInicio`,
                'Horário de intervalo inicial anterior ao horário de atendimento'
              );
              response = false;
              break;
            }

            if (
              dateHelper.verificaHoraUmMaiorQueDois(
                horarios[i].fim,
                listaIntervalo[k].horaFim
              )
            ) {
              response = true;
            } else {
              setFieldError(
                `horariosAtendimento[${i}].listaIntervalo[${k}].horaFim`,
                'Horário de intervalo final posterior ao horario de atendimento'
              );
              response = false;
              break;
            }

            if (
              dateHelper.verificaHoraUmMaiorQueDois(
                listaIntervalo[k].horaFim,
                listaIntervalo[k].horaInicio
              )
            ) {
              response = true;
            } else {
              setFieldError(
                `horariosAtendimento[${i}].listaIntervalo[${k}].horaFim`,
                'Horário de intervalo final posterior ao horario inicial'
              );
              response = false;
              break;
            }
          }
        }

        if (dias && dias.length > 0) {
          for (let j = 0; j < dias.length; j++) {
            const codigo = dias[j].codigo;
            for (let h = 0; h < estabelecimentos.length; h++) {
              let diasEstabelecimento = estabelecimentos[h].dias;
              for (let index = 0; index < diasEstabelecimento.length; index++) {
                if (diasEstabelecimento[index].codigo === codigo) {
                  if (
                    dateHelper.verificaHoraUmMaiorQueDois(
                      horarios[i].inicio,
                      estabelecimentos[h].inicio
                    )
                  ) {
                    response = true;
                  } else {
                    setFieldError(
                      `horariosAtendimento[${i}].inicio`,
                      'Horário inicial anterior ao funcionamento do estabelecimento'
                    );
                    response = false;
                    break;
                  }
                  if (
                    dateHelper.verificaHoraUmMaiorQueDois(
                      estabelecimentos[h].fim,
                      horarios[i].fim
                    )
                  ) {
                    response = true;
                  } else {
                    setFieldError(
                      `horariosAtendimento[${i}].fim`,
                      'Horário final posterior ao funcionamento do estabelecimento'
                    );
                    response = false;
                    break;
                  }
                }
              }
            }
          }
        } else {
          alertActions.error('Selecione ao menos um dia para atendimento');
          response = false;
          break;
        }
      }
      resolve(response);
    });

    return promise;
  };

  addItemInArray = (
    propName,
    defaultValue,
    values,
    setValues,
    createAttribute?: boolean
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName]) {
      newValues[propName].push(
        defaultValue ? defaultValue : { idList: new Date().getTime() }
      );
      setValues(newValues);
    }
    if (!newValues[propName] && createAttribute) {
      _.set(newValues, `${propName}`, [{ idList: new Date().getTime() }]);
      setValues(newValues);
    }
  };

  removeItemInArray = (
    propName,
    indexItem,
    values,
    setValues,
    removerTodos: boolean = false
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1 || removerTodos) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };

  saveForm = () => {
    let _this = this;
    const { fullEntity } = this.props;
    let values = _this.formRef.state.values;
    let setFieldError = _this.formRef.setFieldError;

    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this
            .validaHorariosEstabelecimento(values, setFieldError)
            .then((response) => {
              if (response) {
                _this.props.loading(true);
                exameService
                  .saveLocalHorarioAtendimento(fullEntity, values)
                  .then(
                    (response) => {
                      _this.props.success({
                        message: `Horário, foi ${
                          !values.id ? 'criado' : 'alterado'
                        } com sucesso!`,
                      });
                      _this.props.loading(false);
                      resolve(true);
                    },
                    (erros) => {
                      _this.props.loading(false);
                      _this.props.error({
                        message:
                          'Não foi possível salvar horário de funcionamento.',
                      });
                      try {
                        let response = erros.response.data;
                        if (response && response.messages) {
                          for (var i = 0; i < response.messages.length; i++) {
                            let erroItem = response.messages[i];
                            _this.formRef.setFieldError(
                              erroItem.fieldName,
                              translate(erroItem.message.code)
                            );
                          }
                        }
                      } catch (error) {
                        console.log(error);
                      }
                      console.log(error);
                    }
                  );
              } else {
                resolve(false);
              }
            });
        } else {
          _this.props.error({
            message: 'O formulário contém erro(s)!',
          });
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  countConvenios = (convenios?: any) => {
    if (!convenios) {
      return 'Convênios';
    }
    if (convenios.length === 1) {
      return convenios.length + ' convênio';
    }
    if (convenios.length > 1) {
      return convenios.length + ' convênios';
    }
  };

  render() {
    const { entity, viewMode, periodicidade } = this.props;
    const { horariosFuncionamento } = this.state;
    return (
      <React.Fragment>
        <Formik
          validationSchema={HorarioFuncionamentoExames}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldError,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-5 col-lg-3">
                    <InputViewEdit
                      component={SelectInput}
                      type="text"
                      name="intervaloMarcacao"
                      label="Tempo de atendimento"
                      placeholder="30m, 1h..."
                      options={objectsConstants.TEMPO_ATENDIMENTO}
                      onChange={(name, date) => {
                        setFieldValue(name, date);
                      }}
                      labelKey={'description'}
                      valueKey={'value'}
                      noSize={true}
                      viewMode={viewMode}
                      id="intervaloMarcacao"
                      value={values.intervaloMarcacao}
                      erroMensagem={errors.intervaloMarcacao}
                      required={true}
                      returnFullObject={false}
                      searchable={true}
                      apendRoot={true}
                      defaultValue={formatterHelper.getLabelEnum(
                        values.intervaloMarcacao,
                        objectsConstants.TEMPO_ATENDIMENTO,
                        'value',
                        'description'
                      )}
                    />
                  </FormGroup>
                  <div className="col-12 col-lg-4">
                    {horariosFuncionamento && !viewMode && (
                      <label>Horário de funcionamento do estabelecimento</label>
                    )}
                    {horariosFuncionamento &&
                      !viewMode &&
                      horariosFuncionamento.map((horario, index) => {
                        return (
                          <React.Fragment key={index}>
                            <p>
                              {horario.dias && this.joinDays(horario.dias)}
                              {' -  '}
                              {'  '}
                              <b>
                                {_.get(horario, `inicio`, '--')} {' ás  '}
                                {_.get(horario, `fim`, '--')}
                              </b>
                            </p>
                          </React.Fragment>
                        );
                      })}{' '}
                  </div>
                </div>
              </div>
              <div className="agenda">
                <h2 className="w-100">Dias e horários de atendimento</h2>

                {values.horariosAtendimento &&
                  values.horariosAtendimento.map((horario, index) => {
                    if (
                      !dateHelper.isBeforeToday(_.get(horario, 'dataFinal', ''))
                    ) {
                      return (
                        <HorarioFuncionamento
                          key={
                            horario.id
                              ? horario.id +
                                index +
                                horario.inicio +
                                horario.fim
                              : horario.idList + index
                          }
                          entity={horario}
                          index={index}
                          viewMode={viewMode}
                          size={values.horariosAtendimento.length}
                          propName={`horariosAtendimento[${index}]`}
                          onChange={(name, data) => {
                            setFieldValue(name, data);
                          }}
                          errors={errors}
                          periodicidade={periodicidade}
                          removeItem={() => {
                            this.removeItemInArray(
                              `horariosAtendimento`,
                              index,
                              values,
                              setValues
                            );
                          }}
                        ></HorarioFuncionamento>
                      );
                    }
                  })}
                <div className="form-inside">
                  <div
                    className={` w-100 justify-content-lg-start justify-content-center pl-10px ${
                      viewMode ? 'd-none' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.addItemInArray(
                          'horariosAtendimento',
                          null,
                          values,
                          setValues,
                          true
                        )
                      }
                    >
                      Adicionar outro horário
                    </button>
                  </div>
                </div>
              </div>
              <div className="convenios">
                <h2 className="w-100">
                  {this.countConvenios(
                    _.get(values, 'listEstabelecimentoMedicoConvenio')
                  )}{' '}
                </h2>

                {!values.estabelecimento && (
                  <p className="pt-4 text-center">
                    É necessário selecionar um local de atendimento para
                    adicionar convênios.
                  </p>
                )}
                <div className="form-inside">
                  {values.listEstabelecimentoExameConvenio &&
                    values.listEstabelecimentoExameConvenio.map(
                      (convenio, index) => {
                        return (
                          <React.Fragment
                            key={convenio && convenio.id ? convenio.id : index}
                          >
                            <ConveniosLimite
                              exibeCheckIntegracao={false}
                              size={
                                values.listEstabelecimentoExameConvenio.length
                              }
                              entity={values}
                              index={index}
                              viewMode={viewMode}
                              propName={`listEstabelecimentoExameConvenio[${index}]`}
                              onChange={(name, data) => {
                                console.log(name, data);
                                setFieldValue(name, data);
                              }}
                              removeItem={() => {
                                this.removeItemInArray(
                                  `listEstabelecimentoExameConvenio`,
                                  index,
                                  values,
                                  setValues,
                                  true
                                );
                              }}
                              errors={errors}
                            ></ConveniosLimite>
                          </React.Fragment>
                        );
                      }
                    )}
                  <div
                    className={`w-100 justify-content-lg-start justify-content-center pl-10px  ${
                      viewMode ? 'd-none' : ' d-flex '
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.addItemInArray(
                          'listEstabelecimentoExameConvenio',
                          null,
                          values,
                          setValues,
                          true
                        )
                      }
                    >
                      {values.listEstabelecimentoMedicoConvenio &&
                      values.listEstabelecimentoMedicoConvenio.length >= 1
                        ? 'Adicionar outro convênio'
                        : 'Adicionar convênio'}
                    </button>
                  </div>
                </div>
              </div>

              <div className="info">
                <h2 className="w-100">
                  Informações e recomendações de atendimento
                </h2>
                <div className="form-inside">
                  <div className="row section-form">
                    <FormGroup className={'col-12'}>
                      {!viewMode && (
                        <FormTextCKEditor
                          id={'recomendacoes'}
                          name={'recomendacoes'}
                          label={'Recomendações'}
                          className={'textCKeditor'}
                          type={'textarea'}
                          disabled={false}
                          required={true}
                          onChange={setFieldValue}
                          erroMensagem={errors.recomendacoes}
                          viewMode={viewMode}
                          value={values.recomendacoes}
                          defaultValue={values.recomendacoes}
                          removePlugins={[
                            'Heading',
                            'BlockQuote',
                            'ImageUpload',
                            'MediaEmbed',
                          ]}
                          placeholder={
                            'Insira aqui as recomendações para serem enviadas para o paciente ao agendar'
                          }
                        />
                      )}
                      {viewMode && <label>Recomendações</label>}
                      {viewMode && (
                        <p className="form-view">
                          {ReactHtmlParser(values.recomendacoes)}
                        </p>
                      )}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(HorarioAtendimentoExames);
