import { Can } from '@casl/react';
import React, { Component } from 'react';
import { connect }  from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../_constants/objects.constants';
import iconPerfil from '../../images/icon-perfil.svg';
import Avatar from '../Avatar/Avatar';
import _ from 'lodash';
import { userService } from '../../_services/user.service';

class MenuList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  abrePerfil() {
    let user = this.props.user;
    this.goToPage(`/perfil/${user.id}`);
  }

  goToPage(page) {
    // console.log(page);
    this.props.fechaMenu();
    this.props.history.push(page);
  }

  getInfoSecundaria = (usuario = this.props.user) => {
    if (usuario.crm) {
      return 'CRM - ' + usuario.crm;
      // .map((especialidade) => especialidade.nome)
      // .join(', ');
    } else if (usuario.email) {
      return usuario.email;
    }
  };

  render() {
    const { children, permissions, usuario } = this.props;

    return (
      <React.Fragment>
        <div className="d-flex flex-row">
          <div className="flex-fill d-flex align-items-center"></div>
          <div className="close-menu">
            <button
              className="btn-close"
              type="button"
              onClick={this.props.fechaMenu}
            ></button>
          </div>
        </div>

        <div
          className="meu-perfil"
          onClick={() => {
            this.abrePerfil();
          }}
        >
          <div className="d-flex align-items-end justify-content-center meus-dados mb-2">
            <img src={iconPerfil} alt="Acessar meu perfil" />
            <p>Meus dados</p>
          </div>
          <Avatar user={this.props.user} editable={false}></Avatar>
          <div className="nome-user text-center mt-2">
            {_.get(this.props.user, 'nome')}
          </div>
          <div
            className="tipo-user text-center mt-1 mb-2"
            title={this.getInfoSecundaria(usuario)}
          >
            {this.getInfoSecundaria(usuario)}
          </div>
        </div>

        <div className="menu">
          <ul>
            {this.props.arrayMenu.map((m, index) => (
              <Can
                I={objectsConstants.VIEW}
                a={m.permission}
                ability={permissions}
                key={index + m.name}
              >
                <hr></hr>
                <li
                  key={index + m.name}
                  onClick={() => {
                    if (m.onClick) {
                      m.onClick();
                    } else {
                      this.goToPage(`${m.to}`);
                    }
                  }}
                >
                  <img src={m.icon}></img>
                  <span href={m.to}>{m.name}</span>
                </li>
              </Can>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { error },
  load: { loading },
  sidemenu: { toggleMenu, componenteMenu, abreMenu, fechaMenu },
}) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu(),
  fechaMenu: () => fechaMenu(),
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  return {
    user,
    permissions,
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(MenuList));
