//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../_constants/objects.constants';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { Can } from '@casl/react';
import { withRouter } from 'react-router-dom';
import Button from 'react-scroll/modules/components/Button';

class IntegrationActionButtons extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.goToLog = this.goToLog.bind(this);
    this.goToAtendimentos = this.goToAtendimentos.bind(this);
  }

  goToLog(extra, row, column) {
    console.log('Ver Log');
    console.log(extra.id);
    this.props.history.push('/integracao/sasc/log/' + extra.id);
  }

  goToAtendimentos(extra, row, column) {
    console.log('Ver Atendimentos');
    console.log(extra.id);
    this.props.history.push('/integracao/sasc/atendimentos/' + extra.id);
  }

  render() {
    const { extra, row, column } = this.props;
    return (
      <React.Fragment>
        <button
          onClick={() => {
            this.goToLog(extra, row, column);
          }}
          type="button"
          className="btn btn-integration btn-orange"
        >
          Ver log
        </button>

        <button
          onClick={() => {
            this.goToAtendimentos(extra, row, column);
          }}
          type="button"
          className="btn btn-integration btn-blue "
        >
          Ver Itens
        </button>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(IntegrationActionButtons));
