//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";
import _ from 'lodash';
export const especialidadesService = {
  findAll,
  findAllPublic
};

function findAllPublic(idEstabelecimento,values) {
  let body;
  if(values){
    body = _.cloneDeep(values);
  }else{
    body = {};
  }



  if(idEstabelecimento){
    return defaultService.doPost(`${urlsConstants.ESPECIALIDADES_PUBLIC}findByEstabelecimento/${idEstabelecimento}`,body);
  }
  return defaultService.doPost(`${urlsConstants.ESPECIALIDADES_PUBLIC}findAll`,body);
}

function findAll(idEstabelecimento) {
  if(idEstabelecimento){
    return defaultService.doGet(`${urlsConstants.ESPECIALIDADES}findByEstabelecimento/${idEstabelecimento}`);
  }
  return defaultService.doGet(`${urlsConstants.ESPECIALIDADES}findAll`);
}
