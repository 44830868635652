//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import LocaleHorarioAtendimento from '../../components/LocaleHorarioAtendimento/LocaleHorarioAtendimento';
import CheeseburgerMenu from '../../components/menu/CheeseburgerMenu';
import { ModalDesvincularProfissional } from '../../components/Modal/ModalDesvincularProfissional';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { medicoService } from '../../_services/medico.service';
import { userService } from '../../_services/user.service';
import Historico from '../Historico/Historico';
import LocalAtendimento from './LocalAtendimento';
import MedicoForm from './MedicoForm';
import { Modal, ModalBody } from 'reactstrap';
import { EsqueciSenhaForm } from '../login/EsqueciSenhaForm';
import { NovaSenhaForm } from '../login/NovaSenhaForm';

const atenderSomente = [
  { name: 'PARTICULAR', description: 'Particular', codigo: 1 },
  { name: 'CONVENIO', description: 'Convênio', codigo: 2 },
  { name: 'CONSULTAS', description: 'Consultas', codigo: 3 },
  { name: 'RETORNO', description: 'Retorno', codigo: 4 },
  { name: 'PRIMEIRA_CONSULTA', description: 'Primeira consulta', codigo: 5 },
  { name: 'EXAMES', description: 'Exames', codigo: 6 },
];

// const defaultArrayItem = [
//   { idList: new Date().getTime(), horariosAtendimento: [{atender:ate}] },
// ];

class MedicoView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openModalSenha: false,
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadEntity();
  }

  componentWillUnmount() {
    this.props.fechaHistorico();
  }

  addlocal = () => {
    let newMedico = _.cloneDeep(this.state.entity);
    newMedico.estabelecimentoMedicos.unshift({
      idList: new Date().getTime(),
      medico: { id: this.props.match.params.id },
      horariosAtendimento: [
        { listHorarioAtendimentoMedicoAtender: atenderSomente },
      ],
    });

    this.setState({ entity: newMedico });
  };

  direcionaEstabelecimento = (idEstabelcimento) => {
    this.props.history.push('/estabelecimento/view/' + idEstabelcimento + '');
  };

  deletaLocalAtendimento = (index) => {
    this.props.loading(true);
    let newMedico = _.cloneDeep(this.state.entity);
    let idMedicoEstabelecimento = newMedico.estabelecimentoMedicos[index].id;
    if (idMedicoEstabelecimento) {
      medicoService.deleteLocalHorarioAtendimento(idMedicoEstabelecimento).then(
        (response) => {
          if (newMedico.estabelecimentoMedicos.length === 1) {
            newMedico.estabelecimentoMedicos.splice(index, 1);
            newMedico.estabelecimentoMedicos.push({
              idList: new Date().getTime(),
              medico: { id: this.props.match.params.id },
              horariosAtendimento: [{}],
            });
          } else {
            newMedico.estabelecimentoMedicos.splice(index, 1);
          }
          this.setState({ entity: newMedico });
          this.props.success({
            message: 'Horário excluído com sucesso.',
          });
          this.props.loading(false);
        },

        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    } else {
      newMedico.estabelecimentoMedicos.splice(index, 1);
      this.props.loading(false);
      this.setState({ entity: newMedico });
    }
  };

  loadEstabelecimentoHorarios = (medico) => {
    medicoService.findLocalHorarioAtendimento(medico).then(
      (response) => {
        let estabelecimentoMedicos = response.data;
        let newMedico = _.cloneDeep(medico);
        console.log(estabelecimentoMedicos);
        if (estabelecimentoMedicos && estabelecimentoMedicos.length > 0) {
          console.log(1);
          newMedico.estabelecimentoMedicos = estabelecimentoMedicos;
        } else {
          console.log(2);
          newMedico.estabelecimentoMedicos = [];
        }
        this.setState({ entity: newMedico }, () => {
          this.props.loading(false);
        });
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  loadEntity = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      medicoService.doGet(this.props.match.params.id).then(
        (response) => {
          let medico = response.data;
          console.log(medico);
          this.setState({ entity: medico }, () => {
            this.loadEstabelecimentoHorarios(this.state.entity);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleDeleteItem = (index, entity) => {
    this.props.loading(true);
    medicoService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Profissional de saúde foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push('/medico');
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir profissional de saúde.',
        });
      }
    );
  };

  getEditDeleteBtn = (setEditMode) => {
    let canDelete = userService.isSecretaria() || userService.isAdministrador();

    // canDelete =
    //   canDelete &&
    //   _.get(this.state.entity, 'estabelecimentoMedicos.length', 0) > 0;

    return (
      <React.Fragment>
        {canDelete && (
          <button
            type="button"
            className={`btn btn-link`}
            onClick={() => {
              this._refConfirmDisabled.toogleModal();
            }}
          >
            <span className="icon-btn-deletar"></span>
            Excluir
          </button>
        )}

        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            if (userService.isCurrent(this.state.entity)) {
              setEditMode();
            } else {
              this.props.info({
                message:
                  'Somente o profissional de saúde pode editar os dados dele',
              });
            }
          }}
        >
          <span className="icon-btn-editar"></span>
          Editar
        </button>
        {(userService.isAdministrador() || userService.isSuporte()) && (
          <button
            type="button"
            className={`btn btn-link `}
            onClick={() => {
              this.props.receiveIdEntityHistorico(
                _.get(this.state.entity, 'id'),
                objectsConstants.TIPO_HISTORICO_MEDICO
              );
              this.props.abreHistorico();
            }}
          >
            <span className="icon-historico"></span>
            Histórico de alterações
          </button>
        )}
      </React.Fragment>
    );
  };

  desvincularProfissional = () => {
    this.handleDeleteItem(null, this.state.entity);
  };
  toggleModalSenha = () => {
    this.setState({ openModalSenha: !this.state.openModalSenha });
  };

  getExtraButtons = () => {
    return (
      <button
        type="button"
        className="btn btn-link"
        onClick={this.toggleModalSenha}
      >
        <span className="icon-btn-editar"></span>
        Alterar Senha
      </button>
    );
  };
  salvarNovaSenha = (senha) => {
    this.props.loading(true);
    let values = {
      confirmPassword: senha,
      key: this.state.entity.id,
      password: senha,
    };

    console.log(values);
    userService.updatePassword(values).then(
      (response) => {
        if (response.status == 200) {
          this.props.loading(false);
          this.props.success('Senha alterada com sucesso');
          this.toggleModalSenha();
        } else {
          console.log(response);
          this.props.loading(false);
          this.props.error('Não foi possivel alterar a senha');
        }
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error('Não foi possivel alterar a senha');
      }
    );
  };

  render() {
    // let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <ModalDesvincularProfissional
          ref={(r) => (this._refConfirmDisabled = r)}
          callbackOnSave={this.desvincularProfissional}
        />
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        {entity && entity.id && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={entity.apelido}
              canBack={true}
              backUrl={
                this.props.clickBackUrl
                  ? '/agenda'
                  : _.get(this.props.location, 'state.clickBackUrl')
                  ? '/agenda'
                  : '/medico'
              }
            />
            <TopoListagem
              showIndisponibilidade={false}
              showfilter={false}
            ></TopoListagem>
            <Modal
              isOpen={this.state.openModalSenha}
              toggle={this.toggleModalSenha}
              backdrop="static"
              modalClassName=""
              className="modal-anexos modal-xl"
              centered={true}
              target={this.props.target}
            >
              <div className="close-menu">
                <div className="action" onClick={this.toggleModalSenha}>
                  Fechar
                </div>
              </div>
              <ModalBody>
                <div className="">
                  <div className="row">
                    <div className="col-12 text-center iconLocal">
                      Informe a nova senha do profissional de Saúde.
                    </div>
                    <div className="col-12 text-center iconLocal">
                      <NovaSenhaForm
                        salvarNovaSenha={this.salvarNovaSenha}
                      ></NovaSenhaForm>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <CheeseburgerMenu
              isOpen={this.props.historicoAberto}
              closeCallback={this.props.fechaHistorico}
              right={true}
              width={400}
              className="historico-bg"
            >
              {this.props.historicoAberto && <Historico />}
            </CheeseburgerMenu>
            <FullCustomCard
              permissionSave={objectsConstants.MENU_MEDICO}
              title={'Profissional de saúde'}
              Componente={MedicoForm}
              viewMode={true}
              entity={entity}
              cancelForm={this.loadEntity}
              onSave={this.loadEntity}
              btnExcluir={
                userService.isSecretaria() || userService.isAdministrador()
                  ? true
                  : false
              }
              getEditDeleteBtn={this.getEditDeleteBtn}
              extraButton={this.getExtraButtons}
              removeItem={this.handleDeleteItem}
              {...otherPops}
            ></FullCustomCard>
            {/* </div>*/}
            <div className="d-flex align-items-center justify-content-center">
              <LocalAtendimento
                entity={entity}
                addItem={this.addlocal}
              ></LocalAtendimento>
            </div>
            {entity &&
              entity.estabelecimentoMedicos &&
              entity.estabelecimentoMedicos.map((estabelecimento, index) => {
                return (
                  <React.Fragment
                    key={
                      estabelecimento.id
                        ? estabelecimento.id + index
                        : estabelecimento.idList + index
                    }
                  >
                    <FullCustomCard
                      permissionSave={objectsConstants.MENU_MEDICO}
                      title={
                        estabelecimento.estabelecimento
                          ? _.get(estabelecimento, 'estabelecimento.nome')
                          : 'Local e horários de atendimento'
                      }
                      Componente={LocaleHorarioAtendimento}
                      direcionaEstabelecimento={this.direcionaEstabelecimento}
                      viewMode={estabelecimento.id ? true : false}
                      entity={estabelecimento}
                      fullEntity={entity}
                      className="mt-0"
                      btnExcluir={true}
                      removeItem={(index) => {
                        this.deletaLocalAtendimento(index);
                      }}
                      index={index}
                      cancelForm={this.loadEntity}
                      onSave={this.loadEntity}
                      periodicidade={true}
                      exibeHistorico={true}
                      abreHistoricoCard={() => {
                        console.log(estabelecimento);
                        this.props.receiveIdEntityHistorico(
                          _.get(estabelecimento, 'id'),
                          objectsConstants.TIPO_HISTORICO_ESTABELECIMENTO_MEDICO
                        );
                        this.props.abreHistorico();
                      }}
                      {...otherPops}
                    ></FullCustomCard>
                  </React.Fragment>
                );
              })}
            {/* </div> */}
          </React.Fragment>
        )}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { historicoAberto } = state.historicomenu;
  return {
    historicoAberto,
  };
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
  historicomenu: {
    toggleHistorico,
    abreHistorico,
    fechaHistorico,
    receiveIdEntityHistorico,
  },
}) => ({
  success: (message) => success({ message }),
  info: (msg) => info(msg),
  loading: (load: boolean) => loading({ load }),
  error: (message) => error({ message }),
  clear: () => clear(),
  toggleHistorico: () => toggleHistorico(),
  abreHistorico: () => abreHistorico({}),
  fechaHistorico: () => fechaHistorico(),
  receiveIdEntityHistorico: (idEntityHistorico, tipoHistorico) =>
    receiveIdEntityHistorico({ idEntityHistorico, tipoHistorico }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(MedicoView)
);
