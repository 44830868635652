//@flow
import moment from 'moment';
import { func } from 'prop-types';
import 'moment/locale/pt-br';

type Options = {
  pattern?: string,
  mode?: string,
};

const DATE_TIME = 'DD/MM/YYYY HH:mm:ss';
const DATE_TIME_API = 'yyyy-MM-dd-HH-mm-ss';
const DATE = 'DD/MM/YYYY';
const DATE_API = 'YYYY-MM-DD';
const TIME = 'HH:mm:ss';

export const dateHelper = {
  format,
  getDiasDaSemana,
  isToday,
  verificaHoraUmMaiorQueDois,
  isBeforeNow,
  getLeftTime,
  now,
  isBeforeToday,
  getDiaSemana,
  getHourMinuteNow,
  getDateFormat,
  getFutureYears,
  formatDate,
  getHourFormat,
  adicionaMes,
  adicionaMeses,
  isBeforeDays,
  calcularRepeticoes,
};

function now() {
  return moment();
}

function getHourMinuteNow() {
  const now = moment();
  return now.format('HH:mm');
}

function getLeftTime(data) {
  let now = moment();
  return now.to(moment(data));
}

function isBeforeNow(date) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  if (currentDate.getTime() > date.getTime()) {
    return true;
  }
  return false;
}
function getHourFormat(value) {
  let date = moment(value);
  return date.format('HH:mm');
}
function formatDate(value) {
  let date = moment(value);
  return date.format(DATE);
}
function getDateFormat(dataString) {
  if (dataString) {
    var dia = dataString.split('/')[0];
    var mes = dataString.split('/')[1];
    var ano = dataString.split('/')[2];
    let dataFormatada =
      ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
    return moment(dataFormatada).toDate();
  }
}

function isBeforeToday(date) {
  if (date) {
    const currentDate = new Date();
    let dateFormat = moment(date).toDate();
    currentDate.setHours(0, 0, 0, 0);
    dateFormat.setHours(0, 0, 0, 0);
    if (currentDate > dateFormat) {
      return true;
    }
  }
  return false;
}

function isBeforeDays(date) {
  if (date) {
    const currentDate = new Date();
    let todayMoreWeek = moment(currentDate).add(7, 'd');
    let newDate = getDateFormat(date);
    let dateFormat = moment(newDate).toDate();
    if (dateFormat <= todayMoreWeek) {
      return true;
    }
  }
  return false;
}

function format(value: string | Date, options?: Options) {
  let format = DATE_TIME;
  moment.locale('pt-br');

  if (options) {
    if (options.pattern) {
      format = options.pattern;
    } else {
      const { mode } = options;
      if ('DATE' === mode) {
        format = DATE;
      } else if ('TIME' === mode) {
        format = TIME;
      } else if ('DATE-API' === mode) {
        format = DATE_API;
      } else if ('DATE-TIME' === mode) {
        format = DATE_TIME;
      } else if ('DATE-TIME-API' === mode) {
        format = DATE_TIME_API;
      }
    }
  }

  let date = moment(value);
  return date.format(format);
}

function isToday(data) {
  const today = new Date();
  return (
    data.getDate() == today.getDate() &&
    data.getMonth() == today.getMonth() &&
    data.getFullYear() == today.getFullYear()
  );
}
function verificaHoraUmMaiorQueDois(horario1: string, horario2: string) {
  let horarios = horario1.split(':');
  let dt = new Date();
  dt.setHours(horarios[0]);
  dt.setMinutes(horarios[1]);

  let horarios2 = horario2.split(':');
  let dt2 = new Date();
  dt2.setHours(horarios2[0]);
  dt2.setMinutes(horarios2[1]);

  if (dt >= dt2) {
    return true;
  } else {
    return false;
  }
}
function horaPosterior(horario1: string, horario2: string) {
  let horarios = horario1.split(':');
  let dt = new Date();
  dt.setHours(horarios[0]);
  dt.setMinutes(horarios[1]);

  let horarios2 = horario2.split(':');
  let dt2 = new Date();
  dt2.setHours(horarios2[0]);
  dt2.setMinutes(horarios2[1]);
}

function adicionaMes(d) {
  var fm = moment(d).add(1, 'M');
  return new Date(fm);
}

function adicionaMeses(data, meses) {
  console.log('add mes:', meses);
  var fm = moment(data).add(meses, 'M');
  return new Date(fm);
}

function getDiasDaSemana() {
  let arrayDays = [];

  arrayDays.push({ id: 1, nome: 'Seg' });
  arrayDays.push({ id: 2, nome: 'Ter' });
  arrayDays.push({ id: 3, nome: 'Qua' });
  arrayDays.push({ id: 4, nome: 'Qui' });
  arrayDays.push({ id: 5, nome: 'Sex' });
  arrayDays.push({ id: 6, nome: 'Sáb' });
  arrayDays.push({ id: 0, nome: 'Dom' });

  return arrayDays;
}

function getDiaSemana(data) {
  var dia = new Array(7);
  dia[0] = 'Domingo';
  dia[1] = 'Segunda-feira';
  dia[2] = 'Terça-feira';
  dia[3] = 'Quarta-feira';
  dia[4] = 'Quinta-feira';
  dia[5] = 'Sexta-feira';
  dia[6] = 'Sábado';

  return dia[data.getDay()];
}

function getFutureYears() {
  let arrayYears = [];
  let currentYear = new Date().getFullYear();

  arrayYears.push({
    name: currentYear.toString(),
    description: currentYear.toString(),
  });

  for (let index = 0; index < 19; index++) {
    const element = currentYear++;
    arrayYears.push({
      name: element.toString(),
      description: element.toString(),
    });
  }
  return arrayYears;
}

function calcularRepeticoes(dataInicial, periodicidade, dataFinal) {
  let inicio = moment(dataInicial, 'YYYY-MM-DD');
  const fim = moment(dataFinal, 'YYYY-MM-DD');

  const repeticoes = [];
  repeticoes.push(inicio.clone());

  if (periodicidade === 7 || periodicidade === 14) {
    while (inicio.isBefore(fim)) {
      inicio = inicio.add(periodicidade, 'days');
      if (inicio.isBefore(fim) || inicio.isSame(fim)) {
        repeticoes.push(inicio.clone());
      }
    }
  } else if (periodicidade === 30) {
    const diaSemanaInicial = inicio.day();

    while (inicio.isBefore(fim)) {
      inicio = inicio.add(1, 'months');

      const diaInicialNoMesSeguinte = moment(inicio);
      const diaSemanaNoMesSeguinte = diaInicialNoMesSeguinte.day();

      let diferencaDias = diaSemanaInicial - diaSemanaNoMesSeguinte;
      if (diferencaDias < -3) {
        diferencaDias += 7;
      } else if (diferencaDias > 3) {
        diferencaDias -= 7;
      }

      const proximoDia = diaInicialNoMesSeguinte.add(diferencaDias, 'days');
      if (proximoDia.isBefore(fim) || proximoDia.isSame(fim)) {
        repeticoes.push(proximoDia.clone());
      }
    }
  }

  return repeticoes;
}
