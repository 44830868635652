//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect }  from 'react-redux';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import FormInput from '../../components/inputs/FormInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { translate } from '../../_helpers/messages.helper';
import { defaultService } from '../../_services/defaultService';
import { empresaService } from '../../_services/empresa.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const EmpresaValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Obrigatório'),
  // dominio: Yup.string().required('Obrigatório'),
});

class EmpresaForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/empresa/view/' + values.id,
      state: { entity: values },
    });
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          empresaService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Empresa, foi ${
                  !values.id ? 'criada' : 'alterada'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar empresa.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(error);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  render() {
    let _this = this;
    const { viewMode } = this.props;

    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          validationSchema={EmpresaValidate}
          initialValues={this.props.entity}
          validateOnBlur={false}
          validateOnChange={false}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <div className="col-12 col-lg-10 form-row pl-lg-3">
                    <FormGroup className="col-12 col-lg-7">
                      <InputViewEdit
                        component={FormInput}
                        label={'Nome'}
                        type="text"
                        id="nome"
                        name="nome"
                        placeholder="Digite o nome da empresa"
                        value={values.nome}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.nome}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-5">
                      <InputViewEdit
                        component={FormInput}
                        label={'dominio'}
                        type="text"
                        id="dominio"
                        name="dominio"
                        placeholder="www.dominio.com.br"
                        value={values.dominio}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.dominio}
                        viewMode={viewMode}
                        required={false}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(EmpresaForm);
