//@flow
import * as _ from "lodash";
import { urlsConstants } from '../_constants/urls.constant';
import { oauthHeader } from '../_helpers/oauth-header';
import { defaultService } from './defaultService';


export const perfilservice = { 
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.PERFIL}findAll`);
}

