//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const paramTriagemService = {
  doGet,
  doSave,
  findByFiltro,
  exportToExcel,
  findAll,
  doDelete,
  findByEstabelecimento
};

function findByEstabelecimento(idEstabelecimento){
  return defaultService.doGet(`${urlsConstants.PARAM_TRIAGEM}findByEstabelecimento/${idEstabelecimento}`);
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.PARAM_TRIAGEM}findAll`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.PARAM_TRIAGEM}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.PARAM_TRIAGEM}${id}`);
}

function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.PARAM_TRIAGEM}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.PARAM_TRIAGEM, entity);
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel(
    'estabelecimento',
    filter,
    fields,
    labels
  );
}
