//@flow
//@flow
import { Formik } from 'formik';
import _, { isNumber, toNumber } from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../../components/FormGroup';
import CheckBoxInputOnly from '../../../components/inputs/CheckBoxInputOnly';
import FormInput from '../../../components/inputs/FormInput';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import IconDelete from '../../../images/icon_x_red.svg';
import { objectsConstants } from '../../../_constants/objects.constants';
import { formatterHelper } from '../../../_helpers/formatter.helper';
import { translate } from '../../../_helpers/messages.helper';
import { categoriaDocumentoService } from '../../../_services/categoriaDocumento.service';
import { defaultService } from '../../../_services/defaultService';
import { paramTriagemService } from '../../../_services/paramTriagem.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const TriagemValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(120, 'Nome muito grande!')
    .required('Campo obrigatório'),
  ordem: Yup.string().required('Campo obrigatório'),
});

class TriagemForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    console.log(toNumber(values.id));
    if (isNumber(values.id)) {
      this.props.history.push({
        pathname: redirectPath + values.id,
        state: {
          entity: values,
        },
      });
    }
  };

  saveForm = () => {
    console.log('saveForm Triagem');
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        console.log(error);
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          paramTriagemService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Modelos, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              console.log(values);
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar modelo.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    console.log(newValues);
    console.log(propName);
    console.log(newValues[propName]);
    newValues[propName].push(defaultValue ? defaultValue : {});
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].splice(indexItem, 1);
    setValues(newValues);
  };

  addSubItemInArray = (propName, subPropName, index, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName][index][subPropName] == undefined) {
      newValues[propName][index][subPropName] = [];
    }
    newValues[propName][index][subPropName].push({});
    setValues(newValues);
  };

  removeSubItemInArray = (
    propName,
    subPropName,
    indexItem,
    indexSub,
    values,
    setValues
  ) => {
    let newValues = _.cloneDeep(values);
    newValues[propName][indexItem][subPropName].splice(indexSub, 1);
    setValues(newValues);
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  render() {
    let _this = this;
    const { viewMode, entity } = this.props;
    console.log(entity);
    return (
      <React.Fragment>
        <Formik
          validationSchema={TriagemValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2 className="w-100">Informações</h2>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-lg-2">
                    <InputViewEdit
                      component={SelectInput}
                      id={'multSelection'}
                      options={[
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false },
                      ]}
                      returnFullObject={true}
                      label="A seleção é múltipla? "
                      required={true}
                      labelKey={'label'}
                      valueKey={'value'}
                      viewMode={viewMode}
                      name={'multSelection'}
                      defaultValue={
                        values.multSelection &&
                        values.multSelection.value === false
                          ? 'Não'
                          : formatterHelper.getLabelEnum(
                              values.multSelection && values.multSelection.value
                                ? values.multSelection.value
                                : values.multSelection,
                              objectsConstants.SIM_NAO
                            )
                      }
                      value={
                        _.isBoolean(values.multSelection)
                          ? formatterHelper.getEnum(
                              values.multSelection,
                              objectsConstants.SIM_NAO
                            )
                          : values.multSelection
                      }
                      onChange={(name, value) => {
                        setFieldValue(name, value.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-lg-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'Nome do grupo de triagem'}
                      type="text"
                      id="nome"
                      name="nome"
                      placeholder="Digite o nome do grupo de triagem"
                      value={values.nome}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.nome}
                      viewMode={viewMode}
                      required={true}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-lg-1">
                    <InputViewEdit
                      component={FormInput}
                      label={'Ordem na tela'}
                      type="number"
                      id="ordem"
                      name="ordem"
                      placeholder="Digite a posição do grupo na tela"
                      value={values.ordem}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.ordem}
                      viewMode={viewMode}
                      required={true}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-lg">
                    <InputViewEdit
                      component={FormInput}
                      label={'Descrição'}
                      type="text"
                      id="descricao"
                      name="descricao"
                      placeholder="Digite uma descrição para modelo"
                      value={values.descricao}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode}
                      required={false}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>
                </div>
              </div>

              <h2 className="w-100"> </h2>
              <div className="form-inside">
                {values.itensSelecao &&
                  values.itensSelecao.map((item, index) => {
                    return (
                      <div className={`${index % 2 == 0 ? 'bg-1' : 'bg-2'}`}>
                        <p className="ml-1 mb-0">
                          <b>Item {index + 1}</b>
                        </p>

                        <div className="row section-form">
                          <FormGroup className="col-12 col-lg-4">
                            <InputViewEdit
                              component={FormInput}
                              label="Nome do item"
                              type="text"
                              name={`itensSelecao[${index}].nome`}
                              id={`itensSelecao${index}nome`}
                              placeholder="Informe o nome do campo"
                              value={_.get(
                                values,
                                `itensSelecao[${index}].nome`
                              )}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                              erroMensagem={_.get(
                                errors,
                                `itensSelecao[${index}].nome`
                              )}
                              viewMode={viewMode}
                              required={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-12 col-lg-3">
                            <InputViewEdit
                              component={FormInput}
                              label="Cor do item"
                              type="text"
                              name={`itensSelecao[${index}].color`}
                              id={`itensSelecao${index}color`}
                              placeholder="Informe a cor do campo"
                              value={_.get(
                                values,
                                `itensSelecao[${index}].color`
                              )}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                              erroMensagem={_.get(
                                errors,
                                `itensSelecao[${index}].color`
                              )}
                              viewMode={viewMode}
                              required={true}
                            />
                          </FormGroup>

                          {!viewMode && (
                            <>
                              <div
                                className="col-8 col-lg-1 h-auto icon-delete d-flex align-items-center justify-content-center"
                                onClick={() =>
                                  this.removeItemInArray(
                                    'itensSelecao',
                                    index,
                                    values,
                                    setValues
                                  )
                                }
                              >
                                <img src={IconDelete} alt="Deletar modelo" />
                              </div>
                              <div className="from-inside">
                                <div
                                  className={`w-100 justify-content-lg-start justify-content-center pl-10px ${
                                    viewMode ? 'd-none' : ''
                                  }`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      this.addSubItemInArray(
                                        `itensSelecao`,
                                        `subItensSelecao`,
                                        index,
                                        values,
                                        setValues
                                      )
                                    }
                                  >
                                    Adicionar SubItem
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {item.subItensSelecao && (
                          <p className="ml-3 mb-0">
                            <small>
                              <b>Subitens</b>
                            </small>
                          </p>
                        )}

                        {item.subItensSelecao &&
                          item.subItensSelecao.map((subItem, indexSub) => {
                            return (
                              <div className="subitem">
                                <div className="row section-form">
                                  {!viewMode && (
                                    <>
                                      <div
                                        className="h-auto icon-delete d-flex align-items-center justify-content-center"
                                        onClick={() =>
                                          this.removeSubItemInArray(
                                            'itensSelecao',
                                            'subItensSelecao',
                                            index,
                                            indexSub,
                                            values,
                                            setValues
                                          )
                                        }
                                      >
                                        <img
                                          src={IconDelete}
                                          alt="Deletar Subitem"
                                        />
                                      </div>
                                    </>
                                  )}
                                  <FormGroup className="col-12 col-lg-3">
                                    <InputViewEdit
                                      component={FormInput}
                                      label="Nome do SubItem"
                                      type="text"
                                      name={`itensSelecao[${index}].subItensSelecao[${indexSub}].nome`}
                                      id={`itensSelecao[${index}].subItensSelecao${indexSub}nome`}
                                      placeholder="Informe o nome do campo"
                                      value={_.get(
                                        values,
                                        `itensSelecao[${index}].subItensSelecao[${indexSub}].nome`
                                      )}
                                      onChange={(name, value) =>
                                        setFieldValue(name, value)
                                      }
                                      erroMensagem={_.get(
                                        errors,
                                        `itensSelecao[${index}].subItensSelecao[${indexSub}].nome`
                                      )}
                                      viewMode={viewMode}
                                      required={true}
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-12 col-lg-4">
                                    <InputViewEdit
                                      component={FormInput}
                                      label="Descrição do Subitem"
                                      type="text"
                                      name={`itensSelecao[${index}].subItensSelecao[${indexSub}].descricao`}
                                      id={`itensSelecao[${index}].subItensSelecao${indexSub}descricao`}
                                      placeholder="Informe a descrição do subitem"
                                      value={_.get(
                                        values,
                                        `itensSelecao[${index}].subItensSelecao[${indexSub}].descricao`
                                      )}
                                      onChange={(name, value) =>
                                        setFieldValue(name, value)
                                      }
                                      erroMensagem={_.get(
                                        errors,
                                        `itensSelecao[${index}].subItensSelecao[${indexSub}].descricao`
                                      )}
                                      viewMode={viewMode}
                                      required={true}
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                <div className="from-inside">
                  <div
                    className={`w-100 justify-content-lg-start justify-content-center pl-10px ${
                      viewMode ? 'd-none' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.addItemInArray(
                          'itensSelecao',
                          { subItensSelecao: [] },
                          values,
                          setValues
                        )
                      }
                    >
                      Adicionar Item
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(TriagemForm);
