//@flow
//@flow
import { Form, Formik } from 'formik';
import _, { isNumber, toNumber } from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { FormGroup } from '../../components/FormGroup';
import CheckBoxInputOnly from '../../components/inputs/CheckBoxInputOnly';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import FormTextCKEditor from '../../components/inputs/FormTextCKEditor';
import ImageInput from '../../components/inputs/ImageInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import imagemBG from '../../images/bgImage.jpeg';
import { translate } from '../../_helpers/messages.helper';
import { categoriaDocumentoService } from '../../_services/categoriaDocumento.service';
import { defaultService } from '../../_services/defaultService';
import { medicoService } from '../../_services/medico.service';
import { midiaService } from '../../_services/midia.service';
import { modeloService } from '../../_services/modelo.service';
import { userService } from '../../_services/user.service';
import IconDelete from '../../images/icon_x_red.svg';
import { objectsConstants } from '../../_constants/objects.constants';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const ModelosValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(120, 'Nome muito grande!')
    .required('Campo obrigatório'),
  // descricao: Yup.string()
  // .min(5, 'Descrição muito curto!')
  // .required('Campo obrigatório'),
  // info1: Yup.string().required('Campo obrigatório'),
  categoriaDocumento: Yup.object().shape({
    id: Yup.string().nullable().required('Obrigatório'),
  }),
  textoCorpo: Yup.string().required('Campo obrigatório'),
  // info2: Yup.string().required('Campo obrigatório'),
  // textoRodape: Yup.string().required('Campo obrigatório'),
  formulario: Yup.array().of(
    Yup.object().shape({
      tipo: Yup.string().required('Este campo é obrigatório'),
      nome: Yup.string().required('Este campo é obrigatório'),
    })
  ),
});

class ModelosForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      todosProfissionais: false,
    };
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };
  saveForm = () => {
    console.log('saveForm Modelo');
    let _this = this;
    let values = _this.formRef.state.values;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          modeloService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Modelos, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              console.log(values);
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              console.log(response.headers.location);
              console.log(id);
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar modelo.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(defaultValue ? defaultValue : {});
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].splice(indexItem, 1);
    setValues(newValues);
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  imageUrl = (valuesAttr) => {
    if (valuesAttr && valuesAttr.id) {
      return midiaService.url(valuesAttr.id);
    } else {
      if (valuesAttr) {
        return valuesAttr.midiaConteudoVO.foto64;
      } else {
        return imagemBG;
      }
    }
  };
  // assintauraUrl = (values) => {
  //   if (values.assinatura && values.assinatura.id) {
  //     return midiaService.url(values.assinatura.id);
  //   } else {
  //     if (values.assinatura) {
  //       return values.assinatura.midiaConteudoVO.foto64;
  //     } else {
  //       return imagemBG;
  //     }
  //   }
  // };
  // logoUrl = (values) => {
  //   if (values.logo && values.logo.id) {
  //     return midiaService.url(values.logo.id);
  //   } else {
  //     if (values.logo) {
  //       return values.logo.midiaConteudoVO.foto64;
  //     } else {
  //       return imagemBG;
  //     }
  //   }
  // };

  // backgroundImageUrl = (values) => {
  //   if (values.backgroundImage && values.backgroundImage.id) {
  //     return midiaService.url(values.backgroundImage.id);
  //   } else {
  //     if (values.backgroundImage) {
  //       return values.backgroundImage.midiaConteudoVO.foto64;
  //     } else {
  //       return imagemBG;
  //     }
  //   }
  // };

  selecionarTodosProfissionais = () => {
    this.setState({ todosProfissionais: !this.state.todosProfissionais });
  };

  doGetCaretPosition = (ctrl) => {
    var CaretPos = 0;
    // IE Support
    if (document.selection) {
      ctrl.focus();
      var Sel = document.selection.createRange();

      Sel.moveStart('character', -ctrl.value.length);

      CaretPos = Sel.text.length;
    }
    // Firefox support
    else if (ctrl.selectionStart || ctrl.selectionStart == '0')
      CaretPos = ctrl.selectionStart;

    return CaretPos;
  };
  insertAtCaret = (event, areaId, text, values, setFieldValue) => {
    event.preventDefault();
    let position;
    let newText;
    if (values[areaId] && values[areaId].length >= 4) {
      position = values[areaId].length - 4;

      newText = [
        values[areaId].slice(0, position),
        text,
        values[areaId].slice(position),
      ].join('');
    } else {
      newText = text;
    }

    setFieldValue(areaId, newText);
    // var txtarea = document.getElementById(areaId);
    // var editor = this.refCkEditor.getRefCkEditor();
    // var editor1 = editor.editor;
    // var selection = editor1.getData();
    // let value = selection + text;
    // var scrollPos = txtarea.scrollTop;
    // var strPos = 0;
    // var br =
    //   txtarea.selectionStart || txtarea.selectionStart == '0'
    //     ? 'ff'
    //     : document.selection
    //     ? 'ie'
    //     : false;
    // if (br == 'ie') {
    //   txtarea.focus();
    //   var range = document.selection.createRange();
    //   range.moveStart('character', -txtarea.value.length);
    //   strPos = range.text.length;
    // } else if (br == 'ff') {
    //   strPos = txtarea.selectionStart;
    // }

    // var front = value.substring(0, strPos);
    // var back = value.substring(strPos, value.length);
    // value = front + text + back;
    // strPos = strPos + text.length;
    // if (br == 'ie') {
    //   txtarea.focus();
    //   var range = document.selection.createRange();
    //   range.moveStart('character', -value.length);
    //   range.moveStart('character', strPos);
    //   range.moveEnd('character', 0);
    //   range.select();
    // } else if (br == 'ff') {
    //   txtarea.selectionStart = strPos;
    //   txtarea.selectionEnd = strPos;
    //   txtarea.focus();
    // }

    // setFieldValue('textoCorpo', value);
    // txtarea.scrollTop = scrollPos;
  };

  render() {
    let _this = this;
    const { viewMode, entity, propName, index, formulario } = this.props;

    return (
      <React.Fragment>
        <Formik
          validationSchema={ModelosValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              {!userService.isMedico() && (
                <React.Fragment>
                  <h2 className="w-100">Disponibilidade</h2>
                  <div className="form-inside">
                    <div className="row section-form">
                      <React.Fragment>
                        <FormGroup className=" col-10">
                          {!this.state.todosProfissionais && (
                            <InputViewEdit
                              component={FormSelectInput}
                              name="medicos"
                              id={'medicos'}
                              label="Selecione um ou mais profissionais de saúde"
                              placeholder="Selecione um ou mais profissionais de saúde"
                              valueKey="id"
                              labelKey="nome"
                              noSize={true}
                              required={true}
                              returnFullObject={true}
                              isMulti={true}
                              searchable={true}
                              allowSelectAll={true}
                              viewMode={viewMode}
                              service={medicoService.findMedicoByEspecialidade}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              value={
                                !viewMode
                                  ? _.get(values, `medicos`)
                                  : _.get(values, 'medicos', [])
                                      .map((e) => e.apelido)
                                      .join(', ')
                              }
                            />

                            // <InputViewEdit
                            //   component={FormSelectInput}
                            //   onChange={(name, value) => {
                            //     setFieldValue(name, !value ? [] : value);
                            //   }}
                            //   id={`medicos`}
                            //   label="Selecione um ou mais profissionais de saúde"
                            //   placeholder="Selecione um ou mais profissionais de saúde"
                            //   name={`medicos`}
                            //   value={
                            //     !viewMode
                            //       ? _.get(values, `medicos`)
                            //       : _.get(values, 'medicos', [])
                            //           .map((e) => e.apelido)
                            //           .join(', ')
                            //   }
                            //   erroMensagem={_.get(errors, `medicos`)}
                            //   returnFullObject={true}
                            //   service={medicoService.findMedicoByEspecialidade}
                            //   creatable={false}
                            //   isMulti={true}
                            //   viewMode={viewMode}
                            //   viewClassName="text-capitalize"
                            //   className="text-capitalize"
                            //   valueKey="id"
                            //   labelKey="apelido"
                            //   sortKey="apelido"
                            // />
                          )}
                          {/* {this.state.todosProfissionais && (
                            <InputViewEdit
                              component={SelectInput}
                              disabled={true}
                              id={`medicos`}
                              label="Selecione um ou mais profissionais de saúde"
                              placeholder="Todos Profissionais selecionados"
                              name={`medicos`}
                              options={[]}
                              creatable={false}
                              isMulti={true}
                              defaultValue={null}
                              viewMode={viewMode}
                            />
                          )} */}
                        </FormGroup>
                        {/* <FormGroup className="col-7 d-flex align-items-end justify-content-start">
                          {!viewMode && (
                            <button
                              className="btn btn-primary mb-1"
                              onClick={this.selecionarTodosProfissionais}
                              type="button"
                            >
                              {this.state.todosProfissionais
                                ? 'Selecionar um ou mais profissionais'
                                : 'Selecionar todos os profissionais'}
                            </button>
                          )}
                        </FormGroup> */}
                      </React.Fragment>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <h2 className="w-100">Informações</h2>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-lg-3">
                    <InputViewEdit
                      component={FormInput}
                      label={'Nome'}
                      type="text"
                      id="nome"
                      name="nome"
                      placeholder="Digite o nome do modelo"
                      value={values.nome}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.nome}
                      viewMode={viewMode}
                      required={true}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-lg-3">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Categoria do modelo'}
                      id="categoriaDocumento"
                      name="categoriaDocumento"
                      placeholder="Selecione um categoria"
                      value={values.categoriaDocumento}
                      onChange={setFieldValue}
                      noSize={true}
                      erroMensagem={_.get(errors, 'categoriaDocumento.id')}
                      viewMode={viewMode}
                      required={true}
                      service={categoriaDocumentoService.findAll}
                      defaultValue={_.get(values, 'categoriaDocumento.titulo')}
                      labelKey={'titulo'}
                      returnFullObject={true}
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-lg">
                    <InputViewEdit
                      component={FormInput}
                      label={'Descrição'}
                      type="text"
                      id="descricao"
                      name="descricao"
                      placeholder="Digite uma descrição para modelo"
                      value={values.descricao}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.descricao}
                      viewMode={viewMode}
                      required={false}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>
                </div>
              </div>
              <h2 className="w-100">Topo do documento</h2>
              <div className="form-inside">
                <div className="row section-form">
                  {/* <FormGroup className="col-12 col-lg-12">
                    <FormTextCKEditor
                      label="Cabeçalho do documento"
                      id={'cabecalho'}
                      name={'cabecalho'}
                      className={'textCKeditor'}
                      type={'textarea'}
                      required={true}
                      onChange={setFieldValue}
                      erroMensagem={errors.cabecalho}
                      viewMode={viewMode}
                      viewClassName={'view-anamnese'}
                      value={values.cabecalho}
                      defaultValue={values.cabecalho}
                      placeholder="Insira aqui as informções do cabeçalho(topo) do documento"
                      ref={(ckRef) => {
                        this.refCkEditor = ckRef;
                      }}
                    />
                  </FormGroup> */}
                  <FormGroup className="col-12 col-lg-4">
                    <label>
                      <b>Logomarca</b>
                    </label>
                    <div>
                      <ImageInput
                        maxWidth={120}
                        maxHeight={120}
                        height={120}
                        required={true}
                        erroMensagem={errors.logo}
                        onChange={(imageVO) => setFieldValue('logo', imageVO)}
                        dataUrl={this.imageUrl(values.logo)}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="col-12 col-lg-4">
                    <InputViewEdit
                      component={FormInput}
                      label={'Informações adicionais (Coluna 1)'}
                      type="text"
                      id="info1"
                      name="info1"
                      placeholder="Telefone, endereço ou alguma informação adicional para a coluna 1"
                      value={values.info1}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.info1}
                      viewMode={viewMode}
                      required={false}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>

                  <FormGroup className="col-12 col-lg-4">
                    <InputViewEdit
                      component={FormInput}
                      label={'Informações adicionais (Coluna 2)'}
                      type="text"
                      id="info2"
                      name="info2"
                      placeholder="Telefone, endereço ou alguma informação adicional para a coluna 2"
                      value={values.info2}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.info2}
                      viewMode={viewMode}
                      required={false}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>
                </div>
              </div>

              <h2 className="w-100">Formulário</h2>
              <div className="form-inside">
                {values.formulario &&
                  values.formulario.map((modeloDocumento, index) => {
                    return (
                      <div className="row section-form">
                        <FormGroup className="col-12 col-lg-3">
                          <InputViewEdit
                            component={FormSelectInput}
                            options={objectsConstants.TIPO_DOCUMENTO}
                            valueKey={'name'}
                            labelKey={'description'}
                            multi={false}
                            label="Tipo do campo"
                            name={`formulario[${index}].tipo`}
                            id={`formulario${index}tipo`}
                            placeholder="Selecione o tipo do campo"
                            noSize={true}
                            required={true}
                            viewMode={viewMode}
                            value={_.get(values, `formulario[${index}].tipo`)}
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                            }}
                            erroMensagem={_.get(
                              errors,
                              `formulario[${index}].tipo`
                            )}
                            returnFullObject={false}
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-lg-3">
                          <InputViewEdit
                            component={FormInput}
                            label="Nome do campo"
                            type="text"
                            name={`formulario[${index}].nome`}
                            id={`formulario${index}nome`}
                            placeholder="Informe o nome do campo"
                            value={_.get(values, `formulario[${index}].nome`)}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            erroMensagem={_.get(
                              errors,
                              `formulario[${index}].nome`
                            )}
                            viewMode={viewMode}
                            required={true}
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-lg-2 d-flex align-items-center">
                          <CheckBoxInputOnly
                            checked={_.get(
                              values,
                              `formulario[${index}].obrigatorio`,
                              false
                            )}
                            name={`formulario[${index}].obrigatorio`}
                            id={`formulario${index}obrigatorio`}
                            onChange={(checked, name) => {
                              setFieldValue(name, checked);
                            }}
                            label="É obrigatório ?"
                            viewMode={viewMode}
                          />
                        </FormGroup>
                        {!viewMode && (
                          <div
                            className="col-8 col-lg-1 h-auto icon-delete d-flex align-items-center justify-content-center"
                            onClick={() =>
                              this.removeItemInArray(
                                'formulario',
                                index,
                                values,
                                setValues
                              )
                            }
                          >
                            <img src={IconDelete} alt="Deletar modelo" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div className="from-inside">
                  <div
                    className={`w-100 justify-content-lg-start justify-content-center pl-10px ${
                      viewMode ? 'd-none' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.addItemInArray(
                          'formulario',
                          null,
                          values,
                          setValues
                        )
                      }
                    >
                      Adicionar campo
                    </button>
                  </div>
                </div>
              </div>

              <h2 className="w-100">Corpo do documento</h2>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-lg-2">
                    <label>
                      <b>
                        Imagem de fundo do documento(Imagem que será colocada
                        como marca dágua em todo corpo do documento)
                      </b>
                    </label>
                    <div className="mb-1">
                      <ImageInput
                        maxWidth={20}
                        maxHeight={20}
                        height={20}
                        required={false}
                        onChange={(imageVO) =>
                          setFieldValue('backgroundImage', imageVO)
                        }
                        dataUrl={this.imageUrl(values.backgroundImage)}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="col-12 col-lg-10">
                    <FormTextCKEditor
                      label="Corpo da mensagem"
                      id={'textoCorpo'}
                      name={'textoCorpo'}
                      className={'textCKeditor'}
                      type={'textarea'}
                      required={true}
                      onChange={setFieldValue}
                      erroMensagem={errors.textoCorpo}
                      viewMode={viewMode}
                      viewClassName={'view-anamnese'}
                      value={values.textoCorpo}
                      defaultValue={values.textoCorpo}
                      placeholder="Insira aqui as informações do modelo"
                      // removePlugins={[
                      //   'Heading',
                      //   'BlockQuote',
                      //   'ImageUpload',
                      //   'MediaEmbed',
                      // ]}
                      ref={(ckRef) => {
                        this.refCkEditor = ckRef;
                      }}
                    />
                  </FormGroup>
                  {!viewMode && (
                    <div className="offset-2 col-10">
                      <div>
                        <b>Adicionar variáveis</b>
                      </div>
                      <button
                        className="btn btn-outline-primary ml-0"
                        onClick={(event) =>
                          this.insertAtCaret(
                            event,
                            'textoCorpo',
                            ' _NOME_DO_PACIENTE_ ',
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Nome do paciente
                      </button>
                      <button
                        className="btn btn-outline-primary ml-0"
                        onClick={(event) =>
                          this.insertAtCaret(
                            event,
                            'textoCorpo',
                            ' _ENDERECO_DO_PACIENTE_ ',
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Endereço do paciente
                      </button>
                      <button
                        className="btn btn-outline-primary"
                        onClick={(event) =>
                          this.insertAtCaret(
                            event,
                            'textoCorpo',
                            ' _NOME_DO_PROFISSIONAL_ ',
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Nome do profissional
                      </button>
                      <button
                        className="btn btn-outline-primary"
                        onClick={(event) =>
                          this.insertAtCaret(
                            event,
                            'textoCorpo',
                            ' _NUMERO_DO_CONSELHO_ ',
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Número do conselho
                      </button>
                      <button
                        className="btn btn-outline-primary"
                        onClick={(event) =>
                          this.insertAtCaret(
                            event,
                            'textoCorpo',
                            ' _DATA_DA_CONSULTA_',
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Data da consulta/exame
                      </button>
                      <button
                        className="btn btn-outline-primary"
                        onClick={(event) =>
                          this.insertAtCaret(
                            event,
                            'textoCorpo',
                            ' _IDADE_DO_PACIENTE_ ',
                            values,
                            setFieldValue
                          )
                        }
                      >
                        Idade do paciente
                      </button>
                      {values.formulario.map((ModeloDocumento, index) => {
                        let nomeDoInput = values.formulario[index].nome;
                        if (nomeDoInput)
                          return (
                            <button
                              className="btn btn-outline-primary"
                              onClick={(event) =>
                                this.insertAtCaret(
                                  event,
                                  'textoCorpo',
                                  ` _${nomeDoInput
                                    .replace(/\s/g, '_')
                                    .toUpperCase()}_ `,
                                  values,
                                  setFieldValue
                                )
                              }
                            >
                              {nomeDoInput}
                            </button>
                          );
                      })}
                    </div>
                  )}
                  <span className="offset-2 col-10">
                    Não é necessário escrever o nome do paciente, pois esse dado
                    é preenchido de forma automática. Também não é necessário
                    simular a presença do cabeçalho, assinatura do profissional
                    ou rodapé. Esses dados devem ser editados no local
                    específico, em configurações de impressão, para cada tipo de
                    documento. Preencha apenas o conteúdo do documento. Esse
                    conteúdo poderá ser editado a cada vez que for utilizado, na
                    tela de atendimento.
                  </span>
                </div>
              </div>
              <h2 className="w-100">Rodapé do documento</h2>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-lg-5">
                    <label>
                      <b>Assinatura</b>
                    </label>
                    <div className="mb-1">
                      <ImageInput
                        maxWidth={120}
                        maxHeight={120}
                        height={120}
                        required={false}
                        onChange={(imageVO) =>
                          setFieldValue('assinatura', imageVO)
                        }
                        dataUrl={this.imageUrl(values.assinatura)}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="col-12 col-lg">
                    <InputViewEdit
                      component={FormInput}
                      label={'Texto rodapé'}
                      labelClassName={'invisible'}
                      type="text"
                      id="textoRodape"
                      name="textoRodape"
                      placeholder="Texto que acompanhará a assinatura"
                      value={values.textoRodape}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.textoRodape}
                      viewMode={viewMode}
                      required={false}
                      viewClassName="font-weight-bold"
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ModelosForm);
