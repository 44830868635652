//@flow
import * as React from 'react';
import _ from 'lodash';
import SelectInput from './SelectInput';
import InputViewEdit from "./InputViewEdit";

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean
};

type State = {
  options: Array<any>
};
export default class FormSelectInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    isMulti: false
  }
  constructor(props: Props) {
    super(props);

    this.state = {
      options: [],
      defaultValue: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.parent && (!_.isEqual(nextProps.parent,this.props.parent)||!_.isEqual(nextProps.defaultParent,this.props.defaultParent))) {
      this.fetchData(nextProps.parent, nextProps.defaultParent);
    }
  }
  componentDidMount() {
    if (this.props.service) {
      this.fetchData(this.props.parent, this.props.defaultParent)
    } else if (this.props.options) {
      this.setState({ options: this.props.options });
    }
  }

  sortByKey(data, key) {
    if (this.props.sortNoAsc) {
      return data.sort((a, b) => a[key].localeCompare(b[key]))
    } else {
      return data.sort((a, b) => a[key] > b[key] ? 1 : (b[key] > a[key]) ? -1 : 0)
    }
  }

  fetchData(parent, defaultParent) {
    let _this = this;
    let fetch = parent ? this.props.service(parent, defaultParent) : this.props.service();
    try {
      fetch.then(
        data => {
          let options = [];
          options = options.concat(data.data);
          let defaultValue;
          if (_this.props.defaultSelected && options.length) {
            defaultValue = options[0];
          }
          if (this.props.sortKey) {
            options = this.sortByKey(options, this.props.sortKey);
          }

          this.setState({ options: options, defaultValue }, () => {
            if (defaultValue) {
              this.props.onChange(this.props.name, defaultValue);
            }
          });
        },
        error => {
          console.log('Erro ao montar o combo', error);
        }
      );
    } catch (error) {
      console.log('Erro ao fazer fetch', error);
      let options = [];
      this.setState({ options: options, defaultValue: null });
    }

  }

  render() {
    return (
      <SelectInput
      multi={this.props.multi}
      isMulti={this.props.isMulti}
        id={this.props.id}
        label={this.props.label}
        valueKey={this.props.valueKey ? this.props.valueKey : 'id'}
        labelKey={this.props.labelKey ? this.props.labelKey : 'nome'}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        isMulti={this.props.isMulti}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        allowSelectAll={this.props.allowSelectAll}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        erroMensagem={this.props.erroMensagem}
        className={this.props.className}
        placeholder={this.props.placeholder}
        defaultValue={this.state.defaultValue}
        components={this.props.components}
        formatOptionLabel={this.props.formatOptionLabel}
        onBlur={this.props.onBlur}
        icone={this.props.icone}
        classIcone={this.props.classIcone}
        creatable={this.props.creatable}
        clearable={this.props.clearable}
        onNewOptionClick={this.props.onNewOptionClick}
        showToolTip={this.props.showToolTip}
      />
    );
  }
}
