//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { userService } from '../../_services/user.service';
import PacienteForm from './PacienteForm';

const defaultPaciente = {
  nome: '',
  email: '',
  cpfCnpj: '',
  telefones: [{}],
  tipoAtendimento: objectsConstants.TIPO_ATENDIMENTO_PARTICULAR,
  requiredAddres: false,
  pacienteConvenios: [
    {
      convenio: {},
      plano: '',
      carteirinha: '',
      validadeCarteirinha: '',
    },
  ],
};

class PacienteEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultPaciente,
    };
  }

  // goToViewEntity = (values) => {
  //   this.props.history.push({
  //     pathname: '/paciente/view/' + values.id,
  //     state: { entity: values },
  //   });
  // };

  handleCancel = () => {
    console.log('calcel paciente');
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      userService.doGet(this.props.match.params.id).then(
        (response) => {
          let paciente = response.data.data;
          _.set(paciente, 'requiredAddres', false);
          console.log(paciente);
          this.setState({ entity: paciente }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log('error');
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  render() {
    const { entity } = this.state;
    const { callbackOnSave, ...otherPops } = this.props;

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  paciente`}
          canBack={true}
          backUrl={!entity.id ? null : '/paciente'}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>

        <div className="flex-column">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_PACIENTE}
            title="Paciente"
            Componente={PacienteForm}
            viewMode={false}
            entity={entity}
            hideAddres={false}
            callbackOnSave={callbackOnSave}
            onCancel={this.handleCancel}
            btnExcluir={
              !entity.temMarcacao
            }

            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(PacienteEdit)
);
