//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const unidadePadraoService = {
  findByEstabelecimento,
  doSave
};

function findByEstabelecimento() {
  return defaultService.doGet(`${urlsConstants.UNIDADE_PADRAO}findByEstabelecimento`);
}


function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.UNIDADE_PADRAO, entity);
}

