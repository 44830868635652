//@flow
import _ from 'lodash';

export default {
  state: {
    client: null, webSocketConnected: false
  },
  reducers: {
    conectSocket: (state, payload) => {
      return Object.assign({}, state, {
        client: _.get(payload, 'client', null),
        webSocketConnected: _.get(payload, 'webSocketConnected', false),
      });

    },
    disconectSocket: (state, payload) => {
      return Object.assign({}, state, {

        webSocketConnected: false
      });

    }
  }
};
