//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
import { getAgendasDay } from './../../_helpers/agenda.helper';

type Props = {
  date?: any,
  marcacoes?: any,
  medicoConfiguracaoVO: any,
  onClick: any,
  onMonthChange: any,
};

type State = {
  monthNameShort: string,
  fullYear: string,
  maxMarcacoes: number,
  agendaCheia: boolean,
};

export class CalendarDate extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  getMaxMarcaoDia(agendas: HorariosConsulta, date?: any) {
    if (!agendas || !agendas.length || !date) {
      return 0;
    }
    let agendaDay = getAgendasDay(agendas, date);

    let encaixes = _.filter(
      agendaDay,
      (horario) => horario.listaEncaixe.length > 0
    );
    let qtdEncaixes = _.filter(encaixes, 'paciente').length;

    return agendaDay.length + qtdEncaixes;
  }

  getTotalMarcaoDia(agendas: HorariosConsulta, date?: any) {
    if (!agendas || !agendas.length || !date) {
      return 0;
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() > date.getTime()) {
      return 0;
    }
    let dataString = date.toLocaleDateString('pt-br');
    let agendaDay = _.filter(agendas, { data: dataString });
    let encaixes = _.filter(
      agendaDay,
      (horario) => horario.listaEncaixe.length > 0
    );

    let qtdEncaixes = _.filter(encaixes, 'paciente').length;

    let qtdReservados = _.filter(agendaDay, { horarioReservado: true }).length;

    const countMarcacoes = agendas
      ? _.filter(agendaDay, 'paciente').length + qtdEncaixes + qtdReservados
      : 0;
    return countMarcacoes;
  }

  getTotalReservado(agendas: HorariosConsulta, date?: any) {
    if (!agendas || !agendas.length || !date) {
      return 0;
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() > date.getTime()) {
      return 0;
    }
    let dataString = date.toLocaleDateString('pt-br');
    let agendaDay = _.filter(agendas, { data: dataString });
    let qtdReservados = _.filter(agendaDay, { horarioReservado: true }).length;

    return qtdReservados;
  }

  statusAgenda = (maxMarcacoes, countMarcacoes) => {
    if (maxMarcacoes > 0 && countMarcacoes >= maxMarcacoes) {
      return 'agenda-cheia';
    }
    if (maxMarcacoes == 0) {
      return 'agenda-vazia';
    } else {
      return '';
    }
  };

  divInfo = (
    date?: any,
    maxMarcacoes: number,
    countMarcacoes: number,
    statusAgenda: String
  ) => {
    const { hideTotalMarcacao } = this.props;
    if (!date) {
      return '';
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() > date.getTime()) {
      return (
        <React.Fragment>
          <div className="font-details d-none d-lg-block concluido">
            concluída
          </div>
        </React.Fragment>
      );
    }
    let labelMarcacoes;
    if (statusAgenda == 'agenda-cheia') {
      labelMarcacoes = (
        <React.Fragment>
          <div className="font-details bold red d-none d-lg-block">agenda</div>
          <div className="font-details bold red d-none d-lg-block">cheia</div>
        </React.Fragment>
      );
    } else if (statusAgenda == 'agenda-vazia') {
      labelMarcacoes = (
        <React.Fragment>
          <div className="font-details italic d-none d-lg-block nenhuma-agenda">
            nenhuma
          </div>
          <div className="font-details italic d-none d-lg-block">agenda</div>
        </React.Fragment>
      );
    } else if (hideTotalMarcacao) {
      labelMarcacoes = (
        <React.Fragment>
          <div className="font-details d-none d-lg-block disponivel">
            disponível
          </div>
        </React.Fragment>
      );
    } else {
      labelMarcacoes = (
        <React.Fragment>
          <div className="font-details d-none d-lg-block">agendados:</div>
          <div className="font-details d-none d-lg-block">
            <div>
              {countMarcacoes} / {maxMarcacoes}
            </div>
          </div>
        </React.Fragment>
      );
    }

    return <React.Fragment>{labelMarcacoes}</React.Fragment>;
  };

  cssDate = (date?: any, maxMarcacoes) => {
    if (!date) {
      return 'invisible';
    }
    date.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate.getTime() > date.getTime()) {
      return 'agenda-vazia';
    }
    if (currentDate.getTime() === date.getTime()) {
      return 'date-actual';
    }
    return '';
  };

  render() {
    const { date, agendas, showMont, chave } = this.props;
    const maxMarcacoes = this.getMaxMarcaoDia(agendas, date);
    const countMarcacoes = this.getTotalMarcaoDia(agendas, date);
    const countReservados = this.getTotalReservado(agendas, date);
    let statusAgenda = this.statusAgenda(maxMarcacoes, countMarcacoes);
    let monthNameShort = date
      ? date.toLocaleString('pt-br', { month: 'long' })
      : '';
    let fullYear = date ? date.getFullYear() : '';
    this.state = { monthNameShort, maxMarcacoes, fullYear };
    return (
      <div
        className={`col calendar-date d-flex align-items-center justify-content-around
        ${statusAgenda == 'agenda-vazia' && 'agenda-vazia'}         
        ${this.cssDate(date, maxMarcacoes)} `}
        onClick={() => {
          this.props.onClick(date, maxMarcacoes);
        }}
        id={`dataCalendar${chave}`}
      >
        {countReservados > 0 &&
          maxMarcacoes > 0 &&
          countMarcacoes >= maxMarcacoes && (
            <ToolTipHelp
              message="Existem horários reservados"
              target={`dataCalendar${chave}`}
            />
          )}

        <div className="d-flex flex-row flex-lg-column justify-content-center">
          <div
            className={`font-date ${
              statusAgenda == 'agenda-cheia' && 'red'
            }          
            `}
          >
            {date && date.getDate()}
          </div>
          <React.Fragment>
            {this.divInfo(date, maxMarcacoes, countMarcacoes, statusAgenda)}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
