//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { userService } from '../../../_services/user.service';
import EstoqueProdutosForm from './EstoqueProdutosForm';
import { objectsConstants } from '../../../_constants/objects.constants';
import Precificacao from './Precificacao';
import TagsBusca from './TagBusca';
import EstoqueProdutosUnidadePadraoForm from './EstoqueProdutosUnidadePadraoForm';
import EstoqueFaturamento from './EstoqueFaturamento';
import EstoqueProdutosPrecificacaoForm from './EstoqueProdutosPrecificacaoForm';
import { estoqueService } from '../../../_services/estoque.service';

class EstoqueProdutosEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {},
    };
  }

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/estoque-produtos/' + values.id,
      state: { entity: values },
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  // addPrecificacao = () => {
  //   let newEstoque = _.cloneDeep(this.state.entity);
  //   newEstoque.listPrecificacao.unshift({});
  //   this.setState({ entity: newEstoque });
  // };
  addTagsBusca = () => {
    let newEstoque = _.cloneDeep(this.state.entity);
    newEstoque.tagsBusca.unshift({});
    this.setState({ entity: newEstoque });
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      estoqueService.doGet(this.props.match.params.id).then(
        (response) => {
          let estoque = response.data.data;
          console.log(estoque);

          this.setState({ entity: estoque }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        console.log(erros);
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    console.log(entity);
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  produto`}
          canBack={true}
          backUrl={!entity.id ? null : '/estoque-produtos'}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>

        <div className="flex-column">
          <FullCustomCard
            ref={(form) => {
              this.refFormComponent = form;
            }}
            permissionSave={objectsConstants.MENU_ESTOQUE}
            title="Dados do produto"
            Componente={EstoqueProdutosForm}
            viewMode={false}
            entity={entity}
            redirectPath="/estoque-produtos/"
            onCancel={this.handleCancel}
            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(EstoqueProdutosEdit)
);
