//@flow
import * as React from 'react';

type Props = {
  entity: any,
  onChange: any,
};

type State = {
  selected: boolean,
};

export class DiaDaSemanaItem extends React.Component<Props, State> {
  render() {
    const { selected } = this.props;

    return (
      <label
        className={`buttonWeek ${
          selected === true ? 'buttonWeek-on' : 'buttonWeek-off'
        }`}
        onClick={this.handleClick}
      >
        {this.props.entity.description}
      </label>
    );
  }

  handleClick = (event: any) => {
    event.preventDefault();
    let { selected } = this.props;
    this.props.onChange(!selected, this.props.entity);
    return false; //Click event is triggered twice and this prevents re-toggling of classes
  };
}
