//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from './../../components/inputs/FormInput';
import CheckBoxInputOnly from "../../components/inputs/CheckBoxInputOnly";

type Props = { onChangeText: any, entity: any, onChangeValue: any};

export default class ConvenioListFilter extends React.PureComponent<Props> {
    render() {
        const { entity } = this.props;
        return (
            <React.Fragment>
                <FormGroup className="col-12 col-xl-3">
                    <FormInput
                        label="Nome do convênio"
                        placeholder={'Nome do convênio'}
                        id={'nome'}
                        name="nome"
                        onChange={this.props.onChangeDate}
                        type={'text'}
                        size={12}
                        style={'col-12 col-sm-4 offset-sm-1'}
                        value={entity.nome}
                    />
                </FormGroup>

                <FormGroup className="col-12 col-xl-2">
                    <FormInput
                        label="Registo ANS"
                        placeholder={'Registo ANS'}
                        id={'numeroANS'}
                        name="numeroANS"
                        onChange={this.props.onChangeDate}
                        type={'text'}
                        size={12}
                        style={'col-12 col-sm-4 offset-sm-1'}
                        value={entity.numeroANS}
                    />
                </FormGroup >
                <FormGroup  className="col-12 col-xl-2">
                <CheckBoxInputOnly
                    checked={entity.exibirConvenioForaEstabelecimento}
                    name={`exibirConvenioForaEstabelecimento`}
                    label={`Exibir convenios que não são do estabelecimento`}
                    onChange={(checked, name) => {
                        this.props.onChangeDate(name, checked);
                    }}
                ></CheckBoxInputOnly>
                </FormGroup>
                <FormGroup className="col-12 col-xl-2">
                    <CpfCnpjInput
                        label="Número do CNPJ"
                        placeholder={'CNPJ'}
                        id={'cnpj'}
                        name="cnpj"
                        onChange={this.props.onChangeDate}
                        type={'number'}
                        size={12}
                        style={'col-12 col-sm-4 offset-sm-1'}
                        value={entity.cnpj}
                    />
                </FormGroup>
            </React.Fragment>
        )
    }}