import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import BtnPrimary from "../Buttons/BtnPrimary";
// import BtnSecondary from "../Buttons/BtnSecondary";
import Avatar from 'react-avatar-edit';

type Props = {
  modal: boolean,
  dataUrl: any,
  width: number,
  height: number,
  titleBtn: string,
  title: string,
  onCropper: any,
};
export default class CropperView extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
    this.state = {
      modal: props.modal,
      dataUrl: props.dataUrl,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({ dataUrl: nextProps.dataUrl, modal: nextProps.modal });
  }

  onAccpet = () => {
    this.onClose();
    this.props.onCropper(this.state.preview);
  };
  onClose() {
    this.setState({ modal: !this.state.modal });
  }

  onCrop(preview) {
    this.setState({ preview });
  }

  onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 71680) {
      alert('Arquivo muito grande!');
      elem.target.value = '';
    }
  }
  render() {
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        className={'customModal modal-xl ' + this.props.className}
      >
        <ModalBody>
          <h1 className="text-center mt-2 mb-4">{this.props.title}</h1>
          <p>{this.props.subititle}</p>
          <div
            className="row justify-content-md-center"
            style={{
              width: this.props.width + 'px',
              height: this.props.height + 'px',
            }}
          >
            <Avatar
              width={450}
              height={450}
              imageHeight={450}
              onCrop={this.onCrop}
              onClose={this.onClose}
              onBeforeFileLoad={this.onBeforeFileLoad}
              src={this.props.dataUrl}
              closeIconColor={'transparent'}
              cropRadius={this.props.cropRadius ? this.props.cropRadius : 100}
              exportAsSquare={
                this.props.exportAsSquare ? this.props.exportAsSquare : false
              }
            />
          </div>
        </ModalBody>
        <ModalFooter className={'customModalFooter'}>
          <button
            className={'btn btn-secondary my-4 mx-2'}
            onClick={() => {
              this.onClose();
            }}
          >
            Cancelar
          </button>

          <button
            className="btn btn-primary my-4 mx-2"
            onClick={() => {
              this.onAccpet();
            }}
          >
            {this.props.titleBtn}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
  _crop() {
    // image in dataUrl
  }
}
