//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PerfilPage from './PerfilPage';


export default class PerfilIndexPage extends React.Component<{}> {
  
  render() {
    return (
      <Switch>
        <Route path="/perfil/:id" component={PerfilPage} />
      </Switch>
      
    );
  }
}


