//@flow
import { urlsConstants } from '../_constants/urls.constant';

export const midiaService = {
  url,
  sendImageCKEditor,
};

function url(id) {
  return `${urlsConstants.URL_MIDIAS}${id}`;
}

function sendImageCKEditor() {
  return `${urlsConstants.SEND_IMAGE_CKEDITOR}`;
}
