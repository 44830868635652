//@flow
import * as React from "react";
import { FormGroup, Label, Input } from "reactstrap";

type Props = {
  label: string,
  name: string,
  value?: boolean,
  onChange: any
};

export class FormCheck extends React.Component<Props, {}> {
  render() {
    return (
      <FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              id={this.props.name}
              name={this.props.name}
              checked={this.props.value}
              onChange={this.props.onChange}
            />{" "}
            {this.props.label}
          </Label>
        </FormGroup>
      </FormGroup>
    );
  }
}
