//@flow
import _ from 'lodash';
import { urlsConstants } from './../_constants/urls.constant';
import { oauthHeaderJson } from './../_helpers/oauth-header';
import type { filtro } from './../_types/filtro.type';
import type { medico } from './../_types/medico.type';
import { defaultService } from './defaultService';

export const medicoService = {
  findAll,
  doSave,
  doList,
  doGet,
  doDelete,
  findByNome,
  findByFiltro,
  saveLocalHorarioAtendimento,
  findLocalHorarioAtendimento,
  deleteLocalHorarioAtendimento,
  findByEspecialidade,
  findMedicoByEspecialidade,
  findMedicoByEspecialidadePublic,
  findExistenteMedico,
  findByEspecialidadeOnline,
  findMedicoMarcacao,
  findEstabelecimentoPagamentoPendente,
  findByHorarioExame,
};

function findByHorarioExame(parent) {
  return defaultService.doGet(
    `${urlsConstants.MEDICO}FindAll/${parent.dataConsulta}/${parent.horario}/${parent.exame}`
  );
}

function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.MEDICO}${id}`);
}

function findLocalHorarioAtendimento(medico) {
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findLocalHorarioAtendimento/${medico.id}`
  );
}
function saveLocalHorarioAtendimento(medico, estabelecimentoMedicoVO) {
  return defaultService.doPost(
    `${urlsConstants.MEDICO}saveLocalHorarioAtendimento/${medico.id}`,
    estabelecimentoMedicoVO
  );
}

function deleteLocalHorarioAtendimento(idMedicoEstabelecimento: number) {
  return defaultService.doDelete(
    `${urlsConstants.MEDICO}deleteLocalHorarioAtendimento/${idMedicoEstabelecimento}`
  );
}

function findMedicoMarcacao(medicoFiltroMarcacaoVO) {
  return defaultService.doPost(
    `${urlsConstants.MEDICO}findMedicoMarcacao`,
    medicoFiltroMarcacaoVO
  );
}

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.MEDICO}list`,
    options,
    filter
  );
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.MEDICO}findAll`);
}

function doSave(medico: medico) {
  return defaultService.doSave(urlsConstants.MEDICO, medico);
}

function doList(filtro: filtro) {
  const requestOptions = {
    method: 'POST',
    headers: oauthHeaderJson(),
    body: JSON.stringify(filtro),
  };

  var p: Promise<any> = fetch(urlsConstants.MEDICO_LIST, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
  return p;
}

function doGet(id: number) {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeaderJson(),
  };
  var p: Promise<any> = fetch(urlsConstants.MEDICO + id, requestOptions).then(
    (response) => {
      return response.json();
    }
  );
  return p;
}

function findByNome(input, especialidade, estabelecimento) {
  if (!especialidade) {
    especialidade = 0;
  }
  if (!estabelecimento) {
    estabelecimento = 0;
  }
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findByNomeOuConselho/${input}/${especialidade}/${estabelecimento}`
  );
}
function findByEspecialidade(especialidade, estabelecimento) {
  if (!especialidade) {
    especialidade = 0;
  }
  if (!estabelecimento) {
    estabelecimento = 0;
  }
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findByEspecialidade/${especialidade}/${estabelecimento}`
  );
}

function findEstabelecimentoPagamentoPendente() {
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findEstabelecimentoPagamentoPendente`
  );
}

function findMedicoByEspecialidadePublic(
  especialidade,
  estabelecimento,
  values
) {
  if (!especialidade) {
    especialidade = 0;
  }
  if (!estabelecimento) {
    estabelecimento = 0;
  }
  let body;
  if (values) {
    body = _.cloneDeep(values);
  } else {
    body = {};
  }
  return defaultService.doPost(
    `${urlsConstants.MEDICO_PUBLIC}findMedicoByEstabelecimento/${estabelecimento}`,
    body
  );
}
function findMedicoByEspecialidade(especialidade, estabelecimento) {
  if (!especialidade) {
    especialidade = 0;
  }
  if (!estabelecimento) {
    estabelecimento = 0;
  }
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findMedicoByEspecialidade/${especialidade}/${estabelecimento}`
  );
}

function findByEspecialidadeOnline(especialidade, estabelecimento) {
  if (!especialidade) {
    especialidade = 0;
  }
  if (!estabelecimento) {
    estabelecimento = 0;
  }
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findByEspecialidadeOnline/${especialidade}/${estabelecimento}`
  );
}

function findExistenteMedico(text) {
  return defaultService.doGet(
    `${urlsConstants.MEDICO}findExistenteMedico/${text}`
  );
}
