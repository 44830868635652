//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ParamGeralPage } from './paramGeral/ParamGeralPage';
import TriagemListagemPage from './paramTriagem/TriagemListagemPage';
import TriagemEdit from './paramTriagem/TriagemEdit';
import TriagemView from './paramTriagem/TriagemView';

export default class ParametrizacaoIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/parametrizacao/triagem"
          exact
          component={TriagemListagemPage}
        />
        <Route path="/parametrizacao/triagem/novo" component={TriagemEdit} />
        <Route path="/parametrizacao/triagem/:id" component={TriagemView} />
        <Route path="/parametrizacao/geral" component={ParamGeralPage} />
      </Switch>
    );
  }
}
