//@flow
//@flow
import ptBR from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import moment from 'moment';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { registerLocale } from 'react-datepicker';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import {
  objectsConstants,
  objectsEnuns,
} from '../../../_constants/objects.constants';
import { dateHelper } from '../../../_helpers/date.helper';
import { translate } from '../../../_helpers/messages.helper';
import { defaultService } from '../../../_services/defaultService';
import { entradaEstoqueService } from '../../../_services/entradaEstoque.service';
import { fornecedorService } from '../../../_services/fornecedor.service';
import { FormGroup } from '../../../components/FormGroup';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import DateTimePicker from '../../../components/inputs/DateTimePicker';
import FormInput from '../../../components/inputs/FormInput';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import ProdutosEntradaSumario from './ProdutosEntradaSumario';
import ProdutoEntrada from './produtoEntrada/produtoEntrada';
import { legendHelper } from '../../../_helpers/legend.helper';
import TimePicker from '../../../components/inputs/TimePicker';
import { formatterHelper } from '../../../_helpers/formatter.helper';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};
const OBRIGATORIO = 'Obrigatório';
const hoje = new Date();
const amanha = hoje.setDate(hoje.getDate() + 1);
const STRING_REQUIRED = Yup.string().required(OBRIGATORIO);
const EstoqueEntradaValidate = Yup.object().shape({
  type: STRING_REQUIRED,
  tipoEntrada: STRING_REQUIRED,
  fornecedor: Yup.string().when('type', {
    is: objectsConstants.TIPO_ENTRADA_NOTA_FISCAL,
    then: STRING_REQUIRED,
  }),
  dataEmissaoNotaFiscal: Yup.date().when('type', {
    is: objectsConstants.TIPO_ENTRADA_NOTA_FISCAL,
    then: Yup.date()
      .required('Data de lançamento obrigatória')
      .max(new Date(), 'A data inserida não deve ser maior que hoje.'),
  }),
  horaLancamentoNota: Yup.string().when('type', {
    is: objectsConstants.TIPO_ENTRADA_NOTA_FISCAL,
    then: Yup.string().required('Hora de lançamento obrigatória'),
  }),
  tipoLancamentoNotaFiscal: Yup.string().when('type', {
    is: objectsConstants.TIPO_ENTRADA_NOTA_FISCAL,
    then: STRING_REQUIRED,
  }),
  numeroNotaFiscal: Yup.string().when('type', {
    is: objectsConstants.TIPO_ENTRADA_NOTA_FISCAL,
    then: STRING_REQUIRED,
  }),
  dataHoraLancamento: Yup.date().when('type', {
    is: objectsConstants.TIPO_ENTRADA_MANUAL,
    then: Yup.date()
      .required('Data de lançamento obrigatória')
      .max(new Date(amanha), 'A data inserida não deve ser maior que hoje.'),
  }),
  horaLancamento: Yup.string().when('type', {
    is: objectsConstants.TIPO_ENTRADA_MANUAL,
    then: Yup.string().required('Hora de lançamento obrigatória'),
  }),
  justificativa: Yup.string().when('type', {
    is: objectsConstants.TIPO_ENTRADA_MANUAL,
    then: STRING_REQUIRED,
  }),
  listEntradaEstoqueProduto: Yup.array()
    .min(1, 'É necessario informar pelo menos um produto')
    .of(
      Yup.object().shape({
        produto: STRING_REQUIRED,
        lote: Yup.object().shape({
          nome: STRING_REQUIRED,
          localizacao: STRING_REQUIRED,
          validade: Yup.date()
            .required('Data de validade obrigatória')
            .min(new Date(), 'A data inserida está vencida.'),
        }),
        custoUnitario: STRING_REQUIRED,
        quantidade: STRING_REQUIRED,
      })
    ),
});

class EstoqueEntradaForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  addProdutoEntrada = (
    propName,
    defaultValue,
    values,
    setValues,
    createAttribute?: boolean
  ) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName]) {
      newValues[propName].unshift(
        defaultValue
          ? defaultValue
          : { idList: new Date().getTime(), type: '1' }
      );
      setValues(newValues);
    }
    if (!newValues[propName] && createAttribute) {
      _.set(newValues, `${propName}`, [
        { idList: new Date().getTime(), type: '1' },
      ]);
      setValues(newValues);
    }
  };

  removeProdutoEntrada = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length >= 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };

  saveForm = () => {
    console.log('SVForm');

    let _this = this;
    let values = _this.formRef.state.values;
    let newValues = _.cloneDeep(values);

    let promise = new Promise(function (resolve) {
      console.log(values);
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          if (_.get(values, `type`) != 0) {
            const horaLancamento = _.get(values, 'horaLancamento');
            let horarios = horaLancamento.split(':');
            let dt = _.get(values, `dataHoraLancamento`);
            let date = new Date(dt);
            date.setHours(horarios[0]);
            date.setMinutes(horarios[1]);

            _.set(
              newValues,
              'dataHoraLancamento',
              moment(date).format('YYYY-MM-DD HH:mm:ss')
            );
          }
          if (_.get(values, `type`) == 0) {
            const horaLancamentoNota = _.get(values, 'horaLancamentoNota');
            let horariosNota = horaLancamentoNota.split(':');
            let dt = _.get(values, `dataEmissaoNotaFiscal`);
            let date = new Date(dt);
            date.setHours(horariosNota[0]);
            date.setMinutes(horariosNota[1]);
            _.set(
              newValues,
              'dataEmissaoNotaFiscal',
              moment(date).format('YYYY-MM-DD HH:mm:ss')
            );
            newValues = _.omit(newValues, 'dataHoraLancamento');
          }
          _this.props.loading(true);
          entradaEstoqueService.doSave(newValues).then(
            (response) => {
              console.log('doSave');
              _this.props.success({
                message: `Entrada, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                _this.props.refreshUser(values);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar Entrada.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          console.log(error);
          resolve(false);
        }
      });
    });
    return promise;
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  componentDidMount() {
    registerLocale('pt-BR', ptBR);
  }

  // formataTipoEntrada = (setFieldValue, name, value) => {
  //   let type = null;
  //   console.log(value);
  //   if (_.isEqual(value, objectsConstants.TIPO_ENTRADA_NOTA_FISCAL)) {
  //     type = '0';
  //   }
  //   if (_.isEqual(value, objectsConstants.TIPO_ENTRADA_MANUAL)) {
  //     type = '1';
  //   }
  //   setFieldValue('type', type);
  //   setFieldValue(name, value);
  // };

  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EstoqueEntradaValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-xl-2">
                    {/* {!viewMode && ( */}
                    <InputViewEdit
                      component={SelectInput}
                      options={objectsEnuns.TIPO_ENTRADA_NOTA_FISCAL}
                      type="text"
                      name="type"
                      label="Tipo"
                      placeholder="Selecione "
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      noSize={true}
                      viewMode={viewMode || values.id}
                      id="type"
                      returnFullObject={false}
                      labelKey={'label'}
                      valueKey={'value'}
                      multi={false}
                      value={values.type}
                      erroMensagem={errors.type}
                      required={true}
                      defaultValue={
                        _.get(values, 'type') == '0' ? 'Nota Fiscal' : 'Manual'
                      }
                    />
                    {/* )} */}
                    {/* {viewMode && (
                      <>
                        <label>Tipo</label>
                        <p className="ml-1">
                          {values.type == '0' ? 'NOTA FISCAL' : 'MANUAL'}
                        </p>
                      </>
                    )} */}
                  </FormGroup>

                  {values.id && (
                    <FormGroup className="col-12 col-xl-2">
                      <InputViewEdit
                        component={SelectInput}
                        options={objectsEnuns.SITUACAO_ENTRADA_ESTOQUE}
                        type="text"
                        name="situacao"
                        label="Situação"
                        placeholder="Selecione "
                        onChange={(name, value) => {
                          console.log(value);
                          setFieldValue(name, value);
                        }}
                        noSize={true}
                        viewMode={viewMode}
                        id="situacao"
                        returnFullObject={false}
                        labelKey={'label'}
                        valueKey={'value'}
                        multi={false}
                        value={values.situacao}
                        erroMensagem={errors.situacao}
                        required={true}
                        defaultValue={_.get(values, 'situacao')}
                      />
                    </FormGroup>
                  )}
                  {_.get(values, 'type') && (
                    <>
                      <FormGroup className="col-12 col-xl-2">
                        <InputViewEdit
                          component={SelectInput}
                          options={objectsEnuns.TIPO_ENTRADA}
                          type="text"
                          name="tipoEntrada"
                          label="Tipo de entrada"
                          placeholder="Selecione "
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          noSize={true}
                          viewMode={viewMode}
                          id="tipoEntrada"
                          returnFullObject={false}
                          labelKey={'label'}
                          valueKey={'value'}
                          multi={false}
                          value={values.tipoEntrada}
                          erroMensagem={errors.tipoEntrada}
                          required={true}
                          defaultValue={
                            _.get(values, 'tipoEntrada')
                              ? legendHelper.isTipoEntrada(
                                  _.get(values, 'tipoEntrada')
                                )
                              : '--'
                          }
                        />
                      </FormGroup>
                    </>
                  )}
                </div>

                {_.get(values, 'type') &&
                  values.type == objectsConstants.TIPO_ENTRADA_MANUAL && (
                    <>
                      <div className="row section-form">
                        <FormGroup className="col-12 col-xl-3">
                          <InputViewEdit
                            component={DatePickerInput}
                            label={'Data'}
                            type="text"
                            id="dataHoraLancamento"
                            name="dataHoraLancamento"
                            placeholder=""
                            value={values.dataHoraLancamento}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            erroMensagem={errors.dataHoraLancamento}
                            viewMode={viewMode}
                            required={true}
                            viewClassName={'font-weight-bold'}
                            defaultValue={
                              _.get(values, `dataHoraLancamento`)
                                ? dateHelper.format(
                                    _.get(values, `dataHoraLancamento`),
                                    {
                                      mode: 'DATE',
                                    }
                                  )
                                : '--'
                            }
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-xl-2">
                          <InputViewEdit
                            label="Hora do lançamento"
                            format={'HH:mm'}
                            component={DateTimePicker}
                            defaultValue={
                              _.get(values, `horaLancamento`)
                                ? _.get(values, `horaLancamento`)
                                : '--'
                            }
                            name={`horaLancamento`}
                            id={`horaLancamento`}
                            erroMensagem={_.get(errors, `dataHoraLancamento`)}
                            value={values.horaLancamento}
                            onChange={setFieldValue}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            placeholder={'--:--'}
                            timeIntervals={30}
                            viewMode={viewMode}
                            defaultValue={
                              _.get(values, `horaLancamento`)
                                ? _.get(values, `horaLancamento`)
                                : '--'
                            }
                            required
                          />
                        </FormGroup>
                      </div>
                      <div className="row section-form">
                        <FormGroup className="col-12 col-xl-10">
                          <InputViewEdit
                            component={FormInput}
                            label={'Justificativa'}
                            type="text"
                            id="justificativa"
                            defaultValue={_.get(values, 'justificativa')}
                            name="justificativa"
                            placeholder="Informe uma justificativa"
                            value={values.justificativa}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            viewMode={viewMode}
                            erroMensagem={errors.justificativa}
                            required={true}
                          />
                        </FormGroup>
                      </div>
                    </>
                  )}

                {/* tipo Nota Fiscal */}
                {_.get(values, 'type') &&
                  values.type == objectsConstants.TIPO_ENTRADA_NOTA_FISCAL && (
                    <>
                      <div className="row section-form">
                        <FormGroup className="col-12 col-xl-3">
                          <InputViewEdit
                            component={DatePickerInput}
                            label="Data de emissão da nota fiscal"
                            name={`dataEmissaoNotaFiscal`}
                            erroMensagem={_.get(
                              errors,
                              `dataEmissaoNotaFiscal`
                            )}
                            id={`dataEmissaoNotaFiscal`}
                            value={values.dataEmissaoNotaFiscal}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            placeholder={'--/--/----'}
                            required={true}
                            viewMode={viewMode}
                            defaultValue={
                              _.get(values, `dataEmissaoNotaFiscal`)
                                ? dateHelper.format(
                                    _.get(values, `dataEmissaoNotaFiscal`),
                                    {
                                      mode: 'DATE-TIME',
                                    }
                                  )
                                : '--'
                            }
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-xl-2">
                          <InputViewEdit
                            label="Hora do lançamento"
                            format={'HH:mm'}
                            component={DateTimePicker}
                            defaultValue={
                              _.get(values, `horaLancamentoNota`)
                                ? _.get(values, `horaLancamentoNota`)
                                : '--'
                            }
                            name={`horaLancamentoNota`}
                            id={`horaLancamentoNota`}
                            erroMensagem={_.get(
                              errors,
                              `dataEmissaoNotaFiscal`
                            )}
                            value={values.horaLancamentoNota}
                            onChange={setFieldValue}
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            placeholder={'--:--'}
                            timeIntervals={30}
                            viewMode={viewMode}
                            defaultValue={
                              _.get(values, `horaLancamentoNota`)
                                ? _.get(values, `horaLancamentoNota`)
                                : '--'
                            }
                            required
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-xl-3">
                          <InputViewEdit
                            label={'Fornecedor'}
                            // viewLabelClassName="d-none"
                            viewClassName="font-weight-bold"
                            placeholder="Informe o fornecedor"
                            component={FormSelectInput}
                            service={fornecedorService.findByEstabelecimento}
                            labelKey={'nome'}
                            valueKey={'id'}
                            returnFullObject={true}
                            defaultValue={_.get(
                              values,
                              'fornecedor.nome',
                              '--'
                            )}
                            required={true}
                            value={_.get(values, 'fornecedor')}
                            viewMode={viewMode}
                            id="fornecedor"
                            name="fornecedor"
                            onChange={(name, value) => {
                              if (!value.id) {
                                fornecedorService
                                  .doSave(value)
                                  .then((response) => {
                                    let id = defaultService.getIdFromUrl(
                                      response.headers.location
                                    );
                                    _.set(value, 'id', id);
                                    setFieldValue(name, value);
                                  });
                              } else {
                                setFieldValue(name, value);
                              }
                            }}
                            erroMensagem={_.get(errors, 'fornecedor')}
                            sortNoAsc={true}
                            sortKey={'nome'}
                            creatable={true}
                          />
                        </FormGroup>
                      </div>
                      <div className="row section-form">
                        <FormGroup className="col-12 col-xl-2">
                          <InputViewEdit
                            component={SelectInput}
                            options={objectsEnuns.TIPO_NOTA_FISCAL}
                            type="text"
                            name="tipoLancamentoNotaFiscal"
                            label="Tipo de nota fiscal"
                            placeholder="Selecione  um tipo"
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                            }}
                            noSize={true}
                            viewMode={viewMode}
                            id="tipoLancamentoNotaFiscal"
                            returnFullObject={false}
                            labelKey={'label'}
                            valueKey={'value'}
                            multi={false}
                            value={values.tipoLancamentoNotaFiscal}
                            erroMensagem={errors.tipoLancamentoNotaFiscal}
                            required={true}
                            defaultValue={
                              _.get(values, 'tipoLancamentoNotaFiscal')
                                ? _.get(values, 'tipoLancamentoNotaFiscal')
                                : objectsConstants.TIPO_NOTA_FISCAL_COMUM
                            }
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-xl-3">
                          <InputViewEdit
                            component={FormInput}
                            label={'Número ou chave da nota fiscal'}
                            type="text"
                            id="numeroNotaFiscal"
                            defaultValue={_.get(values, 'numeroNotaFiscal')}
                            name="numeroNotaFiscal"
                            placeholder="Informe o número ou chave da nota fiscal"
                            value={values.numeroNotaFiscal}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            viewMode={viewMode}
                            erroMensagem={errors.numeroNotaFiscal}
                            required={true}
                          />
                        </FormGroup>
                      </div>
                    </>
                  )}
                {_.get(values, 'type') && (
                  <>
                    <div className="d-flex align-items-center justify-content-center">
                      <ProdutosEntradaSumario entity={values} />
                    </div>
                    {/* <h1 className="w-100">Unidades fração</h1> */}

                    <div
                      className={`form-row justify-content-md-start justify-content-center pl-10px ${
                        viewMode ? 'd-none' : ''
                      }`}
                    >
                      <button
                        onClick={() =>
                          this.addProdutoEntrada(
                            'listEntradaEstoqueProduto',
                            null,
                            values,
                            setValues,
                            true
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                      >
                        Adicionar novo produto
                      </button>
                    </div>

                    {values.listEntradaEstoqueProduto &&
                      values.listEntradaEstoqueProduto.map((produto, index) => {
                        return (
                          <div className="row section-form" key={index}>
                            <ProdutoEntrada
                              entity={values}
                              index={index}
                              viewMode={viewMode}
                              size={values.listEntradaEstoqueProduto.length}
                              handleChange={handleChange}
                              errors={errors}
                              propName={`listEntradaEstoqueProduto[${index}]`}
                              onChange={setFieldValue}
                              removeItem={() => {
                                this.removeProdutoEntrada(
                                  `listEntradaEstoqueProduto`,
                                  index,
                                  values,
                                  setValues
                                );
                              }}
                            ></ProdutoEntrada>
                          </div>
                        );
                      })}
                    {values.listEntradaEstoqueProduto &&
                      values.listEntradaEstoqueProduto.length < 1 && (
                        <div className="d-flex align-items-center justify-content-center txt-red">
                          <p>É necessário informar ao menos um produto!</p>
                        </div>
                      )}
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),

  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(EstoqueEntradaForm);
