//@flow
//@flow
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DadosUser from '../../../components/DadosUser/DadosUser';
import HorarioRow from '../../../components/horarios/HorarioRow';
import { objectsConstants } from '../../../_constants/objects.constants';
import "./FilaAtendimentoRow.scss"
import ButtonAutorized from "../../../components/buttons/ButtonAutorized";
import {formatterHelper} from "../../../_helpers/formatter.helper";
import moment from 'moment';
type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
  marcacao: any,
};

type State = {
  id?: number,
};

export class FilaAtendimentoRowPacienteSemAgenda extends React.Component<Props, State> {
  render() {
    console.log(this.props)
    return (
      <div className='row list-pacientes-espera justify-content-center align-items-center text-center p-4'>
          <div className=" col-2">
               Paciente não cadastrado
          </div>
        <div className=" col-2">
            CPF: {formatterHelper.cpfCnpjFormatter(this.props.marcacao.cpf)}
         </div>

          <div className=" col-2">Senha: <b> {this.props.marcacao.senha}</b></div>
            <div className=" col-2">Chegou: <b> {this.props.marcacao.horarioChegada}</b></div>
          
          <div  className="col-2">Aguardando a <b>  {moment.duration(moment().diff(moment(this.props.marcacao.horarioChegada, ['h:m a', 'H:m']))).hours()  } horas e  {moment.duration(moment().diff(moment(this.props.marcacao.horarioChegada, ['h:m a', 'H:m']))).minutes()  } minutos</b></div>

          
          <div className='col-2'>
                <ButtonAutorized
                    className="btn-primary open"
                    label={'Chamar paciente'}
                    role={objectsConstants.AGENDA_COMPARECER}
                    onClick={() => {
                      this.props.onStatusChanged(
                          this.props.marcacao,
                          objectsConstants.AGUARDANDO_ATENDIMENTO,
                          true,
                          this.props.estabelecimento
                      );
                    }}
                ></ButtonAutorized>
          </div>
        
      </div>
    )
  };
}