//@flow
import { Formik } from 'formik';
import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'reactstrap';
import * as Yup from 'yup';
import FormInput from '../../components/inputs/FormInput';

const NovaSenhaValidateModel = Yup.object().shape({
  senha: Yup.string().required('Obrigatório'),
  confirmaSenha: Yup.string()
    .required('Obrigatório')
    .oneOf([Yup.ref('senha'), null], 'Senhas diferentes'),
});

type State = {
  senha?: string,
  confirmaSenha?: string,
};

class NovaSenhaForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let senha = '';
    let confirmaSenha = '';
    this.state = { senha, confirmaSenha };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  salvarNovaSenha = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    let _this = this;
    let values = _this.formRef.state.values;

    _this.formRef.validateForm(values).then((error) => {
      console.log(error);
      if (_.isEmpty(error)) {
        let values = _this.formRef.state.values;
        this.props.salvarNovaSenha(values.senha);
      } else {
        console.log('error');
      }
    });
  };

  render() {
    const { onSubmit } = this.props;
    return (
      <Formik
        validationSchema={NovaSenhaValidateModel}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.state}
        ref={(form) => {
          this.formRef = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Label for="senha">Nova senha</Label>
            <FormInput
              name={'senha'}
              id={'senha'}
              value={values.senha}
              type={'password'}
              onChange={setFieldValue}
              placeholder="Insira sua nova senha"
              maxLength={30}

              viewPassword={true}
              erroMensagem={errors.senha}
            />
            <Label>* A senha deve ter entre 3 a 30 digitos.</Label>
            <br/>
            <Label for="senha">Repita sua nova senha</Label>
            <FormInput
              name={'confirmaSenha'}
              id={'confirmaSenha'}
              value={values.confirmaSenha}
              type={'password'}
              onChange={setFieldValue}
              placeholder="Repita sua nova senha"
              maxLength={30}
              viewPassword={true}
              erroMensagem={errors.confirmaSenha}
            />

            <div className="d-flex justify-content-center align-items-center">
              <Button
                className="bt-size-fixed mt-4 my-xl-4"
                block={true}
                color="primary"
                type="submit"
                onClick={this.salvarNovaSenha}
              >
                Enviar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load }),
});
const connectedLoginForm = connect(mapStateToProps, mapDispatch)(NovaSenhaForm);
export { connectedLoginForm as NovaSenhaForm };