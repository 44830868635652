import * as React from "react";
import SelectInput from "../../components/inputs/SelectInput";
import { OptionUsuario } from "../../components/inputs/OptionUsuario";
import _ from "lodash";
import { pacienteService } from "../../_services/paciente.service";

export default class RemarcaçãoInput extends React.Component<{}> {
  constructor(props) {
    super(props);

  }

  
  render() {
    const {filtro,onChange} = this.props;
    return (
      <div className="row">
        <div className="col-md-4 col-12">
      <label> Para remarcação selecione o paciente</label>
        <div class="input-group mb-3">
           
        <SelectInput
        id={'pacienteFiltro'}
        name="paciente"
        size={12}
        onFetchData={pacienteService.findByNomeOuCPF}
        onChange={onChange}
        value={_.get(filtro,'paciente')}
        labelKey="nome"
        multi={false}
        returnFullObject={true}
        creatable={false}
        required={false}
        components={{ Option: OptionUsuario }}
        placeholder={
          'Selecione o paciente para remarcação da agenda'
        }
        
      />

      </div>
      </div>
      </div>
    
    
    );
  }
}
