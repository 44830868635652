//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConvenioEdit from './ConvenioEdit';
import ConvenioListagemPage from './ConvenioListagemPage';
import ConvenioView from './ConvenioView';

export default class ConvenioIndex extends React.Component<{}> {
    render() {
      return (
        <Switch>
          <Route path="/convenio" component={ConvenioListagemPage} exact />
          <Route path="/convenio/novo" component={ConvenioEdit} />
          <Route path="/convenio/view/:id" component={ConvenioView} />
        </Switch>
      );
    }
  }
  