//@flow
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactHtmlParser from 'react-html-parser';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { translate } from '../../../_helpers/messages.helper';
import { defaultService } from '../../../_services/defaultService';
import { prontuarioService } from '../../../_services/prontuario.service';
import { prontuarioReceituarioService } from '../../../_services/prontuarioReceituario.service';
import { protocoloAtendimentoService } from '../../../_services/protocoloAtendimento.service';
import MedicamentoPrescrito from '../../../components/MedicamentoPrescrito/MedicamentoPrescrito';
import ModalImpressao from '../../../components/Modal/ModalImpressao';
import { ModalSolutiOtp } from '../../../components/Modal/ModalSolutiPasswordOtp';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import FormTextCKEditor from '../../../components/inputs/FormTextCKEditor';
import InputViewEdit from '../../../components/inputs/InputViewEdit';

type Props = {
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const AnamneseValidate = Yup.object().shape({
  //   nome: Yup.string()
  //     .min(10, 'Nome muito curto!')
  //     .max(60, 'Nome muito grande!')
  //     .required('Obrigatório'),
  //   cpfCnpj: Yup.string().required('Obrigatório'),
  //   dataNascimento: Yup.date().required('Obrigatório'),
  //   email: Yup.string().required('Obrigatório').email('Email inválido'),
});

class ReceituarioForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openAnexo: false,
      index: null,
      modalImpressao: false,
      openProtocol: false,
      openSolutiOtp: false,
    };
    // console.log(this.props.receituarioON);
  }

  toggleSaveModelAnamnese = () => {
    this.setState(({ openSaveModelAnamnese }) => ({
      openSaveModelAnamnese: !openSaveModelAnamnese,
    }));
  };

  saveForm = () => {
    const { callbackOnSave, atendimentoEmAtendimento } = this.props;
    let _this = this;
    let values = _this.formRef.state.values;
    _.unset(values, 'modelo');
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          if (atendimentoEmAtendimento) {
            values.fkMarcacao = atendimentoEmAtendimento.idMarcacao;
          }
          prontuarioService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Prontuário, foi salvo com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              if (callbackOnSave) {
                callbackOnSave(values, atendimentoEmAtendimento);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar prontuário.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };
  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  imprimeAnamnese = (values) => {
    this.props.loading(true);
    prontuarioService.imprimir(values).then(
      (response) => {
        var base64 = _.get(response, 'data.data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
      }
    );
  };

  editMedicamento = (index) => {
    this.setState({ index });
  };

  imprimir = (documentSignVO) => {
    const { marcacao, prontuarioReceituario } = this.props.values;
    let documentoPrint = {};
    this.props.loading(true);
    _.set(documentoPrint, 'documentSignVO', documentSignVO);
    _.set(documentoPrint, 'marcacaoVO', marcacao);
    _.set(documentoPrint, 'prescricao', prontuarioReceituario.prescricao);

    prontuarioReceituarioService.imprimir(documentoPrint).then(
      (response) => {
        var base64 = _.get(response, 'data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this.props.loading(false);
        window.open(fileURL);
      },
      (error) => {
        if (error.response.status == 404) {
          this.props.error({
            message:
              'Não existe nenhum modelo de receituário vinculado ao medico. Procure o Administrador do sistema.',
          });
        } else {
          console.log(error.response);
          console.log(documentSignVO);
          console.log(documentSignVO && documentSignVO.signDocument);
          if (documentSignVO && documentSignVO.signDocument) {
            this.props.error({
              message: this.treatSignerSignExceptionGetMessage(
                error.response.data
              ),
            });
          } else {
            this.props.error({
              message:
                'Não foi possivel imprimir documento, contate o administrador do sistema.',
            });
          }
        }
        this.props.loading(false);
      }
    );
  };

  treatSignerSignExceptionGetMessage = (data) => {
    if ('9001' === data.errorCode) {
      return 'Não foi possível assinar o documento por falta de informações. Complete seu cadastro completo para assinar o documento corretamente. (Endereço, Telefone, Conselho).';
    } else if ('9002' === data.errorCode) {
      return 'Seu código OTP não confere. Por favor valide corretamente o mesmo, junto com seu tempo de expiração e tente novamente.';
    } else {
      return 'Erro desconhecido ao assinar o seu documento. Por favor tente novamente em instantes, caso o erro persista entre em contato com o administrador do sistema.';
    }
  };
  assinarImprimir = () => {
    this.setState(({ openSolutiOtp }) => ({ openSolutiOtp: !openSolutiOtp }));
  };

  toggleProtocolo = () => {
    this.setState({ openProtocol: !this.state.openProtocol });
  };

  handleSetOtpCode = (otpCode) => {
    if (otpCode) {
      if (!/^[0-9]+$/.test(otpCode)) {
        this.props.error({
          message: 'O código OTP são somente números',
        });
      } else {
        this.toggleSolutiOtp();
        this.imprimir({
          otpCode: otpCode,
          signDocument: true,
          fileType: 'MEDICAL_PRESCRIPTION',
        });
      }
    } else {
      this.props.error({
        message: 'Insira o seu código otp',
      });
    }
  };
  // getReceituarioItem = (
  //   values,
  //   index,
  //   viewMode,
  //   setFieldValue,
  //   setValues,
  //   errors,
  //   receituarioItem,
  //   handleChange
  // ) => {
  //   if (receituarioItem.type === objectsConstants.TIPO_MEDICAMENTO) {
  //     return (
  //       <MedicamentoPrescrito
  //         size={values.listReceituarioItem.length}
  //         entity={values}
  //         index={index}
  //         viewMode={viewMode}
  //         propName={`listReceituarioItem[${index}]`}
  //         onChange={setFieldValue}
  //         removeItem={() => {
  //           this.removeItemInArray(
  //             `listReceituarioItem`,
  //             index,
  //             values,
  //             setValues
  //           );
  //         }}
  //         errors={errors}
  //       ></MedicamentoPrescrito>
  //     );
  //   }
  // };
  toggleSolutiOtp = () => {
    this.setState(({ openSolutiOtp }) => ({ openSolutiOtp: !openSolutiOtp }));
  };
  render() {
    const {
      viewMode,
      values,
      setValues,
      setFieldValue,
      atendimentoEmAtendimento,
      ...otherProps
    } = this.props;
    return (
      <React.Fragment>
        <div
          className={` mt-2 receituario ${
            this.props.show == 0 ? '' : 'd-none'
          }`}
        >
          {!viewMode && (
            <div className={`form-row ${viewMode ? 'd-none' : ' '}`}>
              <div className="col-12 mt-2">
                <InputViewEdit
                  label="Modelo de Receituário"
                  placeholder="Selecione um protocolo já salvo para o receituário"
                  component={FormSelectInput}
                  service={protocoloAtendimentoService.findByMedico}
                  labelKey={'nome'}
                  valueKey={'id'}
                  returnFullObject={true}
                  defaultValue={_.get(
                    values,
                    `prontuarioReceituario.protocolo`,
                    '--'
                  )}
                  required={false}
                  value={_.get(values, `prontuarioReceituario.protocolo`, '--')}
                  name={`protocolo`}
                  viewMode={viewMode}
                  id={`protocolo`}
                  onChange={(name, value) => {
                    this.props.loading(true);
                    protocoloAtendimentoService.getReceituario(value.id).then(
                      (response) => {
                        let prontuarioReceituario = response.data.data;
                        console.log(prontuarioReceituario);
                        if (prontuarioReceituario) {
                          prontuarioReceituario.id = null;
                          prontuarioReceituario.protocolo = value;
                          let newValues = _.cloneDeep(this.props.values);
                          _.set(
                            newValues,
                            'prontuarioReceituario',
                            prontuarioReceituario
                          );

                          setValues(newValues);
                        } else {
                          setValues({});
                        }

                        this.props.loading(false);
                      },
                      (error) => {
                        this.props.loading(false);
                      }
                    );
                  }}
                />
              </div>
            </div>
          )}
          <ModalSolutiOtp
            id="solutiOtp"
            name="solutiOtp"
            toogle={this.toggleSolutiOtp}
            isOpen={this.state.openSolutiOtp}
            handleSetOtpCode={(otpCode) => {
              this.handleSetOtpCode(otpCode);
            }}
          />
          {!viewMode && (
            <MedicamentoPrescrito
              viewMode={this.props.viewMode}
              values={values}
              setFieldValue={setFieldValue}
              index={this.state.index}
            />
          )}
          <label>Descrição Receituário:</label>
          <div className="ml-3 mt-2 mb-5 descricao-anamnese">
            {!viewMode && (
              <FormTextCKEditor
                id={'prescricao'}
                name={'prontuarioReceituario.prescricao'}
                // description={"posologia.descricao"}
                className={'textCKeditor'}
                type={'textarea'}
                disabled={false}
                required={false}
                onChange={setFieldValue}
                viewMode={this.props.viewMode}
                onInit={(editor) => {}}
                viewClassName={'view-anamnese'}
                value={_.get(values, 'prontuarioReceituario.prescricao', '')}
                edit={this.editMedicamento}
                defaultValue={values.prescricao}
                placeholder={'Escreva aqui a descrição do receituário'}
                removePlugins={[
                  'Heading',
                  'BlockQuote',
                  'ImageUpload',
                  'MediaEmbed',
                ]}
              />
            )}
            {viewMode &&
              ReactHtmlParser(
                _.get(values, 'prontuarioReceituario.prescricao', '')
              )}
          </div>
          <div
            className={`form-row d-flex buttons-bottom
                  ${
                    !values.protocoloAtendimentoReceituario
                      ? 'justify-content-between'
                      : 'justify-content-end'
                  }`}
          >
            {values.id &&
              !this.props.history &&
              !values.protocoloAtendimentoReceituario && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.toggleProtocolo}
                >
                  Salvar como Modelo de Receituário
                </button>
              )}
            {values.prontuarioReceituario &&
              values.prontuarioReceituario.prescricao && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={this.assinarImprimir}
                  >
                    <span className="icon-btn-imprimir"></span>
                    Assinar e imprimir
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={this.imprimir}
                  >
                    <span className="icon-btn-imprimir"></span>
                    Imprimir
                  </button>
                </React.Fragment>
              )}
            {this.state.modalImpressao && (
              <ModalImpressao
                title="Impressão receituário"
                toogle={this.toggleModalImpressao}
                isOpen={this.state.openProtocolo}
                target={'impressao'}
                entity={values.prontuarioReceituario}
                marcacao={values.marcacao}
                onCancel={this.toggleModalImpressao}
                callbackOnSave={(valuesPrint) => {}}
                {...otherProps}
              ></ModalImpressao>
            )}
          </div>
          {/* )} */}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ReceituarioForm);
