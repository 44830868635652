//@flow
import axios from 'axios';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { defaultService } from '../../_services/defaultService';
import ExportExcelButton from '../buttons/ExportExcelButton';
import DataTable from '../table/DataTable';
import sortIcon from './../../images/icones/arrow-down.svg';
import { objectsConstants } from './../../_constants/objects.constants';
import HeaderList from './HeaderList';
let download = require('downloadjs');

type Props = {
  title?: string,
  children: any,
  FormFilter?: any,
  newButtonActionClick: Function | string,
  showNewButton: boolean,
  defaultOrder: string,
  classSignal: string,
  defaultDirection: string,
  service: any,
  deleteSuccess: string,
  labelNewButton: string,
  defaultFilter: any,
  onRowClick: any,
  noFilter?: boolean,
};

type State = { filter: any, filterForm: any, submitFilter: boolean };

class ListagemPage extends React.Component<Props, State> {
  static defaultProps = {
    defaultOrder: 'id',
    showNewButton: true,
    deleteSuccess: 'Registro excluído com sucesso.',
    forceUpdate: false,
    loadOnInit: true,
  };

  _datatable: any;
  _source: any;

  constructor(props: any) {
    super(props);

    this._source = axios.CancelToken.source();
    let filter =
      this.props.filter && this.props.controller === this.props.page
        ? this.props.filter
        : this.props.defaultFilter
        ? this.props.defaultFilter
        : objectsConstants.defaultFilter;

    this.state = {
      filter,
      filterForm: {},
      submitFilter: false,
      openSearch: false,
    };
  }

  handleValue = (value: any, name: string) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    const newFilter = _.cloneDeep(this.state.filter);
    _.set(newFilter, name, type === 'checkbox' ? checked : value);
    this.setState({ filter: newFilter, submitFilter: false });
  };
  handleSelectChange = (name: string, option: any) => {
    const { filter } = this.state;
    let newfilter = _.cloneDeep(filter);
    _.set(newfilter, name, option);
    this.setState({ filter: newfilter, submitFilter: false });
  };
  handleFilter = (e: SyntheticEvent<>) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      filterForm: Object.assign({}, this.state.filter),
      submitFilter: true,
    });
  };

  toggle = () => {
    this.setState({
      openSearch: !this.state.openSearch,
    });
  };

  closeSearch = () => {
    this.setState({
      openSearch: false,
    });
    this.limpaPesquisa();
  };
  handleClick = () => {
    this.props.loading(true);
    defaultService
      .exportToExcel(
        this.props.controller,
        this.state.filter,
        this.props.fields,
        this.props.labels
      )
      .then(
        (response) => {
          download(
            response.data,
            (this.props.downloadExcelName
              ? this.props.downloadExcelName
              : this.props.controller) + '.csv',
            'text/html'
          );
          this.props.loading(false);
        },
        () => {
          this.props.loading(false);
          this.props.error({ message: 'Não foi possível exportar arquivo.' });
        }
      );
  };

  componentWillUnmount() {
    this._source.cancel('Operation canceled due component being unmounted.');
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.defaultFilter &&
      !_.isEqual(nextProps.defaultFilter, this.props.defaultFilter)
    ) {
      this.setState({ filter: nextProps.defaultFilter }, () => {
        !this.props.notUpdateOnChangeFilter &&
          this._datatable.getWrappedInstance().updateTable();
      });
    } else if (nextProps.forceUpdate) {
      this._datatable.getWrappedInstance().updateTable();
    }
    this.setState({ submitFilter: false });
  }

  updateTable = () => {
    this._datatable.getWrappedInstance().updateTable();
  };

  limpaPesquisa = () => {
    console.log('limpar pesquisa');

    this.setState({
      filter: {},
      filterForm: {},
      submitFilter: true,
    });
  };

  render() {
    const {
      newButtonActionClick,
      showNewButton,
      labelNewButton,
      title,
      FormFilter,
      roleNewButton,
      FormCardListagem,
      hideExport,
      validationModal,
    } = this.props;
    return (
      <div className={'listagem-custom d-flex flex-column'}>
        <HeaderList
          title={title}
          labelNewButton={labelNewButton}
          actionClick={newButtonActionClick}
          showNewButton={showNewButton}
          filter={this.state.filter}
          controller={this.props.controller}
          fields={this.props.fields}
          labels={this.props.labels}
          toogleSearch={this.toggle}
          isOpen={this.state.openSearch}
          roleNewButton={roleNewButton}
          hideExport={hideExport}
          formFilter={FormFilter}
        />
        <div className="conteudo-listagem">
          {FormFilter && (
            <Collapse isOpen={this.state.openSearch}>
              <div className="search-box form-inside">
                {/* <div className="d-flex w-100 justify-content-between">
                  <div className="subtitulo">Pesquisa</div>
                  <div
                    className="close-search mr-2"
                    onClick={this.closeSearch}
                  ></div>
                </div> */}
                <div className="form-row w-100 section-form justify-content-start">
                  <FormFilter
                    onChangeSelect={this.handleSelectChange}
                    onChangeText={this._handleChangeText}
                    onChangeValue={this.handleValue}
                    onChangeDate={this._handleChangeData}
                    entity={this.state.filter}
                  />
                  <div className="py-0 py-lg-3 w-100 col-12 text-center text-lg-right">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.limpaPesquisa}
                    >
                      Limpar pesquisa
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary open"
                      onClick={this.handleFilter}
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          )}

          {FormCardListagem && (
            <FormCardListagem
              filter={this.state.filter}
              updateTable={this.updateTable}
            />
          )}
          <div className="action-line">
            <div className="d-flex justify-content-around justify-content-xl-between align-items-center w-100 action-topo-tabela">
              <span className="labelInfoGrid">
                <b>
                  {this.props.subTitle} {this.props.title}
                </b>
              </span>
              <div className="d-flex flex-row justify-content-center justify-sm-content-end">
                <ExportExcelButton onClick={this.handleClick} />
              </div>
            </div>
          </div>
          <DataTable
            ref={(datatable) => (this._datatable = datatable)}
            defaultOrder={this.props.defaultOrder}
            defaultDirection={this.props.defaultDirection}
            service={this.props.service}
            classSignal={this.props.classSignal}
            filter={this.state.filter}
            onRowClick={this.props.onRowClick}
            submitFilter={this.state.submitFilter}
            controller={this.props.controller}
            loadOnInit={this.props.loadOnInit}
            sortIcon={sortIcon}
            columns={this.props.columns}
            trStyle={this.props.trStyle}
            selectRow={this.props.selectRow}
          >
            {this.props.children}
          </DataTable>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { filter, page } = state.defaultFilter;
  const { subTitle } = state.listTitle;

  return {
    filter,
    page,
    subTitle,
  };
}
const mapDispatch = ({ alert: { error, success }, load: { loading } }) => ({
  error: (message) => error({ message }),
  success: (message) => success({ message }),
  loading: (load: boolean) => loading({ load }),
});

export default connect(mapStateToProps, mapDispatch, null)(ListagemPage);
