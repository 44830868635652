//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import Avatar from '../../components/Avatar/Avatar';
import EspecialidadesSelectInput from '../../components/especialidades/EspecialidadesSelectInput';
import { FormGroup } from '../../components/FormGroup';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import DatePickerInput from '../../components/inputs/DatePickerInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import MultiTelefones from '../../components/MultiTelefones/MultiTelefones';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { translate } from '../../_helpers/messages.helper';
import { conselhoService } from '../../_services/conselho.service';
import { tituloService } from '../../_services/titulo.service';
import { defaultService } from '../../_services/defaultService';
import { medicoService } from '../../_services/medico.service';
import ApiIBGE from '../../_services/uf.service';
import { userService } from '../../_services/user.service';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const MedicoValidate = Yup.object().shape({
  nome: Yup.string()
    .min(10, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Obrigatório')
    .matches(/^[A-ZÀ-Úa-zà-ú\s.]+$/, 'Somente letras são aceitas'),
  cpfCnpj: Yup.string().required('Obrigatório'),
  email: Yup.string().required('Obrigatório').email('Email inválido'),
  //senha: Yup.string().required("Obrigatória"),
  /*numeroConselho: Yup.string().required('Obrigatório'),*/
  /*  ufConselho: Yup.string().required('Obrigatória'),*/
  /*  conselho: Yup.object().shape({
    id: Yup.string().nullable().required('Obrigatório'),
  }),*/
});

class MedicoForm extends React.Component<Props, State> {
  // static defaultProps = {
  //   redirectPath: '/medico/',
  // };
  constructor(props: Props) {
    super(props);
  }

  goToViewEntity = (values) => {
    const { redirectPath } = this.props;
    this.props.history.push({
      pathname: redirectPath + values.id,
      state: { entity: values },
    });
  };

  saveForm = () => {
    let _this = this;

    let values = _this.formRef.state.values;

    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          medicoService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Profissional de saúde, foi ${
                  !values.id ? 'criado' : 'alterado'
                } com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              _this.goToViewEntity(values);
              if (_this.props.user.id === values.id) {
                values.role = _this.props.user.role;
                medicoService.doGet(_this.props.user.id).then(
                  (response) => {
                    let medico = response.data;
                    console.log(medico);
                    _this.props.refreshUser(medico);
                  },
                  (error) => {
                    console.log(error);
                    this.props.loading(false);
                  }
                );
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar medico.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  addTelefone = (setFieldValue, telefones) => {
    let newArray = _.concat(telefones, { numero: '', tipo: 'CELULAR' });
    setFieldValue('telefones', newArray);
  };

  removeItem = (setFieldValue, telefones, index) => {
    telefones.splice(index, 1);
    setFieldValue('telefones', telefones);
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };
  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  render() {
    let _this = this;
    const { viewMode, entity, propName, index } = this.props;
    console.log(entity);
    return (
      <React.Fragment>
        <Formik
          validationSchema={MedicoValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log(errors)}
              <div className="form-inside">
                <div className="row section-form">
                  <div className="col-12 col-lg-1">
                    <Avatar
                      user={values}
                      namePropsMidiaId={'foto.id'}
                      editable={!viewMode}
                      onChange={(name, value) => setFieldValue('foto', value)}
                    ></Avatar>
                  </div>
                  <div className="col-12 col-lg-10 form-row pl-3">
                    <FormGroup className="col-13 col-lg-2">
                      <InputViewEdit
                        component={FormSelectInput}
                        label={'Título ou Pronome tratamento'}
                        id="titulo"
                        name="titulo"
                        placeholder="Selecione um título ou pronome de tratamento"
                        value={values.titulo}
                        onChange={setFieldValue}
                        noSize={true}
                        erroMensagem={_.get(errors, 'titulo.id')}
                        viewMode={viewMode}
                        required={false}
                        service={tituloService.findAll}
                        defaultValue={
                          _.get(values, 'titulo.sigla') +
                          ' (' +
                          _.get(values, 'titulo.nome') +
                          ')'
                        }
                        labelKey={'nomeExibicao'}
                        returnFullObject={true}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label={'Nome'}
                        type="text"
                        id="nome"
                        name="nome"
                        placeholder="Digite o nome do profissional de saúde"
                        value={values.nome}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.nome}
                        viewMode={viewMode}
                        required={true}
                        className="nomeProprio"
                        viewClassName="nomeProprio font-weight-bold"
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-2">
                      <InputViewEdit
                        component={CpfCnpjInput}
                        label={'CPF'}
                        type="text"
                        id="cpfCnpj"
                        name="cpfCnpj"
                        placeholder="Digite o CPF"
                        value={values.cpfCnpj}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.cpfCnpj}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label={'E-mail principal'}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Digite o e-mail principal"
                        value={values.email}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        viewMode={viewMode}
                        required={true}
                        erroMensagem={errors.email}
                      />
                    </FormGroup>
                    {userService.isCurrent(values) && (
                      <FormGroup className="col-6 col-lg-3">
                        <InputViewEdit
                          component={FormInput}
                          label={'Senha'}
                          type="password"
                          name="senha"
                          id="senha"
                          defaultValue={'●●●●●●'}
                          placeholder="Digite a senha"
                          value={values.senha}
                          onChange={(name, value) => setFieldValue(name, value)}
                          noSize={true}
                          viewMode={viewMode}
                          erroMensagem={errors.senha}
                          maxLength={30}
                          viewPassword={true}
                        />
                      </FormGroup>
                    )}
                    <div className="form-row col-12">
                      <FormGroup className="col-6 col-lg-3">
                        <InputViewEdit
                          component={DatePickerInput}
                          label="Data de Nascimento"
                          id="dataNascimento"
                          name="dataNascimento"
                          value={values.dataNascimento}
                          required={false}
                          placeholder="- - / - - / - - - -"
                          required={true}
                          onChange={(name, value) => setFieldValue(name, value)}
                          viewMode={viewMode}
                          defaultValue={
                            _.get(values, `dataNascimento`)
                              ? dateHelper.format(
                                  _.get(values, `dataNascimento`),
                                  {
                                    mode: 'DATE',
                                  }
                                )
                              : '--'
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-6 col-lg-3">
                        <InputViewEdit
                          component={SelectInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          id={`sexo`}
                          // erroMensagem={errors.sexo}
                          label={'Sexo'}
                          options={objectsConstants.TIPOS_SEXO}
                          name={`sexo`}
                          placeholder="--"
                          viewMode={viewMode}
                          returnFullObject={false}
                          searchable={true}
                          labelKey={'description'}
                          valueKey={'name'}
                          value={values.sexo}
                          apendRoot={true}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              {/* INFORMAÇÕES */}

              <h2 className="w-100">Informações profissionais</h2>
              <div className="form-inside">
                <div className="row section-form">
                  {!viewMode && (
                    <FormGroup className="col-12 col-lg-8">
                      <InputViewEdit
                        component={FormSelectInput}
                        label={'Conselho'}
                        id="conselho"
                        name="conselho"
                        placeholder="Selecione um conselho"
                        value={values.conselho}
                        onChange={setFieldValue}
                        noSize={true}
                        erroMensagem={_.get(errors, 'conselho.id')}
                        viewMode={viewMode}
                        required={false}
                        service={conselhoService.findAll}
                        defaultValue={_.get(values, 'conselho.sigla')}
                        labelKey={'nome'}
                        returnFullObject={true}
                      />
                    </FormGroup>
                  )}
                  {_.get(values, 'conselho.sigla') && (
                    <React.Fragment>
                      <FormGroup className="col-6 col-lg-2">
                        <InputViewEdit
                          component={FormInput}
                          label={'RQE'}
                          type="text"
                          id="RQE"
                          name="rqe"
                          placeholder={`Digite o RQE`}
                          value={values.rqe}
                          onChange={(name, value) => setFieldValue(name, value)}
                          noSize={true}
                          erroMensagem={errors.rqe}
                          viewMode={viewMode}
                          required={false}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 col-lg-2">
                        <InputViewEdit
                          component={FormInput}
                          label={_.get(values, 'conselho.sigla')}
                          type="text"
                          id="numeroConselho"
                          name="numeroConselho"
                          placeholder={`Digite o ${_.get(
                            values,
                            'conselho.sigla'
                          )}`}
                          value={values.numeroConselho}
                          onChange={(name, value) => setFieldValue(name, value)}
                          noSize={true}
                          erroMensagem={errors.numeroConselho}
                          viewMode={viewMode}
                          required={true}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 col-lg-2">
                        <InputViewEdit
                          component={FormSelectInput}
                          label={'UF'}
                          id="ufConselho"
                          name="ufConselho"
                          placeholder="UF"
                          value={_.get(values, 'ufConselho')}
                          onChange={setFieldValue}
                          noSize={true}
                          erroMensagem={_.get(errors, 'ufConselho')}
                          viewMode={viewMode}
                          required={true}
                          returnFullObject={false}
                          valueKey={'sigla'}
                          labelKey={'sigla'}
                          multi={false}
                          service={ApiIBGE.findAllEstados}
                          sortKey={'sigla'}
                        />
                      </FormGroup>
                    </React.Fragment>
                  )}
                  <FormGroup className={`${viewMode ? 'col-8' : 'col-12'}`}>
                    <InputViewEdit
                      component={EspecialidadesSelectInput}
                      onChange={(name, value) => setFieldValue(name, value)}
                      id={`especialidades${index}`}
                      label={'Especialidades'}
                      name={`especialidades`}
                      placeholder="Digite uma ou mais especialidades"
                      noResultsText={'Digite uma especialidade'}
                      viewMode={viewMode}
                      value={_.get(values, `especialidades`)}
                      isMulti
                      apendRoot
                      labelKey={'nome'}
                      valueKey={'id'}
                      defaultValue={
                        _.get(values, 'especialidades')
                          ? _.get(values, 'especialidades', []).map((c, i) => {
                              if (i > 0) {
                                return ',' + c.nome;
                              } else {
                                return c.nome;
                              }
                            })
                          : ''
                      }
                      returnFullObject={true}
                    />
                  </FormGroup>
                </div>
              </div>

              {/* TELEFONES */}

              <h2 className="w-100">Telefones</h2>
              <div className="form-inside">
                {values.telefones &&
                  values.telefones.map((telefone, index) => {
                    return (
                      <div className="row section-form" key={index}>
                        <MultiTelefones
                          entity={values}
                          index={index}
                          viewMode={viewMode}
                          size={values.telefones.length}
                          handleChange={handleChange}
                          errors={errors}
                          propName={`telefones[${index}]`}
                          onChange={setFieldValue}
                          size={values.telefones.length}
                          removeItem={() => {
                            this.removeItemInArray(
                              `telefones`,
                              index,
                              values,
                              setValues
                            );
                          }}
                        ></MultiTelefones>
                      </div>
                    );
                  })}

                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    onClick={() =>
                      this.addTelefone(setFieldValue, values.telefones)
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Adicionar outro telefone
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(MedicoForm);
