//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { modeloReceituarioService } from '../../_services/modeloReceituario.service';
import { userService } from '../../_services/user.service';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { objectsConstants } from '../../_constants/objects.constants';
import ModelosReceituarioForm from './ModelosReceituarioForm';


const defaultArrayItem = [{ idList: new Date().getTime() }];

class ModelosReceituarioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadModelo();
  }

  loadModelo = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
    modeloReceituarioService.doGet(this.props.match.params.id).then(
        (response) => {
          let modelo = response.data.data;
          console.log(response.data.data);
          if (!modelo.listModeloDocumento) {
            modelo.listModeloDocumento = [{}];
          }
          this.setState({ entity: _.cloneDeep(modelo) }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleDeleteItem = () => {
    const { entity } = this.state;
    console.log(entity);
    this.props.loading(true);
    modeloReceituarioService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Modelo foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push('/modelo-receituario');
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir o modelo.',
        });
      }
    );
  };

  getEditDeleteBtn = (setEditMode) => {
    let canDeleteEdit =
      userService.isSecretaria() ||
      userService.isAdministrador() ||
      this.ehDocumentoMedico();
    return (
      <React.Fragment>
        {canDeleteEdit && (
          // <Can
          //     I={objectsConstants.DELETE}
          //     a={objectsConstants.MENU_MODELO}
          //     ability={this.props.permissions}
          // >
          <button
            type="button"
            className={`btn btn-link`}
            onClick={() => {
              ConfirmAcationService.confirmMsg(
                () => {
                  this.handleDeleteItem();
                },
                `Excluir registro  `,
                `Deseja realmente realizar a exclusão deste modelo?`,
                null,
                'Não',
                'Sim',
                objectsConstants.TYPE_EXCLUIR
              );
            }}
          >
            <span className="icon-btn-deletar"></span>
            Excluir
          </button>
          // </Can>
        )}

        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            if (canDeleteEdit) {
              setEditMode();
            } else {
              this.props.info({
                message:
                  'Este modelo de documento só pode ser alterado por seu criador',
              });
            }
          }}
        >
          <span className="icon-btn-editar"></span>
          Editar
        </button>
      </React.Fragment>
    );
  };

  ehDocumentoMedico = () => {
    let documentoMedico = _.get(this.state.entity, 'medico', null);

    return documentoMedico !== null;
  };

  render() {
    // let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={entity.nome}
          canBack={true}
          backUrl={'/modelo-receituario'}
        />

        <div className="flex-column mt-4">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_MODELO_RECEITUARIO}
            title="Dados do modelo Receituario"
            Componente={ModelosReceituarioForm}
            viewMode={true}
            entity={entity}
            removeItem={this.handleDeleteItem}
            getEditDeleteBtn={this.getEditDeleteBtn}
            onSave={this.loadModelo}
            cancelForm={this.loadModelo}
            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  null,
  mapDispatch,
  null
)(withRouter(ModelosReceituarioView));
