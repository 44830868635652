import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, FormGroup } from 'react-bootstrap';
import DatePickerInput from '../../components/inputs/DatePickerInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import { dateHelper } from '../../_helpers/date.helper';
import { medicoService } from '../../_services/medico.service';
import { pacienteService } from '../../_services/paciente.service';
import FormSelectInput from './../../components/inputs/FormSelectInput';
import { userService } from '../../_services/user.service';
import { exameService } from '../../_services/exame.service';
import moment from 'moment';
import { OptionUsuario } from '../../components/inputs/OptionUsuario';

type Props = {
  submitFilter: (filter: FinalFilterRelatoriosExames) => void,
};

type State = {
  filter: FinalFilterRelatoriosExames,
  openSearch: boolean,
  tipoRelatorio: ComboData[],
  errorsFields: Map<string, string>,
};

const TIPO_CONSULTA = [
  { name: 'PRIMEIRA', description: 'Primeira consulta', codigo: 0 },
  { name: 'COMUM', description: 'Comum', codigo: 1 },
  { name: 'RETORNO', description: 'Retorno', codigo: 2 },
];

const TIPO = [
  { name: 'MARCACAO', description: 'Marcação', codigo: 0 },
  { name: 'ENCAIXE', description: 'Encaixe', codigo: 1 },
];

const TIPO_ATENDIMENTO = [
  { name: 'CONVENIO', description: 'Convenio', codigo: 0 },
  { name: 'PARTICULAR', description: 'Particular', codigo: 1 },
  { name: 'CORTESIA', description: 'Cortesia', codigo: 1 },
];

interface FilterRelatorio {
  tipoRelatorio?: string;
  dataStart?: Date;
  dataEnd?: Date;
  listTipoAtendimento?: string[];
  listTipoConsulta?: string[];
  listTipo?: string[];
  listFkExame?: any[];
  listPaciente?: any[];
  listStatus?: string[];
}

/**
 * Final Filter Dashboard
 *
 */
interface FinalFilterRelatoriosExames {
  tipoRelatorio?: string;
  dataStart?: Date;
  dataEnd?: Date;
  listTipoAtendimento?: string[];
  listTipoConsulta?: string[];
  listTipo?: string[];
  listFkExame?: any[];
  listPaciente?: any[];
  listStatus?: any[];
}

class RelatoriosExamesFilter extends React.PureComponent<Props> {
  constructor(props: any) {
    super(props);
    this.state = {
      filter: {},
      errorsFields: new Map(),
      listTipoAtendimento: this.getTipoAtendimento(),
      listTipoConsulta: this.getTipoConsulta(),
      listTipo: this.getTipo(),
      tiposRelatorio: this.getTiposRelatorio(),
      listaStatus: this.getStatus(),
    };
  }

  /**
   * Tipos de atendimento
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTipoAtendimento = () => {
    return [
      {
        name: 'Convenio',
        value: 'CONVENIO',
      },
      {
        name: 'Particular',
        value: 'PARTICULAR',
      },
      {
        name: 'Cortesia',
        value: 'CORTESIA',
      },
    ];
  };

  /**
   * Status
   *
   * @return {[{value: string, name: string}}]}
   */
  getStatus = () => {
    return [
      { value: 'MARCADO', name: 'Marcado' },
      { value: 'ATENDIDO', name: 'Atendido' },
      { value: 'CANCELADO', name: 'Cancelado' },
      {
        value: 'AGUARDANDO_ATENDIMENTO',
        name: 'Aguardando atendimento',
      },
      { value: 'EM_ATENDIMENTO', name: 'Em atendimento' },
      { value: 'EM_CHAMADA', name: 'Em chamada' },
      { value: 'NAO_COMPARECEU', name: 'Não compareceu' },
    ];
  };

  /**
   * Tipos de consulta
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTipoConsulta = () => {
    return [
      {
        name: 'Comum',
        value: 'COMUM',
      },
      {
        name: 'Retorno',
        value: 'RETORNO',
      },
      {
        name: 'Primeira',
        value: 'PRIMEIRA',
      },
    ];
  };

  /**
   * Tipos Marcação
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTipo = () => {
    return [
      {
        name: 'Marcação',
        value: 'MARCACAO',
      },
      {
        name: 'Encaixe',
        value: 'ENCAIXE',
      },
    ];
  };

  /**
   * Tipos visualização
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTiposRelatorio = () => {
    return [
      {
        name: 'Analítico',
        value: 'ANALITICO',
      },
      {
        name: 'Sintético',
        value: 'SINTETICO',
      },
    ];
  };

  toggleSearch = () => {
    this.setState({
      openSearch: !this.state.openSearch,
    });
  };

  /**
   * Subtract date
   *
   * @param numOfDays - numOfDays
   * @param date - date
   * @return Date
   */
  subtractDays(numOfDays, date = new Date()): Date {
    date.setDate(date.getDate() - numOfDays);
    return date;
  }

  /**
   * Get diff between dates
   *
   * @param startDate
   * @param endDate
   * @return {number}
   */
  getDayDiff(startDate: Date, endDate: Date): number {
    return Math.round(
      Math.abs(Number(endDate) - Number(startDate)) / (24 * 60 * 60 * 1000)
    );
  }

  submitFilter = () => {
    if (this.state.errorsFields.size <= 0) {
      this.setState({
        openSearch: false,
      });
      this.props.submitFilter(this.filterFinalRelatorio());
    } else {
      this.props.error({
        message:
          'Por favor corrija os erros do formulário de pesquisa antes de continuar',
      });
    }
  };

  /**
   * Responsible transform filter dash to final filter dash
   *
   */
  filterFinalRelatorio(): FilterRelatorio {
    if (this.state.filter) {
      console.log(this.state.filter.listPaciente);
      return {
        tipoRelatorio: this.state.filter.tipoRelatorio,
        dataEnd: this.state.filter.dataEnd,
        dataStart: this.state.filter.dataStart,
        listTipoAtendimento: _.map(
          this.state.filter.listTipoAtendimento,
          (o) => o.value
        ),
        listTipoConsulta: _.map(
          this.state.filter.listTipoConsulta,
          (o) => o.value
        ),
        listTipo: _.map(this.state.filter.listTipo, (o) => o.value),

        listFkExame: _.map(this.state.filter.listFkExame, (o) => o.id),

        listPaciente: _.map(this.state.filter.listPaciente, (o) => o.id),
        listStatus: _.map(this.state.filter.listStatus, (o) => o.value),
      };
    } else {
      return {};
    }
  }

  /**
   * Responsible clear search
   *
   */
  clearSearch = () => {
    this.setState({
      filter: this.defaultInitiValueFilterState(),
    });
  };

  /**
   * Component did mount
   *
   * @return {Promise<void>}
   */
  async componentDidMount() {
    // let estabelecimentoUsuario: any = null;
    // let userMedico: any = null;
    // if (userService.isMedico()) {
    //   userMedico = (await medicoService.doGet(userService.getCurrentUser().id))
    //     .data;
    // }
    // if (userService.getExtension()) {
    //   estabelecimentoUsuario = (
    //     await estabelecimentoService.doGet(userService.getExtension())
    //   ).data.data;
    // }
    // this.props.loading(false);
    this.setState(
      {
        filter: this.defaultInitiValueFilterState(),
      },
      () => {
        this.submitFilter();
      }
    );
  }

  /**
   * Default value filter init state
   *
   * @return FilterDashboard
   */
  defaultInitiValueFilterState(): FilterRelatorio {
    return {
      tipoRelatorio: 'ANALITICO',
      dataStart: this.subtractDays(30, new Date()),
      dataEnd: new Date(),
    };
  }

  /**
   * Responsible change data
   *
   * @param name
   * @param value
   */
  handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter: filter }, () => {
      if ('dataStart' === name || 'dataEnd' === name) {
        this.validateIntervalDate();
      }
    });
  };

  /**
   * Validate Dates
   *
   */
  validateIntervalDate = () => {
    if (
      moment(this.state.filter.dataStart).isAfter(this.state.filter.dataEnd)
    ) {
      this.setState({
        errorsFields: this.state.errorsFields.set(
          'dataStart',
          'A data inicial não pode ser maior que e a data final'
        ),
      });
    } else if (
      moment(this.state.filter.dataEnd).isBefore(this.state.filter.dataStart)
    ) {
      this.setState({
        errorsFields: this.state.errorsFields.set(
          'dataEnd',
          'A data final não pode ser menor que e a data inicial'
        ),
      });
    } else {
      let previosErrorsFields: Map<string, string> = this.state.errorsFields;
      previosErrorsFields.delete('dataStart');
      previosErrorsFields.delete('dataEnd');
      this.setState({ errorsFields: previosErrorsFields });
    }
  };

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="topo-listagem d-flex flex-column ">
          <div className="boxButton d-flex justify-content-center">
            {!this.state.openSearch && (
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-secondary open search"
                  onClick={this.toggleSearch}
                >
                  <span className="icone-lupa"></span>
                  Pesquisar
                </button>
              </div>
            )}
            {this.state.openSearch && (
              <button className="btn btn-blue open" onClick={this.toggleSearch}>
                <span className="icon-btn-fechar"></span>
                Fechar pesquisa
              </button>
            )}
          </div>
        </div>
        <div className="conteudo-listagem mt-3">
          <Collapse in={this.state.openSearch}>
            <div className="search-box form-inside">
              <div className="form-row section-form justify-content-center">
                <FormGroup className="col-12 col-md-2">
                  <SelectInput
                    id="tipoRelatorio"
                    name="tipoRelatorio"
                    label="Tipo Visualizacao"
                    options={this.state.tiposRelatorio}
                    onChange={this.handleChangeData}
                    value={this.state.filter.tipoRelatorio}
                    labelKey="name"
                    valueKey="value"
                    multi={false}
                    returnFullObject={false}
                    creatable={false}
                    required={false}
                    placeholder="Tipo Visualização"
                  />
                </FormGroup>
                <FormGroup className="col-12 col-md-2">
                  <InputViewEdit
                    component={DatePickerInput}
                    erroMensagem={
                      this.state.errorsFields.has('dataStart')
                        ? this.state.errorsFields.get('dataStart')
                        : null
                    }
                    label="Data Inicial"
                    name="dataStart"
                    id="dataStart"
                    value={this.state.filter.dataStart}
                    onChange={this.handleChangeData}
                    placeholder={'--/--/----'}
                    defaultValue={
                      _.get(this.state.filter, `dataStart`)
                        ? dateHelper.format(
                            _.get(this.state.filter, `dataStart`),
                            {
                              mode: 'DATE',
                            }
                          )
                        : '--'
                    }
                  />
                </FormGroup>
                <FormGroup className="col-12 col-md-2">
                  <InputViewEdit
                    component={DatePickerInput}
                    erroMensagem={
                      this.state.errorsFields.has('dataEnd')
                        ? this.state.errorsFields.get('dataEnd')
                        : null
                    }
                    label="Data Final"
                    name="dataEnd"
                    id="dataEnds"
                    value={this.state.filter.dataEnd}
                    onChange={this.handleChangeData}
                    placeholder={'--/--/----'}
                    defaultValue={
                      _.get(this.state.filter, `dataEnd`)
                        ? dateHelper.format(
                            _.get(this.state.filter, `dataEnd`),
                            {
                              mode: 'DATE',
                            }
                          )
                        : '--'
                    }
                  />
                </FormGroup>
                <FormGroup className="col-12 col-md-6">
                  <SelectInput
                    id="listPaciente"
                    name="listPaciente"
                    label="Pacientes"
                    placeholder="Pacientes"
                    onFetchData={pacienteService.findByNomeOuCPF}
                    onChange={(name, value) => {
                      this.handleChangeData(name, value);
                    }}
                    value={this.state.filter.listPaciente}
                    labelKey="nome"
                    valueKey="id"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    cache={true}
                    components={{ Option: OptionUsuario }}
                  />
                </FormGroup>

                <FormGroup className="col-12 col-md-2">
                  <SelectInput
                    id="listTipoAtendimento"
                    name="listTipoAtendimento"
                    label="Tipos de Atendimento"
                    options={this.state.listTipoAtendimento}
                    onChange={this.handleChangeData}
                    value={this.state.filter.listTipoAtendimento}
                    labelKey="name"
                    valueKey="value"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    required={false}
                    placeholder="Tipos de Atendimento"
                  />
                </FormGroup>

                <FormGroup className="col-12 col-md-5">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="listFkExame"
                    label="Exame"
                    placeholder="Selecione um Exame"
                    name="listFkExame"
                    value={this.state.filter.listFkExame}
                    returnFullObject={true}
                    service={exameService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                  />
                </FormGroup>
                <FormGroup className="col-12 col-md-2">
                  <SelectInput
                    id="listTipo"
                    name="listTipo"
                    label="Tipos de Marcação"
                    options={this.state.listTipo}
                    onChange={this.handleChangeData}
                    value={this.state.filter.listTipo}
                    labelKey="name"
                    valueKey="value"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    required={false}
                    placeholder="Tipos de Marcação"
                  />
                </FormGroup>
                <FormGroup className="col-12 col-md-3 ">
                  <SelectInput
                    id="listStatus"
                    name="listStatus"
                    label="Status da consulta"
                    options={this.state.listaStatus}
                    onChange={this.handleChangeData}
                    value={this.state.filter.listStatus}
                    labelKey="name"
                    valueKey="value"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    required={false}
                    placeholder="Status da consulta"
                  />
                </FormGroup>
                <div className="py-0 py-lg-3 w-100 col-12 text-center text-lg-right">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={this.clearSearch}
                  >
                    Limpar pesquisa
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary open"
                    onClick={this.submitFilter}
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}
export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  {}
)(RelatoriosExamesFilter);
