//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const examesSolicitadosService = {
  findAll,
  imprimir
};

function findAll(descricao) {

  return defaultService.doPost(`${urlsConstants.EXAMES_SOLICITADOS}findAll/`,{descricao});
}
function imprimir(marcacao) {
  return defaultService.doPost(`${urlsConstants.EXAMES_SOLICITADOS}imprimir`, marcacao);
}
