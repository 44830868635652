//@flow
import React, { PureComponent } from 'react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { connect } from 'react-redux';

class Toast extends PureComponent<> {
  render() {
    return (
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        removeOnHover={true}
        progressBar={false}
      />
    );
  }
}

export default connect()(Toast);
