//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { userService } from '../../_services/user.service';
import UsuarioForm from './UsuarioForm';

// const defaultArrayItem = [{ idList: new Date().getTime() }];

class UsuarioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser = () => {
    console.log('loadUser');
    if (this.props.match.params.id) {
      this.props.loading(true);
      userService.doGet(this.props.match.params.id).then(
        (response) => {
          let usuario = response.data.data;
          console.log(usuario);
          if (!usuario.hasOwnProperty('status')) {
            usuario.status = false;
          }

          this.setState({ entity: usuario }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleCancel = () => {
    console.log('cancel usuario');
    this.props.history.goBack();
  };

  handleDeleteItem = (index, entity) => {
    this.props.loading(true);
    userService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Usuário foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push('/usuario');
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir usuário.',
        });
      }
    );
  };

  handleChangeProfile = (name, value) => {
    // let validateModel = this.ObjectValidateProfile(value);
    const entity = _.cloneDeep(this.state.entity);
    console.log(entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        console.log(erros);
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        {entity.id && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  usuário`}
              canBack={true}
              backUrl={!entity.id ? null : '/usuario'}
            />
            <TopoListagem
              showIndisponibilidade={false}
              showfilter={false}
            ></TopoListagem>
            {/* <UsuarioTopoCrud
              values={entity}
              onChangeProfile={this.handleChangeProfile}
              onChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
              edit={false}
              viewMode={true}
            ></UsuarioTopoCrud> */}

            <div className="flex-column">
              <FullCustomCard
                permissionSave={objectsConstants.MENU_USUARIO}
                ref={(form) => {
                  this.refFormComponent = form;
                }}
                title="Usuário"
                Componente={UsuarioForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                btnExcluir={true}
                removeItem={this.handleDeleteItem}
                {...otherPops}
              ></FullCustomCard>
            </div>
            {<div className="flex-column"></div>}
          </React.Fragment>
        )}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(UsuarioView));
