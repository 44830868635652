//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import * as Yup from 'yup';
import { GuicheAtendimento } from '../../components/GuicheAtendimento/GuicheAtendimento';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import { objectsConstants } from '../../_constants/objects.constants';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { FormGroup } from '../../components/FormGroup';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const EstabelecimentoValidateCampos = Yup.object().shape({
  camposObrigatorios: Yup.object().shape({
    nomePaciente: Yup.string().required('Obrigatório'),
    cpfPaciente: Yup.string().required('Obrigatório'),
    telefonePaciente: Yup.string().required('Obrigatório'),
    dataNascimentoPaciente: Yup.string().required('Obrigatório'),
    enderecoPaciente: Yup.string().required('Obrigatório'),
    emailPaciente: Yup.string().required('Obrigatório'),
  }),
});

class CamposObrigatoriosForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/estabelecimento/view/' + values.id,
      state: { entity: values },
    });
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  saveForm = () => {
    let _this = this;
    let values = _this.formRef.state.values;
    console.log(values);
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          estabelecimentoService
            .saveCamposObrigatorios(values, values.camposObrigatorios)
            .then(
              (r) => {
                _this.props.success({
                  message: `Rcepção salva com sucesso!`,
                });
                _this.props.loading(false);
                //_this.goToViewEntity(values);
                resolve(true);
              },
              (error) => {
                _this.props.loading(false);
                _this.props.error({
                  message: 'Não foi possível salvar Recepção.',
                });
                console.log(error);
              }
            );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  render() {
    let _this = this;
    const { viewMode, entity } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EstabelecimentoValidateCampos}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={entity}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-md-4 col-lg-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Nome'}
                      id="nomePaciente"
                      name="camposObrigatorios.nomePaciente"
                      placeholder="Selecione a obrigatoriedade"
                      value={_.get(values, 'camposObrigatorios.nomePaciente')}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'camposObrigatorios.nomePaciente'
                      )}
                      defaultValue={_.get(
                        values,
                        'camposObrigatorios.nomePaciente.description',
                        ' '
                      )}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      options={objectsConstants.TIPO_OBRIGATORIEDADE}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-4 col-lg-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'CPF'}
                      id="cpfPaciente"
                      name="camposObrigatorios.cpfPaciente"
                      placeholder="Selecione a obrigatoriedade"
                      value={_.get(values, 'camposObrigatorios.cpfPaciente')}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'camposObrigatorios.cpfPaciente'
                      )}
                      defaultValue={_.get(
                        values,
                        'camposObrigatorios.cpfPaciente.description',
                        ' '
                      )}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      options={objectsConstants.TIPO_OBRIGATORIEDADE}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-4 col-lg-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Telefone'}
                      id="telefonePaciente"
                      name="camposObrigatorios.telefonePaciente"
                      placeholder="Selecione a obrigatoriedade"
                      value={_.get(
                        values,
                        'camposObrigatorios.telefonePaciente'
                      )}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'camposObrigatorios.telefonePaciente'
                      )}
                      defaultValue={_.get(
                        values,
                        'camposObrigatorios.telefonePaciente.description',
                        ' '
                      )}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      options={objectsConstants.TIPO_OBRIGATORIEDADE}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-4 col-lg-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Data de nascimento'}
                      id="dataNascimentoPaciente"
                      name="camposObrigatorios.dataNascimentoPaciente"
                      placeholder="Selecione a obrigatoriedade"
                      value={_.get(
                        values,
                        'camposObrigatorios.dataNascimentoPaciente'
                      )}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'camposObrigatorios.dataNascimentoPaciente'
                      )}
                      defaultValue={_.get(
                        values,
                        'camposObrigatorios.dataNascimentoPaciente.description',
                        ' '
                      )}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      options={objectsConstants.TIPO_OBRIGATORIEDADE}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-4 col-lg-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Endereço'}
                      id="enderecoPaciente"
                      name="camposObrigatorios.enderecoPaciente"
                      placeholder="Selecione a obrigatoriedade"
                      value={_.get(
                        values,
                        'camposObrigatorios.enderecoPaciente'
                      )}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'camposObrigatorios.enderecoPaciente'
                      )}
                      defaultValue={_.get(
                        values,
                        'camposObrigatorios.enderecoPaciente.description',
                        ' '
                      )}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      options={objectsConstants.TIPO_OBRIGATORIEDADE}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-4 col-lg-4">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'E-mail'}
                      id="emailPaciente"
                      name="camposObrigatorios.emailPaciente"
                      placeholder="Selecione a obrigatoriedade"
                      value={_.get(values, 'camposObrigatorios.emailPaciente')}
                      noSize={true}
                      erroMensagem={_.get(
                        errors,
                        'camposObrigatorios.emailPaciente'
                      )}
                      defaultValue={_.get(
                        values,
                        'camposObrigatorios.emailPaciente.description',
                        ' '
                      )}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      multi={false}
                      options={objectsConstants.TIPO_OBRIGATORIEDADE}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(CamposObrigatoriosForm);
