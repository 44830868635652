//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { oauthHeaderJson } from '../_helpers/oauth-header';
import { defaultService } from './defaultService';


export const estoqueService = {
  doGet,
  doSave,
  doDelete,
  findByFiltro,
  findByNome,
  estoqueProdutoFindById,
  findEstoqueUnidadeByEstoqueId
};


function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(`${urlsConstants.ESTOQUE}list`, options, filter);
}

function findByNome(nome: string) {
  console.log(nome);
  const requestOptions = {
    method: 'GET',
    headers: oauthHeaderJson(),
  };
  var p: Promise<any> = fetch(
    urlsConstants.ESTOQUE_FIND_BY_NOME + nome,
    requestOptions
  ).then((response) => {
    console.log(response);
    return response.json();
  });
  console.log(p);
  return p;
}


function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.ESTOQUE}${id}`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ESTOQUE}${id}`);
}

function doSave(estoque: any) {
  return defaultService.doSave(urlsConstants.ESTOQUE, estoque);
}

function estoqueProdutoFindById(id) {
  return defaultService.doGet(`${urlsConstants.ESTOQUE_PRODUTO_FIND_BY_ID}${id}`);
}
function findEstoqueUnidadeByEstoqueId(id) {
  return defaultService.doGet(`${urlsConstants.ESTOQUE_UNIDADE_FIND_BY_ID_PRODUTO}${id}`);
}


