//@flow
import * as React from 'react';
import CreatableMulti from './CreatableMulti';
import { pacienteService } from '../../_services/paciente.service';
import SelectInput from './SelectInput';
import { OptionUsuario } from './OptionUsuario';

type Props = {
  value?: any,
  onChange: any,
  id?: string,
  onNewOptionClick: any,
  newOptionCreator: any,
};

export class PacienteSelect extends React.Component<Props, {}> {
  render() {
    const {horario} = this.props;
    return (
      <SelectInput
        id={this.props.id}
        name="paciente"
        onFetchData={pacienteService.findByNomeOuCPF}
        onChange={this.props.onChange}
        value={this.props.value}
        labelKey="nome"
        multi={false}
        returnFullObject={true}
        creatable={true}
        parent={this.props.medico}
        required={this.props.required}
        onNewOptionClick={this.props.onNewOptionClick}
        newOptionCreator={this.props.newOptionCreator}
        cache={true}
        components={{ Option: OptionUsuario }}
        placeholder={
          this.props.disabled
            ? 'Este horário não está mais disponível'
            : `Para agendar ${horario?`as ${horario}`:''}, digite o nome do paciente`
        }
        disabled={this.props.disabled}
      />
    );
  }
}
