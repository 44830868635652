//@flow
import * as _ from "lodash";
import { urlsConstants } from '../_constants/urls.constant';
import { oauthHeader } from '../_helpers/oauth-header';
import { defaultService } from './defaultService';


export const registerService = {
  validateCpf,
  validateCpfCnpj,
  getValorProssional,
  register,
  getCupomDesconto,
};


function validateCpfCnpj(cpfCnpj) {
  let cpfCnpjNumeros = cpfCnpj.replace(/[^0-9]+/g, "");
  return defaultService.doGet(`${urlsConstants.REGISTER_PUBLIC}validateCpfCnpj/${cpfCnpjNumeros}`);
}

function validateCpf(cpf) {
  return defaultService.doGet(`${urlsConstants.REGISTER_PUBLIC}validateCpf/${cpf}`);
}

function getValorProssional() {
  return defaultService.doGet(`${urlsConstants.REGISTER_PUBLIC}getValorProssional`);
}



function register(estabelecimentoRegister) {
  return defaultService.doPost(`${urlsConstants.REGISTER_PUBLIC}`, estabelecimentoRegister);
}

function getCupomDesconto(cupomDesconto) {
  return defaultService.doGet(`${urlsConstants.REGISTER_PUBLIC}getCupomDesconto/${cupomDesconto}`);
}