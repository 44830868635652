//@flow
import _ from 'lodash';
import * as React from 'react';
import SelectInput from '../../components/inputs/SelectInput';
import IconDelete from '../../images/icon_x_red.svg';
import { objectsConstants } from '../../_constants/objects.constants';
import { FormGroup } from '../FormGroup';
import InputViewEdit from '../inputs/InputViewEdit';
import PhoneInput from '../inputs/PhoneInput';

type Props = {
  entity: any,
};

export default class MultiTelefones extends React.Component<Props, State> {
  getValue = (entity) => {
    let value = _.get(entity, `${this.props.propName}.tipo`);
    if (value) {
      return value;
    } else {
      return objectsConstants.TELEFONES[0];
    }
  };

  render() {
    const { entity, propName, index, viewMode, size, agendamentoMode } =
      this.props;
    return (
      <React.Fragment>
        <FormGroup
          className={`col-5 ${agendamentoMode ? 'col-lg-4' : 'col-lg-2'}`}
        >
          <InputViewEdit
            label="Tipo do telefone"
            id={`tipoTelefone`}
            viewMode={viewMode}
            component={SelectInput}
            options={objectsConstants.TELEFONES}
            onChange={this.props.onChange}
            name={`${propName}.tipo`}
            value={_.get(entity, `${propName}.tipo`)}
            defaultValue={objectsConstants.TELEFONES.map((tel) => {
              if (tel.value === _.get(entity, `${propName}.tipo`)) {
                return tel.name;
              }
            })}
            required={true}
            labelKey={'name'}
            valueKey={'value'}
            returnFullObject={false}
            erroMensagem={_.get(this.props.errors, `${propName}.tipo`)}
          />
        </FormGroup>
        <FormGroup
          className={`col-5 ${agendamentoMode ? 'col-lg-6' : 'col-lg-3'}`}
        >
          <InputViewEdit
            label="Número"
            viewMode={viewMode}
            component={PhoneInput}
            type="number"
            id={`Phone${index}`}
            className="form-control"
            size={100}
            name={`${propName}.numero`}
            placeholder="Somente números"
            value={_.get(entity, `${propName}.numero`)}
            onChange={(e) => {
              const { name, type, value, checked } = e.currentTarget;
              this.props.onChange(name, value, type, checked);
            }}
            className={`${this.props.errors.telefone && 'error'}`}
            required={true}
            erroMensagem={_.get(this.props.errors, `${propName}.numero`)}
          />
        </FormGroup>

        <div
          className={`form-group col-2 ${
            agendamentoMode ? 'col-lg-2' : 'col-lg-1'
          } align-items-center justify-content-start ${
            viewMode ? 'd-none' : ' d-flex '
          }`}
        >
          <div
            className={`icon-delete d-flex align-items-end justify-content-start$ {
              size === 1 || viewMode ? 'disabled' : ''
            }`}
            onClick={() => {
              this.props.removeItem(index, entity);
            }}
            disabled={size === 1}
          >
            <img src={IconDelete} alt="img" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
