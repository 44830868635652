import _ from 'lodash';
import React, { PureComponent } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import CropperView from '../../components/Imagem/CropperView';
import IconImage from '../../images/icon-photo.svg';
import IconDeletar from '../../images/icones/delete.svg';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { midiaService } from '../../_services/midia.service';

// let fileInput;

class UserAvatar extends PureComponent<Props> {
  static defaultProps = {
    namePropsMidiaId: 'foto.id',
  };
  constructor(props: Props) {
    super(props);
    this.changeImage = this.changeImage.bind(this);
    this.state = {
      image: null,
      dataUrl: null,
    };
  }

  getUserAvatar = () => {
    if (this.state.dataUrl) {
      return this.state.dataUrl;
    }
    var idMidia = _.get(this.props.user, this.props.namePropsMidiaId);
    if (idMidia) {
      return midiaService.url(idMidia);
    } else {
      return null;
    }
  };

  handleChangeAvatar = () => {
    if (this.props.editable) {
      this.openInputFile();
    }
  };

  changeImage(dataUrl) {
    let { image } = this.state;
    image.data = dataUrl;
    this.setState({ image: null, dataUrl }, () => {
      this.props.onChange(this.props.name, image);
    });
  }
  deleteImage() {

    let { image, dataUrl } = this.state;
    image = null;
    this.setState({ image: null, dataUrl: null }, () => {
      this.props.onChange(this.props.name, image);
    });
  }

  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      // let props = this.props;
      var imageVO = {
        nome: files[0].name,
        tamanho: files[0].size,
        tipo: files[0].type,
      };
      self.resize(files[0], maxWidth, maxHeight, function (resizedDataUrl) {
        imageVO.data = resizedDataUrl;
        self.setState({ image: imageVO });
        //props.onChange(imageVO);
      });
    }
  };
  resize(file, maxWidth, maxHeight, fn) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      var dataUrl = event.target.result;
      fn(dataUrl);
    };
  }

  openInputFile = (event: any) => {
    if (event) event.preventDefault();
    if (this.props.readOnly) {
      return false;
    }
    this.fileInput.click();
  };

  render() {
    const { image } = this.state;
    let size;

    if (!this.props.size) {
      size = 100;
    } else {
      size = this.props.size;
    }
    return (
      <div
        className={`avatar ${this.props.editable ? 'action' : ''}  ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <div onClick={this.handleChangeAvatar}>
          <input
            type="file"
            accept="image/*"
            onChange={this.onChange}
            className="d-none img-profile-input"
            ref={(input) => {
              this.fileInput = input;
            }}
          />
          {this.props.children}
          <CropperView
            modal={image ? true : false}
            onCropper={this.changeImage}
            titleBtn={'Cortar imagem'}
            title="Cortar imagem"
            subititle={''}
            maxHeight={500}
            maxWidth={500}
            dataUrl={image ? image.data : null}
            cropRadius={this.props.cropRadius ? this.props.cropRadius : 100}
            exportAsSquare={
              this.props.exportAsSquare ? this.props.exportAsSquare : true
            }
          />
          <Avatar
            maxInitials={2}
            name={
              _.get(this.props.user, 'id')
                ? formatterHelper.getNameAndLastName(this.props.user.nome)
                : '- -'
            }
            unstyled={true}
            className={`avatarCustom ${
              this.props.classNameCustom ? this.props.classNameCustom : ''
            } avatarPerfil_Protocolo ${
              this.getUserAvatar()
                ? this.getUserAvatar()
                : '' && 'no-background'
            } `}
            size={size}
            src={this.getUserAvatar()}
          />
        </div>
        {this.props.editable && !this.getUserAvatar() && (
          <div
            className="icon-foto-user d-flex align-items-center "
            onClick={this.handleChangeAvatar}
          >
            <img src={IconImage} alt="img" />
          </div>
        )}

        {this.props.editable && this.getUserAvatar() && (
          <div
            className="icon-foto-user d-flex align-items-center "
            onClick={() => {
              this.deleteImage();
            }}
          >
            <img src={IconDeletar} alt="Deletar Imagem" />
          </div>
        )}
      </div>
    );
  }
}
export default connect()(UserAvatar);
