//@flow
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import DashboardListagemPage from "./DashboardListagemPage";

export default class DashboardIndexPage extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/dashboard" component={DashboardListagemPage} exact />
      </Switch>
    );
  }
}
