//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstoqueEntradaEdit from './EstoqueEntradaEdit';
import EstoqueEntradaListagemPage from './EstoqueEntradaListagemPage';
import EstoqueEntradaView from './EstoqueEntradaView';

export default class EstoqueEntradaIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/estoque-entrada/novo" component={EstoqueEntradaEdit} />
        <Route
          path="/estoque-entrada/view/:id"
          component={EstoqueEntradaView}
        />
        <Route path="/estoque-entrada" component={EstoqueEntradaListagemPage} />
      </Switch>
    );
  }
}
