//@flow
import { Can } from '@casl/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../_constants/objects.constants';
import _ from 'lodash';
type Props = {
  label: ?string,
  onClick: Function | string,
  role: String,
};

class ButtonAutorized extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };
  render() {
    const { permissions, role, label, disabled } = this.props;
    return (
      <div className={`actions ${this.props.classAction}`}>
        <Can I={objectsConstants.VIEW} a={role} ability={permissions}>
          <button
            className={`btn ${this.props.className}`}
            onClick={this.props.onClick}
            id={this.props.id}
            disabled={disabled}
          >
            {this.props.icon && <span className={`${this.props.icon}`}></span>}
            {label}
          </button>
        </Can>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions,
  };
}

export default connect(mapStateToProps)(ButtonAutorized);
