import _ from 'lodash';
import * as React from 'react';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import { OptionUsuario } from '../../components/inputs/OptionUsuario';
import SelectInput from '../../components/inputs/SelectInput';
import IconFiltro from '../../images/icon_filter_black.svg';
import { convenioService } from '../../_services/convenio.service';
import { especialidadesService } from '../../_services/especialidades.service';
import { medicoService } from '../../_services/medico.service';
import { pacienteService } from '../../_services/paciente.service';
import './FiltroAgendamento.css';
import FiltroRemarcaçãoInput from './FiltroRemarcaçãoInput';
import { userService } from '../../_services/user.service';

export default class FiltroAgendamento extends React.Component<{}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { filtro, onChange } = this.props;
    const extensao = userService.getExtension();
    return (
      <div className="filter-agendamento">
        <div>
          <img
            src={IconFiltro}
            className="filter-icon-agendamento d-inline "
            alt=""
          />
          <h1 className="d-inline">Filtros</h1>
        </div>
        <div className="row">
          {/*   <div className="col-12">
            <FiltroRemarcaçãoInput filtro={filtro} onChange={onChange} />
          </div>*/}

          <div className="col-md-4 col-12">
            <FormSelectInput
              label="Convênio"
              viewClassName="font-weight-bold"
              placeholder="Selecione um convênio"
              component={FormSelectInput}
              service={convenioService.findAll}
              parent={{ estabelecimento: extensao }}
              labelKey={'sigla'}
              valueKey={'id'}
              returnFullObject={true}
              className={''}
              required={false}
              value={_.get(filtro, 'convenio')}
              name={'convenio'}
              id={'convenioFiltro'}
              onChange={onChange}
            />
          </div>

          <div className="col-md-4 col-12">
            {_.get(filtro, 'convenio') && (
              <FormSelectInput
                label="Plano"
                viewClassName="font-weight-bold"
                placeholder="Selecione um plano"
                component={FormSelectInput}
                service={convenioService.findAllPlansByConvenios}
                parent={[_.get(filtro, 'convenio.id')]}
                labelKey={'nome'}
                valueKey={'id'}
                returnFullObject={true}
                className={''}
                required={false}
                value={_.get(filtro, 'plano')}
                name={'plano'}
                id={'planoFiltro'}
                onChange={onChange}
              />
            )}
            {!_.get(filtro, 'convenio') && (
              <FormSelectInput
                disabled={true}
                label="Plano"
                viewClassName="font-weight-bold"
                placeholder="Selecione um plano"
                component={FormSelectInput}
                service={convenioService.findAllPlansByConvenios}
                parent={[_.get(filtro, 'convenio.id')]}
                labelKey={'nome'}
                valueKey={'id'}
                returnFullObject={true}
                className={''}
                required={false}
                value={_.get(filtro, 'plano')}
                name={'plano'}
                id={'planoFiltro'}
                onChange={onChange}
              />
            )}
          </div>
          <div className="col-md-4 col-12">
            <FormSelectInput
              label="Especialidade"
              viewClassName="font-weight-bold"
              placeholder="Selecione uma especialidade"
              component={FormSelectInput}
              service={() => {
                return especialidadesService.findAll(extensao);
              }}
              labelKey={'nome'}
              valueKey={'id'}
              returnFullObject={true}
              className={''}
              required={false}
              value={_.get(filtro, 'especialidade')}
              name={'especialidade'}
              id={'especialidadeFiltro'}
              onChange={onChange}
            />
          </div>
          <div className="col-md-4 col-12">
            {!_.get(filtro, 'especialidade') && (
              <FormSelectInput
                label="Profissional da saúde"
                viewClassName="font-weight-bold"
                placeholder="Selecione um profissional"
                component={FormSelectInput}
                service={() => {
                  return medicoService.findMedicoByEspecialidade(
                    null,
                    extensao
                  );
                }}
                labelKey={'apelido'}
                valueKey={'id'}
                returnFullObject={true}
                className={''}
                required={false}
                value={_.get(filtro, 'profissional')}
                name={'profissional'}
                id={'profissionalFiltro'}
                onChange={onChange}
              />
            )}
            {_.get(filtro, 'especialidade') && (
              <FormSelectInput
                label="Profissional da saúde"
                viewClassName="font-weight-bold"
                placeholder="Selecione um profissional"
                component={FormSelectInput}
                service={() => {
                  return medicoService.findByEspecialidade(
                    _.get(filtro, 'especialidade.id'),
                    extensao
                  );
                }}
                labelKey={'medico.apelido'}
                valueKey={'medico.id'}
                returnFullObject={true}
                className={''}
                required={false}
                value={_.get(filtro, 'profissional')}
                name={'profissional'}
                id={'profissionalFiltro'}
                onChange={onChange}
              />
            )}
          </div>
          <div className="col d-inline-flex justify-content-center justify-content-md-end text-center text-md-right  ">
            <button
              className="col-6 col-sm-3 btn-custom btn-find-horarios"
              onClick={this.props.pesquisar}
            >
              Buscar horários
            </button>
            <button
              className="col-6 col-sm-3 btn-custom btn-clean-filters"
              onClick={this.props.limpar}
            >
              Limpar filtros
            </button>
          </div>
        </div>
      </div>
    );
  }
}
