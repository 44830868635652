//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const protocoloAtendimentoService = {
  doSave,
  findByMedico,
  getReceituario,
};

function doSave(prontuario) {
  return defaultService.doSave(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_RECEITUARIO}`,
    prontuario
  );
}

function findByMedico() {
  return defaultService.doGet(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_RECEITUARIO}findByMedico`
  );
}

function getReceituario(id) {
  return defaultService.doGet(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_RECEITUARIO}getReceituario/${id}`
  );
}
