//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { modeloReceituarioService } from '../../_services/modeloReceituario.service';
import ModelosReceituarioListFilter from './ModelosReceituarioListerFilter';

const columns = [
  {
    path: 'nome',
    pathFilter: 'nome',
    title: 'Nome do modelo',
    isKey: true,
    dataSort: true,
    className: 'column-bold',
  },
];

const endPoint = '/modelo-receituario/novo';
const endPointView = '/modelo-receituario/view/';

class ModelosReceituarioListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
  };

  render() {
    console.log('render listagem modelos receituario');
    
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_MODELOS_RECEITUARIO}
          subTitle=" "
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_MODELO_RECEITUARIO}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo modelo'}
        ></TopoListagem>
        <ListagemPage
          title={`Modelos`}
          showNewButton={false}
          delete={() => {}}
          service={modeloReceituarioService}
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={ModelosReceituarioListFilter}
          fields={this.state.fields}
          controller="api/documento"
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(ModelosReceituarioListagemPage);
