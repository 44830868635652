//@flow
import * as React from 'react';
import { userService } from '../../_services/user.service';
import MedicoView from '../medico/MedicoView';
import UsuarioView from '../usuario/UsuarioView';
import { objectsConstants } from '../../_constants/objects.constants';

export default class PerfilPage extends React.Component<{}> {
  abrePerfil() {
    if (userService.isMedico()) {
      return (
        <MedicoView clickBackUrl={true} redirectPath={'/perfil/'}></MedicoView>
      );
    } else if (
      userService.isSecretaria() ||
      userService.isAdministrador() ||
      userService.isSudo()
    ) {
      return (
        <UsuarioView
          clickBackUrl={true}
          redirectPath={'/perfil/'}
        ></UsuarioView>
      );
    }
  }

  componentDidMount() {

  }

  render() {
    return <React.Fragment>{this.abrePerfil()}</React.Fragment>;
  }
}
