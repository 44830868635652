//@flow
import _ from 'lodash';
import { toInteger } from 'lodash';
import { toNumber } from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import FormInput from '../../../../components/inputs/FormInput';
import FormSelectInput from '../../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../../components/inputs/InputViewEdit';
import MoneyInput from '../../../../components/inputs/MoneyInput';
import SelectInput from '../../../../components/inputs/SelectInput';
import IconDelete from '../../../../images/icon_x_red.svg';
import { dateHelper } from '../../../../_helpers/date.helper';
import { formatterHelper } from '../../../../_helpers/formatter.helper';
import { estoqueService } from '../../../../_services/estoque.service';

type Props = {
  entity: any,
};

export default class ProdutoEntrada extends React.Component<Props, State> {
  calculaCustoTotal = () => {
    const { entity, propName } = this.props;
    const custoUnit = _.get(entity, `${propName}.custoUnitario`);
    const qtd = _.get(entity, `${propName}.quantidade`);
    if (custoUnit && qtd) {
      return toNumber(custoUnit) * toInteger(qtd);
    }
  };

  render() {
    const { entity, propName, index, viewMode, size } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            component={SelectInput}
            id={`produto${index}`}
            name={`${propName}.produto`}
            label="Produto"
            onFetchData={estoqueService.findByNome}
            onChange={(name, value) => {
              let estoqueUnidade = _.get(value, 'estoqueUnidade');
              this.props.onChange(`${propName}.estoqueUnidade`, estoqueUnidade);
              let newValue = _.omit(value, 'estoqueUnidade');
              console.log(newValue);
              this.props.onChange(name, newValue);
            }}
            value={_.get(entity, `${propName}.produto`)}
            labelKey={'nome'}
            valueKey={'id'}
            multi={false}
            returnFullObject={true}
            creatable={false}
            required={true}
            viewMode={viewMode}
            erroMensagem={_.get(this.props.errors, `${propName}.produto`)}
            placeholder="Informe o nome ou codigo do produto"
            defaultValue={
              _.get(entity, `${propName}.produto`)
                ? _.get(entity, `${propName}.produto`).nome
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl">
          <InputViewEdit
            component={FormInput}
            label={'lote'}
            type="text"
            id={`lote${index}`}
            name={`${propName}.lote.nome`}
            placeholder="Informe o lote do produto"
            value={_.get(entity, `${propName}.lote.nome`)}
            onChange={this.props.onChange}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.lote.nome`)}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl">
          <InputViewEdit
            component={FormInput}
            label={'Localização'}
            type="text"
            id={`localizacao${index}`}
            name={`${propName}.lote.localizacao`}
            placeholder="Informe a localização do produto"
            value={_.get(entity, `${propName}.lote.localizacao`)}
            onChange={this.props.onChange}
            noSize={true}
            erroMensagem={_.get(
              this.props.errors,
              `${propName}.lote.localizacao`
            )}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={DatePickerInput}
            label="Validade do lote"
            name={`${propName}.lote.validade`}
            erroMensagem={_.get(this.props.errors, `${propName}.lote.validade`)}
            id={`validade${index}`}
            value={_.get(entity, `${propName}.lote.validade`)}
            onChange={this.props.onChange}
            placeholder={'--/--/----'}
            viewMode={viewMode}
            required={true}
            defaultValue={
              _.get(entity, `${propName}.lote.validade`)
                ? dateHelper.format(
                    _.get(entity, `${propName}.lote.validade`),
                    {
                      mode: 'DATE',
                    }
                  )
                : '--'
            }
          />
        </FormGroup>

        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={MoneyInput}
            label={'Custo unitário'}
            type="text"
            id={`custoUnitario${index}`}
            name={`${propName}.custoUnitario`}
            placeholder="Informe o custo unitário"
            value={_.get(entity, `${propName}.custoUnitario`)}
            onChange={(name, value) => {
              this.props.onChange(name, value);
            }}
            defaultValue={formatterHelper.money(
              _.get(entity, `${propName}.custoUnitario`)
            )}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.custoUnitario`)}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={FormInput}
            label={'quantidade'}
            type="number"
            id={`quantidade${index}`}
            name={`${propName}.quantidade`}
            placeholder="Informe a quantidade"
            value={_.get(entity, `${propName}.quantidade`)}
            onChange={(name, value) => {
              this.props.onChange(name, value);
            }}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.quantidade`)}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={SelectInput}
            label={'Unidade'}
            type="text"
            id={`unidadePadrao${index}`}
            name={`${propName}.estoqueUnidade`}
            placeholder=""
            value={_.get(entity, `${propName}.estoqueUnidade`)}
            labelKey={'unidadePadrao.nome'}
            valueKey={'unidadePadrao.id'}
            viewMode={viewMode}
            defaultValue={
              _.get(entity, `${propName}.estoqueUnidade`)
                ? _.get(entity, `${propName}.estoqueUnidade.unidadePadrao.nome`)
                : '--'
            }
            returnFullObject={true}
            creatable={false}
            required={true}
            disabled
          />
        </FormGroup>
        {/* <InputViewEdit
            component={FormInput}
            label={'Unidade'}
            type="text"
            id={`estoqueUnidade${index}`}
            name={`${propName}.estoqueUnidade`}
            placeholder=""
            value={_.get(entity, `${propName}.estoqueUnidade`)}
            defaultValue={
              _.get(entity, `${propName}.estoqueUnidade`)
                ? _.get(entity, `${propName}.estoqueUnidade.unidadePadrao.nome`)
                : '--'
            }
            // onChange={this.props.onChange}
            noSize={true}
            // erroMensagem={_.get(this.props.errors, `${propName}.unidadePadrao`)}
            disabled={true}
          /> 
        </FormGroup>*/}
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={FormInput}
            label={'Custo total'}
            type="number"
            id={`custoTotal${index}`}
            name={`${propName}.custoTotal`}
            placeholder=""
            value={formatterHelper.money(this.calculaCustoTotal())}
            onChange={this.props.onChange}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.custoTotal`)}
            viewMode={true}
            required={true}
          />
        </FormGroup>
        <div
          className={`form-group col-2 col-lg-1 align-items-center justify-content-start ${
            viewMode ? 'd-none' : ' d-flex '
          }`}
        >
          <div
            className={`icon-delete d-flex align-items-end justify-content-start$ {
              size === 1 || viewMode ? 'disabled' : ''
            }`}
            onClick={() => {
              this.props.removeItem(index, entity);
            }}
            disabled={size === 1}
          >
            <img src={IconDelete} alt="img" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
