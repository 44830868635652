//@flow
//@flow
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DadosUser from '../../../components/DadosUser/DadosUser';
import HorarioRow from '../../../components/horarios/HorarioRow';
import { objectsConstants } from '../../../_constants/objects.constants';
import "./FilaAtendimentoRow.scss"
import ButtonAutorized from "../../../components/buttons/ButtonAutorized";
import moment from "moment";
import Avatar from "../../../components/Avatar/Avatar";

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
  marcacao: any,
};

type State = {
  id?: number,
};

export class FilaAtendimentoRow extends React.Component<Props, State> {
  render() {
    console.log(this.props)
    return (
      <div className='row list-pacientes-espera justify-content-center align-items-center text-center p-4'>

        <div className=" col-3">
            <div className="dados-user d-flex align-items-center justify-content-start">
                <Avatar
                    namePropsMidiaId={"foto.id"}
                    user={this.props.marcacao.paciente}
                    editable={false}
                    classNameCustom={"avatar-card"}
                ></Avatar>
                <div className="d-flex flex-column pl-2 min-0">
                    <p
                        className="nome-paciente"
                        title={this.props.marcacao.paciente.nome}
                    >
                        {this.props.marcacao.paciente.apelido
                            ? this.props.marcacao.paciente.apelido
                            : this.props.marcacao.paciente.nome}
                    </p>
                </div>
            </div>
         </div>
        
          
            <div className=" col-2">Horário agendado:<b> {this.props.marcacao.horario}</b></div>
            <div className=" col-2">Chegou: <b> {this.props.marcacao.horaChegada}</b></div>
          
          <div  className="col-2">Aguardando a <b> {moment.duration(moment().diff(moment(this.props.marcacao.horaChegada, ['h:m a', 'H:m']))).hours()  } horas e  {moment.duration(moment().diff(moment(this.props.marcacao.horaChegada, ['h:m a', 'H:m']))).minutes()  } minutos</b></div>

          
          <div className='col-2'>
            {this.props.marcacao&&this.props.marcacao.status=='AGUARDANDO_RECEPCAO'&&(
                <ButtonAutorized
                    className="btn-primary open"
                    label={'Chamar Paciente'}
                    role={objectsConstants.AGENDA_COMPARECER}
                    onClick={() => {
                      this.props.onStatusChanged(
                          this.props.marcacao,
                          objectsConstants.AGUARDANDO_ATENDIMENTO,
                          true,
                          this.props.estabelecimento
                      );
                    }}
                ></ButtonAutorized>
            )}
          </div>
        
      </div>
    )
  };
}