import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import { Md5 } from 'ts-md5/dist/md5';
import * as moment from 'moment-timezone';

/**
 * Props
 *
 */
type Props = {
  pacientesData: Analytic[],
  showSyntheticData: boolean,
  showAnalyticData: boolean,
};

/**
 * State
 *
 */
type State = {
  syntheticData: SyntheticData,
  analyticData: Analytic[],
  showSyntheticData: boolean,
  showAnalyticData: boolean,
  showAnalyticData: boolean,
  hasAnalyticData: boolean,
};

/**
 * SyntheticData
 *
 */
interface SyntheticData {
  quantidadePacientesAgendados: number;
  quantidadePacientesAtendidos: number;
  quantidadePacientesConfirmados: number;
  quantidadePacientesFaltantes: number;
}

/**
 * Analytic
 *
 */
interface Analytic {
  // period: string;
  agendados: number;
  confirmados: number;
  atendidos: number;
  faltantes: number;
}

/**
 * Summaray Dashboard Component
 *
 */
class SummaryDashboardComponent extends React.Component<Props, State> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Responsible change componente.
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    let newHashProps: string = this.generateHasProps(
      this.props.pacientesData,
      this.props.showSyntheticData,
      this.props.showAnalyticData
    );
    if (newHashProps !== prevState.hashProps) {
      this.setState(this.getInitialState());
    }
  }

  /**
   * Responsible get initial state
   *
   * @return {{showSyntheticData: (boolean|boolean), showAnalyticData: (boolean|boolean), syntheticData: SyntheticData, analyticData: Analytic[]}}
   */
  getInitialState(): any {
    return {
      syntheticData: this.getSyntheticData(this.props.pacientesData),
      analyticData: this.props.pacientesData.slice().reverse(),
      showSyntheticData:
        this.props.showSyntheticData !== undefined
          ? this.props.showSyntheticData
          : true,
      showAnalyticData:
        this.props.showAnalyticData !== undefined
          ? this.props.showAnalyticData
          : false,
      hashProps: this.generateHasProps(
        this.props.pacientesData,
        this.props.showSyntheticData,
        this.props.showAnalyticData
      ),
      hasAnalyticData: this.hasData(),
    };
  }

  /**
   * Responsible generate hash.
   *
   * @param analyticData
   * @param showSyntheticData
   * @param showAnalyticData
   * @return {string}
   */
  generateHasProps = (
    analyticData: Analytic[],
    showSyntheticData: boolean,
    showAnalyticData: boolean
  ): string => {
    return Md5.hashStr(
      JSON.stringify(analyticData) +
        JSON.stringify(showSyntheticData) +
        JSON.stringify(showAnalyticData)
    );
  };

  /**
   *
   * Responsible validate if has data.
   *
   * @return {boolean}
   */
  hasData = (): boolean => {
    return this.props.pacientesData && this.props.pacientesData.length > 0;
  };

  /**
   * Responsible get SyntheticData
   *
   * @param pacientesData - pacientesData
   * @returns SyntheticData
   */
  getSyntheticData(pacientesData: Analytic[]): SyntheticData {
    if (pacientesData) {
      let quantidadePacientesAgendados = 0;
      let quantidadePacientesAtendidos = 0;
      let quantidadePacientesConfirmados = 0;
      let quantidadePacientesFaltantes = 0;
      _.forEach(pacientesData, function (pacienteData: Analytic) {
        quantidadePacientesAgendados += pacienteData.agendados;
        quantidadePacientesAtendidos += pacienteData.atendidos;
        quantidadePacientesConfirmados += pacienteData.confirmados;
        quantidadePacientesFaltantes += pacienteData.faltantes;
      });
      return {
        quantidadePacientesAgendados: quantidadePacientesAgendados,
        quantidadePacientesAtendidos: quantidadePacientesAtendidos,
        quantidadePacientesConfirmados: quantidadePacientesConfirmados,
        quantidadePacientesFaltantes: quantidadePacientesFaltantes,
      };
    } else {
      return {
        quantidadePacientesAgendados: 0,
        quantidadePacientesAtendidos: 0,
        quantidadePacientesConfirmados: 0,
        quantidadePacientesFaltantes: 0,
      };
    }
  }

  /**
   * Responsible get color anality data
   *
   * @param id - id
   * @param data - data
   * @returns {string}
   */
  getColorAnalyticData(id, data) {
    if (id && data) {
      switch (id) {
        case 'agendados':
          return '#BC4B0D';
        case 'confirmados':
          return '#02457A';
        case 'atendidos':
          return '#606C38';
        case 'faltantes':
          return '#8c2f39';
      }
    }
  }

  /**
   * Responsible get legend label
   *
   * @returns {string}
   */
  //   getLegendLabel(): string {
  //     if (
  //       this.state.analyticData &&
  //       this.state.analyticData[0] &&
  //       this.state.analyticData[0]
  //     ) {
  //       if (this.state.analyticData[0].period.length === 10) {
  //         return 'Dias';
  //       } else if (this.state.analyticData[0].period.length === 7) {
  //         return 'Meses';
  //       } else {
  //         return 'Anos';
  //       }
  //     } else {
  //       return '';
  //     }
  //   }

  /**
   * Responsible format date.
   *
   * @param datum
   * @return {string}
   */
  formatDateIndex = (datum: RawDatum): string => {
    if (datum && datum.period) {
      let splitDate: Array<string> = datum.period.split('-');
      if (splitDate.length > 1) {
        let finalDate: string = '';
        splitDate
          .slice()
          .reverse()
          .forEach((value, index, array) => {
            finalDate += value + (index + 1 < array.length ? '/' : '');
          });
        return finalDate;
      }
    }
    return datum.period;
  };

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    console.log(this.state);
    return (
      <React.Fragment>
        {this.state.showSyntheticData && (
          <Container fluid className="mt-3 d-flex justify-content-center">
            <Row className="justify-content-around metric-cards">
              <Col className="col-md-auto">
                <Card className="metric-card bg-light">
                  <Card.Body className="metric-card-body">
                    <Card.Title className="metric-card-title d-flex justify-content-center">
                      Pacientes agendados
                    </Card.Title>
                    <Card.Text className="metric-card-text metric-card-pacientes-agendados d-flex justify-content-center">
                      {this.state.syntheticData.quantidadePacientesAgendados |
                        0}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-md-auto">
                <Card className="metric-card bg-light">
                  <Card.Body className="metric-card-body">
                    <Card.Title className="metric-card-title d-flex justify-content-center">
                      Pacientes confirmados
                    </Card.Title>
                    <Card.Text className="metric-card-text metric-card-pacientes-confirmados d-flex justify-content-center">
                      {this.state.syntheticData.quantidadePacientesConfirmados |
                        0}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-md-auto">
                <Card className="metric-card bg-light">
                  <Card.Body className="metric-card-body">
                    <Card.Title className="metric-card-title d-flex justify-content-center">
                      Pacientes atendidos
                    </Card.Title>
                    <Card.Text className="metric-card-text metric-card-pacientes-atendidos d-flex justify-content-center">
                      {this.state.syntheticData.quantidadePacientesAtendidos |
                        0}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-md-auto">
                <Card className="metric-card bg-light">
                  <Card.Body className="metric-card-body">
                    <Card.Title className="metric-card-title d-flex justify-content-center">
                      Pacientes faltantes
                    </Card.Title>
                    <Card.Text className="metric-card-text metric-card-pacientes-faltantes d-flex justify-content-center">
                      {this.state.syntheticData.quantidadePacientesFaltantes |
                        0}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        {this.state.showAnalyticData && (
          <Container fluid className="mt-3 d-flex justify-content-center">
            <Row className="justify-content-around metric-cards">
              <Col>
                <Card className="chart-card-full bg-light">
                  <Card.Body className="chart-card-body">
                    <Card.Title className="chart-card-title d-flex justify-content-center">
                      Agendamentos
                    </Card.Title>
                    {!this.state.hasAnalyticData && (
                      <React.Fragment>
                        <div className="chart-text-blank justify-content-center">
                          Não existem dados a serem exibidos para essa pesquisa.
                        </div>
                      </React.Fragment>
                    )}
                    {this.state.hasAnalyticData && (
                      <React.Fragment>
                        <div className="chart-card-full">
                          <ResponsiveBar
                            keys={[
                              'agendados',
                              'confirmados',
                              'atendidos',
                              'faltantes',
                            ]}
                            margin={{
                              top: 60,
                              right: 110,
                              bottom: 70,
                              left: 80,
                            }}
                            indexBy={this.formatDateIndex}
                            padding="0.2"
                            labelTextColor="inherit:darker(1.4)"
                            labelSkipHeight="16"
                            labelSkipWidth="16"
                            legends={[
                              {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                toggleSerie: true,
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: 'hover',
                                    style: {
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: -63,
                              //   legend: this.getLegendLabel(),
                              legendPosition: 'end',
                              legendOffset: 50,
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Quantidade',
                              legendPosition: 'end',
                              legendOffset: -60,
                            }}
                            data={this.state.analyticData}
                            height={500}
                            colors={({ id, data }) =>
                              this.getColorAnalyticData(id, data)
                            }
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

/**
 * Export default connect
 *
 */
export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  {}
)(SummaryDashboardComponent);
