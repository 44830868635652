//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const prontuarioReceituarioService = {
  doSave,
  imprimir,
};

function doSave(prontuario) {
  return defaultService.doSave(`${urlsConstants.RECEITUARIO}`, prontuario);
}

function imprimir(prontuarioReceituario) {
  return defaultService.doPost(
    `${urlsConstants.PRONTUARIO_RECEITUARIO_IMPRIMIR}`,
    prontuarioReceituario
  );
}
