//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const cooperativaService = {
  findAll
};

function findAll() {

  return defaultService.doGet(`${urlsConstants.COOPERATIVAS}findAll`);
}
