//@flow
import { Formik } from 'formik';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'reactstrap';
import * as Yup from 'yup';
import FormInput from '../../components/inputs/FormInput';
import { userService } from '../../_services/user.service';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const EsqueciSenhaValidateModel = Yup.object().shape({
  email: Yup.string().required('E-mail obrigatório').email('Email inválido'),
});

type Props = {
  email?: string,
};

type State = {
  email?: string,
};

class SenhaEnviadaForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let email = '';
    this.state = { email };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-10 offset-1 text-center mt-3">
            <h2 className="text-primary">
              <b>E-mail de recuperação enviado!</b>
            </h2>
          </div>
          <div className="col-10 offset-1 text-center">
            <p>
              Para recuperar o seu acesso siga os passos enviado no seu e-mail.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col mb-15-negative text-center">
            {this.props.emailMask}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load }),
});
const connectedLoginForm = connect(
  mapStateToProps,
  mapDispatch
)(SenhaEnviadaForm);
export { connectedLoginForm as SenhaEnviadaForm };
