import { Formik } from 'formik';
import React from 'react';
import _ from 'lodash';
import { Button, Form, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import FormInput from '../../../components/inputs/FormInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import DumbNumber from '../../../components/countNumber/DumbNumber';
import { formatterHelper } from '../../../_helpers/formatter.helper';
import { registerService } from '../../../_services/register.service';
import { pagamentoService } from '../../../_services/pagamento.service';
import { translate } from '../../../_helpers/messages.helper';
import { dateHelper } from '../../../_helpers/date.helper';

const REQUIRED_STRING_VALIDATE = Yup.string()
  .nullable()
  .required('Campo obrigatório');

const Step4ValidateModel = Yup.object().shape({
  quantidadeProfissionais: REQUIRED_STRING_VALIDATE,
});

class Step4 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: 1,
      valor_unitario: 0.0,
      licencasEmUso: 0,
    };
  }

  componentDidMount() {
    registerService.getValorProssional().then(
      (response) => {
        let valor_unitario = response.data;
        this.setState({ valor_unitario }, () => {});
      },
      (error) => {
        console.log('Erro: ', error);
      }
    );
    if (this.props.editMode) {
      this.props.loading(true);
      pagamentoService.licencasEmUso().then(
        (response) => {
          let licencasEmUso = response.data;
          this.setState({ licencasEmUso }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          this.props.loading(false);
          console.log('Erro: ', error);
        }
      );
    }
  }
  validaCupom = (codigoCupom, setFieldError, setFieldValue) => {
    registerService.getCupomDesconto(codigoCupom).then(
      (response) => {
        let cupom = response.data.data;
        console.log(cupom);
        setFieldValue('cupomDesconto', cupom);
        this.props.handleChange('cupomDesconto', cupom);
      },
      (erros) => {
        setFieldError('codigoCupom', 'Código inválido ou já utilizado');
        setFieldValue('cupomDesconto', undefined);
        this.props.handleChange('', undefined);
      }
    );
  };

  saveForm = () => {
    const { callbackOnSave } = this.props;
    let _this = this;
    let values = _this.form.state.values;

    let promise = new Promise(function (resolve) {
      if (
        parseInt(_.get(values, 'quantidadeProfissionais')) >=
        _this.state.licencasEmUso
      ) {
        _this.form.validateForm(values).then((error) => {
          console.log(error);
          if (_.isEmpty(error)) {
            pagamentoService.updateLicenses(values).then(
              (response) => {
                console.log(response.data);

                _this.props.success({
                  message: `Números de licenças atualizados com sucesso!`,
                });
                _this.props.pagamentoAutorizado();
                // _this.setState({ cardNumber: response.data.cardNumber }, () => {
                //   _this.props.loading(false);
                resolve(true);
                // });
              },
              (erros) => {
                console.log(erros);
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    this.props.error({
                      message: translate(erroItem.message.code),
                    });
                  }
                }

                _this.props.loading(false);
                resolve(false);
              }
            );
          } else {
            resolve(false);
          }
        });
      } else {
        _this.form.setFieldError(
          'quantidadeProfissionais',
          'Numero de licenças não pode ser menor que o numero de liceças utilizadas!'
        );
        resolve(false);
      }
    });
    return promise;
  };

  calculaPorcentagem = (values) => {
    var valorCheio =
      this.state.valor_unitario * _.get(values, 'quantidadeProfissionais');
    var desconto =
      (_.get(values, 'cupomDesconto.percentualDesconto') / 100) * valorCheio;
    var valorComDesconto = valorCheio - desconto;

    if (_.get(values, 'cupomDesconto.percentualDesconto')) {
      return valorComDesconto;
    }
    return valorCheio;
  };

  render() {
    console.log(this.props.entity.estabelecimento);
    return (
      <Formik
        validationSchema={Step4ValidateModel}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.props.entity}
        ref={(form) => {
          this.form = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldError,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="row form-inside">
              <div className="col-12 section-form">
                {!this.props.editMode && (
                  <p>Passo 4 de 6 - Dados para pagamento</p>
                )}
                <div className="row">
                  <div className="container">
                    <div className="row justify-content-end text-center align-items-center by-1 p-2">
                      <div className="col-6"></div>
                      <div className="col-2">
                        {' '}
                        {!this.props.viewMode
                          ? 'Quatidade'
                          : 'Quantidade de profissionais atual'}
                      </div>
                      <div className="col-2">Unitário</div>
                      <div className="col-2">Valor total</div>
                    </div>
                    <div className="row justify-content-end text-center align-items-center p-2">
                      {!this.props.viewMode && (
                        <div className="col-6 text-right">
                          Informe a quantidade de profissionais da saúde para
                          cálculo do valor:
                        </div>
                      )}
                      {this.props.viewMode && (
                        <React.Fragment>
                          <div className="col-6 text-right">
                            Total contratado
                          </div>
                        </React.Fragment>
                      )}
                      <FormGroup className="col-2">
                        <InputViewEdit
                          component={FormInput}
                          label=""
                          type="number"
                          name="quantidadeProfissionais"
                          id="quantidadeProfissionais"
                          placeholder="Número de profissionais"
                          viewMode={this.props.viewMode}
                          required={true}
                          value={_.get(values, 'quantidadeProfissionais')}
                          onChange={this.props.handleChange}
                          erroMensagem={_.get(
                            errors,
                            'quantidadeProfissionais'
                          )}
                        />
                      </FormGroup>

                      <FormGroup className="col-2">
                        <InputViewEdit
                          component={FormInput}
                          label=""
                          type="text"
                          name="unitario"
                          id="unitario"
                          placeholder="R$ 0,00"
                          viewMode={true}
                          required={true}
                          value={formatterHelper.money(
                            this.state.valor_unitario
                          )}
                          onChange={this.props.handleChange}
                          erroMensagem={_.get(errors, 'unitario')}
                        />
                      </FormGroup>
                      <FormGroup className="col-2">
                        <InputViewEdit
                          component={FormInput}
                          label=""
                          type="text"
                          name="total"
                          id="total"
                          placeholder="R$ 0,00"
                          required={true}
                          viewMode={true}
                          value={formatterHelper.money(
                            this.calculaPorcentagem(values)
                          )}
                          onChange={this.props.handleChange}
                          erroMensagem={_.get(errors, 'total')}
                        />
                      </FormGroup>
                    </div>
                    {this.props.editMode && (
                      <React.Fragment>
                        <div className="row justify-content-end align-items-center mt-2 p-2 pr-0">
                          <div className="col-6 text-right txt-destaque txt-green pr-0">
                            Profissionais vinculados no estabelecimento:{' '}
                            {this.state.licencasEmUso}
                          </div>
                        </div>
                        {this.props.entity.estabelecimento &&
                          this.props.entity.estabelecimento
                            .quantidadeProfissionaisRemover > 0 && (
                            <div className="row justify-content-end align-items-center mt-2 p-2 pr-0">
                              <div className="col-6 text-right txt-destaque txt-red pr-0">
                                Profissionais que serão removidos no fim do
                                ciclo de pagamento (
                                {dateHelper.format(
                                  _.get(
                                    this.props.entity,
                                    'estabelecimento.dataProximoPagamento'
                                  ),
                                  {
                                    mode: 'DATE',
                                  }
                                )}
                                ):{' '}
                                {_.get(
                                  this.props.entity,
                                  'estabelecimento.quantidadeProfissionaisRemover'
                                )}
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    )}
                    <div className="row justify-content-end align-items-center p-2">
                      {!this.props.viewMode && (
                        <React.Fragment>
                          {!this.props.editMode && (
                            <React.Fragment>
                              <div className="col-3 offset-4 text-right">
                                Digite o código do cupom de desconto:
                              </div>
                              <FormGroup className="col-2 text-left">
                                <InputViewEdit
                                  component={FormInput}
                                  label=""
                                  type="text"
                                  name="codigoCupom"
                                  id="codigoCupom"
                                  placeholder=""
                                  viewMode={this.props.viewMode}
                                  required={false}
                                  value={_.get(values, 'codigoCupom')}
                                  onChange={this.props.handleChange}
                                  erroMensagem={_.get(errors, 'codigoCupom')}
                                />
                              </FormGroup>
                              <div className="col-1 text-right">
                                <Button
                                  color="secondary float-left"
                                  onClick={() => {
                                    this.validaCupom(
                                      _.get(values, 'codigoCupom'),
                                      setFieldError,
                                      setFieldValue
                                    );
                                  }}
                                >
                                  Validar
                                </Button>
                              </div>
                            </React.Fragment>
                          )}
                          <div className="col-2 text-left">
                            {_.get(
                              values,
                              'cupomDesconto.percentualDesconto'
                            ) && (
                              <h6 className="mb-0 ml-2 txt-cupom-desconto">
                                <b>
                                  {_.get(
                                    values,
                                    'cupomDesconto.percentualDesconto'
                                  )}
                                  % Off
                                </b>
                              </h6>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                      {this.props.viewMode && (
                        <React.Fragment>
                          <div className="col-5 text-right">
                            {_.get(
                              values,
                              'cupomDesconto.percentualDesconto'
                            ) && (
                              <h6 className="txt-cupom-desconto">
                                <b>
                                  Cupom aplicado:{' '}
                                  {_.get(
                                    values,
                                    'cupomDesconto.percentualDesconto'
                                  )}
                                  % Off
                                </b>
                              </h6>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {/* <div className="row justify-content-end align-items-center p-2">
                      <div className="col-2 offset-8 text-right">Sub-total</div>
                      <div className="col-2 text-left">
                        <b>R$ 0,00 </b>
                      </div>
                      <div className="col-2 offset-8 text-right">Total</div>
                      <div className="col-2 text-left">
                        <b>R$ 0,00 </b>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Step4;
