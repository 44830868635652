//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { objectsConstants } from '../../_constants/objects.constants';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { translate } from '../../_helpers/messages.helper';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  disabled: boolean,
  style?: any,
  messageValidate?: string
};

class CheckBoxInputOnly extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false
  };

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.checked &&
      !_.isEqual(nextProps.checked, this.props.checked)
    ) {
      this.setState({ checked: nextProps.checked }, () => {

      });
    }

  }

  getInput = () => {
    return this._input;
  };

  toggleLine = e => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onChange(this.state.checked, this.props.name);
    });
  };

  render() {
    const { messages, name } = this.props;

    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName === name) {
          messageValidate = translate(message.message.code);
        }
      });
    }

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }

    if (!this.props.viewMode) {
      return (
        <React.Fragment>
          {
            this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={'help_' + this.props.id} />
                <ToolTipHelp
                  target={'help_' + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )
          }


          <div className={`top my-auto ${this.props.className} ${messageValidate ? 'is-invalid' : ''} `} onClick={this.toggleLine}>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={this.state.checked}
                onChange={() => { }}
                ref={input => {
                  this._input = input;
                }}
                id={this.props.id}
                name={this.props.name}
                disabled={this.props.disabled}
              />

              <i className="icon-checkbox" />
              <span className={`pl-2 ${this.props.labelClass}`}>{this.props.label}</span>
            </label>
          </div>
        </React.Fragment>
      );
    }

    if (this.props.viewMode) {
      return (
        <div className={`top my-auto ${messageValidate ? 'is-invalid' : ''} `} onClick={this.toggleLine}>
          <p className='form-view'>
            {
              this.state.checked === true ? 'Sim' : 'Não'
            }
          </p>
        </div>
      );
    }


  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(CheckBoxInputOnly);
