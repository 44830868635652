import React, { PureComponent } from "react";
import { Modal, ModalBody } from "reactstrap";
import SaveCancelCardBtn from "../buttons/SaveCancelCardBtn";

type Props = {
  target?: any,
  itens: [],
  titleBtnSave: String,
  titleBtnCancel: String
};

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};
let fileInput;

export class ModalCrud extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: props.viewMode
    };
  }
  static defaultProps = {
    titleBtnSave: "Salvar",
    titleBtnCancel: "Cancelar",
  }

  setEditMode = () => {
    this.setState({ viewMode: !this.state.viewMode }, () => {
      if (this.props.setEditMode) {
        this.props.setEditMode();
      }
    });
  };

  cancelForm = () => {
    if (this.props.onCancel) {
      this.props.onCancel(this.state.edit);
    }
    if (this.refComponent && this.refComponent.resetForm) {
      this.refComponent.resetForm(this.props.entity);
    }
    this.props.toogle();
  };
  saveForm = () => {
    if (this.refComponent) {
      this.refComponent.saveForm().then(result => {
        if (result) {
          if (this.props.onSave) {
            this.props.onSave(result);
          }
        }
      });
    }
  };

  render() {
    const { title, Componente, viewMode, entity, ...otherProps } = this.props;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className={`cinza modal-lg modalCrud ${this.props.className}`}
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <div className="d-flex align-items-center modal-header">
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between modal-header-info">
              <h1>{title}</h1>
              <div className="box-buton d-flex align-items-center justify-content-center mt-3 mt-lg-0">
                <SaveCancelCardBtn
                  permissionSave={this.props.permissionSave}
                  entity={this.props.entity}
                  onSave={this.saveForm}
                  onCancel={this.cancelForm}
                  titleBtnSave={this.props.titleBtnSave}
                  titleBtnCancel={this.props.titleBtnCancel}

                />
              </div>
            </div>
          </div>
          <ModalBody>
            <React.Fragment>
              {Componente && (
                <Componente
                  ref={form => {
                    this.refComponent = form;
                  }}
                  viewMode={this.state.viewMode}
                  entity={entity}
                  {...otherProps}
                />
              )}
            </React.Fragment>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
