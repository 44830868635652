//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { pacoteService } from '../../_services/pacote.service';
import PacoteListFilter from './PacoteListFilter';


const columns = [

  {
    path: 'codigo',
    pathFilter: 'codigo',
    title: 'Codigo do pacote',
    isKey: true,
    dataSort: true,
    className: 'column-bold',
    width: '33%',
  },
  {
    path: 'descricao',
    pathFilter: 'descricao',
    title: 'Descrição',
    isKey: false,
    dataSort: true,
    width: '33%',
  },
  {
    path: 'valorTotal',
    pathFilter: 'valorTotal',
    title: 'Valor total',
    isKey: false,
    dataSort: true,
    width: '33%',
    isMoney:true
  },
 
];

const endPoint = '/pacotes/novo';
const endPointView = '/pacotes/view/';

class PacoteListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    
  };
  calculaValorTotalPacote=(pacote)=>{
    let valorTotal=0.0;
    if (pacote&&pacote.procedimentos){
    pacote.procedimentos.map((procedimento,index)=>{
      valorTotal=valorTotal+  parseFloat(procedimento.valorProcedimento?procedimento.valorProcedimento:0.0);
    });}
    if(pacote&&pacote.materiais){
    pacote.materiais.map((material,index)=>{
      valorTotal=valorTotal+  parseFloat(material.quantidade && material.valor
        ? material.quantidade * material.valor
        : 0.0);
    });}
    return valorTotal;

  };
  renderCustom=(row: any, extra: any, column: any)=>{
    if(column.isValorTotal){
        return this.calculaValorTotalPacote(row);
    }else{
      return tableHelper.renderCustom(row,extra,column);
    }

  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_PACOTES}
          subTitle=" "
          canBack={false}
        />

        
        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_MEDICO_EDITAR}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo pacote'}
        ></TopoListagem>

        <ListagemPage
          title={`Pacotes`}
          showNewButton={false}
         
          delete={() => {}}
          service={pacoteService}
          
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={PacoteListFilter}
          fields={this.state.fields}
          controller="api/pacote"
          labels={this.state.labels}
          // roleNewButton={objectsConstants.CRUD_ESTABELECIMENTOS_EDITAR}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(PacoteListagemPage);
