//@flow
import _, { toNumber } from 'lodash';
import { toInteger } from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconEntradaSaida from '../../../images/icon-entrada-saida.png';
import { formatterHelper } from '../../../_helpers/formatter.helper';

export default class ProdutosEntradaSumario extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
  }

  calculaQuantidadeTotal = () => {
    const { entity } = this.props;
    let total = 0;
    entity.listEntradaEstoqueProduto.map((produto) => {
      let qtd = _.get(produto, 'quantidade');
      if (qtd != null) {
        total = total + toInteger(qtd);
      }
    });
    return total;
  };

  calculaValorTotal = () => {
    const { entity } = this.props;
    let total = 0.0;
    entity.listEntradaEstoqueProduto.map((produto) => {
      let qtd = _.get(produto, 'quantidade');
      let custoUnitario = _.get(produto, 'custoUnitario');
      if (custoUnitario != null) {
        total = total + toNumber(custoUnitario) * toInteger(qtd);
      }
    });
    return formatterHelper.money(total);
  };

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="card-central-sumario d-flex flex-column flex-xl-row">
          <div className="col-3 col-xl-2 d-flex align-items-center justify-content-center">
            <img src={IconEntradaSaida} alt="Imagem de agenda" />
          </div>
          <div className="col-9 col-xl-5 p-0">
            <h1>Produtos de entrada</h1>
            <p>
              Insira os produtos de entrada no estoque informando o lote,
              quantidade e validade.
            </p>
          </div>
          <div className="bl-1 col-12 col-xl-5 d-flex flex-column justify-content-around px-3 py-2">
            <p>
              <b>Total de produtos lançados: </b>
              <span>{_.get(entity, 'listEntradaEstoqueProduto').length}</span>
            </p>
            <p>
              <b>Quantidade total: </b>
              <span>{this.calculaQuantidadeTotal()}</span>
            </p>
            <p>
              <b>Valor total da nota: </b>
              <span>{this.calculaValorTotal()}</span>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
