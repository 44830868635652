// @flow
import React from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

const AuthorizedRoute = React.memo(({permissions ,component:Component,I,a,...others}) => {
  if (!permissions.can(I, a)) {
    return <Redirect
      to={{ pathname: '/401' }}
    />;
  }
  return <Component {...others}/>;
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(AuthorizedRoute);
