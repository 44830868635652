//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../components/Topo/TopoListagem';
import Step4 from '../cadastroWizard/components/Step4';
import Step5 from '../cadastroWizard/components/Step5';
import { objectsConstants } from '../../_constants/objects.constants';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { translate } from '../../_helpers/messages.helper';
import { statusPagamentoHelper } from '../../_helpers/statusPagamento.helper';

class PagamentoPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {},
    };
  }
  componentDidMount() {
    this.loadEntity();
  }

  loadEntity = () => {
    this.props.loading(true);
    let entity = {};
    estabelecimentoService.getPagamento().then(
      (response) => {
        let entity = _.cloneDeep(response.data.data);
        let estabelecimento = _.get(entity, 'estabelecimento');
        _.set(
          entity,
          'quantidadeProfissionais',
          _.get(estabelecimento, 'quantidadeProfissionais')
        );
        _.set(entity, 'tipoPagamento', _.get(entity, 'metodo'));
        this.setState({ entity: entity }, () => {
          this.props.loading(false);
        });
      },
      (erros) => {
        console.log(erros);
        let response = erros.response.data;
        if (response && response.messages) {
          for (var i = 0; i < response.messages.length; i++) {
            let erroItem = response.messages[i];
            this.props.error({
              message: translate(erroItem.message.code),
            });
          }
        }

        this.props.loading(false);
      }
    );
  };

  editDeleteButton = () => {
    return <React.Fragment></React.Fragment>;
  };

  handleChange = (name: any, value: string) => {
    let form = this.refComponent.refComponent.form;
    const entity = _.cloneDeep(form.state.values);
    _.set(entity, name, value);
    this.setState({
      entity,
    });
  };

  pagamentoAutorizado = () => {
    this.props.setErroPagamento({ erroPagamento: false });
    this.loadEntity();
  };

  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_PAGAMENTO}
          canBack={false}
        />

        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>

        <div
          className={` row ${statusPagamentoHelper.getCssClass(
            _.get(entity, 'statusOperacao')
          )} py-3 my-3 mx-0 justify-content-center`}
        >
          {_.get(entity, 'statusOperacao')}
        </div>

        <div className="flex-column">
          {!this.props.erroPagamento && (
            <FullCustomCard
              title="Profissionais"
              Componente={Step4}
              permissionSave={objectsConstants.MENU_ADMINNISTRADOR}
              viewMode={true}
              editMode={true}
              entity={entity}
              getEditDeleteBtn={
                _.get(entity, 'metodo') == objectsConstants.BOLETO
                  ? this.editDeleteButton
                  : null
              }
              onCancel={this.loadEntity}
              pagamentoAutorizado={this.pagamentoAutorizado}
              handleChange={this.handleChange}
              ref={(formRef) => {
                this.refComponent = formRef;
              }}
              loading={this.props.loading}
              success={this.props.success}
            ></FullCustomCard>
          )}
          <FullCustomCard
            title="Dados de pagamento"
            Componente={Step5}
            permissionSave={objectsConstants.MENU_ADMINNISTRADOR}
            pagamentoAutorizado={this.pagamentoAutorizado}
            viewMode={true}
            editMode={true}
            entity={entity}
            handleChange={this.handleChange}
            onCancel={this.loadEntity}
            ref={(formRef) => {
              this.refComponent = formRef;
            }}
            loading={this.props.loading}
            success={this.props.success}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { erroPagamento } = state.authentication;
  return {
    alert,
    erroPagamento,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading },
  authentication: { setErroPagamento },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  setErroPagamento: (erroPagamento) => setErroPagamento(erroPagamento),
});

export default connect(mapStateToProps, mapDispatch)(PagamentoPage);
