import React from "react";
import ReactDOM from "react-dom";
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
import { App } from "./App";
import "./App.scss";
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./store/store";
import { Provider } from "react-redux";
import RememberGate from "./pages/RememberGate";
ReactDOM.render(
<ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
  <Provider store={store}>
    <RememberGate>
    <App />
    </RememberGate>
    </Provider>
    </ClearBrowserCacheBoundary>,
  document.getElementById("root")
);
registerServiceWorker();
