//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import FormInput from '../inputs/FormInput';
import InputViewEdit from '../inputs/InputViewEdit';
import IconDelete from '../../images/icon_x_red.svg';
import { FormGroup } from '../FormGroup';

type Props = {
  entity: any,
};

type State = {
  daysOfWeek: [],
};

export class GuicheAtendimento extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { entity, propName, index, viewMode, size } = this.props;

    return (
      <React.Fragment>
          <FormGroup className="col-12 col-lg-4">
              <InputViewEdit
                  label="Nome"
                  viewMode={viewMode}
                  component={FormInput}
                  name={`${propName}.local`}
                  id={`Sala${index}Nome`}
                  value={_.get(entity, `${propName}.local`)}
                  onChange={this.props.onChange}
                  placeholder={'Recepção principal, Recepção segundo andar.'}
                  erroMensagem={_.get(this.props.errors, `${propName}.nome`)}
              />
          </FormGroup>
        <FormGroup className="col-12 col-lg-4">
          <InputViewEdit
            label="Nome"
            viewMode={viewMode}
            component={FormInput}
            name={`${propName}.nome`}
            id={`Sala${index}Nome`}
            value={_.get(entity, `${propName}.nome`)}
            onChange={this.props.onChange}
            placeholder={'Guichê, balcão, etc...'}
            erroMensagem={_.get(this.props.errors, `${propName}.nome`)}
          />
        </FormGroup>
        <FormGroup className="col-6 col-lg-1">
          <InputViewEdit
            label="de"
            viewMode={viewMode}
            component={FormInput}
            name={`${propName}.inicioNumero`}
            id={`inicio${index}Numero`}
            value={_.get(entity, `${propName}.inicioNumero`)}
            onChange={this.props.onChange}
            placeholder={'Nº'}
            type="number"
            erroMensagem={_.get(this.props.errors, `${propName}.inicioNumero`)}
          />
        </FormGroup>
        <FormGroup className="col-6 col-lg-1">
          <InputViewEdit
            label="até"
            viewMode={viewMode}
            component={FormInput}
            name={`${propName}.fimNumero`}
            id={`fim${index}Numero`}
            value={_.get(entity, `${propName}.fimNumero`)}
            onChange={this.props.onChange}
            placeholder={'Nº'}
            type="number"
            erroMensagem={_.get(this.props.errors, `${propName}.fimNumero`)}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1 justify-content-center align-items-center justify-content-md-start d-flex">
          <div className="text-right">
            <div
              disabled={size === 1}
              className={`icon-delete d-flex align-items-center justify-content-center ${
                size === 1 || viewMode ? 'disabled' : ''
              }`}
              onClick={() => {
                this.props.removeItem(index, entity);
              }}
              disabled={size === 1}
            >
              <img src={IconDelete} alt="img" />
            </div>
          </div>
        </FormGroup>
      </React.Fragment>
    );
  }
}

// const connectedMedicoFormHorario = connect()(GuicheAtendimento);
// export { connectedMedicoFormHorario as GuicheAtendimento };
