//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import FormInput from '../../../../components/inputs/FormInput';
import FormSelectInput from '../../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../../components/inputs/InputViewEdit';
import MoneyInput from '../../../../components/inputs/MoneyInput';
import SelectInput from '../../../../components/inputs/SelectInput';
import IconDelete from '../../../../images/icon_x_red.svg';
import { formatterHelper } from '../../../../_helpers/formatter.helper';
import { convenioService } from '../../../../_services/convenio.service';
import { defaultService } from '../../../../_services/defaultService';
import { unidadePadraoService } from '../../../../_services/unidadePadrao.service';

type Props = {
  entity: any,
};

export default class ItemPrecificacao extends React.Component<Props, State> {
  render() {
    const { entity, propName, index, viewMode, size } = this.props;

    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-6">
          <InputViewEdit
            label="Convênio(s)"
            className="flex-fill"
            // viewLabelClassName="d-none"
            viewClassName="font-weight-bold"
            placeholder="Selecione um convênio"
            component={FormSelectInput}
            service={convenioService.findAll}
            labelKey={'sigla'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, `${propName}.listConvenio`, []).map(
              (c, i) => {
                if (i > 0) {
                  return ', ' + c.nome;
                } else {
                  return c.nome;
                }
              }
            )}
            className={''}
            required={true}
            value={_.get(entity, `${propName}.listConvenio`)}
            name={`${propName}listConvenio`}
            viewMode={viewMode}
            isMulti={true}
            id={`listConvenio${index}Item`}
            onChange={(name, value) => {
              this.props.onChange(name, value);
            }}
            erroMensagem={_.get(this.props.errors, `${propName}.listConvenio`)}
            sortNoAsc={true}
            sortKey={'sigla'}
            creatable={true}
          />
        </FormGroup>

        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            viewClassName="font-weight-bold"
            name="unidadePadrao"
            label="Unidade"
            placeholder="Selecione a unidade"
            component={FormSelectInput}
            service={unidadePadraoService.findByEstabelecimento}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, `${propName}.unidadePadrao.nome`, '--')}
            required={true}
            value={_.get(entity, `${propName}.unidadePadrao`)}
            viewMode={viewMode}
            id={`unidadePadrao${index}Item`}
            name={`${propName}unidadePadrao`}
            onChange={(name, value) => {
              if (!value.id) {
                unidadePadraoService.doSave(value).then((response) => {
                  let id = defaultService.getIdFromUrl(
                    response.headers.location
                  );
                  _.set(value, 'id', id);
                  this.props.onChange(name, value);
                });
              } else {
                this.props.onChange(name, value);
              }
            }}
            erroMensagem={_.get(this.props.errors, `${propName}.unidadePadrao`)}
            sortNoAsc={true}
            sortKey={'nome'}
            creatable={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={MoneyInput}
            label={'preço de venda'}
            type="number"
            id={`precoVenda${index}`}
            name={`${propName}.precoVenda`}
            placeholder="Informe o preço de venda"
            value={_.get(entity, `${propName}.precoVenda`)}
            defaultValue={formatterHelper.money(
              _.get(entity, `${propName}.precoVenda`)
            )}
            onChange={this.props.onChange}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.precoVenda`)}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>

        <div
          className={`form-group col-2 col-lg-1 align-items-center justify-content-start ${
            viewMode ? 'd-none' : ' d-flex '
          }`}
        >
          <div
            className={`icon-delete d-flex align-items-end justify-content-start$ {
              size === 1 || viewMode ? 'disabled' : ''
            }`}
            onClick={() => {
              this.props.removeItem(index, entity);
            }}
            disabled={size === 1}
          >
            <img src={IconDelete} alt="img" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
