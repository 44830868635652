//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import DateTimePicker from '../../../components/inputs/DateTimePicker';
import FormInput from '../../../components/inputs/FormInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { objectsEnuns } from '../../../_constants/objects.constants';
import { dateHelper } from '../../../_helpers/date.helper';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import { grupoService } from '../../../_services/grupo.service';
import { estoqueService } from '../../../_services/estoque.service';
import { userService } from '../../../_services/user.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class EstoqueRelatorioPosicaoListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-2">
          <InputViewEdit
            component={DatePickerInput}
            label="Dia início"
            name={`dataStart`}
            id={`dataStart`}
            value={entity.dataStart}
            onChange={(name, value) => {
              let newValue = value.setHours(0, 0, 0, 0);
              this.props.onChangeDate(name, new Date(newValue));
            }}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataStart`)
                ? dateHelper.format(_.get(entity, `dataStart`), {
                    mode: 'DATE',
                  })
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-2">
          <InputViewEdit
            component={DatePickerInput}
            label="Dia fim"
            name={`dataEnd`}
            id={`dataEnd`}
            value={entity.dataEnd}
            onChange={(name, value) => {
              let newValue = value.setHours(23, 59, 0, 0);
              this.props.onChangeDate(name, new Date(newValue));
            }}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataEnd`)
                ? dateHelper.format(_.get(entity, `dataEnd`), {
                    mode: 'DATE',
                  })
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-5">
          <InputViewEdit
            label={'Produto'}
            placeholder="Selecione o Produto"
            component={SelectInput}
            onFetchData={estoqueService.findByNome}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            value={_.get(entity, 'produto')}
            id="produto"
            name="produto"
            onChange={this.props.onChangeDate}
            sortNoAsc={true}
            sortKey={'nome'}
            creatable={false}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <InputViewEdit
            label={'Grupo'}
            placeholder="Informe o grupo do produto"
            component={FormSelectInput}
            service={grupoService.findByEstabelecimento}
            labelKey={'nome'}
            valueKey={'id'}
            returnFullObject={true}
            value={_.get(entity, 'grupo')}
            id="grupo"
            name="grupo"
            onChange={this.props.onChangeDate}
            sortNoAsc={true}
            sortKey={'nome'}
            creatable={false}
          />
        </FormGroup>
        {/* <FormGroup className="col-12 col-xl-3">
          <InputViewEdit
            component={SelectInput}
            label={'Nome ou Cpf / CNPJ do usuário'}
            id="responsavel"
            name="responsavel"
            placeholder="Nome ou Cpf / CNPJ do usuário"
            value={_.get(entity, 'responsavel')}
            defaultValue={
              _.get(entity, 'responsavel')
                ? _.get(entity, 'responsavel').cpfCnpjJoinName
                : '--'
            }
            onChange={this.props.onChangeDate}
            noSize={true}
            returnFullObject={true}
            valueKey={'id'}
            labelKey={'cpfCnpjJoinName'}
            multi={false}
            onFetchData={userService.findByCpfCnpjOrName}
            sortKey={'cpfCnpjJoinName'}
          />
        </FormGroup> */}
      </React.Fragment>
    );
  }
}
