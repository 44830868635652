//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const cidService = {
  findAll
};

function findAll(description) {

  return defaultService.doGet(`${urlsConstants.CID}findAll/${description}`);
}
