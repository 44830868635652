//@flow
import React, { PureComponent } from 'react';
import IconDelete from '../../images/icon_x_red.svg';
import SaveCancelCardBtn from '../buttons/SaveCancelCardBtn';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { objectsConstants } from '../../_constants/objects.constants';
import { userService } from '../../_services/user.service';

type Props = {
  entity: any,
  step: number,
  getFormContent: any,
  onSave: any,
  removeItem: any,
  getForm: any,
  Componente: any,
  titleBtnSave: string,
  titleBtnCancel: string,
};
type State = {
  entity: any,
  edit: boolean,
};

class FullCustomCard extends PureComponent<Props, State> {
  static defaultProps = {
    titleBtnSave: 'Salvar',
    titleBtnCancel: 'Cancelar',
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      viewMode: props.viewMode,
    };
  }

  setEditMode = () => {
    this.setState({ viewMode: !this.state.viewMode }, () => {
      if (this.props.setEditMode) {
        this.props.setEditMode();
      }
    });
  };

  cancelForm = () => {
    this.setState({ viewMode: !this.state.viewMode }, () => {
      if (this.props.onCancel) {
        this.props.onCancel(this.state.edit);
      }
    });

    if (this.refComponent && this.refComponent.resetForm) {
      this.refComponent.resetForm(this.props.entity);
      if (this.props.entity && this.props.entity.idList) {
        this.props.removeItem(this.props.index, this.props.entity);
      }
    }
  };
  saveForm = () => {
    if (this.refComponent) {
      this.refComponent.saveForm().then((result) => {
        if (result) {
          this.setEditMode();
          if (this.props.onSave) {
            this.props.onSave(result);
          }
        }
      });
    }
  };
  getBtns = () => {
    if (!this.state.viewMode) {
      return (
        <SaveCancelCardBtn
          permissionSave={this.props.permissionSave}
          entity={this.props.entity}
          onSave={this.saveForm}
          onCancel={this.cancelForm}
          titleBtnCancel={this.props.titleBtnCancel}
          titleBtnSave={this.props.titleBtnSave}
        />
      );
    } else {
      if (this.props.getEditDeleteBtn) {
        return this.props.getEditDeleteBtn(this.setEditMode);
      } else {
        return (
          <React.Fragment>
            {!userService.isSuporte() && (
              <button
                type="button"
                className={`btn btn-link ${
                  this.props.btnExcluir ? 'd-block' : 'd-none'
                }`}
                onClick={() => {
                  if (this.props.entity.id) {
                    ConfirmAcationService.confirmMsg(
                      () => {
                        this.props.removeItem(
                          this.props.index,
                          this.props.entity
                        );
                      },
                      `Excluir registro  `,
                      `Deseja realmente realizar a exclusão deste registro?`,
                      null,
                      'Não',
                      'Sim',
                      objectsConstants.TYPE_EXCLUIR
                    );
                  } else {
                    this.props.removeItem(this.props.index, this.props.entity);
                  }
                }}
              >
                <span className="icon-btn-deletar"></span>
                Excluir
              </button>
            )}
            <button
              type="button"
              className="btn btn-link"
              onClick={this.setEditMode}
            >
              <span className="icon-btn-editar"></span>
              Editar
            </button>
            {(userService.isAdministrador() || userService.isSuporte()) && (
              <button
                type="button"
                className={`btn btn-link ${
                  this.props.exibeHistorico ? 'd-block' : 'd-none'
                }`}
                onClick={this.props.abreHistoricoCard}
              >
                <span className="icon-historico"></span>
                Histórico de alterações
              </button>
            )}
          </React.Fragment>
        );
      }
    }
  };

  render() {
    const { Componente, viewMode, ...otherProps } = this.props;

    return (
      <div
        className={`cardCrud ${
          !this.state.viewMode ? 'viewMode' : 'noViewMode'
        } ${this.props.className}`}
      >
        {this.props.title && (
          <div className="topo-card d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between">
            <h1>{this.props.title}</h1>
            <div className="box-buton d-flex flex-wrap align-items-center justify-content-center mt-3 mt-lg-0">
              {this.props.extraButton && this.props.extraButton()}
              {this.props.getBtns ? this.props.getBtns() : this.getBtns()}
            </div>
          </div>
        )}
        <div className="conteudo-card">
          {Componente && (
            <Componente
              ref={(form) => {
                this.refComponent = form;
              }}
              viewMode={this.state.viewMode}
              {...otherProps}
            />
          )}
        </div>
      </div>
    );
  }
}
export default FullCustomCard;
