//@flow
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import VoucherListagemPage from "./VoucherListagemPage";
import SolutiVoucherNew from "./SolutiVoucherNew";
import VoucherView from "./VoucherView";

export default class VoucherSolutiIndexPage extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/voucher-soluti" component={VoucherListagemPage} exact />
        <Route path="/voucher-soluti/new" component={SolutiVoucherNew} />
          <Route path="/voucher-soluti/view/:id" component={VoucherView} />
      </Switch>
    );
  }
}
