import { Can } from "@casl/react";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import packageJson from '../../../package.json';
import btnVoltar from "../../images/btn_voltar.svg";
import Icon_sair from "../../images/icones/log-out.svg";
import IconPagamento from '../../images/icon-payment.svg';
import Logo from '../../images/logosasccm.png';
import { objectsConstants } from "../../_constants/objects.constants";
import { userService } from "../../_services/user.service";
import Avatar from "../Avatar/Avatar";
import * as _ from "lodash";



class MenuAguardandoPagamento extends PureComponent<Props, State> {
  constructor(props) {
    super(props);


  }


  goToPage(page) {
    this.props.fechaMenu();
    this.props.history.push(page);
  }

  getInfoSecundaria = (usuario = this.props.user) => {
    if (usuario.crm) {
      return 'CRM - ' + usuario.crm;
    } else {
      return userService.getPerfilAtual(this.props.user)
    }
  };

  handleLogout = () => {
    this.props.fechaMenu();
    this.props.logout();
    this.props.clearCache();
  };







  render() {
    const {
      menuAberto,
      children, permissions, usuario } = this.props;

    return (
      <div id="menuContent" className={`d-flex flex-column fixed-top bg-riscos background-menu  ${menuAberto ? "move-in" : "move-out"}`}
      >
        <header className="navbar fixed-top topo iconMenu px-4">

          <div className="btnVoltar bd-highlight pl-3"
            onClick={() => {
              this.props.fechaMenu();
            }}
          >
            <img src={btnVoltar} alt="Voltar" className="backMenu" />
          </div>
          <div className={"bd-highlight"} >
            <img src={Logo} alt="Logo" className="iconLogo" />
          </div>
          <div className="App">
            <small>Versão: {packageJson.version}</small>
          </div>

        </header>

        <div className="bodyMenu d-flex flex-column h-100 mx-sm-auto" id="bodyMenu" >
          <div className="container-box">
            <div className="box-perfil">
              <div className="box row menu-user" >

                <div className="col-md-12 col-3 p-0 justify-content-center">
                  <h2 className="roboto text-center font-weight-bold">
                    Bem vindo(a)
                  </h2>
                </div>

                <div className="col-md-12 col-4 p-0 align-self-center">
                  <Avatar className="avatarMenu" user={this.props.user} ></Avatar>
                </div>

                <div className="col-md-12 col-5 d-flex flex-column justify-content-between ">
                  <p className="my-1">
                    <b className="nomeProprioMenu">{this.props.user.nome}</b>
                  </p>
                  <div className="d-flex justify-content-center txt-secondary">
                    <p>{_.get(this.props.user, 'role.role.description')}</p>
                  </div>
                </div>

              </div>
              <div className="row d-flex align-items-center justify-content-center p-3">
                <button
                  onClick={this.handleLogout}
                  className="d-flex align-items-center justify-content-start button-logout"
                >
                  <div className=" header-icon icon-sair"></div>
                  <span className="txt-header red"> Sair do sistema</span>
                </button>
              </div>
            </div>

            <Can I={objectsConstants.VIEW} a={objectsConstants.PUBLIC} ability={permissions}>
              <div className="box" onClick={() => this.goToPage('/pagamento')}>
                <img src={IconPagamento} className="icon-menu" alt="Pagamento" />
                <p>Dados de pagamento</p>
              </div>
            </Can>


          </div >
          <hr className="max-hr"></hr>
        </div >

        <footer>
          <p>© 2022 Sasc CM</p>
        </footer>
      </div >
    );
  }
}
const mapDispatch = ({
  alert: { error, warning },
  load: { loading },
  authentication: { logout, login },
  sidemenu: { toggleMenu, componenteMenu, abreMenu, fechaMenu },
    agenda:{clearCache}
}) => ({
  clearCache:()=>clearCache(),
  logout: () => logout(),
  login: (user, password, loginOnline, perfil) =>
    login({ user, password, loginOnline, perfil }),
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu(),
  fechaMenu: () => fechaMenu(),
  warning: (msg) => warning(msg),
});

function mapStateToProps(state) {
  const { permissions, user, selectPerfil, morePerfil } = state.authentication;
  const { menuAberto } = state.sidemenu;
  return {
    permissions,
    menuAberto,
    user,
    selectPerfil,
    morePerfil
  };
}



export default connect(mapStateToProps, mapDispatch)(withRouter(MenuAguardandoPagamento));
