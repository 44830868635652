//@flow
import _, { toInteger, toNumber } from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import { formatterHelper } from '../../../../_helpers/formatter.helper';
import { estoqueService } from '../../../../_services/estoque.service';
import { lotesService } from '../../../../_services/lotes.service';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import FormInput from '../../../../components/inputs/FormInput';
import InputViewEdit from '../../../../components/inputs/InputViewEdit';
import MoneyInput from '../../../../components/inputs/MoneyInput';
import SelectInput from '../../../../components/inputs/SelectInput';
import IconDelete from '../../../../images/icon_x_red.svg';
import { entradaEstoqueProdutoService } from '../../../../_services/entradaEstoqueProduto.service';
import { dateHelper } from '../../../../_helpers/date.helper';

type Props = {
  entity: any,
};

export default class ProdutoSaida extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      listaLotes: [],
      listaEstoqueUnidade: [],
      custoTotal: '',
    };
  }

  componentDidMount() {
    const { entity, propName } = this.props;
    const idProduto = _.get(entity, `${propName}.produto.id`);
    if (idProduto) {
      this.getProdutoEstoque(idProduto, propName);
      this.calculaCustoTotal();
    }
  }

  componentDidUpdate(nextProps: any) {
    const { entity, propName } = this.props;
    if (
      _.get(nextProps.entity, `${propName}.lote`) !=
        _.get(entity, `${propName}.lote`) ||
      _.get(nextProps.entity, `${propName}.estoqueUnidade`) !=
        _.get(entity, `${propName}.estoqueUnidade`) ||
      _.get(nextProps.entity, `${propName}.quantidade`) !=
        _.get(entity, `${propName}.quantidade`)
    ) {
      this.calculaCustoTotal();
    }
  }

  calculaCustoTotal = () => {
    const { entity, propName } = this.props;
    const custoUnit = this.calculaCustoUnitario();
    const qtd = _.get(entity, `${propName}.quantidade`);
    if (custoUnit && qtd) {
      setTimeout(() => {
        this.props.onChange(
          `${propName}.custoTotal`,
          toNumber(custoUnit) * toInteger(qtd)
        );
      }, 500);

      this.setState({ custoTotal: toNumber(custoUnit) * toInteger(qtd) });
    }
  };

  calculaCustoUnitario = () => {
    const { entity, propName } = this.props;
    const precoUnit = _.get(entity, `${propName}.precoUnitario`);
    const fracoes = _.get(entity, `${propName}.estoqueUnidade.fracoes`);
    if (precoUnit && fracoes) {
      return toNumber(precoUnit) / fracoes;
    } else {
      return precoUnit;
    }
  };

  getProdutoEstoque = (id, propName) => {
    estoqueService.estoqueProdutoFindById(id).then(
      (response) => {
        if (response) {
          const idEstoque = _.get(response.data, `idEstoque`);
          this.getAllLotesAndUnidade(id, idEstoque);
        }
      },
      (error) => {}
    );
  };

  clearValues = () => {
    const { entity, propName } = this.props;
    this.props.onChange(`${propName}.unidadePadrao`, {});
    this.props.onChange(`${propName}.lote`, {});
    this.props.onChange(`${propName}.precoUnitario`, '');
    this.props.onChange(`${propName}.fracoes`, '');
    this.props.onChange(`${propName}.quantidade`, '');
  };

  getAllLotesAndUnidade = (idProduto, idEstoque) => {
    Promise.all([
      lotesService.findByProduto(idProduto),
      estoqueService.findEstoqueUnidadeByEstoqueId(idEstoque),
    ]).then(
      (r) => {
        if (r) {
          let listaLotes = r[0].data;
          let listaEstoqueUnidade = r[1].data;
          this.setState({ listaLotes, listaEstoqueUnidade });
        }
      },
      (error) => {}
    );
  };

  render() {
    const { entity, propName, index, viewMode, size } = this.props;

    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-3">
          <InputViewEdit
            component={SelectInput}
            id={`produto${index}`}
            name={`${propName}.produto`}
            label="Produto"
            onFetchData={estoqueService.findByNome}
            onChange={(name, value) => {
              let idProduto = _.get(value, 'id');
              let idEstoque = _.get(value, 'idEstoque');
              this.clearValues();
              this.getAllLotesAndUnidade(idProduto, idEstoque);
              this.props.onChange(name, value);
            }}
            value={_.get(entity, `${propName}.produto`)}
            labelKey={'nome'}
            valueKey={'id'}
            multi={false}
            returnFullObject={true}
            creatable={false}
            required={true}
            viewMode={viewMode}
            erroMensagem={_.get(this.props.errors, `${propName}.produto`)}
            placeholder="Informe o nome ou codigo do produto"
            defaultValue={
              _.get(entity, `${propName}.produto`)
                ? _.get(entity, `${propName}.produto`).nome
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={SelectInput}
            label={'Unidade'}
            type="text"
            id={`unidadePadrao${index}`}
            name={`${propName}.estoqueUnidade`}
            placeholder=""
            options={this.state.listaEstoqueUnidade}
            value={_.get(entity, `${propName}.estoqueUnidade`)}
            onChange={(name, value) => {
              let fracoes = _.get(value, 'fracoes');
              this.props.onChange(`${propName}.fracoes`, fracoes);
              this.props.onChange(name, value);
            }}
            labelKey={'unidadePadrao.nome'}
            valueKey={'unidadePadrao.id'}
            erroMensagem={_.get(
              this.props.errors,
              `${propName}.estoqueUnidade.id`
            )}
            viewMode={viewMode}
            defaultValue={
              _.get(entity, `${propName}.estoqueUnidade`)
                ? _.get(entity, `${propName}.estoqueUnidade.unidadePadrao.nome`)
                : '--'
            }
            returnFullObject={true}
            creatable={false}
            required={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl">
          <InputViewEdit
            component={SelectInput}
            id={`lote${index}`}
            name={`${propName}.lote`}
            label="Lote"
            options={this.state.listaLotes}
            onChange={(name, value) => {
              let validade = _.get(value, 'validade');
              let precoUnitario = _.get(value, 'precoUnitario');
              this.props.onChange(`${propName}.lote.validade`, validade);
              this.props.onChange(`${propName}.precoUnitario`, precoUnitario);
              this.props.onChange(name, value);
            }}
            value={_.get(entity, `${propName}.lote`)}
            labelKey={'nome'}
            valueKey={'id'}
            multi={false}
            returnFullObject={true}
            creatable={false}
            required={true}
            viewMode={viewMode}
            erroMensagem={_.get(this.props.errors, `${propName}.lote.nome`)}
            placeholder="Selecione o Lote"
            defaultValue={
              _.get(entity, `${propName}.lote`)
                ? _.get(entity, `${propName}.lote`).nome
                : '--'
            }
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={FormInput}
            label={'Quantidade'}
            type="number"
            id={`quantidade${index}`}
            name={`${propName}.quantidade`}
            placeholder="Informe a quantidade"
            value={_.get(entity, `${propName}.quantidade`)}
            onChange={(name, value) => {
              this.props.onChange(name, value);
            }}
            noSize={true}
            erroMensagem={_.get(this.props.errors, `${propName}.quantidade`)}
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>

        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={DatePickerInput}
            label="Validade do lote"
            name={`${propName}.lote.validade`}
            erroMensagem={_.get(this.props.errors, `${propName}.lote.validade`)}
            id={`validade${index}`}
            value={_.get(entity, `${propName}.lote.validade`)}
            onChange={this.props.onChange}
            placeholder={'--/--/----'}
            viewMode={viewMode}
            disabled
            required={true}
            defaultValue={
              _.get(entity, `${propName}.lote.validade`)
                ? dateHelper.format(
                    _.get(entity, `${propName}.lote.validade`),
                    {
                      mode: 'DATE',
                    }
                  )
                : '--'
            }
          />
        </FormGroup>

        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={MoneyInput}
            label={'Custo unitário'}
            type="text"
            id={`precoUnitario${index}`}
            name={`${propName}.custoUnitario`}
            placeholder="Informe o custo unitário"
            value={this.calculaCustoUnitario()}
            defaultValue={formatterHelper.formatMoney(
              this.calculaCustoUnitario(),
              2,
              ',',
              '.'
            )}
            disabled
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>

        <FormGroup className="col-12 col-xl-1">
          <InputViewEdit
            component={MoneyInput}
            label={'Custo total'}
            id={`custoTotal${index}`}
            name={`${propName}.custoTotal`}
            placeholder=""
            value={_.get(entity, `${propName}.custoTotal`)}
            defaultValue={formatterHelper.formatMoney(
              _.get(entity, `${propName}.custoTotal`),
              2,
              ',',
              '.'
            )}
            disabled
            viewMode={viewMode}
            required={true}
          />
        </FormGroup>
        <div
          className={`form-group col-2 col-lg-1 align-items-center justify-content-start ${
            viewMode ? 'd-none' : ' d-flex '
          }`}
        >
          <div
            className={`icon-delete d-flex align-items-end justify-content-start$ {
              size === 1 || viewMode ? 'disabled' : ''
            }`}
            onClick={() => {
              this.props.removeItem(index, entity);
            }}
            disabled={size === 1}
          >
            <img src={IconDelete} alt="img" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
