//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import { convenioService } from '../../_services/convenio.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import ConsultoriosForm from './ConsultoriosForm';
import ConveniosForm from './ConveniosForm';
import EstabelecimentoForm from './EstabelecimentoForm';
import EstabelecimentoLink from './EstabelecimentoLink';
import RecepcaoForm from './RecepcaoForm';
import CamposObrigatoriosForm from './CamposObrigatoriosForm';

const defaultArrayItem = [{ idList: new Date().getTime() }];

class EstabelecimentoView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadUser();
  }
  loadRecepcao = (estabelecimento) => {
    estabelecimentoService.findRecepcao(estabelecimento.id).then(
      (response) => {
        let recepcao = response.data;
        let newEstabelecimento = _.cloneDeep(estabelecimento);
        if (recepcao && recepcao.length) {
          newEstabelecimento.listConfiguracaoRecepcao = recepcao;
        }
        this.setState(
          Object.assign({}, this.state.entity, newEstabelecimento),
          () => {
            this.props.loading(false);
            this.loadConsultorios(newEstabelecimento);
          }
        );
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };
  loadConsultorios = (estabelecimento) => {
    estabelecimentoService.findConsultorios(estabelecimento.id).then(
      (response) => {
        let consultorios = response.data;
        let newEstabelecimento = _.cloneDeep(estabelecimento);
        if (consultorios && consultorios.length) {
          newEstabelecimento.listConfiguracaoSala = consultorios;
        }
        this.setState(
          Object.assign({}, this.state.entity, newEstabelecimento),
          () => {
            this.props.loading(false);
            this.loadConvenios(newEstabelecimento);
          }
        );
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };
  loadConvenios = (estabelecimento) => {
    convenioService.findAll(estabelecimento.id).then(
      (response) => {
        let convenios = response.data;

        let newEstabelecimento = Object.assign({}, estabelecimento);
        if (convenios && convenios.length) {
          newEstabelecimento.listConvenio = convenios;
        }
        this.setState({ entity: newEstabelecimento }, () => {
          this.props.loading(false);
        });
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      estabelecimentoService.doGet(this.props.match.params.id).then(
        (response) => {
          let estabelecimento = response.data.data;

          // if (!_.get(estabelecimento, 'listConvenio.length')) {
          //   estabelecimento.listConvenio = [{}];
          // }
          if (!_.get(estabelecimento, 'listHorarioAtendimento.length')) {
            estabelecimento.listHorarioAtendimento = defaultArrayItem;
          }
          if (!_.get(estabelecimento, 'listConfiguracaoRecepcao.length')) {
            estabelecimento.listConfiguracaoRecepcao = defaultArrayItem;
          }
          if (!_.get(estabelecimento, 'listConfiguracaoSala.length')) {
            estabelecimento.listConfiguracaoSala = defaultArrayItem;
          }
          this.setState({ entity: _.cloneDeep(estabelecimento) }, () => {
            this.props.loading(false);
            this.loadRecepcao(estabelecimento);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };

  render() {
    // let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={entity.nome}
          canBack={true}
          backUrl={'/estabelecimento'}
        />
        {/* 
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem> */}
        {entity.id && (
          <EstabelecimentoLink entity={entity}></EstabelecimentoLink>
        )}
        <div className="flex-column mt-4">
          <FullCustomCard
            title="Estabelecimento"
            permissionSave={objectsConstants.CRUD_ESTABELECIMENTOS}
            Componente={EstabelecimentoForm}
            viewMode={true}
            entity={entity}
            onSave={this.loadUser}
            cancelForm={this.loadUser}
            {...otherPops}
          ></FullCustomCard>
          {entity.id && (
            <React.Fragment>
              <FullCustomCard
                title="Dados obrigatórios do paciente"
                permissionSave={objectsConstants.CRUD_ESTABELECIMENTOS}
                Componente={CamposObrigatoriosForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                {...otherPops}
              ></FullCustomCard>
              <FullCustomCard
                title="Recepção"
                permissionSave={objectsConstants.CRUD_ESTABELECIMENTOS}
                Componente={RecepcaoForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                {...otherPops}
              ></FullCustomCard>

              <FullCustomCard
                title="Consultórios"
                permissionSave={objectsConstants.CRUD_ESTABELECIMENTOS}
                Componente={ConsultoriosForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                {...otherPops}
              ></FullCustomCard>

              <FullCustomCard
                title="Convênios"
                Componente={ConveniosForm}
                permissionSave={objectsConstants.CRUD_ESTABELECIMENTOS}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadUser}
                onSave={this.loadUser}
                {...otherPops}
              ></FullCustomCard>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  null,
  mapDispatch,
  null
)(withRouter(EstabelecimentoView));
