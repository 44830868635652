import * as React from 'react';
import {connect} from 'react-redux';
import {Card} from "react-bootstrap";
import {ResponsivePie} from "@nivo/pie";
import {animated} from "@react-spring/web";
import {Md5} from "ts-md5";

/**
 * Props
 *
 */
type Props = {
    quantidadeTotalProcedimentos: number;
    quantidadeTotalProcedimentosParticulares: number;
    quantidadeTotalProcedimentosConveniadas: number;
    quantidadeTotalProcedimentosCortesia: number;
};

/**
 * State
 *
 */
type State = {
    dataChartTipoAtendimento: DataChartTipoAtendimento[];
    hashProps: string;
    hasData: boolean;
}

/**
 * Data chart pacientes
 *
 */
interface DataChartTipoAtendimento {
    id: string;
    label: string;
    value: number;
    color: string
}

/**
 * Chart Dashboard Component
 *
 */
class ChartTipoAtendimentoDashboardComponent extends React.Component<Props, State> {

    /**
     * Default constructor
     *
     * @param props
     */
    constructor(props: any) {
        super(props);
        this.state = this.getInitialState();
    }

    /**
     * Responsible change componente.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        let newHashProps: string = this.generateHasProps(
            this.props.quantidadeTotalProcedimentos,
            this.props.quantidadeTotalProcedimentosParticulares,
            this.props.quantidadeTotalProcedimentosConveniadas,
            this.props.quantidadeTotalProcedimentosCortesia
        );
        if (newHashProps !== prevState.hashProps) {
            this.setState(this.getInitialState());
        }
    }

    /**
     * Responsible get initial state
     *
     * @return {}
     */
    getInitialState(): any {
        return {
            dataChartTipoAtendimento: this.getDataChartTipoAtendimento(),
            hashProps: this.generateHasProps(
                this.props.quantidadeTotalProcedimentos,
                this.props.quantidadeTotalProcedimentosParticulares,
                this.props.quantidadeTotalProcedimentosConveniadas,
                this.props.quantidadeTotalProcedimentosCortesia
            ),
            hasData: this.hasData()
        };
    }

    /**
     * Responsible generate hash.
     *
     * @param quantidadeTotalProcedimentos
     * @param quantidadeTotalProcedimentosParticulares
     * @param quantidadeTotalProcedimentosConveniadas
     * @param quantidadeTotalProcedimentosCortesia
     * @return {string}
     */
    generateHasProps = (quantidadeTotalProcedimentos: number, quantidadeTotalProcedimentosParticulares: number,
                        quantidadeTotalProcedimentosConveniadas: number, quantidadeTotalProcedimentosCortesia: number): string => {
        return Md5.hashStr(
            JSON.stringify(quantidadeTotalProcedimentos) +
            JSON.stringify(quantidadeTotalProcedimentosParticulares) +
            JSON.stringify(quantidadeTotalProcedimentosConveniadas) +
            JSON.stringify(quantidadeTotalProcedimentosCortesia)
        )
    }

    /**
     *
     * Responsible validate if has data.
     *
     * @return {boolean}
     */
    hasData = (): boolean => {
        return this.props.quantidadeTotalProcedimentos > 0
            || this.props.quantidadeTotalProcedimentosParticulares > 0
            || this.props.quantidadeTotalProcedimentosConveniadas > 0
            || this.props.quantidadeTotalProcedimentosCortesia > 0
    }


    /**
     * getDataChartTipoAtendimento
     *
     */
    getDataChartTipoAtendimento() {
        return [
            {
                id: "Particular",
                label: "Particular",
                value: Math.round((this.props.quantidadeTotalProcedimentosParticulares / this.props.quantidadeTotalProcedimentos) * 100),
                color: "#606C38"
            },
            {
                id: "Conveniado",
                label: "Conveniado",
                value: Math.round((this.props.quantidadeTotalProcedimentosConveniadas / this.props.quantidadeTotalProcedimentos) * 100),
                color: "#02457A"
            },
            {
                id: "Cortesia",
                label: "Cortesia",
                value: Math.round((this.props.quantidadeTotalProcedimentosCortesia / this.props.quantidadeTotalProcedimentos) * 100),
                color: "#BC4B0D"
            }
        ];
    }

    getArcLabelsComponent() {
        return ({datum, label, style}) => (
            <animated.g transform={style.transform} style={{pointerEvents: 'none'}}>
                <circle fill={style.textColor} cy={6} r={15}/>
                <circle fill="#ffffff" stroke={datum.color} strokeWidth={2} r={16}/>
                <text
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={style.textColor}
                    style={{
                        fontSize: 10,
                        fontWeight: 700,
                    }}
                >
                    {label}%
                </text>
            </animated.g>
        );
    }

    getLegends(justify, translateX, translateY, itemsSpacing, itemWidth, itemHeight, symbolSize) {
        return [
            {
                anchor: 'bottom',
                direction: 'row',
                toggleSerie: true,
                justify: justify,
                translateX: translateX,
                translateY: translateY,
                itemsSpacing: itemsSpacing,
                itemWidth: itemWidth,
                itemHeight: itemHeight,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: symbolSize,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ];
    }

    /**
     * Get layers
     *
     * @returns {(string|(function({dataWithArc: *, centerX: *, centerY: *})))[]}
     */
    getLayers() {
        return ['arcs', 'arcLabels', 'legends', ({dataWithArc, centerX, centerY}) => {
            return (
                <text
                    x={centerX}
                    y={centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '35px',
                        fontWeight: 350,
                    }}
                >
                    {this.props.quantidadeTotalProcedimentos}
                </text>
            )
        }];
    }

    /**
     * Render component
     *
     * @returns {JSX.Element}
     */
    render() {
        return (
            <React.Fragment>
                <Card className="chart-card bg-light pr-0 pl-0">
                    <Card.Body className="chart-card-body pr-0 pl-0">
                        <Card.Title
                            className="chart-card-title d-flex justify-content-center">Tipo Atendimento</Card.Title>
                        {!this.state.hasData && (
                            <React.Fragment>
                                <div className="chart-text-blank">Não existem dados a serem exibidos para essa
                                    pesquisa.
                                </div>
                            </React.Fragment>
                        )}
                        {this.state.hasData && (
                            <React.Fragment>
                                <div className="chart-card-full">
                                    <ResponsivePie
                                        data={this.state.dataChartTipoAtendimento}
                                        colors={{datum: 'data.color'}}
                                        innerRadius={0.5}
                                        activeInnerRadiusOffset={8}
                                        layers={this.getLayers()}
                                        enableArcLinkLabels={false}
                                        activeOuterRadiusOffset={8}
                                        legends={this.getLegends(false, 0, 0, 20, 70, 15, 16)}
                                        margin={{right: 10, bottom: 100, left: 10}}
                                        animate={true}
                                        sortByValue={true}
                                        arcLabelsSkipAngle={20}
                                        arcLabelsRadiusOffset={0.55}
                                        arcLabelsTextColor={{from: 'color', modifiers: [['darker', 0.6]]}}
                                        arcLinkLabelsOffset={2}
                                        arcLinkLabelsColor={{from: 'color'}}
                                        arcLinkLabelsThickness={3}
                                        arcLabelsComponent={this.getArcLabelsComponent()}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}

/**
 * Export default connect
 *
 */
export default connect(null, null, null)(ChartTipoAtendimentoDashboardComponent);