import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import IconPainel from "../../images/icon-remove-med.svg";

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalDesvincularProfissional extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      medico: null
    };
  }

  toogleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  fecharModal = () => {
    this.toogleModal(null, null);
  };

  handleSelectChange = (name: string, option: any) => {
    const { medico } = this.state;
    let newEntity = _.cloneDeep(medico);
    newEntity = option;
    this.setState({ medico: newEntity });
  };

  render() {
    const { estabelecimento } = this.props;
    const { medico, idMarcacao, status } = this.state;
    let _this = this;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toogleModal}
          backdrop="static"
          modalClassName=""
          className="modal-anexos"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="">
              <div className="row">
                <div className="col-12 text-center iconLocal">
                  <img src={IconPainel} alt="Icone painel de chamada" />
                </div>
                <div className="col-12 text-center mb-2">
                  <h2 className="font-weight-bold">
                    Desvincular profissional
                   </h2>
                  <p>
                    Ao desvincular este profissional do estabelecimento, toda agenda dele será excluída e os pacientes já agendados deverão ser remarcados.<b>Deseja desvincular este profissional?</b>
                  </p>
                </div>
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-50 text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    this.fecharModal();
                  }}
                >
                  {" "}
                  Cancelar{" "}
                </button>
              </div>
              <div className="w-50 text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    _this.fecharModal();
                    _this.props.callbackOnSave(medico);
                  }
                  }
                >
                  {" "}
                  Desvincular{" "}
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>

        {/* ////////////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
