//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';
import IndisponibilidadeRow from '../../../components/Indisponibilidade/IndisponibilidadeRow';
import DatePickerInput from '../../../components/inputs/DatePickerInput';
import DateTimePicker from '../../../components/inputs/DateTimePicker';
import FormSelectInput from '../../../components/inputs/FormSelectInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import IconIndisponibilidade from '../../../images/icon-close-agenda-x1.svg';
import { objectsConstants } from '../../../_constants/objects.constants';
import { dateHelper } from '../../../_helpers/date.helper';
import { translate } from '../../../_helpers/messages.helper';
import { ConfirmAcationService } from '../../../_services/ConfirmAcationService';
import { exameService } from '../../../_services/exame.service';
import { indisponibilidadeService } from '../../../_services/indisponibilidade.service';
import { medicoService } from '../../../_services/medico.service';
import { userService } from '../../../_services/user.service';
import { Can } from '@casl/react';
import { FormText } from 'react-bootstrap';
import FormTextArea from '../../../components/inputs/FormTextArea';

type Props = {
  filtro: any,
};

const yesterday = new Date(Date.now() - 86400000);
const ValidaIndisponibilidade = Yup.object().shape({
  listMedico: Yup.object()
    .nullable()
    .when(
      'requiredEstabelecimentoMedico',
      (requiredEstabelecimentoMedico, schema) => {
        if (requiredEstabelecimentoMedico) {
          return Yup.string().required('Selecione ao menos um');
        } else {
          return Yup.string().notRequired();
        }
      }
    ),
  listExame: Yup.object()
    .nullable()
    .when(
      'requiredEstabelecimentoExame',
      (requiredEstabelecimentoExame, schema) => {
        if (requiredEstabelecimentoExame) {
          return Yup.string().required('Selecione ao menos um Exame');
        } else {
          return Yup.string().notRequired();
        }
      }
    ),
  dataInicio: Yup.date()
    .required('Um dia ou um período são necessários')
    .min(yesterday, 'O dia inicial não pode ser anterior ao dia de hoje'),
  dataFim: Yup.date().min(
    Yup.ref('dataInicio'),
    'Dia final deve ser maior que dia inicio.'
  ),
});

class Indisponibilidade extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      periodo: false,
      listIndisponibilidade: [],
      exibeListagem: true,
      todosProfissionais: false,
      todosExames: false,
      todosEstabelecimentos: false,
      listaEstabelecimentoMedico: [],
    };
  }

  componentDidMount() {
    this.props.loading(true);
    let values = {};
    _.set(values, 'tipo', this.props.tipoAgenda);
    indisponibilidadeService.doList(values).then(
      (response) => {
        if (response) {
          let listIndisponibilidade = response.data;
          if (listIndisponibilidade.length > 0) {
            this.setState({ listIndisponibilidade }, () => {
              this.props.loading(false);
            });
          } else {
            this.props.loading(false);
          }
        }
      },
      (error) => {
        this.props.loading(false);
        this.props.error(`Erro ao obter lista de Indisponibilidades`);
      }
    );
    if (userService.isMedico()) {
      medicoService.findByEspecialidade().then((response) => {
        if (response) {
          let listaEstabelecimentoMedico = response.data;
          if (listaEstabelecimentoMedico.length > 0) {
            this.setState({ listaEstabelecimentoMedico }, () => {});
          }
        }
      });
    }
  }
  formataFormParaEnvio = (valuesForm) => {
    let values = valuesForm;

    if (
      !_.get(values, `selecionarHorarios`) ||
      _.get(values, `selecionarHorarios`) === 'TODOS'
    ) {
      _.set(values, 'horaInicio', '00:00');
      _.set(values, 'horaFim', '23:59');
    }

    return values;
  };

  enviaIndisponibilidade = (values) => {
    let _this = this;
    let valuesFormat = _this.formataFormParaEnvio(values);
    this.props.loading(true);
    indisponibilidadeService.doSave(valuesFormat).then(
      (response) => {
        this.props.fechaIndisponibilidade();
        this.props.success(`Agenda fechada com sucesso!`);
        this.props.reloadAgenda(this.props.filtro);
        this.props.loading(false);
      },
      (erros) => {
        console.log(erros);
        this.props.loading(false);
        this.props.error(`Erro ao cadastrar indisponibilidade de agenda`);
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              _this.formRef.setFieldError(
                erroItem.fieldName,
                translate(erroItem.message.code)
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  fecharAgenda = () => {
    var values = this.formRef.state.values;
    if (this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS) {
      if (userService.isMedico()) {
        _.set(values, 'requiredEstabelecimentoMedico', false);
      } else {
        _.set(values, 'requiredEstabelecimentoMedico', true);
      }

      _.set(values, 'tipoAgenda', 0);
    } else {
      _.set(values, 'requiredEstabelecimentoExame', true);
      _.set(values, 'tipoAgenda', 1);
    }
    if (this.state.todosProfissionais) {
      values = _.omit(values, ['listMedico']);
      _.set(values, 'requiredEstabelecimentoMedico', false);
      _.set(values, 'estabelecimento', userService.getExtension());
    }
    if (this.state.todosEstabelecimentos) {
      values = _.omit(values, ['listMedico']);
      _.set(values, 'requiredEstabelecimentoMedico', false);
    }
    if (this.state.todosExames) {
      values = _.omit(values, ['listExame']);
      _.set(values, 'requiredEstabelecimentoExame', false);
      _.set(values, 'requiredEstabelecimentoMedico', false);
    }
    console.log(values);
    this.formRef.validateForm(values).then((error) => {
      if (_.isEmpty(error)) {
        // this.props.fechaIndisponibilidade();
        ConfirmAcationService.confirmMsg(
          () => {
            this.enviaIndisponibilidade(values);
          },
          'Fechar agenda',
          <span>
            Se você possuir algum paciente já agendado neste período, tarefas
            para desmarcar estes pacientes serão criadas. Você gostaria de
            fechar a sua agenda para o período selecionado?
          </span>,
          <span className="text-danger">{}</span>,
          'Não',
          'Sim',
          objectsConstants.TYPE_WARNING,
          () => {
            // this.props.abreIndisponibilidade();
          }
        );
      } else {
        console.log(error);
      }
    });
  };

  toggleExibeListagem = () => {
    this.setState({ exibeListagem: !this.state.exibeListagem });
  };

  togglePeriodo = () => {
    this.setState({ periodo: !this.state.periodo });
  };

  selecionarTodosProfissionais = () => {
    this.setState({ todosProfissionais: !this.state.todosProfissionais });
  };
  selecionarTodosEstabelecimentos = (setFieldValue) => {
    const { listaEstabelecimentoMedico } = this.state;

    this.setState(
      { todosEstabelecimentos: !this.state.todosEstabelecimentos },
      () => {
        if (this.state.todosEstabelecimentos) {
          setFieldValue(
            'listEstabelecimentoMedico',
            listaEstabelecimentoMedico
          );
        } else {
          setFieldValue('listEstabelecimentoMedico', null);
        }
      }
    );
  };

  selecionarTodosExames = () => {
    this.setState({ todosExames: !this.state.todosExames });
  };

  clearForm = () => {
    this.formRef.resetForm({});
  };

  fechar = () => {
    this.setState({ exibeListagem: true }, () => {
      this.props.fechaIndisponibilidade();
    });
  };

  removeIndisponibilidade = (indisponibilidadeId) => {
    if (!userService.isSuporte()) {
      this.props.fechaIndisponibilidade();
      ConfirmAcationService.confirmMsg(
        () => {
          this.props.loading(true);
          indisponibilidadeService
            .doDelete(indisponibilidadeId)
            .then((response) => {
              this.props.reloadAgenda(this.props.filtro);
              this.props.abreIndisponibilidade();
              this.props.loading(false);
            });
        },
        'Excluir indisponibilidade de agenda',
        <span>
          Tem certeza que deseja excluir o período de indisponibilidade
          selecionado?
        </span>,
        <span className="text-danger">{}</span>,
        'Não',
        'Sim',
        objectsConstants.TYPE_EXCLUIR,
        () => {
          this.props.abreIndisponibilidade();
        }
      );
    }
  };

  render() {
    const { exibeListagem, listIndisponibilidade } = this.state;

    let listaIndisponibilidades = [];
    let tipoDeAgendaAtual = this.props.tipoAgenda;

    _.filter(listIndisponibilidade, function (indisponibilidade) {
      if (_.get(indisponibilidade, 'tipoAgenda') === tipoDeAgendaAtual) {
        console.log(indisponibilidade);
        listaIndisponibilidades.push(indisponibilidade);
      }
    });
    if (exibeListagem) {
      // if (!userService.isMedico()) {
      //  let listaIndisponibilidade = _.groupBy(listIndisponibilidade, function (
      //     item
      //   ) {
      //     return _.get(item, 'listMedico.medico');
      //   });
      // }
      return (
        <div className="menu-indisponibilidade">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="close-menu">
              <div className="action" onClick={this.fechar}>
                Cancelar
              </div>
            </div>
            <div className="titulo-indisponibilidade d-flex align-items-center">
              <img src={IconIndisponibilidade} alt="icone Indisponibilidade" />
              <span className="titulo"> Indisponibilidade</span>
            </div>
            <div className="m-3"></div>
          </div>
          {/* {!userService.isSuporte() && ( */}
          <button
            className="mt-4 btn btn-primary btn-block"
            type="button"
            disabled={userService.isSuporte()}
            onClick={this.toggleExibeListagem}
          >
            Adicionar outra indisponibilidade
          </button>
          {/* )} */}
          <div className="pt-3 pb-2">
            <div className="d-flex flex-row align-items-start">
              {/* <DadosUser
                  user={_.get(listIndisponibilidade[0], 'medico')}
                ></DadosUser>
      */}
            </div>
            <label>Indisponibilidades ativas </label>

            {listaIndisponibilidades &&
              listaIndisponibilidades.map((item, index) => {
                return (
                  <IndisponibilidadeRow
                    key={index + '' + item.dataCriacao}
                    index={index}
                    indisponibilidade={item}
                    removeItem={this.removeIndisponibilidade}
                  ></IndisponibilidadeRow>
                );
              })}
          </div>
          <hr></hr>
        </div>
      );
    } else {
      return (
        <div className="menu-indisponibilidade">
          <div className="d-flex flex-row">
            <div className="flex-fill d-flex align-items-center titulo-indisponibilidade">
              <button
                className=" header-icon back-icon"
                type="button"
                onClick={this.toggleExibeListagem}
              >
                <p className="txt-header"> Voltar</p>
              </button>
            </div>
            <div className="close-menu">
              <button
                className="btn-close"
                type="button"
                onClick={this.fechar}
              ></button>
            </div>
          </div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={ValidaIndisponibilidade}
            enableReinitialize={true}
            initialValues={{}}
            ref={(form) => {
              this.formRef = form;
            }}
          >
            {({
              values,
              // handleChange,
              handleSubmit,
              // setFieldError,
              setFieldValue,
              // setValues,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                <React.Fragment>
                  {userService.isMedico() && (
                    <React.Fragment>
                      <FormGroup className=" pt-3">
                        {!this.state.todosEstabelecimentos && (
                          <InputViewEdit
                            component={FormSelectInput}
                            onChange={(name, value) => {
                              setFieldValue(name, !value ? [] : value);
                            }}
                            id={`listEstabelecimentoMedico`}
                            label="Selecione um ou mais estabelecimentos"
                            placeholder="Selecione o local do atendimento"
                            name={`listEstabelecimentoMedico`}
                            required={true}
                            value={_.get(values, `listEstabelecimentoMedico`)}
                            erroMensagem={_.get(
                              errors,
                              `listEstabelecimentoMedico`
                            )}
                            returnFullObject={true}
                            options={this.state.listaEstabelecimentoMedico}
                            creatable={false}
                            isMulti={true}
                            valueKey={'estabelecimento.id'}
                            labelKey={'estabelecimento.nome'}
                          />
                        )}
                        {this.state.todosEstabelecimentos && (
                          <InputViewEdit
                            component={SelectInput}
                            disabled={true}
                            id={`listEstabelecimentoMedico`}
                            label="Selecione um ou mais estabelecimentos"
                            placeholder="Todos estabelecimentos selecionados"
                            name={`listEstabelecimentoMedico`}
                            options={[]}
                            creatable={false}
                            isMulti={true}
                            defaultValue={null}
                          />
                        )}
                      </FormGroup>
                      <FormGroup className="pt-1 pb-3 text-center">
                        <button
                          className="btn btn-link m-0 p-0"
                          onClick={() => {
                            this.selecionarTodosEstabelecimentos(setFieldValue);
                          }}
                          type="button"
                        >
                          {!this.state.todosEtabelecimentos
                            ? 'Selecionar todos os estabelecimentos'
                            : 'Selecionar um ou mais estabelecimentos'}
                        </button>
                      </FormGroup>
                    </React.Fragment>
                  )}
                </React.Fragment>
                <hr></hr>
                {!userService.isMedico() && (
                  <React.Fragment>
                    {tipoDeAgendaAtual ===
                      objectsConstants.TIPO_AGENDA_CONSULTAS && (
                      <React.Fragment>
                        <FormGroup className=" pt-3">
                          {!this.state.todosProfissionais && (
                            <InputViewEdit
                              component={FormSelectInput}
                              onChange={(name, value) => {
                                setFieldValue(name, !value ? [] : value);
                              }}
                              id={`listMedico`}
                              label="Selecione um ou mais profissionais de saúde"
                              placeholder="Selecione um ou mais profissionais de saúde"
                              name={`listMedico`}
                              value={_.get(values, `listMedico`)}
                              erroMensagem={_.get(errors, `listMedico`)}
                              returnFullObject={true}
                              service={medicoService.findMedicoByEspecialidade}
                              creatable={false}
                              isMulti={true}
                              valueKey="id"
                              labelKey="apelido"
                            />
                          )}
                          {this.state.todosProfissionais && (
                            <InputViewEdit
                              component={SelectInput}
                              disabled={true}
                              id={`listMedico`}
                              label="Selecione um ou mais profissionais de saúde"
                              placeholder="Todos Profissionais selecionados"
                              name={`listMedico`}
                              options={[]}
                              creatable={false}
                              isMulti={true}
                              defaultValue={null}
                            />
                          )}
                        </FormGroup>
                        <FormGroup className="pt-1 pb-3 text-center">
                          <button
                            className="btn btn-link m-0 p-0"
                            onClick={this.selecionarTodosProfissionais}
                            type="button"
                          >
                            {this.state.todosProfissionais
                              ? 'Selecionar um ou mais profissionais'
                              : 'Selecionar todos os profissionais'}
                          </button>
                        </FormGroup>
                      </React.Fragment>
                    )}

                    {tipoDeAgendaAtual ===
                      objectsConstants.TIPO_AGENDA_EXAMES && (
                      <React.Fragment>
                        <FormGroup className=" pt-3">
                          {!this.state.todosExames && (
                            <InputViewEdit
                              component={FormSelectInput}
                              onChange={(name, value) => {
                                setFieldValue(name, !value ? [] : value);
                              }}
                              id={`listExame`}
                              label="Selecione um ou mais Exames"
                              placeholder="Selecione um ou mais exames"
                              name={`listExame`}
                              value={_.get(values, `listExame`)}
                              erroMensagem={_.get(errors, `listExame`)}
                              returnFullObject={true}
                              service={exameService.findAll}
                              creatable={false}
                              isMulti={true}
                              valueKey="id"
                              labelKey="nome"
                            />
                          )}
                          {this.state.todosExames && (
                            <InputViewEdit
                              component={SelectInput}
                              disabled={true}
                              id={`listExame`}
                              label="Selecione um ou emxames"
                              placeholder="Todos exames selecionados"
                              name={`listExame`}
                              options={[]}
                              creatable={false}
                              isMulti={true}
                              defaultValue={null}
                            />
                          )}
                        </FormGroup>
                        <FormGroup className="pt-1 pb-3 text-center">
                          <button
                            className="btn btn-link m-0 p-0"
                            onClick={this.selecionarTodosExames}
                            type="button"
                          >
                            {this.state.todosExames
                              ? 'Selecionar um ou mais exames'
                              : 'Selecionar todos os exames'}
                          </button>
                        </FormGroup>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <hr></hr>
                {!this.state.periodo && (
                  <div className="form-row pt-3 ">
                    <FormGroup className="col-12">
                      <InputViewEdit
                        component={DatePickerInput}
                        label="Dia"
                        id="dataInicio"
                        name="dataInicio"
                        erroMensagem={_.get(errors, `dataInicio`)}
                        value={values.dataInicio}
                        required={false}
                        placeholder="--/--/----"
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          setFieldValue('dataFim', value);
                        }}
                        defaultValue={
                          _.get(values, `dataInicio`)
                            ? dateHelper.format(_.get(values, `dataInicio`), {
                                mode: 'DATE',
                              })
                            : '--'
                        }
                      />
                    </FormGroup>
                  </div>
                )}

                {this.state.periodo && (
                  <React.Fragment>
                    <div className="form-row pt-3 ">
                      <div className="col-6 py-0">
                        <InputViewEdit
                          component={DatePickerInput}
                          label="Dia início"
                          name={`dataInicio`}
                          erroMensagem={_.get(errors, `dataInicio`)}
                          id={`dataInicio`}
                          value={values.dataInicio}
                          onChange={(name, value) => setFieldValue(name, value)}
                          // showTimeSelect={true}
                          // showTimeSelectOnly={true}
                          placeholder={'--/--/----'}
                          required={false}
                          defaultValue={
                            _.get(values, `dataInicio`)
                              ? dateHelper.format(_.get(values, `dataInicio`), {
                                  mode: 'DATE',
                                })
                              : '--'
                          }
                        />
                      </div>
                      <div className="col-6 py-0">
                        <InputViewEdit
                          component={DatePickerInput}
                          label="Dia fim"
                          name={`dataFim`}
                          id={`dataFim`}
                          value={values.dataFim}
                          onChange={(name, value) => setFieldValue(name, value)}
                          // showTimeSelect={true}
                          // showTimeSelectOnly={true}
                          erroMensagem={_.get(errors, `dataFim`)}
                          placeholder={'--/--/----'}
                          required={false}
                          defaultValue={
                            _.get(values, `dataFim`)
                              ? dateHelper.format(_.get(values, `dataFim`), {
                                  mode: 'DATE',
                                })
                              : '--'
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <FormGroup className=" pb-3 text-center">
                  <button
                    className="btn btn-link m-0 p-0"
                    onClick={this.togglePeriodo}
                    type="button"
                  >
                    {this.state.periodo
                      ? 'Selecionar somente um dia'
                      : 'Inserir um período'}
                  </button>
                </FormGroup>
                <hr></hr>
                <div className=" py-3">
                  <InputViewEdit
                    component={SelectInput}
                    options={objectsConstants.HORARIO_INDISPONIBILIDADE}
                    name={`selecionarHorarios`}
                    label="Horários de fechamento da agenda"
                    placeholder="Selecione"
                    onChange={setFieldValue}
                    valueKey={'name'}
                    labelKey={'description'}
                    returnFullObject={false}
                    value={_.get(values, `selecionarHorarios`)}
                    id="selecionarHorarios"
                    defaultValue={objectsConstants.HORARIO_INDISPONIBILIDADE[0]}
                  />
                </div>
                {_.get(values, `selecionarHorarios`) === 'SELECIONAR' && (
                  <React.Fragment>
                    <div className="form-row ">
                      <div className="col-6 py-0">
                        <InputViewEdit
                          label="Hora início"
                          format={'HH:mm'}
                          component={DateTimePicker}
                          defaultValue={
                            _.get(values, `horaInicio`)
                              ? _.get(values, `horaInicio`)
                              : '--'
                          }
                          name={`horaInicio`}
                          id={`horaInicio`}
                          value={values.horaInicio}
                          onChange={setFieldValue}
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          placeholder={'--:--'}
                          timeIntervals={30}
                        />
                      </div>
                      <div className="col-6 py-0">
                        <InputViewEdit
                          label="Hora fim"
                          format={'HH:mm'}
                          component={DateTimePicker}
                          defaultValue={
                            _.get(values, `horaFim`)
                              ? _.get(values, `horaFim`)
                              : '--'
                          }
                          name={`horaFim`}
                          id={`horaFim`}
                          erroMensagem={_.get(errors, `horaFim`)}
                          value={values.horaFim}
                          onChange={setFieldValue}
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          placeholder={'--:--'}
                          timeIntervals={30}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <React.Fragment>
                  <div className=" py-3">
                    <InputViewEdit
                      component={FormTextArea}
                      className={'textArea'}
                      label="Justificativa:"
                      id={`justificativa`}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                      }}
                      placeholder="Insira aqui a justificativa"
                      value={_.get(values, `justificativa`)}
                      name={`justificativa`}
                      maxLength={50}
                    />
                  </div>
                </React.Fragment>
                <div className={' text-center'}>
                  <button
                    className="btn btn-link mt-3 mb-4 mx-2 "
                    onClick={this.clearForm}
                    type="button"
                  >
                    Limpar formulário{' '}
                  </button>
                  <div className="">
                    <Can
                      I={objectsConstants.INSERT}
                      a={objectsConstants.INDISPONIBILIDADE}
                      ability={this.props.permissions}
                    >
                      <button
                        className="btn btn-primary mb-5 btn-aplicar "
                        onClick={this.fecharAgenda}
                        type="button"
                      >
                        Fechar agenda
                      </button>
                    </Can>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      );
    }
  }
}

const mapDispatch = ({
  alert: { error, success },
  load: { loading },
  indisponibilidademenu: {
    toggleIndisponibilidade,
    componenteIndisponibilidade,
    abreIndisponibilidade,
    fechaIndisponibilidade,
  },
}) => ({
  error: (message, code) => error({ message, code }),
  success: (message, code) => success({ message, code }),
  loading: (load: boolean) => loading({ load }),
  toggleIndisponibilidade: () => toggleIndisponibilidade(),
  abreIndisponibilidade: () => abreIndisponibilidade(),
  fechaIndisponibilidade: () => fechaIndisponibilidade(),
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(Indisponibilidade));
