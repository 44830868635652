//@flow
import {urlsConstants} from '../_constants/urls.constant';
import {defaultService} from './defaultService';
import {oauthHeaderJson} from "../_helpers/oauth-header";
import _ from 'lodash';
import {userService} from "./user.service";

export const voucherService = {
  findByFiltro,
  hasVoucherUsedOrEnable,
  generate,
  findById,
  cancelVoucher,
  printVoucher
};

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.VOUCHER_SOLUTI}/list`,
    options,
    filter
  );
}

function hasVoucherUsedOrEnable(): Promise<boolean> {
  return fetch(
      `${urlsConstants.VOUCHER_SOLUTI}/hasVoucherUsedOrEnable`,
      {
        method: 'POST',
        headers: oauthHeaderJson(),
      }
  ).then((response) => {
     return response.json().then((data) => {
       return data.data;
    });
  });
}

function findById(id: number) {
    return fetch(
        `${urlsConstants.VOUCHER_SOLUTI}/findById/${id}`,
        {
            method: 'GET',
            headers: oauthHeaderJson(),
        }
    ).then((response) => {
        return response.json().then((data) => {
            return data.data;
        });
    });
}

function cancelVoucher(voucherNumber: string) {
    return fetch(
        userService.isMedico() ? `${urlsConstants.VOUCHER_SOLUTI}/cancelVoucher` : `${urlsConstants.VOUCHER_SOLUTI}/cancelVoucher/${voucherNumber}`,
        {
            method: 'POST',
            headers: oauthHeaderJson(),
        }
    ).then((response) => {
        return response.json().then((data) => {
            treatResponseCancel(data);
            return data;
        });
    });
}

function printVoucher(voucherNumber: string) {
    return fetch(
        `${urlsConstants.VOUCHER_SOLUTI}/printVoucher/${voucherNumber}`,
        {
            method: 'POST',
            headers: oauthHeaderJson()
        }
    ).then((response) => {
        return response.json().then((data) => {
            return data.data;
        });
    });
}

function generate(idUser: number): Promise<any> {
    return fetch(
        `${urlsConstants.VOUCHER_SOLUTI}/getOrGenerateVoucher`,
        {
            method: 'PUT',
            headers: oauthHeaderJson(),
            body: JSON.stringify({
                idUser: idUser
            })
        }
    ).then((response) => {
        return response.json().then((data) => {
            treatResponseGenerate(data);
            return data;
        });
    });
}

function treatResponseGenerate(response): any {
    _.set(response, "statusFinal", response.type === "SUCCESS" ? "SUCCESS" : "ERROR");
    _.set(response, "formatMsg", getFormatMsgGenerate(response.errorCode));
}

function getFormatMsgGenerate(errorCode): string {
    if(errorCode) {
        switch (errorCode) {
            case "000" || "001" :
                return "Usuário inválido.";
            case "002" || "003" :
                return "Cpf / Cnpj inválido.";
            case "004" :
                return "Voucher já utilizado por outro usuário.";
            case "005" :
                return "Voucher bloqueado para esse usuário.";
            case "007" || "008" :
                return "Não temos mais vouchers disponiveis para esse produto.";
            case "009" :
                return "Voucher não encontrado.";
            case "010" :
                return "Certificado não pode ser gerado para esse tipo de usuário.";
            default :
                return "Falha ao conectar com a Soluti. Por favor entre em contato com o admin do sistema."
        }
    }else {
        return "Voucher gerado com sucesso. Um e-mail foi enviado para o usuário com instruções de como realizar a impressão. Você também pode emitir seu comprovante a qualquer momento na aba detalhes.";
    }
}

function treatResponseCancel(response): any {
    _.set(response, "statusFinal", response.type === "SUCCESS" ? "SUCCESS" : "ERROR");
    _.set(response, "formatMsg", getFormatMsgCancel(response.errorCode));
}

function getFormatMsgCancel(errorCode): string {
    if(errorCode) {
        switch (errorCode) {
            case "000" || "0000" || "0001" :
                return "Voucher não encontrado.";
            case "0002" :
                return "Esse voucher já foi utilizado.";
            case "0003" :
                return "Esse voucher já foi cancelado.";
            default :
                return "Falha ao conectar com a Soluti. Por favor entre em contato com o admin do sistema."
        }
    }else {
        return "Voucher cancelado com sucesso.";
    }
}