//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const modeloService = {
  doGet,
  doSave,
  findByFiltro,
  exportToExcel,
  findAll,
  findSemReceituario,
  findReceituario,
  imprimir,
  generateDocumentAndSendEmail,
  doDelete,
  textoModeloSubstituido,
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.MODELO}findAll`);
}

function findSemReceituario(idEstabelecimento) {
  console.log(idEstabelecimento);
  if (idEstabelecimento) {
    return defaultService.doGet(
      `${urlsConstants.MODELO}findSemReceituario/${idEstabelecimento}`
    );
  } else {
    return defaultService.doGet(`${urlsConstants.MODELO}findSemReceituario`);
  }
}

function findReceituario(idEstabelecimento) {
  if (idEstabelecimento) {
    return defaultService.doGet(
      `${urlsConstants.MODELO}findReceituario/${idEstabelecimento}`
    );
  } else {
    return defaultService.doGet(`${urlsConstants.MODELO}findReceituario`);
  }
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.MODELO}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.MODELO}${id}`);
}

function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.MODELO}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.MODELO, entity);
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel(
    'estabelecimento',
    filter,
    fields,
    labels
  );
}

function imprimir(values) {
  return defaultService.doPost(`${urlsConstants.MODELO}imprimir`, values);
}

function textoModeloSubstituido(values) {
  return defaultService.doPost(
    `${urlsConstants.MODELO}textoModeloSubstituido`,
    values
  );
}

function generateDocumentAndSendEmail(values) {
  return defaultService.doPost(
    `${urlsConstants.MODELO}generateAndSendAsyncMedicalDocument`,
    values
  );
}
