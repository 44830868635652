//@flow
import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { signerService } from '../../_services/signer.service';

type Props = {};

type State = {};

class SignDocumentsDownloadPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      secretKey: null,
    };
  }

  updateInputValue = (evt) => {
    this.setState({
      secretKey: evt.target.value,
    });
  };

  download = () => {
    this.props.loading(true);
    if (_.isEmpty(this.state.secretKey)) {
      this.props.loading(false);
      this.props.error({
        message: 'Por favor informe o código de desbloqueio',
      });
    } else {
      signerService
        .download(this.props.match.params.token, this.state.secretKey)
        .then(
          (response) => {
            let array = new Uint8Array(
              [...atob(_.get(response, 'data.data.data'))].map((char) =>
                char.charCodeAt(0)
              )
            );
            const fileURL = URL.createObjectURL(
              new Blob([array], { type: 'application/pdf' })
            );
            window.open(fileURL);
            this.props.loading(false);
          },
          (error) => {
            this.treatSignerSignException(error);
            console.log(error);
            this.props.loading(false);
          }
        );
    }
  };

  treatSignerSignException = (error) => {
    if (error && error.response && error.response.data) {
      this.props.error({
        message: this.treatSignerSignExceptionGetMessage(error.response.data),
      });
    }
  };

  treatSignerSignExceptionGetMessage = (data) => {
    if ('9001' === data.errorCode) {
      return 'Os dados não conferem. Por favor confira o seu código de desbloqueio';
    } else if ('9002' === data.errorCode) {
      return 'Seu código OTP não confere. Por favor valide corretamente o mesmo, junto com seu tempo de expiração e tente novamente.';
    } else {
      return 'Erro desconhecido ao assinar o seu documento. Por favor tente novamente em instantes, caso o erro persista entre em contato com o administrador do sistema.';
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={true}
          backdrop="static"
          modalClassName=""
          className="modal-soluti-otp"
          centered={true}
        >
          <ModalBody>
            <div className="row top-modal">
              <div className="col-12 text-center my-2">
                <h2 className="font-weight-bold">Código de Desbloqueio</h2>
              </div>
            </div>

            <div className="row">
              <Input
                type="text"
                id="code"
                placeholder="Digite aqui o seu código código de desbloqueio"
                onChange={this.updateInputValue}
              />
            </div>
            <hr />
          </ModalBody>
          <ModalFooter>
            <div className="w-100 text-center">
              <button
                className="btn btn-primary"
                type={'button'}
                onClick={() => {
                  this.download();
                }}
              >
                {' '}
                Fazer Download{' '}
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading },
}) => ({
  error: (message) => error(message),
  clear: () => clear(),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
});

export default connect(mapStateToProps, mapDispatch)(SignDocumentsDownloadPage);
