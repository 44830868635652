//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { estoqueService } from '../../../_services/estoque.service';
import EstoqueFaturamento from './EstoqueFaturamento';
import EstoqueProdutosForm from './EstoqueProdutosForm';
import EstoqueProdutosPrecificacaoForm from './EstoqueProdutosPrecificacaoForm';
import EstoqueProdutosUnidadePadraoForm from './EstoqueProdutosUnidadePadraoForm';
import Precificacao from './Precificacao';

// const defaultArrayItem = [{ idList: new Date().getTime() }];

class EstoqueProdutosView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadProduct();
  }

  loadProduct = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      estoqueService.doGet(this.props.match.params.id).then(
        (response) => {
          console.log(response);
          let produto = response.data.data;
          if (produto.estocagem == null) {
            _.set(produto, 'estocagem', {});
          }
          this.setState({ entity: produto }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleCancel = () => {
    console.log('cancel produto');
    this.props.history.goBack();
  };

  handleDeleteItem = (index, entity) => {
    this.props.loading(true);
    estoqueService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Produto foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push('/estoque-produtos');
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir produto.',
        });
      }
    );
  };
  // addUnidadeFracao = () => {
  //   let newProduct = _.cloneDeep(this.state.entity);
  //   newProduct.listUnidadeFracao.unshift({});

  //   this.setState({ entity: newProduct });
  // };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        console.log(erros);
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {entity && entity.id && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  produto`}
              canBack={true}
              backUrl={!entity.id ? null : '/estoque-produtos'}
            />
            <TopoListagem
              showIndisponibilidade={false}
              showfilter={false}
            ></TopoListagem>
            <div className="flex-column">
              <FullCustomCard
                permissionSave={objectsConstants.MENU_ESTOQUE}
                ref={(form) => {
                  this.refFormComponent = form;
                }}
                title="Dados do produto"
                Componente={EstoqueProdutosForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadProduct}
                onSave={this.loadProduct}
                btnExcluir={true}
                removeItem={this.handleDeleteItem}
                redirectPath="/estoque-produtos/view/"
                {...otherPops}
              ></FullCustomCard>
            </div>
          </React.Fragment>
        )}

        <>
          {/* Inicio Precificação  */}
          <div className="d-flex align-items-center justify-content-center">
            <Precificacao />
          </div>
          <FullCustomCard
            ref={(form) => {
              this.refFormComponent = form;
            }}
            permissionSave={objectsConstants.MENU_ESTOQUE}
            title="Precificação por convênio"
            Componente={EstoqueProdutosPrecificacaoForm}
            viewMode={true}
            entity={entity}
            onCancel={this.loadProduct}
            {...otherPops}
          ></FullCustomCard>
          {/* Fim Precificação  */}
        </>
        {/* )} */}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  null,
  mapDispatch,
  null
)(withRouter(EstoqueProdutosView));
