//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactHtmlParser from 'react-html-parser';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import DadosUser from '../../components/DadosUser/DadosUser';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import FormTextCKEditor from '../../components/inputs/FormTextCKEditor';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import { ModalAnexos } from '../../components/Modal/ModalAnexos';
import { ModalCids } from '../../components/Modal/ModalCids';
import { ModalModelAnamnese } from '../../components/Modal/ModalModelAnamnese';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import { urlsConstants } from '../../_constants/urls.constant';
import { translate } from '../../_helpers/messages.helper';
import { defaultService } from '../../_services/defaultService';
import { doencaService } from '../../_services/doencas.service';
import { prontuarioService } from '../../_services/prontuario.service';
import { protocoloAnamneseService } from '../../_services/protocoloAnamnese.service';

type Props = {
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
};

const AnamneseValidate = Yup.object().shape({
  //   nome: Yup.string()
  //     .min(10, 'Nome muito curto!')
  //     .max(60, 'Nome muito grande!')
  //     .required('Obrigatório'),
  //   cpfCnpj: Yup.string().required('Obrigatório'),
  //   dataNascimento: Yup.date().required('Obrigatório'),
  //   email: Yup.string().required('Obrigatório').email('Email inválido'),
});

class AnamneseHistoricoDadosForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openAnexo: false,
      openCids: false,
      position: null,
    };
    // console.log(this.props.receituarioON);
  }

  toggleSaveModelAnamnese = () => {
    this.setState(({ openSaveModelAnamnese }) => ({
      openSaveModelAnamnese: !openSaveModelAnamnese,
    }));
  };
  inserirSelecionados = (cids) => {
    let anamnese = _.cloneDeep(this.props.values.anamnese);
    if (!anamnese) {
      anamnese = '';
    }
    let textCids = '';
    for (let cid of cids) {
      textCids = textCids + '<p>' + cid.codigo + ' - ' + cid.descricao + '</p>';
    }
    if (anamnese.indexOf('&nbsp;&nbsp;&nbsp;') > 0) {
      anamnese = anamnese.replace('&nbsp;&nbsp;&nbsp;', textCids);
    } else {
      anamnese = anamnese + textCids;
    }
    this.props.setFieldValue('anamnese', anamnese);
    this.toggleCids();
  };
  saveForm = () => {
    const { callbackOnSave, atendimentoEmAtendimento } = this.props;
    let _this = this;
    let values = _this.formRef.state.values;
    _.unset(values, 'modelo');
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.props.loading(true);
          if (atendimentoEmAtendimento) {
            values.fkMarcacao = atendimentoEmAtendimento.idMarcacao;
          }
          prontuarioService.doSave(values).then(
            (response) => {
              _this.props.success({
                message: `Prontuário, foi salvo com sucesso!`,
              });
              let id = values.id
                ? values.id
                : defaultService.getIdFromUrl(response.headers.location);
              values.id = id;
              _this.props.loading(false);
              if (callbackOnSave) {
                callbackOnSave(values, atendimentoEmAtendimento);
              }
              resolve(true);
            },
            (erros) => {
              _this.props.loading(false);
              _this.props.error({
                message: 'Não foi possível salvar prontuário.',
              });
              try {
                let response = erros.response.data;
                if (response && response.messages) {
                  for (var i = 0; i < response.messages.length; i++) {
                    let erroItem = response.messages[i];
                    _this.formRef.setFieldError(
                      erroItem.fieldName,
                      translate(erroItem.message.code)
                    );
                  }
                }
              } catch (error) {
                console.log(error);
              }
              console.log(erros);
            }
          );
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };
  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  toggleCids = () => {
    this.setState(({ openCids }) => ({
      openCids: !openCids,
      position: this.editor.model.document.selection.getFirstPosition(),
    }));
  };

  imprimeAnamnese = (values) => {
    this.props.loading(true);
    prontuarioService.imprimir(values).then(
      (response) => {
        var base64 = _.get(response, 'data.data.data');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );
        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
      }
    );
  };

  finalizaAtendimento = (id) => {
    if (this.props.client.connected) {
      this.props.client.send(
        urlsConstants.SEND_PACIENTE_STATUS,
        JSON.stringify({
          id: id,
          status: objectsConstants.ATENDIDO,
        })
      );
    }
  };

  render() {
    const {
      viewMode,
      values,
      setFieldValue,
      setValues,
      errors,
      entity,
      atendimentoEmAtendimento,
      ...otherProps
    } = this.props;
    let _this = this;
    return (
      <React.Fragment>
        <div className={`form-row ${viewMode ? 'd-none' : ' '}`}>
          {!viewMode && (
            <div className="col-12 mt-2">
              <InputViewEdit
                label="Modelo de Anamnese"
                placeholder="Selecione um modelo de anamnese já salvo"
                component={FormSelectInput}
                service={protocoloAnamneseService.findByMedico}
                labelKey={'nome'}
                valueKey={'id'}
                returnFullObject={true}
                defaultValue={_.get(values, `modelo`, '--')}
                required={false}
                value={_.get(values, `modelo`)}
                name={`modelo`}
                viewMode={viewMode}
                id={`modelo`}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                  setFieldValue('anamnese', value.anamnese);
                }}
              />
            </div>
          )}
        </div>

        <div className={' d-flex'}>
          {values.id &&
            values.listAnexos &&
            values.listAnexos.length > 0 &&
            viewMode && (
              <button
                type="button"
                className="btn btn-info d-flex align-items-center"
                onClick={this.toggleAnexos}
              >
                <span className="icon-anexar"></span>Anexos
              </button>
            )}
          {!viewMode && (
            <button
              type="button"
              className="btn btn-info d-flex align-items-center"
              onClick={this.toggleAnexos}
            >
              <span className="icon-anexar"></span> Anexar
            </button>
          )}
          {!viewMode && (
            <button
              type="button"
              className="btn btn-warning d-flex align-items-center"
              onClick={this.toggleCids}
            >
              <span className="icon-anexar"></span> Inserir C.I.D
            </button>
          )}
        </div>

        <div className=" p-0 mb-5 descricao-anamnese">
          {!viewMode && (
            <FormTextCKEditor
              id={'anamnese'}
              name={'anamnese'}
              label={''}
              className={'textCKeditor'}
              type={'textarea'}
              disabled={false}
              required={true}
              onChange={setFieldValue}
              erroMensagem={errors.anamnese}
              viewMode={viewMode}
              onInit={(editor) => {
                _this.editor = editor;
              }}
              viewClassName={'view-anamnese'}
              value={values.anamnese}
              defaultValue={values.anamnese}
              placeholder={'Escreva aqui a anamnese do paciente'}
              removePlugins={[
                'Heading',
                'BlockQuote',
                'ImageUpload',
                'MediaEmbed',
              ]}
            />
          )}
          {viewMode && ReactHtmlParser(values.anamnese)}
        </div>
        <div className="form-row d-flex justify-content-left buttons-bottom mt-auto">
          <ModalCids
            ref={(anexos) => (this._anexosInput = anexos)}
            name="cids"
            toogle={this.toggleCids}
            isOpen={this.state.openCids}
            updateCids={() => this.atualizaCids(values)}
            target={'anexos'}
            itens={values.cids ? values.cids : []}
            onChange={setFieldValue}
            viewMode={viewMode}
            user={this.props.user}
            inserirSelecionados={this.inserirSelecionados}
          />

          <ModalAnexos
            ref={(anexos) => (this._anexosInput = anexos)}
            name="listAnexos"
            toogle={this.toggleAnexos}
            isOpen={this.state.openAnexo}
            target={'anexos'}
            itens={values.listAnexos ? values.listAnexos : []}
            onChange={setFieldValue}
            viewMode={viewMode}
            user={this.props.user}
          />
          {values.id && viewMode && !values.protocoloAtendimentoAnamnese && (
            <React.Fragment>
              <button
                type="button"
                className="btn btn btn-info"
                onClick={() => {
                  this.imprimeAnamnese(values);
                }}
              >
                Imprimir anamnese
              </button>
            </React.Fragment>
          )}
          {values.id && values.listAnexos && viewMode && (
            <div
              id="anexos"
              className="pointer d-flex align-items-center ml-3"
              onClick={this.toggleAnexos}
            >
              <React.Fragment>
                <span
                  className={`destaque font-bold ${
                    _.size(values.listAnexos) === 0 && 'd-none'
                  }`}
                >
                  {_.size(values.listAnexos) === 1
                    ? `${_.size(values.listAnexos)} arquivo anexado`
                    : `${_.size(values.listAnexos)} arquivos anexados`}
                </span>
              </React.Fragment>
            </div>
          )}
        </div>

        <ModalModelAnamnese
          ref={(modelAnamnese) => (this._modelAnamneseInput = modelAnamnese)}
          name="listmodel"
          toogle={this.toggleSaveModelAnamnese}
          isOpen={this.state.openSaveModelAnamnese}
          target={'model'}
          user={this.props.user}
          entity={values}
          callbackOnSave={(savedEntity) => setValues(savedEntity)}
          {...otherProps}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { client } = state.webSocket;
  return {
    user,
    client,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(AnamneseHistoricoDadosForm);
