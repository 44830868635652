//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';

type Props = {
  showVoltar: boolean,
  dispatch: any,
  typeButton: string,
  handle: any,
  handleExtraButton: any,
  showExtraButton: boolean,
  typeExtraButton: string,
  isAnamneseEditing: boolean,
};

class Header extends React.Component<Props> {
  // openSidebar = () => {
  //   const wrapper = document.getElementById('wrapper');
  //   if (wrapper) {
  //     wrapper.classList.toggle('toggled');
  //   }
  // };
  abreTarefas = () => {
    this.props.history.push('/tarefas');
  };

  handleBack = () => {
    const { backUrl, onClickBack } = this.props;

    if (backUrl) {
      this.props.history.push(backUrl);
    } else {
      this.props.history.goBack();
    }
    if (onClickBack) {
      onClickBack();
    }
  };
  render() {
    const { backConfirmObject, aguardandoPagamento } = this.props;
    return (
      <header id="app-header" className="navbar navbar-light sticky-top">
        <div>
          {!this.props.canBack && (
            <button
              className="header-icon menu-icon"
              type="button"
              onClick={() => {
                this.props.openMenu();
              }}
            ></button>
          )}
          {this.props.canBack && (
            <button
              className=" header-icon back-icon"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={(e) => {
                if (backConfirmObject) {
                  ConfirmAcationService.confirmMsg(
                    this.handleBack,
                    backConfirmObject.title,
                    backConfirmObject.message,
                    backConfirmObject.subMessage,
                    'Não',
                    'Sim',
                    backConfirmObject.type
                  );
                } else {
                  this.handleBack();
                }
              }}
            >
              <p className="txt-header"> Voltar</p>
            </button>
          )}
        </div>
        <div className="header-title">{this.props.mainTitle}</div>
        {!this.props.aguardandoPagamento && (
          <button
            onClick={() => {
              this.abreTarefas();
            }}
            type="button"
            className="header-icon task-icon"
          >
            {this.props.taskListSize > 0 && (
              <span className="badge badge-danger">
                {this.props.taskListSize}
              </span>
            )}
          </button>
        )}
        {this.props.aguardandoPagamento && <div></div>}
      </header>
    );
  }

  createNewAnamneseButton = () => {
    if (!this.props.isAnamneseEditing) {
      return (
        <button
          className="menu-button font-medium-38"
          type="button"
          onClick={this.props.handleExtraButton}
        >
          <span className="header-icon mais-icon" />
        </button>
      );
    }
  };
}

function mapStateToProps(state) {
  const {
    typeButton,
    handle,
    handleExtraButton,
    showExtraButton,
    typeExtraButton,
  } = state.header;
  const { taskListSize } = state.task;

  const {
    mainTitle,
    subTitle,
    canBack,
    backUrl,
    backConfirmObject,
    onClickBack,
  } = state.pageTitle;

  const { isAnamneseEditing } = state.anamnese;
  return {
    typeButton,
    handle,
    handleExtraButton,
    showExtraButton,
    typeExtraButton,
    isAnamneseEditing,
    mainTitle,
    subTitle,
    canBack,
    backUrl,
    backConfirmObject,
    onClickBack,
    taskListSize,
  };
}

const connectedHeader = withRouter(connect(mapStateToProps)(Header));
export { connectedHeader as Header };
