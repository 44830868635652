import _ from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { objectsConstants } from '../../_constants/objects.constants';
import { cardHelper } from '../../_helpers/card.helper';
import { registerService } from '../../_services/register.service';
import MultiStepProgressBar from './components/MultiStepProgressBar';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';

class CadastroWizard extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    // Set the intiial input values
    this.state = {
      currentStep: 1,
      entity: {
        acceptTermosUso: true,
        // tipoPagamento: 'CARTAO_CREDITO',
        // /////
        // cpfCnpj: '782.562.650-86',
        // senha: '123',
        // confirmaSenha: '123',
        // nome: 'Estab Recusado',
        // email: 'dudubernardino@hotmail.com',
        // endereco: {
        //   cep: '32.043-040',
        //   cidade: 'Contagem',
        //   uf: 'MG',
        //   bairro: 'Europa',
        //   logradouro: 'Rua Capitão Britaldo Soares Ferreira Diniz',
        //   numero: '123',
        //   estado: 'AM',
        // },
        // nomeResponsavel: 'Edu Bernardino',
        // cpf: '782.562.650-86',
        // emailEstabelecimento: 'eduardo.almeida@marph.com.br',
        // quantidadeProfissionais: 1,
        formaPagamento: {
          type: 'CARTAO_CREDITO',
        },

        ////
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }

  handleChange = (name: any, value: string) => {
    let form = this.refComponent.form;
    const entity = _.cloneDeep(form.state.values);
    _.set(entity, name, value);
    this.setState({
      entity,
    });
  };

  concluir = () => {
    let entitySend = _.cloneDeep(this.state.entity);
    let form = this.refComponent.form;
    this.props.exibeLoading(true);
    let tipoPagamento = entitySend.tipoPagamento;
    _.set(entitySend, 'formaPagamento.type', tipoPagamento);
    if (tipoPagamento === objectsConstants.CARTAO_CREDITO) {
      let bandeira = cardHelper.getBandeiraCartao(
        _.get(entitySend, 'formaPagamento.numeroCartao')
      );
      if (bandeira != null) {
        _.set(entitySend, 'formaPagamento.brand', bandeira);

        registerService.register(entitySend).then(
          (response) => {
            console.log(response.data);
            let res = response.data;
            _.set(entitySend, 'response', res);
            if (response) {
              this.setState(
                {
                  currentStep: 6,
                  entity: entitySend,
                },
                () => {
                  this.props.exibeLoading(false);
                }
              );
            }
          },
          (error) => {
            this.props.exibeLoading(false);
            console.log('Erro: ', error);
          }
        );
      } else {
        this.props.exibeLoading(false);
        form.setFieldError(
          'formaPagamento.numeroCartao',
          'Número de cartão inválido'
        );
      }
    }
  };

  validaCpfCnpj = () => {
    let form = this.refComponent.form;
    var cpfCnpj = _.get(form.state.values, 'cpfCnpj');
    form.validateForm(form.state.values).then((erros) => {
      console.log(erros);
      if (_.isEmpty(erros)) {
        registerService.validateCpfCnpj(cpfCnpj).then(
          (response) => {
            if (response) {
              this._next();
            }
          },
          (error) => {
            if (_.get(error, 'response.data') === 'CPF_CNPJ_ALREADY_USED') {
              console.log('CPF/CNPJ já cadastrado');
              form.setFieldError('cpfCnpj', 'CPF/CNPJ já cadastrado');
            } else {
              console.log('Erro geral');
              this.props.exibeLoading(false);
              this.props.exibeErro(
                'Não foi possível verificar o CPF/CNPJ, tente novamente mais tarde'
              );
            }
          }
        );
      }
    });
  };

  validaCpf = () => {
    let form = this.refComponent.form;
    var cpf = _.get(form.state.values, 'cpf');
    console.log(form.state.values);
    console.log(cpf);
    form.validateForm(form.state.values).then((erros) => {
      console.log(erros);
      if (_.isEmpty(erros)) {
        registerService.validateCpf(cpf).then(
          (response) => {
            if (response) {
              this._next();
            }
          },
          (error) => {
            if (_.get(error, 'response.data') === 'CPF_ALREADY_USED') {
              console.log('CPF já cadastrado');
              form.setFieldError('cpf', 'CPF já cadastrado');
            } else {
              console.log('Erro geral');
              this.props.exibeLoading(false);
              this.props.exibeErro(
                'Não foi possível verificar o CPF, tente novamente mais tarde'
              );
            }
          }
        );
      }
    });
  };

  validaForm = () => {
    let form = this.refComponent.form;
    console.log(form.state.values);
    form.validateForm(form.state.values).then((erros) => {
      console.log(erros);
      if (_.isEmpty(erros)) {
        if (this.state.currentStep !== 5) {
          this._next();
        } else {
          this.concluir();
        }
      }
    });
  };

  _next() {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 5 ? 6 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  get previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1 && currentStep !== 6) {
      return (
        <Button color="secondary float-left" onClick={this._prev}>
          Passo Anterior
        </Button>
      );
    }

    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep > 1 && currentStep < 5) {
      return (
        <Button
          color="primary float-right"
          onClick={
            this.state.currentStep === 3 ? this.validaCpf : this.validaForm
          }
        >
          Próximo passo
        </Button>
      );
    }
    if (currentStep === 1) {
      return (
        <Button color="primary float-right" onClick={this.validaCpfCnpj}>
          Criar conta
        </Button>
      );
    }
    return null;
  }

  get submitButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 5) {
      return (
        <Button color="primary float-right" onClick={this.validaForm}>
          Concluir
        </Button>
      );
    }
    if (currentStep === 6) {
      return (
        <Button color="secondary float-right" onClick={this.props.voltar}>
          Sair
        </Button>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.state.currentStep === 1 && (
          <div className="float-left fade-in ">
            <button
              className=" header-icon back-icon"
              type="button"
              onClick={this.props.voltar}
            >
              <p className="txt-header"> Voltar</p>
            </button>
          </div>
        )}
        <div className="logo-sasc-cm"></div>
        {/* <Form className="h-100 d-flex flex-column" onSubmit={this.handleSubmit}> */}
        {this.state.currentStep !== 6 && (
          <div className="px-5">
            <MultiStepProgressBar currentStep={this.state.currentStep} />
          </div>
        )}
        {this.state.currentStep === 1 && (
          <Step1
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            entity={this.state.entity}
            ref={(formRef) => {
              this.refComponent = formRef;
            }}
          />
        )}
        {this.state.currentStep === 2 && (
          <Step2
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            entity={this.state.entity}
            ref={(formRef) => {
              this.refComponent = formRef;
            }}
          />
        )}
        {this.state.currentStep === 3 && (
          <Step3
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            entity={this.state.entity}
            ref={(formRef) => {
              this.refComponent = formRef;
            }}
          />
        )}
        {this.state.currentStep === 4 && (
          <Step4
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            entity={this.state.entity}
            ref={(formRef) => {
              this.refComponent = formRef;
            }}
          />
        )}
        {this.state.currentStep === 5 && (
          <Step5
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            exibeAlertErro={this.props.exibeAlertErro}
            entity={this.state.entity}
            ref={(formRef) => {
              this.refComponent = formRef;
            }}
          />
        )}
        {this.state.currentStep === 6 && (
          <Step6
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            entity={this.state}
          />
        )}
        {/* <Step7
          currentStep={this.state.currentStep}
          handleChange={this.handleChange}
          voltar={this.props.voltar}
        /> */}
        <div className="boxButtonCadastro mt-auto">
          {this.previousButton}
          {this.nextButton}
          {this.submitButton}
        </div>
        {/* </Form> */}
      </>
    );
  }
}

export default CadastroWizard;
