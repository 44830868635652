//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const categoriaDocumentoService = {
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.CATEGORIA_DOCUMENTO}findAll`);
}
