//@flow
import _ from 'lodash';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import {objectsConstants} from '../../_constants/objects.constants';
import {tableHelper} from '../../_helpers/tableHelper';
import VoucherListFilter from './VoucherListFilter';
import {voucherService} from "../../_services/voucher.service";
import {userService} from "../../_services/user.service";

const columns = [
  {
    path: 'userVO.foto',
    title: '',
    user: 'userVO',
    isKey: false,
    isAvatar: true,
    dataSort: false,
    propsMidiaId: 'userVO.foto.id',
    className: 'avatar-list',
    width: '100px',
    notExport: true,
  },
  {
    path: 'voucherNumber',
    pathFilter: 'voucherNumber',
    title: 'Número',
    isKey: true,
    dataSort: true,
    width: '100px',
  },
  {
    path: 'userVO.nome',
    pathFilter: 'owner',
    title: 'Proprietário',
    isKey: false,
    dataSort: true,
    width: '40%',
  },
  {
    path: 'userVO.cpfCnpj',
    pathFilter: 'identifier',
    title: 'CPF / CNPJ',
    cpfCnpj: true,
    isKey: false,
    dataSort: true,
    width: '20%',
  },
  {
    path: 'status',
    pathFilter: 'status',
    title: 'Status',
    isStatusVoucher: true,
    isKey: false,
    dataSort: true,
    width: '40%',
  },
  {
    path: 'creationDate',
    pathFilter: 'creationDate',
    title: 'Data de criação',
    isDate: true,
    isKey: false,
    dataSort: true,
    width: '40%',
  },
  {
    path: 'overdueDate',
    pathFilter: 'overdueDate',
    title: 'Data de Expiração',
    isDate: true,
    isKey: false,
    dataSort: true,
    width: '40%',
  }
];

class VoucherListagemPage extends React.PureComponent<{}, State> {

  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
      hasVoucherUsedOrEnable: false
    };
    if(userService.isMedico()) {
      voucherService.hasVoucherUsedOrEnable().then((response) => {
        this.setState({
          hasVoucherUsedOrEnable: response
        });
      })
    }
  }

  handleRowClick = (row: e) => {
    this.props.history.push("/voucher-soluti/view/" + row.id);
  };

  handleNewButton = () => {
    if(userService.isAdministrador() || userService.isSudo()) {
      this.props.history.push("/voucher-soluti/new");
    }else if(userService.isMedico()) {
      this.props.loading(true);
      voucherService.generate(userService.getCurrentUser().id).then((response) => {
        if(response.statusFinal === "SUCCESS") {
          this.props.success({message: response.formatMsg});
          this.props.history.push("/voucher-soluti");
        }else {
          this.props.error({message: response.formatMsg});
        }
        this.props.loading(false);
      })
    }
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension
    };

    if(userService.isMedico()) {
      filter.owner = this.props.user.nome;
      filter.identifier = this.props.user.cpfCnpj;
    }

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_VOUCHER_SOLUTI}
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.INSERT}
          securityActionOn={objectsConstants.MENU_VOUCHER}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Gerar Novo Voucher'}
          hideNewButton={!(userService.isAdministrador() || userService.isSudo() || userService.isMedico()) || (userService.isMedico() && this.state.hasVoucherUsedOrEnable)}
        ></TopoListagem>
        <ListagemPage
          title={'Vouchers'}
          showNewButton={false}
          delete={() => {}}
          service={voucherService}
          defaultOrder={'owner'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          FormFilter={VoucherListFilter}
          controller="api/soluti/voucher"
          fields={this.state.fields}
          labels={this.state.labels}
          onRowClick={this.handleRowClick}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
                       alert: {success, error, clear},
                       load: {loading},
                       authentication: {refreshUser},
                     }) => ({
  refreshUser: (user) => refreshUser({user}),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({load}),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps, mapDispatch, null, {})(VoucherListagemPage);
