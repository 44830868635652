//@flow
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import DashboardFinanceiroPage from './DashboardFinanceiroPage';


export default class DashboardFinanceiroIndexPage extends React.Component<{}> {
  render() {
    console.log(111111)
    return (
      <Switch>
        <Route path="/dashboard-financeiro" component={DashboardFinanceiroPage} exact />
      </Switch>
    );
  }
}
