//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import HorarioAtendimentoExames from '../../components/HorarioAtendimentoExames/HorarioAtendimentoExames';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import { convenioService } from '../../_services/convenio.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { exameService } from '../../_services/exame.service';
import ExameForm from './ExameForm';

const defaultArrayItem = [{ idList: new Date().getTime() }];

class ExameView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadConsultorios = (estabelecimento) => {
    console.log('Consultorio');
    estabelecimentoService.findConsultorios(estabelecimento.id).then(
      (response) => {
        let consultorios = response.data;
        let newEstabelecimento = _.cloneDeep(estabelecimento);
        if (consultorios && consultorios.length) {
          newEstabelecimento.listConfiguracaoSala = consultorios;
        }
        this.setState(
          Object.assign({}, this.state.entity, newEstabelecimento),
          () => {
            this.props.loading(false);
            this.loadConvenios(newEstabelecimento);
          }
        );
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };
  loadConvenios = (estabelecimento) => {
    console.log('Convenios');
    convenioService.findAll(estabelecimento.id).then(
      (response) => {
        let convenios = response.data;
        let newEstabelecimento = Object.assign({}, estabelecimento);
        if (convenios && convenios.length) {
          newEstabelecimento.listConvenio = convenios;
        }
        this.setState({ entity: newEstabelecimento }, () => {
          this.props.loading(false);
        });
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  loadUser = () => {
    console.log('User');
    if (this.props.match.params.id) {
      this.props.loading(true);
      exameService.doGet(this.props.match.params.id).then(
        (response) => {
          let exame = response.data.data;
          console.log(response.data.data);
          if (!_.get(exame, 'estabelecimentoExame.length')) {
            console.log('não tem convenio');
            exame.estabelecimentoExame = [{}];
          }

          this.setState({ entity: _.cloneDeep(exame) }, () => {
            this.props.loading(false);
            this.loadConsultorios(exame);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  deletaLocalAtendimento = (index) => {
    let newExame = _.cloneDeep(this.state.entity);
    let idEstabelecimentoExame = newExame.estabelecimentoExame[index].id;
    exameService.deleteLocalHorarioAtendimento(idEstabelecimentoExame).then(
      (response) => {
        if (newExame.estabelecimentoExame.length === 1) {
          newExame.estabelecimentoExame.splice(index, 1);
          newExame.estabelecimentoExame.push({
            idList: new Date().getTime(),
            exame: { id: this.props.match.params.id },
            horariosAtendimento: [{}],
          });
        } else {
          newExame.estabelecimentoExame.splice(index, 1);
        }
        this.setState({ entity: newExame });
        this.props.success({
          message: 'Horário excluído com sucesso.',
        });
      },

      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  render() {
    // let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    console.log('render');
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={entity.nome}
          canBack={true}
          backUrl={'/exame'}
        />

        <div className="flex-column mt-4">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_EXAME}
            title="Exame"
            Componente={ExameForm}
            viewMode={true}
            entity={entity}
            onSave={this.loadUser}
            cancelForm={this.loadUser}
            {...otherPops}
          ></FullCustomCard>
          {entity &&
            entity.estabelecimentoExame &&
            entity.estabelecimentoExame.map((estabelecimentoExame, index) => {
              return (
                <React.Fragment
                  key={
                    estabelecimentoExame.id
                      ? estabelecimentoExame.id + index
                      : estabelecimentoExame.idList + index
                  }
                >
                  <FullCustomCard
                    permissionSave={objectsConstants.MENU_EXAME}
                    title={
                      estabelecimentoExame.estabelecimento
                        ? _.get(estabelecimentoExame, 'estabelecimento.nome')
                        : 'Local e horários de atendimento'
                    }
                    Componente={HorarioAtendimentoExames}
                    viewMode={estabelecimentoExame.id ? true : false}
                    entity={estabelecimentoExame}
                    fullEntity={entity}
                    btnExcluir={true}
                    removeItem={(index) => {
                      this.deletaLocalAtendimento(index);
                    }}
                    index={index}
                    cancelForm={this.loadEntity}
                    onSave={this.loadEntity}
                    periodicidade={true}
                    {...otherPops}
                  ></FullCustomCard>
                </React.Fragment>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(ExameView));
