//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EstoqueSaidaEdit from './EstoqueSaidaEdit';
import EstoqueSaidaListagemPage from './EstoqueSaidaListagemPage';
import EstoqueSaidaView from './EstoqueSaidaView';

export default class EstoqueSaidaIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/estoque-saida/novo" component={EstoqueSaidaEdit} />
        <Route path="/estoque-saida/view/:id" component={EstoqueSaidaView} />
        <Route path="/estoque-saida" component={EstoqueSaidaListagemPage} />
      </Switch>
    );
  }
}
