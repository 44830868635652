//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';
import IconErrorToast from '../components/ToastIcons/IconErrorToast';
import IconSucessToast from '../components/ToastIcons/IconSucessToast';
import IconWarningToast from '../components/ToastIcons/IconWarningToast';
import IconPendenciaToast from '../components/ToastIcons/IconPendenciaToast';
import IconInfoToast from '../components/ToastIcons/IconInfoToast';
import IconInfoBlackToast from '../components/ToastIcons/IconInfoBlackToast';

const TIMOUT_TOAST = 6000;
export default {
  state: {},
  effects: () => ({
    success: payload => {
      toastr.success(
        (payload.title ? payload.title : ''),
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          position: 'bottom-right',
          className: 'toast-marph toast-marph-success',
          icon: <IconSucessToast />,
        }
      );
    },
    error: payload => {
      console.log(payload)
      toastr.error(

        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          position: 'bottom-right',
          className: 'toast-marph toast-marph-erro',
          icon: <IconErrorToast />
        }
      );
    },
    warning: payload => {
      toastr.warning(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          position: 'bottom-right',
          className: 'toast-marph toast-marph-warning',
          icon: <IconWarningToast />
        }
      );
    },
    pendencia: payload => {
      toastr.success(
        (payload.title ? payload.title : ''),
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          position: 'bottom-right',
          className: 'toast-marph toast-marph-pendencia',
          icon: <IconPendenciaToast />
        }
      );
    },
    clear: () => {
      toastr.clean();
    },
    info: async payload => {
      toastr.info(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          position: 'bottom-right',
          className: 'toast-marph toast-marph-info branco',
          icon: <IconInfoToast />
        }
      );
    },
    infoBlack: async payload => {
      toastr.info(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          position: 'bottom-right',
          className: 'toast-marph toast-marph-info-black',
          icon: <IconInfoBlackToast />
        }
      );
    }

  })
};
