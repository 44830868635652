//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import CheeseburgerMenu from '../../components/menu/CheeseburgerMenu';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import ListagemRelatorioPage from '../../components/templates/ListagemRelatoriosPage';
import TopoListagem from '../../components/Topo/TopoListagem';
import { objectsConstants } from '../../_constants/objects.constants';
import { tableHelper } from '../../_helpers/tableHelper';
import { relatorioExamesService } from '../../_services/relatorioExames.service';
import { userService } from '../../_services/user.service';
import Historico from '../Historico/Historico';
import RelatoriosExamesFilter from './RelatoriosExamesFilter';

const columnsAnalitico = [
  {
    path: 'id',
    pathFilter: 'id',
    title: 'Código',
    isKey: true,
    dataSort: true,
    // width: "5%",
  },
  {
    path: 'dataConsulta',
    pathFilter: 'dataConsulta',
    title: 'Data',
    isKey: false,
    dataSort: true,
    isDate: true,
    className: 'column-bold',
    // width: '33%',
  },
  {
    path: 'hora',
    pathFilter: 'hora',
    title: 'Hora',
    isKey: false,
    dataSort: true,
    className: 'column-bold',
  },
  {
    path: 'pacienteNome',
    pathFilter: 'paciente',
    title: 'Paciente',
    isKey: false,
    dataSort: true,
    className: 'column-bold',
    width: '16%',
  },
  {
    path: 'tipoAtendimento',
    pathFilter: 'tipoAtendimento',
    title: 'Tipo do atendimento',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'convenio',
    pathFilter: 'convenio',
    title: 'Convênio',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'exameNome',
    pathFilter: 'exame',
    title: 'Exame',
    isKey: false,
    dataSort: true,
    className: 'column-bold',
    width: '16%',
  },
  {
    path: 'tipo',
    pathFilter: 'tipo',
    title: 'Tipo',
    isKey: false,
    dataSort: true,
    className: '',
  },
  {
    path: 'status',
    pathFilter: 'status',
    title: 'Status',
    isKey: false,
    dataSort: true,
    className: '',
    isStatusMarcacao: true,
  },
  {
    path: 'valor',
    pathFilter: 'valor',
    title: 'Valor',
    isKey: false,
    dataSort: true,
    className: '',
  },
];

const columnsSintetico = [
  {
    path: 'count',
    pathFilter: 'count',
    title: 'Quantidade de atendimentos',
    isKey: true,
    dataSort: true,
    className: 'column-bold',
    width: '15%',
  },
  {
    path: 'exameNome',
    pathFilter: 'exame',
    title: 'Exame',
    isKey: false,
    dataSort: true,
    className: 'column-bold',
    width: '22%',
  },
  {
    path: 'tipoAtendimento',
    pathFilter: 'tipoAtendimento',
    title: 'Tipo do atendimento',
    isKey: false,
    dataSort: true,
  },

  {
    path: 'tipo',
    pathFilter: 'tipo',
    title: 'Tipo',
    isKey: false,
    dataSort: true,
    className: '',
  },
  {
    path: 'status',
    pathFilter: 'status',
    title: 'Status',
    isKey: false,
    dataSort: true,
    className: '',
    isStatusMarcacao: true,
  },
];

type State = {
  showAnalyticData: boolean,
  showSyntheticData: boolean,
  filter: FilterRelatorio,
};

interface FilterRelatorio {
  tipoRelatorio?: string;
  dataStart?: Date;
  dataEnd?: Date;
  listTipoAtendimento?: string[];
  listTipoConsulta?: string[];
  tipo?: string[];
  listFkMedico?: any[];
  listStatus?: string[];
  listPaciente?: any[];
}

class RelatoriosExamesListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);

    let fieldsAnalitico = _.map(
      _.filter(columnsAnalitico, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labelsAnalitico = _.map(
      _.filter(columnsAnalitico, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );

    let fieldsSintetico = _.map(
      _.filter(columnsSintetico, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labelsSintetico = _.map(
      _.filter(columnsSintetico, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labelsAnalitico,
      fieldsAnalitico,
      labelsSintetico,
      fieldsSintetico,
    };
  }

  handleRowClick = (row: e, columnIndex, rowIndex) => {
    if (userService.isAdministrador() || userService.isSuporte()) {
      this.props.receiveIdEntityHistorico(
        row.id,
        objectsConstants.TIPO_HISTORICO_MARCACAO
      );
      this.props.fechaHistorico();
      this.props.abreHistorico();
    }
  };

  loadRelatorioData = (filter: FilterRelatorio) => {
    this.setState(
      {
        showSyntheticData:
          !filter.tipoRelatorio || filter.tipoRelatorio === 'SINTETICO',
        showAnalyticData:
          filter.tipoRelatorio && filter.tipoRelatorio === 'ANALITICO',
        filter: filter,
      },
      () => {}
    );
  };

  render() {
    const tableColumn = _.map(
      this.state.showAnalyticData ? columnsAnalitico : columnsSintetico,
      (column, index) => (
        <TableHeaderColumn
          key={index}
          dataField={column.path}
          dataFormat={tableHelper.renderCustom}
          formatExtraData={column}
          isKey={column.isKey}
          dataSort={column.dataSort}
          hidden={column.hidden}
          width={column.width}
          className={column.classname}
        >
          {column.title}
        </TableHeaderColumn>
      )
    );

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_RELATORIOS_EXAMES}
          subTitle=" "
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_RELATORIOS}
          buttonAction={() => {}}
          securityActionIcon={''}
          securityActionName={''}
        ></TopoListagem>
        <RelatoriosExamesFilter submitFilter={this.loadRelatorioData} />
        <ListagemRelatorioPage
          title={
            this.state.showAnalyticData
              ? 'Listagem de exames Analítico'
              : 'Listagem de exames Sintético'
          }
          service={relatorioExamesService}
          controller="/api/relatorio/exames"
          defaultOrder={'dataConsulta'}
          defaultDirection={'desc'}
          filtro={this.state.filter}
          onRowClick={this.handleRowClick}
          fields={
            this.state.showAnalyticData
              ? this.state.fieldsAnalitico
              : this.state.fieldsSintetico
          }
          labels={
            this.state.showAnalyticData
              ? this.state.labelsAnalitico
              : this.state.labelsSintetico
          }
          columns={
            this.state.showAnalyticData ? columnsAnalitico : columnsSintetico
          }
          selectRow={{ bgColor: 'red' }}
        >
          {tableColumn}
        </ListagemRelatorioPage>

        <CheeseburgerMenu
          isOpen={this.props.historicoAberto}
          closeCallback={this.props.fechaHistorico}
          right={true}
          width={400}
          className="historico-bg"
        >
          {this.props.historicoAberto && (
            <Historico idEntity={this.props.idEntityHistorico} />
          )}
        </CheeseburgerMenu>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { historicoAberto, idEntityHistorico } = state.historicomenu;
  return {
    user,
    historicoAberto,
    idEntityHistorico,
  };
}

const mapDispatch = ({
  historicomenu: {
    toggleHistorico,
    abreHistorico,
    fechaHistorico,
    receiveIdEntityHistorico,
  },
}) => ({
  toggleHistorico: () => toggleHistorico(),
  abreHistorico: () => abreHistorico({}),
  receiveIdEntityHistorico: (idEntityHistorico, tipoHistorico) =>
    receiveIdEntityHistorico({ idEntityHistorico, tipoHistorico }),
  fechaHistorico: () => fechaHistorico(),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(RelatoriosExamesListagemPage);
