//@flow
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../_constants/objects.constants';
import { urlsConstants } from '../../_constants/urls.constant';
import { translate } from '../../_helpers/messages.helper';
import { agendaService } from '../../_services/agenda.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { marcacaoService } from '../../_services/marcacao.service';
import { midiaService } from '../../_services/midia.service';
import { Calendar } from '../../components/calendar/Calendar';
import { CalendarDateView } from '../../components/calendar/CalendarDateView';
import { FormGroup } from '../../components/FormGroup';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import Convenio from '../../images/icon-convenio.svg';
import IconEspecialidades from '../../images/icones/icon-especialidade.svg';
import IconMedico from '../../images/icones/profissionais.svg';
import AgendaHorariosPage from '../agenda/AgendaHorariosPage';

class AgendaOnlineView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // let dataAtual = moment();
    // let year = dataAtual.year();
    // let month = dataAtual.month();
    console.log(props);
    this.state = {
      viewHorario: false,
      maxMarcacaoDia: 0,
      // year,
      // month,
      filtro: props.filter ? props.filter : {},
      date: new Date(),
      agendaDoDia: [],
    };
  }

  componentDidMount() {
    this.props.loading(true);
    const { filtro } = this.state;
    if (_.isEmpty(filtro.especialidade) && _.isEmpty(filtro.medicoList)) {
      this.props.history.push('/agendamento');
    }
    this.handleFilterAgenda(this.state.filtro);
    this.getCamposObrigatorios();
  }

  getCamposObrigatorios = () => {
    const { estabelecimento } = this.props;
    let idEstabelecimento = _.get(estabelecimento, 'id');
    estabelecimentoService
      .findCamposObrigatorios(idEstabelecimento)
      .then((response) => {
        console.log(response);
        let camposObrigatorios = response.data;
        this.setState({ camposObrigatorios: camposObrigatorios });
      });
  };

  handleDateClick = (dataAtualAgenda, maxMarcacaoDia, viewHorario = true) => {
    this.props.receiveDadosAgenda(maxMarcacaoDia, dataAtualAgenda, viewHorario);
  };

  arrowClick = (date, agendaDoDia, maxMarcacaoDia) => {
    this.setState({ date, agendaDoDia, maxMarcacaoDia }, () => {});
  };

  // conectChanels = () => {
  //   console.log('connectChanels');
  //   if (this.props.client.connected) {
  //     const canalAtivo = this.state.canal;
  //     if (canalAtivo) {
  //       canalAtivo.unsubscribe();
  //     }
  //     let { estabelecimento } = this.props.filtro;
  //     let id = estabelecimento.id;
  //     if (id) {
  //       let canal = this.props.client.subscribe(
  //         urlsConstants.SUBSCRIBE_PACIENTE_STATUS_CHANGE + id,
  //         this.handleDataUpdate
  //       );
  //       this.setState({ canal, maxMarcacaoDia: 0 });
  //     }
  //   }
  // };

  // handleDataUpdate = (messageOutput: any) => {
  //   console.log(messageOutput);
  //   let marcacao = JSON.parse(messageOutput.body);
  //   console.log(marcacao);
  //   let agendas = updateMarcacao(this.props.agendas, marcacao);
  //   this.props.receiveAgenda(agendas);
  //   let paciente = this.props.user;
  //   if (paciente.id === _.get(marcacao, 'listMarcacaoHora[0].paciente.id')) {
  //     if (
  //       _.get(marcacao, 'listMarcacaoHora[0].status') ==
  //       objectsConstants.MARCADO
  //     ) {
  //       this.props.history.push('/agendamento');
  //       this.props.history.push({
  //         pathname: '/agendamento/sucesso',
  //         state: { agendamento: marcacao },
  //       });
  //     }
  //   }
  // };
  marcacaoSucesso = (marcacao) => {
    // this.props.history.push('/agendamento');
    this.props.history.push({
      pathname: '/agendamento/sucesso',
      state: { agendamento: marcacao },
    });
  };

  handleStatusChanged = (
    idMarcacao: number,
    status: string,
    tipoAtendimento: string,
    convenio: any,
    tipoConsulta: any
  ) => {
    if (this.props.client.connected) {
      this.props.client.send(
        urlsConstants.SEND_PACIENTE_STATUS,
        JSON.stringify({
          id: idMarcacao,
          status,
          tipoAtendimento,
          convenio,
          tipoConsulta,
        })
      );
    }
  };

  handleMarcacaoCreated = (entidade: any) => {
    this.props.loading(true);
    marcacaoService.doSave(entidade).then(
      (response) => {
        let date = new Date();
        this.props
          .receiveMonthAndYear(date.getMonth(), date.getFullYear())
          .then((_response) => {
            this.props.receiveViewHorario(false);
            this.marcacaoSucesso(response.data.data[0]);
            this.props.loading(false);
          });
      },
      (erros) => {
        console.log(erros);
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              this.props.error({
                message: translate(erroItem.message.code),
              });
            }
          }
          this.props.loading(false);
        } catch (error) {
          this.props.loading(false);
          console.log(error);
        }
      }
    );
    // }
  };

  setAgendaDoDia = (agendaDoDia) => {
    this.setState({ agendaDoDia });
  };

  handleFilterAgenda = (filtro) => {
    const { year, month, tipoAgenda, estabelecimento } = this.props;
    this.props.fechaFiltro();
    let now = new Date();
    let dataStart = new Date(year, month, 1, 0, 0, 0);
    let dataEnd = new Date(year, month + 1, 0, 23, 59);
    filtro.tipoAgenda = tipoAgenda;
    filtro.estabelecimento = estabelecimento;
    filtro.dataStart = moment(dataStart).format('YYYY-MM-DDTHH:mm:ss');
    filtro.dataEnd = moment(dataEnd).format('YYYY-MM-DDTHH:mm:ss');
    filtro.medico = _.get(filtro, 'estabelecimentoMedico.medico');
    filtro.medicoList = _.get(filtro, 'medicoList');
    //this.conectChanels();
    this.setState({ filtro }, () => {
      this.props.changeFilter(filtro);
      agendaService.findHorariosConsultaPublic(filtro).then(
        (json) => {
          this.props.loading(false);
          this.props.receiveAgenda(json.data, month);
        },
        (error) => {
          if (tipoAgenda === objectsConstants.TIPO_AGENDA[0].name) {
            this.props.error({
              message: 'Não foi possível atualizar a agenda de CONSULTAS.',
            });
          } else if (tipoAgenda === objectsConstants.TIPO_AGENDA[1].name) {
            this.props.error({
              message: 'Não foi possível atualizar a agenda de EXAMES.',
            });
            this.props.receiveAgenda({});
          }

          this.props.loading(false);
        }
      );
    });
  };

  handleMonthChange = (year: any, month: any) => {
    this.props.loading(true);
    this.props.receiveMonthAndYear(month, year).then((response) => {
      this.handleFilterAgenda(this.state.filtro);
    });
  };

  gettexthelper = () => {
    if (!this.state.viewHorario) {
      return (
        <h2>
          Selecione no calendário <b>um dia disponível</b>
        </h2>
      );
    } else {
      return (
        <h2>
          <b>Selecione um horário disponível</b> e clique em agendar
        </h2>
      );
    }
  };

  // handleOnGoBack = () => {
  //   this.setState({ viewHorario: false });
  // };

  handleOnGoBack = () => {
    let viewHorario = false;
    this.props.receiveViewHorario(viewHorario);
  };

  render() {
    const { ...otherProps } = this.props;
    return (
      <div className="container-fluid row d-flex p-0 m-0">
        <div className="col-sm-12 col-md-3 agenda-online-filter">
          <div className="text-center">
            {_.get(this.props.estabelecimento, 'foto.id') && (
              <img
                className="logoOnline"
                src={midiaService.url(
                  _.get(this.props.estabelecimento, 'foto.id')
                )}
              ></img>
            )}
            {!_.get(this.props.estabelecimento, 'foto.id') && (
              <div className="logo-agenda"></div>
            )}
          </div>
          <div className="text-center">{this.gettexthelper()}</div>
          <hr></hr>
          {this.props.viewHorario && (
            <div className="d-flex flex-column order-4 order-lg-3 align-items-center justify-content-center justify-content-lg-end pl-lg-2 pb-3">
              <CalendarDateView
                data={this.props.dataAtualAgenda}
                agendas={this.props.agendas}
                onMonthChange={this.handleMonthChange}
                arrowNavigation={true}
                arrowClick={this.handleDateClick}
                className="justify-content-between"
                isAgendamentoOnline={true}
              ></CalendarDateView>
            </div>
          )}
          <FormGroup className="col-12 py-2 select-filter">
            {this.state.filtro.especialidade && (
              <div className="filters d-flex flex-column align-items-center justify-content-center col-12">
                <span className="mb-2 col-12">
                  <img src={IconEspecialidades} width="30" className="mr-1" />{' '}
                  Especialidade
                </span>
                <p className="tag col-12">
                  {this.state.filtro.especialidade.nome}
                </p>
              </div>
            )}
          </FormGroup>
          {this.state.filtro.medicoList &&
            this.state.filtro.medicoList.length > 0 && (
              <div className="filters d-flex flex-column align-items-center justify-content-center col-12">
                <span className="mb-2 col-12">
                  {' '}
                  <img src={IconMedico} width="15" className="mr-1" />{' '}
                  Profissional de saúde
                </span>
                <p className="tag col-12">
                  {this.state.filtro.medicoList[0].apelido}
                </p>
              </div>
            )}
          {this.state.filtro.convenio && (
            <div className="filters d-flex flex-column align-items-center justify-content-center col-12">
              <span className="mb-2 col-12">
                <img src={Convenio} width="30" className="mr-1" /> Convenio
              </span>
              <p className="tag col-12">{this.state.filtro.convenio.nome}</p>
            </div>
          )}
        </div>

        <div className="agenda-online-content d-flex flex-column col-sm-12 col-md-9 m-0 p-0 col-12">
          {!this.props.viewHorario ? (
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={objectsConstants.TITULO_AGENDA_ONLINE}
                subTitle=""
                canBack={true}
                backUrl="/agendamento"
              />
              <Calendar
                agendas={this.props.agendas}
                onClick={this.handleDateClick}
                onMonthChange={this.handleMonthChange}
                year={this.props.year}
                month={this.props.month}
                hideTotalMarcacao={true}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AgendaHorariosPage
                isAgendamentoOnline={true}
                pacienteOnline={this.props.user}
                date={this.props.dataAtualAgenda}
                maxMarcacaoDia={this.state.maxMarcacaoDia}
                agendas={this.props.agendas}
                onStatusChanged={this.handleStatusChanged}
                onGoBack={this.handleOnGoBack}
                onMarcacaoCreated={this.handleMarcacaoCreated}
                backUrl={'/agendamento/view'}
                filtro={this.state.filtro}
                year={this.props.year}
                month={this.props.month}
                tipoAgenda={this.props.tipoAgenda}
                itemHorario={_.get(this.props, 'location.state.itemHorario')}
                reagendamento={false}
                camposObrigatorios={this.state.camposObrigatorios}
                {...otherProps}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    dados,
    medico,
    showDivMedico,
    filter,
    agendas,
    maxMarcacaoDia,
    dataAtualAgenda,
    viewHorario,
    filtroRemarcacao,
    canal,
    month,
    year,
    tipoAgenda,
  } = state.agenda;
  const { filtroAberto } = state.filtromenu;
  const { indisponibilidadeAberta } = state.indisponibilidademenu;
  const { webSocketConnected } = state.webSocket;
  const { user } = state.authentication;
  return {
    dados,
    medico,
    showDivMedico,
    filtroAberto,
    indisponibilidadeAberta,
    filter,
    agendas,
    maxMarcacaoDia,
    dataAtualAgenda,
    viewHorario,
    filtroRemarcacao,
    webSocketConnected,
    canal,
    month,
    year,
    tipoAgenda,
    user,
  };
}

const mapDispatch = ({
  agenda: {
    receiveData,
    changeFilter,
    receiveAgenda,
    receiveDadosAgenda,
    receiveViewHorario,
    setFiltroRemarcacao,
    setCanalSocket,
    receiveMonthAndYear,
    receiveTipoAgenda,
    updateMarcacao,
  },
  load: { loading },
  filtromenu: { toggleFiltro, abreFiltro, fechaFiltro },
  indisponibilidademenu: {
    toggleIndisponibilidade,
    abreIndisponibilidade,
    fechaIndisponibilidade,
  },
  agendaOnline: { setEstabelecimento, setFiltroAgenda },
  alert: { success, error, warning },
}) => ({
  setCanalSocket: (canal) => setCanalSocket({ canal }),
  receiveAgenda: (agendas, month, source) =>
    receiveAgenda({ agendas, month, source }),
  updateMarcacao: (marcacao) => updateMarcacao({ marcacao }),
  setFiltroRemarcacao: (filtroRemarcacao) =>
    setFiltroRemarcacao({ filtroRemarcacao }),
  receiveDadosAgenda: (maxMarcacaoDia, dataAtualAgenda, viewHorario) =>
    receiveDadosAgenda({
      maxMarcacaoDia,
      dataAtualAgenda,
      viewHorario,
    }),
  receiveMonthAndYear: (month, year) => receiveMonthAndYear({ month, year }),
  receiveTipoAgenda: (tipoAgenda) => receiveTipoAgenda({ tipoAgenda }),
  receiveViewHorario: (viewHorario) => receiveViewHorario({ viewHorario }),
  changeFilter: (filter) => changeFilter({ filter: filter }),
  receiveData: (data, medico) => receiveData({ data, medico }),
  loading: (load: boolean) => loading({ load }),
  toggleFiltro: () => toggleFiltro(),
  abreFiltro: () => abreFiltro({}),
  fechaFiltro: () => fechaFiltro(),
  toggleIndisponibilidade: () => toggleIndisponibilidade(),
  abreIndisponibilidade: () => abreIndisponibilidade({}),
  fechaIndisponibilidade: () => fechaIndisponibilidade(),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
  warning: (msg) => warning(msg),
  setEstabelecimento: (estabelecimento: any) =>
    setEstabelecimento({ estabelecimento }),
  setFiltroAgenda: (filtro: any) => setFiltroAgenda({ filtro }),
});
const connectedAgendaOnlineView = connect(
  mapStateToProps,
  mapDispatch
)(AgendaOnlineView);
export { connectedAgendaOnlineView as AgendaOnlineView };
