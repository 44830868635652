//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../../components/templates/ListagemPage';
import TopoListagem from '../../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../../_constants/objects.constants';
import { tableHelper } from '../../../../_helpers/tableHelper';
import { IntegracaoSascConnectService } from '../../../../_services/integracaoSascConnect.service';
import { IntegracaoSascAtendimentosService } from '../../../../_services/integracaoSascAtendimentos.service';
import IntegracaoSascAtendimentosListFilter from './IntegracaoSascAtendimentosListFilter';

const columns = [
  {
    path: 'id',
    title: 'Identificador',
    isKey: true,
    dataSort: true,
  },
  {
    path: 'metadados',
    title: 'Metadados',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'hql',
    title: 'HQL',
    isKey: false,
    dataSort: true,
  },
];

class IntegracaoSascAtendimentosListagemPage extends React.PureComponent<
  {},
  State
> {
  constructor(props) {
    super(props);

    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  render() {
    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };
    if (this.props.match.params.id) {
      filter = { integracaoLote: { id: this.props.match.params.id } };
    }

    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={
            objectsConstants.TITULO_PAGINA_INTEGRACAO_SASC_ATENDIMENTOS
          }
          subTitle=" "
          canBack={true}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_INTEGRACAO}
        ></TopoListagem>
        <ListagemPage
          title={objectsConstants.TITULO_PAGINA_INTEGRACAO_SASC_ATENDIMENTOS}
          showNewButton={false}
          service={IntegracaoSascAtendimentosService}
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          fields={this.state.fields}
          controller="api/integracaoItemLote"
          labels={this.state.labels}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(IntegracaoSascAtendimentosListagemPage);
