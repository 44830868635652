//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const signerService = {
  download
};

function download(token, secretKey) {
  return defaultService.doPost(
    `${urlsConstants.SIGNER_DOWNLOAD}${token}/${secretKey}`
  );
}