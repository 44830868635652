//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import packageJson from '../../../package.json';
import Footer from '../../components/Footer/Footer';
import { userService } from '../../_services/user.service';
import CadastroWizard from '../cadastroWizard/CadastroWizard';
import { EsqueciSenhaForm } from './EsqueciSenhaForm';
import { LoginForm } from './LoginForm';
import { NovaSenhaForm } from './NovaSenhaForm';
import { SenhaEnviadaForm } from './SenhaEnviadaForm';

type Props = {
  email?: string,
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  location: any,
  alert: any,
};

type State = {
  email?: string,
  senha?: string,
  esqueciSenha: boolean,
  senhaEnviada: boolean,
  idRecover: any,
};

class LoginPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showCadastro: false,
      esqueciSenha: false,
      senhaEnviada: false,
      idRecover: this.props.match.params.id,
    };
  }

  componentDidMount() {}

  esqueciSenha = () => {
    this.setState({ esqueciSenha: true });
  };

  recuperarSenha = (values) => {
    console.log('recuperarSenha');
    this.props.loading(true);
    userService.esqueciSenha(values).then(
      (response) => {
        console.log(response);
        if (response.status == 200) {
          this.props.loading(false);
          this.setState({ emailMask: response.data.email, senhaEnviada: true });
        } else {
          console.log(response);
          this.props.loading(false);
          this.props.error('O CPF digitado não foi encontrado');
        }
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error(
          'Não foi possível recuperar a senha, entre em contato com o administrador'
        );
      }
    );
  };

  salvarNovaSenha = (senha) => {
    this.props.loading(true);
    let values = {
      confirmPassword: senha,
      key: this.state.idRecover,
      password: senha,
    };

    userService.updateRecoverPassword(values).then(
      (response) => {
        console.log(response);
        if (response.status == 200) {
          this.props.loading(false);
          this.props.success({
            message:
              'Senha cadastrada com sucesso! Informe seu CPF e senha para acessar o sistema.',
          });
          this.setState({ idRecover: null });
        } else {
          console.log(response);
          this.props.loading(false);
          this.props.error('Não foi possivel alterar sua senha');
        }
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error('Não foi possivel alterar sua senha');
      }
    );
  };

  showCadastro = () => {
    this.setState({ showCadastro: !this.state.showCadastro });
  };

  cancelarLogin = () => {
    console.log('cancelar login');
    this.setState({
      esqueciSenha: false,
      senhaEnviada: false,
      showCadastro: false,
    });
  };

  render() {
    const { loggedIn, alert } = this.props;
    const { showCadastro, esqueciSenha, senhaEnviada, idRecover, emailMask } =
      this.state;
    const { from } =
      userService.isSudo() || userService.isCode4()
        ? userService.isCode4()
          ? { from: { pathname: '/empresa' } }
          : { from: { pathname: '/estabelecimento' } }
        : { from: { pathname: '/' } };
    if (loggedIn) {
      return <Redirect to={from} />;
    }
    return (
      <React.Fragment>
        <div className="container-fluid d-flex h100 back-elements">
          <div className="background-lines">
            <div className="bg-riscos"></div>
          </div>
          {showCadastro && !esqueciSenha && !senhaEnviada && (
            <div className="card login cadastro col-12 col-md-8 d-flex flex-column align-items-stretch">
              <CadastroWizard
                voltar={this.cancelarLogin}
                exibeErro={this.props.error}
                exibeLoading={this.props.loading}
                exibeAlertErro={(msg) => {
                  this.props.error(msg);
                }}
              ></CadastroWizard>
            </div>
          )}
          {!showCadastro && (
            <div className="card login">
              {esqueciSenha && (
                <div className="float-left fade-in ">
                  <button
                    className=" header-icon back-icon"
                    type="button"
                    onClick={this.cancelarLogin}
                  >
                    <p className="txt-header"> Voltar</p>
                  </button>
                </div>
              )}
              <div className="logo-sasc-cm"></div>
              {!esqueciSenha && !senhaEnviada && !idRecover && (
                <React.Fragment>
                  <LoginForm
                    exibeAlertErro={(msg) => {
                      this.props.error(msg);
                    }}
                    esqueciSenha={this.esqueciSenha}
                  ></LoginForm>
                  <hr></hr>
                  <div className="text-center">
                    <p>
                      Se ainda não possuir cadastro,<br></br>clique abaixo e
                      cadastre sua conta
                    </p>
                    <button
                      className="btn btn-primary light"
                      onClick={this.showCadastro}
                    >
                      Cadastrar
                    </button>
                  </div>
                </React.Fragment>
              )}

              {esqueciSenha && !senhaEnviada && (
                <EsqueciSenhaForm
                  recuperarSenha={this.recuperarSenha}
                  agendamentoOnline={false}
                ></EsqueciSenhaForm>
              )}
              {senhaEnviada && (
                <SenhaEnviadaForm emailMask={emailMask}></SenhaEnviadaForm>
              )}
              {idRecover && (
                <NovaSenhaForm
                  salvarNovaSenha={this.salvarNovaSenha}
                ></NovaSenhaForm>
              )}
            </div>
          )}

          {/* {alert.message && (
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          )} */}
        </div>
        <Footer></Footer>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
  };
}
const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load: boolean) => loading({ load }),
});

export default connect(mapStateToProps, mapDispatch)(LoginPage);

// const connectedLoginPage = connect(mapStateToProps, mapDispatch)(LoginPage);
// export { connectedLoginPage as LoginPage };
