import React, { PureComponent } from "react";
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import iconPerfil from "../../images/icon_perfil.svg";
import { Can } from "@casl/react";
import { Button } from "reactstrap";
import { userService } from "../../_services/user.service";
import InputViewEdit from "../inputs/InputViewEdit";
import SelectInput from "../inputs/SelectInput";
import { objectsConstants } from "../../_constants/objects.constants";
import ButtonAutorized from "../buttons/ButtonAutorized";


class TopoListagem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  abrePerfil = () => {
    if (!userService.isCode4()) {
      this.props.history.push('/perfil/' + this.props.user.id);
    }
  }

  mountButtonOutOfSchedule = () => {
    if (!this.props.isAgendamentoOnline && !this.props.viewHorario && !userService.isSuporte()) {
      return (
        <React.Fragment>
          <ButtonAutorized
            className="btn-blue"
            classAction=""
            label={'Atender fora da agenda'}
            role={objectsConstants.AGENDA_ATENDER}
            onClick={() => {
              this.props.toggleConsultaAvulsa();
            }}
          ></ButtonAutorized>
        </React.Fragment>
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="topo-listagem-fixo px-sm-5 py-2 my-sm-3 rounded d-flex flex-column flex-sm-row justify-content-around justify-content-lg-between align-items-center">
          <div className={`d-none d-sm-flex  bloco-dados-user  mr-sm-auto  flex-column flex-sm-row align-items-center ${userService.isCode4() ? '' : 'pointer'} `}
            onClick={() => {
              this.abrePerfil();
            }}
          >

            <div className="bloco-image-user m-sm-2 mr-sm-4">
              <div className="icon-foto-user d-flex align-items-center ">
                <img src={iconPerfil} alt="iconPerfil" />
              </div>
              <Avatar user={this.props.user}></Avatar>
            </div>
            <div className="info-user">
              <p className="txtCooperativa">
                {this.props.user.cooperativa && this.props.user.cooperativa.sigla}
              </p>
              <h2 className="font-weight-bold nomeProprio">{this.props.user.nome}</h2>

              {!this.props.user.setorAtual && (
                <p className="font-italic txtPerfil">
                  {userService.getPerfilAtual(this.props.user)}
                </p>
              )}

              {this.props.user.setorAtual && (
                <p className="font-italic txtPerfil ">
                  {this.props.user.setorAtual.nome}
                </p>
              )}
            </div>
          </div>

          {!this.props.hideNewButton && this.props.securityActionDo && this.props.securityActionOn && (
            <Can
              I={this.props.securityActionDo}
              a={this.props.securityActionOn}
              ability={this.props.permissions}
            >
              <div className=" px-sm-3 ">
                <button
                  type="button"
                  className={`btn ${this.props.buttonActionClassName}`}
                  onClick={this.props.buttonAction}
                >
                  <span className={this.props.securityActionIcon}></span>
                  {this.props.securityActionName}
                </button>{" "}
              </div>
            </Can>
          )}

          <div className="d-flex flex-row flex-wrap align-items-center justify-content-center action-buttons-fixed">
            {this.props.showTipoAgenda && !userService.isMedico() && <div className="px-0 mt-0">
              <InputViewEdit
                component={SelectInput}
                options={objectsConstants.TIPO_AGENDA}
                name={"tipoAgenda"}
                label=""
                placeholder="Selecione o tipo de agenda"
                onChange={this.props.handleTipoAgenda}
                noSize={true}
                valueKey={'name'}
                labelKey={'description'}
                returnFullObject={false}
                value={this.props.tipoAgenda}
                id="tipoAgenda"

              />
            </div>}
            {this.props.showAtenderForaAgenda && (
              this.mountButtonOutOfSchedule()
            )}
            {this.props.viewHorario && (
              <div className="d-none d-sm-block px-0 mt-0">
                <Button className="btn btn-light" onClick={() => {
                  this.props.toggleModalImpressao();
                }}>
                  <span className="icon-btn-imprimir"></span>
                  Imprimir agenda            </Button>
              </div>
            )}
            {this.props.showfilter && !userService.isMedico() && <div className="px-0 mt-0">
              <Button className="btn btn-light" onClick={() => {
                this.props.abreFiltro();
              }}>
                <span className="icon-btn-filtro"></span>
                Filtrar
              </Button>
            </div>}

            {this.props.showIndisponibilidade &&
              <div className="px-0 mt-0">
                <Button className="btn btn-light" onClick={() => {
                  this.props.abreIndisponibilidade();
                }}>
                  <span className="icon-btn-indisponibilidade"></span>
                  Indisponibilidade
                </Button>
              </div>}
          </div>
        </div>
        <hr className="topo text-center w-50"></hr>
        <div className="background-lines">
          <div className="bg-riscos"></div>
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatch = ({ filtromenu: { toggleFiltro, abreFiltro, fechaFiltro },
  indisponibilidademenu: {
    toggleIndisponibilidade,
    abreIndisponibilidade,
    fechaIndisponibilidade,
  }, }) => ({
    toggleFiltro: () => toggleFiltro(),
    abreFiltro: () => abreFiltro({}),
    fechaFiltro: () => fechaFiltro(),
    toggleIndisponibilidade: () => toggleIndisponibilidade(),
    abreIndisponibilidade: () => abreIndisponibilidade({}),
    fechaIndisponibilidade: () => fechaIndisponibilidade(),
  });
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  const { filtroAberto } = state.filtromenu;
  const { indisponibilidadeAberta } = state.indisponibilidademenu;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(TopoListagem));
