//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const posologiaService = {
  find,
  savePosologiaCustom
};

function find(filtro) {
  return defaultService.doPost(
    `${urlsConstants.POSOLOGIA}find`,
    filtro
  );
}
function savePosologiaCustom(value) {
  return defaultService.doPost(
      `${urlsConstants.POSOLOGIA}saveCustom`,{descricao:value}
  );
}