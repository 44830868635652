import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, FormGroup } from 'react-bootstrap';
import SelectInput from '../inputs/SelectInput';
import _ from 'lodash';
import InputViewEdit from '../inputs/InputViewEdit';
import DatePickerInput from '../inputs/DatePickerInput';
import { dateHelper } from '../../_helpers/date.helper';
import FormSelectInput from '../inputs/FormSelectInput';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { medicoService } from '../../_services/medico.service';
import { pacienteService } from '../../_services/paciente.service';
import { OptionUsuario } from '../inputs/OptionUsuario';
import { empresaService } from '../../_services/empresa.service';
import { cooperativaService } from '../../_services/cooperativa.service';
import { especialidadesService } from '../../_services/especialidades.service';
import { convenioService } from '../../_services/convenio.service';
import { exameService } from '../../_services/exame.service';
import { procedimentoService } from '../../_services/procedimento.service';
import { userService } from '../../_services/user.service';
import moment from 'moment';

/**
 * Props
 *
 */
type Props = {
  submitFilter: (filter: FinalFilterDashboard) => void,
};

/**
 * State
 *
 */
type State = {
  filter: FilterDashboard,
  openSearch: boolean,
  disableEmpresa: boolean,
  disableEstabelecimento: boolean,
  disableMedico: boolean,
  disableEspecialidades: boolean,
  periodicidadeVisualizacao: ComboData[],
  tiposAtendimento: ComboData[],
  tiposConsulta: ComboData[],
  tiposMarcacao: ComboData[],
  tiposVizualicao: ComboData[],
  errorsFields: Map<string, string>,
  somentePacientesEstabelecimento: Boolean,
};

/**
 * Filter Dashboard
 *
 */
interface FilterDashboard {
  groupDate?: string;
  tipoVizualicao?: string;
  dataInicio?: Date;
  dataFim?: Date;
  tiposAtendimento?: string[];
  tiposConsulta?: string[];
  tiposMarcacao?: string[];
  idsEstabelecimento?: any[];
  idsMedico?: any[];
  idsPaciente?: any[];
  idsEmpresa?: any[];
  idsCooperativa?: any[];
  idsEspecialidade?: any[];
  idsConvenio?: any[];
  idsPlanoConvenio?: any[];
  idsExame?: any[];
  idsProcedimento?: any[];
}

/**
 * Final Filter Dashboard
 *
 */
interface FinalFilterDashboard {
  groupDate?: string;
  tipoVizualicao?: string;
  dataInicio?: Date;
  dataFim?: Date;
  tiposAtendimento?: string[];
  tiposConsulta?: string[];
  tiposMarcacao?: string[];
  idsEstabelecimento?: number[];
  idsMedico?: number[];
  idsPaciente?: number[];
  idsEmpresa?: number[];
  idsCooperativa?: number[];
  idsEspecialidade?: number[];
  idsConvenio?: number[];
  idsPlanoConvenio?: number[];
  idsExame?: number[];
  idsProcedimento?: number[];
}

/**
 * ComboData
 *
 */
interface ComboData {
  name: string;
  value: string;
}

/**
 *
 * ErrorsFields
 */
interface ErrorsFields {
  fieldName: string;
  errorMessage: string;
}

/**
 * Chart Dashboard Component
 *
 */
class SearchDashboardComponent extends React.Component<Props, State> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {
      filter: {},
      openSearch: false,
      disableEmpresa: false,
      disableEstabelecimento: false,
      disableMedico: false,
      disableEspecialidades: false,
      errorsFields: new Map(),
      periodicidadeVisualizacao: this.getPeriodicidadeVisualizacao(),
      tiposAtendimento: this.getTiposAtendimento(),
      tiposConsulta: this.getTiposConsulta(),
      tiposMarcacao: this.getTiposMarcacao(),
      tiposVizualicao: this.getTiposVizualicao(),
    };
  }

  /**
   *
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getPeriodicidadeVisualizacao = () => {
    return [
      {
        name: 'Automático',
        value: 'AUTOMATICO',
      },
      {
        name: 'Diário',
        value: 'DAILY',
      },
      {
        name: 'Mensal',
        value: 'MONTH',
      },
      {
        name: 'Anual',
        value: 'YEAR',
      },
    ];
  };

  /**
   * Tipos de atendimento
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTiposAtendimento = () => {
    return [
      {
        name: 'Convenio',
        value: 'CONVENIO',
      },
      {
        name: 'Particular',
        value: 'PARTICULAR',
      },
      {
        name: 'Cortesia',
        value: 'CORTESIA',
      },
    ];
  };

  /**
   * Tipos de consulta
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTiposConsulta = () => {
    return [
      {
        name: 'Comum',
        value: 'COMUM',
      },
      {
        name: 'Retorno',
        value: 'RETORNO',
      },
      {
        name: 'Primeira',
        value: 'PRIMEIRA',
      },
    ];
  };

  /**
   * Tipos Marcação
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTiposMarcacao = () => {
    return [
      {
        name: 'Marcação',
        value: 'MARCACAO',
      },
      {
        name: 'Encaixe',
        value: 'ENCAIXE',
      },
    ];
  };

  /**
   * Tipos visualização
   *
   * @return {[{name: string, value: string},{name: string, value: string}]}
   */
  getTiposVizualicao = () => {
    return [
      {
        name: 'Analítica',
        value: 'ANALITICA',
      },
      {
        name: 'Sintética',
        value: 'SINTETICA',
      },
    ];
  };

  /**
   * Component did mount
   *
   * @return {Promise<void>}
   */
  async componentDidMount() {
    let estabelecimentoUsuario: any = null;
    let userMedico: any = null;
    if (userService.isMedico()) {
      userMedico = (await medicoService.doGet(userService.getCurrentUser().id))
        .data;
    }
    if (userService.getExtension()) {
      estabelecimentoUsuario = (
        await estabelecimentoService.doGet(userService.getExtension())
      ).data.data;
    }
    this.setState({
      filter: this.defaultInitiValueFilterState(
        estabelecimentoUsuario,
        userMedico
      ),
      disableEstabelecimento: !!estabelecimentoUsuario,
      disableEmpresa: !!(
        estabelecimentoUsuario && estabelecimentoUsuario.empresa
      ),
      disableMedico: !!userMedico,
      disableEspecialidades: !!userMedico,
    });
    this.submitFilter();
  }

  /**
   * Default value filter init state
   *
   * @return FilterDashboard
   */
  defaultInitiValueFilterState(
    estabelecimentoUsuario: any,
    userMedico: any
  ): FilterDashboard {
    return {
      groupDate: 'AUTOMATICO',
      tipoVizualicao: 'SINTETICA',
      dataInicio: this.subtractDays(7, new Date()),
      dataFim: new Date(),
      idsEmpresa:
        estabelecimentoUsuario && estabelecimentoUsuario.empresa
          ? [estabelecimentoUsuario.empresa]
          : [],
      idsEstabelecimento: estabelecimentoUsuario
        ? [estabelecimentoUsuario]
        : [],
      idsMedico: userMedico ? [{ medico: userMedico }] : [],
      idsEspecialidade: userMedico ? userMedico.especialidades : [],
    };
  }

  /**
   * Responsible change data
   *
   * @param name
   * @param value
   */
  handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter: filter }, () => {
      if ('dataInicio' === name || 'dataFim' === name) {
        this.validateIntervalDate();
        // this.checkAllowedVisualizationType();
      }
    });
  };

  /**
   * Validate Dates
   *
   */
  validateIntervalDate = () => {
    if (
      moment(this.state.filter.dataInicio).isAfter(this.state.filter.dataFim)
    ) {
      this.setState({
        errorsFields: this.state.errorsFields.set(
          'dataInicio',
          'A data inicial não pode ser maior que e a data final'
        ),
      });
    } else if (
      moment(this.state.filter.dataFim).isBefore(this.state.filter.dataInicio)
    ) {
      this.setState({
        errorsFields: this.state.errorsFields.set(
          'dataFim',
          'A data final não pode ser menor que e a data inicial'
        ),
      });
    } else {
      let previosErrorsFields: Map<string, string> = this.state.errorsFields;
      previosErrorsFields.delete('dataInicio');
      previosErrorsFields.delete('dataFim');
      this.setState({ errorsFields: previosErrorsFields });
    }
  };

  /**
   * Check allowed visualization
   *
  //  */
  // checkAllowedVisualizationType = () => {
  //   let diffDays = this.getDayDiff(
  //     this.state.filter.dataInicio,
  //     this.state.filter.dataFim
  //   );
  //   if (diffDays > 60) {
  //     const filter = _.cloneDeep(this.state.filter);
  //     if (diffDays > 1100) {
  //       if (
  //         this.state.filter.groupDate === 'MONTH' ||
  //         this.state.filter.groupDate === 'DAILY'
  //       ) {
  //         _.set(filter, 'groupDate', 'AUTOMATICO');
  //       }
  //       this.setState({
  //         filter: filter,
  //         periodicidadeVisualizacao: [
  //           { name: 'Automático', value: 'AUTOMATICO' },
  //           { name: 'Anual', value: 'YEAR' },
  //         ],
  //       });
  //     } else {
  //       if (this.state.filter.groupDate === 'DAILY') {
  //         _.set(filter, 'groupDate', 'AUTOMATICO');
  //       }
  //       this.setState({
  //         filter: filter,
  //         periodicidadeVisualizacao: [
  //           { name: 'Automático', value: 'AUTOMATICO' },
  //           { name: 'Mensal', value: 'MONTH' },
  //           { name: 'Anual', value: 'YEAR' },
  //         ],
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       periodicidadeVisualizacao: this.getPeriodicidadeVisualizacao(),
  //     });
  //   }
  // };

  /**
   * Responsible toggle search
   *
   */
  toggleSearch = () => {
    this.setState({
      openSearch: !this.state.openSearch,
    });
  };

  /**
   * Subtract date
   *
   * @param numOfDays - numOfDays
   * @param date - date
   * @return Date
   */
  subtractDays(numOfDays, date = new Date()): Date {
    date.setDate(date.getDate() - numOfDays);
    return date;
  }

  /**
   * Get diff between dates
   *
   * @param startDate
   * @param endDate
   * @return {number}
   */
  getDayDiff(startDate: Date, endDate: Date): number {
    return Math.round(
      Math.abs(Number(endDate) - Number(startDate)) / (24 * 60 * 60 * 1000)
    );
  }

  /**
   * Submit filter search
   *
   */
  submitFilter = () => {
    if (this.state.errorsFields.size <= 0) {
      this.setState({
        openSearch: false,
      });
  
      // // Se o checkbox estiver marcado, aplique o filtro
      // if (this.state.somentePacientesEstabelecimento) {
      //   this.applyFilterAndSubmit();
      // } else {
        // Se não estiver marcado, apenas chame a função submitFilter diretamente
        this.props.submitFilter(this.filterDashboardToFinalFilterDashboard());
      // }
    } else {
      this.props.error({
        message:
          'Por favor corrija os erros do formulário de pesquisa antes de continuar',
      });
    }
  };
  
  /**
   * Responsible transform filter dash to final filter dash
   *
   */
  filterDashboardToFinalFilterDashboard(): FilterDashboard {
    if (this.state.filter) {
      return {
        groupDate: this.getFinalGroup(
          this.state.filter.groupDate,
          this.state.filter.dataInicio,
          this.state.filter.dataFim
        ),
        tipoVizualicao: this.state.filter.tipoVizualicao,
        dataInicio: this.state.filter.dataInicio,
        dataFim: this.state.filter.dataFim,
        tiposAtendimento: _.map(
          this.state.filter.tiposAtendimento,
          (o) => o.value
        ),
        tiposConsulta: _.map(this.state.filter.tiposConsulta, (o) => o.value),
        tiposMarcacao: _.map(this.state.filter.tiposMarcacao, (o) => o.value),
        idsEstabelecimento: _.map(
          this.state.filter.idsEstabelecimento,
          (o) => o.id
        ),
        idsMedico: _.map(this.state.filter.idsMedico, (o) => o.medico.id),
        idsPaciente: this.state.filter.idsPaciente
          ? [this.state.filter.idsPaciente.id]
          : [],
        idsEmpresa: _.map(this.state.filter.idsEmpresa, (o) => o.id),
        idsCooperativa: _.map(this.state.filter.idsCooperativa, (o) => o.id),
        idsEspecialidade: _.map(
          this.state.filter.idsEspecialidade,
          (o) => o.id
        ),
        idsConvenio: _.map(this.state.filter.idsConvenio, (o) => o.id),
        idsPlanoConvenio: _.map(
          this.state.filter.idsPlanoConvenio,
          (o) => o.id
        ),
        idsExame: _.map(this.state.filter.idsExame, (o) => o.id),
        idsProcedimento: this.state.filter.idsProcedimento
          ? [this.state.filter.idsProcedimento.id]
          : [],
      };
    } else {
      return {};
    }
  }

  /**
   * Responsible get final group date
   *
   * @param groupDate
   * @param dataInicio
   * @param dataFim
   * @return {string}
   */
  getFinalGroup(groupDate: string, dataInicio: Date, dataFim: Date): string {
    if ('AUTOMATICO' === groupDate) {
      let diffDaysFilters = this.getDayDiff(dataInicio, dataFim);
      if (diffDaysFilters) {
        if (diffDaysFilters <= 31) {
          return 'DAILY';
        } else if (diffDaysFilters > 31 && diffDaysFilters <= 365) {
          return 'MONTH';
        } else {
          return 'YEAR';
        }
      } else {
        return 'DAILY';
      }
    } else if (groupDate) {
      return groupDate;
    } else {
      return 'DAILY';
    }
  }

  /**
   * Responsible clear search
   *
   */
  clearSearch = () => {
    this.setState({
      filter: this.defaultInitiValueFilterState(),
    });
  };

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <React.Fragment>
        <div className="topo-listagem d-flex flex-column ">
          <div className="boxButton d-flex justify-content-center">
            {!this.state.openSearch && (
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-secondary open search"
                  onClick={this.toggleSearch}
                >
                  <span className="icone-lupa"></span>
                  Pesquisar
                </button>
              </div>
            )}
            {this.state.openSearch && (
              <button className="btn btn-blue open" onClick={this.toggleSearch}>
                <span className="icon-btn-fechar"></span>
                Fechar pesquisa
              </button>
            )}
          </div>
        </div>
        <div className="conteudo-listagem mt-3">
          <Collapse in={this.state.openSearch}>
            <div className="search-box form-inside">
              <div className="form-row search-form-row section-form justify-content-center">
                {/* <FormGroup className="col-3">
                  <SelectInput
                    id="groupDate"
                    name="groupDate"
                    label="Periodicidade da Visualização"
                    options={this.state.periodicidadeVisualizacao}
                    onChange={this.handleChangeData}
                    value={this.state.filter.groupDate}
                    labelKey="name"
                    valueKey="value"
                    multi={false}
                    returnFullObject={false}
                    creatable={false}
                    required={false}
                    placeholder="Periodicidade da Visualização"
                  />
                </FormGroup> */}
                {/* <FormGroup className="col-4">
                  <SelectInput
                    id="tipoVizualicao"
                    name="tipoVizualicao"
                    label="Tipo Visualizacao"
                    options={this.state.tiposVizualicao}
                    onChange={this.handleChangeData}
                    value={this.state.filter.tipoVizualicao}
                    labelKey="name"
                    valueKey="value"
                    multi={false}
                    returnFullObject={false}
                    creatable={false}
                    required={false}
                    placeholder="Tipo Visualização"
                  />
                </FormGroup> */}
                <FormGroup className="col-2">
                  <InputViewEdit
                    component={DatePickerInput}
                    erroMensagem={
                      this.state.errorsFields.has('dataInicio')
                        ? this.state.errorsFields.get('dataInicio')
                        : null
                    }
                    label="Data Inicial"
                    name="dataInicio"
                    id="dataInicio"
                    value={this.state.filter.dataInicio}
                    onChange={this.handleChangeData}
                    placeholder={'--/--/----'}
                    defaultValue={
                      _.get(this.state.filter, `dataInicio`)
                        ? dateHelper.format(
                            _.get(this.state.filter, `dataInicio`),
                            {
                              mode: 'DATE',
                            }
                          )
                        : '--'
                    }
                  />
                </FormGroup>
                <FormGroup className="col-2">
                  <InputViewEdit
                    component={DatePickerInput}
                    erroMensagem={
                      this.state.errorsFields.has('dataFim')
                        ? this.state.errorsFields.get('dataFim')
                        : null
                    }
                    label="Data Final"
                    name="dataFim"
                    id="dataFim"
                    value={this.state.filter.dataFim}
                    onChange={this.handleChangeData}
                    placeholder={'--/--/----'}
                    defaultValue={
                      _.get(this.state.filter, `dataFim`)
                        ? dateHelper.format(
                            _.get(this.state.filter, `dataFim`),
                            {
                              mode: 'DATE',
                            }
                          )
                        : '--'
                    }
                  />
                </FormGroup>
                {/* <div className="w-100" />
                <FormGroup className="col-4">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsEmpresa"
                    label="Empresas"
                    placeholder="Empresas"
                    name="idsEmpresa"
                    value={this.state.filter.idsEmpresa}
                    returnFullObject={true}
                    service={empresaService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                    disabled={this.state.disableEmpresa}
                  />
                </FormGroup>
                <FormGroup className="col-3">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsEstabelecimento"
                    label="Estabelecimentos"
                    placeholder="Estabelecimentos"
                    name="idsEstabelecimento"
                    value={this.state.filter.idsEstabelecimento}
                    returnFullObject={true}
                    service={estabelecimentoService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                    disabled={this.state.disableEstabelecimento}
                  />
                </FormGroup> 
                <FormGroup className="col-3">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsCooperativa"
                    label="Cooperativas"
                    placeholder="Cooperativas"
                    name="idsCooperativa"
                    value={this.state.filter.idsCooperativa}
                    returnFullObject={true}
                    service={cooperativaService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                  />
                </FormGroup>*/}
                {/* <div className="w-100" /> */}
                <FormGroup className="col-2">
                  <SelectInput
                    id="tiposAtendimento"
                    name="tiposAtendimento"
                    label="Tipos de Atendimento"
                    options={this.state.tiposAtendimento}
                    onChange={this.handleChangeData}
                    value={this.state.filter.tiposAtendimento}
                    labelKey="name"
                    valueKey="value"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    required={false}
                    placeholder="Tipos de Atendimento"
                  />
                </FormGroup>
                <FormGroup className="col-2">
                  <SelectInput
                    id="tiposConsulta"
                    name="tiposConsulta"
                    label="Tipos de Consulta"
                    options={this.state.tiposConsulta}
                    onChange={this.handleChangeData}
                    value={this.state.filter.tiposConsulta}
                    labelKey="name"
                    valueKey="value"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    required={false}
                    placeholder="Tipos de Consulta"
                  />
                </FormGroup>
                <FormGroup className="col-2">
                  <SelectInput
                    id="tiposMarcacao"
                    name="tiposMarcacao"
                    label="Tipos de Marcação"
                    options={this.state.tiposMarcacao}
                    onChange={this.handleChangeData}
                    value={this.state.filter.tiposMarcacao}
                    labelKey="name"
                    valueKey="value"
                    isMulti={true}
                    returnFullObject={true}
                    creatable={false}
                    required={false}
                    placeholder="Tipos de Marcação"
                  />
                </FormGroup>
                <div className="w-100" />
                <FormGroup className="col-5">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsMedico"
                    label="Médicos"
                    placeholder="Médicos"
                    name="idsMedico"
                    value={this.state.filter.idsMedico}
                    returnFullObject={true}
                    service={medicoService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="medico.id"
                    labelKey="medico.nome"
                    disabled={this.state.disableMedico}
                  />
                </FormGroup>
                <FormGroup className="col-5">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsEspecialidade"
                    label="Especialidades"
                    placeholder="Especialidades"
                    name="idsEspecialidade"
                    value={this.state.filter.idsEspecialidade}
                    returnFullObject={true}
                    service={especialidadesService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                    disabled={this.state.disableEspecialidades}
                  />
                </FormGroup>
                <div className="w-100" />
                <FormGroup className="col-5">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsConvenio"
                    label="Convenios"
                    placeholder="Convenios"
                    name="idsConvenio"
                    value={this.state.filter.idsConvenio}
                    returnFullObject={true}
                    service={convenioService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                  />
                </FormGroup>
                <FormGroup className="col-5">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsPlanoConvenio"
                    label="Planos Convenios"
                    placeholder="Planos Convenios"
                    name="idsPlanoConvenio"
                    value={this.state.filter.idsPlanoConvenio}
                    returnFullObject={true}
                    service={convenioService.findAllPlansByConvenios}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                    parent={this.state.filter.idsConvenio}
                    disabled={
                      !this.state.filter.idsConvenio ||
                      this.state.filter.idsConvenio.length === 0
                    }
                  />
                </FormGroup>
                <div className="w-100" />
                <FormGroup className="col-2">
                  <InputViewEdit
                    component={FormSelectInput}
                    onChange={this.handleChangeData}
                    id="idsExame"
                    label="Exames"
                    placeholder="Exames"
                    name="idsExame"
                    value={this.state.filter.idsExame}
                    returnFullObject={true}
                    service={exameService.findAll}
                    creatable={false}
                    isMulti={true}
                    valueKey="id"
                    labelKey="nome"
                    // parent={userService.getExtension()}
                  />
                </FormGroup>
                <FormGroup className="col-4">
                  <SelectInput
                    id="idsProcedimento"
                    name="idsProcedimento"
                    label="Procedimentos"
                    placeholder="Procedimentos"
                    onFetchData={procedimentoService.findAll}
                    onChange={this.handleChangeData}
                    value={this.state.filter.idsProcedimento}
                    labelKey="descricao"
                    valueKey="id"
                    multi={true}
                    returnFullObject={true}
                    creatable={false}
                    cache={true}
                  />
                </FormGroup>
                <FormGroup className="col-4">
                  <SelectInput
                    id="idsPaciente"
                    name="idsPaciente"
                    label="Pacientes"
                    placeholder="Pacientes"
                    onFetchData={pacienteService.findByNomeOuCPF}
                    onChange={this.handleChangeData}
                    value={this.state.filter.idsPaciente}
                    labelKey="nome"
                    valueKey="id"
                    multi={true}
                    returnFullObject={true}
                    creatable={false}
                    cache={true}
                    components={{ Option: OptionUsuario }}
                  />
                </FormGroup>
                <div className="py-0 py-lg-3 w-100 col-12 text-center text-lg-right">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={this.clearSearch}
                  >
                    Limpar pesquisa
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary open"
                    onClick={this.submitFilter}
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

/**
 * Export default connect
 *
 */
export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(SearchDashboardComponent);
