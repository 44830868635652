//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'reactstrap';
import * as Yup from 'yup';
import { objectsConstants } from '../../_constants/objects.constants';
import { agendaService } from '../../_services/agenda.service';
import { convenioService } from '../../_services/convenio.service';
import { especialidadesService } from '../../_services/especialidades.service';
import { medicoService } from '../../_services/medico.service';
import { midiaService } from '../../_services/midia.service';
import { prontuarioService } from '../../_services/prontuario.service';
import { FormGroup } from '../../components/FormGroup';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import SelectInput from '../../components/inputs/SelectInput';
import Convenio from '../../images/icon-convenio.svg';
import Medicos from '../../images/icon-doctor.svg';
import Especialidade from '../../images/icon-especialidade.svg';
import AtendimentosList from '../paciente/atendimentos/AtendimentosList';

const filterValidate = Yup.object().shape({
  especialidade: Yup.object()
    .nullable()
    .when('requiredEspecialidade', (requiredEspecialidade, schema) => {
      if (requiredEspecialidade) {
        return Yup.string().required('Selecione uma especialidade');
      } else {
        return Yup.mixed().notRequired();
      }
    }),
});

class AgendaOnlineFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCadastro: false,
      listAtendimentos: [],
      listAgenda: [],
      listAgendaExames: [],
      listaMedico: [],
      listaEspecialidades: [],
      requiredEspecialidade: false,
      listConvenio: [],
    };
  }

  componentDidMount() {
    let paciente = this.props.user;
    if (paciente) {
      this.loadDados(paciente);
    } else {
      this.setState({
        listAgenda: [],
        listAtendimentos: [],
        listAgendaExames: [],
      });
    }
    if (this.props.estabelecimento) {
      this.loadProfissionais(this.props.filtro);
      this.loadEspecialidades(this.props.filtro);
      this.loadConvenios(this.props.filtro);
    } else {
      this.props.history.push('/agendaOnline/');
    }
  }

  loadProfissionais = (values) => {
    const { estabelecimento } = this.props;
    medicoService
      .findMedicoByEspecialidadePublic(
        _.get(values, 'especialidade.id'),
        _.get(estabelecimento, 'id'),
        values
      )
      .then((response) => {
        let medicos = _.get(response, 'data');
        this.setState({ listaMedico: medicos });
      });
  };

  loadEspecialidades = (values) => {
    const { estabelecimento } = this.props;
    especialidadesService
      .findAllPublic(_.get(estabelecimento, 'id'), values)
      .then((response) => {
        let especialidades = _.get(response, 'data');
        this.setState({ listaEspecialidades: especialidades });
      });
  };

  loadDados = (paciente) => {
    const { estabelecimento } = this.props;
    this.props.loading(true);
    moment.locale('pt-br');
    let filtro = {};
    let dataStart = new Date();
    let dataEnd = new Date().setDate(dataStart.getDate() + 30);
    filtro.dataEnd = moment(dataEnd)
      .locale('pt-br')
      .format('YYYY-MM-DDTHH:mm:ss');
    filtro.paciente = { id: _.get(paciente, 'id') };
    filtro.estabelecimento = estabelecimento;
    if (_.get(paciente, 'id')) {
      Promise.all([
        prontuarioService.doList(
          _.get(paciente, 'id'),
          _.get(estabelecimento, 'id')
        ),
        agendaService.findMarcacaoByPaciente(filtro),
        agendaService.findMarcacaoExamesByPaciente(filtro),
      ]).then(
        (response) => {
          let atendimentos = response[0].data;
          let agenda = response[1].data;
          let exames = response[2].data;

          this.setState(
            {
              listAgenda: agenda,
              listAtendimentos: atendimentos,
              listAgendaExames: exames,
            },
            () => {
              this.props.loading(false);
            }
          );
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
          this.props.error(
            'Não foi possível carregar algumas informações, tente novamente.'
          );
        },
        () => {
          this.props.loading(false);
        }
      );
    } else {
      this.props.loading(false);
    }
  };

  clearFormFilter = () => {
    this.formRef.resetForm({});
    this.loadProfissionais(0);
    this.loadEspecialidades();
    this.loadConvenios();
  };
  loadConvenios = (values) => {
    const { estabelecimento } = this.props;
    convenioService
      .findByEstabelecimentoPublic(_.get(estabelecimento, 'id'), values)
      .then((response) => {
        let listConvenio = _.get(response, 'data');
        this.setState({ listConvenio });
      });
  };
  atualizaListas = (name, value, setFieldValue, values, setFieldError) => {
    if (name === 'medicoList') {
      value = _.filter(value, (v) => {
        return v;
      });
    }
    _.set(values, name, value);
    if (name === 'especialidade') {
      this.loadProfissionais(values);
      this.loadConvenios(values);
    }
    if (name === 'medicoList') {
      this.loadEspecialidades(values);
      this.loadConvenios(values);
    }
    if (name === 'convenio') {
      this.loadEspecialidades(values);
      this.loadProfissionais(values);
    }
    setFieldValue(name, value);
  };

  render() {
    const { filtro, estabelecimento, ...otherPops } = this.props;
    const { entity, listAgenda, listAtendimentos, listAgendaExames } =
      this.state;

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TIPO_AGENDA_ONLINE}
          subTitle=""
          canBack={false}
          backUrl=""
        />

        <div className="d-flex flex-column flex-md-row justify-content-center">
          <div className="col-sm-12 col-md-3 agenda-online-filter">
            <div className="text-center">
              {_.get(estabelecimento, 'foto.id') && (
                <img
                  className="logoOnline"
                  src={midiaService.url(_.get(estabelecimento, 'foto.id'))}
                ></img>
              )}
              {!_.get(estabelecimento, 'foto.id') && (
                <div className="logo-agenda"></div>
              )}
            </div>
            <h2>
              Para agendar a sua consulta,{' '}
              <b>
                Selecione abaixo uma especialidade, profissional de saúde ou
                ambos:
              </b>
            </h2>
            <hr></hr>
            <Formik
              validationSchema={filterValidate}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={filtro}
              ref={(form) => {
                this.formRef = form;
              }}
              onSubmit={(values, { errors }) => {
                console.log(errors);
                if (
                  !_.isEmpty(values.medicoList) ||
                  !_.isEmpty(values.especialidade)
                ) {
                  this.formRef.validateForm(values).then((error) => {
                    console.log(error);
                    if (_.isEmpty(error)) {
                      let date = new Date();
                      if (_.isEmpty(values.especialidade)) {
                        values = _.omit(values, 'especialidade');
                      }
                      if (_.isEmpty(values.medicoList)) {
                        values = _.omit(values, 'medicoList');
                      }
                      if (_.isEmpty(values.convenio)) {
                        values = _.omit(values, 'convenio');
                      } else {
                        _.set(values, 'planoConvenio', {});
                        values = _.omit(values, 'convenio.listPlanoConvenio');
                      }
                      this.props
                        .receiveMonthAndYear(
                          date.getMonth(),
                          date.getFullYear()
                        )
                        .then((response) => {
                          this.props.changeFilter(values);
                          this.props.history.push('/agendamento/view');
                        });
                    } else {
                      this.props.error(
                        'Profissional com mais de uma especialidade, selecione uma especialidade'
                      );
                    }
                  });
                } else {
                  this.props.error(
                    'Selecione uma especialidade ou profissional de saúde, ou ambos.'
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldError,
                validationSchema,
                validateForm,
                setValues,
              }) => (
                <div className="filters">
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup className="col-12 py-2 select-filter">
                      <SelectInput
                        label="Convênio"
                        placeholder="Selecione um convênio"
                        name="convenio"
                        id={'convenio'}
                        icone={Convenio}
                        classIcone={'off'}
                        noSize={true}
                        required={false}
                        options={this.state.listConvenio}
                        searchable={true}
                        returnFullObject={true}
                        valueKey={'id'}
                        labelKey={'sigla'}
                        defaultSelected={false}
                        onChange={(name, value) => {
                          this.atualizaListas(
                            name,
                            value,
                            setFieldValue,
                            values,
                            setFieldError
                          );
                        }}
                        value={values.convenio}
                        clearable={true}
                      />
                    </FormGroup>

                    <FormGroup className="col-12 select-filter">
                      <SelectInput
                        label="Selecione uma especialidade"
                        name="especialidade"
                        id={'especialidade'}
                        icone={Especialidade}
                        classIcone={'on'}
                        placeholder="Selecione uma especialidade"
                        valueKey="id"
                        labelKey="nome"
                        noSize={true}
                        required={this.state.requiredEspecialidade}
                        returnFullObject={true}
                        searchable={true}
                        isMulti={false}
                        options={this.state.listaEspecialidades}
                        onChange={(name, value) => {
                          this.atualizaListas(
                            name,
                            value,
                            setFieldValue,
                            values,
                            setFieldError
                          );
                        }}
                        value={values.especialidade}
                        erroMensagem={errors.especialidade}
                        clearable={true}
                      />
                    </FormGroup>

                    <FormGroup className="col-12 py-3 select-filter">
                      <SelectInput
                        name="medicoList"
                        id={'medico'}
                        icone={Medicos}
                        classIcone={'on'}
                        label="Profissional de saúde"
                        placeholder="Selecione um profissional de saúde"
                        valueKey="id"
                        labelKey="apelido"
                        noSize={true}
                        required={false}
                        returnFullObject={true}
                        isMulti={false}
                        searchable={true}
                        options={this.state.listaMedico}
                        onChange={(name, value) => {
                          this.atualizaListas(
                            name,
                            [value],
                            setFieldValue,
                            values,
                            setFieldError
                          );
                        }}
                        value={values.medicoList}
                        clearable={true}
                      />
                    </FormGroup>

                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-link mt-3 mb-4 mx-0"
                        onClick={this.clearFormFilter}
                        type="button"
                      >
                        Limpar filtros{' '}
                      </button>
                      <Button
                        className="bt-size-fixed my-4"
                        block={true}
                        color="primary"
                        type="submit"
                      >
                        Avançar
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>

          <div
            className={`d-flex col-sm-12 col-md-9  justify-content-center flex-column agenda-online-info`}
          >
            {listAgenda.length > 0 && this.props.loggedIn && (
              <FullCustomCard
                key={'consultas'}
                title="Consultas Marcadas"
                Componente={AtendimentosList}
                viewMode={true}
                entity={entity}
                agenda={listAgenda}
                atendimentos={[]}
                getBtns={() => {}}
                online={true}
                {...otherPops}
              ></FullCustomCard>
            )}

            {listAtendimentos.length > 0 && this.props.loggedIn && (
              <FullCustomCard
                key={'historico'}
                title="Histórico de atendimentos"
                Componente={AtendimentosList}
                viewMode={true}
                entity={entity}
                agenda={[]}
                atendimentos={listAtendimentos}
                getBtns={() => {}}
                online={true}
                {...otherPops}
              ></FullCustomCard>
            )}

            {listAgendaExames.length > 0 && this.props.loggedIn && (
              <FullCustomCard
                key={'exames'}
                title="Exames"
                Componente={AtendimentosList}
                viewMode={true}
                entity={entity}
                agenda={listAgendaExames}
                atendimentos={[]}
                getBtns={() => {}}
                online={true}
                {...otherPops}
              ></FullCustomCard>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { estabelecimento, filtro } = state.agendaOnline;
  const { user } = state.authentication;

  return {
    estabelecimento,
    filtro,
    user,
    loggedIn,
  };
}
const mapDispatch = ({
  alert: { error },
  load: { loading },
  agenda: { receiveMonthAndYear, changeFilter },

  agendaOnline: { setEstabelecimento, setFiltroAgenda },
}) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  setEstabelecimento: (estabelecimento: any) =>
    setEstabelecimento({ estabelecimento }),
  setFiltroAgenda: (filtro: any) => setFiltroAgenda({ filtro }),
  changeFilter: (filter) => changeFilter({ filter: filter }),
  receiveMonthAndYear: (month, year) => receiveMonthAndYear({ month, year }),
});
const connectedAgendaOnlineFilter = connect(
  mapStateToProps,
  mapDispatch
)(AgendaOnlineFilter);
export { connectedAgendaOnlineFilter as AgendaOnlineFilter };
