//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EmpresaEdit from './EmpresaEdit';
import EmpresaListagemPage from './EmpresaListagemPage';
import EmpresaView from './EmpresaView';

export default class EmpresaIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/empresa" component={EmpresaListagemPage} exact />
        <Route path="/empresa/novo" component={EmpresaEdit} />
        <Route path="/empresa/view/:id" component={EmpresaView} />
      </Switch>
    );
  }
}
