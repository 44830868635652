//@flow
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect }  from 'react-redux';
import { withRouter } from 'react-router-dom';
import icon from '../../../images/icones/cancel.png';
import { objectsConstants } from '../../../_constants/objects.constants';

class StatusAtendimento extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {}

  render() {
    const { atendimento, ...otherProps } = this.props;
    console.log(atendimento);

    return atendimento.status == 'CANCELADO' ? (
      <div className={'atendimento-status atendimento-status-cancelado'}>
        <img src={icon} className={'icon-status-cancelado'} /> Cancelado
      </div>
    ) : (
      <div className={'atendimento-status'}>
        {objectsConstants.STATUS_DESCRICAO[atendimento.status]}
      </div>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(StatusAtendimento));
