import Avatar from "../../components/Avatar/Avatar";
import { dateHelper } from '../../_helpers/date.helper';

import _ from "lodash";
import React from "react";

export const OptionUsuario = props => {
    const { data, innerProps } = props;
    return (
        <div className="d-block pb-1" {...innerProps}>
            <div className="d-flex flex-row align-items-center justify-content-around react-select__option action py-1">
                <div className="col-1 d-flex flex-column align-items-stretch optionCreateUser">
                    <Avatar className="avatarCustom avatar-list" size={32} user={data} namePropsMidiaId={'foto.id'} />
                </div>
                <div className="col-11 d-flex flex-column align-items-stretch create">
                    <div className="font-weight-bold customOption">{data.nome} {data.cpfCnpj ? '(' + data.cpfCnpj + ')' : ''}</div>
                    <div className="local optionCreateUser">{`${_.get(data, `dataNascimento`) ? dateHelper.format(_.get(data, `dataNascimento`), {
                        mode: 'DATE',
                    }) : '--/--/----'}`}</div>
                </div>
            </div>
        </div>
    );
};