//@flow
import { func } from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { objectsConstants } from '../_constants/objects.constants';
import { ToolTipHelp } from '../components/Utils/ToolTipHelp';

const HORAS_DELAY_ATENDIMENTO = 2;
const INICIADO = 'Iniciado';
const BOLETO_IMPRESSO = 'Boleto Impresso';
const CANCELADO = 'Cancelado';
const EM_ANALISE = 'Em análise';
const PRE_AUTORIZADO = 'Pré-Autorizado';
const AUTORIZADO_PARCIAL = 'Autorizado Valor Parcial';
const RECUSADO = 'Recusado';
const APROVADO = 'Aprovado e Capturado';
const EM_DISPUTA = 'Em Disputa';
const NAO_IDENTIFICADO =
  'Erro não identificado, atualize seus dados de pagamento';

export const statusPagamentoHelper = {
  getCssClass,
};

function getCssClass(statusString) {
  if (
    statusString == INICIADO ||
    statusString == BOLETO_IMPRESSO ||
    statusString == EM_ANALISE ||
    statusString == PRE_AUTORIZADO
  ) {
    return 'barra-status-pendente';
  }
  if (
    statusString == CANCELADO ||
    statusString == RECUSADO ||
    statusString == EM_DISPUTA ||
    statusString == NAO_IDENTIFICADO
  ) {
    return 'barra-status-negado';
  }
  if (statusString == AUTORIZADO_PARCIAL || statusString == APROVADO) {
    return 'barra-status-sucesso';
  }
  return null;
}
