export function fieldMessage(state: any = {}, action: any) {
  switch (action.type) {
    case 'teste':
      return Object.assign({}, state, {
        loading: action.teste1 + 1
      });
    default:
      return state;
  }
}
