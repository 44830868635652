//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconTags from '../../../images/icon-tags.png';
export default class TagsBusca extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="card-central d-flex flex-column flex-xl-row m-0 p-0">
          <div className="col-12 col-xl-3 d-flex align-items-center justify-content-center">
            <img src={IconTags} alt="Imagem de agenda" />
          </div>
          <div className="col-12 col-xl-9 p-0">
            <h1>Tags de busca</h1>
            <p>
              Facilite a busca deste produto no momento da dispensação. Você
              pode utilizar tipos, principios ativos, ou qualquer outra
              caracteristica relevante.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
