import * as React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AgendaIcon from '../../images/icones/icon-com-agenda2.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default class AgendamentoRapidoMedicos extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
  }

  next = () => {
    this.setState({ currentIndex: this.state.currentIndex + 1 });
  };

  previus = () => {
    this.setState({ currentIndex: this.state.currentIndex - 1 });
  };

  render() {
    let { currentIndex } = this.state;
    let { indexMedico } = this.props;
    let itens = this.props.medicos.map((m, i) => {
      let medico = m.medico;
      return (
        <div className="col-6 col-sm-4 col-md-3 pointer" key={i}>
          <div
            className="d-flex justify-content-start carrossel-medicos "
            onClick={() => {
              this.props.changeMedico(i);
            }}
          >
            <Avatar user={medico}></Avatar>
            <div className="dados-medico-carrossel ml-0 ml-sm-3">
              <h1>{medico.apelido}</h1>
              <p className="dados-medico-carrossel-conselho">
                CRM: {medico.numeroConselho}
              </p>
              <p className="dados-medico-carrossel-especialidade">
                {medico.especialidades.map((especialidade, index) => {
                  let display = '';
                  if (index != 0) {
                    display = ', ';
                  }
                  return display + especialidade.nome;
                })}
              </p>
              <div className="mt-0 mt-sm-2">
                <img
                  src={AgendaIcon}
                  className="icon-agenda-carrossel mr-2"
                  alt=""
                />
                <span className="proxima-data-agenda ">{m.agenda[0].dia}</span>
              </div>
            </div>
          </div>
          {i == indexMedico && <hr className={'medico-select'} />}
        </div>
      );
    });

    return (
      <div className="topo-carousel-profissionais">
        <div className="carousel-container">
          <div className="carousel-wrapper">
            <button
              className="left-arrow"
              disabled={currentIndex == 0}
              onClick={this.previus}
            >
              &lt;
            </button>
            <div className="carousel-content-wrapper px-1 px-sm-5">
              <div
                className="d-flex m-4 carousel-container"
                style={{ transform: `translateX(-${currentIndex * 25}%)` }}
              >
                {itens}
              </div>
            </div>
            <button
              className="right-arrow"
              disabled={currentIndex >= this.props.medicos.length - 4}
              onClick={this.next}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    );
  }
}
