//@flow
import axios from 'axios';
import { urlsConstants } from '../_constants/urls.constant';
import { oauthHeaderJson } from '../_helpers/oauth-header';

export const defaultService = {
  findByFiltro,
  doGet,
  doDelete,
  doSave,
  listAll,
  doPost,
  doPut,
  getIdFromUrl,
  exportToExcel,
};

export type Options = {
  pageSize: number,
  page: number,
  order: string,
  direction: string,
  naturalOrder: boolean,
};

function getIdFromUrl(url) {
  let arrayUrl = url.split('/');
  let id = arrayUrl[arrayUrl.length - 1];
  return id;
}

function exportToExcel(controller, filter, fields, labels) {
  let url = `${urlsConstants.URL_DOMAIN}/${controller}/export/CSV`;
  return axios.post(
    url,
    {
      pageSize: 100000,
      page: 0,
      filter,
      fields,
      labels,
    },
    { headers: oauthHeaderJson() }
  );
}

function findByFiltro(url: string, options: Options, filter: any) {
  return axios.post(
    url,
    {
      pageSize: options.pageSize,
      page: options.page - 1,
      order: options.order,
      direction: options.direction,
      naturalOrder: options.naturalOrder,
      filter,
    },
    { headers: oauthHeaderJson() }
  );
}

function doGet(url: string) {
  return axios.get(url, { headers: oauthHeaderJson() });
}

function doDelete(url: string) {
  return axios.delete(url, { headers: oauthHeaderJson() });
}

function doSave(url: string, entity: any) {
  if (entity.id === null || entity.id === undefined) {
    return axios.post(url, entity, { headers: oauthHeaderJson() });
  } else {
    return axios.put(url + entity.id, entity, { headers: oauthHeaderJson() });
  }
}

function listAll(url: string) {
  return axios.post(url, {}, { headers: oauthHeaderJson() });
}

function doPost(url: string, entity: any) {
  return axios.post(url, entity, { headers: oauthHeaderJson() });
}

function doPut(url: string, entity: any) {
  return axios.put(url, entity, { headers: oauthHeaderJson() });
}
