
//@flow
import React from 'react';

export default {
  state: {
    estabelecimento: {}, filtro: {}, paciente: {}
  },
  reducers: {
    setEstabelecimento: (state, payload) => {
      return Object.assign({}, state, {
        estabelecimento: payload.estabelecimento
      });
    },
    setPaciente: (state, payload) => {
      return Object.assign({}, state, {
        paciente: payload.paciente
      });
    },
    setFiltroAgenda: (state, payload) => {
      return Object.assign({}, state, {
        filtro: payload.filtro
      });
    },
  }
};
