import { Line } from "@nivo/line";
import * as React from "react";
import "./DashboardFinanceiro.css";
import { Bar } from "@nivo/bar";
import Avatar from "../../components/Avatar/Avatar";
import { formatterHelper } from "../../_helpers/formatter.helper";

export default class DashFinanceiroGraficoDiario extends React.Component<{}> {


    constructor(props){
        super(props)
        this.state = {fluxoCaixa:[],data:null}
    }
    componentDidMount(){
        //reporteFinancyService.getCashFlow(r).then(r=>{
       //     this.setState({fluxoCaixa:r.data});
      //  })
      this.setState({fluxoCaixa:[{date:'01/10/2022',payment:1000.58,receipt:2545.00},
      {date:'02/10/2022',payment:1345.58,receipt:12456.00},
      {date:'03/10/2022',payment:6790.58,receipt:6000.00}]})
    }
  render() {
    const keys = ["Recebimentos", "Pagamentos"];
    const commonProperties = {
      width: 900,
      height: 400,
      margin: { top: 20, right: 20, bottom: 60, left: 80 },
      data: [],
      animate: true,
      enableSlices: "Valor",
    };
    const commonProps = {
      width: 900,
      height: 500,
      margin: { top: 60, right: 110, bottom: 60, left: 80 },

      indexBy: "data",
      keys,
      padding: 0.2,
      labelTextColor: "inherit:darker(1.4)",
      labelSkipWidth: 16,
      labelSkipHeight: 16,
    };
    const colors = { Pagamentos: "#dd3a46", Recebimentos: "#7bcc6d" };
    let data = this.state.fluxoCaixa.map((item)=>{
        return {
            data: item.date,
    
            Recebimentos: item.receipt,
    
            Pagamentos: item.payment,
          }
        });

    return (
      <div className=" box-grafico">
         <nav aria-label="Navegação de página exemplo">
            <ul class="pagination justify-content-end">
              <li class="page-item disabled">
                <a class="page-link" href="#" aria-label="Anterior">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Anterior</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  Outubro - 2022
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Próximo">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Próximo</span>
                </a>
              </li>
            </ul>
          </nav>
        <div className=" row justify-content-center text-center ">
          <h2 className="col-12 mt-5">FLUXO DE CAIXA DIÁRIO</h2>
         

          <div className=" col-12">
            <Bar
              colors={(bar) => {
                return colors[bar.id];
              }}
              groupMode="grouped"
              data={data}
              {...commonProps}
              layers={[
                "grid",
                "axes",
                "bars",
                "markers",
                "legends" /*LineLayer*/,
              ]}
            />
          </div>
        </div>
        
        <div className="label-listagem d-flex flex-wrap justify-content-end align-items-center text-center">
          <p className="col-3">Data</p>
          <p className="col-3">Recebimentos</p>
          <p className="col-3">Pagamentos</p>
          <p className="col-3">Saldo Final</p>
        </div>

        
        {this.state.fluxoCaixa.map((item)=>{
            return(
            <div className="listagem-valores  d-flex flex-wrap  justify-content-end align-items-center text-center">
            <p className="col-3">{item.date}</p>
            <p className="col-3">{formatterHelper.money( item.receipt)}</p>
            <p className="col-3">{formatterHelper.money( item.payment)}</p>
            <p className="col-3">{formatterHelper.money( item.receipt-item.payment)}</p>
          </div>)
        })}
      </div>
    );
  }
}
