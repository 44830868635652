//@flow
import { Can } from '@casl/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../_constants/objects.constants';
import _ from 'lodash';
import ButtonAutorized from './ButtonAutorized';
import { userService } from '../../_services/user.service';
type Props = {
  label: ?string,
  onClick: Function | string,
  role: String,
};

class ButtonAutorizedChangeStatusMarcacao extends React.Component<
  Props,
  State
> {
  static defaultProps = {
    disabled: false,
  };

  renderButton = () => {
    const {
      statusMarcacao,
      role,
      disabled,
      onClick,
      className,
      label,
      statusAuthorizated,
      user,
      isAgendamentoOnline,
    } = this.props;

    let listPermissao = _.get(user, 'role.role.listaPerfil', []);

    let permissaoPerfilBotao = _.filter(listPermissao, (p) => {
      if (
        p.etapaAtendimento &&
        p.etapaAtendimento.status == statusAuthorizated
      ) {
        // if (userService.isSecretaria() && p.etapaAtendimento.local) {
        //   return (
        //     _.filter(
        //       _.get(user, 'listLocalAtendimentoRecepcao', []),
        //       (local) => {
        //         return local.local.indexOf(p.etapaAtendimento.local) >= 0;
        //       }
        //     ).length > 0 && (!this.props.localAtendimento|| _.filter(
        //         _.get(user, 'listLocalAtendimentoRecepcao', []),
        //         (local) => {
        //           return local.local.indexOf(this.props.localAtendimento) >= 0;
        //         }
        //     ).length > 0)
        //   );
        // }
        return true;
      }

      return false;
    });
    if (userService.isSecretaria()) {
      let permite = _.get(user, 'listLocalAtendimentoRecepcao');
    }
    if (
      permissaoPerfilBotao &&
      permissaoPerfilBotao.length &&
      permissaoPerfilBotao.length > 0 &&
      statusMarcacao === statusAuthorizated
    ) {
      if (!isAgendamentoOnline) {
        return (
          <ButtonAutorized
            className={className}
            label={label}
            role={role}
            disabled={disabled}
            onClick={() => {
              onClick();
            }}
          ></ButtonAutorized>
        );
      }
    }
    return null;
  };
  render() {
    const { permissions, role, label, disabled } = this.props;
    return <React.Fragment>{this.renderButton()}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions,
  };
}

export default connect(mapStateToProps)(ButtonAutorizedChangeStatusMarcacao);
