import React from 'react';
import _ from 'lodash';

class Step6 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { formaPagamento: '' };
  }

  render() {
    const { entity } = this.props;
    let retorno = _.get(entity.entity, 'response.attributes');
    return (
      <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
        <div className="text-center">
          <h5>Finalizou</h5>
          <p>Cadastro finalizado com sucesso!</p>
          {_.get(retorno, 'boleto') && (
            <React.Fragment>
              <p>
                <b>Linha digitável: </b>
                {_.get(retorno, 'boleto.digitable_line')}
              </p>
              <p>
                <b>Link para visualização: </b>
                <a
                  className="link-boleto"
                  href={_.get(retorno, 'boleto.link')}
                  target="_blank"
                >
                  {_.get(retorno, 'boleto.link')}
                </a>
              </p>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Step6;
