//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import { HorarioRemarcacaoRow } from '../../components/horarios/HorarioRemarcacaoRow';
import { HorarioMarcacaoNaoFinalizadaRow } from '../../components/horarios/HorarioMarcacaoNaoFinalizadaRow';

import IconFechouAgenda from '../../images/icon-agenda-online-WHITE.svg';
import IconTask from '../../images/icones/notificacao.svg';
import { objectsConstants } from '../../_constants/objects.constants';
import type { medico } from '../../_types/medico.type';
import { HorarioTarefasRow } from '../../components/horarios/HorarioTarefasRow';

type Props = {
  date: any,
  medico: medico,
  onGoBack: any,
  listTaskPacientes: any,
};

type State = {};
const defaultPaciente = {
  nome: '',
  email: '',
  cpfCnpj: '',
  telefones: [{}],
  tipoAtendimento: objectsConstants.TIPO_ATENDIMENTO_PARTICULAR,
  pacienteConvenios: [
    {
      convenio: {},
      plano: '',
      carteirinha: '',
      validadeCarteirinha: '',
    },
  ],
};
class TarefasList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      paciente: defaultPaciente,
    };
  }

  remarcar = (marcacao) => {
    let filtro = {};
    _.set(filtro, 'estabelecimentoAtendimento.medico', marcacao.medico);
    _.set(filtro, 'medicoList', [marcacao.medico]);
    _.set(marcacao, 'tipoAgenda', this.props.tipoAgenda);
    this.props.changeFilter(filtro);
    this.props.receiveViewHorario(false);
    this.props.history.push({
      pathname: '/reagendamento',
      state: { marcacao: marcacao },
    });
  };

  getHeader = (usuario, nome, especialidade) => {
    return (
      <React.Fragment>
        <div className="col-12 col-lg-7 d-flex flex-column flex-lg-row photo-name">
          <Avatar user={usuario} editable={false}></Avatar>
          <div className="title d-flex flex-column justify-content-center">
            <p className="name">{nome}</p>
            <p className="info">{especialidade}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  getHeaderExame = (exame) => {
    return (
      <React.Fragment>
        <div className="col-12 col-lg-7 d-flex flex-column flex-lg-row photo-name">
          <div className="title d-flex flex-column justify-content-center">
            <p className="name">{_.get(exame, 'nome')}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { medico, listTaskPacientes, exame } = this.props;

    return (
      <React.Fragment>
        <div className="row tarefas">
          <div className="top d-flex flex-wrap">
            {this.props.medico &&
              this.getHeader(
                medico,
                _.get(medico, 'apelido'),
                _.get(medico, 'especialidades', [])
                  .map((e) => e.nome)
                  .join(',')
              )}

            {this.props.exame && this.getHeaderExame(exame)}

            <div className="count d-flex align-items-center ml-3">
              <img src={IconFechouAgenda} width="18" alt="Agenda fechada"></img>
              <div className="ml-3 text-center">
                <p>Não finalizados</p>
              </div>
            </div>
            <div className="background"></div>
          </div>
          <div className="box-alerta d-flex align-items-center">
            <img src={IconTask} width="18" alt="task"></img>
            <div className="ml-3">
              <p>
                <b>Os atendimento abaixo não foram finalizados.</b>
              </p>
              <p>Clique nos botões abaixo e finalize o atendimento.</p>
            </div>
          </div>

          <div className="schedules">
            {listTaskPacientes.map((itemHorario, index) => {
              return (
                <HorarioTarefasRow
                  idMarcacao={itemHorario.entity}
                  tipo={itemHorario.taskType}
                  key={itemHorario.id + '_' + index}
                  horario={itemHorario.horaLimite}
                  remarcar={this.remarcar}
                  clientSocket={this.props.client}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Array.prototype.sortBy = function (p) {
  return this.slice(0).sort(function (a, b) {
    return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
  });
};

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { client } = state.webSocket;
  const { tipoAgenda } = state.agenda;

  return {
    user,
    client,
    tipoAgenda,
  };
}
const mapDispatch = ({
  pageTitle: { changePageTitle },
  agenda: { receiveViewHorario, receiveTipoAgenda },
  load: { loading },
  authentication: { refreshUser },
  agendaOnline: { setPaciente },
  alert: { error },
  agenda: { changeFilter },
}) => ({
  changePageTitle: (pageTitle) => changePageTitle(pageTitle),
  loading: (load: boolean) => loading({ load }),
  receiveViewHorario: (viewHorario) => receiveViewHorario({ viewHorario }),

  refreshUser: (payload) => refreshUser(payload),
  setPaciente: (paciente) => setPaciente({ paciente }),
  error: (msg) => error(msg),
  changeFilter: (filter) => changeFilter({ filter: filter }),
});

export default connect(mapStateToProps, mapDispatch)(withRouter(TarefasList));
