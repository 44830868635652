//@flow
import * as React from 'react';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { objectsConstants } from '../../_constants/objects.constants';
import TopoListagem from '../../components/Topo/TopoListagem';
import {
  dashboardService,
  GetAllMedicalAppointmentDataResponse,
} from '../../_services/dashboard.service';
import SummaryDashboardComponent from '../../components/dashboard/SummaryDashboardComponent';
import ChartDashboardComponent from '../../components/dashboard/ChartDashboardComponent';
import SearchDashboardComponent from '../../components/dashboard/SearchDashboardComponent';

/**
 * State map
 *
 */
type State = {
  medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>,
  pacientesData: PacientesData[],
  pacientesDetailsData: PacientesDetailsData[],
  procedimentosDetailsData: ProcedimentosDetailsData[],
  atendimentosDetailsData: AtendimentoDetailsData[],
  tempoAtendimentosDetailsData: TempoAtendimentoDetailsData,
  conveniosDetailsData: ConveniosDetailsData[],
  showAnalyticData: boolean,
  showSyntheticData: boolean,
};

/**
 * Pacientes data.
 *
 */
interface PacientesData {
  //   period: string;
  agendados: number;
  confirmados: number;
  atendidos: number;
  faltantes: number;
}

/**
 * PacientesDetailsData
 *
 */
interface PacientesDetailsData {
  //   period: string;
  recorrentes: number;
  novos: number;
  homens: number;
  mulheres: number;
}

/**
 * ProcedimentosDetailsData
 *
 */
interface ProcedimentosDetailsData {
  //   period: string;
  consultas: number;
  retornos: number;
  exames: number;
}

/**
 * AtendimentoDetailsData
 *
 */
interface AtendimentoDetailsData {
  //   period: string;
  particular: number;
  convenio: number;
  cortesia: number;
}

/**
 *
 * TempoAtendimentoDetailsData
 *
 */
interface TempoAtendimentoDetailsData {
  particularData: TempoDetailsData[];
  convenioData: TempoDetailsData[];
}

/**
 * TempoAtendimentoDetailsData
 *
 */
interface TempoDetailsData {
  //   period: string;
  minimo: number;
  medio: number;
  maximo: number;
}

/**
 * ConveniosDetailsData
 *
 */
interface ConveniosDetailsData {
  convenios: ConvenioData[];
}

/**
 * ConvenioData
 *
 */
interface ConvenioData {
  name: string;
  value: number;
}

/**
 * Filter Dashboard
 *
 */
interface FilterDashboard {
  groupDate?: string;
  tipoVizualicao?: string;
  dataInicio?: Date;
  dataFim?: Date;
  tiposAtendimento?: string[];
  tiposConsulta?: string[];
  tiposMarcacao?: string[];
  idsEstabelecimento?: number[];
  idsMedico?: number[];
  idsPaciente?: number[];
  idsEmpresa?: number[];
  idsCooperativa?: number[];
  idsEspecialidade?: number[];
  idsConvenio?: number[];
  idsPlanoConvenio?: number[];
  idsExame?: number[];
  idsProcedimento?: number[];
}

/**
 * Dashboard listagem page
 *
 */
class DashboardListagemPage extends React.PureComponent<Props, State> {
  /**
   * Default constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      medicalAppointmentDataResponse: {},
      pacientesData: [],
      pacientesDetailsData: [],
      showAnalyticData: false,
      showSyntheticData: true,
    };
  }

  /**
   * Responsible load dashboard data
   *
   */
  loadDashboardData = (filter: FilterDashboard) => {
    this.props.loading(true);
    dashboardService
      .getAllMedicalAppointmentData(filter ? filter : {})
      .then((response) => {
        let medicalAppointmentDataResponse = _.cloneDeep(response);
        this.setState(
          {
            medicalAppointmentDataResponse: medicalAppointmentDataResponse,
            showSyntheticData:
              !filter.tipoVizualicao || filter.tipoVizualicao === 'SINTETICA',
            showAnalyticData:
              filter.tipoVizualicao && filter.tipoVizualicao === 'ANALITICA',
            pacientesData:
              this.converterMedicalAppointmentDataResponseToPacientesData(
                medicalAppointmentDataResponse
              ),
            pacientesDetailsData:
              this.converterMedicalAppointmentDataResponseToPacientesDetailsData(
                medicalAppointmentDataResponse
              ),
            procedimentosDetailsData:
              this.converterMedicalAppointmentDataResponseToProcedimentosDetailsData(
                medicalAppointmentDataResponse
              ),
            atendimentosDetailsData:
              this.converterMedicalAppointmentDataResponseToAtendimentosDetailsData(
                medicalAppointmentDataResponse
              ),
            tempoAtendimentosDetailsData:
              this.converterMedicalAppointmentDataResponseToTempoAtendimentosDetailsData(
                medicalAppointmentDataResponse
              ),
            conveniosDetailsData:
              this.converterMedicalAppointmentDataResponseToConveniosDetailsData(
                medicalAppointmentDataResponse
              ),
          },
          () => {
            this.props.loading(false);
          }
        );
      })
      .catch((reason: any) => {
        this.props.loading(false);
        const msg = `Falha ao obter os dados do dashboard, motivo: ${reason}. Tente novamente mais tarde ou entre em contato com o administrador do sistema.`;
        console.error(msg);
        this.props.error({ message: msg });
      });
  };

  /**
   * Responsible converter MedicalAppointmentDataResponse to PacientesData
   *
   * @param medicalAppointmentDataResponse
   * @return PacientesData[]
   */
  converterMedicalAppointmentDataResponseToPacientesData(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): PacientesData[] {
    if (
      this.validMedicalAppointmentsAndExamsAndProceduresDetails(
        medicalAppointmentDataResponse
      )
    ) {
      return _.map(
        medicalAppointmentDataResponse.medicalAppointmentDataResponse
          .medicalAppointmentsAndExamsAndProceduresDetails,
        function map(medicalAppointmentsAndExamsAndProceduresDetail) {
          return {
            // period: medicalAppointmentsAndExamsAndProceduresDetail.period,
            agendados:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountScheduled,
            atendidos:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountAttended,
            confirmados:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountWaitingAppointment +
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountAppointmentInProgress +
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountInCall,
            faltantes:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountNotAttended,
          };
        }
      );
    } else {
      return [];
    }
  }

  /**
   * Responsible converter MedicalAppointmentDataResponse to PacientesDetailsData
   *
   * @param medicalAppointmentDataResponse
   * @return PacientesDetailsData[]
   */
  converterMedicalAppointmentDataResponseToPacientesDetailsData(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): PacientesDetailsData[] {
    if (
      this.validMedicalAppointmentsAndExamsAndProceduresDetails(
        medicalAppointmentDataResponse
      )
    ) {
      return _.map(
        medicalAppointmentDataResponse.medicalAppointmentDataResponse
          .medicalAppointmentsAndExamsAndProceduresDetails,
        function map(medicalAppointmentsAndExamsAndProceduresDetail) {
          return {
            // period: medicalAppointmentsAndExamsAndProceduresDetail.period,
            recorrentes:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountPatientRecurrents,
            novos:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountPatientNew,
            homens:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountMalePatient,
            mulheres:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountFemalePatient,
          };
        }
      );
    } else {
      return [];
    }
  }

  /**
   * Responsible converter MedicalAppointmentDataResponse to ProcedimentosDetailsData
   *
   * @param medicalAppointmentDataResponse
   * @return ProcedimentosDetailsData[]
   */
  converterMedicalAppointmentDataResponseToProcedimentosDetailsData(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): ProcedimentosDetailsData[] {
    if (
      this.validMedicalAppointmentsAndExamsAndProceduresDetails(
        medicalAppointmentDataResponse
      )
    ) {
      return _.map(
        medicalAppointmentDataResponse.medicalAppointmentDataResponse
          .medicalAppointmentsAndExamsAndProceduresDetails,
        function map(medicalAppointmentsAndExamsAndProceduresDetail) {
          return {
            // period: medicalAppointmentsAndExamsAndProceduresDetail.period,
            consultas:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountAppointment -
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountReturn,
            retornos:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountReturn,
            exames:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountExamsAndProcedures,
          };
        }
      );
    } else {
      return [];
    }
  }

  /**
   * Responsible converter MedicalAppointmentDataResponse to AtendimentoDetailsData
   *
   * @param medicalAppointmentDataResponse
   * @return AtendimentoDetailsData[]
   */
  converterMedicalAppointmentDataResponseToAtendimentosDetailsData(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): AtendimentoDetailsData[] {
    if (
      this.validMedicalAppointmentsAndExamsAndProceduresDetails(
        medicalAppointmentDataResponse
      )
    ) {
      return _.map(
        medicalAppointmentDataResponse.medicalAppointmentDataResponse
          .medicalAppointmentsAndExamsAndProceduresDetails,
        function map(medicalAppointmentsAndExamsAndProceduresDetail) {
          return {
            // period: medicalAppointmentsAndExamsAndProceduresDetail.period,
            particular:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountPrivate,
            convenio:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountWithInsurance,
            cortesia:
              medicalAppointmentsAndExamsAndProceduresDetail.totalAmountFree,
          };
        }
      );
    } else {
      return [];
    }
  }

  /**
   * Responsible converter MedicalAppointmentDataResponse to TempoAtendimentoDetailsData
   *
   * @param medicalAppointmentDataResponse
   * @return TempoAtendimentoDetailsData
   */
  converterMedicalAppointmentDataResponseToTempoAtendimentosDetailsData(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): TempoAtendimentoDetailsData {
    if (
      this.validMedicalAppointmentsAndExamsAndProceduresDetails(
        medicalAppointmentDataResponse
      )
    ) {
      return {
        particularData: _.map(
          medicalAppointmentDataResponse.medicalAppointmentDataResponse
            .medicalAppointmentsAndExamsAndProceduresDetails,
          function map(medicalAppointmentsAndExamsAndProceduresDetail) {
            return {
              //   period: medicalAppointmentsAndExamsAndProceduresDetail.period,
              minimo:
                medicalAppointmentsAndExamsAndProceduresDetail.minAppointmentTimeParticular,
              medio:
                medicalAppointmentsAndExamsAndProceduresDetail.averageAppointmentTimeParticular,
              maximo:
                medicalAppointmentsAndExamsAndProceduresDetail.maxAppointmentTimeParticular,
            };
          }
        ),
        convenioData: _.map(
          medicalAppointmentDataResponse.medicalAppointmentDataResponse
            .medicalAppointmentsAndExamsAndProceduresDetails,
          function map(medicalAppointmentsAndExamsAndProceduresDetail) {
            return {
              //   period: medicalAppointmentsAndExamsAndProceduresDetail.period,
              minimo:
                medicalAppointmentsAndExamsAndProceduresDetail.minAppointmentTimeConvenio,
              medio:
                medicalAppointmentsAndExamsAndProceduresDetail.averageAppointmentTimeConvenio,
              maximo:
                medicalAppointmentsAndExamsAndProceduresDetail.maxAppointmentTimeConvenio,
            };
          }
        ),
      };
    } else {
      return [];
    }
  }

  /**
   * Responsible converter MedicalAppointmentDataResponse to ConveniosDetailsData
   *
   * @param medicalAppointmentDataResponse
   * @return ConveniosDetailsData[]
   */
  converterMedicalAppointmentDataResponseToConveniosDetailsData(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): ConveniosDetailsData[] {
    if (
      this.validMedicalInsuranceDataResponses(medicalAppointmentDataResponse)
    ) {
      return {
        convenios: _.map(
          medicalAppointmentDataResponse.medicalAppointmentDataResponse
            .medicalInsuranceDataResponses,
          function map(medicalAppointmentsAndExamsAndProceduresDetail) {
            return {
              name: medicalAppointmentsAndExamsAndProceduresDetail.name,
              value: medicalAppointmentsAndExamsAndProceduresDetail.amount,
            };
          }
        ),
      };
    } else {
      return [];
    }
  }

  /**
   * Responsible valid MedicalAppointmentsAndExamsAndProceduresDetails
   *
   * @param medicalAppointmentDataResponse
   * @return {MedicalAppointmentsAndExamsAndProceduresDetail[]}
   */
  validMedicalAppointmentsAndExamsAndProceduresDetails(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): boolean {
    return (
      medicalAppointmentDataResponse &&
      medicalAppointmentDataResponse.medicalAppointmentDataResponse &&
      medicalAppointmentDataResponse.medicalAppointmentDataResponse
        .medicalAppointmentsAndExamsAndProceduresDetails
    );
  }

  /**
   * Responsible valid MedicalInsuranceDataResponses
   *
   * @param medicalAppointmentDataResponse
   * @return {MedicalInsuranceDataResponses}
   */
  validMedicalInsuranceDataResponses(
    medicalAppointmentDataResponse: Partial<GetAllMedicalAppointmentDataResponse>
  ): boolean {
    return (
      medicalAppointmentDataResponse &&
      medicalAppointmentDataResponse.medicalAppointmentDataResponse &&
      medicalAppointmentDataResponse.medicalAppointmentDataResponse
        .medicalInsuranceDataResponses
    );
  }

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PAGINA_DASHBOARD}
          subTitle=" "
          canBack={false}
        />
        <TopoListagem />
        <SearchDashboardComponent submitFilter={this.loadDashboardData} />
        <SummaryDashboardComponent
          pacientesData={this.state.pacientesData}
          showAnalyticData={this.state.showAnalyticData}
          showSyntheticData={this.state.showSyntheticData}
        />
        <ChartDashboardComponent
          dataAnalyticsPacientes={this.state.pacientesDetailsData}
          dataAnalyticsProcedimentos={this.state.procedimentosDetailsData}
          dataAnalyticsAtendimento={this.state.atendimentosDetailsData}
          dataAnalyticsTemposAtendimento={
            this.state.tempoAtendimentosDetailsData
          }
          dataAnalyticsConvenios={this.state.conveniosDetailsData}
          showAnalyticData={this.state.showAnalyticData}
          showSyntheticData={this.state.showSyntheticData}
        />
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  refreshUser: (user) => refreshUser({ user }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  {}
)(DashboardListagemPage);
