//@flow
import { urlsConstants } from "../_constants/urls.constant";
import { defaultService } from "./defaultService";

export const conselhoService = {
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.CONSELHO}findAll`);
}
