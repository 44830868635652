//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import SelectInput from '../../components/inputs/SelectInput';
import IconFiltro from '../../images/icones/filter.svg';
import { objectsConstants } from '../../_constants/objects.constants';
import { convenioService } from '../../_services/convenio.service';
import { especialidadesService } from '../../_services/especialidades.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { exameService } from '../../_services/exame.service';
import { medicoService } from '../../_services/medico.service';
import { userService } from '../../_services/user.service';
// import "./AgendaPage.css";

class AgendaFiltro extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  handleFilter = () => {
    this.props.changeFilter(this.formRef.state.values);
  };

  clearFormFilter = () => {
    this.formRef.resetForm({});
  };

  render() {
    let { filtro, user } = this.props;

    return (
      <div className="menu-filtros">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="close-menu">
            <div className="action" onClick={this.props.fechaFiltro}>
              Cancelar
            </div>
          </div>
          <div className="titulo-filtro d-flex align-items-center">
            <img src={IconFiltro} />
            <span className="titulo"> Filtros</span>
          </div>
          <div className="mx-4"></div>
        </div>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={filtro}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <hr></hr>
              {userService.isSudo() && (
                <FormGroup className="col-12 select-filter">
                  <FormSelectInput
                    name="estabelecimento"
                    id="estabelecimento"
                    // icone={Estabelecimento}
                    classIcone={'off'}
                    label="Estabelecimento"
                    placeholder="Selecione o local do atendimento"
                    onChange={setFieldValue}
                    noSize={true}
                    returnFullObject={true}
                    valueKey={'id'}
                    labelKey={'nome'}
                    multi={false}
                    service={estabelecimentoService.findAll}
                    value={values.estabelecimento}
                    defaultValue={_.get(values, 'estabelecimento.nome')}
                    defaultSelected={false}
                  />
                </FormGroup>
              )}
              <FormGroup className="col-12 select-filter">
                <FormSelectInput
                  label="Convênio"
                  placeholder="Selecione um convênio"
                  name="convenio"
                  id={'convenio'}
                  // icone={Convenios}
                  classIcone={'off'}
                  noSize={true}
                  required={false}
                  service={convenioService.findAll}
                  searchable={true}
                  returnFullObject={true}
                  valueKey={'id'}
                  labelKey={'sigla'}
                  defaultSelected={false}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    setFieldValue(`planoConvenio`, {});
                  }}
                  value={values.convenio}
                  clearable={true}
                  parent={
                    _.get(values, 'estabelecimento.id')
                      ? _.get(values, 'estabelecimento.id')
                      : userService.getExtension()
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 select-filter">
                <SelectInput
                  label="Plano"
                  placeholder={
                    !values.convenio
                      ? 'Selecione o convenio para escolher o plano'
                      : 'Selecione um plano'
                  }
                  component={SelectInput}
                  labelKey={'nome'}
                  valueKey={'id'}
                  returnFullObject={true}
                  disable={!values.convenio}
                  defaultValue={_.get(values, `planoConvenio.nome`, '--')}
                  className={''}
                  clearable={true}
                  value={_.get(values, `planoConvenio`)}
                  name={`planoConvenio`}
                  id={`convenioplanoConvenioItem`}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  options={_.get(values, `convenio.listPlanoConvenio`, [])}
                />
              </FormGroup>
              {this.props.tipoAgenda ===
                objectsConstants.TIPO_AGENDA_CONSULTAS && (
                <FormGroup className="col-12 select-filter">
                  <FormSelectInput
                    name="especialidades"
                    id={'especialidades'}
                    // icone={Especialidade}
                    classIcone={'off'}
                    label="Especialidade"
                    placeholder="Selecione uma especialidade"
                    valueKey="id"
                    labelKey="nome"
                    noSize={true}
                    required={false}
                    clearable={true}
                    service={especialidadesService.findAll}
                    returnFullObject={true}
                    searchable={true}
                    onChange={(name, value) => {
                      setFieldValue(name, [value]);
                      setFieldValue(`medicoList`, null);
                    }}
                    value={values.especialidades}
                    parent={
                      _.get(values, 'estabelecimento.id')
                        ? _.get(values, 'estabelecimento.id')
                        : userService.getExtension()
                    }
                    // parent={_.get(values, 'estabelecimentoMedico.medico.id')}
                  />
                </FormGroup>
              )}

              {this.props.tipoAgenda ===
                objectsConstants.TIPO_AGENDA_EXAMES && (
                <FormGroup className="col-12 select-filter">
                  <FormSelectInput
                    name="exame"
                    id={'medico'}
                    // icone={Medicos}
                    classIcone={'off'}
                    label="Exames"
                    placeholder="Selecione um exame"
                    valueKey="id"
                    labelKey="nome"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={false}
                    searchable={true}
                    clearable={true}
                    service={exameService.findAll}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    value={values.exame}
                    defaultParent={
                      _.get(values, 'estabelecimento.id')
                        ? _.get(values, 'estabelecimento.id')
                        : userService.getExtension()
                    }
                  />
                </FormGroup>
              )}
              <FormGroup className="col-12 select-filter">
                {_.get(values, 'especialidades[0].id') && (
                  <FormSelectInput
                    name="medicoList"
                    id={'medico'}
                    // icone={Medicos}
                    classIcone={'off'}
                    label="Profissional de saúde"
                    placeholder="Selecione um profissional de saúde"
                    valueKey="id"
                    labelKey="apelido"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={true}
                    searchable={true}
                    service={medicoService.findMedicoByEspecialidade}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    value={values.medicoList}
                    parent={_.get(values, 'especialidades[0].id')}
                  />
                )}
                {!_.get(values, 'especialidades[0].id') && (
                  <FormSelectInput
                    name="medicoList"
                    id={'medico'}
                    // icone={Medicos}
                    classIcone={'off'}
                    label="Profissional de saúde"
                    placeholder="Selecione um profissional de saúde"
                    valueKey="id"
                    labelKey="apelido"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={true}
                    searchable={true}
                    service={medicoService.findMedicoByEspecialidade}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    value={values.medicoList}
                    parent={_.get(values, 'especialidades[0].id')}
                  />
                )}
              </FormGroup>

              <div
                className={
                  'd-flex align-items-center justify-content-between mt-3'
                }
              >
                <button
                  className="btn btn-link "
                  onClick={this.clearFormFilter}
                  type="button"
                >
                  Limpar filtros{' '}
                </button>

                <button
                  className="btn btn-primary btn-aplicar "
                  onClick={this.handleFilter}
                  type="button"
                >
                  Aplicar
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatch = ({
  alert: { error },
  load: { loading },
  filtromenu: { toggleFiltro, componenteFiltro, abreFiltro, fechaFiltro },
}) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  toggleFiltro: () => toggleFiltro(),
  abreFiltro: () => abreFiltro(),
  fechaFiltro: () => fechaFiltro(),
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions,
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(AgendaFiltro));
