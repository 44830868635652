//@flow
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from './../../components/inputs/FormInput';
import FormSelectInput from './../../components/inputs/FormSelectInput';
import _ from 'lodash';
import { categoriaDocumentoService } from './../../_services/categoriaDocumento.service.js';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class ModelosListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-3">
          <FormInput
            label="Nome do modelo"
            placeholder={'Nome'}
            id={'namefilter'}
            name="nome"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4 offset-sm-1'}
            value={entity.nome}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-3">
          <FormSelectInput
            label={'Categoria do modelo'}
            id="categoriaDocumento"
            name="categoriaDocumento"
            placeholder="Selecione um categoria"
            value={entity.categoriaDocumento}
            onChange={this.props.onChangeDate}
            noSize={true}
            required={true}
            service={categoriaDocumentoService.findAll}
            labelKey={'titulo'}
            returnFullObject={true}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
