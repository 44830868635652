//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const relatorioConsultasService = {
  findAll,
  doGet,
  findByFiltro,
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.RELATORIO_CONSULTAS}findAll`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.RELATORIO_CONSULTAS}${id}`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELATORIO_CONSULTAS}list`,
    options,
    filter
  );
}
