//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const protocoloAnamneseService = {
  doSave,
  findByMedico,
};

function doSave(modeloAnamnese) {
  return defaultService.doSave(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}`,
    modeloAnamnese
  );
}

function findByMedico() {
  return defaultService.doGet(
    `${urlsConstants.PROTOCOLO_ATENDIMENTO_ANAMNESE}findByMedico`
  );
}
