//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const empresaService = {
  doGet,
  doSave,
  findByFiltro,
  exportToExcel,
  findAll,
  findEmpresaVOById,
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.EMPRESA}findAll`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.EMPRESA}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.EMPRESA}${id}`);
}

function findEmpresaVOById(id) {
  return defaultService.doGet(
    `${urlsConstants.EMPRESA}findEmpresaVOById/${id}`
  );
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.EMPRESA, entity);
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel('empresa', filter, fields, labels);
}
