import * as React from "react";
import "./DashboardFinanceiro.css";
import DashFinanceiroGraficoDiario from "./DashFinancieroGraficoDiario";
import DashFinanceiroGraficoMensal from "./DashFinancieroGraficoMensal";

export default class DashboardFinanceiroPage extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = { graficoAExibir: 1 };
  }

  changeGrafico = (graficoAExibir) => {
    this.setState({ graficoAExibir });
  };

  render() {
    return (
      <div className=" d-flex  justify-content-center ">
        <div className=" dash-financeiro ">
          <h1 className="titulo-dashfinanceiro text-start m-5">
            Fluxo de Caixa
          </h1>
          
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`btn-custom nav-link ${
                  this.state.graficoAExibir === 1 ? "active" : ""
                } `}
                onClick={() => {
                  this.changeGrafico(1);
                }}
              >
                Diário
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`btn-custom nav-link ${
                  this.state.graficoAExibir === 2 ? "active" : ""
                } `}
                onClick={() => {
                  this.changeGrafico(2);
                }}
              >
                Mensal
              </button>
            </li>
          </ul>
          
          <div className={"fila-body-container"}>
            {this.state.graficoAExibir === 1 && <DashFinanceiroGraficoDiario />}

            {this.state.graficoAExibir === 2 && <DashFinanceiroGraficoMensal />}
          </div>
          
        </div>
      </div>
    );
  }
}
