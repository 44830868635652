//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const grupoService = {
  findByEstabelecimento,
  doSave
};

function findByEstabelecimento() {
  return defaultService.doGet(`${urlsConstants.GRUPO}findByEstabelecimento`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.GRUPO, entity);
}
