//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const medicamentoService = {
  findByNomeOrPrincipio,
  saveMedicamentoCustom,
};

function findByNomeOrPrincipio(nome) {
  return defaultService.doPost(
    `${urlsConstants.MEDICAMENTO}findByNomeOrPrincipio/`,
    { nome, type: 'medicamentoAnvisa' }
  );
}
function saveMedicamentoCustom(value) {
  return defaultService.doPost(`${urlsConstants.MEDICAMENTO}saveCustom`, {
    apresentacao: value,
    type: 'medicamentoCustom',
  });
}
