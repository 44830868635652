//@flow
import { oauthHeaderJson } from './../_helpers/oauth-header';
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const filaService = {

  findPacientesFila,
  findFilas,
  chamarPaciente,
  cancelarPaciente,
    inativarPaciente,
  chamarTerminal,
  chamarNovamentePaciente
};

function chamarTerminal(local,idMarcacao){
  return defaultService.doPost(`${urlsConstants.GENIALTEC}chamar/marcacao/${idMarcacao}`,local.nome );
}

function findPacientesFila() {
  return defaultService.doGet(`${urlsConstants.MARCACAO}recepcao/fila/list`, );
}

function findFilas(){
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}fila/list`, );
}
function chamarNovamentePaciente(paciente,local){
    return defaultService.doGet(`${urlsConstants.CHAMAR_NOVAMENTE_PACIENTE_GENIALTEC}${paciente.id}/${local.local+' '+local.nome}`);
}

function chamarPaciente(paciente,local){
  console.log(local);
  console.log(123)
  return defaultService.doGet(`${urlsConstants.CHAMAR_PACIENTE_GENIALTEC}${paciente.id}/${local.local+' '+local.nome}`);
}


function cancelarPaciente(paciente){
  return defaultService.doGet(`${urlsConstants.CANCELAR_PACIENTE_GENIALTEC}${paciente.id}`);
}


function inativarPaciente(paciente){
  return defaultService.doGet(`${urlsConstants.INATIVAR_PACIENTE_GENIALTEC}${paciente.id}`);
}
