//@flow
import { urlsConstants } from './../_constants/urls.constant';
import { defaultService } from './defaultService';

export const pacoteService = {
  findByFiltro,
  doSave,
  doGet,
  
};

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.PACOTE}list`,
    options,
    filter
  );
}

function doSave(entity: any) {
    return defaultService.doSave(urlsConstants.PACOTE, entity);
  }

  function doGet(id){
    return defaultService.doGet(`${urlsConstants.PACOTE}${id}`);
  }


