import { PureComponent } from "react";
import {connect} from "react-redux";

type Props = {
  mainTitle?: string,
  subTitle: string,
  canBack: boolean
};

class TopoTitleComponente extends PureComponent<Props, State> {
  componentDidMount = () => {
    this.dispatchUrl(this.props);
  };

  componentWillReceiveProps(nexProps) {
    this.dispatchUrl(nexProps);
  }

  dispatchUrl = (props) => {
    this.props.changePageTitle({
      subTitle: props.subTitle,
      mainTitle: props.mainTitle,
      canBack: props.canBack,
      backUrl: props.backUrl,
      backConfirmObject: props.backConfirmObject,
      onClickBack: props.onClickBack
    });
  }
  render() {
    return null;
  }
}
const mapDispatch = ({ pageTitle: { changePageTitle } }) => ({
  changePageTitle: pageTitle => changePageTitle(pageTitle)
});

export default connect(null, mapDispatch)(TopoTitleComponente);
