//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TarefasPage from './TarefasPage';

export default class TarefasIndexPage extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/tarefas/" component={TarefasPage} exact />
      </Switch>
    );
  }
}
