//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExameEdit from './ExameEdit';
import ExameListagemPage from './ExameListagemPage';
import ExameView from './ExameView';

export default class ExameIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/exame" component={ExameListagemPage} exact />
        <Route path="/exame/novo" component={ExameEdit} />
        <Route path="/exame/view/:id" component={ExameView} />
      </Switch>
    );
  }
}
