//@flow
import _ from 'lodash';

export default {
    state: { taskList: [], taskListSize: 0, currentTask: {} },
    reducers: {
        addTask: (state, payload) => {
            return Object.assign({}, state, {
                taskList: payload.notificacoes,
                taskListSize: payload.size
            });
        },
        runTask: (state, payload) => {
            return Object.assign({}, state, {
                currentTask: payload
            });
        }
    },
    effects: (dispatch: any) => ({
        addAllTask: async payload => {
            dispatch.task.addTask(payload);
        },

    })
};
