//@flow
import React from 'react';

type State = {
  load: boolean
};

type Payload = {
  load: boolean
};

export default {
  state: { load: false },
  reducers: {
    loading: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        load: payload.load
      });
    }
  }
};
