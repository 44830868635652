//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PacoteListagemPage from './PacoteListagemPage';
import PacoteEdit from './PacoteEdit';
import PacoteView from './PacoteView';



export default class PacoteIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/pacotes"
          component={PacoteListagemPage}
          exact
        />
        <Route path="/pacotes/novo" component={PacoteEdit} />
        <Route path="/pacotes/view/:id" component={PacoteView} />
        
      </Switch>
    );
  }
}
