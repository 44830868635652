//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import SockJS from 'sockjs-client';
import webstomp from 'webstomp-client';
import AuthorizedRoute from '../../_components/AuthorizedRoute';
import { CacheAgenda } from '../../_components/CacheAgenda';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { agendaService } from '../../_services/agenda.service';
import { userService } from '../../_services/user.service';
import Footer from '../../components/Footer/Footer';
import MenuAguardandoPagamento from '../../components/MenuAguardandoPagamento/MenuAguardandoPagamento';
import Menu from '../../components/MenuFull/Menu';
import { TaskSocketConect } from '../../components/Socket/TaskSocketConect';
import Toast from '../../components/Toasts/Toast';
import { Header } from '../../components/header/Header';
import MenuList from '../../components/menu/MenuList';
import { AgendaPage } from '../agenda/AgendaPage';
import EmpresaIndex from '../empresa/EmpresaIndex';
import EstabelecimentoIndex from '../estabelecimento/EstabelecimentoIndex';
import EstoqueProdutosIndex from '../estoque/estoque-produtos/EstoqueProdutosIndexPage';
import ExameIndex from '../exames/ExameIndex';

import PagamentoIndexPage from '../Pagamento/PagamentoIndexPage';
import AgendamentoRapidoIndexPage from '../agendamentoRapido/AgendamentoRapidoIndexPage';
import ConvenioIndex from '../convenio/ConvenioIndex';
import DashboardIndexPage from '../dashboard/DashboardIndexPage';
import DashboardFinanceiroIndexPage from '../dashboardFinanceiro/DashboardFinanceiroIndexPage';
import EstoqueAjusteIndex from '../estoque/estoque-ajuste/EstoqueAjusteIndex';
import EstoqueEntradaIndex from '../estoque/estoque-entrada/EstoqueEntradaIndexPage';
import EstoqueRelatorioMovimentacaoIndex from '../estoque/estoque-rel-movimentacao/EstoqueRelatorioMovimentacaoIndex';
import EstoqueRelatorioPosicaoIndex from '../estoque/estoque-rel-posicao/EstoqueRelatorioPosicaoIndex';
import FilaPage from '../fila/FilaPage';
import { HomePage } from '../home/HomePage';
import IntegracaoIndex from '../integracao/IntegracaoIndex';
import ModelosIndex from '../modelos/ModelosIndex';
import ModelosAnamneseIndex from '../modelos_anamnese/ModelosAnamneseIndex';
import ModelosReceituarioIndex from '../modelos_receituario/ModeloReceituarioIndex';
import PacoteIndex from '../pacote/PacoteIndex';
import ParametrizacaoIndex from '../parametrizacao/ParametrizacaoIndexPage';
import PerfilIndexPage from '../perfil/PerfilIndexPage';
import ProntuarioIndex from '../prontuario/ProntuarioIndexPage';
import ReagendamentoIndexPage from '../reagendamento/ReagendamentoIndexPage';
import RelatoriosIndex from '../relatorios/RelatoriosIndex';
import RelatoriosExamesIndex from '../relatoriosExames/RelatoriosExamesIndex';
import TarefasIndexPage from '../tarefas/TarefasIndexPage';
import VoucherSolutiIndexPage from '../voucher-soluti/VoucherSolutiIndexPage';
import { urlsConstants } from './../../_constants/urls.constant';
import {
  updateCache,
  updateFila,
  updateMarcacao,
} from './../../_helpers/agenda.helper';
import MedicoIndex from './../medico/MedicoIndexPage';
import PacienteIndex from './../paciente/PacienteIndexPage';
import UsuarioIndex from './../usuario/UsuarioIndexPage';
import './MainPage.css';

import { defaultService } from '../../_services/defaultService';
import EstoqueSaidaIndex from '../estoque/estoque-saida/EstoqueSaidaIndexPage';
webstomp.debug = null;
type Props = {
  dispatch: any,
  alert: any,
};

type States = {
  message: string,
  client?: any,
  webSocketConnected: boolean,
  aguardandoPagamento: boolean,
};

const medicoMenu = { to: '/medico', name: 'Profissional de saúde' };
class MainPage extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: '',
      webSocketConnected: false,
      exibeLoading: false,
      aguardandoPagamento: false,
      client: null,
    };
    // this.arrayMenu = [
    //   {
    //     to: '/agenda',
    //     name: 'Agenda',
    //     icon: Agenda,
    //     permission: objectsConstants.MENU_AGENDA,
    //   },
    //   {
    //     to: '/estabelecimento',
    //     name: 'Estabelecimento',
    //     icon: Estabelecimento,
    //     permission: objectsConstants.CRUD_ESTABELECIMENTOS,
    //   },
    //   {
    //     to: '/exame',
    //     name: 'Exames',
    //     icon: Exames,
    //     permission: objectsConstants.MENU_PACIENTE,
    //   },
    //   {
    //     to: '/paciente',
    //     name: 'Pacientes',
    //     icon: Paciente,
    //     permission: objectsConstants.MENU_PACIENTE,
    //   },
    //   {
    //     to: '/medico',
    //     name: 'Profissionais de saúde',
    //     icon: Medicos,
    //     permission: objectsConstants.MENU_MEDICO,
    //   },
    //   {
    //     to: '/usuario',
    //     name: 'Usuários do sistema',
    //     icon: Usuarios,
    //     permission: objectsConstants.MENU_USUARIO,
    //   },
    //   // { to: '/secretaria/novo', name: 'Secretária' },
    //   {
    //     to: '/',
    //     name: 'Sair',
    //     icon: Sair,
    //     permission: objectsConstants.PUBLIC,
    //     onClick: this.handleLogout,
    //   },
    // ];
    // if (!userService.isMedico()) {
    //   this.arrayMenu.push(medicoMenu);
    // }
  }

  handleLogout = () => {
    this.props.fechaMenu();
    this.props.logout();
    this.props.clearCache();
  };

  // openMenu() {
  //   this.setState({ menuOpen: true });
  // }

  // closeMenu() {
  //   this.setState({ menuOpen: false });
  // }

  componentDidMount() {
    this.updateAll();
    setInterval(this.verificaSocket, 10000);
  }
  verificaSocket = () => {
    try {
      if (this.state.client && this.state.client.connected) {
        this.state.client.send(urlsConstants.SEND_PACIENTE_STATUS, '{}');
      }
    } catch (e) {
      console.log('socket desconectado');
    }
  };
  // verificaPagamento = () => {
  //   console.log(this.props.erroPagamento);
  //   if (!this.props.erroPagamento) {
  //     this.setState({ aguardandoPagamento: false });
  //   } else {
  //     this.setState({ aguardandoPagamento: true }, () => {
  //       this.props.history.push('/pagamento');
  //     });
  //   }
  // };

  updateAll = () => {
    userService.me().then(
      (user) => {
        this.connectAndReconnect();
      },
      (error) => {
        if (error === 'invalid_token') {
          this.props.logout();
          this.props.clearCache();
        }
      }
    );
  };

  atualizaMensagensPendentes = (channel, functionCall) => {
    let dateTimeLastSync = localStorage.getItem('dateTimeLastUpdateNotify');
    if (!dateTimeLastSync) dateTimeLastSync = Date.now();

    agendaService
      .getPendingNotifyMessages(channel, dateTimeLastSync)
      .then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((item) => {
            functionCall(item);
          });
        }
      });
  };

  conectChanels = (client) => {
    if (client && client.connected) {
      const canalAtivo = this.props.canal;
      if (canalAtivo) {
        canalAtivo.unsubscribe();
      }

      let id;
      if (userService.isMedico()) {
        id = userService.getCurrentUser().id;
      } else {
        id = userService.getExtension();
        ///Atualiza mensagens pendentes
        this.atualizaMensagensPendentes(
          urlsConstants.SUBSCRIBE_PACIENTE_SEM_MARCACAO + id,
          this.handleDataUpdatePaciente
        );

        let canal = client.subscribe(
          urlsConstants.SUBSCRIBE_PACIENTE_SEM_MARCACAO + id,
          this.handleDataUpdatePaciente
        );
      }
      if (id) {
        ///Atualiza mensagens pendentes
        this.atualizaMensagensPendentes(
          urlsConstants.SUBSCRIBE_PACIENTE_STATUS_CHANGE + id,
          this.handleUpdateMessage
        );

        let canal = client.subscribe(
          urlsConstants.SUBSCRIBE_PACIENTE_STATUS_CHANGE + id,
          this.handleDataUpdate
        );
        this.props.setCanalSocket(canal);
      }
    }
  };
  conectChanelsIndisponibilidade = (client) => {
    if (client && client.connected) {
      let id;
      if (userService.isMedico()) {
        ///Atualiza mensagens pendentes
        this.atualizaMensagensPendentes(
          urlsConstants.SUBSCRIBE_MEDICO_CONSULTA_INDISPONIBILIDADE +
            userService.getCurrentUser().id,
          this.handleUpdateCache
        );

        client.subscribe(
          urlsConstants.SUBSCRIBE_MEDICO_CONSULTA_INDISPONIBILIDADE +
            userService.getCurrentUser().id,
          this.handleUpdateCache
        );
      } else {
        ///Atualiza mensagens pendentes
        this.atualizaMensagensPendentes(
          urlsConstants.SUBSCRIBE_ESTABELECIMENTO_CONSULTA_INDISPONIBILIDADE +
            userService.getExtension(),
          this.handleUpdateCache
        );

        client.subscribe(
          urlsConstants.SUBSCRIBE_ESTABELECIMENTO_CONSULTA_INDISPONIBILIDADE +
            userService.getExtension(),
          this.handleUpdateCache
        );
      }
    }
  };

  handleUpdateCache = (messageOutput: any) => {
    let newServerCache = JSON.parse(messageOutput.body);
    console.log(this);
    if (newServerCache && this.props.cache) {
      defaultService
        .doPost(`${urlsConstants.AGENDA}medicoList`, this.props.filtro)
        .then((response) => {
          let agenda = newServerCache[0].agenda;
          let medicos = response.data;
          _.map(agenda, (a) => {
            _.map(medicos, (m) => {
              if (a.medico != null && m.id == a.medico.id) {
                if (a.medico.localAtendimento) {
                  m.localAtendimento = a.medico.localAtendimento;
                }
                a.medico = m;
              }
            });
          });
          newServerCache[0].agenda = agenda;

          let newCache = updateCache(newServerCache, this.props.cache);
          ///Salva a data e hora da última mensagem de update vinda do servidor
          localStorage.setItem('dateTimeLastUpdateNotify', Date.now());
          this.props.updateCache(newCache);
          console.log(newServerCache);
          if (
            this.props.filtro &&
            new CacheAgenda(newServerCache[0].filtro).isEquals(
              new CacheAgenda(this.props.filtro)
            )
          ) {
            let newCacheAtual = new CacheAgenda(newServerCache[0].filtro);
            newCacheAtual.agenda = newServerCache[0].agenda;
            this.props.updateCacheAgendaAtual(newCacheAtual);
            this.props.receiveAgenda(newCacheAtual.filtrar(this.props.filtro));
          }
        });
    }
  };

  handleDataUpdate = (messageOutput: any) => {
    let marcacao = JSON.parse(messageOutput.body);
    this.handleUpdateMessage(marcacao);
  };

  handleUpdateMessage = (marcacao) => {
    let agendas = {};
    if (dateHelper.isBeforeNow(this.props.dataAtualAgenda)) {
      var agendaConcat = this.props.agendaDoDia.concat(this.props.agendas);
      agendas = updateMarcacao(agendaConcat, marcacao);
    } else {
      agendas = updateMarcacao(
        this.props.agendas,
        marcacao,
        this.props.tipoAgenda
      );
    }
    this.props.addMarcacaoCache(marcacao);
    //this.props.receiveAgenda(agendas, 'agendaPage');
  };

  handleDataUpdatePaciente = (messageOutput: any) => {
    let pacienteFila;
    if (messageOutput && messageOutput.body) {
      pacienteFila = JSON.parse(messageOutput.body);
    } else {
      pacienteFila = messageOutput;
    }
    let fila = {};

    fila = updateFila(this.props.fila, pacienteFila);

    this.props.receiveFila(fila);
  };

  connectAndReconnect = (socketInfo) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      let sock = new SockJS(
        urlsConstants.WS_WEBSOCKET + '?access_token=' + token.access_token
      );

      let client = webstomp.over(sock);
      this.setState({ client }, () => {
        client.connect(
          {},
          (frame) => {
            this.successCallback(client);
          },
          (error) => {
            console.log('error');
            this.errorCallback(error);
            setTimeout(() => {
              this.connectAndReconnect(socketInfo);
            }, 5000);
          }
        );
      });
    }
  };

  successCallback = (client) => {
    this.props.clearMedico();
    this.props.clear();
    this.setState({ client, webSocketConnected: true }, () => {});
    this.props.conectSocket({ client, webSocketConnected: true });
    this.conectChanels(client);
    this.conectChanelsIndisponibilidade(client);
  };

  errorCallback = (error) => {
    console.log(error);
    this.setState({ webSocketConnected: false });
    this.props.disconectSocket();
    /*    this.props.error({
      message:
        'A conexão com o servidor foi perdida, aguarde enquanto tentamos reconectar.',
    });*/

    this.setState({ exibeLoading: true }, () => {
      //this.props.loading(true);
    });
  };

  Agenda = () => {
    return <AgendaPage client={this.state.client} />;
  };

  abreMenu = () => {
    let propriedades = {
      arrayMenu: this.arrayMenu,
      user: userService.getCurrentUser(),
    };

    this.props.abreMenu(MenuList, propriedades);
  };

  render() {
    const { alert } = this.props;
    return (
      <React.Fragment>
        <div id="wrapper" className="container-fluid">
          <Header
            openMenu={this.abreMenu}
            aguardandoPagamento={this.props.erroPagamento}
          />
          {alert.message && (
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          )}
          {!this.props.erroPagamento && (
            <Menu
              user={this.props.menu}
              isOpen={this.props.menuAberto}
              closeCallback={this.props.fechaMenu}
              updateAll={this.updateAll}
            ></Menu>
          )}
          {this.props.erroPagamento && (
            <MenuAguardandoPagamento
              user={this.props.menu}
              isOpen={this.props.menuAberto}
              closeCallback={this.props.fechaMenu}
            ></MenuAguardandoPagamento>
          )}
          <Toast></Toast>
          {this.state.webSocketConnected && (
            <TaskSocketConect></TaskSocketConect>
          )}
          <Switch>
            <Route exact path="/" component={HomePage} />

            <Switch>
              <AuthorizedRoute
                path="/agenda"
                component={this.Agenda}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_AGENDA}
              />

              <AuthorizedRoute
                path="/paciente"
                component={PacienteIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_PACIENTE}
              />
              <AuthorizedRoute
                path="/medico"
                component={MedicoIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_MEDICO}
              />
              <AuthorizedRoute
                path="/usuario"
                component={UsuarioIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_USUARIO}
              />
              <AuthorizedRoute
                path="/empresa"
                component={EmpresaIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_CODE4}
              />
              <AuthorizedRoute
                path="/prontuario"
                component={ProntuarioIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_ANAMNESE_VISUALIZAR}
              />
              <AuthorizedRoute
                path="/estabelecimento"
                component={EstabelecimentoIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_ESTABELECIMENTOS}
              />
              <AuthorizedRoute
                path="/pacotes"
                component={PacoteIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_ESTABELECIMENTOS}
              />
              <AuthorizedRoute
                path="/integracao"
                component={IntegracaoIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_INTEGRACAO}
              />
              <AuthorizedRoute
                path="/exame"
                component={ExameIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_EXAME}
              />
              {/* <AuthorizedRoute
                  path="/convenio"
                  component={Convenios}
                  I={objectsConstants.VIEW}
                  a={objectsConstants.MENU_EXAME}
              />*/}
              <AuthorizedRoute
                path="/modelo"
                component={ModelosIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_MODELO}
              />
              <AuthorizedRoute
                path="/modelo-anamnese"
                component={ModelosAnamneseIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_MODELO_ANAMNESE}
              />
              <AuthorizedRoute
                path="/modelo-receituario"
                component={ModelosReceituarioIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_MODELO_RECEITUARIO}
              />

              <AuthorizedRoute
                path="/relatorios"
                component={RelatoriosIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_RELATORIOS}
              />
              <AuthorizedRoute
                path="/relatorios-exames"
                component={RelatoriosExamesIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_RELATORIOS_EXAMES}
              />
              <AuthorizedRoute
                path="/perfil"
                component={PerfilIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.PUBLIC}
              />
              <AuthorizedRoute
                path="/tarefas"
                component={TarefasIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.PUBLIC}
              />
              <AuthorizedRoute
                path="/reagendamento"
                component={ReagendamentoIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_AGENDA}
              />
              <AuthorizedRoute
                path="/pagamento"
                component={PagamentoIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.PUBLIC}
              />
              <AuthorizedRoute
                path="/voucher-soluti"
                component={VoucherSolutiIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_VOUCHER}
              />
              <AuthorizedRoute
                path="/dashboard"
                component={DashboardIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_DASHBOARD}
              />
              <AuthorizedRoute
                path="/convenio"
                component={ConvenioIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_RELATORIOS} // Mudar objectConstants.CRUD //
              />
              <AuthorizedRoute
                path="/triagempaciente"
                component={FilaPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_RELATORIOS} // Mudar objectConstants.CRUD //
              />
              <AuthorizedRoute
                path="/dashboard-financeiro"
                component={DashboardFinanceiroIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.CRUD_RELATORIOS}
              />
              {/* MENU ESTOQUE */}
              <AuthorizedRoute
                path="/estoque-produtos"
                component={EstoqueProdutosIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_ESTOQUE}
              />
              <AuthorizedRoute
                path="/estoque-entrada"
                component={EstoqueEntradaIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_ESTOQUE}
              />
              <AuthorizedRoute
                path="/estoque-saida"
                component={EstoqueSaidaIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_ESTOQUE}
              />

              <AuthorizedRoute
                path="/estoque-relatorio-movimentacao"
                component={EstoqueRelatorioMovimentacaoIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_ESTOQUE}
              />

              <AuthorizedRoute
                path="/estoque-relatorio-posicao"
                component={EstoqueRelatorioPosicaoIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_ESTOQUE}
              />

              <AuthorizedRoute
                path="/estoque-ajuste"
                component={EstoqueAjusteIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_ESTOQUE}
                a={objectsConstants.CRUD_RELATORIOS} // Mudar objectConstants.CRUD //
              />
              <AuthorizedRoute
                path="/agendamento-rapido"
                component={AgendamentoRapidoIndexPage}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_PACIENTE} // Mudar objectConstants.CRUD //
              />

              <AuthorizedRoute
                path="/parametrizacao"
                component={ParametrizacaoIndex}
                I={objectsConstants.VIEW}
                a={objectsConstants.MENU_PACIENTE} // Mudar objectConstants.CRUD //
              />
            </Switch>
            {/* )} */}
          </Switch>

          <Footer></Footer>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  authentication: { logout },
  webSocket: { conectSocket, disconectSocket },
  agenda: {
    clearMedico,
    receiveAgenda,
    setCanalSocket,
    addMarcacaoCache,
    updateCacheAgendaAtual,
    updateCache,
    receiveFila,
    clearCache,
  },
  alert: { success, error, clear },
  sidemenu: { toggleMenu, abreMenu, fechaMenu },
  load: { loading },
}) => ({
  updateCache: (cache) => updateCache({ cache }),
  updateCacheAgendaAtual: (cacheAgendaAtual) =>
    updateCacheAgendaAtual({ cacheAgendaAtual }),
  setCanalSocket: (canal) => setCanalSocket({ canal }),
  receiveAgenda: (agendas, source) => receiveAgenda({ agendas, source }),
  receiveFila: (fila) => receiveFila({ fila }),
  addMarcacaoCache: (marcacao) => addMarcacaoCache({ marcacao }),
  logout: () => logout(),
  clearMedico: () => clearMedico(),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
  clear: () => clear(),
  clearCache: () => clearCache(),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu(),
  fechaMenu: () => fechaMenu(),
  loading: (load: boolean) => loading({ load }),
  conectSocket: (clientScoket) => conectSocket(clientScoket),
  disconectSocket: () => disconectSocket(),
});

function mapStateToProps(state) {
  const { alert } = state;
  const { menuAberto } = state.sidemenu;
  const { webSocketConnected } = state.webSocket;
  const {
    agendas,
    dataAtualAgenda,
    agendaDoDia,
    dataAgendaDia,
    cacheAgendaAtual,
    cache,
    fila,
    filtro,
    tipoAgenda,
  } = state.agenda;
  const { user, erroPagamento } = state.authentication;

  return {
    tipoAgenda,
    user,
    alert,
    menuAberto,
    cacheAgendaAtual,
    webSocketConnected,
    agendas,
    dataAtualAgenda,
    agendaDoDia,
    dataAgendaDia,
    erroPagamento,
    cache,
    fila,
    filtro,
  };
}

export default connect(mapStateToProps, mapDispatch)(MainPage);

// const connectedMainPage = connect(mapStateToProps, mapDispatch)(MainPage);
// export { connectedMainPage as MainPage };
