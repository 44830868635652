//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import UsuarioEdit from './UsuarioEdit';
import UsuarioListagemPage from './UsuarioListagemPage';
import UsuarioView from './UsuarioView';

export default class UsuarioIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path="/usuario/novo" component={UsuarioEdit} />
        <Route path="/usuario/view/:id" component={UsuarioView} />
        <Route path="/usuario" component={UsuarioListagemPage} />
      </Switch>
    );
  }
}
