//@flow
import _ from 'lodash';
import * as React from 'react';
import { FormGroup } from 'reactstrap';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import InputViewEdit from '../../../../components/inputs/InputViewEdit';
import SelectInput from '../../../../components/inputs/SelectInput';
import { objectsConstants } from '../../../../_constants/objects.constants';
import { dateHelper } from '../../../../_helpers/date.helper';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class IntegracaoSascLogsListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormGroup className="col-12 col-md-3">
          <InputViewEdit
            component={DatePickerInput}
            label="Data da criação"
            name={`dataCriacao`}
            id={`dataCriacao`}
            value={entity.dataCriacao}
            onChange={this.props.onChangeDate}
            placeholder={'--/--/----'}
            defaultValue={
              _.get(entity, `dataCriacao`)
                ? dateHelper.format(_.get(entity, `dataCriacao`))
                : '--'
            }
          />
        </FormGroup>

        <FormGroup className={'col-12 col-md-3'}>
          <InputViewEdit
            component={SelectInput}
            options={objectsConstants.STATUS_INTEGRACAO}
            label="Status"
            id="statusIntegracao"
            name="statusIntegracao"
            viewMode={this.props.viewMode}
            valueKey={'name'}
            labelKey={'description'}
            returnFullObject={false}
            value={_.get(entity, 'statusIntegracao')}
            placeholder="Status da integração"
            onChange={this.props.onChangeDate}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
