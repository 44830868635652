import React, { PureComponent } from "react";
import {ModalBody, Modal, ModalFooter, Input} from "reactstrap";

type Props = {};

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};

export class ModalSolutiOtp extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      otpCode: null
    }
  }
  updateInputValue = (evt) => {
    this.setState({
      otpCode: evt.target.value
    });
  }
  cancel = () => {
    this.props.toogle();
  }
  sign = () => {
    this.props.handleSetOtpCode(this.state.otpCode);
  }
  render() {
    return (
        <React.Fragment>
          <Modal
              isOpen={this.props.isOpen}
              toggle={this.props.toggle}
              backdrop="static"
              modalClassName=""
              className="modal-soluti-otp"
              centered={true}
              modifiers={modifiers}
              target={this.props.target}
          >
            <ModalBody>
              <div className="row top-modal">
                <div className="col-12 text-center my-2">
                  <h2 className="font-weight-bold">BirdId OTP</h2>
                </div>
              </div>

              <div className="row">
                <Input
                    type='text'
                    id='otp'
                    placeholder='Digite aqui o seu código OTP gerado pelo aplicativo BirdId'
                    maxLength={6}
                    onChange={this.updateInputValue}
                >
                </Input>
              </div>
              <hr></hr>
            </ModalBody>
            <ModalFooter>
              <div className="w-100 text-center">
                <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.cancel();
                    }}
                >
                  {' '}
                  Cancelar{' '}
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.sign();
                    }}
                >
                  {" "}
                  Assinar e Imprimir{" "}
                </button>
              </div>
            </ModalFooter>
          </Modal>

          {/* ////////////////////////////////////////////////////// */}
        </React.Fragment>
    );
  }
}
