import _ from 'lodash';
import React from "react";
import Avatar from "../../components/Avatar/Avatar";


export const OptionProfissional = props => {
    const { data, innerProps } = props;
    return (
        <div className="d-block  pb-1" {...innerProps}>
            <div className="d-flex w-100 flex-row align-items-center justify-content-around react-select__option action py-2">
                <div className="col-2 d-flex flex-column align-items-stretch optionCreateUser">
                    <Avatar className="avatarCustom avatar-list" size={32} user={data} namePropsMidiaId={'foto.id'} />
                </div>
                <div className="col-10 d-flex flex-column align-items-stretch create">
                    <div className="select-nome customOption">{data.apelido}</div>
                    <div className="select-conselho customOption">{data.conselhoString}</div>
                    <div className="select-especialidades customOption">{_.get(data, 'especialidades', [])
                        .map((e) => e.nome)
                        .join(',')}</div>
                </div>
            </div>
        </div>
    );
};