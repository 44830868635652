//@flow
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import ExamesForm from './ExamesForm';
import ProcedimentosForm from './ProcedimentosForm';
import ReceituarioForm from './ReceituarioForm';

class ReceituarioExamesProntuarioForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: 0,
    };
    // console.log(this.props.receituarioON);
  }

  show = (show) => {
    this.setState({ show });
  };

  render() {
    const {
      viewMode,
      entity,
      atendimentoEmAtendimento,
      values,
      errors,
      handleChange,
      setFieldValue,
      setValues,
      ...otherProps
    } = this.props;

    return (
      <React.Fragment>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link d-flex align-items-center ${
                this.state.show === 0 ? 'active' : ''
              }`}
              onClick={() => {
                this.show(0);
              }}
            >
              <span className={'icon-receituario mr-2'}></span>
              Receituário
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link d-flex align-items-center ${
                this.state.show === 1 ? 'active' : ''
              }`}
              onClick={() => {
                this.show(1);
              }}
            >
              <span className={'icon-procedimentos mr-2'}></span>
              Procedimentos
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link d-flex align-items-center ${
                this.state.show === 2 ? 'active' : ''
              }`}
              onClick={() => {
                this.show(2);
              }}
            >
              <span className={'icon-exames mr-2'}></span>
              Exames
            </a>
          </li>
        </ul>
        <ReceituarioForm
          toggleProtocolo={this.props.toggleProtocolo}
          show={this.state.show}
          history={this.props.history}
          viewMode={this.props.viewMode}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setValues={setValues}
        />
        <ProcedimentosForm
          show={this.state.show}
          viewMode={this.props.viewMode}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setValues={setValues}
        />
        <ExamesForm
          show={this.state.show}
          agendamentosHoje={this.props.agendamentosHoje}
          viewMode={this.props.viewMode}
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setValues={setValues}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ReceituarioExamesProntuarioForm);
