//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../_constants/objects.constants';
import { ConfirmAcationService } from '../../_services/ConfirmAcationService';
import { Can } from '@casl/react';
import { withRouter } from 'react-router-dom';
type Props = {
  onCancel: any,
  onSave: any,
};

class SaveCancelCardBtn extends PureComponent<Props> {
  static defaultProps = {
    titleBtnSave: 'Salvar',
    titleBtnCancel: 'Cancelar',
  };
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-secondary delete"
          onClick={() => {
            ConfirmAcationService.confirmMsg(
              this.props.onCancel,
              `Cancelar ${entity.id ? 'edição' : 'criação'}  `,
              `Ao cancelar, você irá perder todos os dados inseridos. Deseja realmente cancelar a ${
                entity.id ? 'edição' : 'criação'
              }  deste registro?`,
              null,
              'Não',
              'Sim',
              objectsConstants.TYPE_CANCEL
            );
          }}
        >
          {this.props.titleBtnCancel}
        </button>
        <Can
          I={objectsConstants.INSERT}
          a={this.props.permissionSave}
          ability={this.props.permissions}
          key={this.props.permissionSave}
        >
          <button
            type="button"
            className="btn btn-secondary save"
            onClick={this.props.onSave}
          >
            {this.props.titleBtnSave}
          </button>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions,
  };
}

export default connect(mapStateToProps, null)(withRouter(SaveCancelCardBtn));
