//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import './Pacote.css';
import { objectsConstants } from '../../_constants/objects.constants';
import PacoteForm from './PacoteForm';

const defaultArrayItem = [{ idList: new Date().getTime() }];
const defaultEntity = {
  codigo: '',
  procedimentos:[],
  materiais: [],
};

class PacoteEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/pacotes/view/' + values.id,
      state: { entity: values },
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      estabelecimentoService.doGet(this.props.match.params.id).then(
        (response) => {
          console.log('componentDidMount');
          console.log(response.data.data);
          let estabelecimento = response.data.data;
          if (!_.get(estabelecimento, 'listConvenio.length')) {
            estabelecimento.listConvenio = defaultArrayItem;
          }
          if (!_.get(estabelecimento, 'listHorarioAtendimento.length')) {
            estabelecimento.listHorarioAtendimento = defaultArrayItem;
          }
          if (!_.get(estabelecimento, 'listConfiguracaoSala.length')) {
            estabelecimento.listConfiguracaoSala = defaultArrayItem;
          }
          this.setState({ entity: estabelecimento }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  render() {
    let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {/* <div className="d-flex flex-column flex-xl-row justify-content-center"> */}
        <TopoTitleComponente
          mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  Pacote`}
          canBack={true}
          backUrl={!entity.id ? null : '/pacotes'}
        />
        {/* <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem> */}

        <div className="flex-column mt-4">
          <FullCustomCard
            title="Pacote"
            permissionSave={objectsConstants.CRUD_ESTABELECIMENTOS}
            Componente={PacoteForm}
            viewMode={false}
            entity={entity}
            onCancel={this.handleCancel}
            {...otherPops}
          ></FullCustomCard>

         
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(PacoteEdit)
);
