//@flow
type State = {
    indisponibilidadeAberta: boolean,
};

export default {
    state: { indisponibilidadeAberta: false },
    reducers: {
        toggleIndisponibilidade: (state: State) => {
            console.log('ToogleIndisponibilidade');
            return Object.assign({}, state, {
                indisponibilidadeAberta: !state.indisponibilidadeAberta
            });
        },
        abreIndisponibilidade: (state: State,) => {
            return Object.assign({}, state, {
                indisponibilidadeAberta: true,
            });
        },
        fechaIndisponibilidade: (state: State) => {
            return Object.assign({}, state, {
                indisponibilidadeAberta: false
            });
        },

    }
};
