//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import IconDelete from '../../images/icon_x_red.svg';
import { convenioService } from '../../_services/convenio.service';
import { FormGroup } from '../FormGroup';
import CreatableMulti from '../inputs/CreatableMulti';
import FormSelectInput from '../inputs/FormSelectInput';
import InputViewEdit from '../inputs/InputViewEdit';

type Props = {
  entity: any,
};

type State = {
  daysOfWeek: [],
};

export default class ConveniosPlanos extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listPlanoConvenios: [],
    };
  }

  componentDidMount() {
    const { entity, propName } = this.props;
    let convenio = _.get(entity, `${propName}`);
    if (!_.isEmpty(convenio)) {
      this.carregaListaPlanoConvenio(convenio.id);
    }
  }

  carregaListaPlanoConvenio = (idConvenio) => {
    convenioService.findPlanoConvenioByConvenio(idConvenio).then(
      (response) => {
        let listPlanoConvenios = response.data;
        this.setState({ listPlanoConvenios: listPlanoConvenios }, () => {});
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const { entity, propName, index, viewMode, size } = this.props;
    return (
      <div className="row section-form">
        <FormGroup className="col-12 col-lg-5">
          <InputViewEdit
            label="Convênio"
            viewClassName="font-weight-bold"
            placeholder="Selecione um plano"
            component={FormSelectInput}
            service={convenioService.findAll}
            labelKey={'sigla'}
            valueKey={'id'}
            returnFullObject={true}
            defaultValue={_.get(entity, `${propName}.sigla`, '--')}
            className={''}
            required={true}
            value={_.get(entity, `${propName}`)}
            name={`${propName}`}
            viewMode={viewMode}
            id={`convenioplano${index}Item`}
            onChange={(name, value) => {
              this.props.onChange(name, value);
            }}
            erroMensagem={_.get(this.props.errors, `${propName}.nome`)}
            sortNoAsc={true}
            sortKey={'sigla'}
            creatable={true}
          />
        </FormGroup>
        <FormGroup className="col-12 col-lg-6">
          <InputViewEdit
            component={CreatableMulti}
            onChange={(name, value) => {
              if (value && !_.get(value[value.length - 1], 'status')) {
                value[value.length - 1].id = new Date().getTime();
              }
              this.props.onChange(name, value ? value : []);
            }}
            returnFullObject={true}
            id={`planos${index}`}
            label={'Planos'}
            name={`${propName}.listPlanoConvenio`}
            placeholder="Digite o plano e aperte enter"
            noResultsText={'Digite o plano'}
            viewMode={viewMode}
            value={_.get(entity, `${propName}.listPlanoConvenio`, [])}
            creatable={true}
            multi
            apendRoot
            labelKey={'nome'}
            valueKey={'id'}
            defaultValue={_.get(entity, `${propName}.listPlanoConvenio`, [])
              .map((x) => x.nome)
              .join(', ')}
            options={this.state.listPlanoConvenios}
          />
        </FormGroup>
        {!viewMode && (
          <React.Fragment>
            <FormGroup className="col-12 col-sm-1 d-flex align-items-center justify-content-center">
              <div className="row">
                <div className={`col-12 ${viewMode ? 'd-none' : ' d-flex '}`}>
                  <div
                    className={`icon-delete d-flex align-items-center justify-content-center `}
                    onClick={() => {
                      this.props.removeItem(index, entity);
                    }}
                  >
                    <img src={IconDelete} alt="img" />
                  </div>
                </div>
              </div>
            </FormGroup>
            {/* <hr className="alpha" /> */}
          </React.Fragment>
        )}
      </div>
    );
  }
}
