import React from "react";



export default class CheckboxMultiSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props)

  }


  render() {
    return (
      <div></div>
    )
  }
}