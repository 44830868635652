//@flow
export default {
  state: { messages: [] },
  reducers: {
    addMessage: (state, payload) => {
      return Object.assign({}, state, {
        messages: payload
      });
    },
    clearMessage: (state, payload) => {
      return Object.assign({}, state, {
        messages: []
      });
    }
  },
  effects: (dispatch: any) => ({
    addAllMessage: async payload => {
      if (payload && payload.length > 0) {
        dispatch.alert.clear();
        dispatch.alert.error({
          message:
            'Existem erros no formulário, verifique os campos em destaque.'
        });
      }
      dispatch.fieldMessage.addMessage(payload);
    }
  })
};
