//@flow
import { urlsConstants } from '../_constants/urls.constant';
import { defaultService } from './defaultService';


export const fornecedorService = {
  findByEstabelecimento,
  doSave
};

function findByEstabelecimento() {
  return defaultService.doGet(`${urlsConstants.FORNECEDOR}findByEstabelecimento`);
}


function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.FORNECEDOR, entity);
}

