//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../components/templates/ListagemPage';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { tableHelper } from '../../../_helpers/tableHelper';
import { saidaEstoqueService } from '../../../_services/saidaEstoque.service';
import EstoqueSaidaListFilter from './EstoqueSaidaListFilter';

const columns = [
  {
    path: 'id',
    pathFilter: 'id',
    title: 'Código',
    isKey: true,
    dataSort: true,
    className: '',
    width: '4%',
  },
  {
    path: 'tipoSaida',
    pathFilter: 'tipoSaida',
    title: 'Tipo',
    isKey: false,
    isTipoSaida: true,
    dataSort: true,
    className: 'nomeProprio column-bold',
    width: '10%',
  },
  {
    path: 'dataHoraLancamento',
    pathFilter: 'dataHoraLancamento',
    title: 'Data do lancamento',
    isKey: false,
    dataSort: true,
    isDateTime: true,
    className: 'nomeProprio column-bold',
    width: '10%',
  },
  {
    path: 'justificativa',
    pathFilter: 'justificativa',
    title: 'Justificativa',
    isKey: false,
    dataSort: true,
    width: '25%',
  },

  {
    path: 'paciente.nome',
    pathFilter: 'paciente.nome',
    title: 'Paciente',
    isKey: false,
    dataSort: true,
    width: '12%',
  },
  {
    path: 'situacao',
    pathFilter: 'situacao',
    title: 'Situação',
    isKey: false,
    dataSort: true,
    className: 'nomeProprio column-bold',
    width: '10%',
  },
];

const endPoint = '/estoque-saida/novo';
const endPointView = '/estoque-saida/view/';
class EstoqueSaidaListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    console.log('construtor');
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );
    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    // this.props.history.push({
    //   pathname: endPoint,
    //   state: {
    //     titlePage: 'Usuário',
    //   },
    // });
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_ESTOQUE_SAIDA}
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_ESTOQUE_EDITAR}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Nova saída'}
        ></TopoListagem>
        <ListagemPage
          title={'Estoque: Saídas'}
          showNewButton={false}
          delete={() => {}}
          service={saidaEstoqueService}
          defaultOrder={'dataHoraLancamento'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={EstoqueSaidaListFilter}
          controller="api/saidaEstoque"
          fields={this.state.fields}
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(EstoqueSaidaListagemPage);
