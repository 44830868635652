//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../components/templates/ListagemPage';
import TopoListagem from '../../../components/Topo/TopoListagem';
import { objectsConstants } from '../../../_constants/objects.constants';
import { tableHelper } from '../../../_helpers/tableHelper';
import { paramTriagemService } from '../../../_services/paramTriagem.service';
import TriagemListFilter from './TriagemListFilter';

const columns = [
  {
    path: 'ordem',
    pathFilter: 'ordem',
    title: 'Ordenação',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'nome',
    pathFilter: 'nome',
    title: 'Nome dogrupo de triagem ',
    isKey: true,
    dataSort: true,
    className: 'column-bold',
  },

  {
    path: 'descricao',
    pathFilter: 'descricao',
    title: 'Descrição',
    isKey: false,
    dataSort: true,
  },
];

const endPoint = '/parametrizacao/triagem/novo';
const endPointView = '/parametrizacao/triagem/';
class TriagemListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={objectsConstants.TITULO_PARAMETRIZACAO_TRIAGEM}
          subTitle=" "
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.CRUD_PARAMETRIZACAO_TRIAGEM}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo parâmetro triagem'}
        ></TopoListagem>
        <ListagemPage
          title={`Parâmetros de triagem`}
          showNewButton={false}
          delete={() => {}}
          service={paramTriagemService}
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={TriagemListFilter}
          fields={this.state.fields}
          controller="api/triagemgruposelecao"
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(TriagemListagemPage);
