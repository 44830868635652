//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import IntegracaoConnectListagemPage from './Connect/IntegracaoConnectListagemPage';
import IntegracaoSascAtendimentosListagemPage from './Sasc/Atendimentos/IntegracaoSascAtendimentosListagemPage';
import IntegracaoSascListagemPage from './Sasc/IntegracaoSascListagemPage';
import IntegracaoSascLogsListagemPage from './Sasc/Log/IntegracaoSascLogsListagemPage';

export default class IntegracaoIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/integracao/sascconnect"
          component={IntegracaoConnectListagemPage}
          exact
        />
        <Route
          path="/integracao/sasc"
          component={IntegracaoSascListagemPage}
          exact
        />
        <Route
          path="/integracao/sasc/log/:id"
          component={IntegracaoSascLogsListagemPage}
          exact
        />

        <Route
          path="/integracao/sasc/atendimentos/:id"
          component={IntegracaoSascAtendimentosListagemPage}
          exact
        />
      </Switch>
    );
  }
}
