//@flow
import { urlsConstants } from './../_constants/urls.constant';
import type { filtro } from './../_types/filtro.type';
import { defaultService } from './defaultService';

export const indisponibilidadeService = {
  findAll,
  doSave,
  doList,
  doDelete,
  findByFiltro,
  findMarcacoesAfetadas,
};

function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.INDISPONIBILIDADE}${id}`);
}

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.INDISPONIBILIDADE}list`,
    options,
    filter
  );
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.INDISPONIBILIDADE_FINDALL}`);
}

function findMarcacoesAfetadas(id, tipoAgenda) {
  return defaultService.doGet(
    `${urlsConstants.INDISPONIBILIDADE_MARCACOES_AFETADAS}/${id}/${tipoAgenda}`
  );
}

function doSave(indisponibilidade: any) {
  return defaultService.doSave(
    urlsConstants.INDISPONIBILIDADE,
    indisponibilidade
  );
}

function doList(filtro: filtro) {
  return defaultService.doPost(
    `${urlsConstants.INDISPONIBILIDADE_LIST}`,
    filtro
  );
}
