import * as React from 'react';
import _ from 'lodash';
import { userService } from '../../../_services/user.service';
import { filaService } from '../../../_services/fila.service';
import moment from 'moment';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import CpfCnpjInput from '../../../components/inputs/CpfCnpjInput';

export default class FilaMarcacaoPage extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.state = {
      fila: [],
      filas: [],
      pacienteAtual: null,
    };
  }

  chamar = () => {
    this.props.onChange(this.props.fila[0].paciente.cpfCnpj);
  };

  render() {
    let { fila } = this.props;
    console.log(fila);

    return (
      <React.Fragment>
        <div>
          {' '}
          <div></div>
          <div className="conteiner-triagem">
            {this.state.pacienteAtual && (
              <div className="text-center d-flex flex-column align-items-center">
                <h1>Paciente Atual</h1>
                <p>{this.state.pacienteAtual.senha}</p>
                <p>{this.state.pacienteAtual.horaChegada}</p>
                <div className={'col-6'}>
                  <InputViewEdit
                    label="CPF do Paciente"
                    viewMode={false}
                    component={CpfCnpjInput}
                    type="text"
                    id={`cpf`}
                    name={`cpf`}
                    placeholder="Informe o CPF do Paciente"
                    value={_.get(this.state, `cpf`)}
                    onChange={(name, cpf) => {
                      this.setState({ cpf });
                    }}
                  />
                </div>
                <div className={'col-12'}>
                  <button
                    className={'btn btn-danger float-left mx-3'}
                    onClick={this.inativar}
                  >
                    Inativar Senha
                  </button>
                  <button
                    className={'btn btn-secondary mx-3'}
                    onClick={this.cancelar}
                  >
                    Cancelar
                  </button>
                  <button
                    className={'btn btn-info'}
                    onClick={this.iniciarAtendimento}
                    disabled={!this.state.cpf || this.state.cpf.length != 14}
                  >
                    Iniciar Atendimento
                  </button>
                </div>
              </div>
            )}

            <div className="row align-items-stretch filas">
              <div className={'col-6 col-lg-3 d-flex'}>
                <div className={'my-2 mr-2 w-100 card card-fila'}>
                  <div className="clearfix mb-2 d-flex">
                    <h2 className={'d-inline mx-3'}>{fila.nome}</h2>
                  </div>

                  {fila.length > 0 && (
                    <React.Fragment>
                      <div>
                        <p>
                          <b>Senha:</b> {fila[0].paciente.nome}
                        </p>
                        <p>
                          <b>Horario da Consulta: </b>
                          {fila[0].horario}
                        </p>
                        <p>
                          <b>Horario da chegada do paciente: </b>
                          {fila[0].horaChegada}
                        </p>
                      </div>
                      <div className="clearfix  text-left">
                        <button
                          className={'btn btn-primary float-left '}
                          onClick={() => {
                            this.chamar(fila[0]);
                          }}
                        >
                          Encontrar Paciente
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                  {fila.length == 0 && (
                    <React.Fragment>
                      <div className={'mb-3'}>Nenhum Paciente aguardando</div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Paciente</th>
                  <th scope="col">Hora da Consulta</th>
                  <th scope="col">Horario de chegada do paciente</th>
                </tr>
              </thead>
              <tbody>
                {fila &&
                  fila.map((f, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{f.paciente.nome}</th>
                        <td>{f.horario}</td>
                        <td>{f.horaChegada}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
