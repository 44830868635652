//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';

import { paramTriagemService } from '../../../_services/paramTriagem.service';
import { objectsConstants } from '../../../_constants/objects.constants';
import TriagemForm from './TriagemForm';

const defaultArrayItem = [{ idList: new Date().getTime() }];

class TriagemView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
    };
  }

  componentDidMount() {
    this.loadParamTriagem();
  }

  loadParamTriagem = () => {
    console.log(this.props.match.params.id);
    if (this.props.match.params.id) {
      this.props.loading(true);
      paramTriagemService.doGet(this.props.match.params.id).then(
        (response) => {
          let paramTriagem = response.data.data;
          console.log(response.data.data);
          this.setState({ entity: _.cloneDeep(paramTriagem) }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleDeleteItem = () => {
    const { entity } = this.state;
    this.props.loading(true);
    paramTriagemService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Parâmetro de triagem foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push('/parametrizacao/triagem');
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir o parâmetro de triagem.',
        });
      }
    );
  };

  render() {
    // let _this = this;
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={entity.nome}
          canBack={true}
          backUrl={'/parametrizacao/triagem'}
        />

        <div className="flex-column mt-4">
          <FullCustomCard
            permissionSave={objectsConstants.MENU_PARAMETRIZACAO_TRIAGEM}
            title="Dados da parametrização de triagem"
            Componente={TriagemForm}
            viewMode={true}
            entity={entity}
            removeItem={this.handleDeleteItem}
            onSave={this.loadParamTriagem}
            cancelForm={this.loadParamTriagem}
            btnExcluir={true}
            redirectPath="/parametrizacao/triagem/"
            {...otherPops}
          ></FullCustomCard>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(TriagemView));
