//@flow
import _ from 'lodash';
import * as React from 'react';
import nextId from 'react-id-generator';
import { objectsConstants } from '../../_constants/objects.constants';
import { urlsConstants } from '../../_constants/urls.constant';
import { marcacaoService } from '../../_services/marcacao.service';
import { userService } from '../../_services/user.service';
import DadosUser from '../../components/DadosUser/DadosUser';
import ButtonAutorized from '../buttons/ButtonAutorized';

type Props = {
  horario: any,
  idMarcacao: number,
};

type State = {
  marcacao: any,
};

export class HorarioMarcacaoNaoFinalizadaRow extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { paciente: null };
  }

  componentDidMount() {
    this.findMarcacao(this.props.idMarcacao);
  }

  handleChangeStatus = (status) => {
    if (this.props.clientSocket && this.props.clientSocket.connected) {
      this.props.clientSocket.send(
        urlsConstants.TASK_STATUS_CHANGE,
        JSON.stringify({
          id: this.props.idMarcacao,
          status: status,
        })
      );
    }
  };

  findMarcacao = (idMarcacao) => {
    marcacaoService.doGet(idMarcacao).then(
      (response) => {
        this.setState({
          marcacao: response.data.data,
        });
      },
      () => {
        console.log('Não foi possivel buscar marcação');
      }
    );
  };

  render() {
    const { horario } = this.props;

    // let horaMenorQueAgora = this.horaMenorQueAgora(horario) || indisponivel;
    return (
      <div className="row timetable flex-column flex-lg-row align-items-center">
        <div className="hour">{horario}</div>
        <div className="patient pl-0 pl-sm-5">
          <React.Fragment>
            {this.state.marcacao && (
              <DadosUser
                key={nextId()}
                getInfoSecundaria={
                  this.state.marcacao.paciente.telefones
                    ? _.get(this.state.marcacao.paciente.telefones[0], 'numero')
                    : 'Nenhum telefone cadastrado'
                }
                idUser={this.state.marcacao.paciente.id}
                marcacao={this.state.marcacao}
              ></DadosUser>
            )}
          </React.Fragment>
        </div>

        <div className="d-flex justify-content-center">
          <React.Fragment>
            {this.createAtendidoButton()}
            {this.createNaoCompareceuButton()}
          </React.Fragment>
        </div>
      </div>
    );
  }

  createAtendidoButton = () => {
    return (
      <ButtonAutorized
        className="btn-primary open"
        label={'Marcar como atendido'}
        role={objectsConstants.AGENDA_MARCAR}
        onClick={() => this.handleChangeStatus()}
        disabled={userService.isSuporte(objectsConstants.ATENDIDO)}
      ></ButtonAutorized>
    );
  };

  createNaoCompareceuButton = (value?: any) => {
    return (
      <ButtonAutorized
        className="btn btn btn-orange open"
        label={'Não compareceu'}
        role={objectsConstants.AGENDA_MARCAR}
        onClick={() => this.handleChangeStatus()}
        disabled={userService.isSuporte(objectsConstants.NAO_COMPARECEU)}
      ></ButtonAutorized>
    );
  };
}
